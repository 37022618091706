import {
  Filter,
  Filter1Outlined,
  FilterAlt,
  Search,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownTap from "../components/ProductComp/ProductDropdownTap";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import ProductListComp from "../components/ProductComp/ProductListComp";
import ProductLibraryComp from "../components/ProductLibrary/ProductLibraryComp";
import ProductListLibraryComp from "../components/ProductLibrary/ProductListLibraryComp";
import { animatedImg } from "../assets/img";
import CachedIcon from "@mui/icons-material/Cached";
import { product as productImg } from "../assets/img";
import ProductRequest from "../components/ProductFeed/ProductRequest";
import ProductMap from "../components/ProductFeed/ProductMap";
import PriceChange from "../components/ProductFeed/PriceChange";
import NewProductSuggest from "../components/ProductFeed/NewProductSuggest";

const ProductFeed = () => {
  const navigate = useNavigate();
  const Path = useLocation();
  const location = Path?.state?.type;
  const [activeTab, setActiveTab] = useState("product");

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="dashRightView  p-5 home_section trans">
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2">
        Vendor Request List
      </p>
      <div className="d-flex mb-4 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-4 rk">
          <button
            onClick={() => onTabClick("product")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "product"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "product" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Product Request
            </p>
          </button>
          <button
            onClick={() => onTabClick("map")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "map"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "map" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Map Request
            </p>
          </button>
          <button
            onClick={() => onTabClick("price")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "price"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "price" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Price Change
            </p>
          </button>
          <button
            onClick={() => onTabClick("new_product_suggest")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "new_product_suggest"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "new_product_suggest" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              New Project Suggestion
            </p>
          </button>
        </div>
      </div>

      {activeTab === "product" && <ProductRequest />}
      {activeTab === "map" && <ProductMap />}
      {activeTab === "price" && <PriceChange />}
      {activeTab === "new_product_suggest" && <NewProductSuggest />}
    </div>
  );
};

export default ProductFeed;
