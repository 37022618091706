import React, { useState } from "react";
import { searchIcon } from "../../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { event_category } from "../../redux/api/DummyJson";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";

const Inprocess = ({ onShowPopupHander }) => {
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      vendor_id: "Ven_001",
      prid: "PR-001",
      vendor_name: "Dhanush",
      vendor_email: "dhanush@gmail.com",
      mobile: "9080877665",
      total_qty: "2900",
      total: "30000",
      ex_date: "23/02/2024",
    },
    {
      id: 2,
      vendor_id: "Ven_002",
      prid: "PR-002",
      vendor_name: "Kamesh",
      vendor_email: "kamesh@gmail.com",
      mobile: "9080877660",
      total_qty: "2900",
      total: "100000",
      ex_date: "30/02/2024",
    },
    {
      id: 3,
      vendor_id: "Ven_003",
      prid: "PR-003",
      vendor_name: "Vinoth",
      vendor_email: "vinoth@gmail.com",
      mobile: "9000000000",
      total_qty: "2900",
      total: "20000",
      ex_date: "30/02/2024",
    },
  ];

  return (
    <div className="w-100 overflow-scroll mt-4">
      <table className="w-100">
        <tr>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              S.No
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Vendor ID
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              PRID
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Vendor Name
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Vendor Email
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Vendor Mobile Number
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Total Req Qty
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Total Amount
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Expected Delivery Date
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Notes
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              View
            </p>
          </th>
        </tr>
        {data?.map((item, ind) => {
          return (
            <tr className="my-3">
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {ind + 1}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.vendor_id}
                </p>
              </th>{" "}
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.prid}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.vendor_name}
                </p>
              </th>{" "}
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.vendor_email}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.mobile}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.total_qty}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.total}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.ex_date}
                </p>
              </th>
              <th>
                <p
                  className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4"
                  role={"button"}
                  onClick={onShowPopupHander}
                >
                  <CommentOutlinedIcon />
                </p>
              </th>
              <th>
                <button
                  onClick={() => navigate("/po-detail-view")}
                  className="w-100 text-center bg-transparent border-0"
                >
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 pb-4">
                    <VisibilityIcon />
                  </p>
                </button>
              </th>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default Inprocess;
