import React, { useState } from "react";
import { product } from "../../assets/img";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const LibraryVendorPricingComp = () => {
  const [customer, setCustomer] = useState(false);
  const [reseller, setReseller] = useState(false);
  const [priceCharge, setPriceCharge] = useState([]);

  const [marginShow, SetMarginShow] = useState(false);
  const [marginShow2, SetMarginShow2] = useState(false);

  const addpriceCharge = () => {
    let temp = [...priceCharge];

    temp.push({
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      num7: "",
      text: "",
    });
    setPriceCharge(temp);
  };

  const removepriceCharge = (e, ind) => {
    let temp = [...priceCharge];
    temp.splice(ind, 1);
    setPriceCharge(temp);
  };

  const handlepriceCharge = (e, index) => {
    let temp = [...priceCharge];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setPriceCharge(temp);
  };

  return (
    <div className="h-auto mt-4">
      <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Supplier Priority List
      </p>
      <div style={{ width: "250px" }} className="my-3 f2 fs-14 ">
        <div className="d-flex my-3 align-items-center justify-content-around">
          <p className="primary1 cp text-decoration-underline">VMHQ001</p>
          <label className="">5SK Supplier</label>
          <select
            className="cust-btn primary p-1 mx-2"
            style={{ border: "1px solid #07679c", borderRadius: "10px" }}
          >
            <option hidden selected>
              Select
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select>
        </div>
        <div className="d-flex my-3 ac-jb">
          <p className="primary1 cp text-decoration-underline">VMHQ002</p>
          <label className="w-100 text-nowrap ms-2">Charles Supplier</label>
          <select
            className="cust-btn primary p-1 mx-2"
            style={{ border: "1px solid #07679c", borderRadius: "10px" }}
          >
            <option hidden selected>
              Select
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select>
        </div>
      </div>

      <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Vendor Pricing
      </p>
      <div className=" mt-3">
        <div className="d-flex ac-js gap-2">
          <p className="primary1 cp text-decoration-underline">VMHQ001</p>
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            5SK Supplier
            <input type={"checkbox"} className="mx-2" />
          </p>
        </div>

        <div className="overflow-scroll">
          <table className="w-100">
            <tr className="mt-4">
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Qty
              </th>
              <div className="my-3">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="25"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="50"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="100"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="150"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="250"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="500"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="1000"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Distributor Price
              </th>
              <div className="mt-2">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="$100.00"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$90.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$80.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$70.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$70.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$70.00"
                        type="number"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
        </div>
      </div>
      <div className="mt-3">
        <div className="d-flex ac-js gap-2">
          <p className="primary1 cp text-decoration-underline">VMHQ001</p>
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Charles Supplier <input type={"checkbox"} className="mx-2" />
          </p>
        </div>

        <div className="overflow-scroll">
          <table className="w-100">
            <tr className="mt-4">
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Qty
              </th>
              <div className="my-3">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="25"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="50"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="100"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="150"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="250"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="500"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="1000"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Distributor Price
              </th>
              <div className="mt-2">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="$90.00"
                        maxlength={3}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
        </div>
      </div>
      <div className="">
        <button
          class="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mt-4"
          onClick={() => SetMarginShow(true)}
        >
          View Margin
        </button>
      </div>
      {marginShow && (
        <div>
          <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary mt-4">
            Admin Pricing
          </p>
          <div className="w-100 d-flex flex-wrap res-flex as-jb gap-5 py-4 px-4 bg-lt-blue2 rounded-4 mt-3">
            <div className="w-100">
              <div>
                <div className="w-100 d-flex ac-js">
                  <div className="w-100">
                    <h5 className="primary">
                      Ecommerce:
                    </h5>
                  </div>
                </div>
                <div>
                  <div className="">
                    <div className="overflow-scroll">
                      <table className="w-100">
                        <tr className="mt-4">
                          <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            Qty
                          </th>
                          <div className="my-3">
                            <td>
                              <div className="d-flex w-100">
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="25"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="50"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="100"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="150"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="250"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="500"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="1000"
                                  />
                                </div>
                              </div>
                            </td>
                          </div>
                        </tr>
                        <tr>
                          <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            Distributor Price
                          </th>
                          <div className="mt-2">
                            <td>
                              <div className="d-flex w-100">
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="number"
                                    placeholder="$90.00"
                                    maxlength={3}
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    placeholder="$85.00"
                                    type="number"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    placeholder="$85.00"
                                    type="number"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    placeholder="$75.00"
                                    type="number"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    placeholder="$75.00"
                                    type="number"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    placeholder="$75.00"
                                    type="number"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    placeholder="$75.00"
                                    type="number"
                                  />
                                </div>
                              </div>
                            </td>
                          </div>
                        </tr>
                        <tr>
                          <th className="  f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            <button
                              className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                              onClick={() => addpriceCharge()}
                            >
                              + add Charges
                            </button>
                          </th>
                        </tr>
                        {priceCharge?.map((item, ind) => {
                          return (
                            <tr>
                              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                <span>
                                  <button
                                    className="cust-btn"
                                    onClick={() => removepriceCharge(item, ind)}
                                  >
                                    <HighlightOffIcon
                                      style={{ width: "15px" }}
                                      className="mb-3"
                                    />
                                  </button>
                                </span>
                                <input
                                  className="mx-1 num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="text"
                                  style={{ width: "80px", height: "35px" }}
                                  value={item?.text}
                                  name="text"
                                  onChange={(e) => handlepriceCharge(e, ind)}
                                />
                              </th>
                              <div className="mt-2">
                                <td>
                                  <div className="d-flex w-100">
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        type="number"
                                        placeholder="$90.00"
                                        maxlength={3}
                                        value={item?.num1}
                                        name="num1"
                                        onChange={(e) =>
                                          handlepriceCharge(e, ind)
                                        }
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="$85.00"
                                        type="number"
                                        value={item?.num2}
                                        name="num2"
                                        onChange={(e) =>
                                          handlepriceCharge(e, ind)
                                        }
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="$85.00"
                                        type="number"
                                        value={item?.num3}
                                        name="num3"
                                        onChange={(e) =>
                                          handlepriceCharge(e, ind)
                                        }
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="$75.00"
                                        type="number"
                                        value={item?.num4}
                                        name="num4"
                                        onChange={(e) =>
                                          handlepriceCharge(e, ind)
                                        }
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="$75.00"
                                        type="number"
                                        value={item?.num5}
                                        name="num5"
                                        onChange={(e) =>
                                          handlepriceCharge(e, ind)
                                        }
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="$75.00"
                                        type="number"
                                        value={item?.num6}
                                        name="num6"
                                        onChange={(e) =>
                                          handlepriceCharge(e, ind)
                                        }
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="$75.00"
                                        type="number"
                                        value={item?.num7}
                                        name="num7"
                                        onChange={(e) =>
                                          handlepriceCharge(e, ind)
                                        }
                                      />
                                    </div>
                                  </div>
                                </td>
                              </div>
                            </tr>
                          );
                        })}
                        <tr className="mt-4">
                          <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            Net
                          </th>
                          <div className="my-3">
                            <td>
                              <div className="d-flex w-100">
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="25"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="50"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="100"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="150"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="250"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="500"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="1000"
                                  />
                                </div>
                              </div>
                            </td>
                          </div>
                        </tr>
                        <tr className="mt-4">
                          <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            <button
                              className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                              onClick={() => SetMarginShow2(!marginShow2)}
                            >
                              + add Margin
                            </button>
                          </th>
                          {marginShow2 && (
                            <div className="my-3">
                              <td>
                                <div className="d-flex w-100">
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="25"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="50"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="100"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="150"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="250"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="500"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="1000"
                                    />
                                  </div>
                                </div>
                              </td>
                            </div>
                          )}
                        </tr>
                        <tr className="mt-4">
                          <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            Price
                          </th>
                          <div className="my-3">
                            <td>
                              <div className="d-flex w-100">
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="25"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="50"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="100"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="150"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="250"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="500"
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="button"
                                    value="1000"
                                  />
                                </div>
                              </div>
                            </td>
                          </div>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="w-100 d-flex ac-js mt-4">
                    <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      MSP :
                    </p>
                    <div className="mx-2 place-holde">
                      <input
                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="5%"
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="overflow-scroll">
                    <table className="w-100 ">
                      <tr className="mt-4">
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Net
                        </th>
                        <div className="my-3">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    </table>
                  </div>
                  <div className="w-100 d-flex ac-js mt-4">
                    <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      SCP :
                    </p>
                    <div className="mx-2 place-holde">
                      <input
                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="5%"
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="overflow-scroll">
                    <table className="w-100 ">
                      <tr className="mt-4">
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Net
                        </th>
                        <div className="my-3">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="w-100 d-flex ac-jc">
                <div className="w-100">
                  <h5 class="primary">
                    Reseller:
                  </h5>
                </div>
              </div>
              <div>
                <div className="">
                  <div className="overflow-scroll">
                    <table className="w-100">
                      <tr className="mt-4">
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Qty
                        </th>
                        <div className="my-3">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="250"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="500"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="1000"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                      <tr>
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Net
                        </th>
                        <div className="mt-2">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$85.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className=" d-flex ac-js mt-4">
                  <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    MSP
                  </p>
                  <div className="mx-2 place-holde">
                    <input
                      class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      placeholder="5%"
                      type="number"
                    />
                  </div>
                </div>
                <div className="overflow-scroll">
                  <table className="w-100">
                    <tr className="mt-4">
                      <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Net
                      </th>
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
                <div className=" d-flex ac-js mt-4">
                  <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    SCP
                  </p>
                  <div className="mx-2 place-holde">
                    <input
                      class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      placeholder="5%"
                      type="number"
                    />
                  </div>
                </div>
                <div className="overflow-scroll">
                  <table className="w-100">
                    <tr className="mt-4">
                      <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Net
                      </th>
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-100 mt-5">
        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
          Last updated on August 28, 2023 at 03:10 AM
        </p>
      </div>
    </div>
  );
};

export default LibraryVendorPricingComp;
