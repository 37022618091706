import { useNavigate } from "react-router-dom";
import { editIcon, toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { customerList, shippingAddress } from "../../redux/api/DummyJson";
import { Search } from "@mui/icons-material";

const ShippingAddressList = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="wholeDesignShippingAdd d-flex mt-5 border-bottom">
        <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          S.no
        </p>
        {/* <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Customer ID
        </p> */}
        <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          First Name
        </p>
        <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Last Name
        </p>

        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Phone Number
        </p>
        <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Status
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Options
        </p>
      </div>
      {shippingAddress?.map((item, index) => {
        return (
          <div className="wholeDesignShippingAdd d-flex mt-5">
            <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {index + 1}
            </p>
            {/* <p className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.CustomerId}
            </p> */}
            <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.name}
            </p>
            <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.name}
            </p>

            {/* <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.organization}
            </p> */}
            <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.phoneNo}
            </p>
            <div className="w-10 d-flex ac-jc">
              {item?.status === true && (
                <div>
                  <img src={toggleOn} className="toggleOnDes" />
                </div>
              )}
              {item?.status === false && (
                <div>
                  <img src={toggleOff} className="toggleOnDes" />
                </div>
              )}
            </div>
            <div className="w-15 d-flex ac-jc">
              <div
                onClick={() =>
                  navigate("/view-customer-details", {
                    state: { data: item, type: "shipping" },
                  })
                }
                className="viewBoxDes pointerView p-2 bg-white rounded-2">
                <img src={viewIcon} className="viewDes" />
              </div>
              <div
                onClick={() =>
                  navigate("/view-customer-details", {
                    state: { data: item, type: "shipping", edit: true },
                  })
                }
                className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2">
                <img src={editIcon} className="viewDes" />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ShippingAddressList;
