import DeleteIcon from "@mui/icons-material/Delete";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CallIcon from "@mui/icons-material/Call";
import GroupsIcon from "@mui/icons-material/Groups";
import { animatedImg, empty_profile } from "../../assets/img";
import { toggleOn } from "../../assets/img";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LinkIcon from "@mui/icons-material/Link";
import { replyData } from "../../redux/api/DummyJson";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import EditIcon from "@mui/icons-material/Edit";
import AddContacts from "./AddContacts";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddNotes from "./AddNotes";
import DownloadIcon from "@mui/icons-material/Download";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useNavigate } from "react-router-dom";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";

const EditProfileDetails = ({
  setContactPopup,
  setAddressPopup,
  setPositionPopup,
  setEditProfile,
  setProfile,
  setHistory,
  place,
}) => {
  const navigate = useNavigate();
  const [reply, setReply] = useState(false);
  const [note, setNote] = useState(false);
  const [call, setCall] = useState(false);
  const [meeting, setMeeting] = useState(false);
  const [repIndex, setRepIndex] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [noteIndex, setNoteIndex] = useState("");

  const [addContact1, setAddContact1] = useState(false);
  const [addContact2, setAddContact2] = useState(false);
  const [addContact3, setAddContact3] = useState(false);
  const [addContact4, setAddContact4] = useState(false);
  const [addContact5, setAddContact5] = useState(false);
  const [addContact6, setAddContact6] = useState(false);
  const [addContact7, setAddContact7] = useState(false);
  const [addContact8, setAddContact8] = useState(false);
  const [addContact9, setAddContact9] = useState(false);
  const [addContact10, setAddContact10] = useState(false);
  const [addContact11, setAddContact11] = useState(false);

  const handleReply = (ind) => {
    if (repIndex === ind) {
      setReply(!reply);
    }
  };

  const handleNotes = (ind) => {
    if (noteIndex === ind) {
      setOpenNotes(!openNotes);
    }
  };

  return (
    <div className="d-flex flex-md-row flex-column flex-wrap w-100 mt-4">
      <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
        <div className="w-100 mt-1 ac-jb d-flex">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Vendor Details
          </p>

          <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
            Save
          </button>
        </div>
        <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Name
            </p>
            <input
              placeholder=""
              value={
                place === "John Rolph"
                  ? "John Rolph"
                  : place === "Jake Adams"
                    ? "Jake Adams"
                    : "Charles"
              }
              className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor ID
            </p>
            <input
              placeholder=""
              value={
                place === "John Rolph"
                  ? "VMHQ003"
                  : place === "Jake Adams"
                    ? "VMHQ002"
                    : "VMHQ001"
              }
              disabled
              className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        </div>
        <div className="w-95 mt-lg-4">
          <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Phone Number
          </p>
          <div className="w-100 d-flex ac-jb">
            <select
              placeholder=""
              className="w-15 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
              name=""
              id=""
            >
              <option>Work</option>
            </select>
            <input
              placeholder=""
              value="9876543210"
              className=" editBtn rounded-3 mt-2 p-2 w-60 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17"
            />
            <div className="d-flex ac-js w-20">
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-13">
                +Add Phone
              </p>
              <DeleteIcon className="pointerView primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
            </div>
          </div>
        </div>
        <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Type of Business*
            </p>
            <select
              placeholder=""
              className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
              name=""
              id=""
            >
              <option>MSME</option>
              <option>Proprietorship</option>
              <option>Partnership</option>
              <option>Pvt Ltd</option>
              <option>LLP</option>
              <option>Public Limited</option>
              <option value="others">Others</option>
            </select>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Line of Business*
            </p>
            <select
              placeholder=""
              className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
              name=""
              id=""
            >
              <option>Enterprise (company)</option>
              <option>MHQ</option>
              <option>Market Place (whole sales)</option>
            </select>
          </div>
        </div>
        <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Category*
            </p>
            <input
              placeholder=""
              value=""
              className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
            {/* {vendorCat === "others" && (
              <input
                placeholder=""
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            )} */}
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Tags
            </p>
            <input
              placeholder=""
              value=""
              className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        </div>
        <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Terms
            </p>
            <select
              placeholder=""
              className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
              name=""
              id=""
            >
              <option>7 days</option>
            </select>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Method
            </p>
            <select
              placeholder=""
              className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
              name=""
              id=""
            >
              <option>Mobile Banking</option>
            </select>
          </div>
        </div>
        <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              GST Tax*
            </p>
            <select
              placeholder=""
              className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
              name=""
              id=""
            >
              <option>18%</option>
            </select>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Currency*
            </p>
            <select
              placeholder=""
              className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
              name=""
              id=""
            >
              <option>INR</option>
            </select>
          </div>
        </div>
        <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Profile Description
            </p>
            <textarea
              placeholder=""
              value="Executive"
              className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        </div>

        <div className="w-100 mt-5">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Departments
          </p>
          <div className="w-100 mt-3 ac-jb d-flex">
            <p
              onClick={() => setAddContact1(!addContact1)}
              className="pointerView f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact1 ? "arrowIcon" : ""
                  } `}
              />
              Management <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact1 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}

          <div className="w-100 mt-3 ac-jb d-flex">
            <p
              onClick={() => setAddContact10(!addContact10)}
              className="pointerView f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact1 ? "arrowIcon" : ""
                  } `}
              />
              Marketing <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact10 && <AddContacts setContactPopup={setContactPopup} />}

          <div className="w-100 mt-3 ac-jb d-flex">
            <p
              onClick={() => setAddContact11(!addContact11)}
              className="pointerView f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact1 ? "arrowIcon" : ""
                  } `}
              />
              Logistics <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact11 && <AddContacts setContactPopup={setContactPopup} />}

          <div className="w-100 mt-1 ac-jb d-flex">
            <p
              onClick={() => setAddContact2(!addContact2)}
              className="pointerView f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact2 ? "arrowIcon" : ""
                  } `}
              />
              Design/Creative <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact2 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="w-100 mt-1 ac-jb d-flex">
            <p
              onClick={() => setAddContact3(!addContact3)}
              className="pointerView f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact3 ? "arrowIcon" : ""
                  } `}
              />
              Sales <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact3 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="pointerView w-100 mt-1 ac-jb d-flex">
            <p
              onClick={() => setAddContact4(!addContact4)}
              className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact4 ? "arrowIcon" : ""
                  } `}
              />
              Production <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact4 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="w-100 mt-1 ac-jb d-flex">
            <p
              onClick={() => setAddContact5(!addContact5)}
              className="pointerView f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact5 ? "arrowIcon" : ""
                  } `}
              />
              Customer Service <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact5 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="w-100 mt-1 ac-jb d-flex">
            <p
              onClick={() => setAddContact6(!addContact6)}
              className="pointerView f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact6 ? "arrowIcon" : ""
                  } `}
              />
              Accounting <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact6 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="w-100 mt-1 ac-jb d-flex">
            <p
              onClick={() => setAddContact7(!addContact7)}
              className="pointerView f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact7 ? "arrowIcon" : ""
                  } `}
              />
              Human Resources <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact7 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="w-100 mt-1 ac-jb d-flex">
            <p
              onClick={() => setAddContact8(!addContact8)}
              className="pointerView f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact8 ? "arrowIcon" : ""
                  } `}
              />
              Procurement <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact8 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="w-100 mt-1 ac-jb d-flex">
            <p
              onClick={() => setAddContact9(!addContact9)}
              className="pointerView f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1"
            >
              <PlayArrowIcon
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${addContact9 ? "arrowIcon" : ""
                  } `}
              />
              Inactive Contacts <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact9 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
        </div>

        <div className="w-100 mt-5">
          <div className="w-100 ac-jb d-flex">
            <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Addresses
            </p>
            <button
              onClick={() => setAddressPopup(true)}
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
            >
              Add
            </button>
          </div>
          <div className="w-95 mt-4">
            <div className="w-100 ac-jb d-flex">
              <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                Address 1{" "}
                <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                  (Shipping)
                </span>
              </p>
              <div>
                <EditIcon
                  onClick={() => setAddressPopup(true)}
                  className="pointerView primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                />
                <DeleteIcon className="pointerView ms-3 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
              </div>
            </div>
            <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
              No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai -
              600099, Tamil Nadu, India.
            </p>
          </div>
          <div className="w-95 mt-4">
            <div className="w-100 ac-jb d-flex">
              <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                Address 2{" "}
                <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                  (Billing){" "}
                  <span className="gray f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                    Optional
                  </span>
                </span>
              </p>
              <div>
                <EditIcon
                  onClick={() => {
                    setAddressPopup(true);
                  }}
                  className="pointerView primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                />
                <DeleteIcon className="pointerView ms-3 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
              </div>
            </div>
            <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
              No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai -
              600099, Tamil Nadu, India.
            </p>
          </div>
        </div>
      </div>
      <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
        <div className="w-100 replyPaddings mt-1">
          {!note && !call && !meeting && (
            <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Hey Charles,
            </p>
          )}
          {!note && !call && !meeting && (
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Find below your recent feed:
            </p>
          )}
          {!note && !call && !meeting && (
            <div className="w-100 ac-jc d-flex">
              <div className="d-flex w-lg-90 w-md-60 w-sm-70 flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row ac-jb">
                <div
                  onClick={() => setNote(true)}
                  className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <EventNoteIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Note
                  </p>
                </div>
                <div
                  onClick={() => setCall(true)}
                  className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <CallIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Call
                  </p>
                </div>
                <div
                  onClick={() => setMeeting(true)}
                  className="d-flex pointerView px-3 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <GroupsIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Meeting
                  </p>
                </div>
              </div>
            </div>
          )}
          {note && <AddNotes type="note" setNote={setNote} />}
          {call && <AddNotes type="call" setNote={setCall} />}
          {meeting && <AddNotes type="meet" setNote={setMeeting} />}

          <div className="w-100 d-flex ac-je mt-5">
            <div className="d-flex flex-row">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show:
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">All</option>
              </select>
              <p className="f2 ms-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Time Period:
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">All</option>
              </select>
            </div>
          </div>
          {replyData?.map((item, index) => {
            return (
              <div className=" w-xl-100 w-lg-100 w-md-90 w-sm-100 w-xs-100 d-grid ac-jc mt-5">
                <div className="w-100 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row ac-jc gap-3">
                  <div>
                    <img src={empty_profile} className="replyImg rounded-3" />
                  </div>
                  <div>
                    <p className="primary f3 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                      {item?.name}{" "}
                      <span className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Hourglass Essentials Pvt. Ltd.
                      </span>
                    </p>
                    <p className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Roger added the vendor account{" "}
                      <span className="text-black f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        {item?.referName}
                      </span>
                    </p>
                    <p className="primary1 f3 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Tagged in this post.
                    </p>
                  </div>
                </div>
                <div className="mt-3 w-xl-100 w-lg-100 w-xs-100 w-sm-100 d-flex ac-jb">
                  <div
                    onClick={() => {
                      handleReply(index);
                      setRepIndex(index);
                      setNoteIndex("");
                    }}
                    className="d-flex pointerView"
                  >
                    <ChatBubbleOutlineIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Reply
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setNoteIndex(index);
                      handleNotes(index);
                      setRepIndex("");
                    }}
                    className="d-flex pointerView"
                  >
                    <NoteAddOutlinedIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Add Note
                    </p>
                  </div>
                  <div
                    onClick={() => navigate("/view-thread")}
                    className="d-flex pointerView"
                  >
                    <LinkIcon className="black linkImg f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Full thread
                    </p>
                  </div>
                  <div className="d-flex pointerView">
                    <DownloadIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Download
                    </p>
                  </div>
                  {/* <div className="d-flex pointerView">
                    <DeleteIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Delete
                    </p>
                  </div> */}
                </div>
                <div className="mt-2">
                  <p className="primary f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    {item?.date}
                  </p>
                </div>
                {repIndex === index && reply && (
                  <div className="w-100 mt-2">
                    <div className="d-flex ac-je">
                      <textarea
                        className="editBtn rounded-3 p-2 w-90"
                        placeholder="Type here..."
                      />
                      <div className="mt-4 ms-1">
                        <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                      </div>
                    </div>
                  </div>
                )}
                {noteIndex === index && openNotes && (
                  <AddNotes type="profile" setOpenNotes={setOpenNotes} />
                )}
              </div>
            );
          })}

          {reply && (
            <div className="w-100 mt-5">
              <div className="d-flex ac-je">
                <textarea
                  className="editBtn rounded-3 p-2 w-90"
                  placeholder="Type here..."
                />
                <div className="mt-4 ms-1">
                  <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                </div>
              </div>
            </div>
          )}
          <div
            onClick={() => {
              setProfile(false);
              setEditProfile(false);
              setHistory(true);
            }}
            className="w-100 ac-jc d-flex mt-4"
          >
            <p className="pointerView f3 mt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
              See more
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfileDetails;
