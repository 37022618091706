import React, { useState } from "react";
import { delivery, product, searchIcon, viewIcon } from "../assets/img";
import EditShippingInfo from "../components/ShippingPopup/EditShippingInfo";
import {
  collaborateList,
  Shipping_supplier,
  Supplier_Details,
} from "../redux/api/DummyJson";
import EditShippingPoppup from "../components/ShippingPopup/EditShippingPoppup";
import NavbarHead from "../components/Sub_Layout/NavbarHead";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Inprocess from "../components/POallRequest/Inprocess";
import Accepted from "../components/POallRequest/Accepted";
import { Dropdown } from "bootstrap";
import { DropdownButton } from "react-bootstrap";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const ShippingDetails = () => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  const onEditClick = () => {
    setShowEditPopup(!showEditPopup);
  };
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("Warehouse Assigned");

  const navigate = useNavigate();
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const onShowPopupHander = () => {
    setShow(!show);
  };
  return (
    <div className="dashRightView2 overview_pages overflow-scroll ">
      <NavbarHead />
      <div className="w-100">
        <div className="d-flex ac-jb px-5 bg-lt-blue ">
          <div className="">
            <h2 className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-3 gray">
              Product Delivery Details
            </h2>
            <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 primary">
              {" "}
              Vishwa Velan
            </p>
            <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 gray">
              T-Shirt : <span className="primary">SKU-001</span>
            </p>
            <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 gray">
              Project-Id : <span className="primary">Pro-001</span>
            </p>
            <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 gray">
              Requested : <span className="primary">600</span>
            </p>
            <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 gray">
              Delivered : <span className="primary">500</span>
            </p>
          </div>
          <div className=" d-flex ac-je">
            <img src={delivery} alt="img" className="w-40" />
          </div>
        </div>

        <div className="d-flex mt-4 rk w-40">
          <button
            onClick={() => onTabClick("Warehouse Assigned")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Warehouse Assigned"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }   cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Warehouse Assigned" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Warehouse Assigned
            </p>
          </button>{" "}
          <button
            onClick={() => onTabClick("Supplier Assigned")}
            style={{ cursor: "pointer" }}
            Ontao
            className={`${
              activeTab === "Supplier Assigned"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Supplier Assigned" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Supplier Assigned
            </p>
          </button>
        </div>
      </div>
      <div className="d-flex ac-jb mt-4 px-4 py-3 rounded  bg-lt-blue">
        <div className="d-flex">
          <DropdownButton name="" id="" className="border-0 bg-transparent ">
            <div className="w-100 overflow-scroll mt-4 edit_page_cust bg-soft-gray1  bottom_cust_bord ">
              <table className="w-100 px-4">
                <tr className=" bg_fun">
                  <th className="col-md-4 ">
                    <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                      Product
                    </p>
                  </th>
                  <th className="col-md-4">
                    <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                      Supplier Shipping
                    </p>
                  </th>{" "}
                  <th className="col-md-4">
                    <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                      Decorator Shipping
                    </p>
                  </th>
                </tr>

                {Shipping_supplier?.map((item, ind) => {
                  return (
                    <tr className="my-5 border-bottom">
                      <td className="col-md-4 my-3">
                        <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                          <button className="mx-2 d-flex ac-jc cust-btn">
                            <img
                              src={item.img_list}
                              className="object-fit-contain img_size2 "
                            />
                            <p className="primary1 ms-2 f1 fs-16  fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                              {item?.id}
                            </p>
                          </button>
                        </p>
                      </td>
                      <td className="col-md-4 my-3 pb-4">
                        <div className="mt-3">
                          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                            <div className="d-flex mb-1 ">
                              <div className="col-md-6">
                                <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                                  Name :
                                </p>
                              </div>
                              <div className="col-md-6 text-start">
                                {item?.ship_name}
                              </div>
                            </div>
                            <div className="d-flex  mb-1 ">
                              <div className="col-md-6">
                                <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                                  In Hand Date :
                                </p>
                              </div>
                              <div className="col-md-6 text-start">
                                {item?.ship_inhand}
                              </div>
                            </div>
                            <div className="d-flex mb-1  ">
                              <div className="col-md-6">
                                <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                                  Ship To :
                                </p>
                              </div>
                              <div className="col-md-6 text-start">
                                {item?.ship_To}
                              </div>
                            </div>
                            <div className="d-flex  mb-1 ">
                              <div className="col-md-6">
                                <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                                  Address :
                                </p>
                              </div>

                              <div className="col-md-6 text-start">
                                {item?.Address}
                              </div>
                            </div>
                            <div className="d-flex  mb-1 ">
                              <div className="col-md-6">
                                <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                                  Method :
                                </p>
                              </div>

                              <div className="col-md-6 text-start">
                                {item?.Method}
                              </div>
                            </div>
                          </p>
                        </div>
                      </td>{" "}
                      <td className="col-md-4 my-3">
                        <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                          For More Details
                        </p>
                        <p className="text-center">
                          <button className="cust-btn addbtn  mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                            View <RemoveRedEyeIcon />
                          </button>
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </DropdownButton>
          <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2 gray">
            {" "}
            Warehouse{" "}
            <span className="f1 fs-8 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 ms-2">
              (Trichy)
            </span>
            <span className="bg-gray white p-2 rounded-pill ms-2">100</span>
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 gray me-4">
            Output : <span className="primary">100</span>
          </p>
          <button className="primary bg-transparent border-0">
            <RemoveRedEyeIcon />
          </button>
        </div>
      </div>
      {/* {showEditPopup && <EditShippingInfo togglePopup={onEditClick} />} */}
      {showEditPopup && <EditShippingPoppup togglePopup={onEditClick} />}
      <div className="w-100 overflow-scroll mt-4 edit_page_cust bg-soft-gray1  bottom_cust_bord ">
        <table className="w-100 px-4">
          <tr className=" bg_fun">
            {" "}
            <th className="col-md-3 ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"></p>
            </th>{" "}
            <th className="col-md-3">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"></p>
            </th>{" "}
            <th className="col-md-3">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"></p>
            </th>
            {""}
            <th className="col-md-3">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"></p>
            </th>
          </tr>

          {Supplier_Details?.map((item, ind) => {
            return (
              <tr className="my-5 border-bottom">
                <td className="col-md-3 my-3 d-flex  align-items-start p-4">
                  <div className="d-flex">
                    <div className="mx-2 mt-2 ">
                      <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                        1
                      </p>
                      <div className="px-2">
                        <p className="bg-soft-primary soft-primary">|</p>
                        <p className="bg-soft-primary soft-primary">|</p>
                        <p className="bg-soft-primary soft-primary">|</p>
                        <p className="bg-soft-primary soft-primary">|</p>
                        <p className="bg-soft-primary soft-primary">|</p>
                        <p className="bg-soft-primary soft-primary">|</p>
                        <p className="bg-soft-primary soft-primary">|</p>
                        <p className="bg-soft-primary soft-primary">|</p>
                      </div>
                    </div>
                    <div>
                      <p className="bg-soft-primary primary rounded-circle px-3 py-2 mx-2">
                        {item?.id}
                      </p>
                    </div>
                    <div>
                      <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                        WareHouse{" "}
                        <span className="bg-lt-blue gray">
                          {item?.WareLocation}
                        </span>
                      </p>
                      <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        In Hands : {item?.In_Date}
                      </p>
                    </div>
                    <div className="mx-2">
                      <p className="bg-gray white rounded-circle px-2 py-1">
                        {item?.ware_num}
                      </p>
                    </div>
                    <div>
                      <p className="bg-soft-gray1 primary rounded-circle mx-4 mb-3">
                        <KeyboardArrowRightIcon />
                      </p>
                    </div>
                  </div>
                </td>
                {/* data 1 ends */}
                {/* data 2 starts */}
                <td className="col-md-3 my-3 pb-4 p-4">
                  <div className="mt-3 mx-3">
                    <div className="d-flex">
                      <div>
                        <p className="bg-soft-primary primary rounded-circle me-1 px-3 py-2">
                          {item?.id}
                        </p>
                      </div>
                      <div className="mb-2">
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            Decorator{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-1 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.Decor_name}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.ware_num}
                        </p>
                      </div>
                      <div>
                        <p className="bg-soft-gray1 primary rounded-circle mx-4 mb-3">
                          <KeyboardArrowRightIcon />
                        </p>
                      </div>
                    </div>
                    {/* warehouse 1 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id1}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            WareHouse{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.WareLocation1}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num1}
                        </p>
                      </div>
                      <div className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        <SwapHorizIcon />
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name1}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num1}
                        </p>
                      </div>
                    </div>
                    {/* warehouse 2 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id2}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            WareHouse{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.WareLocation2}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num2}
                        </p>
                      </div>
                      <div className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        <SwapHorizIcon />
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name2}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num2}
                        </p>
                      </div>
                    </div>
                    {/* warehouse 3 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id3}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            WareHouse{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.WareLocation3}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num3}
                        </p>
                      </div>
                      <div className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        <SwapHorizIcon />
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name3}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num3}
                        </p>
                      </div>
                    </div>
                    {/* warehouse 4 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id4}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name1}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num4}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>{" "}
                {/* data 3 starts */}
                <td className="col-md-3 my-3 pb-4 ">
                  <div className="mt-3 mx-3">
                    <div className="d-flex">
                      <div>
                        <p className="bg-soft-primary primary rounded-circle me-1 px-3 py-2">
                          {item?.id}
                        </p>
                      </div>
                      <div className="mb-2">
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            Decorator{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-1 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.Decor_name}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.ware_num}
                        </p>
                      </div>
                      <div>
                        <p className="bg-soft-gray1 primary rounded-circle mx-4 mb-3">
                          <KeyboardArrowRightIcon />
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            WareHouse{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.WareLocation1}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num1}
                        </p>
                      </div>
                      <div className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        <SwapHorizIcon />
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name1}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num1}
                        </p>
                      </div>
                    </div>
                    {/* warehouse 2 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id2}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            WareHouse{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.WareLocation2}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num2}
                        </p>
                      </div>
                      <div className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        <SwapHorizIcon />
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name2}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num2}
                        </p>
                      </div>
                    </div>
                    {/* warehouse 3 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id3}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            WareHouse{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.WareLocation3}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num3}
                        </p>
                      </div>
                      <div className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        <SwapHorizIcon />
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name3}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num3}
                        </p>
                      </div>
                    </div>
                    {/* warehouse 4 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id4}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name1}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num4}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>{" "}
                <td className="col-md-3 my-3 pb-4 ">
                  <div className="mt-3 mx-3">
                    <div className="d-flex">
                      <div>
                        <p className="bg-soft-primary primary rounded-circle me-1 px-3 py-2">
                          {item?.id}
                        </p>
                      </div>
                      <div className="mb-2">
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            Decorator{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-1 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.Decor_name}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.ware_num}
                        </p>
                      </div>
                      <div>
                        <p className="bg-soft-gray1 primary rounded-circle mx-4 mb-3">
                          <KeyboardArrowRightIcon />
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            WareHouse{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.WareLocation1}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num1}
                        </p>
                      </div>
                      <div className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        <SwapHorizIcon />
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name1}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num1}
                        </p>
                      </div>
                    </div>
                    {/* warehouse 2 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id2}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            WareHouse{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.WareLocation2}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num2}
                        </p>
                      </div>
                      <div className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        <SwapHorizIcon />
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name2}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num2}
                        </p>
                      </div>
                    </div>
                    {/* warehouse 3 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id3}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 mb-1 fs-xxl-16 ms-2">
                            WareHouse{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.WareLocation3}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num3}
                        </p>
                      </div>
                      <div className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                        <SwapHorizIcon />
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name3}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num3}
                        </p>
                      </div>
                    </div>
                    {/* warehouse 4 starts */}
                    <div className="d-flex">
                      <div className="">
                        <p className="bg-soft-primary soft-primary rounded-circle mb-2 px-2">
                          .
                        </p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                        <p className="px-1 ms-1 soft-primary">|</p>
                      </div>
                      <div>
                        <p className="bg-soft-primary primary rounded-circle px-3 ms-4 py-2">
                          {item?.id4}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex">
                          <p className="f3 rounded p-1 bg-primary1 gray fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mb-1 ms-2">
                            Client{" "}
                          </p>
                          <p className="bg-lt-blue gray mx-2 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                            {item?.client_name1}
                          </p>
                        </div>
                        <p className="f2 fs-10 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-14 mb-1 fs-xxl-14 ms-2 gray">
                          In Hands : {item?.In_Date}
                        </p>
                      </div>
                      <div>
                        <p className="bg-gray white rounded-circle px-2 py-1">
                          {item?.decor_num4}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>{" "}
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};
//         <table className="w-100">
//           <tr className="mt-4 border_cust">
//             <div className="bg-soft-gray1 py-3 bottom_cust_bord">

//               <td>
//                 <div className="d-flex w-100">
//                   {/* <div className="mx-2">
//                     <div
//                       class="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary f3"
//                       style={{ width: "200px" }}
//                     >
//                       <input className="mx-2" type="checkbox" />
//                     </div>
//                   </div> */}
//                   <div className="mx-2">
//                     <td>
//                     <p
//                       class=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-start primary f3"
//                       style={{ width: "250px" }}
//                     >
//                       Product
//                     </p>
//                     </td>
//                   </div>
//                   <div className="mx-2">
//                     <td>
//                     <p
//                       class=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center primary f3"
//                       style={{ width: "200px" }}
//                     >
//                       Supplier Shipping
//                     </p>
//                     </td>
//                   </div>
//                   {/* <div className="mx-2" >
//                     <p class=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center primary f3"
//                       style={{ width: '150px' }}
//                     >Vendor Name</p>
//                   </div> */}
//                   <div className="mx-2">
//                     <td>
//                     <p
//                       class=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center primary f3"
//                       style={{ width: "200px" }}
//                     >
//                       {" "}
//                       Decorator Shipping
//                     </p>
//                     </td>
//                   </div>
//                 </div>
//               </td>
//             </div>
//           </tr>

//           <tr className="mt-4">
//             <div className="">
//               <td>
//                 <div className="d-flex w-100">
//                   <div className="mx-2 d-flex ac-jc" >
//                     <div class="d-flex ac-jc pb-3 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center primary f3">
//                       <input className="mx-2" type="checkbox" />
//                       <button className="mx-2 d-flex ac-jc cust-btn">
//                         <img
//                           src={product}
//                           className="object-fit-contain img_size2"
//                         />
//                       </button>
//                       <p className="primary1 f1 fs-16 me-1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
//                       SKU-001 Steel Hot
//                       </p>
//                     </div>
//                   </div>
//                   <div className="mx-2 d-flex ac-jc" style={{ width: "250px" }}>
//                     {/* <div>
//                       <p className="primary1 f1 fs-16 me-1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
//                         Stainless Steel Hot N Cold Bottle
//                       </p>
//                       <button
//                         onClick={onEditClick}
//                         className="cust-btn addbtn mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
//                       >
//                         ✎ Edit
//                       </button>
//                     </div> */}
//                   </div>
//                   <div className="mx-2 d-flex ac-jc" style={{ width: "200px" }}>
//                     <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView primary1">
//                       Name
//                     </p>
//                     {Shipping_supplier?.map((item, ind) => {
//           return (
// {item?.shipping}
//           )}
//                   </div>

//                   <div className="mx-2 d-flex ac-jc" style={{ width: "200px" }}>
//                     <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView gray">
//                       Garg Enterprises
//                     </p>
//                   </div>
//                 </div>
//               </td>
//             </div>
//           </tr>
//         </table>
{
  /* {activeTab == "Inprocess" && (
        <Inprocess onShowPopupHander={onShowPopupHander} />
      )}
      {activeTab == "Accepted" && (
        <Accepted onShowPopupHander={onShowPopupHander} />
      )}
      {activeTab == "Rejected" && (
        <Rejected onShowPopupHander={onShowPopupHander} />
      )} */
}
// </div>

export default ShippingDetails;
