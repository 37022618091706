import React, { useEffect, useState } from "react";
import { profilePic, searchIcon } from "../../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { event_category } from "../../redux/api/DummyJson";
import { CommentOutlined, Edit } from "@mui/icons-material";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import PorejectPoppup from "../../components/PODetails/PorejectPoppup";
import AddFileImage from "../../components/Popup/AddFileImage";
import PoNotesViewPopup from "../../components/Popup/PoNotesViewPopup";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const PODetailViewScreen = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Inprocess");

  const [productqty, setProductQty] = useState(500);
  const [edit, setEdit] = useState(true);
  const [statust, setStatust] = useState("");
  const [show, setShow] = useState(false);
  const [addFile, setAddFile] = useState(false);

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };
  const status = [
    { list: "Approved" },
    {
      list: "Rejected",
    },
    {
      list: "In process",
    },
    { list: "Request" },
  ];
  const statushandl = (e) => {
    setStatust(e.target.value);
    if (e.target.value == "Rejected") {
      setRejectPoppup(true);
    } else {
      setRejectPoppup(false);
    }
    console.log("statustss", statust);
  };

  console.log("statust", statust);

  const [rejectpoppup, setRejectPoppup] = useState(false);

  const rejPopFun = () => {
    setRejectPoppup(!rejectpoppup);
  };

  const data = [
    {
      id: 1,
      pro_id: "Pro_001",
      company: "Ninos Enterprises",
      sales_id: "SO-001 Ninos",
      client_name: "Ninos",
      sku_code: "SKU-001",
      project: "T-Shirt",
      attributes: "XL/BLACK",
      pro_qty: "600",
      extra_qty: "500",
      total: "500",
      final_sku: "Ninos-SKU001",
      amount: "30000",
      reci_qty: "500",
      status: "Based on GRN",
      actual_date: "2024-12-12",
      date_pay: "2024-12-12",
      payment_status: 1,
      mathod: "Screen Pending",
    },
    {
      id: 2,
      pro_id: "Pro_001",
      company: "Ninos Enterprises",
      sales_id: "SO-001 Ninos",
      sku_code: "SKU-002",
      client_name: "Ninos",
      project: "Bottle",
      attributes: "-",
      pro_qty: "2000",
      extra_qty: "300",
      total: "800",
      final_sku: "ECPHASIS-SKU002",
      amount: "100000",
      reci_qty: "",
      status: "-",
      actual_date: "2024-12-12",
      date_pay: "2024-12-12",
      payment_status: 1,
      mathod: "Screen Pending",
    },
    {
      id: 3,
      pro_id: "Pro_003",
      company: "Krishna Brand",
      sales_id: "SO-003 Krishna",
      client_name: "Krishna",
      sku_code: "SKU-001",
      project: "T-Shirt",
      attributes: "L/BLACK",
      pro_qty: "100",
      extra_qty: "100",
      total: "200",
      final_sku: "ECPHASIS-SKU002",
      amount: "20000",
      reci_qty: "",
      status: "-",
      actual_date: "2024-12-12",
      date_pay: "2024-12-12",
      payment_status: 1,
      mathod: "Laser Pending",
    },
    {
      id: 4,
      pro_id: "Pro_004",
      company: "5SK",
      sales_id: "SO-004 Sakthi",
      client_name: "Sakthi",
      sku_code: "SKU-001",
      project: "T-Shirt",
      attributes: "M/BLACK",
      pro_qty: "200",
      extra_qty: "500",
      total: "700",
      final_sku: "Ninos-SKU001",
      amount: "15000",
      reci_qty: "",
      status: "-",
      actual_date: "2024-12-12",
      date_pay: "2024-12-12",
      payment_status: 1,
      mathod: "Laser Pending",
    },
  ];

  const onShowPopupHander = () => {
    setShow(!show);
  };

  const toggleShowPopup = () => {
    setAddFile(!addFile);
  };

  return (
    <>
      {rejectpoppup && (
        <PorejectPoppup
          rejPopFun={rejPopFun}
          setRejectPoppup={setRejectPoppup}
        />
      )}

      {addFile && <AddFileImage toggleShowPopup={toggleShowPopup} />}
      {show && <PoNotesViewPopup onShowPopupHander={onShowPopupHander} />}
      <div className="dashRightView p-5 home_section trans">
        <p
          class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2 d-flex align-items-center gap-1"
          role={"button"}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon /> PO Inprocess Detail View
        </p>
        <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">10</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
          </div>
        </div>
        <div className="w-100 ac-js ps-4 d-flex bannerVendor p-1 mt-3 rounded-3">
          <div className="d-flex as-jb flex-md-row flex-column w-xs-100 w-sm-100 rounded-2 w-md-90 w-lg-90 w-xl-90">
            <div className="d-lg-flex d-xl-flex d-md-flex as-js bg-light p-4 gap-3 rounded-3">
              <img src={profilePic} className="profileImg" />
              <div className="">
                <p className="primary1 f4 fs-xs-22 fs-sm-23 fs-md-24 fs-lg-25 fs-xl-26 fs-xxl-27">
                  Dhanush
                </p>
                <div className="d-flex ac-jb gap-3">
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    Vendor ID :
                  </p>
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                    Ven_002
                  </p>
                </div>
                <div className="d-flex ac-jb gap-3">
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    Email Id :
                  </p>
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                    {" "}
                    Total Count
                  </p>
                </div>
                <div className="d-flex ac-jb gap-3">
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    Mob No :
                  </p>
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                    +91 99999 90908
                  </p>
                </div>
                <div className="d-flex ac-jb gap-3">
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    InHands Date :
                  </p>
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                    12/12/24
                  </p>
                </div>
                <div className="d-flex ac-jb gap-3">
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    Total Qty =
                  </p>
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                    2900
                  </p>
                </div>
                <div className="d-flex ac-jb gap-3">
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    Total Amount=
                  </p>
                  <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                    500000
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex ac-je w-100 mt-sm-3">
          <div className="d-flex ac-jb flex-md-row flex-column">
            <select
              placeholder="Status"
              onChange={statushandl}
              value={statust}
              className="w-30 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3"
              name=""
              id=""
            >
              {status?.map((item, ind) => {
                return (
                  <option key={item?.list} value={item?.list}>
                    {item?.list}
                  </option>
                );
              })}
              {/* <option>Rejected</option>
            <option>In process</option>
            <option>Request</option> */}
            </select>
            <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 px-3">
              Total Count : {"02"}
            </p>
            <div className="d-flex border-search">
              <img src={searchIcon} alt="search" className="searchiConImg" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <div className="w-100 overflow-scroll mt-4">
          <table className="w-100">
            <tr>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  S.No
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Project ID and Name
                </p>
              </th>{" "}
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Client name
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Sales Order Id
                </p>
              </th>{" "}
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  SKU Code
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Product Name
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Attributes
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Product Quantity
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Extra Quantity
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Total
                </p>
              </th>
              {/* <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Status Of Production
              </p>
            </th> */}
              {/* <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Issue Detail
              </p>
            </th> */}
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Shipping
                </p>
              </th>
              {/* <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Actual Date Of Delivery
              </p>
            </th> */}
              {/* <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Date Of Payment
              </p>
            </th> */}
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Final SKU
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Branded Qty
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Amount
                </p>
              </th>
              {/* <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Payment Status
              </p>
            </th> */}
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Proof
                </p>
              </th>
            </tr>
            {data?.map((item, ind) => {
              return (
                <tr className="my-3">
                  <th>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {ind + 1}
                    </p>
                  </th>
                  <th>
                    <div className="d-flex flex-column">
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                        {item?.pro_id}
                      </p>
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                        {item?.company}
                      </p>
                    </div>
                  </th>{" "}
                  <th>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.client_name}
                    </p>
                  </th>
                  <th>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.sales_id}
                    </p>
                  </th>{" "}
                  <th>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.sku_code}
                    </p>
                  </th>
                  <th>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.project}
                    </p>
                  </th>
                  <th>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.attributes}
                    </p>
                  </th>
                  <th>
                    <div className="w-100 text-center bg-transparent border-1 pb-4">
                      <input
                        placeholder=""
                        disabled={edit}
                        value={item?.pro_qty}
                        onChange={(e) => setProductQty(e.target.value)}
                        className="datepicker-input w-50 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                      />
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => setEdit(!edit)}
                      >
                        <DriveFileRenameOutlineOutlinedIcon />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex ac-jc">
                      <input
                        placeholder=""
                        value={item?.extra_qty}
                        className="datepicker-input w-50 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                      />
                    </div>
                  </th>
                  <th>
                    <input
                      placeholder=""
                      value={item?.total}
                      className="datepicker-input w-100 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                    />
                  </th>
                  {/* <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      Based on GRN
                    </p>
                  </button>
                </th> */}
                  {/* <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4">
                      <CommentOutlined />
                    </p>
                  </button>
                </th> */}
                  <th>
                    <button className="w-100 text-center bg-transparent border-0">
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4">
                        <ShoppingCartCheckoutOutlinedIcon />
                      </p>
                    </button>
                  </th>
                  {/* <th>
                  <div className="w-100 text-center bg-transparent border-1 pb-4">
                    <input
                      type="date"
                      class="datepicker-input viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                    />
                    <CalendarTodayOutlinedIcon className="f3 fs-13 fs-lg-17 fs-xl-18 fs-xxl-19 text-dark" />
                  </div>
                </th> */}
                  {/* <th>
                  <div className="w-100 text-center bg-transparent border-1 pb-4">
                    <input
                      type="date"
                      class="datepicker-input viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                    />
                    <CalendarTodayOutlinedIcon className="f3 fs-13 fs-lg-17 fs-xl-18 fs-xxl-19 text-dark" />
                  </div>
                </th> */}
                  <th>
                    <button className="w-100 text-center bg-transparent border-0">
                      <p className=" f2 fs-xs-11 text-nowrap fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                        {item?.final_sku}
                      </p>
                    </button>
                  </th>
                  <th>
                    <button className="w-100 text-center bg-transparent border-0">
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                        -
                      </p>
                    </button>
                  </th>
                  <th>
                    <button className="w-100 text-center bg-transparent border-0">
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                        {item?.amount}
                      </p>
                    </button>
                  </th>
                  {/* <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2"
                      name=""
                      id="">
                      <option>Amount Yet Received</option>
                      <option>Amount Yet Received</option>
                      <option>Individual pack</option>
                      <option>Ecommerce</option>
                    </select>
                  </p>
                </th> */}
                  <th>
                    <button className="w-100 text-center bg-transparent border-0">
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4 d-flex gap-2">
                        <p role={"button"} onClick={toggleShowPopup}>
                          <AttachFileOutlinedIcon />
                        </p>
                        <p role={"button"} onClick={onShowPopupHander}>
                          {" "}
                          <CommentOutlined />
                        </p>
                      </p>
                    </button>
                  </th>
                </tr>
              );
            })}
          </table>
        </div>
        <div className="d-flex ac-jb w-100 mt-3">
          <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
            Previous
          </button>
          <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default PODetailViewScreen;
