import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import CategoryIcon from "@mui/icons-material/Category";

const ProfileNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // console.log("Split________", splitLocation);
  const [enableDown, setEnableDown] = useState(false);
  const [prodManagement, setProdManagement] = useState(false);
  const [customerDown, setCustomerDown] = useState(false);
  const [helpDeskDown, setHelpDeskDown] = useState(false);
  const [siteManagement, setSiteManagement] = useState(false);
  const [production, setProduction] = useState(false);

  useEffect(() => {
    if (splitLocation[1] === "") {
      navigate("/home");
    }
  }, []);

  return (
    <div className="navBar">
      <div className="w-100">
        <div className="mt-4 ac-jc d-flex">
          <p className="f4 fs-sm-21 fs-md-25 fs-lg-27 fs-xl-29 fs-xxl-30 primary">
            merchhq
          </p>
        </div>
        <div
          onClick={() => {
            navigate("/home");
          }}
          className={`${
            splitLocation[1] === "home" ? "bg-soft-gray1" : ""
          } d-flex py-2 w-100 flex-md-row flex-column mt-4 pointerBtn`}
        >
          <DashboardIcon
            className={` ${
              splitLocation[1] === "home" ? "black" : "gray"
            } f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`wordsDes ${
              splitLocation[1] === "home" ? "black" : "gray"
            } f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Dashboard
          </p>
        </div>
        <div
          onClick={() => {
            navigate("/profile");
          }}
          className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
            splitLocation[1] === "profile" ||
            splitLocation[1] === "add-profile" ||
            splitLocation[1] === "view-profile" ||
            splitLocation[1] === "edit-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <PersonIcon
            className={` ${
              splitLocation[1] === "profile" ||
              splitLocation[1] === "add-profile" ||
              splitLocation[1] === "view-profile" ||
              splitLocation[1] === "edit-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "profile" ||
              splitLocation[1] === "add-profile" ||
              splitLocation[1] === "view-profile" ||
              splitLocation[1] === "edit-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Profile
          </p>
        </div>
        <div
          onClick={() => navigate("/role-profile")}
          className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
            splitLocation[1] === "role-profile" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <PermContactCalendarIcon
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Department/ Role
          </p>
        </div>
        <div
          onClick={() => navigate("/preset-packs")}
          className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
            splitLocation[1] === "our-packs" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <LocalGroceryStoreIcon
            className={`${
              splitLocation[1] === "our-packs" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "our-packs" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Our Packs
          </p>
        </div>
        <div
          onClick={() => {
            setCustomerDown(false);
            setProdManagement(false);
            setEnableDown(!enableDown);
            setHelpDeskDown(false);
            setSiteManagement(false);
            setProduction(false);
            navigate("/vendor-home");
          }}
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
        >
          <SupervisorAccountIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Vendor Management
          </p>
        </div>
        {enableDown && (
          <div>
            <div
              onClick={() => {
                navigate("/vendor-home");
              }}
              className={`${
                splitLocation[1] === "vendor-home" ||
                splitLocation[1] === "add-vendor"
                  ? "bg-soft-gray1"
                  : ""
              } d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100`}
            >
              <p
                className={`${
                  splitLocation[1] === "vendor-home" ||
                  splitLocation[1] === "add-vendor"
                    ? "black"
                    : "gray"
                } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Dashboard
              </p>
            </div>
            <div
              onClick={() => navigate("/vendor-management")}
              className={`${
                splitLocation[1] === "vendor-management" ||
                splitLocation[1] === "vendor-details"
                  ? "bg-soft-gray1"
                  : ""
              } d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100`}
            >
              <p
                className={`
              ${
                splitLocation[1] === "vendor-management" ||
                splitLocation[1] === "vendor-details"
                  ? "black"
                  : "gray"
              } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Manage Vendor
              </p>
            </div>
            {/* <div
              onClick={() => navigate("/products")}
              className={`${
                splitLocation[1] === "vendor-management" ||
                splitLocation[1] === "vendor-details"
                  ? "bg-soft-gray1"
                  : ""
              } d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100`}
            >
              <p
                className={`
              ${
                splitLocation[1] === "vendor-management" ||
                splitLocation[1] === "vendor-details"
                  ? "black"
                  : "gray"
              } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Vendor Management
              </p>
            </div> */}
            <div
              onClick={() => navigate("/vendor-settings")}
              className={`d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100 ${
                splitLocation[1] === "vendor-settings" ? "bg-soft-gray1" : ""
              }`}
            >
              <p
                className={`ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 ${
                  splitLocation[1] === "vendor-settings" ? "black" : "gray"
                }`}
              >
                Vendor Settings
              </p>
            </div>
            {/* <div className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100">
              <p className=" ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Production Dashboard
              </p>
            </div> */}
          </div>
        )}
        <div
          onClick={() => {
            setCustomerDown(false);
            setProdManagement(!prodManagement);
            setEnableDown(false);
            setHelpDeskDown(false);
            setSiteManagement(false);
            setProduction(false);
            // navigate("/product-feed");
          }}
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
        >
          <WarehouseIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Product Management
          </p>
        </div>
        {prodManagement && (
          <div>
            <div
              onClick={() => {
                navigate("/product-feed");
              }}
              className={`d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100 ${
                splitLocation[1] === "product-feed" ? "bg-soft-gray1" : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "product-feed" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Feed
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/product-library");
              }}
              className={`d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100 ${
                splitLocation[1] === "product-library" ? "bg-soft-gray1" : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "product-library" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Product Library
              </p>
            </div>
            <div className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100">
              <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Pricebook Library
              </p>
            </div>
            {/* <div
              className={`d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100 ${
                splitLocation[1] === "decorative-method" ? "bg-soft-gray1" : ""
              }`}
              onClick={() => {
                navigate("/decorative-method");
              }}
            >
              <p
                className={`${
                  splitLocation[1] === "decorative-method" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Pre Production
              </p>
            </div> */}
            <div
              className={`d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100 ${
                splitLocation[1] === "category-setting" ? "bg-soft-gray1" : ""
              }`}
              onClick={() => {
                navigate("/category-setting");
              }}
            >
              <p
                className={`${
                  splitLocation[1] === "category-setting" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Category Setting
              </p>
            </div>
            <div
              className={`d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100 ${
                splitLocation[1] === "decorative-method" ? "bg-soft-gray1" : ""
              }`}
              onClick={() => {
                navigate("/decorative-method");
              }}
            >
              <p
                className={`${
                  splitLocation[1] === "decorative-method" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Decoration Method
              </p>
            </div>
            <div
              className={`d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100 ${
                splitLocation[1] === "hsn-settings" ? "bg-soft-gray1" : ""
              }`}
              onClick={() => {
                navigate("/hsn-settings");
              }}
            >
              <p
                className={`${
                  splitLocation[1] === "hsn-settings" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                HSN Settings
              </p>
            </div>
            <div
              className={`d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100 ${
                splitLocation[1] === "attributes" ? "bg-soft-gray1" : ""
              }`}
              onClick={() => {
                navigate("/attributes");
              }}
            >
              <p
                className={`${
                  splitLocation[1] === "attributes" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Attributes
              </p>
            </div>
          </div>
        )}
        <div
          onClick={() => {
            setCustomerDown(!customerDown);
            setProdManagement(false);
            setEnableDown(false);
            setHelpDeskDown(false);
            setSiteManagement(false);
            setProduction(false);
            navigate("/customer-list");
          }}
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
        >
          <GroupsIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            {/* Customers */}
            Sales
          </p>
        </div>
        {customerDown && (
          <div>
            <div
              onClick={() => {
                navigate("/customer-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "customer-list" ||
                splitLocation[1] === "view-customer-details"
                  ? "bg-soft-gray1"
                  : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "customer-list" ||
                  splitLocation[1] === "view-customer-details"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Customer List
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/customer-request-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "customer-request-list" ||
                splitLocation[1] === "view-request-details-customer" ||
                splitLocation[1] === "view-nonrequest-details"
                  ? "bg-soft-gray1"
                  : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "customer-request-list" ||
                  splitLocation[1] === "view-request-details-customer" ||
                  splitLocation[1] === "view-nonrequest-details"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Customer Request List
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/projects");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "projects" ? "bg-soft-gray1" : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "projects" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Projects
              </p>
            </div>
            {/* <div
              onClick={() => {
                navigate("/order-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "order-list" ||
                splitLocation[1] === "view-order-details"
                  ? "bg-soft-gray1"
                  : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "order-list" ||
                  splitLocation[1] === "view-order-details"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Order List
              </p>
            </div> */}
          </div>
        )}
        {/* <div
          onClick={() => {
            setCustomerDown(false);
            setProdManagement(false);
            setEnableDown(false);
            setHelpDeskDown(!helpDeskDown);
            navigate("/ecommerce-list");
          }}
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
        >
          <SupportAgentIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Help Desk
          </p>
        </div> */}
        <div
          onClick={() => {
            setCustomerDown(false);
            setProdManagement(false);
            setEnableDown(false);
            setHelpDeskDown(false);
            setSiteManagement(!siteManagement);
            setProduction(false);
            // navigate("/ecommerce-list");
          }}
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
        >
          <AccountTreeIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Site Managememnt
          </p>
        </div>
        {siteManagement && (
          <div>
            <div
              onClick={() => {
                navigate("/testimonials");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                (splitLocation[1] === "testimonials" ||
                  splitLocation[1] === "testimonials-detail" ||
                  splitLocation[1] === "testimonials-edit") &&
                "bg-soft-gray1"
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "testimonials" ||
                  splitLocation[1] === "testimonials-detail" ||
                  splitLocation[1] === "testimonials-edit"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Testimonials
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/banner-management");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                (splitLocation[1] === "banner-management" ||
                  splitLocation[1] === "banner-management-edit" ||
                  splitLocation[1] === "banner-management-detail") &&
                "bg-soft-gray1"
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "banner-management" ||
                  splitLocation[1] === "banner-management-edit" ||
                  splitLocation[1] === "banner-management-detail"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Banner Management
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/blog-management");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                (splitLocation[1] === "blog-management" ||
                  splitLocation[1] === "blog-management-edit" ||
                  splitLocation[1] === "blog-management-detail") &&
                "bg-soft-gray1"
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "blog-management" ||
                  splitLocation[1] === "blog-management-edit" ||
                  splitLocation[1] === "blog-management-detail"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Blog Management
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/special-offers");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                (splitLocation[1] === "special-offers" ||
                  splitLocation[1] === "special-offers-edit" ||
                  splitLocation[1] === "special-offers-detail") &&
                "bg-soft-gray1"
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "special-offers" ||
                  splitLocation[1] === "special-offers-edit" ||
                  splitLocation[1] === "special-offers-detail"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Special Offers
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/vendor-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                (splitLocation[1] === "vendor-list" ||
                  splitLocation[1] === "vendor-management-edit" ||
                  splitLocation[1] === "vendor-management-detail") &&
                "bg-soft-gray1"
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "vendor-list" ||
                  splitLocation[1] === "vendor-management-edit" ||
                  splitLocation[1] === "vendor-management-detail"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Vendor List
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/our-deals");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                (splitLocation[1] === "our-deals" ||
                  splitLocation[1] === "our-deals-view") &&
                "bg-soft-gray1"
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "our-deals" ||
                  splitLocation[1] === "our-deals-view"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Our Deals
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/join-with-us");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                (splitLocation[1] === "join-with-us" ||
                  splitLocation[1] === "join-with-us-view") &&
                "bg-soft-gray1"
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "join-with-us" ||
                  splitLocation[1] === "join-with-us-view"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Join with us
              </p>
            </div>
          </div>
        )}
        <div
          onClick={() => {
            setCustomerDown(false);
            setProdManagement(false);
            setEnableDown(false);
            setHelpDeskDown(false);
            setSiteManagement(false);
            setProduction(!production);
            // navigate("/ecommerce-list");
          }}
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
        >
          <CategoryIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Production
          </p>
        </div>
        {production && (
          <div>
            <div
              onClick={() => {
                navigate("/production-dashboard");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "production-dashboard" && "bg-soft-gray1"
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "production-dashboard" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                DashBoard
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/production");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "production" && "bg-soft-gray1"
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "production" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Production
              </p>
            </div>
          </div>
        )}
        <div className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100">
          <LogoutIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes gray f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Logout
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileNavBar;
