import { PointOfSale } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import {
  addres_list,
  department_list,
  phone_list,
  sub_menu,
} from "../../../redux/api/DummyJson";
import { uploadIcon } from "../../../assets/img";

const SalesOrderEmailUploadPopup = ({ poppupEmailToggle, setaddPoView }) => {
  const [dropDwon, setDropDown] = useState(undefined);

  const [department, setDepartment] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const navigate = useNavigate();
  const fileRef = useRef();
  const [file, setFile] = useState("");
  console.log(file?.type);

  // Function
  const onDepartment = (e) => {
    setDepartment(e.target.value);
    department.length === 0 ? setDropDown(undefined) : setDropDown(1);
  };
  return (
    <div className="modal-popup">
      <div onClick={poppupEmailToggle} className="back-close" />
      <div className=" center-content-contact2 w-90 w-md-55">
        <div className="w-100 d-flex ac-jb mt-4 px-md-5 px-3">
          <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
            Upload Email Proof
          </p>
          <div onClick={poppupEmailToggle} className="">
            <button class="cust-btn addbtn addbtn_save  text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">
              Cancel
            </button>
            {/* <button class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">save</button> */}
          </div>
        </div>
        <div className="mt-3 overflow-scroll p-3 ">
          <div className="w-100 px-4 px-md-5 ">
            <div className="w-100 d-flex flex-wrap justify-content-around align-items-center">
              <div>
                <div className=" as-jb my-2 flex-column flex-md-row">
                  <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary ">
                    Po Number
                  </p>
                  <input
                    placeholder="Required Field"
                    className="editBtn md_cust_w w-xl-100 w-lg-100 w-sm-100 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                </div>
                <div onClick={poppupEmailToggle} className=" mt-3">
                  <button class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 me-2 rounded">
                    Submit
                  </button>
                  <button class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">
                    Add
                  </button>
                </div>
              </div>
              <div className="content bg-primary1 p-2 mt-1 rounded">
                <div className="pointerView w-100  ac-jc">
                  <div
                    onClick={() => fileRef.current.click()}
                    className="w-100 d-flex "
                  >
                    {file === "" ? (
                      <img src={uploadIcon} className="mailImg" />
                    ) : (
                      <div style={{ width: "250px" }}>
                        <img
                          onClick={() => fileRef.current.click()}
                          src={file}
                          className=""
                          style={{ width: "100%", overflow: "hidden" }}
                        />
                      </div>
                    )}
                  </div>
                  {file === "" && (
                    <div
                      onClick={() => {
                        fileRef.current.click();
                      }}
                      className="w-100 d-flex "
                    >
                      <p className="dark text-center w-100 f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1  ">
                        Click here to Upload
                      </p>
                    </div>
                  )}
                  <div
                    onClick={poppupEmailToggle}
                    className="w-100 d-flex ac-jc "
                  >
                    {/* {file !== "" && (
                      <button
                        // onClick={() => setImportList(false)}
                        className="pointerView cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                      >
                        Submit
                      </button>
                    )} */}
                    {file !== "" && (
                      <button
                        onClick={() => {
                          fileRef.current.click();
                        }}
                        className="pointerView cust-btn addbtn mt-sm-3 ms-5 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                      >
                        ReUpload
                      </button>
                    )}
                  </div>
                </div>
                <input
                  type="file"
                  accept=".pdf,.doc,.jpeg,.docx,.png,.jpg,.xls,"
                  onChange={(e) => {
                    if (e?.target?.files?.length > 0) {
                      setFile(
                        window?.URL?.createObjectURL(e?.target?.files?.[0])
                      );
                    }
                  }}
                  className="d-none"
                  ref={fileRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesOrderEmailUploadPopup;
