import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import {
  Delete,
  Edit,
  Password,
  RemoveRedEye,
  Search,
} from "@mui/icons-material";
import ProjectDetailPopup from "../../Popup/ProjectDetailPopup";
import AddTaskPopup from "../../Popup/AddTaskPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link, useNavigate } from "react-router-dom";
import { salesForce } from "../../../redux/api/DummyJson";

const CollaborateRejectTableComp = () => {
  const navigate = useNavigate();

  return (
    <div className="presentaion_pages overflow-scroll mt-4 w-100">
      <div className="w-100 cust_table overflow-scroll table_border menu-table">
        <table>
          <thead className="py-3">
            <tr className="bg-soft-gray1">
              <th className="text-nowrap">S.No</th>
              <th>Collabrate ID</th>
              <th>Project ID</th>
              <th>Product</th>
              <th>Requested by</th>
              <th>Assigned</th>
              <th>Date & Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            <tr className="position-relative">
              <td>1</td>
              <td>-</td>
              <td>PRO001</td>
              <td>Shirt</td>
              <td>Sakthi</td>
              <td>Vishwa</td>
              <td>22/01/2024</td>
              <td className="px-0">
                <button className="editBtn rounded-3 my-2 px-3 py-2 bg-transparent fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Reject
                </button>
              </td>
              <td>
                <button
                  onClick={() => navigate("/view-collaborate")}
                  className="border-0 rounded-1 p-1 primary bg-transparent"
                >
                  <RemoveRedEye />
                </button>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default CollaborateRejectTableComp;
