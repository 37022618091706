import { configureStore } from "@reduxjs/toolkit";
import { rtkQueryErrorLogger } from "../slice/errorLogger";
import { api } from "../api/api";
import alertMessage from "../slice/alertMessage";
import loginHeader from "../slice/loginHeader";
import vendarnaviReducer from "../slice/NavigationSlice/vendarSlice";
import imageSave from "../slice/imageSave";
import saveshowproductionProof from "../slice/popupget";
import colorSize from "../slice/colorSize";
import loaderNoti from "../slice/loaderNoti";
//

const store = configureStore({
  reducer: {
    alertMessage: alertMessage,
    loginHeader: loginHeader,
    imageSave: imageSave,
    colorSize: colorSize,
    loaderNoti: loaderNoti,
    saveshowproductionProof: saveshowproductionProof,
    [api.reducerPath]: api.reducer,
    navigateSlice: vendarnaviReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
});

export default store;
