import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const AddDecorativePopup = ({
  toggleShowPopup,
  popupType,
  toggleImagePopup,
  croperImage,
}) => {
  return (
    <div className="add-category-popup">
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          {popupType === "edit" ? "Edit" : "Add"} Decorative Method
          <span>
            <button onClick={toggleShowPopup} className="cust-btn">
              <HighlightOffIcon />
            </button>
          </span>
        </p>

        <div className="d-flex ac-jc add-content">
          <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Method Name
            </p>
            <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
              <input
                className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Decorative Method"
              />
            </div>
          </div>
          <div
            className="w-50 d-flex ac-jb mt-4 flex-wrap"
            onClick={() => toggleImagePopup()}
          >
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Image (Optional)
            </p>
            <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
              <input
                className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                // placeholder="Search"
                // type="file"
                value={
                  croperImage ? "Image Uploaded Successfuly" : "Upload File"
                }
              />
            </div>
          </div>
        </div>

        <div className="d-flex mt-3 justify-content-end m-2">
          <button
            onClick={toggleShowPopup}
            className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDecorativePopup;
