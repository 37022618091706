import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { vendor_category } from "../../redux/api/DummyJson";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ErrorIcon from "@mui/icons-material/Error";

const AddVendorDetails2 = ({
  setVendorCat,
  vendorCat,
  categoryList,
  vendorName,
  primaryEmail,
  primaryMobile,
  primaryEmailErr,
  primaryMobileErr,
  vendorNameErr,
  vendorCategories,
  setVendorCategories,
  setPrimaryMobile,
  setPrimaryEmail,
  setVendorName,
  submitHandler,
  categoryName,
  setCategoryName,
  categoryNameErr,
  btn,
}) => {
  const [vendorCatShow, setVendorCatShow] = useState(false);
  const [vendorCatogory, setVendorCatogory] = useState("");

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const onClickVendorCat = (e, id) => {
    let temp = [...vendorCategories];
    let inx = temp.indexOf(id);
    if (vendorCategories?.includes(id)) {
      temp.splice(inx, 1);
    } else {
      temp.push(id);
    }

    setVendorCategories(temp);

    if (vendorCatogory.includes(e)) {
      const listtemp = vendorCatogory.indexOf(e);
      const list = [...vendorCatogory];
      list.splice(listtemp, 1);
      setVendorCatogory(list);
    } else {
      setVendorCatogory([...vendorCatogory, e]);
    }

    setCategoryName(e);
  };

  return (
    <>
      <div className="w-90 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Name*
          </p>
          <input
            placeholder=""
            onChange={(e) => setVendorName(e.target.value)}
            value={vendorName}
            className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
          {vendorName?.length == 0 && vendorNameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Vendor Name</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Email*
          </p>
          <input
            placeholder=""
            onChange={(e) => setPrimaryEmail(e.target.value)}
            value={primaryEmail}
            className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
          {validEmail.test(primaryEmail) == false && primaryEmailErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Primary Email</p>
            </div>
          )}
        </div>
      </div>
      <div className="w-90 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Number*
          </p>

          <input
            className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              if (e.target.value.length !== 10) {
                e.target.setCustomValidity("invalid Number");
              } else if (e.target.value.length == 10) {
                e.target.setCustomValidity("");

                setPrimaryMobile(e.target.value);
              }
            }}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
            maxLength={10}
            name="mobile"
            onChange={(e) => {
              setPrimaryMobile(e.target.value);
            }}
            type="number"
            value={primaryMobile}
          />
          {primaryMobile?.length == 0 && primaryMobileErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Primary Number</p>
            </div>
          )}
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Category*
          </p>
          <div className="position-relative">
            <input
              placeholder="Select Category"
              type="text"
              value={vendorCatogory}
              onClick={() => setVendorCatShow(!vendorCatShow)}
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
            <button
              className="drop_down cust-btn"
              onClick={() => setVendorCatShow(!vendorCatShow)}
            >
              <KeyboardArrowDownIcon />
            </button>
            {vendorCatShow && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setVendorCatShow(false)}
              />
            )}
            <div
              className={`${
                vendorCatShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {categoryList?.map((item, ind) => {
                return (
                  <button
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    onClick={() => {
                      onClickVendorCat(item?.name, item?.id);
                    }}
                    key={ind}
                  >
                    <button className="px-2 cust-btn text-start py-1 w-100 ">
                      {item?.name}
                    </button>
                    <button className="px-2 cust-btn text-start">
                      {vendorCatogory.includes(item?.name) ? (
                        <CheckCircleIcon className="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="primary" />
                      )}
                    </button>
                  </button>
                );
              })}
            </div>
          </div>
          {categoryName?.length == 0 && categoryNameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Vendor Category</p>
            </div>
          )}
        </div>
      </div>
      <div className="w-xl-90 w-lg-90 w-md-90 w-sm-90 w-xs-90 d-flex justify-content-end">
        <button
          className={`mail-btn greens border-0 rounded-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  p-2 text-center text-nowrap d-inline white f3 px-5 jc-ac ${
            btn && "opacity-50"
          }`}
          style={{ margin: "30px 0px 0px 40px" }}
          onClick={() => submitHandler()}
          disabled={btn ? true : false}
        >
          Send Mail
        </button>
      </div>
    </>
  );
};

export default AddVendorDetails2;
