import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { product } from "../assets/img";
import ImageCroper from "../components/Popup/ImageCroper";

const CustomerFiles = ({ list }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  return (
    <>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="text-center files_tab mt-3 f2 ">
        <label className="mt-2 f2" onClick={() => toggleImagePopup()}>
          Upload Files
        </label>
      </div>

      <div className="d-flex flex-wrap mt-4 mx-3 details_tab">
        <Col lg={5} xl={5} xs={5} className={"mx-3"}>
          <div className="files_img d-flex ac-jc">
            <img src={product} className="w-100" />
            <div className="files_btn">
              <button className="mx-2">Edit</button>
              {/* <button>Delete</button> */}
            </div>
          </div>
          <p className="text-center mt-3">T-Shirt</p>
        </Col>
        <Col lg={5} xl={5} xs={5} className={"mx-3"}>
          <div className="files_img d-flex ac-jc">
            <img src={product} className="w-100" />
            <div className="files_btn">
              <button className="mx-2">Edit</button>
              {/* <button>Delete</button> */}
            </div>
          </div>
          <p className="text-center mt-3">T-Shirt</p>
        </Col>
        {croperImage && (
          <Col lg={5} xl={5} xs={5} className={"mx-3"}>
            <div className="files_img d-flex ac-jc">
              <img src={croperImage} className="w-100" />
              <div className="files_btn">
                <button className="mx-2">Edit</button>
              </div>
            </div>
            <p className="text-center mt-3">T-Shirt</p>
          </Col>
        )}
      </div>
    </>
  );
};

export default CustomerFiles;
