import { useNavigate } from "react-router-dom";
import { editIcon, toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { Form } from "react-bootstrap";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import GetAppIcon from "@mui/icons-material/GetApp";
import { customerList, enterprisesList } from "../../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import ImportListPopup from "../Popup/ImportListPopup";

const RegisteredEnterprisesList = ({ tabData, setAddEnter }) => {
  const navigate = useNavigate();
  const [importList, setImportList] = useState(false);

  const showPopupHander = () => {
    setImportList(false);
  };

  return (
    <>
      {/* <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Registered Customer List
        </p>
        <div className="d-flex w-xs-100 mt-xs-5 position-relative">
          <input
            className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
          <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
        </div>
      </div> */}
      {importList && (
        <ImportListPopup
          showPopupHander={showPopupHander}
          setImportList={setImportList}
        />
      )}
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="w-xs-100 d-flex ac-jc">
          <div
            // onClick={() => setImportList(!importList)}
            className="pointerView d-flex  me-3"
          >
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2">
              Total Count : {"07"}
            </p>
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div
            onClick={() => setImportList(!importList)}
            className="pointerView d-flex  me-3"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Import
            </p>
          </div>
          <div className="pointerView d-flex  me-3">
            <ListAltRoundedIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Download List
            </p>
          </div>
          <button
            className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14  rounded"
            // onClick={() => setAddEnter(true)}
            onClick={() => navigate("/add-enterprises")}
          >
            + Add Enterprises
          </button>
        </div>
      </div>

      <div className="mt-3" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.no
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Enterprise ID
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Enterprise Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Contact Name and Email ID
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Order Rep Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Client Rep Name
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {enterprisesList?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <p
                      className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p
                      className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {item?.CustomerId}
                    </p>
                  </td>
                  <td>
                    <p
                      className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {item?.name}
                    </p>
                  </td>

                  <td className="d-flex flex-column">
                    <p
                      className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {item?.name}
                    </p>
                    <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.email}
                    </p>
                  </td>
                  <td>
                    <p
                      className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {item?.name}
                    </p>
                  </td>
                  <td>
                    <p
                      className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {item?.lname}
                    </p>
                  </td>
                  <td>
                    <div className="d-flex ac-jc">
                      {item?.status === true && (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      )}
                      {item?.status === false && (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex ac-jc primary1">
                      <div
                        onClick={() =>
                          // navigate("/view-customer-details", { state: { data: item } })
                          tabData(item)
                        }
                        className="viewBoxDes pointerView p-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                      <div
                        className="viewBoxDes ms-2 p-2 bg-white rounded-2"
                        onClick={() =>
                          navigate(`/enterprises-view?name=${item?.name}`)
                        }
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default RegisteredEnterprisesList;
