import { useNavigate } from "react-router-dom";
import { toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { Form } from "react-bootstrap";

import { customerList } from "../../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { searchIcon } from "../../assets/img";

const RegisteredCustomerList = ({ tabData }) => {
  const navigate = useNavigate();

  return (
    <>
      {/* <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Registered Customer List
        </p>
        <div className="d-flex w-xs-100 mt-xs-5 position-relative">
          <input
            className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
          <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
        </div>
      </div> */}
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex ac-je flex-md-row flex-column w-100 ">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-3">
            Total Count : {"07"}
          </p>
          <div className="pointerView d-flex mt-sm-3 mt-0 me-3">
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}>
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search border">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          {/* <button
            // onClick={() => toggleShowPopup("add")}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add E-Commerce List
          </button> */}
        </div>
      </div>

      <div className=" d-flex mt-5 border-bottom w-100">
        <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          S.no
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          E-Commerce ID
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          First Name
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Last Name
        </p>
        <p className="w-25 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Email
        </p>
        <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Status
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Options
        </p>
      </div>
      {customerList?.map((item, index) => {
        return (
          <div className=" d-flex mt-5">
            <p
              className="pointerView   w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
              onClick={() => tabData(item)}>
              {index + 1}
            </p>
            <p
              className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
              onClick={() => tabData(item)}>
              {item?.CustomerId}
            </p>
            <p
              className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
              onClick={() => tabData(item)}>
              {item?.name}
            </p>
            <p
              className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
              onClick={() => tabData(item)}>
              {item?.lname}
            </p>

            <p className="w-25 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.email}
            </p>
            <div className="w-10 d-flex ac-jc">
              {item?.status === true && (
                <div>
                  <img src={toggleOn} className="toggleOnDes" />
                </div>
              )}
              {item?.status === false && (
                <div>
                  <img src={toggleOff} className="toggleOnDes" />
                </div>
              )}
              {/* <Form.Check
                type="switch"
                id="toggle-switch"
                label=""
                checked={item?.status === true ? true : false}
              /> */}
            </div>
            <div className="w-15 d-flex ac-jc primary1">
              <div
                onClick={() =>
                  // navigate("/view-customer-details", { state: { data: item } })
                  tabData(item)
                }
                className="viewBoxDes pointerView p-2 bg-white rounded-2">
                <img src={viewIcon} className="viewDes" />
              </div>
              {/* <div className="viewBoxDes ms-2 p-2 bg-white rounded-2">
                <img src={editIcon} className="viewDes" />
              </div> */}
            </div>
          </div>
        );
      })}
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default RegisteredCustomerList;
