import React from "react";
import { head, logos } from "../assets/img";
import { banner } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";

const POProductSupplierA = () => {
  const navigate=useNavigate();
  return (
    <div className=".bg-white" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-4 banner col-md-4"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="p-2 ms-2  font-blue2" >
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                MerchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-10 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Approval for 5SK supplier
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                PURCHASE ORDER #330070
              </p>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div
          className="d-flex flex-wrap  bg-primary2  w-100  mt-4  me-3 p-4"
          style={{
            // backgroundColor: "#ecfaff",
            borderRadius: "20px",
            lineHeight: "35px",
            flexWrap: "wrap",
          }}
        >
          <div className="col-md-3 mt-sm-2 p-2 ">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                SHIP DATE REQUIRED
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                To Meet the IHD
              </p>
            </div>
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                IN HANDS DATE
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Date 10,2024
              </p>
            </div>
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                SHIPPING METHOD
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Same Day Ship on Supplier Account
              </p>
            </div>
          </div>
          <div className="col-md-3 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              SHIPPPING ADDRESS
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-70">
              5SK Designer Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
              NADU NINOS 125, INDIA
            </p>
          </div>
          {/* <div className="ryt-line"
            style={{
              height: "150px",
              width: "1px",
              backgroundColor: "#000",
            }}
          ></div> */}
          <div className="col-md-2 ps-2 mt-sm-2 p-2">
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                PROJECT #
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                2134567
              </p>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                CUSTOMER PO
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                5SK Supplier
              </p>
            </div>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              PO #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              1213123-9878976
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              ACCOUNT #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              777777777
            </p>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              PO DATE
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              FEB 4,2024
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              PHONE #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              798687406
            </p>
          </div>
        </div>
        <div
          class=" p-4 mt-4 overflow-scroll bg-primary2"
          style={{  borderRadius: "20px" }}
        >
          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 mb-3">
            301WH1026 - Stainless Steel And hot N Cold bottle
          </p>
          <table className="w-100 ">
            <thead>
              <tr>
                <th className="f3 col-md-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                  SKU
                </th>
                <th className="f3 col-md-6  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                  ITEM
                </th>
                <th>
                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-3">
                    QTY
                  </p>
                </th>
                <th>
                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                    UNIT COST
                  </p>
                </th>
                <th>
                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                    TOTAL COST
                  </p>
                </th>
              </tr>
            </thead>
            <tbody style={{ lineHeight: "30px" }}>
              <tr>
                <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    301WH1026
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                    301WH1026
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    301WH1026
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Size: TBD - Color: TBD
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                    Size: M - Color: Blue
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Size: TBD - Color: TBD
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    300
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    10
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    0
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,987,9879.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,3479.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,4569.00
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,987,9879.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,3479.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,4569.00
                  </p>
                </td>
              </tr>

              <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                ITEM COST
              </p>

              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Fixed pre Charges
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                    RS15.0000
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.00
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Run Charges
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    310
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS10.0000000
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS31.002796
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Fixed Charges
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                    RS10.0000
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS10.00
                  </p>
                </td>
              </tr>

              <tr
                className="m-2"
                style={{
                  borderBottom: "0px",
                  borderTop: "1px",
                  borderRight: "0",
                  borderColor: "#787b7f",
                  borderStyle: "solid",
                  borderLeft: 0,
                }}
              >
                <td>
                  <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                    TOTAL
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    310
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                </td>
                <td>
                  <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                    RS348,332.00
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="d-flex mt-4 ps-3 ac-jb" style={{ flexWrap: "wrap" }}>
          <div className="col-md-5 " style={{ lineHeight: "25px" }}>
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 primary mb-2">
              Questions About this estimate?
            </p>
            <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 ">
              Roger Daniel
            </h2>
            <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 blue1 mb-2">
              roger@companystore.io
            </a>
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              merchHQ Pvt.Ltd.
            </p>
            <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-40">
              #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
              Kartnataka, India.
            </p>
          </div>
          <div
            className="d-flex ac-jb col-md-7 px-4 white"
            style={{
              backgroundColor: "#0082ca",
              borderRadius: "20px",
              padding: "30px 10px",
              margin: "20px 0px 10px",
              lineHeight: "30px",
            }}
          >
            <div>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TOTAL UNITS ORDERED
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TOTAL UNIT COSTS
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TOTAL FIXED PRE CHARGES:
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TOTAL FIXED CHARGES:
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TOTAL RUN CHARGES:
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-2">
                ORDER TOTAL
              </p>
            </div>
            <div className="text-end">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                310
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Rs9,5467,865.15
              </p>

              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                RS 15.00
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                RS 10.00
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                RS 31,0005.00
              </p>
              <hr style={{ margin: "1px", color: "#fff" }} />
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-2">
                RS 7,531,0005.00
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex my-2 justify-content-end w-100">
          <div>
            <button
              className=""
              style={{
                backgroundColor: "#f82a2a",
                borderRadius: "10px",
                padding: "10px",
                border:"none"
              }}
            >
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white" onClick={()=>{
                navigate("/supplier-po-request-a");
              }}>
                Request Change
              </p>
            </button>
          </div>
          <div>
            <button
              style={{
                backgroundColor: "#0082ca",
                borderRadius: "10px",
                padding: "10px",
                border:"none",
                marginLeft:"5px",
              }}
            >
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white " onClick={()=>{
                navigate("/supplier-po-request-a");
              }}>
                Approve Order
              </p>
            </button>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#e5e5e5 ", padding: "20px" }}>
        <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 primary w-90 m-auto">
          TERMS AND CONDITIONS
        </h2>
        <div
          className="d-flex ac-jb w-90 mt-3 flex-wrap  px-4"
          style={{
            backgroundColor: "#fff",
            margin: "auto",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <button
            class=" d-flex ps-3 mt-sm-2"
            style={{
              backgroundColor: "#07679c",
              border: "none",
              borderRadius: "7px",
              padding: "5px",
              color: "#fff",
            }}
          >
            <UnsubscribeIcon />

            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
              Contact us
            </p>
          </button>
          <div className="m-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary mt-sm-2">
              Powered By mHQ
            </p>
          </div>

          <div class="d-flex align-items-center primary mt-sm-2">
          <FacebookRoundedIcon className="me-2"/>

<TwitterIcon className="mx-2"/>

<LinkedInIcon className="mx-2"/>

<InstagramIcon className="mx-2"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POProductSupplierA;
