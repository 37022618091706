import React, { useState } from "react";
import { searchIcon } from "../../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { event_category } from "../../redux/api/DummyJson";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import Inprocess from "../../components/POallRequest/Inprocess";
import Accepted from "../../components/POallRequest/Accepted";
import Rejected from "../../components/POallRequest/Rejected";
import PoNotesViewPopup from "../../components/Popup/PoNotesViewPopup";
import POoverAllRequestScreen from "./POoverAllRequestScreen";
import POAllVendorScreen from "../POAllVendorScreen";

const POAllScreen = () => {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("Admin");

  const navigate = useNavigate();
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const onShowPopupHander = () => {
    setShow(!show);
  };

  return (
    <div className="dashRightView  home_section trans">
      {show && <PoNotesViewPopup onShowPopupHander={onShowPopupHander} />}
      <div className="">
        <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2">
          Overall PO Request List
        </p>
        <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-1 3 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">10</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex  ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-4 rk">
          <button
            onClick={() => onTabClick("Admin")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Admin"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Admin" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Admin
            </p>
          </button>
          <button
            onClick={() => onTabClick("Vendor")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Vendor"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Vendor" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Vendor
            </p>
          </button>
        </div>
      </div>
      {activeTab === "Admin" && <POoverAllRequestScreen />}
      {activeTab === "Vendor" && <POAllVendorScreen />}
    </div>
  );
};

export default POAllScreen;
