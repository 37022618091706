import { Search } from '@mui/icons-material'
import React from 'react'
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";


const ProductSearchHeader = () => {
    return (
        <div className="d-flex w-95 searchField p-2">
            <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
            <input
                className="cust-btn ms-4 w-100"
                placeholder="Enter Project Name, Client Name, PO# to search"
            />
            <img src={manImage} className="searchManImg" />
        </div>
    )
}

export default ProductSearchHeader