import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import SalesTasks from "../components/Sales/SalesTasks";
import SalesProgress from "../components/Sales/SalesProgress";
import CollaborateRequest from "../components/Sales/collaborate/CollaborateRequest";
import CollaborateRequestTableComp from "../components/Sales/collaborate/CollaborateRequestTableComp";
import CollaborateAcceptedTableComp from "../components/Sales/collaborate/CollaborateAcceptedTableComp";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import CollaborateRejectTableComp from "../components/Sales/collaborate/CollaborateRejectTableComp";
import { Search } from "@mui/icons-material";
import { searchIcon } from "../assets/img";

const CollaborateScreen = () => {
  const [activeTab, setActiveTab] = useState("sales");
  const location = useLocation();

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };
  // useEffect(() => {
  //   if (location?.state === "accepted") {
  //     setActiveTab("tasks");
  //   }
  // });
  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <div className="d-flex ac-jb flex-wrap rk2">
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary ">
          Collaborate
        </p>
        {/* <button className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          + Add Project
        </button> */}
      </div>

      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-4 rk">
          <button
            onClick={() => onTabClick("sales")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "sales"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "sales" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Requested
            </p>
          </button>
          <button
            onClick={() => onTabClick("tasks")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "tasks"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "tasks" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Accepted
            </p>
          </button>
          <button
            onClick={() => onTabClick("progress")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "progress"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "progress" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Rejected
            </p>
          </button>
          {/* <button
            onClick={() => onTabClick("progress")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "closed"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "closed" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Closed
            </p>
          </button> */}
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 mt-4 px-3">
            Total Count : {activeTab === "tasks" ? "05" : "01"}
          </p>
          <div className="d-flex mt-sm-3 border-search mx-1">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      {/* {activeTab === "sales" && <CollaborateRequest />}
      {activeTab === "tasks" && <CollaborateRequest />}
      {activeTab === "progress" && <CollaborateRequest />}
      {activeTab === "closed" && <CollaborateRequest />} */}
      {activeTab === "sales" && <CollaborateRequestTableComp />}
      {activeTab === "tasks" && <CollaborateAcceptedTableComp />}
      {activeTab === "progress" && <CollaborateRejectTableComp />}
    </div>
  );
};

export default CollaborateScreen;
