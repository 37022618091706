import { Search } from "@mui/icons-material";
import React from "react";
import CachedIcon from "@mui/icons-material/Cached";

const ProductDropdownTap = ({ location, products }) => {
  return (
    <div className="mt-5 banner-left">
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className="d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
          <input
            type="text"
            className="get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3 w-auto fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            placeholder='Search Product Name'
          />
        </div>
        <div className="d-flex ac-jc gap-3">
          <button
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
          >
            <Search />
          </button>
          <button
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
          >
            <CachedIcon />
          </button>
        </div>
      </div>
      {/* <div className="mt-md-3  mt-4">
        <p class=" f2 primary1 mb-0">
          Your Hourglass Essentials Pvt. Ltd. database contains products that
          you or your co-workers have added directly into commonsku. These
          products will often be Hourglass Essentials Pvt. Ltd.'s go-to
          products, preferred supplier products, or local decorators that may
          not be on commonsku otherwise.
        </p>
        <p class=" f2 primary1 mb-0">
          Enter some criteria to search for products.
        </p>
      </div> */}
    </div>
  );
};

export default ProductDropdownTap;
