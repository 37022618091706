import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { product } from "../assets/img";

const CustomerNote = ({ list }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [add, setAdd] = useState(false);
  return (
    <>
      <div className="mt-4 mx-3 details_tab">
        <div className="d-flex justify-content-between">
          <Col md={12} lg={8}>
            {add && (
              <textarea
                placeholder="text here..."
                className="w-100 rounded-3 p-2 w-70 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 editBtn"
              />
            )}
          </Col>
          <Col md={12} lg={4}>
            {!add ? (
              <button
                className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                onClick={() => setAdd(true)}
              >
                Add Notes
              </button>
            ) : (
              <button
                className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                onClick={() => setAdd(false)}
              >
                Submit
              </button>
            )}
          </Col>
        </div>
        <div className="editBtn rounded-3 cust_dis_h p-2 w-100 text mt-3">
          <p className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      </div>
    </>
  );
};

export default CustomerNote;
