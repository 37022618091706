import React, { useRef, useState } from "react";
import {
  Bottle,
  head,
  logos,
  tshirt1,
  tshirt2,
  tshirt3,
  uploadIcon,
} from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Navigate, useNavigate } from "react-router";
import StarIcon from "@mui/icons-material/Star";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

const FeedbackA = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [ratinggs, setRatinggs] = useState(0);
  const [hoverr, setHoverr] = useState(0);
  const [ratingss, setRatingss] = useState(0);
  const [hoverrr, setHoverrr] = useState(0);
  const [image, setImage] = useState(null);
  const [filename, setFileName] = useState("No Selected File");
  const [images, setImages] = useState(null);
  const [filenames, setFileNames] = useState("No Selected File");
  const [imagess, setImagess] = useState(null);
  const [filenamess, setFileNamess] = useState("No Selected File");
  const [imagesss, setImagesss] = useState(null);
  const [filenamesss, setFileNamesss] = useState("No Selected File");
  return (
    <div className="bg-white " style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="ps-2 ms-2 font-blue2">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                FEEDBACK for 5SK -Project #210223
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Oct 25,2023
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div className="mt-3">
          <p className="f1 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 my-2          ">
            Please Rate your experience in merchHQ Pvt.Ltd.:
          </p>
          <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={index <= (hover || rating) ? "on" : "off"}
                  onClick={() => setRating(index)}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(rating)}
                >
                  <span className="star" style={{ fontSize: "25px" }}>
                    &#9733;
                  </span>
                </button>
              );
            })}
          </div>
        </div>
        <div className="mt-3">
          <p className="f1 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ">
            Please share any feedback regarding your experience with our
            Customer Care Team!
          </p>
          <div className="w-100">
            <textarea
              className="editBtn bg-primary2 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              style={{ height: "100px"}}
            ></textarea>
          </div>
        </div>
        <div className="d-flex flex-wrap my-3">
          <div className="col-md-3">
            <img src={Bottle} alt="" className="feedimg rounded"></img>
          </div>
          <div className="col-md-9">
            <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
              Stainless Steel Hot N Cold Bottle
            </h2>
            <p className="f1 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 my-2">
              How would you rate this product?
            </p>
            <div className="star-rating">
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={index <= (hoverr || ratinggs) ? "on" : "off"}
                    onClick={() => setRatinggs(index)}
                    onMouseEnter={() => setHoverr(index)}
                    onMouseLeave={() => setHoverr(ratinggs)}
                  >
                    <span className="star" style={{ fontSize: "25px" }}>
                      &#9733;
                    </span>
                  </button>
                );
              })}
            </div>
            <div className="w-100">
              <textarea
                className="editBtn bg-primary2 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                style={{ height: "100px" }}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap my-4">
          <div className="col-md-3">
            <img src={tshirt1} alt="" className="feedimg rounded"></img>
          </div>
          <div className="col-md-9">
            <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2 ">
              Navy Blue Play dry T-shirts with white tipping.
            </h2>
            <p className="f1 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 my-2 ">
              How would you rate this product?
            </p>
            <div className="star-rating">
              {[...Array(5)].map((star, index1) => {
                index1 += 1;
                return (
                  <button
                    type="button"
                    key={index1}
                    className={index1 <= (hoverrr || ratingss) ? "on" : "off"}
                    onClick={() => setRatingss(index1)}
                    onMouseEnter={() => setHoverrr(index1)}
                    onMouseLeave={() => setHoverrr(ratingss)}
                  >
                    <span className="star" style={{ fontSize: "25px" }}>
                      &#9733;
                    </span>
                  </button>
                );
              })}
            </div>
            <div className="w-100">
              <textarea
                className="bg-primary2 rounded border-0 editBtn w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                style={{ height: "100px" }}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="my-4 bg-primary2 rounded p-3 py-4">
          <div className="">
            <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
              We love pictures!
            </h2>
            <p className="f1 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 my-2 ">
              If you have any photos of the product in action, please submit
              them here.
            </p>
          </div>
          {/* <div
            className="position-relative"
            onClick={() => !fistShow && dualToggele("product")}
          >
            <img
              className={"img_up_cont"}
              src={bigImage?.image ? bigImage?.image : uploadIcon}
            />
            {!textShow && (
              <div>
                <ul
                  className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                >
                  <li className="set-default-image">Set&nbsp;Default</li>
                  {smallimage?.length > 1 && (
                    <li
                      className="set-default-image"
                      onClick={() => removeImg(bigImage?.ind, "product")}
                    >
                      Remove
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div> */}
          {/* <div className="pointerView w-100 mt-5">
          <div
            onClick={() => fileRef.current.click()}
            className="w-100 d-flex ac-jc"
          >
            <img src={uploadIcon} className="uploadImgLib" />
          </div>
          <div className="w-100 d-flex ac-jc">
            <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
              Drag and drop to upload or Click
            </p>
          </div>
          <div className="w-100 d-flex ac-jc">
            <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
              here to upload
            </p>
          </div>
        </div> */}

          <div className="d-flex ac-jc flex-wrap mt-3">
            <div className="col-md-3 px-2">
              <div
                className="bar d-flex ac-jc rounded bg-white border-0 p-3 py-4 m-1 "
                action=""
                onClick={() => document.querySelector(".input-field").click()}
              >
                <input
                  style={{ cursor: "pointer" }}
                  type="file"
                  accept="image/*"
                  className="input-field mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  hidden
                  onChange={({ target: { files } }) => {
                    files[0] && setFileName(files[0].name);
                    if (files) {
                      setImage(URL.createObjectURL(files[0]));
                    }
                  }}
                />
                <div className="text-center ">
                  {image ? (
                    <img src={image} width={150} height={150} alt={filename} />
                  ) : (
                    <>
                      {" "}
                      <CloudUploadOutlinedIcon style={{width:"80px",height:"80px",color:"#9dc0d12b ",}} />
                      <p className="f2 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                        Choose Files
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className=" col-md-3 px-2">
            <div
                className="bar d-flex ac-jc rounded bg-white border-0 p-3 py-4 m-1 "
                action=""
                onClick={() => document.querySelector(".input-fields").click()}
              >
                <input
                  style={{ cursor: "pointer" }}
                  type="file"
                  accept="image/*"
                  className="input-fields mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  hidden
                  onChange={({ target: { files } }) => {
                    files[0] && setFileNames(files[0].name);
                    if (files) {
                      setImages(URL.createObjectURL(files[0]));
                    }
                  }}
                />
                <div className="text-center ">
                  {images ? (
                    <img src={images} width={150} height={150} alt={filenames} />
                  ) : (
                    <>
                      {" "}
                      <CloudUploadOutlinedIcon style={{width:"80px",height:"80px",color:"#9dc0d12b ",}} />
                      <p className="f2 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                       Choose Files
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-3 px-2">
            <div
                className="bar d-flex ac-jc rounded bg-white border-0 p-3 py-4 m-1 "
                action=""
                onClick={() => document.querySelector(".input-fieldss").click()}
              >
                <input
                  style={{ cursor: "pointer" }}
                  type="file"
                  accept="image/*"
                  className="input-fieldss mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  hidden
                  onChange={({ target: { files } }) => {
                    files[0] && setFileNamess(files[0].name);
                    if (files) {
                      setImagess(URL.createObjectURL(files[0]));
                    }
                  }}
                />
                <div className="text-center ">
                  {imagess ? (
                    <img src={imagess} width={150} height={150} alt={filenamess} />
                  ) : (
                    <>
                      {" "}
                      <CloudUploadOutlinedIcon style={{width:"80px",height:"80px",color:"#9dc0d12b ",}}/>
                      <p className="f2 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                        Choose Files
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-3 px-2">
            <div
                className="bar d-flex ac-jc rounded bg-white border-0 p-3 py-4 m-1 "
                action=""
                onClick={() => document.querySelector(".input-fieldsss").click()}
              >
                <input
                  style={{ cursor: "pointer" }}
                  type="file"
                  accept="image/*"
                  className="input-fieldsss mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  hidden
                  onChange={({ target: { files } }) => {
                    files[0] && setFileNamesss(files[0].name);
                    if (files) {
                      setImagesss(URL.createObjectURL(files[0]));
                    }
                  }}
                />
                <div className="text-center ">
                  {imagesss ? (
                    <img src={imagesss} width={150} height={150} alt={filenamesss} />
                  ) : (
                    <>
                      {" "}
                      <CloudUploadOutlinedIcon  style={{width:"80px",height:"80px",color:"#9dc0d12b ",}}/>
                      <p className="f2 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                        Choose Files
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className="mt-3 d-flex flex-wrap ac-jb px-5"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="ms-5">
            <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
              Roger Daniel
            </h2>
            <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
              roger@companystore.io
            </a>
            <button
              class=" d-flex ac-jc ps-3 mt-4"
              style={{
                backgroundColor: "#0082ca",
                border: "none",
                borderRadius: "7px",
                padding: "5px",
                color: "#fff",
              }}
            >
              <UnsubscribeIcon />

              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                Contact us
              </p>
            </button>
          </div>
          <div>
            <button className="bg-white rounded primary my-4 p-2 px-3 f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 border-0">
              <PlaylistAddCheckIcon />
              Send FeedBack
            </button>
          </div>
          <div className="col-md-3 me-4">
            <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
              merchHQ Pvt.Ltd.
            </p>
            <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
              #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
              Kartnataka, India.
            </p>
            <div class="d-flex white mt-sm-2 ">
              <FacebookRoundedIcon className="me-2"/>

              <TwitterIcon className="mx-2"/>

              <LinkedInIcon className="mx-2"/>

              <InstagramIcon className="mx-2"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackA;
