import React, { useEffect, useState } from "react";
import { product, uploadIcon } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { productDetailImg } from "../../redux/api/constants";
import ImageCroper from "../Popup/ImageCroper";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const ImageUplodeComp = ({ vendor, vendor2, selColors }) => {
  const navigate = useNavigate();
  const [colorImg, setColorImg] = useState(null);
  const [smallimage, setSmallImage] = useState([]);
  const [smallMockimage, setSmallMockImage] = useState([]);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });
  const [mockImage, setMockImage] = useState({ image: "", ind: "" });
  const [fistShow, setFirstShow] = useState(false);
  const [fistShows, setFirstShows] = useState(false);
  const [croperCol, setCroperCol] = useState(0);
  console.log(vendor);
  const [logo, setLogo] = useState({});
  const [logo1, setLogo1] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [textShows, setTextShows] = useState(true);
  console.log(vendor2);

  const onColorImgUpload = (e) => {
    setColorImg(e);
  };

  const imageSelector = (item, ind, type) => {
    if (type == "mock") {
      setMockImage({ image: item, ind: ind });
    } else if (type == "product") {
      setBigImage({ image: item, ind: ind });
    }
  };

  const dualToggele = (type) => {
    if (type == "color") {
      setCroperCol(1);
      toggleImagePopup();
    } else if (type == "product") {
      setCroperCol(0);
      toggleImagePopup();
    } else {
      setCroperCol(2);
      toggleImagePopup();
    }
  };

  useEffect(() => {
    if (croperCol == 0 && croperImage) {
      setTextShow(false);
      setFirstShow(true);

      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallImage(temp);
      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    } else if (croperCol == 1 && croperImage) {
      onColorImgUpload(croperImage);
    } else if (croperCol == 2 && croperImage) {
      setTextShows(false);
      setFirstShows(true);

      let temp = [...smallMockimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallMockImage(temp);
      setMockImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    }
  }, [croperImage]);

  const removeImg = (ind, type) => {
    if (type == "product") {
      let temp = [...smallimage];

      temp.splice(ind, 1);
      setSmallImage(temp);
      if (temp?.length - 1 === 0) {
        setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      } else {
        setBigImage({ image: temp[ind - 1], ind: ind - 1 });
      }
    } else if (type == "mock") {
      let temps = [...smallMockimage];

      temps.splice(ind, 1);
      setSmallMockImage(temps);
      if (temps?.length - 1 === 0) {
        setMockImage({ image: temps[temps.length - 1], ind: temps.length - 1 });
      } else {
        setMockImage({ image: temps[ind - 1], ind: ind - 1 });
      }
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  return (
    <div className="d-flex gap-3 ac-jc w-100 res-img">
      {(vendor === "Charles" || vendor === "Jake Adams") && (
        <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Product Image
          </p>
          <div>
            <div className="">
              <div
                className="position-relative"
                onClick={() => !fistShow && dualToggele("product")}
              >
                <img
                  className={"img_up_cont"}
                  src={bigImage?.image ? bigImage?.image : uploadIcon}
                />
                {!textShow && (
                  <div>
                    <ul
                      className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                    >
                      <li className="set-default-image">Set&nbsp;Default</li>
                      {smallimage?.length > 1 && (
                        <li
                          className="set-default-image"
                          onClick={() => removeImg(bigImage?.ind, "product")}
                        >
                          Remove
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="d-flex ac-jc gap-3 w-100">
                {fistShow && (
                  <div
                    class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                    style={{ width: "300px", overflow: "scroll" }}
                  >
                    {smallimage?.map((item, ind) => {
                      return (
                        <div className="gap-3 d-flex ac-j w-100">
                          <div
                            class="add_img d-flex ac-jc"
                            onClick={() => imageSelector(item, ind, "product")}
                          >
                            {" "}
                            <img
                              src={item}
                              class="cp add_img object-fit-fill"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {smallimage?.length > 0 && (
                  <label
                    className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                      smallimage?.length == 0 && "mt-3"
                    }`}
                    onClick={() => dualToggele("product")}
                  >
                    +
                  </label>
                )}
              </div>
            </div>
          </div>

          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Color Image
          </p>
          <div
            className="d-flex gap-3 mt-3 ac-jc flex-wrap"
            style={{ width: "350px" }}
          >
            {selColors?.map((item) => {
              return (
                <div className="d-flex flex-column ac-jc">
                  {colorImg ? (
                    <div className="position-relative for_hover">
                      <img
                        alt="icon"
                        src={colorImg}
                        className="add_img object-fit-fill"
                      />
                      <div className="d-flex w-100 justify-content-between position-absolute in_hover text-white">
                        <FileDownloadOutlinedIcon />
                        <ClearOutlinedIcon />
                      </div>
                    </div>
                  ) : (
                    <label
                      className="add_img d-flex ac-jc cp f3 primary fs-3"
                      onClick={() => dualToggele("color")}
                    >
                      +
                    </label>
                  )}
                  <p className="f3" style={{ color: item }}>
                    {item}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {(vendor === "Charles" || vendor === "Jake Adams") && (
        <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Mockup Image
          </p>

          <div>
            {" "}
            <div className="">
              <div
                className="position-relative"
                onClick={() => !fistShows && dualToggele("mock")}
              >
                <img
                  className={"img_up_cont"}
                  src={mockImage?.image ? mockImage?.image : uploadIcon}
                />
                {!textShows && (
                  <div>
                    <ul
                      className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                    >
                      <li className="set-default-image">Set&nbsp;Default</li>
                      {smallMockimage?.length > 1 && (
                        <li
                          className="set-default-image"
                          onClick={() => removeImg(mockImage?.ind, "mock")}
                        >
                          Remove
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="d-flex ac-jc gap-3 w-100">
                {fistShows && (
                  <div
                    class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                    style={{ width: "300px", overflow: "scroll" }}
                  >
                    {smallMockimage?.map((item, ind) => {
                      return (
                        <div className="gap-3 d-flex ac-j w-100">
                          <div
                            class="add_img d-flex ac-jc"
                            onClick={() => imageSelector(item, ind, "mock")}
                          >
                            {" "}
                            <img
                              src={item}
                              class="cp add_img object-fit-fill"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {smallMockimage?.length > 0 && (
                  <label
                    className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                      smallMockimage?.length == 0 && "mt-3"
                    }`}
                    onClick={() => dualToggele("mock")}
                  >
                    +
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {(vendor === "John Rolph" || vendor === "Jake Adams") && (
        <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Decoration Image
          </p>
          <div className="file-upload" style={{ cursor: "pointer" }}>
            <div className="file-icon d-flex ac-jc">
              <div className="d-flex flex-column ac-jc">
                <label className="upload-btn">
                  <img
                    className={
                      logo?.name
                        ? "placeholder_icon1 object-fit-contain"
                        : "placeholder_icon object-fit-contain"
                    }
                    src={
                      logo?.name ? window.URL.createObjectURL(logo) : uploadIcon
                    }
                    alt="logo"
                  />
                  {textShow ? (
                    <p className="fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 text-center">
                      Drag and drop to upload or Click here to upload
                    </p>
                  ) : null}
                  <input
                    onChange={(e) => {
                      setLogo(e.target.files[0]);
                      setTextShow(false);
                    }}
                    className="d-none"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                  />
                </label>
                {/* <p class="f2 text-dark mb-2 mb-lg-3 text-center fs-13 fs-md-14 fs-lg-15 fs-xl-16 w-70">Drag and drop to upload or Click here to upload</p> */}
              </div>
            </div>
          </div>
          <div className="btn-w-cust-cont mt-4">
            <button
              class="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded w-100"
              onClick={() => navigate(-1)}
            >
              Save
            </button>
          </div>
        </div>
      )}
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      {/* <div>
                <h3>Owl Carocle</h3>
                <OwlCarousel  {...productDetailImg}>
                    <div className='owl-corocle bg-danger w-100'></div>
                </OwlCarousel>
            </div> */}
    </div>
  );
};

export default ImageUplodeComp;
