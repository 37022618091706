import { PointOfSale } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import {
  addres_list,
  department_list,
  phone_list,
  sub_menu,
} from "../../../redux/api/DummyJson";
import { uploadedImg, uploadIcon } from "../../../assets/img";

const SalesOrderPoViewPopup = ({ poppupViewToggle, setaddPoView }) => {
  const [dropDwon, setDropDown] = useState(undefined);

  const [department, setDepartment] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const navigate = useNavigate();
  const fileRef = useRef();
  const [file, setFile] = useState("");
  console.log(file?.type);

  // Function
  const onDepartment = (e) => {
    setDepartment(e.target.value);
    department.length === 0 ? setDropDown(undefined) : setDropDown(1);
  };
  return (
    <div className="modal-popup">
      {/* <div onClick={poppupViewToggle} className="back-close" /> */}
      <div className=" center-content-contact2 w-90 w-md-55">
        <div className="w-100 d-flex ac-jb mt-4 px-md-5 px-3">
          <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
            View Po Proof
          </p>
          <div onClick={poppupViewToggle} className="">
            <button class="cust-btn addbtn_cancel bg-danger text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">
              Cancel
            </button>
            {/* <button class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">save</button> */}
          </div>
        </div>
        <div className="mt-3 overflow-scroll p-3">
          <div className="w-100 px-4 px-md-5 ">
            <div className="w-100 d-flex flex-wrap justify-content-around align-items-center">
              <div>
                <div className=" as-jb my-2 flex-column flex-md-row">
                  <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary ">
                    Po Number
                  </p>
                  <input
                    placeholder="Required Field"
                    className="editBtn md_cust_w w-xl-100 w-lg-100 w-sm-100 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                </div>
              </div>
              <div className="p-2 ">
                <img src={uploadedImg} className="mailImg"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesOrderPoViewPopup;
