import React, { useState, useMemo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CheckBox } from "@mui/icons-material";

const PricingComp = ({ types, edit }) => {
  const [customer, setCustomer] = useState(false);
  const [reseller, setReseller] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);

  const [ISCount, setISCount] = useState("3");

  const [check, setCheck] = useState([]);
  const [marginShow, SetMarginShow] = useState(false);
  const [decorativeMethod, setDecorativeMethod] = useState(
    "mobile Phones, Note Book, Bottels"
  );
  const [show, setShow] = useState(false);
  const [boxShow, setBoxShow] = useState(0);

  const [inches, setInches] = useState("50");
  const [color1, setColor1] = useState("100");
  const [color2, setColor2] = useState("200");
  const [color3, setColor3] = useState("300");

  const checkBox = (ind) => {
    if (check.includes(ind)) {
      const indtemp = check.indexOf(ind);
      const temp = [...check];
      temp.splice(ind);
      setCheck(temp);
    } else {
      setCheck([...check, ind]);
    }
    console.log(check);
  };
  return (
    <div className="h-auto mt-4">
      <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Vendor Pricing
      </p>
      <div className="w-100">
        <div className="w-md-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
            John Rolph Supplier
          </p>
          {/* <div className="w-md-33  mt-3">
                        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                            Decoration method
                        </p>
                        <div className='position-relative'>
                            <div placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 w-50 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            // value={decorativeMethod}
                            // onChange={onDecorative}
                            >
                                {sub_menu_overview?.map((item) => {
                                    console.log()
                                    return (
                                        <div className='d-flex ac-jb hover-cust'>
                                            <button className='px-2 cust-btn text-start py-1 w-100 ' onClick={() => { checkBox(item?.id); }}>{item?.list}
                                            </button>
                                            <button className='px-2 cust-btn text-start' onClick={() => { checkBox(item?.id); setShow(true) }}>
                                                {check.includes(item?.id) ? item?.check : null}
                                            </button>
                                            {(show || check.includes(item?.id)) &&
                                                <button className='px-2 cust-btn text-start primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'
                                                    onClick={() => {
                                                        setBoxShow(boxShow + 1)
                                                    }}>
                                                    show
                                                </button>}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div> */}
        </div>
        <div className="overflow-scroll mt-4">
          <p class="fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary f4 mb-5 text-decoration-underline">
            Game consoles
          </p>
          <table className="w-100">
            <tr className="mt-4">
              <div className="">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        UOM
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "150px" }}
                      >
                        Inches
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 1 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 2 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 3 Rate
                      </p>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr className="mt-2">
              <div className="my-3">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <select
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        // type="text"
                        // value={ISCount}
                        // onChange={ }
                        // disabled
                        style={{ width: "100px" }}
                        disabled={edit ? false : true}
                      >
                        <option value="australia">Inches</option>
                        <option value="canada">Stitch</option>
                      </select>
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="3"
                        style={{ width: "150px" }}
                        disabled={edit ? false : true}
                        onChange={(e) => setInches(e.target.value)}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="100"
                        style={{ width: "100px" }}
                        disabled={edit ? false : true}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="150"
                        style={{ width: "100px" }}
                        disabled={edit ? false : true}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="250"
                        style={{ width: "100px" }}
                        disabled={edit ? false : true}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
          <table className="w-100">
            <tr className="mt-4">
              <div className="">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        UOM
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "150px" }}
                      >
                        Inches
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 1 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 2 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 3 Rate
                      </p>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr className="mt-2">
              <div className="my-3">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <select
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        // type="text"
                        // value={ISCount}
                        // onChange={ }
                        // disabled
                        style={{ width: "100px" }}
                        disabled={edit ? false : true}
                      >
                        <option value="canada">Stitch</option>
                        <option value="australia">Inches</option>
                      </select>
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="3"
                        style={{ width: "150px" }}
                        disabled={edit ? false : true}
                        onChange={(e) => setInches(e.target.value)}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="100"
                        style={{ width: "100px" }}
                        disabled={edit ? false : true}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="150"
                        style={{ width: "100px" }}
                        disabled={edit ? false : true}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="250"
                        style={{ width: "100px" }}
                        disabled={edit ? false : true}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
        </div>
      </div>
      {/* <div className="w-100 mt-5">
                <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                    Last updated on August 28, 2023 at 03:10 AM
                </p>
            </div> */}
    </div>
  );
};

export default PricingComp;
