import React from 'react'
import { tshirt1 } from '../assets/img';

const SalesOrderEnlargeB = () => {
  return (
    <div className='full-img'>
    <img src={tshirt1} style={{width:"100%",height:"100%",objectFit:"contain",objectPosition:"center",}}/>
  </div>
  )
}

export default SalesOrderEnlargeB;