import { useNavigate } from "react-router-dom";
import {
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
  searchIcon,
} from "../../assets/img";
import {
  customerDecoList,
  enterprisesDecoList,
} from "../../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import { Form } from "react-bootstrap";
import GetAppIcon from "@mui/icons-material/GetApp";

const RequestCustListEnterprises = ({ tabData }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Enterprises Request List
        </p>
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex ac-jb flex-md-row flex-column">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 mt-4 px-3">
            Total Count : {"03"}
          </p>
          <div className="pointerView d-flex mt-4 me-3">
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            + Add Enterprises Request List
          </button>
        </div>
      </div>
      <div className="overflow-scroll mt-4">
        <div className="wholeDesignCustomerDeco d-flex mt-4 border-bottom">
          <p className="w-5 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            S.no
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Enterprises ID
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Request ID
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Order Rep name
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Client Rep Name
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Request Type
          </p>
          {/* <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Number of Items
          </p> */}
          {/* <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Total Price
          </p> */}
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Order Status
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Status
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Options
          </p>
          {/* <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Product quantity and pack size
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Number of logos
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Price
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Reference logo & count
          </p>
          <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Contact details
          </p> */}
        </div>
        {enterprisesDecoList?.map((item) => {
          return (
            <div className="wholeDesignCustomerDeco d-flex mt-5 pb-2">
              <p className="w-5 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.slNo}
              </p>
              <p
                className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc"
                onClick={() => tabData(item)}
              >
                {item?.customerId}
              </p>
              <p className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.reqId}
              </p>
              <p className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.OrderRepname}
              </p>
              <p className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.ClientRepName}
              </p>
              <div className="w-15 pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                <select
                  placeholder=""
                  className="w-90 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>Null</option>
                  <option>Preset</option>
                  <option>Bulk</option>
                  <option>Individual pack</option>
                </select>
              </div>
              {/* <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                5
              </p> */}
              {/* <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                100
              </p> */}
              <div className="w-15 pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                <select
                  placeholder=""
                  className="w-90 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>Request</option>
                  <option>In-process</option>
                  <option>Collaborate</option>
                  <option>Presentation Ready</option>

                  <option>Presentation Accept</option>
                  <option>Presentation Reject</option>
                  <option>Estimation Ready</option>

                  <option>Estimation Accept</option>
                  <option>Estimation Reject</option>
                  <option>Customer Paid</option>
                </select>
              </div>
              <div className="w-10 d-flex ac-jc">
                {item?.status === true && (
                  <div>
                    <img src={toggleOn} className="toggleOnDes" />
                  </div>
                )}
                {item?.status === false && (
                  <div>
                    <img src={toggleOff} className="toggleOnDes" />
                  </div>
                )}
                {/* <Form.Check type="switch" id="toggle-switch" label="" /> */}
              </div>
              <div className="w-15 d-flex ac-jc primary1">
                <div
                  onClick={() =>
                    navigate("/view-request-details-customer", {
                      state: { data: item, method: "enterprises" },
                    })
                  }
                  className="viewBoxDes pointerView p-2 bg-white rounded-2"
                >
                  <img src={viewIcon} className="viewDes" />
                </div>
                <div
                  onClick={() =>
                    navigate("/view-request-details-customer", {
                      state: {
                        type: "edit",
                        data: item,
                        method: "enterprises",
                      },
                    })
                  }
                  className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                >
                  <img src={editIcon} className="viewDes" />
                </div>
              </div>
              {/* <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.qtySize}
              </p>
              <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.NoOfLog}
              </p>
              <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.price}
              </p>
              <div className="w-10 d-flex ac-jc">
                <img src={item?.img} className="imgDashboard" />
              </div>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.ContactDetails}
              </p> */}
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default RequestCustListEnterprises;
