import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { toggleOn } from "../assets/img";
import OverViewComp from "../components/ProductDetailComp/OverViewComp";
import VendorPricingComp from "../components/ProductDetailComp/VendorPricingComp";
import LibraryOverViewComp from "../components/ProductLibraryDetails/LibraryOverViewComp";
import LibraryVendorPricingComp from "../components/ProductLibraryDetails/LibraryVendorPricingComp";
import { useLocation, useNavigate } from "react-router-dom";
import DecorativePricing from "../components/ProductLibraryDetails/DecorativePricing";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ProductLibraryDetailsScreen = () => {
  const [priceBook, setpriceBook] = useState(true);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [tagged, setTagged] = useState(false);
  const [edit, setEdit] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location?.state?.type);

  useEffect(() => {
    if (location?.state?.type == "price") {
      setPreviousOrder(true);
      setpriceBook(false);
    }
  }, []);

  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3">
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          5SK Home Tour
        </h5>
      </div>
      <div className="d-flex mt-2 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc">
          <div>
            <p
              className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary"
              role={"button"}
              onClick={() => navigate(-1)}
            >
              <KeyboardBackspaceIcon />
            </p>
          </div>
          <div
            onClick={() => {
              setpriceBook(true);
              setTagged(false);
              setPreviousOrder(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              priceBook
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                priceBook ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Overview
            </p>
          </div>
          <div
            onClick={() => {
              setPreviousOrder(true);
              setpriceBook(false);
              setTagged(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              previousOrder
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                previousOrder ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Product Pricing
            </p>
          </div>
          <div
            onClick={() => {
              setTagged(true);
              setpriceBook(false);
              setPreviousOrder(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tagged
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tagged ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Decoration Pricing
            </p>
          </div>
          {priceBook && (
            <div className="ms-5">
              <button
                className={
                  edit
                    ? "cust_two_E cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                    : "cust_two_S cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                }
                onClick={() => setEdit(!edit)}
              >
                {edit ? "Submit" : "Edit"}
              </button>
            </div>
          )}
        </div>
        <div className="mt-md-4 mt-3">
          <h5 class="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              {" "}
              INR
            </span>
          </h5>
        </div>
      </div>
      {priceBook && <LibraryOverViewComp edit={edit} />}
      {previousOrder && <LibraryVendorPricingComp />}
      {tagged && <DecorativePricing />}
    </div>
  );
};

export default ProductLibraryDetailsScreen;
