import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import DecorativeDropdownTap from "../DecorativeComp/DecorativeDropdownTap";
import DecorativeListComp from "./DecorativeListComp";

const DecorativeComp = ({ place, decorative2 }) => {
  const [priceBook, setpriceBook] = useState(false);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [tagged, setTagged] = useState(false);
  const navigate = useNavigate();
  const Path = useLocation()
  console.log(Path?.state)
  const location = Path?.state?.type
  const loc = Path?.state?.type
  console.log(place)
  return (
    <div className="">
      <DecorativeDropdownTap />
      <DecorativeListComp decorative2={decorative2} place={place} />
    </div>
  );
};

export default DecorativeComp;
