import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon, product } from "../assets/img";
import FormComp from "../components/AddNewProductComps/FormComp";
import ImageUplodeComp from "../components/AddNewProductComps/ImageUplodeComp";
import ImageCroper from "../components/Popup/ImageCroper";
import { useLocation, useNavigate } from "react-router-dom";
import RequestImageUplodeComp from "../components/CreateNewRequest/RequestImageUplodeComp";
import RequestFormComp from "../components/AddPricing/RequestFormComp";
import PricingBoxComp from "../components/AddPricing/PricingBoxComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddArtWorker from "../components/Projects/AddArtWorker";

const AddPriceBookLibraryScreen = () => {
  const navigat = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const method = location?.state?.method;
  const [editBtn, setEditBtn] = useState(false);
  const [addClient, setAddClient] = useState(false);
  const [addArt, setAddArt] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);
  const [cropImage, setCropImage] = useState();

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };
  const artWork = () => {
    toggleShowPopup();
  };
  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {addClient && (
        <AddArtWorker
          toggleShowPopup={toggleShowPopup}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
        />
      )}
      {AddFilesOverview && (
        <ImageCroper
          toggleImagePopup={toggleShowPopups}
          setCropImage={setCropImage}
        />
      )}
      <ProductSearchHeader />
      <div className="d-flex gap-5">
        <div>
          <p
            className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-3 d-flex align-items-center gap-1"
            role={"button"}
            onClick={() => navigat(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            {type == "edit" ? "Edit " : type == "view" ? " " : "Create "}
            Pricebook Product
          </p>
        </div>
        {type == "edit" && (
          <div>
            <button
              className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              onClick={() => setEditBtn(!editBtn)}
            >
              {editBtn ? "Save" : "Edit"}
            </button>
          </div>
        )}
      </div>
      <RequestFormComp editBtn={editBtn} method={method} />
      <div className="">
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-3">
          Decoration Information
        </p>
        <div className="w-50  res-flex as-jb gap-5 py-4 px-4 bg-lt-blue2 rounded-4 mt-3">
          <div className="w-100 d-flex ac-jb">
            <div className="position-relative w-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Location 1
              </p>
              <div className="position-relative">
                <input
                  placeholder="Enter here"
                  className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="text"
                />
              </div>
            </div>
            <div
              className="w-100 mt-4"
              onClick={() => toggleShowPopup()}
              style={{ cursor: "pointer" }}
            >
              <div className="w-100 d-flex ac-jc gap-2 mt-3">
                <div className="w-15 editBtn rounded-3">
                  <img src={product} className="w-100" />
                </div>
                <p
                  className="f2 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2"
                  style={{ color: "#06679c" }}
                >
                  <InfoOutlinedIcon
                    className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1   parag "
                    style={{ color: "#06679c" }}
                  />{" "}
                  View Proof
                </p>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex ac-jb  mt-3 mb-3">
            <div className="position-relative w-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Location 2
              </p>
              <div className="position-relative">
                <input
                  placeholder="Enter here"
                  className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="text"
                />
              </div>
            </div>
            <div
              className="w-100 mt-4"
              onClick={() => toggleShowPopup()}
              style={{ cursor: "pointer" }}
            >
              <div className="w-100 d-flex ac-jc gap-2 mt-3">
                <div className="w-15 editBtn rounded-3">
                  <img src={product} className="w-100" />
                </div>
                <p
                  className="f2 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2"
                  style={{ color: "#06679c" }}
                >
                  <InfoOutlinedIcon
                    className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1   parag "
                    style={{ color: "#06679c" }}
                  />{" "}
                  View Proof
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb gap-5 flex-column flex-md-row">
        <PricingBoxComp method={method} />
      </div>

      {/* <div className='d-flex as-jb w-100 mt-5 res-flex gap-5'> */}

      <div className="d-flex ac-js gap-5 w-100">
        <button
          className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigat(-1)}
        >
          Back
        </button>
        {type !== "edit" && (
          <button
            className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => navigat(-1)}
          >
            Save
          </button>
        )}
      </div>

      {/* </div> */}
    </div>
  );
};

export default AddPriceBookLibraryScreen;
