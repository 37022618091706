import {
  animatedImg,
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { Search } from "@mui/icons-material";
import { customerList } from "../redux/api/DummyJson";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NonRegisteredCustomerList from "../components/Customers/NonregisteredCustomerList";
import { Col } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomerDetails from "./CustomerDetails";
import CustomerAddress from "./CustomerAddress";
import CustomerProjects from "./CustomerProjects";
import CustomerFiles from "./CustomerFiles";
import CustomerContact from "./CustomerContact";
import CustomerNote from "./CustomerNote";
import RegisteredEnterprisesList from "../components/Enterprises/RegisteredEnterprisesList";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import EditContactPopup from "../components/Popup/EditContactPopup";
import EditAddressPopup from "../components/Popup/EditAddressPopup";

const EnterprisesList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [slide, setSlide] = useState(false);
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogory, setCatogory] = useState("Mobile phones");
  const [tabBtn, setTabBtn] = useState(4);
  const [list, setList] = useState({});
  const [actionBtn, setActionBtn] = useState(false);
  const [detailEdit, setDetailsEdit] = useState(true);
  const [addEnter, setAddEnter] = useState(false);
  const [name, setName] = useState("");
  const [childMenu, setChildMenu] = useState(false);
  const [contactPopup, setContactPopup] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);

  const tabData = (item) => {
    setList(item);
    setSlide(true);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setList((values) => ({ ...values, [name]: value }));
  };

  const submitHandler = () => {
    if (name?.length > 0) {
      setAddEnter(false);
      setTimeout(() => {
        window.open(`/#/enterprises-view?name=${name}`);
      }, 400);
    }
  };

  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 2) {
      setCatogoryShow(true);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };

  const showPopupHander = () => {
    setContactPopup(false);
    setAddressPopup(false);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {contactPopup && (
        <EditContactPopup
          setContactPopup={setContactPopup}
          showPopupHander={showPopupHander}
        />
      )}
      {addressPopup && (
        <EditAddressPopup
          setAddressPopup={setAddressPopup}
          showPopupHander={showPopupHander}
        />
      )}
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} className="searchManImg" />
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Enterprises List
        </p>
        <div className="d-flex w-xs-100 mt-xs-5 position-relative">
          <input
            className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
          <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
        </div>
      </div>

      <RegisteredEnterprisesList tabData={tabData} setAddEnter={setAddEnter} />
      {/* {nonRegList && <NonRegisteredCustomerList />} */}
      <div
        style={{
          width: slide ? "50%" : "0px",
          transition: "0.2s all linear",
          position: "fixed",
          right: "0",
          top: 0,
          zIndex: 1111,
          background: "#fff",
        }}
      >
        <Col className="customer_list_slide px-2">
          <div className="d-flex justify-content-end">
            <div className="mx-2 mt-3">
              <div className="position-relative f3">
                <button
                  className="slide_btn"
                  onClick={() => setActionBtn(!actionBtn)}
                >
                  Actions
                  <KeyboardArrowDownIcon />
                </button>
                {actionBtn && (
                  <div className="action_btn">
                    <button
                      className="border-0 bg-white text-center"
                      onClick={() => {
                        setDetailsEdit(!detailEdit);
                        setActionBtn(!actionBtn);
                      }}
                    >
                      {"Edit Details"}
                    </button>
                    <button
                      className="border-0 bg-white text-center border-top"
                      onClick={() => {
                        setActionBtn(!actionBtn);
                        setAddressPopup(true);
                        setSlide(false);
                      }}
                    >
                      New Address
                    </button>
                    <button
                      className="border-0 bg-white text-center border-top"
                      onClick={() => {
                        setActionBtn(!actionBtn);
                        setContactPopup(true);
                        setSlide(false);
                      }}
                    >
                      New Contact
                    </button>
                    {splitLocation[1] == "enterprises-list" && (
                      <button
                        className="border-0 bg-white text-center border-top"
                        onClick={() => {
                          navigate(`/enterprises-view?name=${list?.name}`);
                        }}
                      >
                        View Profile
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="mx-2 ">
              <button
                // className="slide_btns"
                className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                style={{ backgroundColor: "#01a0b6" }}
                onClick={() => setSlide(false)}
              >
                Close
              </button>
            </div>
          </div>
          <div className="mt-3 mx-2 slide_head">
            <h5 className="f4 primary">{list?.name}</h5>
            <div className="d-flex">
              <p className="mt-2 f3">Last Activity: </p>
              <p className="mt-2 mx-1 f1">2023-10-11</p>
              <p className="mt-2 mx-2 primary1">CID #{list?.CustomerId}</p>
            </div>
            <div className="slide_tabs f2">
              <button
                className={
                  tabBtn == 4
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(4)}
              >
                Details
              </button>
              {splitLocation[1] == "enterprises-list" && (
                <button
                  className={
                    tabBtn == 1
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                      : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                  }
                  onClick={() => setTabBtn(1)}
                >
                  Contacts
                </button>
              )}
              <button
                className={
                  tabBtn == 2
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(2)}
              >
                Addresses
              </button>
              <button
                className={
                  tabBtn == 3
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(3)}
              >
                Projects
              </button>
              {splitLocation[1] !== "enterprises-list" && (
                <button
                  className={
                    tabBtn == 7
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                      : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                  }
                  onClick={() => setTabBtn(7)}
                >
                  Order Request ID
                </button>
              )}
              <button
                className={
                  tabBtn == 5
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(5)}
              >
                Files
              </button>
              <button
                className={
                  tabBtn == 6
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(6)}
              >
                Notes
              </button>
            </div>
            {tabBtn == 1 && <CustomerContact list={list} />}
            {tabBtn == 2 && <CustomerAddress list={list} />}
            {tabBtn == 3 && <CustomerProjects list={list} />}
            {tabBtn == 4 && (
              <CustomerDetails
                list={list}
                detailEdit={detailEdit}
                setDetailsEdit={setDetailsEdit}
                handleChange={handleChange}
              />
            )}
            {tabBtn == 5 && <CustomerFiles list={list} />}
            {tabBtn == 6 && <CustomerNote list={list} />}
            {tabBtn == 7 && (
              <div className="d-flex flex-wrap mt-4 mx-3 details_tab link_projects">
                <Col lg={6} xl={6} xs={12}>
                  <h4 className="f3 fs-16 fs-xs-18 fs-sm-18 fs-md-18 fs-lg-18 fs-xl-18 fs-xxl-18 primary mb-3">
                    Order Request ID
                  </h4>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc "
                  >
                    <p>RMHQ001</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc "
                  >
                    <p>RMHQ002</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc "
                  >
                    <p>RMHQ003</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc "
                  >
                    <p>RMHQ004</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc "
                  >
                    <p>RMHQ005</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc "
                  >
                    <p>RMHQ006</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc "
                  >
                    <p>RMHQ007</p>
                  </Link>
                </Col>
              </div>
            )}
          </div>
        </Col>
      </div>
      {addEnter && (
        <div className="delete-popup">
          <div className="w-90 w-md-40">
            {" "}
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Add Enterprise
              <div className="d-flex gap-1">
                <button
                  className="cust-btn addbtn2  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                  onClick={submitHandler}
                >
                  Create
                </button>
                <span>
                  <button
                    onClick={() => setAddEnter(false)}
                    className="cust-btn"
                  >
                    <HighlightOffIcon />
                  </button>
                </span>
              </div>
            </p>
            <div className="d-flex flex-column ac-jc gap-3 mb-5 mt-3 ">
              <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <input
                  placeholder="Enterprise Name"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Category
                </p>
                <div className="position-relative z-3">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={catogory}
                    onChange={onCatogory}
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setCatogoryShow(!catogoryShow);
                      setChildMenu(false);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                </div>
                {catogoryShow && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setCatogoryShow(!catogoryShow)}
                  />
                )}
                <div
                  className={`${
                    catogoryShow && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
                  // style={{ height: "50px" }}
                >
                  {[
                    "Ad Agency",
                    "Auto",
                    "Aviation",
                    "Beverages",
                    "Business Services",
                  ]?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setCatogoryShow(false);
                            setCatogory(item);
                            setChildMenu(false);
                          }}
                        >
                          {item}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={childMenu ? "submenu_child_1" : "submenu_child_2"}
                >
                  <div
                    className={`${
                      catogoryShow && "submenu_2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    {[
                      "Ad Agency",
                      "Auto",
                      "Aviation",
                      "Beverages",
                      "Business Services",
                    ]?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          {item !== "Game consoles" && (
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => {
                                setCatogoryShow(false);
                                setCatogory(item);
                                setChildMenu(false);
                              }}
                            >
                              {item}
                            </button>
                          )}
                          {item.list === "Game consoles" && (
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => setChildMenu(!childMenu)}
                            >
                              {item?.list}
                            </button>
                          )}
                          {item.list === "Game consoles" && (
                            <button
                              className="cust-btn position-relative"
                              onClick={() => {
                                setChildMenu(!childMenu);
                              }}
                            >
                              {childMenu ? (
                                <KeyboardArrowRightIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnterprisesList;
