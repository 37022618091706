// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { productTableData } from "../redux/api/DummyJson";
import { editIcon, toggleOff, toggleOn, viewIcon } from "../assets/img";
import ShippingAddressList from "../components/Customers/ShippingAddressList";
import WarHouse from "../components/WarHouse/WarHouse";
import SingleShipping from "../components/WarHouse/SingleShipping";
import AddProjectPopup from "../components/Popup/AddProjectPopup";
import Select from "react-select";
import ImageCroper from "../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const CustomerRequestViewDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const methods = location?.state?.method;
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const [method, setMethod] = useState("");
  const [shipping, setShipping] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [requestOrderStatus, setRequestOrderStatus] = useState("");
  const [selectBox, setSelectBox] = useState([]);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const [customerType, setCutomerType] = useState("");
  console.log("data", data);

  useEffect(() => {
    setData(location?.state?.data);
    if (methods == "enterprises") {
      setCutomerType("Enterprises");
    } else if (methods !== "enterprises" && customerType == "Enterprises") {
      navigate("/add-enterprises");
    }
  }, [location, customerType]);

  const toggleShowPopup = () => {
    setShowPopup(!showPopup);
  };

  console.log("datadatadata", data);

  let options = [
    { value: "Bottle", label: "Bottle" },
    { value: "Shirts", label: "Shirts" },
    { value: "T-Shirts", label: "T-Shirts" },
  ];

  const onTagInputChange = (e) => {
    let tmp = {
      value: e,
      label: e,
      type: "input",
    };
    let filtered = options?.filter((item) => item?.type === "input");
    if (filtered?.length === 0) {
      options.push(tmp);
    } else {
      options.pop();
      options.push(tmp);
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}>
        <KeyboardBackspaceIcon />{" "}
        {location?.state?.page === "non-registered"
          ? "Non-Registered Request Details"
          : "Registered Request Details"}
      </p>
      <div className="w-100 searchField mt-5 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                {methods == "enterprises" ? "Enterprises" : "Customer"} Name:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data != undefined ? data?.customerName : "Charles"}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Request ID:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data != undefined ? data?.reqId : "RMHQ001"}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                {methods == "enterprises" ? "Enterprises" : "Customer"} Email:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data != undefined ? data?.email : "charles@ninosit.com"}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                {methods == "enterprises" ? "Enterprises" : "Customer"} Phone
                Number:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data != undefined ? data?.phoneNo : "98765 43210"}
              </p>
            </div>
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Order ID:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.orderId}
              </p>
            </div> */}
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Request Product Name:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.productName}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Category Type:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.categoryType}
              </p>
            </div> */}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Pack:
                </p>
                <div className="w-70 ms-3">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value="25"
                  />
                  {/* <select
                  placeholder=""
                  className="primary1 ms-2 w-45 editBtnSelect f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 rounded-3"
                  name=""
                  id=""
                >
                  <option>Paid</option>
                  <option>Operation</option>
                  <option>Shipping Ready</option>
                  <option>Shipment</option>
                  <option>Deliver</option>
                </select> */}
                  {/* <select
                  placeholder=""
                  className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>E-Commerce</option>
                  <option>Enterprises</option>
                </select> */}
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Products Request Count:
                </p>
                <div className="w-70 ms-3">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value="5"
                  />
                  {/* <select
                  placeholder=""
                  className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>E-Commerce</option>
                  <option>Enterprises</option>
                </select> */}
                </div>
                {/* <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.reqCount}
              </p> */}
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Order Rep Name:
                </p>
                <div className="w-70 ms-3">
                  <select
                    placeholder=""
                    className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                    name=""
                    id="">
                    <option>Sakthivel</option>
                  </select>
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Client Rep Name:
                </p>
                <div className="w-70 ms-3">
                  <select
                    placeholder=""
                    className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                    name=""
                    id="">
                    <option>Sakthivel</option>
                  </select>
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  {methods == "enterprises" ? "Enterprises" : "Customer"} Type:
                </p>
                <div className="w-70 ms-3">
                  <select
                    placeholder=""
                    className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                    name=""
                    id=""
                    value={customerType}
                    onChange={(e) => setCutomerType(e.target.value)}
                    disabled={methods == "enterprises" ? true : false}>
                    <option>E-Commerce</option>
                    <option>Enterprises</option>
                  </select>
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Product Type:
                </p>
                <div className="w-70 ms-3">
                  <select
                    placeholder=""
                    className="w-50  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                    name=""
                    id="">
                    {methods == "enterprises" && <option>Null</option>}
                    <option>Preset</option>
                    <option>Bulk</option>
                    <option>Individual pack</option>
                  </select>
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Request Order Status:
                </p>
                <div className="w-70 ms-3">
                  <select
                    placeholder=""
                    className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                    name=""
                    id=""
                    onChange={(e) => setRequestOrderStatus(e.target.value)}>
                    <option value={0}>Request</option>

                    <option value={1}>In-process</option>
                    <option value={2}>Collaborate</option>
                    <option value={3}>Presentation Ready</option>

                    <option value={4}>Presentation Accept</option>
                    <option value={5}>Presentation Reject</option>
                    <option value={6}>Estimation Ready</option>

                    <option value={7}>Estimation Accept</option>
                    <option value={8}>Estimation Reject</option>
                    <option value={9}>Customer Paid</option>
                  </select>
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Tags:
                </p>
                <div className="w-70 ms-3">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#07679c",
                        borderRadius: "8px",
                      }),
                    }}
                    onInputChange={onTagInputChange}
                    options={options}
                    isMulti
                    classNamePrefix=""
                    className="w-50  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2"
                    onChange={(e) => setSelectBox(e)}
                  />
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  In Hands Date:
                </p>
                <div className="w-70 ms-3">
                  <input
                    type="date"
                    id="from"
                    name="from"
                    className="cust-btn searchFill w-50 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                  />
                </div>
              </div>
            )}
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Product quantity and pack size:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.qtySize}
              </p>
            </div> */}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Number of Colors:
                </p>
                <div className="w-70 ms-3">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value="3"
                  />
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Number of logos:
                </p>

                <div className="w-70 ms-3">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={data?.NoOfLog}
                  />
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Reference logo & count:
                </p>
                <div className="w-70 ms-3" onClick={() => toggleImagePopup()}>
                  <img
                    src={croperImage ? croperImage : data?.img ? data?.img : ""}
                    className="imgDashboard"
                  />
                </div>
              </div>
            )}
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Contact details:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.ContactDetails}
              </p>
            </div> */}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Storing Method:
                </p>
                <div className="w-70 ms-3">
                  <select
                    placeholder=""
                    className="w-50  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                    name=""
                    onChange={(e) => setMethod(e.target.value)}>
                    <option selected hidden value="">
                      Select Method
                    </option>
                    <option value="WareHouse">WareHouse</option>
                    <option value="Shipping">Shipping</option>
                  </select>
                </div>
              </div>
            )}
            {method === "Shipping" && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Shipping Method:
                </p>
                <div className="w-70 ms-3">
                  <select
                    placeholder=""
                    className="w-50  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                    name=""
                    onChange={(e) => setShipping(e.target.value)}>
                    <option selected hidden value="">
                      Select Method
                    </option>
                    <option value="Single">Single Shipping</option>
                    <option value="Multiple">Multiple Shipping</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="w-xxl-15 w-xl-15 w-lg-15 position-relative">
            {data != undefined && (
              <button
                onClick={() => setAction(!action)}
                className="cust-btn addbtn mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                Actions
              </button>
            )}
            {action && (
              <>
                {/* <div
                onClick={() => navigate("/order-list")}
                className="w-xxl-100 w-xl-100 w-lg-100 w-20 actionDetailPopup searchField pointerView py-2 ac-jc rounded-3"
              >
                <h6 className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                  Create Order
                </h6>
              </div> */}
                <div className="w-xxl-100 w-xl-100 w-lg-100 w-20 mt-2 actionDetailPopup searchField pointerView py-2 ac-jc rounded-3">
                  {requestOrderStatus == 1 && (
                    <h6
                      className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14"
                      onClick={() => toggleShowPopup()}>
                      Create Project
                    </h6>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {method === "WareHouse" && <WarHouse setShipping={setShipping} />}
        {shipping === "Single" && <SingleShipping />}
        {shipping === "Multiple" && (
          <div className="w-100 ac-js mt-5">
            <p className=" d-flex ac-jb f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Shipping Address:
              <button
                onClick={() =>
                  navigate("/view-customer-details", {
                    state: { type: "shipping" },
                  })
                }
                className="cust-btn addbtn mt-sm-3 ms-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                + Add Shipping Address
              </button>
            </p>
            <ShippingAddressList />
          </div>
        )}
        <div className="w-100">
          <p className="d-flex ac-jb f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
            Requested Product List
            <span className="d-flex gap-2">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4">
                Total Count : {"05"}
              </p>
              {data != undefined && (
                <button
                  onClick={() => navigate("/ordered-view-details")}
                  className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                  + Add
                </button>
              )}
            </span>
          </p>
          <div className="w-100 mt-3 overflow-scroll">
            <div className="wholeCustomerDet d-flex mt-4 border-bottom pb-3 ">
              <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
                Sl.No
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
                Category Type
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
                Product Name
              </p>
              <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
                Quantity
              </p>
              <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
                Price
              </p>
              <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
                Status
              </p>
              <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
                Options
              </p>
            </div>
            {productTableData?.map((item, index) => {
              return (
                <div className="wholeCustomerDet d-flex mt-4 border-bottom pb-3">
                  <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {index + 1}
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.catType}
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.productName}
                  </p>
                  <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.qty}
                  </p>
                  <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.price}
                  </p>
                  <div className="w-15 d-flex ac-jc">
                    <div>
                      <img src={toggleOn} className="toggleOnDes" />
                      {/* <img src={toggleOff} className="toggleOnDes" /> */}
                    </div>
                  </div>
                  {/* <select
                    placeholder=""
                    className="primary1 w-15 editBtnSelect f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 rounded-3"
                    name=""
                    id=""
                  >
                    <option>Paid</option>
                    <option>Operation</option>
                    <option>Shipping Ready</option>
                    <option>Shipment</option>
                    <option>Deliver</option>
                  </select> */}
                  <div className="w-15 d-flex ac-jc">
                    <div
                      onClick={() =>
                        navigate("/ordered-view-details", {
                          state:
                            data != undefined
                              ? {
                                  data: item,
                                  ind: index + 1,
                                }
                              : { undefined },
                        })
                      }
                      className="viewBoxDes pointerView p-2 bg-white rounded-2 mx-2">
                      <img src={viewIcon} className="viewDes" />
                    </div>
                    {data != undefined && (
                      <div
                        onClick={() =>
                          navigate("/ordered-view-details", {
                            state: {
                              data: item,
                              ind: index + 1,
                            },
                          })
                        }
                        className="viewBoxDes p-2 bg-white rounded-2">
                        <img src={editIcon} className="viewDes" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
            Back
          </button>
          {location?.state?.type === "edit" && (
            <button className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Submit
            </button>
          )}
        </div>
      </div>

      {showPopup && (
        <AddProjectPopup
          toggleShowPopup={toggleShowPopup}
          popupType={"customerReq"}
        />
      )}
    </div>
  );
};

export default CustomerRequestViewDetails;
