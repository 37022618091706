import React, { useState } from "react";
import {
  editIcon,
  searchIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { attributeList, decorativeMethodList } from "../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import AddAttributesPopup from "../components/Popup/AddAttributesPopup";
import ImageCroper from "../components/Popup/ImageCroper";
import GetAppIcon from "@mui/icons-material/GetApp";

const AttributesListScreen = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();

  const toggleShowPopup = (type) => {
    setShowPopup(!showPopup);
    if (type) setPopupType(type);
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  return (
    <>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      {showPopup && (
        <AddAttributesPopup
          toggleShowPopup={toggleShowPopup}
          popupType={popupType}
          toggleImagePopup={toggleImagePopup}
          croperImage={croperImage}
        />
      )}
      <div className="dashRightView p-5 home_section trans">
        <div className="d-flex ac-jb mt-2 flex-wrap">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Attributes
          </p>
          <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
            <div className="d-flex">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Entries
              </p>
            </div>
            <div className="d-flex flex-md-row flex-column">
              {/* <div className="d-flex mt-sm-3">
              <img src={searchIcon} className="searchiConImg" alt="icon" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
              />
            </div> */}
              <div className="d-flex ac-jb flex-md-row flex-column">
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4">
                  Total Count : {"02"}
                </p>
                <div className="pointerView d-flex mt-4 me-3">
                  <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
                  <p
                    className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
                  >
                    Export
                  </p>
                </div>
                <div className="d-flex mt-sm-3 border-search mx-1">
                  <img
                    src={searchIcon}
                    alt="search"
                    className="searchiConImg"
                  />
                  <input
                    className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                    placeholder="Search"
                  />
                </div>
                <button
                  onClick={() => toggleShowPopup("add")}
                  className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add Attribute
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 mt-5 overflow-scroll">
          <div className="w-100 d-flex border-bottom pb-1">
            <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center">
              S.No
            </p>
            <p className="w-20  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center">
              Icon
            </p>
            <p className="w-30  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center">
              Attribute Name
            </p>
            <p className="w-20  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center">
              Status
            </p>
            <p className="w-20  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center">
              Actions
            </p>
          </div>
          {attributeList?.map((item, index) => {
            return (
              <div className="decor-list d-flex mb-3 mt-5 w-100">
                <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 text-center">
                  {index + 1}
                </p>
                <div className="w-20 text-center">
                  <img src={item?.image} className="decorative-img" alt="" />
                </div>
                <p className="w-30 w-sm-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 text-center">
                  {item?.name}
                </p>
                <div className="w-20 ac-jc text-center">
                  {item?.active ? (
                    <div>
                      <img src={toggleOn} className="toggleOnDes" alt="icon" />
                    </div>
                  ) : (
                    <div>
                      <img src={toggleOff} className="toggleOnDes" alt="icon" />
                    </div>
                  )}
                </div>
                <div className="w-20 d-flex ac-jc text-center">
                  <div
                    onClick={() => toggleShowPopup("edit")}
                    className="viewBoxDes ms-2 p-2 bg-white rounded-2"
                  >
                    <img src={editIcon} className="viewDes" alt="icon" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Previous
          </button>
          <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default AttributesListScreen;
