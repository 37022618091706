import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import NavbarHead from "../components/Sub_Layout/NavbarHead";
import { Shipping_bills } from "../redux/api/DummyJson";

const BillsPage = () => {
  const navigate = useNavigate();
  const [actionBtn, setActionBtn] = useState(false);
  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };
  return (
    <div className="dashRightView2 overview_pages overflow-scroll ">
      <div className=" d-flex justify-content-end position-relative mb-2">
        <div className="action_overview mx-4">
          <NavbarHead onLinkShow={onLinkShow} type={"invoice"} />
          {actionBtn && (
            <div
              className="invisible-cont2"
              style={{ zIndex: 200 }}
              onClick={() => setActionBtn(false)}
            />
          )}
          {actionBtn && (
            <div className="action_btn ml-1" style={{ zIndex: 300 }}>
              <button
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                onClick={() => {
                  setActionBtn(false);
                }}
              >
                Create Invoice
              </button>
              <button
                onClick={() => navigate("/create-new-bill")}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Create new bill
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                View Portal
              </button>
            </div>
          )}
        </div>
      </div>
      <div className=" py-5">
        <div className="d-flex as-js gap-2 ">
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-3">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Search for project </option>
              <option value="1">PO</option>
              <option value="2">Merchhq</option>
              <option value="3">Over</option>
              <option value="4">Sales</option>
              {/* <option value="5">Honda</option>
              <option value="6">Jaguar</option>
              <option value="7">Land Rover</option>
              <option value="8">Mercedes</option>
              <option value="9">Mini</option>
              <option value="10">Nissan</option>
              <option value="11">Toyota</option>
              <option value="12">Volvo</option> */}
            </select>
          </button>
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Client Rep</option>
              <option value="10">Shakthi</option>
              <option value="11">Ninos</option>
              <option value="12">Krishna</option>
            </select>
          </button>
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Order Rep</option>
              <option value="11">Delivered</option>
              <option value="12">In Process</option>
            </select>
          </button>
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Stage</option>
              <option value="11">Completed</option>
              <option value="12">Ongoing</option>
            </select>
          </button>
        </div>
      </div>
      <div className="w-100 overflow-scroll mt-4  ">
        <table className="w-100 px-4">
          <tr className=" b">
            <th className="col-md-4 ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                S.NO
              </p>
            </th>
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                PO
              </p>
            </th>{" "}
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                PO Status
              </p>
            </th>
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                PO Supplier
              </p>
            </th>
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                PO Amount
              </p>
            </th>
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Bill
              </p>
            </th>
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Bill Supplier
              </p>
            </th>
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                All Bill Received
              </p>
            </th>
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Amount
              </p>
            </th>
          </tr>

          {Shipping_bills?.map((item, ind) => {
            return (
              <tr className="my-5 border-bottom">
                <td className="col-md-4 my-3">
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center f3 pb-4">
                    {item?.id}
                  </p>
                </td>
                <td className="col-md-4 my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center f3 pb-4">
                      {item?.po}
                    </p>
                  </div>
                </td>{" "}
                <td className="col-md-4 my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center f3 pb-4">
                      {item?.po_status}
                    </p>
                  </div>
                </td>{" "}
                <td className="col-md-4 my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center f3 pb-4">
                      {item?.po_supplier}
                    </p>
                  </div>
                </td>{" "}
                <td className="col-md-4 my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center f3 pb-4">
                      {item?.po_amount}
                    </p>
                  </div>
                </td>{" "}
                <td className="col-md-4 my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center f3 pb-4">
                      {item?.bill}
                    </p>
                  </div>
                </td>{" "}
                <td className="col-md-4 my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center f3 pb-4">
                      {item?.bill_supplier}
                    </p>
                  </div>
                </td>{" "}
                <td className="col-md-4 my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center f3 pb-4">
                      Bill Subtotal: {item?.all_bills}
                    </p>
                  </div>
                </td>{" "}
                <td className="col-md-4 my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center f3 pb-4">
                      {item?.amount}
                    </p>
                  </div>
                </td>{" "}
              </tr>
            );
          })}
        </table>
      </div>
      <div className="bg-lt-blue mt-4 rounded d-inline-block p-3">
        <div className="d-flex gap-5 justify-content-between">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  primary1 f3 pb-2">
            Subtotal(all invoices)
          </p>
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  f3 pb-2 align-items-end">
            $2970.00
          </p>
        </div>
        <div className="d-flex gap-5 justify-content-between">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  primary1 f3 pb-2">
            Bill Margin Amount
          </p>
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  f3 pb-2">
            $2970.00
          </p>
        </div>
        <div className="d-flex gap-5 justify-content-between">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 f3 pb-2">
            Billed Margin
          </p>
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  f3 pb-2">
            100.00%
          </p>
        </div>
        <div className="d-flex gap-5 justify-content-between">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 f3 pb-2">
            Booked Margin
          </p>
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  f3 pb-2 ">
            5.00%
          </p>
        </div>
      </div>
    </div>
  );
};

export default BillsPage;
