import React from 'react'

const CheckingPricePoppup = ({ poppupAddressToggle }) => {
    return (
        <div className="modal-popup">
            <div onClick={poppupAddressToggle} className="back-close" />
            <div className="z_ind_cust center-content-contact2 w-90 w-md-55 px-md-5 px-3 " >
                <div className="w-100 d-flex ac-jb mt-4 ">
                    <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
                        No Costs Available
                    </p>
                    <div onClick={poppupAddressToggle} className="" >
                        <button class="cust-btn addbtn_cancel bg-danger text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">Cancel</button>
                    </div>
                </div>
                <p className="black f1 mt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Please contact the supplier directly to obtain costs for this product.
                </p>
            </div>
        </div>
    )
}

export default CheckingPricePoppup