import React from "react";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import { product } from "../../assets/img";

const VendorsRequestDetails = () => {
  const navigate = useNavigate();
  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3 d-flex ac-jb px-3">
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          John Rolph
        </h5>
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 black">
          Status :{" "}
          <span>
            {/* Requested */}
            <select
              name="status"
              className="primary p-1 f2 fs-18"
              style={{ border: "1px solid #07679c", borderRadius: "10px" }}
            >
              <option disabled hidden value="">
                Select
              </option>
              <option value="Request">Request</option>
              <option value="In Process">In Process</option>
              <option value="Accept">Accept</option>
              <option value="Reject">Reject</option>
            </select>
          </span>
        </h5>
      </div>
      <div className="d-flex mt-3 ac-js gap-3">
        <div>
          <p
            className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary"
            role={"button"}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />
          </p>
        </div>
        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black  d-flex align-items-center gap-2">
          Vendors Request List
        </p>
      </div>

      <div className="d-flex flex-wrap as-jb flex-m-r my-5">
        <div className="col-lg-7 col-12">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Tags
          </p>
          <input
            type="text"
            placeholder="Enter Product Title"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="col-lg-7 col-12 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Price
          </p>

          <input
            type="text"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            placeholder="Enter product Price here..."
          />
        </div>
        <div className="col-lg-7 col-12 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Images
          </p>
          <img
            src={product}
            alt="img"
            style={{ width: "150px", height: "150px" }}
          />
        </div>
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <button className="cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default VendorsRequestDetails;
