import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import SideBarComp from "./SideBarComp";
import SideBarComp2 from "./SideBarComp2";
import MenuIcon from "@mui/icons-material/Menu";
const ProfileNavBar = () => {
  const [sideList, setSaidList] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log("Split________", splitLocation);

  useEffect(() => {
    if (splitLocation[1] === "") {
      navigate("/home");
    }
  }, []);

  // All Dropsowns
  const [roles, setRoles] = useState(false);
  const [ourPacks, setOurPacks] = useState(false);
  const [enableDown, setEnableDown] = useState(false);
  const [prodManagement, setProdManagement] = useState(false);
  const [customerDown, setCustomerDown] = useState(false);
  const [adminRequest, setAdminRequst] = useState(false);
  const [siteManagement, setSiteManagement] = useState(false);
  const [production, setProduction] = useState(false);
  return (
    <div className="navBar position-relative overflow-hidden">
      <div className="w-100">
        <div className="ac-jc gap-5 px-3 d-flex">
          <div
            className="as-js d-flex cp hover_eff"
            onClick={() => {
              setSaidList(!sideList);
              setRoles(false);
              setEnableDown(false);
              setProdManagement(false);
              setCustomerDown(false);
              setSiteManagement(false);
              setProduction(false);
              setAdminRequst(false);
              setOurPacks(false);
            }}
          >
            <MenuIcon className="primary" />
          </div>
          <p className="f4 text fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            merchhq
          </p>
        </div>
        <div className="menu-container">
          <div className="submenu_one">
            <div className={`${sideList && "side_br"} submenu_ch_cont w-100`}>
              <SideBarComp
                setSaidList={setSaidList}
                sideList={sideList}
                roles={roles}
                setRoles={setRoles}
                ourPacks={ourPacks}
                setOurPacks={setOurPacks}
                enableDown={enableDown}
                setEnableDown={setEnableDown}
                prodManagement={prodManagement}
                setProdManagement={setProdManagement}
                customerDown={customerDown}
                setCustomerDown={setCustomerDown}
                adminRequest={adminRequest}
                setAdminRequst={setAdminRequst}
                siteManagement={siteManagement}
                setSiteManagement={setSiteManagement}
                production={production}
                setProduction={setProduction}
              />
            </div>
            <div class="submenu_two overflow-x-hidden overflow-scroll">
              <div className="mt-4 ac-jc d-flex">
                <p className="f2 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-20 fs-xxl-21 primary">
                  Products
                </p>
              </div>
              <SideBarComp2 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNavBar;
