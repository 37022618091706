import React, { useState } from "react";
import { searchIcon } from "../../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { event_category } from "../../redux/api/DummyJson";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";

const PoRequestComp = ({ onShowPopupHander, type }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  const stat = [{ list: "Accepted" }, { list: "Rejected" }];

  const data = [
    {
      id: 1,
      vendor_id: "Ven_001",
      prid: "PR-001",
      vendor_name: "Dhanush",
      mobile_no: "9080877665",
      email: "dhanush@gmail.com",
      total: "30000",
      ex_date: "23/2/2024",
      req_qty: "2900",
      status: 1,
    },
    {
      id: 2,
      vendor_id: "Ven_001",
      prid: "PR-002",
      vendor_name: "Dhanush",
      mobile_no: "9080877665",
      email: "dhanush@gmail.com",
      total: "100000",
      req_qty: "600",
      ex_date: "30/2/2024",
      status: 1,
    },
    {
      id: 3,
      vendor_id: "Ven_001",
      prid: "PR-003",
      vendor_name: "Dhanush",
      mobile_no: "9080877665",
      email: "dhanush@gmail.com",
      total: "20000",
      req_qty: "700",
      ex_date: "30/2/2024",
      status: 2,
    },
  ];

  console.log("yyyy", type);

  return (
    <>
      <div className="w-100 overflow-scroll mt-4">
        <table className="w-100">
          <tr>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                S.No
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor ID
              </p>
            </th>{" "}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                PRID
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor Name
              </p>
            </th>{" "}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor Mobile Number
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor Email
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Total Req Qty
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Total Amount
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Expected Delivery Date
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Notes
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                View
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Status
              </p>
            </th>
          </tr>
          {data?.map((item, ind) => {
            return (
              <tr className="my-3">
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {ind + 1}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.vendor_id}
                  </p>
                </th>{" "}
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.prid}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.vendor_name}
                  </p>
                </th>{" "}
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.mobile_no}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.email}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.req_qty}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.total}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.ex_date}
                  </p>
                </th>
                <th>
                  <p
                    className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4"
                    onClick={() => onShowPopupHander()}
                  >
                    <CommentOutlinedIcon />
                  </p>
                </th>
                <th>
                  <button
                    onClick={() =>
                      navigate("/product-vendor-poreject-view?type=1", {
                        state: { type: type },
                      })
                    }
                    className="w-100 text-center bg-transparent border-0"
                  >
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 pb-4">
                      <VisibilityIcon />
                    </p>
                  </button>
                </th>
                <th>
                  {/* <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className={
                  status == "Rejected"
                    ? "w-auto mt-2 editBtnSelect mb-4 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 text-danger"
                    : "w-auto mt-2 editBtnSelect mb-4 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 text-success"
                }
              >
                {stat?.map((item) => (
                  <option value={item?.list} label={item?.list} className="">
                    {item?.list}
                  </option>
                ))}
              </select> */}
                  <p
                    className={
                      item?.status == 1
                        ? "w-auto mt-2 editBtnSelect mb-4 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 text-success"
                        : "w-auto mt-2 editBtnSelect mb-4 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 text-danger"
                    }
                  >
                    {item?.status == 1 ? "Accepted" : "Rejected"}
                  </p>
                </th>
              </tr>
            );
          })}
        </table>
      </div>
      <div className="d-flex ac-jb w-100 mt-3">
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Previous
        </button>
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default PoRequestComp;
