import React, { useState } from "react";
import { Estimate_req, } from "../../redux/api/DummyJson";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useNavigate } from "react-router";

const Accepted = () => {
  const navigate = useNavigate();
  const [designStatus, setDesignStatus] = useState("Approved");
  const [salseStatus, setSalseStatus] = useState("Completed");
  const design_list = [{ list: "Inprocess" }, { list: "Approved" }];
 
  return (
    <div className="w-100 overflow-scroll mt-5">
      <table className="w-100 px-4">
        <tr>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              S.No
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Project ID
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Project Name
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Customer ID
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Estimate ID
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Order Rep
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Client Rep
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Customer Status
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-5 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Sales Status
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-5 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Options
            </p>
          </th>
        </tr>
        {Estimate_req ?.map((item, ind) => {
          return (
            <tr className="my-3">
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.id}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.project_id}
                </p>
              </th>{" "}
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.project_name}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.customer_id}
                </p>
              </th>{" "}
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.estimate_id}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.order_rep}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.client_rep}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.customer_status}
                </p>
              </th>
              <th>
                
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4 " >
                  {item?.salse_list}
                </p>
                <div className=" position-relative rel_sty d-flex flex-column ac-jc">
                  {/* <select
                    placeholder=""
                    className="editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2 select_drop"
                    value={designStatus}
                    onChange={(e) => setDesignStatus(e.target.value)}
                    id="">
                    {design_list?.map((item) => (
                      <option value={item?.salse_list} label={item?.salse_list}>
                        {item?.salse_list}
                      </option>
                    ))} 
                  </select> */}
                  {designStatus == "Approved" && (
                    <div className="hov_popp viewBoxDes rounded-2">
                      <p className="f1 fs-xs-11 fs-sm-12 border-3 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-10 text-dark text-center py-2">
                        Approved by hari
                      </p>
                    </div>
                  )}
                </div>
              
              </th>
              <th>
                <div className="d-flex ac-jc pb-4">
                  <button
                    onClick={() => navigate("/estimate")}
                    className="text-center bg-transparent border-0 pointerView viewBoxDes p-2 bg-white rounded-2">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 ">
                      <BorderColorIcon />
                    </p>
                  </button>
                </div>
              </th>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default Accepted;
