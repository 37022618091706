import React from "react";
import { art1, art2, head, logos, tshirt1 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const SalesOrder = () => {
  return (
    <div className="bg-gray3" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div
          className="d-flex mt-3  banner"
          style={{
            backgroundImage:
              "url( https://media.geeksforgeeks.org/wp-content/uploads/rk.png)",
            padding: "20px",
            flexWrap: "wrap",
          }}
        >
          <div className="">
            <img
              alt="img"
              src={logos}
              className=" ps-3"
              style={{ width: "150px", objectFit: "cover" }}
            />
          </div>

          <div className="ps-2">
            <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
              MerchHQ Pvt.Ltd.
            </h2>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
              INVOICE for 5SK
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Shankar cement
            </p>
          </div>
        </div>
        <div
          className="d-flex flex-wrap    w-100  mt-4  me-3 p-4"
          style={{
            backgroundColor: "#fff",
            lineHeight: "35px",
            flexWrap: "wrap",
          }}
        >
          <div className="col-md-3 mt-sm-2 p-2">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
                SHIPPPING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                5SK Designer Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
                NADU NINOS 125, INDIA
              </p>
            </div>
          </div>
          <div className="col-md-3 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              BILLING ADDRESS
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              5SK Designer Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
              NADU , INDIA
            </p>
          </div>
          {/* <div className="ryt-line"
            style={{
              height: "150px",
              width: "1px",
              backgroundColor: "#000",
            }}
          ></div> */}
          <div className="col-md-2 ps-2 mt-sm-2 p-2">
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
                PROJECT #
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                2134567
              </p>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TERMS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Purchse Order
              </p>
            </div>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              ESTIMATE #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              1213123
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CUSTOMER PO #
            </p>
            {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              777777777
            </p> */}
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              IN HAND DATE
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              FEB 4,2024
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CURRENCY
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              INR
            </p>
          </div>
        </div>
        <div className="my-3 d-flex bg-white flex-wrap ">
          <div
            className="col-md-4"
            style={{
              backgroundColor: "#fff",

              padding: "12px",
              textAlign: "center",
            }}
          >
            <img
              alt="img"
              src={tshirt1}
              className=" "
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Product image for reference only. <br />
              See your artwork below.
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary">
              Click to enlarge
            </p>
          </div>
          <div className="col-md-6 p-4">
            <div style={{ backgroundColor: "#fff" }}>
              <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 ">
                SOIL Classic Journal -mHQ Blue
              </h2>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                220 GSM, Classic Fit. Style code: 64520.100% ring-spun co on.
                Countered welt collar. tereaway label Tumble Try Medium. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Eligendi
                cupiditate quisquam
              </p>
            </div>
            <div class="  mt-4 overflow-scroll">
              <table className="w-100 ">
                <thead>
                  <tr>
                    <th className="f3 col-md-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                      ITEM
                    </th>
                    <th className="f3 col-md-6  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap"></th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-3 ">
                        QTY
                      </p>
                    </th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                        PRICE
                      </p>
                    </th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                        AMOUNT
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ lineHeight: "30px" }}>
                  <tr>
                    <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Size: TBD - Color: TBD
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        Size: M - Color: Blue
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Size: TBD - Color: TBD
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap "></p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        300
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        10
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        0
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,987,9879.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,4569.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,987,9879.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,4569.00
                      </p>
                    </td>
                  </tr>

                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                    TOTAL UNITS
                  </p>
                  <td></td>
                  <td>
                    <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                      310
                    </p>
                  </td>

                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Fixed pre Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        RS15.0000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS15.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Run Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        310
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS10.0000000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS31.002796
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Fixed Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        RS10.0000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS10.00
                      </p>
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderBottom: "0px",
                      borderTop: "1px",
                      borderRight: "0",
                      borderColor: "#787b7f",
                      borderStyle: "solid",
                      borderLeft: 0,
                    }}
                  >
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Setup Cost - top
                      </p>
                    </td>
                    <td></td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.10.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.10.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Setup Cost -
                      </p>
                    </td>
                    <td></td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.20.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.20.00
                      </p>
                    </td>
                  </tr>

                  <tr className="">
                    <td>
                      <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                        TOTAL
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                    </td>
                    <td>
                      <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                        RS348,332.00
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="bg-white p-2">
          <div className="bg-gray3  rounded p-4 ">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              ARTWORK DETAILS
            </p>
          </div>
          <div className="d-flex flex-wrap ac-ja  m-4">
            <table className="mt-2 w-50" style={{ lineHeight: "30px" }}>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN NAME
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Shakthi
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    IMPRINT TYPE
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    3D Laser Engraving
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN LOCATION
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Top
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN SIZE
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    20*20
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN COLOR
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    blue
                  </p>
                </td>
              </tr>
            </table>
            <div className=" py-2 w-50 text-center">
              <img src={art1} className="w-40" alt="" />
              <a className="primary  ">Click here to enlarge</a>
            </div>
          </div>
        </div>
        <div className="bg-white p-2">
          <div className="bg-gray3  rounded p-4 mt-3">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              ARTWORK DETAILS
            </p>
          </div>
          <div className="d-flex flex-wrap ac-ja  m-4">
            <table className=" mt-2 w-50" style={{ lineHeight: "30px" }}>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN NAME
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Vel
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    IMPRINT TYPE
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Applique
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN LOCATION
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN SIZE
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    20*30
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN COLOR
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    black
                  </p>
                </td>
              </tr>
            </table>
            <div className="py-2 w-50 text-center">
              <img src={art2} className="w-40" alt="" />
              <a className="primary  ">Click here to enlarge</a>
            </div>
          </div>
        </div>
        <div className="my-3 d-flex bg-white flex-wrap ">
          <div
            className="col-md-4"
            style={{
              backgroundColor: "#fff",
              padding: "12px",
              textAlign: "center",
            }}
          >
            <img
              alt="img"
              src={tshirt1}
              className=" "
              style={{
                width: "100%",
                objectFit: "cover",
              }}
            />
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Product image for reference only.
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary">
              Click to enlarge
            </p>
          </div>
          <div className="col-md-6 p-4">
            <div style={{}}>
              <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 ">
                20 oz Matt Solid State Himalayan Tumbler
              </h2>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                220 GSM, Classic Fit. Style code: 64520.100% ring-spun co on.
                Countered welt collar. tereaway label Tumble Try Medium. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Eligendi
                cupiditate quisquam Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Ad quidem eligendi maxime consectetur non
              </p>
            </div>
            <div class="  mt-4 overflow-scroll">
              <table className="w-100 ">
                <thead>
                  <tr>
                    <th className="f3 col-md-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                      ITEM
                    </th>
                    <th className="f3 col-md-6  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap "></th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-3 ">
                        QTY
                      </p>
                    </th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                        PRICE
                      </p>
                    </th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                        AMOUNT
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ lineHeight: "30px", width: "100%" }}>
                  <tr>
                    <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Size: TBD - Color: TBD
                      </p>
                      {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        Size: M - Color: Blue
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Size: TBD - Color: TBD
                      </p> */}
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap "></p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        300
                      </p>
                      {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        10
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        0
                      </p> */}
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,987,9879.00
                      </p>
                      {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,4569.00
                      </p> */}
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,987,9879.00
                      </p>
                      {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,4569.00
                      </p> */}
                    </td>
                  </tr>

                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                    TOTAL UNITS
                  </p>
                  <td></td>
                  <td>
                    <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                      310
                    </p>
                  </td>

                  {/* <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Fixed pre Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        RS15.0000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS15.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Run Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        310
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS10.0000000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS31.002796
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Fixed Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        RS10.0000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS10.00
                      </p>
                    </td>
                  </tr>
                  <tr
                   
                  >
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Setup Cost - top
                      </p>
                    </td>
                    <td></td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.10.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.10.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Setup Cost -
                      </p>
                    </td>
                    <td></td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.20.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.20.00
                      </p>
                    </td>
                  </tr> */}

                  <tr
                    className=""
                    style={{
                      borderBottom: "0px",
                      borderTop: "1px",
                      borderRight: "0",
                      borderColor: "#787b7f",
                      borderStyle: "solid",
                      borderLeft: 0,
                    }}
                  >
                    <td>
                      <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                        TOTAL
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                    </td>
                    <td>
                      <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                        RS348,332.00
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="w-90 m-auto d-flex flex-wrap ac-jb">
        <div className="mt-3 " style={{ lineHeight: "30px" }}>
          <div className="">
            <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ">
              Questions About this estimate?
            </p>
            <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 ">
              Roger Daniel
            </h2>
            <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary">
              roger@companystore.io
            </a>
            <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ">
              merchHQ Pvt.Ltd.
            </p>
            <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15  ">
              #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
              Kartnataka, India.
            </p>
            <button
              class=" d-flex ac-jc ps-3 mt-sm-2"
              style={{
                backgroundColor: "#0082ca",
                border: "none",
                borderRadius: "7px",
                padding: "5px",
                color: "#fff",
              }}
            >
              <UnsubscribeIcon />

              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                Contact us
              </p>
            </button>
          </div>
        </div>
        <div className="col-md-5  mt-3">
          <div className="bg-gray px-3 py-2">
            <table className="w-100 ">
              <tr>
                <td>
                  {" "}
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                    SUBTOTAL
                  </p>
                </td>
                <td>
                  {" "}
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                    RS8,8,396,095.67
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                    TAX - CGST 6:
                  </p>
                </td>
                <td>
                  {" "}
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                    RS8,481,095.67
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                    TOTAL
                  </p>
                </td>
                <td
                  style={{
                    borderBottom: "0px",
                    borderTop: "1px",
                    borderRight: "0",
                    borderColor: "#fff",
                    borderStyle: "solid",
                    borderLeft: 0,
                  }}
                >
                  {" "}
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                    INR RS,8,500,876.30
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div class="d-flex mt-sm-2 ae-je ">
            <FacebookRoundedIcon />

            <TwitterIcon />

            <LinkedInIcon />

            <InstagramIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesOrder;
