import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  sub_menu,
  Product_Name,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  catogory_setting,
  counting,
  colors,
  Select_list,
  selecLibList,
  selected_list,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import TvIcon from "@mui/icons-material/Tv";

const LeftComp = ({ edit, types, status }) => {
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(false);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);
  const [hsnTypeShow, setHsnTypeShow] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [uomShow, setuomShow] = useState(false);
  const [isCountShow, setISCountShow] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);
  const [Dropdown, setDrop] = useState(0);

  // input box
  const [catogory, setCatogory] = useState("Mobile");
  const [catogory2, setCatogory2] = useState("Android");
  const [catogory3, setCatogory3] = useState("Samsung");
  const [productName, setProductName] = useState("Mobile Phone");
  const [productSuplierCode, setProductSuplierCode] = useState("SM10001");
  const [decorativeMethod, setDecorativeMethod] = useState(
    "Game consoles,Game consoles,Game consoles,Game consoles,Game consoles,Game consoles"
  );
  const [hsnType, sethsnType] = useState("CGST-85");
  const [packingSize, setPackingSize] = useState("sample Content");
  const [country, setCountry] = useState("India");
  const [uomCont, setuomCont] = useState("Inches");
  const [ISCount, setISCount] = useState("3");
  const [color1, setColor1] = useState("100");
  const [color2, setColor2] = useState("200");
  const [color3, setColor3] = useState("300");
  const [productLink, setProductLink] = useState("");
  const [productDescription, setProductDescription] = useState(
    "Once, there was a boy who became bored when he watched over the village sheep grazing on the hillside. To entertain himself, he sang out, “Wolf! Wolf! The wolf is chasing the sheep!”"
  );
  const [sizeOfDimention, setSizeOfDimention] = useState(10);
  const [weight, setWeight] = useState("10kg");
  const [design, setDesign] = useState("Bottels");
  const [meterialUse, setMeterialUse] = useState("village sheep");
  const [color, setColor] = useState("Red, Blue");
  const [selectCondtion, setSelectCondtion] = useState("");
  const [check, setCheck] = useState([]);
  const [editComp, setEditComp] = useState(false);

  // input box

  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 2) {
      setCatogoryShow(true);
      setProductNameShow(false);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };
  const onCatogory2 = (e) => {
    setCatogory2(e.target.value);
    if (catogory2.length >= 2) {
      setCatogoryShow2(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onCatogory3 = (e) => {
    setCatogory3(e.target.value);
    if (catogory3.length >= 2) {
      setCatogoryShow3(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onProduct = (e) => {
    setProductName(e.target.value);
    if (productName.length >= 2) {
      setProductNameShow(true);
    } else {
      if (productName.length >= 0) setProductNameShow(false);
      setProductSuplierCode("");
    }
    setCatogoryShow(false);
  };
  const onDecorative = (e) => {
    setDecorativeMethod(e.target.value);
    if (decorativeMethod.length >= 2) {
      setDecorativeShow(true);
    } else {
      if (decorativeMethod.length >= 0) setDecorativeShow(false);
    }
  };
  const onHsnTyoe = (e) => {
    sethsnType(e.target.value);
    if (hsnType.length >= 2) {
      setHsnTypeShow(true);
    } else {
      if (hsnType.length >= 0) setHsnTypeShow(false);
    }
  };
  const onPackingSize = (e) => {
    setPackingSize(e.target.value);
    if (packingSize.length >= 2) {
      setPackingSizeShow(true);
    } else {
      if (packingSize.length >= 0) setPackingSizeShow(false);
    }
  };
  const onCountry = (e) => {
    setCountry(e.target.value);
    if (country.length >= 2) {
      setCountryShow(true);
    } else {
      if (country.length >= 0) setCountryShow(false);
    }
  };
  const onUom = (e) => {
    setuomCont(e.target.value);
    if (uomCont.length >= 2) {
      setuomShow(true);
    } else {
      if (uomCont.length >= 0) setuomShow(false);
    }
  };
  const onISCount = (e) => {
    setISCount(e.target.value);
    if (ISCount.length >= 2) {
      setISCountShow(true);
    } else {
      if (ISCount.length >= 0) setISCountShow(false);
    }
  };
  const checkBox = (ind) => {
    if (check.includes(ind)) {
      const indtemp = check.indexOf(ind);
      const temp = [...check];
      temp.splice(1, indtemp);
      setCheck(temp);
    } else {
      setCheck([...check, ind]);
    }
    console.log(check);
  };
  // disabled
  // disabled
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap as-jb flex-m-r">
        <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
          <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Category
          </p>
          <div className="position-relative z-3">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={catogory}
              onChange={onCatogory}
              disabled={edit ? false : true}
            />
            {edit && (
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  edit && setCatogoryShow(!catogoryShow);
                  setChildMenu(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            )}
          </div>
          {catogoryShow && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setCatogoryShow(!catogoryShow)}
            />
          )}
          <div
            className={`${catogoryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setCatogoryShow(false);
                      setCatogory(item?.list);
                      setChildMenu(false);
                      setSubcatogory(true);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
          <div className={childMenu ? "submenu_child_1" : "submenu_child_2"}>
            <div
              className={`${catogoryShow && "submenu_2"
                } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    {item.list !== "Game consoles" && (
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setCatogoryShow(false);
                          setCatogory(item?.list);
                          setChildMenu(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    )}
                    {item.list === "Game consoles" && (
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => setChildMenu(!childMenu)}
                      >
                        {item?.list}
                      </button>
                    )}
                    {item.list === "Game consoles" && (
                      <button
                        className="cust-btn position-relative"
                        onClick={() => {
                          setChildMenu(!childMenu);
                          setProductNameShow(false);
                          setCountryShow(false);
                          setHsnTypeShow(false);
                          setPackingSizeShow(false);
                        }}
                      >
                        {childMenu ? (
                          <KeyboardArrowRightIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Sub Category 1
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={catogory2}
              onChange={onCatogory2}
              disabled={edit ? false : true}
            />
            {edit && (
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  edit && setCatogoryShow2(!catogoryShow2);

                  setCatogoryShow(false);

                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            )}
          </div>
          {catogoryShow2 && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setCatogoryShow2(!catogoryShow2)}
            />
          )}
          <div
            className={`${catogoryShow2 && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setCatogoryShow2(false);
                      setCatogory2(item?.list);
                      setSubcatogory2(true);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Sub Category Code
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={packingSize}
              disabled
              onChange={onPackingSize}
            />
          </div>
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Sub Catogory 2
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={catogory3}
              onChange={onCatogory3}
              disabled={edit ? false : true}
            />
            {edit && (
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  edit && setCatogoryShow3(!catogoryShow3);
                  setChildMenu(false);

                  setCatogoryShow(false);
                  setCatogoryShow2(false);

                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            )}
          </div>
          {catogoryShow3 && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setCatogoryShow3(!catogoryShow3)}
            />
          )}
          <div
            className={`${catogoryShow3 && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setCatogoryShow3(false);
                      setCatogory3(item?.list);
                      setChildMenu(false);
                      setSubcatogory3(true);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Name
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={productName}
              onChange={onProduct}
              disabled={edit ? false : true}
            />
            {(productNameShow || edit) && (
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  edit && setProductNameShow(!productNameShow);
                  setProductSuplierCode("code1003");
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);

                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            )}
          </div>
          {productNameShow && (
            <div
              className="invisible-cont2"
              onClick={() => setProductNameShow(!productNameShow)}
            />
          )}
          <div
            className={`${productNameShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setProductNameShow(false);
                      setProductName(item?.list);
                      setProductSuplierCode("code1003");
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                SKU Code
            </p>
            <input
            type='text'
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value='SKU0001'
                onChange={(e) => { setProductSuplierCode(e.target.value) }}
                disabled={edit ? false : true}
            />
        </div> */}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Product Code
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={productSuplierCode}
            onChange={(e) => {
              setProductSuplierCode(e.target.value);
            }}
            disabled={edit ? false : true}
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Decoration Method
          </p>
          <textarea
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={decorativeMethod}
            // onChange={(e) => { setDecorativeMethod(e.target.value) }}
            disabled={edit ? false : true}
          />
        </div>
        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Size or dimensions
            </p>
            <input
            type='text'
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={sizeOfDimention}
                type='number'
                disabled={edit ? false : true}
                onChange={(e) => setSizeOfDimention(e.target.value)}
            />
        </div> */}
        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Weight
            </p>
            <input
            type='text'
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={weight}
                disabled={edit ? false : true}
                onChange={(e) => setWeight(e.target.value)}

            />
        </div> */}
        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Design features
            </p>
            <input
            type='text'
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                disabled={edit ? false : true}
                value={design}
                onChange={(e) => setDesign(e.target.value)}

            />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Materials used in construction
            </p>
            <input
            type='text'
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                disabled={edit ? false : true}
                value={meterialUse}
                onChange={(e) => setMeterialUse(e.target.value)}



            />
        </div> */}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Country of Origin
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={country}
              onChange={onCountry}
              disabled={edit ? false : true}
            />
            {edit && (
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  edit && setCountryShow(!countryShow);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            )}
          </div>
          {countryShow && (
            <div
              className="invisible-cont2"
              onClick={() => setCountryShow(!countryShow)}
            />
          )}
          <div
            className={`${countryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setCountryShow(false);
                      setCountry(item?.list);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Color
            </p>
            <input
            type='text'
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={color}
                disabled={edit ? false : true}
                onChange={(e) => setColor(e.target.value)}


            />
        </div> */}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Packaging Size
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={packingSize}
              disabled
              onChange={onPackingSize}
            />
            {/* {edit && <button className='drop_down cust-btn' onClick={() => {
                    edit && setPackingSizeShow(!PackingSizeShow);
                    setCatogoryShow(false);
                    setCatogoryShow2(false);
                    setCatogoryShow3(false);
                    setProductNameShow(false);
                    setHsnTypeShow(false);
                    setCountryShow(false);
                    setuomShow(false);
                    setISCountShow(false)
                    setDecorativeShow(false)
                }}>
                    <KeyboardArrowDownIcon />
                </button>} */}
          </div>
          {PackingSizeShow && (
            <div
              className="invisible-cont2"
              onClick={() => setPackingSizeShow(!PackingSizeShow)}
            />
          )}
          <div
            className={`${PackingSizeShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setPackingSizeShow(false);
                      setPackingSize(item?.list);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            HSN Code
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={hsnType}
              // onChange={onHsnTyoe}
              disabled={edit ? false : true}
            />
          </div>
          <div
            className={`${hsnTypeShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {Hsn_type?.map((item, ind) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      Dropdown === ind
                        ? setChildMenu2(!childMenu2)
                        : setDrop(ind);
                    }}
                  >
                    {item?.list}
                  </button>

                  {/* {ind === childM/enu3 && */}
                  <button
                    className="cust-btn position-relative"
                    onClick={() => {
                      Dropdown === ind
                        ? setChildMenu2(!childMenu2) && setDrop(ind)
                        : setDrop(ind);
                    }}
                  >
                    {/* {childMenu2 && Dropdown === ind ? */}
                    <KeyboardArrowRightIcon />
                  </button>
                </div>
              );
            })}
          </div>
          {Dropdown === 0 && (
            <div className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}>
              <div
                className={`${hsnTypeShow && "submenu_2 h-auto sub-2"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {Hsn_type_Child2?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setHsnTypeShow(false);
                          sethsnType(item?.list);
                          setChildMenu2(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {Dropdown === 1 && (
            <div className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}>
              <div
                className={`${hsnTypeShow && "submenu_2 h-auto sub-2"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {Hsn_type_Child3?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setHsnTypeShow(false);
                          sethsnType(item?.list);
                          setChildMenu2(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {Dropdown === 2 && (
            <div className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}>
              <div
                className={`${hsnTypeShow && "submenu_2 h-auto sub-2"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {Hsn_type_Child4?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setHsnTypeShow(false);
                          sethsnType(item?.list);
                          setChildMenu2(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="w-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Product Description
          </p>
          <textarea
            type="text"
            placeholder="Autogenerate"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={productDescription}
            style={{ height: "100px" }}
            disabled={edit ? false : true}
            onChange={(e) => setProductDescription(e.target.value)}
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Size
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value="Xl"
            disabled={edit ? false : true}
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Weight
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value="2Kg"
            disabled={edit ? false : true}
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Dimention
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value="Dimetion Cont"
            disabled={edit ? false : true}
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Material
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value="meterial cont"
            disabled={edit ? false : true}
          />
        </div>
        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Materials used in construction
            </p>
            <input
                type='text'
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value='M Use'
                disabled={edit ? false : true}

            />
        </div> */}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Style
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value="Color Cont"
            disabled={edit ? false : true}
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Color
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={color}
            disabled={edit ? false : true}
          />
        </div>
      </div>
      <div className="w-100 overflow-scroll mt-4">
        <table className="w-100">
          <tr className="mt-4">
            <div className="">
              <td>
                <div className="d-flex w-100">
                  <div className="mx-2">
                    <p
                      className=" f2 fs-xs-11 fs-sm-12 cp fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "50px" }}
                    >
                      <CheckBoxOutlined />
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                      style={{ width: "200px" }}
                    >
                      Variant
                    </p>
                  </div>
                </div>
              </td>
            </div>
          </tr>
          <tr className="mt-2">
            <div className="my-3">
              <td>
                <div className="d-flex w-100">
                  <div className="d-flex ac-jc mx-2" style={{ width: "50px" }}>
                    <button className="mx-2 d-flex ac-jc cust-btn">
                      <CheckBoxOutlined className="primary" />
                    </button>
                  </div>
                  <div className="mx-2">
                    <div
                      className="num-cls-btn d-flex overflow-scroll p-2"
                      style={{ width: "200px" }}
                    >
                      <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                        XL/Blue/Classic
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </div>
          </tr>
        </table>
      </div>
      <div className="w-100 my-3">
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
          Product Website
        </p>
        <div className="d-flex ac-js gap-2">
          <TvIcon className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start" />
          <p className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
            Link will open a new tab
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeftComp;
