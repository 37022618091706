import React, { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import Select from "react-select";
import ImageCroper from "../Popup/ImageCroper";
import { Col } from "react-bootstrap";
import { IoPricetag } from "react-icons/io5";
import { IoPricetagOutline } from "react-icons/io5";
import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { searchIcon, toggleOff, toggleOn } from "../../assets/img";
import {
  useLazyTagsStatusQuery,
  useLazyTagsViewQuery,
  useTagsCreateMutation,
  useTagsEditMutation,
  useTagsListsMutation,
} from "../../redux/api/api";
import { toast } from "react-hot-toast";

const IconTags = () => {
  let [iconTagOption, setIconTagOption] = useState([]);
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [titleEdit, setTitleEdit] = useState("");
  const [titleEditErr, setTitleEditErr] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [update, setUpdate] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState();

  // RTK QUERY
  const [tagsCreateApi] = useTagsCreateMutation();
  const [tagsStatusApi] = useLazyTagsStatusQuery();
  const [tagsUpdateApi] = useTagsEditMutation();
  const [tagsViewApi] = useLazyTagsViewQuery();
  const [listTagsApi] = useTagsListsMutation();

  useEffect(() => {
    if (croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setImage(file);
        });
    }
  }, [croperImage]);

  // GET LIST
  const getList = (event) => {
    setSearchValue(event);

    let formdata = new FormData();

    if (event?.length > 0) {
      formdata.append("search", event);
    }
    formdata.append("type", 3);

    listTagsApi(formdata)
      .unwrap()
      .then((res) => {
        setTagsList(res?.lists);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  // ADD TAGS
  const submitHandle = () => {
    if (title?.length == 0) {
      setTitleErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", title);
      formdata.append("type", 3);

      if (image?.name) {
        formdata.append("icon", image);
      }

      tagsCreateApi(formdata)
        .unwrap()
        .then((res) => {
          toast.success(res?.data?.message || res?.message);
          setTitle("");
          getList();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // UPDATE TAGS
  const submitHandleUpdate = () => {
    if (titleEdit?.length == 0) {
      setTitleEditErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", titleEdit);
      formdata.append("type", 3);

      if (image?.name) {
        formdata.append("icon", image);
      }

      tagsUpdateApi({ payload: formdata, id: update?.id })
        .unwrap()
        .then((res) => {
          toast.success(res?.data?.message || res?.message);
          setShowPopup(false);
          setTitleEdit("");
          setUpdate({});
          getList();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // SHOW POPUP
  const getShowHandle = (item, ind) => {
    setTitleEdit(item?.name);
    setImage(item?.icon);
    setUpdate(item);
    setShowPopup(true);
  };

  // STATUS CHANGER
  const change = (id) => {
    tagsStatusApi(id)
      .unwrap()
      .then((res) => {
        toast.success(res?.data?.message || res?.message);
        getView(id);
        getList();
      })
      .catch((err) => {
        console.log("err");
      });
  };

  // VIEW ONE
  const getView = (id) => {
    tagsViewApi(id)
      .unwrap()
      .then((res) => {
        let data = res?.showData;
        setTitleEdit(data?.name);
        setUpdate(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //   Event functions
  const handleAddTags = () => {
    if (title) {
      let temp = {
        label: title.toLowerCase(),
        title: title,
        type: "input",
      };
      let arr = [...iconTagOption, temp];
      let uniqueArr =
        arr &&
        Array.isArray(arr) &&
        arr.length > 0 &&
        arr.filter(
          (data, i) => arr.findIndex((item) => item.title === data?.title) === i
        );
      setIconTagOption(uniqueArr);
      setTitle("");
    }
  };

  const handleRemove = (e) => {
    console.log(e, "e");
    if (e) {
      let removeVal =
        iconTagOption &&
        Array.isArray(iconTagOption) &&
        iconTagOption.filter((data, i) => data?.title !== e);
      setIconTagOption(removeVal);
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };
  const handleUploadImg = (e) => {
    e.preventDefault();
    toggleImagePopup("ban");
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-3 w-100 ac-jb pt-4">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Icon Tags
          </p>
          <div className="d-flex ac-jb">
            <h5 className="preset_count bold pe-3">
              Icon Tags Count: {tagsList?.length}
            </h5>
            <div className="d-flex mt-sm-3 border-search">
              <img src={searchIcon} alt="img" className="searchiConImg" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
                onChange={(e) => getList(e.target.value)}
                value={searchValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap as-jb flex-m-r my-5">
        {/* <div className="col-lg-7 col-12">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Tags
          </p>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#07679c",
                borderRadius: "8px",
              }),
            }}
            onChange={(e) => setIconTagOption([...iconTagOption, ...e])}
            isMulti
            className="w-100  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2"
            value={iconTagOption}
          />
        </div> */}
        <div className="col-lg-7 col-12 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Add Icon Image
          </p>

          <input
            style={{ cursor: "pointer" }}
            type="file"
            onClick={(e) => {
              toggleImagePopup(e);
            }}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 upload_img"
          />
        </div>
        <div className="col-lg-7 col-12">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Add Icon Name
          </p>
          <input
            placeholder="Type here..."
            value={title}
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
          {title?.length == 0 && titleErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Title</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <button
            onClick={() => submitHandle()}
            className="  cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            Submit
          </button>
        </div>

        <div className="col-lg-12 col-12 mt-3">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Tags
          </p>
          <div className="col-lg-12 col-12 d-flex flex-wrap">
            {tagsList?.map((item, ind) => {
              return (
                <Col key={ind} className="mb-4" xs={10} sm={5} lg={2} xxl={2}>
                  <div className="d-flex mt-4 ms-1 align-items-center">
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => getShowHandle(item)}
                    >
                      <p className="w-100  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2 d-flex text-nowrap d-flex align-items-center gap-1">
                        {/* <IoPricetag />  */}
                        <IoPricetagOutline />
                        {item?.name}
                      </p>
                    </button>
                    <div
                      className="d-flex ac-jc mt-1"
                      onClick={() => change(item?.id)}
                    >
                      {item?.status === 1 ? (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      ) : (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      )}
                    </div>
                  </div>{" "}
                </Col>
              );
            })}
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="add-category-popup">
          <div>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Edit Title
              <span>
                <button
                  onClick={() => {
                    setShowPopup(false);
                  }}
                  className="cust-btn"
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </p>

            <div className="d-flex ac-jc add-content">
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Title *
                </p>
                <div>
                  <div
                    className="d-flex ac-jc"
                    onClick={() => change(update?.id)}
                  >
                    {update?.status === 1 ? (
                      <div>
                        <img src={toggleOn} className="toggleOnDes" />
                      </div>
                    ) : (
                      <div>
                        <img src={toggleOff} className="toggleOnDes" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex w-xs-100 mt-xs-5 mt-3 position-relative">
                  <input
                    className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                    placeholder="Title"
                    onChange={(e) => setTitleEdit(e.target.value)}
                    value={titleEdit}
                  />
                </div>
                {titleEdit?.length == 0 && titleEditErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Title</p>
                  </div>
                )}
              </div>
            </div>
            <div
              className="w-100 d-flex ac-jb mt-4 flex-wrap"
              onClick={() => toggleImagePopup()}
            >
              <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                Image (Optional)
              </p>
              <div className="w-100 d-flex w-xs-100 mt-xs-5 mt-3 position-relative">
                <input
                  className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                  placeholder="Search"
                  value={image ? "Image Uploaded Successfuly" : "Upload File"}
                />
              </div>

              {image && (
                <div className="mt-3 d-flex justify-content-end">
                  <img
                    src={image?.name ? URL.createObjectURL(image) : image}
                    style={{ width: "100px" }}
                  />
                </div>
              )}
            </div>
            <div className="d-flex mt-3 justify-content-end m-2">
              <button
                onClick={submitHandleUpdate}
                className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
    </div>
  );
};

export default IconTags;
