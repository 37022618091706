// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const BannerViewDetails = () => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  console.log(data);

  return (
    <div className="dashRightView p-5 home_section trans">
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-0 mb-3 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Banner Details
      </p>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Name:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.customerName}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Banner Image:
              </p>
              <div className="w-70 ms-3">
                <img src={data?.img} className="imgDashboard" />
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Description:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.phoneNo}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Button Name:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.orderId}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Button Link:
              </p>
              <a
                href="#"
                className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-primary"
              >
                {data?.orderId}
              </a>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Priority Status:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.orderId}
              </p>
            </div>

            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.productName}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex ac-jb">
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
        {/* <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Submit
        </button> */}
      </div>
    </div>
  );
};

export default BannerViewDetails;
