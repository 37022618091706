import React, { useState } from "react";
import { head, logos, tshirt1, tshirt2, tshirt3 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Carousel from "react-bootstrap/Carousel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import ExampleCarouselImage from "components/ExampleCarouselImage";

// import ExampleCarouselImage from 'components/ExampleCarouselImage';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Navigate, useNavigate } from "react-router";






const PresentationGalleryPage3A = () => {
     const navigate = useNavigate();
  const [imgitem, setimgitem] = useState([]);

  const images = [
    {
      original: tshirt1,
      name: "img",
      thumbnail: tshirt1,
    },
    {
      original: tshirt2,
      thumbnail: tshirt2,
    },
    {
      original: tshirt3,
      thumbnail: tshirt3,
    },
  ];
  console.log("imgitem", imgitem);
  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
      <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="px-2 font-blue2" >
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                PRESENTATION #110208 for AAHIKA
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project Name
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div className="d-flex flex-wrap mt-4">
          <div className="col-md-6 mt-4 rounded">
            <ImageGallery items={images} onClick={() => setimgitem(images)} style={{width:"300px", height:"300px"}} />
            {/* <Carousel className="rounded" >
              <Carousel.Item >
                <img src={tshirt1} className="rounded " alt="" style={{height:"500px",width:"100%",objectFit:"cover"}} />
              </Carousel.Item>
              <Carousel.Item >
                <img src={tshirt2} className="rounded" alt="" style={{height:"500px",width:"100%",objectFit:"cover"}} />
              </Carousel.Item>
              <Carousel.Item  >
                <img src={tshirt3} className="rounded" alt="" style={{height:"500px",width:"100%",objectFit:"cover"}} />
              </Carousel.Item>     
            </Carousel> */}
            {/* <div className="carousel-indicators">
               <img src={tshirt1} alt="" style={{width:"30px", height:"30px"}} />
               <img src={tshirt2} alt="" style={{width:"30px", height:"30px"}}/>
               <img src={tshirt3} alt="" style={{width:"30px", height:"30px"}}/>
            </div> */}
          </div>
          <div className="p-3 col-md-6 my-3">
            <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 primary  mt-3">
              Gildy Easy Care Solid Tee - Royal
            </h2>
            <p className="f3 fs-xs-9 fs-sm-10 fs-md-12 fs-lg-12 fs-xl-12 fs-xxl-13">
              (Previously ArchiveGildan Royal-Blue Polo Tee)
            </p>
            {/* <div className="my-3">
              <button className="border-0 bg-gray p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                Product Description
              </button>
            </div> */}
            <div>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 my-4 ">
                Price label:  Rs.650.00
              </p>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                Pricing
              </p>
            </div>
            <div
              className="my-3 bg-lt-blue3 p-3"
              style={{ lineHeight: "40px" }}
            >
              <table className="w-100 overflow-scroll  ">
                <tr className=" text-center">
                  <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    QTY{" "}
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    25
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    50
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    100
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    150
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    250
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    RS{" "}
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.131.38
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.32.4
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.244.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.435.0
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.498.00
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                Fixed Charges
              </p>

              <div
                className="p-3 my-3 bg-lt-blue3 w-90"
                style={{ lineHeight: "40px" }}
              >
                <table className=" w-90  overflow-scroll">
                  <tr>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Fixed Charges1
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                     Rs.250
                    </td>
                  </tr>
                  <tr>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Setup Cost
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Rs.498.00
                    </td>
                  </tr>
                  <tr>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Setup Cost
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Rs.348.00
                    </td>
                  </tr>
                </table>
              </div>

              <div>
                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                  Colors:
                </p>
                <div className="d-flex">
                  <div className="primary">
                    <FiberManualRecordIcon />
                  </div>
                  <div className="lt-Blue">
                    <FiberManualRecordIcon />
                  </div>
                  <div className="gray">
                    <FiberManualRecordIcon />
                  </div>
                  <div className="gray1">
                    <FiberManualRecordIcon />
                  </div>
                </div>
              </div>
              <div>
                <p className="f3 mt-3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                  Sizes:
                </p>
                <div className="d-flex">
                  <h2 className="f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                    XS
                  </h2>
                  <h2 className="f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                    S
                  </h2>
                  <h2 className="f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                    M
                  </h2>
                  <h2 className="f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                    L
                  </h2>
                  <h2 className="f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 bg-lt-blue3 rounded px-1">
                    XL
                  </h2>
                  <h2 className="f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                    XXL
                  </h2>
                  <h2 className="f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                    XXXL
                  </h2>
                </div>
              </div>
            </div>
            <div className="">
              <button
                onClick={() => {
                  navigate("/presentation-gallery-comment-a");
                }}
                className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4"
              >
                Comments{" "}
                <span className="bg-blue1 rounded px-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  12
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        class=" d-flex ac-jc px-3 mt-2 ms-4 py-2"
        style={{
          backgroundColor: "#0082ca",
          border: "none",
          borderRadius: "7px",
          padding: "5px",
          color: "#fff",
        }}
      >
        <UnsubscribeIcon />

        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-2 py-2">
          Contact us
        </p>
      </button>
    </div>
  );
};

export default PresentationGalleryPage3A;
