import { Download, FileUpload, FileUploadOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { profilePic } from "../assets/img";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useRef } from "react";
import { useState } from "react";

const CollabrateAcceptEditScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hiddenFileInput = useRef(null);
  const [images, setImages] = useState([]);

  // console.log(location?.state);

  const handleChange = (event) => {
    const selectedFIles = [];
    const targetFiles = event.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <div className="w-100 searchField mt-0 bg-white d-flex ac-jb">
        <button className="d-flex me-3 pointerView btn_border py-2 px-3 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
          {location?.state === "view" ? " View Ticket" : "Edit Ticket"}
        </button>
        <button
          onClick={() => navigate("/collaborates", { state: "accepted" })}
          class="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
      </div>
      <div className="w-100 searchField mt-0 bg-white d-flex ac-jb">
        <div className="w-100 mt-0 ">
          <div
            className={
              location?.state?.ticket !== 2
                ? "w-100 scroll_view"
                : "w-100 scroll_view1"
            }
          >
            <div className=" searchField d-flex ac-jb mb-5 bg-white">
              <button className="d-flex me-3 pointerView border-0 py-2 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Subject : <span className="primary1 ms-2">Shirt</span>
              </button>
              {location?.state != "view" && (
                <button
                  onClick={() =>
                    navigate("/collaborates", { state: "accepted" })
                  }
                  class="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  Ticket Close
                </button>
              )}
            </div>
            <div>
              <li className="d-flex flex-wrap mb-5">
                <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                  <div className="d-flex-ac-jc mb-3 mb-md-0">
                    <img src={profilePic} className="sm_img_size" />
                  </div>
                  <div className="d-flex px-0 px-md-3 w-100">
                    <div className="mx-3 w-100">
                      <div className="d-flex ac-jb">
                        <h5 className="author">
                          <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                            Sakthi
                          </span>
                          <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            5.00 PM
                          </span>
                        </h5>
                      </div>
                      {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                      <div>
                        <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                          <p>
                            text and a search for lorem ipsum will uncover many
                            web sites still in
                          </p>
                        </div>
                        <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                  <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                    <img src={profilePic} className="sm_img_size2" />
                    <div
                      className="position-absolute invisible down_icon"
                      style={{ top: 0, right: 0 }}
                    >
                      <Download className="primary" />
                    </div>
                  </div>
                  <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                    <img src={profilePic} className="sm_img_size2" />
                    <div
                      className="position-absolute invisible down_icon"
                      style={{ top: 0, right: 0 }}
                    >
                      <Download className="primary" />
                    </div>
                  </div>
                  <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                    <img src={profilePic} className="sm_img_size2" />
                    <div
                      className="position-absolute invisible down_icon"
                      style={{ top: 0, right: 0 }}
                    >
                      <Download className="primary" />
                    </div>
                  </div>
                  <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                    <img src={profilePic} className="sm_img_size2" />
                    <div
                      className="position-absolute invisible down_icon"
                      style={{ top: 0, right: 0 }}
                    >
                      <Download className="primary" />
                    </div>
                  </div>
                  <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                    <img src={profilePic} className="sm_img_size2" />
                    <div
                      className="position-absolute invisible down_icon"
                      style={{ top: 0, right: 0 }}
                    >
                      <Download className="primary" />
                    </div>
                  </div>
                </div>
              </li>
              <ul>
                <div className="w-100 d-flex ac-jc mt-5">
                  <div className="bg_today px-3 py-2 rounded-3">
                    <p class="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                      Today
                    </p>
                  </div>
                </div>
                <li className="d-flex flex-wrap mb-5">
                  <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc mb-3 mb-md-0">
                      <img src={profilePic} className="sm_img_size" />
                    </div>
                    <div className="d-flex px-0 px-md-3 w-100">
                      <div className="mx-3 w-100">
                        <div className="d-flex ac-jb">
                          <h5 className="author">
                            <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                              Sakthi
                            </span>
                            <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              5.00 PM
                            </span>
                          </h5>
                        </div>
                        {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                        <div>
                          <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                            <p>
                              text and a search for lorem ipsum will uncover
                              many web sites still in
                            </p>
                          </div>
                          <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li className="d-flex flex-wrap">
                  <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc mb-3 mb-md-0">
                      <img src={profilePic} className="sm_img_size" />
                    </div>
                    <div className="d-flex px-0 px-md-3 w-100">
                      <div className="mx-3 w-100">
                        <div className="d-flex ac-jb">
                          <h5 className="author">
                            <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                              Vishwa
                            </span>
                            <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              5.20 PM
                            </span>
                          </h5>
                        </div>
                        {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                        <div>
                          <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                            <p>
                              like readable English. Many desktop publishing
                              packages and web page editors now use Lorem Ipsum
                              as their default readable English. Many desktop
                              publishing packages and web page editors now use
                              Lorem Ipsum as their default readable English.
                              Many desktop publishing packages and web page
                              editors now use Lorem Ipsum as their default model
                              text and a search for lorem ipsum will uncover
                              many web sites still in
                            </p>
                          </div>
                          <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="line w-100 mt-5" />
            <div>
              <ul>
                <div className="w-100 d-flex ac-jc mt-5">
                  <div className="bg_today px-3 py-2 rounded-3">
                    <p class="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                      Yesterday
                    </p>
                  </div>
                </div>
                <li className="d-flex flex-wrap">
                  <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc mb-3 mb-md-0">
                      <img src={profilePic} className="sm_img_size" />
                    </div>
                    <div className="d-flex px-0 px-md-3 w-100">
                      <div className="mx-3 w-100">
                        <div className="d-flex ac-jb">
                          <h5 className="author">
                            <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                              Vishwa
                            </span>
                            <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              5.00 PM
                            </span>
                          </h5>
                        </div>
                        {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                        <div>
                          <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                            <p>
                              like readable English. Many desktop publishing
                              packages and web page editors now use Lorem Ipsum
                              as their and web page editors now use Lorem Ipsum
                              as their default model text and a search for lorem
                              ipsum will uncover many web sites still in
                            </p>
                          </div>
                          <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="d-flex flex-wrap mt-5">
                  <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc mb-3 mb-md-0">
                      <img src={profilePic} className="sm_img_size" />
                    </div>
                    <div className="d-flex px-0 px-md-3 w-100">
                      <div className="mx-3 w-100">
                        <div className="d-flex ac-jb">
                          <h5 className="author">
                            <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                              Krishna Vendor
                            </span>
                            <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              5.00 PM
                            </span>
                          </h5>
                        </div>
                        {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                        <div>
                          <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                            <p>
                              like readable English. Many desktop publishing
                              packages and web page editors now use Lorem Ipsum
                              as their and web page editors now use Lorem Ipsum
                              as their default model text and a search for lorem
                              ipsum will uncover many web sites still in
                            </p>
                          </div>
                          <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {location?.state?.ticket !== 2 && (
            <>
              {" "}
              <div className="w-100 mt-0 bg-white">
                <div className="d-flex ac-jb ">
                  <button className="d-flex me-3 primary pointerView border-0 py-2 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Message
                  </button>
                  {/* <button className="d-flex me-3 primary pointerView border-0 py-2 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <DriveFolderUploadIcon />
              </button>   */}
                </div>
                <textarea
                  class="editBtn rounded-3 p-2 w-100"
                  style={{ height: "100px" }}
                  placeholder="Message..."
                />
              </div>
              <div className="d-flex ac-jb w-100">
                <div className="d-flex ac-jb">
                  <input
                    type="file"
                    multiple
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                  />

                  <button
                    onClick={handleClick}
                    className="cust-btn ms-1 bg-primar text-white f3 px-3 py-2 mb-3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-0 rounded"
                  >
                    {/* <img src={uploadIcon} alt="" className="me-2" /> */}
                    upload your Files
                  </button>
                </div>
                <button class="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                  Send
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default CollabrateAcceptEditScreen;
