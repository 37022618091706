import { CheckBox } from "@mui/icons-material";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { navigate, useNavigate } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';

import NavbarHead from "../components/Sub_Layout/NavbarHead";
import { Sales_order, Shipping_bills } from "../redux/api/DummyJson";

const CreateNewBill = () => {
  const navigate=useNavigate();
  const [actionBtn, setActionBtn] = useState(false);
  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };
  return (
    <div className="dashRightView2 overview_pages overflow-scroll ">
      <NavbarHead />
      {/* <div className=" d-flex justify-content-end position-relative mb-2">
        <div className="action_overview mx-4">
          <NavbarHead onLinkShow={onLinkShow} type={"invoice"} />
          {actionBtn && (
            <div
              className="invisible-cont2" 
              style={{ zIndex: 200 }}
              onClick={() => setActionBtn(false)}
            />
          )}
          {actionBtn && (
            <div className="action_btn ml-1" style={{ zIndex: 300 }}>
              <button
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                onClick={() => {
                  setActionBtn(false);
                }}
              >
                Create Invoice
              </button>
              <button
                onClick={() => Navigate("/")}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Create new bill
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                View Portal
              </button>
            </div>
          )}
        </div>
      </div> */}
      <div className=" py-5">
        <div className="d-flex as-js gap-2 mt-3">
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-3">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Search for project </option>
              <option value="1">PO</option>
              <option value="2">Merchhq</option>
              <option value="3">Over</option>
              <option value="4">Sales</option>
              {/* <option value="5">Honda</option>
              <option value="6">Jaguar</option>
              <option value="7">Land Rover</option>
              <option value="8">Mercedes</option>
              <option value="9">Mini</option>
              <option value="10">Nissan</option>
              <option value="11">Toyota</option>
              <option value="12">Volvo</option> */}
            </select>
          </button>
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Client Rep</option>
              <option value="10">Shakthi</option>
              <option value="11">Ninos</option>
              <option value="12">Krishna</option>
            </select>
          </button>
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Order Rep</option>
              <option value="11">Delivered</option>
              <option value="12">In Process</option>
            </select>
          </button>
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Stage</option>
              <option value="11">Completed</option>
              <option value="12">Ongoing</option>
            </select>
          </button>
        </div>
      </div>
      <div className="d-flex ac-jb">
        <div className="p-3 px-4 text-nowrap fs-xs-14 fs-sm-16  fs-md-18 fs-lg-20 fs-xl-22 fs-xxl-24  primary f3">
          <h4>Add Bill</h4>
        </div>
        <div className="d-flex">
          <button className="  cust-btn addbtn_cancel  mt-sm-3 ms-1 bg-danger text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Cancel
          </button>
          <button onClick={()=>navigate("/bill-total-page")} 
          className="  cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Save
          </button>
        </div>
      </div>
      <div className="d-flex px-4 m-4 gap-3">
        <Col sm={12} md={12} lg={6} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Supplier
          </p>

          <select
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              Supplier Name
            </option>
          </select>
        </Col>
        <Col sm={12} md={12} lg={6} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Subtotal
          </p>
          <select
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              $0.00
            </option>
          </select>
        </Col>
      </div>
      <div className="d-flex px-4 m-4 gap-3">
        <Col sm={12} md={12} lg={6} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Invoice#
          </p>

          <select
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              Required Field
            </option>
          </select>
        </Col>
        <Col sm={12} md={12} lg={6} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Default Tax
          </p>
          <select
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              E(0%)
            </option>
          </select>
        </Col>
      </div>
      <div className="d-flex px-4 m-4 gap-3">
        <Col sm={12} md={12} lg={6} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Invoice Date
          </p>

          <select
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              05/02/2024
            </option>
          </select>
        </Col>
        <Col sm={12} md={12} lg={6} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Total Tax
          </p>
          <select
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              $0.00
            </option>
          </select>
        </Col>
      </div>
      <div className="d-flex px-4 m-4 gap-3">
        <Col sm={12} md={12} lg={6} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Due Date
          </p>

          <select
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              05/02/2024
            </option>
          </select>
        </Col>
        <Col sm={12} md={12} lg={6} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Amount Due
          </p>
          <select
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              $0.00
            </option>
          </select>
        </Col>
      </div>
      <div className="d-flex px-4 mx-4 gap-3 my-4">
        <Col sm={12} md={12} lg={6} className="me-md-3 my-4">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Notes
          </p>

          <input
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            {/* <option selected disabled>
              Supplier Name
            </option>     */}
          </input>
        </Col>
        <Col sm={12} md={12} lg={6} className="me-md-3 ">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
            Currency Conversion
          </p>
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Bill Invoice : INR
          </p>
          <select
            // value={status}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              1=1 INR
            </option>
          </select>
        </Col>
      </div>
      <div className="bg-lt-blue">
        <div className="d-flex ac-jb p-2 w-100">
          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust primary">
            Sales Order
          </p>
          <button className="  cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Action
          </button>
        </div>
      </div>
      <div className="overflow-scroll">
        <table className="w-100 px-4 pt-4">
          <tr className=" bg-lt-blue  px-2">
            <th className="col-md-1 ">
              <p className=" p-3 f3 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                {""}
              </p>
            </th>
            <th className="col-md-1">
              <p className="f3 d-flex align-items-start fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                Code
              </p>
            </th>{" "}
            <th className="col-md-3">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                Item Name
              </p>
            </th>{" "}
            <th className="col-md-1">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                {" "}
                Qty
              </p>
            </th>
            <th className="col-md-1">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                Unit Cost
              </p>
            </th>
            <th className="col-md-1">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                Subtotal
              </p>
            </th>
            <th className="col-md-1">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 tect-nowrap text-center gray">
                Tax
              </p>
            </th>
            <th className="col-md-1">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                Total
              </p>
            </th>
            <th className="col-md-2">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                {" "}
              </p>
            </th>
          </tr>
          {Sales_order?.map((item, ind) => {
            return (
              <tr className="mt-4 px-2 vert-top">
                <td className="col-md-1 ">
                  <p className=" fs-xs-11 fs-sm-12 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center mt-3  f3">
                    <input className="mx-2" type="checkbox" />
                  </p>
                </td>
                <td className="col-md-1 ">
                  <input className="editBtn d-flex align-item-start f1  rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  "></input>
                </td>{" "}
                <td className="col-md-3">
                  <input className="editBtn f1  rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-2"></input>
                  <div className="d-flex ac-jc me-3 mt-4 w-90">
                    <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap col-md-6 text-center">
                      Taxes
                    </p>
                    <input
                      className="f1 border-0 text-center bg-lt-blue rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 col-md-6"
                      placeholder="Add Tax"
                    />
                  </div>
                  <div>
                  <div className="primary">
                  <CancelIcon />
                  </div>
                     
                    <select
                      // value={status}
                      className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    >
                      <option selected disabled>
                        CGST (2.5% 2.50%)
                      </option>
                    </select>
                  </div>
                </td>{" "}
                <td className="col-md-1">
                  <input className="editBtn f1  rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-2"></input>
                </td>
                <td className="col-md-1">
                  <input className="editBtn f1  rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-2"></input>
                </td>
                <td className="col-md-1">
                  <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center  w-90 ">
                    {item?.subtotal}
                  </p>
                </td>
                <td className="col-md-1">
                  <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 tect-nowrap text-center w-90 ">
                    {item?.tax}
                  </p>
                </td>
                <td className="col-md-1">
                  <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center w-90 ">
                    {item?.total}
                  </p>
                </td>
                <th className="col-md-2">
                  <button className="  cust-btn addbtn mt-sm-3  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3 rounded">
                    View/Edit
                  </button>
                </th>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default CreateNewBill;
