import React from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { product as productImg } from "../../assets/img";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Filter,
  Filter1Outlined,
  FilterAlt,
  Search,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ProductRequest = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className="d-flex gap-3">
          <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
            <option hidden>Select Categories</option>
            <option>Mobile Phone</option>
            <option>Water Bottle</option>
          </select>
          <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
            <option hidden>Select Status</option>
            <option>Active</option>
            <option>Deactive</option>
          </select>
        </div>
        <div className="d-flex ac-jb flex-column flex-md-row gap-3 justify-content-md-end">
          <div className="d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <input
              type="text"
              className="get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3 w-auto fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder="Search Product Name"
            />
          </div>
          <div className="d-flex ac-jc gap-3">
            <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
              <Search />
            </button>
            <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
              <CachedIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4 flex-wrap">
        <div className="d-flex ac-jb w-xs-100 mt-xs-5 position-relative">
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary me-3">
            From
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mx-3">
            To
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
      </div>
      <div className="w-100 d-flex ac-js mt-4 flex-wrap">
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => navigate("/request-detail-view")}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={productImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Charles
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb">
              SKU0001 <span className="text-primary f4">Requested</span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => navigate("/request-detail-view")}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={productImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Charles
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb">
              SKU0001 <span className="text-success f4">Accepted</span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => navigate("/request-detail-view")}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={productImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Charles
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb">
              SKU0001{" "}
              <span className="text-danger f4 d-flex">
                Rejected{" "}
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      Product Rejected reason will show here
                    </Tooltip>
                  )}
                  placement="bottom"
                >
                  <InfoOutlinedIcon
                    className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 mt-1  parag "
                    style={{ color: "#06679c" }}
                  />
                </OverlayTrigger>
              </span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => navigate("/request-detail-view")}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={productImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Charles
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb">
              SKU0001 <span className="text-warning f4">In progress</span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => navigate("/request-detail-view")}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={productImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Charles
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb">
              SKU0001{" "}
              <span className="text-danger f4 d-flex">
                Rejected{" "}
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      Product Rejected reason will show here
                    </Tooltip>
                  )}
                  placement="bottom"
                >
                  <InfoOutlinedIcon
                    className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 mt-1  parag "
                    style={{ color: "#06679c" }}
                  />
                </OverlayTrigger>
              </span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => navigate("/request-detail-view")}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={productImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Charles
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb">
              SKU0001 <span className="primary f4">Requested</span>
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default ProductRequest;
