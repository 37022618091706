import React, { useState } from "react";
import { Col } from "react-bootstrap";
import AddClientContactPopup from "./AddClientContactPopup";
import PresentaionList from "./PresentaionList";
import NavbarHead from "../Sub_Layout/NavbarHead";
import PresentaionListTable from "./PresentaionListTable";
import { useNavigate } from "react-router-dom";
import PresentationCopyPopup from "./PresentationCopyPopup";

const Presentation = () => {
  const navigete = useNavigate();
  const [infoBtn, setInfoBtn] = useState(true);
  const [addClient, setAddClient] = useState(false);
  const [actionBtn, setActionBtn] = useState(false);
  const [copyPresentation, SetCopyPresentation] = useState(false);
  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };

  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };

  const toggleCopy = () => {
    SetCopyPresentation(!copyPresentation);
  };

  const copyPresent = () => {
    navigete(`/presentation?id=${Math.random().toString().substr(2, 6)}`);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div className="dashRightView2 presentaion_pages  overflow-scroll ">
      {copyPresentation && (
        <PresentationCopyPopup
          toggleCopy={toggleCopy}
          copyPresent={copyPresent}
        />
      )}
      <div className=" d-flex justify-content-end position-relative mb-2">
        <div className="action_overview mx-4">
          <NavbarHead onLinkShow={onLinkShow} />
          {actionBtn && (
            <div
              className="invisible-cont2"
              style={{ zIndex: 200 }}
              onClick={() => setActionBtn(false)}
            />
          )}
          {actionBtn && (
            <div className="action_btn ml-1" style={{ zIndex: 300 }}>
              <button
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                onClick={() => {
                  setActionBtn(false);
                  toggleCopy();
                }}
              >
                Copy Presentation
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Preview Presentation
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Email Presentation
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 text-danger bg-white text-start f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Delete Presentation
              </button>
              <button
                onClick={() => navigete("/estimate")}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Create Estimate
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                View Portal
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="w-100">
        <div className="w-100 d-flex ac-je">
          <button
            className="d-flex me-3 pointerView btn_border py-2 px-3 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onClick={() => setInfoBtn(!infoBtn)}
          >
            {infoBtn ? "- Collapse Info" : "-Expand Info"}
          </button>
        </div>
        <div className="d-flex flex-column flex-md-row gap-3">
          <div className="w-100">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Status
            </p>

            <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
              <option>Over All</option>
              <option>In Process</option>
              <option>Presentation Ready</option>
              <option>Presentation Accepted</option>
              <option>Presentation Rejected</option>
            </select>
          </div>
          <div className="w-100">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Presentation Date
            </p>

            <input
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              type="date"
            />
          </div>
          <div className="w-100">
            {infoBtn && (
              <>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  In Hands Date
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="date"
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        {infoBtn && (
          <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            {" "}
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Introduction
            </p>
            <textarea
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder=""
              rows={2}
            />
          </div>
        )}
      </div>
      {infoBtn && (
        <div className="d-flex gap-3 flex-column flex-xl-row">
          <div className="d-flex w-100 gap-3 flex-column flex-md-row">
            <div className="w-100">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Client Contact
              </p>
              <div className="d-flex">
                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                  <option>Cli</option>
                  <option>Cli-2</option>
                  <option>Cli-3</option>
                </select>
                <input
                  type="button"
                  value={"+"}
                  placeholder="Required Field"
                  onClick={toggleShowPopup}
                  className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                />
              </div>
              <div className="mt-3">
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  sakthivelsninos@gmail.com
                </p>
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  W: 7358144805
                </p>
              </div>
            </div>
            <div className="w-100">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2 mx-3"
                style={{ marginLeft: "0px" }}
              >
                Presentation Style
              </p>
              <div className="d-flex mt-2 mx-3">
                <div className="justify-content-center d-flex gap-3">
                  <div className="d-flex">
                    <input type={"radio"} className="mx-1" />
                    <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                      List
                    </span>
                  </div>
                  <div className="d-flex">
                    <input type={"radio"} className="mx-1" />
                    <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                      Gallery
                    </span>
                  </div>
                </div>
                {/* {["Tiles", "List", "Gallery", "Smart Gallery"].map((item) => (
                <div className="d-flex flex-wrap">
                  <input type={"radio"} className="mx-1" />
                  <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                    {item}
                  </span>
                </div>
              ))} */}
              </div>
              <div className="d-flex mt-3 mx-3">
                <input type={"checkbox"} className="mx-1 " />
                <p className="mx-2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                  Hide Pricing
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 gap-3 flex-column flex-md-row">
            <div className="w-100">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Expiry Date
              </p>
              <input
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                type="date"
              />
            </div>
            <div className="w-100">
              <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                Currency
              </p>
              <select
                disabled
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 opacity-50"
              >
                <option>INR</option>
                <option>Converted</option>
                <option>Closed</option>
              </select>
            </div>
          </div>
        </div>
      )}
      <div className="mt-3">
        <PresentaionList />
      </div>
      {addClient && <AddClientContactPopup toggleShowPopup={toggleShowPopup} />}
    </div>
  );
};

export default Presentation;
