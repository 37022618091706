import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import { colors } from "../../redux/api/DummyJson";

const VariationsPopUpCopy = ({ toggleShowPopup, variationType }) => {
  return (
    <div className="variation-popup">
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          Which product would you like to copy from?
          <span>
            <button
              onClick={() => toggleShowPopup("copy")}
              className="cust-btn"
            >
              <HighlightOffIcon />
            </button>
          </span>
        </p>
      </div>
    </div>
  );
};

export default VariationsPopUpCopy;
