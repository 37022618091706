import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import { useNavigate } from "react-router-dom";
import AddFilesPopup from "../Popup/AddFilesOverview";
import NavbarHead from "../Sub_Layout/NavbarHead";
import IosShareIcon from "@mui/icons-material/IosShare";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import { uploadIcon } from "../../assets/img";
import { useRef } from "react";
import { CloseOutlined, DeleteForever } from "@mui/icons-material";

const AddCollaborate = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState("");
  const [selectFiles, setSelectFiles] = useState();

  const [selectBox, setSelectBox] = useState([]);
  const [selecter, setSelecter] = useState("");

  const [file, setFile] = useState([]);
  const [images, setImages] = useState([]);

  const imgFiles = [];

  let options = [
    { value: "all suppliers", label: "All Suppliers" },
    { value: "ab prime", label: "ab Prime" },
    { value: "kti promos", label: "KTI  Promos" },
  ];
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    selectBox?.map((item) => {
      if (item?.value == "all suppliers") {
        setSelecter(item?.value);
      } else {
        setSelecter("");
      }
    });
  }, [selectBox]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const selectedFIles = [];
    const targetFiles = event.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };

  return (
    <div className="dashRightView collaborates p-5 home_section trans">
      <ProductSearchHeader />
      <div className="d-flex gap-4 as-jb mt-3 flex-column flex-md-row w-100">
        <div className="w-100">
          <div className="py-2 px-3 rounded-3 bg-lt-blue2 my-3 py-4 rounded-3">
            <div className="acti_box">
              <div className="d-flex as-jb flex-wrap w-100 ">
                <div className="w-50 px-2">
                  <div className="w-100">
                    <p className=" f3">Choose Category</p>
                    <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      <option>Category-1</option>
                      <option>Category-2</option>
                      <option>Category-3</option>
                    </select>
                  </div>
                </div>
                <div className="w-50 px-2 supp-select">
                  <p className=" f3">Choose Vendor</p>
                  <Select
                    options={options}
                    isMulti
                    classNamePrefix="select"
                    className="editBtn w-100 rounded-3 border-0 mt-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => setSelectBox(e)}
                  />
                </div>
                <div className="w-50 px-2 supp-select mt-3">
                  <p className=" f3">Product Name</p>
                  <input
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    type="text"
                  />
                </div>
                <div className="w-50 px-2 supp-select mt-3">
                  <p className=" f3">Assigned to</p>
                  <Select
                    options={options}
                    isMulti
                    classNamePrefix="select"
                    className="editBtn w-100 rounded-3 border-0 mt-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => setSelectBox(e)}
                  />
                </div>
              </div>
              <div className=" w-100 px-2 mt-3">
                <p className=" f3">Description</p>
                <textarea
                  className="editBtn w-100 rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  placeholder="Post a note about this project"
                  onChange={(e) => setPost(e.target.value)}
                />
              </div>
              <div className="d-flex ac-jb">
                {/* <button className="button-upload">Upload a file</button> */}
                <input
                  type="file"
                  multiple
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />

                <button
                  onClick={handleClick}
                  className="cust-btn addbtn ms-1  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-0 rounded"
                >
                  <img src={uploadIcon} alt="" className="me-2" />
                  {/* <AttachmentOutlinedIcon className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" /> */}
                  upload your Files
                </button>
              </div>
              <div className="w-100 d-flex flex-wrap mt-3">
                {images?.length > 0 &&
                  images.map((url, ind) => {
                    console.log(url);
                    return (
                      <div className="prev-img position-relative">
                        <img src={url} />
                        <button
                          onClick={() => {
                            setImages(images.filter((o, i) => ind !== i));
                          }}
                          className="icon-delete cust-btn"
                        >
                          <CloseOutlined />
                        </button>
                      </div>
                    );
                  })}
              </div>
              <button
                className="cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                style={
                  post?.length > 0
                    ? { background: "red", float: "right" }
                    : { background: "red", opacity: "0.3", float: "right" }
                }
              >
                <IosShareIcon className='className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17"' />
                Request
              </button>
            </div>
          </div>
        </div>
        <div className="w-50 box_width2">
          <h5 className="f3 fs-13 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark">
            Project Status
          </h5>
          <div className="d-flex ac-js gap-3 mt-3">
            <div className="over_pro_st m-0">
              <button className="cust-btn addbtn m-0  bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2">
                P
              </button>
            </div>
            <div className="flex-wrap">
              <p className="over_p_tag f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 m-0">
                Presentation
              </p>
              <p className="over_open f1 fs-15 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-12 fs-xl-13 fs-xxl-14 primary1 m-0">
                New
              </p>
            </div>
          </div>
          <div className="collab_tip rounded-3 mt-4 ms-0 w-100">
            <h4 className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
              Collaborate with your suppliers
            </h4>
            <p className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries
            </p>
            <p className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
              as opposed to using 'Content here, content here', making it look
              like readable English. Many desktop publishing packages and web
              page editors now use Lorem Ipsum as their default model text, and
              a search for 'lorem ipsum' will uncover many web sites still in
              their infancy. Various versions have evolved over the years,
              sometimes by accident, sometimes on purpose (injected humour and
              the like).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCollaborate;
