import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { uploadIcon, vendorSuccessImg } from "../../assets/img";
import { useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ImportListPopup = ({ showPopupHander, setImportList }) => {
  const navigate = useNavigate();
  const fileRef = useRef();
  const [file, setFile] = useState("");
  console.log(file?.type);
  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      <div className="center-content p-4">
        {/* <div>
          <span>
            <button onClick={showPopupHander} className="cust-btn">
              <HighlightOffIcon />
            </button>
          </span>
        </div> */}
        <p className="d-flex ac-jc f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Import Vendor List
        </p>
        <div className="pointerView w-100 mt-4 ac-jc">
          <div
            onClick={() => fileRef.current.click()}
            className="w-100 d-flex ac-jc"
          >
            {file === "" ? (
              <img src={uploadIcon} className="mailImg" />
            ) : (
              <iframe
                onClick={() => fileRef.current.click()}
                src={file}
                className="importExcel"
              />
            )}
          </div>
          {file === "" && (
            <div
              onClick={() => {
                fileRef.current.click();
              }}
              className="w-100 d-flex ac-jc"
            >
              <p className="primary1 w-100 f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                +Import List
              </p>
            </div>
          )}
          <div className="w-100 d-flex ac-jc ">
            {file !== "" && (
              <button
                onClick={() => setImportList(false)}
                className="pointerView cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                Submit
              </button>
            )}
            {file !== "" && (
              <button
                onClick={() => {
                  fileRef.current.click();
                }}
                className="pointerView cust-btn addbtn mt-sm-3 ms-5 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                ReUpload
              </button>
            )}
          </div>
        </div>
        <input
          type="file"
          accept=".pdf,.doc,.jpeg,.docx,.png,.jpg,.xls,"
          onChange={(e) => {
            if (e?.target?.files?.length > 0) {
              setFile(window?.URL?.createObjectURL(e?.target?.files?.[0]));
            }
          }}
          className="d-none"
          ref={fileRef}
        />
      </div>
    </div>
  );
};

export default ImportListPopup;
