import React, { useEffect, useState } from "react";
import { product } from "../../assets/img";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sub_menu } from "../../redux/api/DummyJson";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useLocation } from "react-router-dom";

const PricingBoxComp = ({
  edit,
  setpriceBook,
  setPreviousOrder,
  status,
  type,
  method,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [marginBox, setMarginBox] = useState(false);
  const [priceCharge, setPriceCharge] = useState([]);
  const location = useLocation();
  const path = location?.pathname;
  const show = location?.state?.status;
  const [box1, setbox1] = useState("25%");
  const [box2, setbox2] = useState(50);
  const [box3, setbox3] = useState(100);
  const [box4, setbox4] = useState(150);
  const [box5, setbox5] = useState(250);
  const [box6, setbox6] = useState(500);
  const [box7, setbox7] = useState(1000);

  const [net1, setNet1] = useState();
  const [net2, setNet2] = useState();
  const [net3, setNet3] = useState();
  const [net4, setNet4] = useState();
  const [net5, setNet5] = useState();
  const [net6, setNet6] = useState();
  const [net7, setNet7] = useState();

  const addpriceCharge = () => {
    let temp = [...priceCharge];

    temp.push({
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      num7: "",
      text: "",
    });
    setPriceCharge(temp);
  };

  const removepriceCharge = (e, ind) => {
    let temp = [...priceCharge];
    temp.splice(ind, 1);
    setPriceCharge(temp);
  };

  const handlepriceCharge = (e, index) => {
    let temp = [...priceCharge];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setPriceCharge(temp);
  };

  // console.log(show);

  return (
    <>
      <div>
        <div className="w-100 d-flex res-flex as-jb gap-5 py-4 px-4 bg-lt-blue2 rounded-4 mt-3">
          <div className="w-100">
            <div>
              <div className="w-100">
                <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary mt-2">
                  {/* {method == "pricebook-lib" || method == "enterprises"
                    ? "Admin Pricing"
                    : "Vendor Price"} */}
                  Admin Pricing
                </p>
              </div>
              <div>
                <div className="">
                  <div className="overflow-scroll">
                    <table className="w-100">
                      <tr className="mt-4">
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Qty
                        </th>
                        <div className="my-3">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="25"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="50"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="100"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="150"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="250"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="500"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="1000"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                      {/* <tr>
                            <th className="  f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              <button
                                className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                                onClick={() => addpriceCharge()}
                              >
                                + add Charges
                              </button>
                            </th>
                          </tr>
                          {priceCharge?.map((item, ind) => {
                            return (
                              <tr>
                                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                  <span>
                                    <button
                                      className="cust-btn"
                                      onClick={() =>
                                        removepriceCharge(item, ind)
                                      }
                                    >
                                      <HighlightOffIcon
                                        style={{ width: "15px" }}
                                        className="mb-3"
                                      />
                                    </button>
                                  </span>
                                  <input
                                    className="mx-1 num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="text"
                                    style={{ width: "80px", height: "35px" }}
                                    value={item?.text}
                                    name="text"
                                    onChange={(e) => handlepriceCharge(e, ind)}
                                  />
                                </th>
                                <div className="mt-2">
                                  <td>
                                    <div className="d-flex w-100">
                                      <div className="mx-2">
                                        <input
                                          class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          type="number"
                                          placeholder="$90.00"
                                          maxlength={3}
                                          value={item?.num1}
                                          name="num1"
                                          onChange={(e) =>
                                            handlepriceCharge(e, ind)
                                          }
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="$85.00"
                                          type="number"
                                          value={item?.num2}
                                          name="num2"
                                          onChange={(e) =>
                                            handlepriceCharge(e, ind)
                                          }
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="$85.00"
                                          type="number"
                                          value={item?.num3}
                                          name="num3"
                                          onChange={(e) =>
                                            handlepriceCharge(e, ind)
                                          }
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="$75.00"
                                          type="number"
                                          value={item?.num4}
                                          name="num4"
                                          onChange={(e) =>
                                            handlepriceCharge(e, ind)
                                          }
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="$75.00"
                                          type="number"
                                          value={item?.num5}
                                          name="num5"
                                          onChange={(e) =>
                                            handlepriceCharge(e, ind)
                                          }
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="$75.00"
                                          type="number"
                                          value={item?.num6}
                                          name="num6"
                                          onChange={(e) =>
                                            handlepriceCharge(e, ind)
                                          }
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="$75.00"
                                          type="number"
                                          value={item?.num7}
                                          name="num7"
                                          onChange={(e) =>
                                            handlepriceCharge(e, ind)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </div>
                              </tr>
                            );
                          })}*/}
                      <tr className="mt-4">
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          {/* {method == "pricebook-lib" || method == "enterprises"
                            ? "Price"
                            : "Net"} */}
                          Price
                        </th>
                        <div className="my-3">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="25"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="50"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="100"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="150"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="250"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="500"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="1000"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                      {/* <tr className="mt-4">
                            <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              <button
                                className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                                onClick={() => SetMarginShow2(!marginShow2)}
                              >
                                + add Margin
                              </button>
                            </th>
                            {marginShow2 && (
                              <div className="my-3">
                                <td>
                                  <div className="d-flex w-100">
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        type="button"
                                        value="25"
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        type="button"
                                        value="50"
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        type="button"
                                        value="100"
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        type="button"
                                        value="150"
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        type="button"
                                        value="250"
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        type="button"
                                        value="500"
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        type="button"
                                        value="1000"
                                      />
                                    </div>
                                  </div>
                                </td>
                              </div>
                            )}
                          </tr>
                          <tr className="mt-4">
                            <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              Price
                            </th>
                            <div className="my-3">
                              <td>
                                <div className="d-flex w-100">
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="25"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="50"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="100"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="150"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="250"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="500"
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="button"
                                      value="1000"
                                    />
                                  </div>
                                </div>
                              </td>
                            </div>
                          </tr> */}
                    </table>
                  </div>
                </div>
                <div>
                  <div className="w-100">
                    <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary mt-2">
                      {/* {method == "pricebook-lib" || method == "enterprises"
                        ? "Computer Pricing"
                        : "Admin Pricing"} */}
                      Computer Pricing
                    </p>
                  </div>
                </div>
                <div className="w-100 d-flex ac-js mt-4">
                  <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    {/* {method == "pricebook-lib" || method == "enterprises"
                      ? "Pricebook Margin"
                      : "MSP :"} */}
                    Pricebook Margin
                  </p>

                  {/* <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
                    MSP :
                  </p> */}
                  <div className="mx-2 place-holde mx-4">
                    <input
                      class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center mx-1"
                      placeholder="5%"
                      type="number"
                    />
                  </div>
                </div>
                <div className="overflow-scroll">
                  <table className="w-100 ">
                    <tr className="mt-4">
                      <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        {/* {method == "pricebook-lib" || method == "enterprises"
                          ? "Price"
                          : "Net"} */}
                        Price
                      </th>
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                value="75"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                value="75"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                value="75"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                value="75"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                value="75"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                value="75"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                value="75"
                                type="number"
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
                {/* <div className="w-100 d-flex ac-js mt-4">
                  {/* <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    SCP :
                  </p> 
                  {/* <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
                    Admin Margin :
                  </p> 
                  <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    Admin Margin :
                  </p>
                  <div className="mx-2 place-holde mx-3">
                    <input
                      class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center mx-1"
                      placeholder="5%"
                      type="number"
                    />
                  </div>
                </div> */}
                {/* <div className="overflow-scroll">
                  <table className="w-100 ">
                    <tr className="mt-4">
                      <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Net
                      </th>
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div> */}
                <div className="w-100 d-flex ac-js mt-4">
                  <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    Overwrite Margin
                  </p>
                  {/* <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
                    SSP :
                  </p> */}
                  <div className="mx-2 place-holde mx-4">
                    <input
                      class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center mx-2"
                      placeholder="5%"
                      type="number"
                    />
                  </div>
                </div>
                <div className="overflow-scroll">
                  <table className="w-100 ">
                    <tr className="mt-4">
                      <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        {/* {method == "pricebook-lib" || method == "enterprises"
                          ? "Overwrite Price"
                          : "Net"} */}
                        Overwrite Price
                      </th>
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="w-50">
            <div className="w-100 d-flex ac-jc">
              <div className="w-100">
                <p class="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Show to Reseller
                </p>
              </div>
              <button
                className="100 cust-btn"
                onClick={() => setReseller(!reseller)}
              >
                <div class="button r button-1">
                  <input type="checkbox" class="checkbox" />
                  <div class="yes"></div>
                  <div class="no"></div>
                </div>
              </button>
            </div>
            {reseller && (
              <div>
                <div className="">
                  <div className="overflow-scroll">
                    <table className="w-100">
                      <tr className="mt-4">
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Qty
                        </th>
                        <div className="my-3">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="250"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="500"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="1000"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                      <tr>
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Net
                        </th>
                        <div className="mt-2">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$85.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="$75.00"
                                  type="number"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className=" d-flex ac-js mt-4">
                  <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    MSP
                  </p>
                  <div className="mx-2 place-holde">
                    <input
                      class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      placeholder="5%"
                      type="number"
                    />
                  </div>
                </div>
                <div className="overflow-scroll">
                  <table className="w-100">
                    <tr className="mt-4">
                      <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Net
                      </th>
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
                <div className=" d-flex ac-js mt-4">
                  <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    SCP
                  </p>
                  <div className="mx-2 place-holde">
                    <input
                      class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      placeholder="5%"
                      type="number"
                    />
                  </div>
                </div>
                <div className="overflow-scroll">
                  <table className="w-100">
                    <tr className="mt-4">
                      <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Net
                      </th>
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="$75.00"
                                type="number"
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
      {/* <div className="h-auto mt-4">
        <div className="d-flex">
          <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            Admin margin
          </p>
        </div>

        <div className="mx-2 mt-4">
          <input
            className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
            type="text"
            value={box1}
            onChange={(e) => setbox1(e.target.value)}
            disabled={!edit ? true : disabled ? true : false}
          />
        </div>

        <div className="">
          <div className="overflow-scroll">
            <p className="f4 fs-sm-16 mt-5 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              CSSP
            </p>
            <table className="">
              <tr className="mt-4">
                <th className="primary me-5 me-5 f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  MSP
                </th>
                <div className="my-3" style={{ display: "inline-block" }}>
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="text"
                          value={box1}
                          onChange={(e) => setbox1(e.target.value)}
                          disabled={!edit ? true : disabled ? true : false}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        </div>

        <div className="">
          <div className="overflow-scroll">
            {/* <p className="f4 fs-sm-16 mt-5 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            CSSP
          </p> 
            <table className="">
              <tr className="mt-4">
                <th className="primary me-5 me-5 f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  SSP
                </th>
                <div className="my-3" style={{ display: "inline-block" }}>
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="text"
                          value={box1}
                          onChange={(e) => setbox1(e.target.value)}
                          disabled={!edit ? true : disabled ? true : false}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr>
                <th className="primary me-5 f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Net
                </th>
                <div className="mt-2" style={{ display: "inline-block" }}>
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          placeholder="$90.00"
                          maxlength={3}
                          value={net1}
                          onChange={(e) => setNet1(e.target.value)}
                          disabled={!edit ? true : disabled ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="$85.00"
                          type="number"
                          value={net2}
                          onChange={(e) => setNet2(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="$85.00"
                          type="number"
                          value={net3}
                          onChange={(e) => setNet3(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="$75.00"
                          type="number"
                          value={net4}
                          onChange={(e) => setNet4(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="$75.00"
                          type="number"
                          value={net5}
                          onChange={(e) => setNet5(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="$75.00"
                          type="number"
                          value={net6}
                          onChange={(e) => setNet6(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="$75.00"
                          type="number"
                          value={net7}
                          onChange={(e) => setNet7(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        </div>

        {/* <div className="">
        <div className="overflow-scroll">
          <table className="">
            <tr className="mt-4">
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Qty
              </th>
              <div className="my-3" style={{ display: "inline-block" }}>
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        value={box1}
                        onChange={(e) => setbox1(e.target.value)}
                        disabled={!edit ? true : disabled ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        value={box2}
                        disabled={!edit ? true : false}
                        onChange={(e) => setbox2(e.target.value)}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        value={box3}
                        disabled={!edit ? true : false}
                        onChange={(e) => setbox3(e.target.value)}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 px-3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        disabled={!edit ? true : false}
                        value={box4}
                        onChange={(e) => setbox4(e.target.value)}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 px-3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        value={box5}
                        disabled={!edit ? true : false}
                        onChange={(e) => setbox5(e.target.value)}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 px-3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        value={box6}
                        disabled={!edit ? true : false}
                        onChange={(e) => setbox6(e.target.value)}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        value={box7}
                        disabled={!edit ? true : false}
                        onChange={(e) => setbox7(e.target.value)}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Cost
              </th>
              <div className="mt-2" style={{ display: "inline-block" }}>
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="$90.00"
                        maxlength={3}
                        value={net1}
                        onChange={(e) => setNet1(e.target.value)}
                        disabled={!edit ? true : disabled ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                        type="number"
                        value={net2}
                        onChange={(e) => setNet2(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                        type="number"
                        value={net3}
                        onChange={(e) => setNet3(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net4}
                        onChange={(e) => setNet4(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net5}
                        onChange={(e) => setNet5(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net6}
                        onChange={(e) => setNet6(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net7}
                        onChange={(e) => setNet7(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <th className="  f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                <button
                  className="cust-btn text-nowrap py-2 px-3 sm-btn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                  onClick={() => addpriceCharge()}
                >
                  + add charges
                </button>
              </th>
            </tr>
            {priceCharge?.map((item, ind) => {
              return (
                <tr>
                  <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    {" "}
                    <span>
                      <button
                        className="cust-btn"
                        onClick={() => removepriceCharge(item, ind)}
                      >
                        <HighlightOffIcon
                          style={{ width: "15px" }}
                          className="mb-3"
                        />
                      </button>
                    </span>
                    <input
                      className="mx-1 num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      type="text"
                      style={{ width: "120px", height: "35px" }}
                      value={item?.text}
                      name="text"
                      onChange={(e) => handlepriceCharge(e, ind)}
                      disabled={!edit ? true : false}
                    />
                  </th>
                  <div className="mt-2" style={{ display: "inline-block" }}>
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            placeholder="$90.00"
                            maxlength={3}
                            value={item?.num1}
                            name="num1"
                            onChange={(e) => handlepriceCharge(e, ind)}
                            disabled={!edit ? true : false}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="$85.00"
                            type="number"
                            value={item?.num2}
                            name="num2"
                            onChange={(e) => handlepriceCharge(e, ind)}
                            disabled={!edit ? true : false}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="$85.00"
                            type="number"
                            value={item?.num3}
                            name="num3"
                            onChange={(e) => handlepriceCharge(e, ind)}
                            disabled={!edit ? true : false}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="$75.00"
                            type="number"
                            value={item?.num4}
                            name="num4"
                            onChange={(e) => handlepriceCharge(e, ind)}
                            disabled={!edit ? true : false}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="$75.00"
                            type="number"
                            value={item?.num5}
                            name="num5"
                            onChange={(e) => handlepriceCharge(e, ind)}
                            disabled={!edit ? true : false}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="$75.00"
                            type="number"
                            value={item?.num6}
                            name="num6"
                            onChange={(e) => handlepriceCharge(e, ind)}
                            disabled={!edit ? true : false}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="$75.00"
                            type="number"
                            value={item?.num7}
                            name="num7"
                            onChange={(e) => handlepriceCharge(e, ind)}
                            disabled={!edit ? true : false}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              );
            })}
            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Net
              </th>
              <div className="mt-2" style={{ display: "inline-block" }}>
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="$90.00"
                        maxlength={3}
                        value={net1}
                        onChange={(e) => setNet1(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                        type="number"
                        value={net2}
                        onChange={(e) => setNet2(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                        type="number"
                        value={net3}
                        onChange={(e) => setNet3(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net4}
                        onChange={(e) => setNet4(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net5}
                        onChange={(e) => setNet5(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net6}
                        onChange={(e) => setNet6(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net7}
                        onChange={(e) => setNet7(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <th className="  f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                <button
                  className="cust-btn py-2 px-3 text-nowrap sm-btn mt-sm-3  mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                  onClick={() => setMarginBox(!marginBox)}
                >
                  +add margin
                </button>
              </th>
              {!marginBox && (
                <div className="mt-2" style={{ display: "inline-block" }}>
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          placeholder="%90.00"
                          maxlength={3}
                          value={net1}
                          onChange={(e) => setNet1(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="%85.00"
                          type="number"
                          value={net2}
                          onChange={(e) => setNet2(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="%85.00"
                          type="number"
                          value={net3}
                          onChange={(e) => setNet3(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="%75.00"
                          type="number"
                          value={net4}
                          onChange={(e) => setNet4(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="%75.00"
                          type="number"
                          value={net5}
                          onChange={(e) => setNet5(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="%75.00"
                          type="number"
                          value={net6}
                          onChange={(e) => setNet6(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="%75.00"
                          type="number"
                          value={net7}
                          onChange={(e) => setNet7(e.target.value)}
                          disabled={!edit ? true : false}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              )}
            </tr>

            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Distributor Price
              </th>
              <div className="mt-2" style={{ display: "inline-block" }}>
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="$90.00"
                        maxlength={3}
                        value={net1}
                        onChange={(e) => setNet1(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                        type="number"
                        value={net2}
                        onChange={(e) => setNet2(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                        type="number"
                        value={net3}
                        onChange={(e) => setNet3(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net4}
                        onChange={(e) => setNet4(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net5}
                        onChange={(e) => setNet5(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net6}
                        onChange={(e) => setNet6(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$75.00"
                        type="number"
                        value={net7}
                        onChange={(e) => setNet7(e.target.value)}
                        disabled={!edit ? true : false}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
        </div>
      </div>
      <div className="mt-5">
        <button
          className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => {
            setpriceBook(true);
            setPreviousOrder(false);
          }}
        >
          Back
        </button>
      </div> 
      </div> */}
    </>
  );
};

export default PricingBoxComp;
