import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const SalesOrderFileView = ({ fileViewToggle, file }) => {
  return (
    <div className="delete-popup">
      <div className="w-90 w-md-40 h-50">
        {" "}
        <button
          onClick={() => fileViewToggle()}
          className="cust-btn d-flex ac-je w-100"
        >
          <HighlightOffIcon />
        </button>
        <div className="mt-3 gap-4 d-flex w-100 ac-jc h-50 overflow-scroll">
          <iframe src={file} height={"100%"} width={"100%"} />
        </div>
        <div className="mt-3 gap-4 d-flex w-100 ac-jc">
          <button
            onClick={() => fileViewToggle()}
            class="position-relative align-selfe-center cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
export default SalesOrderFileView;
