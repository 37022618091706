import React from "react";
import {
  art1,
  art2,
  head,
  logos,
  tshirt1,
  tshirt2,
  tshirt3,
} from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ImageGallery from "react-image-gallery";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { presentation_list } from "../redux/api/DummyJson";
import { useNavigate } from "react-router-dom";

const PresentationListA = () => {
  //     const images=[ {
  //           original: tshirt1,
  //           thumbnail: tshirt1,
  //         },
  //         {
  //           original: tshirt2,
  //           thumbnail: tshirt2,
  //         },
  //         {
  //           original: tshirt3,
  //           thumbnail: tshirt3,
  //         },
  //      ];
  const navigate = useNavigate();

  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="ps-2 font-blue2">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                PRESENTATION #110208 for AAHIKA
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project Name
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        {presentation_list?.map((item, ind) => {
          return (
            <div className="my-3 d-flex  flex-wrap ">
              <div
                className="img col-md-6 bg-primary2"
                style={{
                  // backgroundColor: "#ecfaff",
                  borderRadius: "22px",
                  padding: "12px",
                  textAlign: "center",
                }}
              >
                <ImageGallery items={item?.image1} />
              </div>
              <div className="col-md-6 p-4">
                <div>
                  <h2 className="f4 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                    {item?.head}
                  </h2>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    {item?.para}
                  </p>
                  <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray my-2">
                    Price label: Rs {item?.price_label1}
                  </h2>
                </div>
                <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                  Pricing
                </p>
                <div
                  className="my-3 bg-primary2 p-3"
                  style={{ lineHeight: "40px" }}
                >
                  <table className="w-100 overflow-scroll  ">
                    <tr className=" text-center">
                      <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        QTY{" "}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.qty1}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.qty2}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.qty3}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.qty4}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.qty5}
                      </td>
                    </tr>
                    <tr className="text-center">
                      <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        RS{" "}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.rs1}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.rs2}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.rs3}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.rs4}
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item.rs5}
                      </td>
                    </tr>
                  </table>
                </div>
                <div>
                  <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                    Fixed Charges
                  </p>

                  <div
                    className="p-3 my-3 bg-primary2 w-100"
                    style={{ lineHeight: "40px" }}
                  >
                    <table className=" w-100  overflow-scroll">
                      <tr>
                        <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Fixed Charges1
                        </td>
                        <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end">
                          {item.fc1}
                        </td>
                      </tr>
                      <tr>
                        <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Setup Cost
                        </td>
                        <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end">
                          {item.fc2}
                        </td>
                      </tr>
                      <tr>
                        <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Setup Cost
                        </td>
                        <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end">
                          {item.fc3}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="d-flex flex-wrap ">
                    <div className="col-md-4">
                      <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                        Colors:
                      </p>
                      <div className="d-flex">
                        <div className="primary">
                          <FiberManualRecordIcon />
                        </div>
                        <div className="lt-Blue">
                          <FiberManualRecordIcon />
                        </div>
                        <div className="gray">
                          <FiberManualRecordIcon />
                        </div>
                        <div className="gray1">
                          <FiberManualRecordIcon />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                        Sizes:
                      </p>
                      <div className="d-flex">
                        <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                          XS
                        </h2>
                        <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                          S
                        </h2>
                        <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                          M
                        </h2>
                        <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                          L
                        </h2>
                        <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 px-1 bg-lt-blue1 rounded">
                          XL
                        </h2>
                        <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                          XXL
                        </h2>
                        <h2 className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                          XXXL
                        </h2>
                      </div>
                    </div>
                  </div>
                  <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray my-3 ">
                    Comments :
                  </h2>
                  <div className="d-flex my-1">
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                      Sakthi :
                    </h2>
                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 gray mx-1">
                      Nice Product
                    </p>
                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  mx-1">
                      - Delete
                    </p>
                  </div>
                  <div>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 gray mb-3">
                      04/04/2024 - 06:11PM
                    </p>
                  </div>

                  <div className="w-100">
                    <textarea
                      className="editBtn bg-primary2 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      style={{ height: "100px" }}
                      placeholder="Comment on this item..."
                    ></textarea>
                  </div>
                  <div className="d-flex ac-jb">
                    <button className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4">
                      Add Comment <PlaylistAddCheckIcon />
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* <div className="my-3 d-flex  flex-wrap ">
          <div
            className="img col-md-6 "
            style={{
              backgroundColor: "#ecfaff",
              borderRadius: "22px",
              padding: "12px",
              textAlign: "center",
            }}
          >
            <ImageGallery items={images} />
          </div>
          <div className="col-md-6 p-4">
            <div style={{}}>
              <h2 className="f4 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
               20 oz Matt Solid State Himalayan Tumbler
              </h2>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                304 stainless steel, After filled with cold breverages,
                Countered welt collar. tereaway label Tumble Try Medium. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Eligendi
                cupiditate quisquam
              </p>
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray my-2">
                Price label: Rs 475.00
              </h2>
            </div>
            <p className="f3 fs-xs-9 fs-sm-10 fs-md-12 fs-lg-12 fs-xl-12 fs-xxl-13 primary">
               Pricing
              </p>
            <div
              className="my-3 bg-lt-blue3 p-3"
              style={{ lineHeight: "40px" }}
            >
            
              <table className="w-100 overflow-scroll  ">
                <tr className=" text-center">
                  <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    QTY{" "}
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    25
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    50
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    100
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    150
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    250
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    RS{" "}
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.131.38
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.32.4
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.244.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.435.0
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Rs.498.00
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <p className="f3 fs-xs-9 fs-sm-10 fs-md-12 fs-lg-12 fs-xl-12 fs-xxl-13 primary">
                Fixed Charges
              </p>

              <div
                className="p-3 my-3 bg-lt-blue3 w-100"
                style={{ lineHeight: "40px" }}
              >
                <table className=" w-100  overflow-scroll">
                  <tr>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Fixed Charges1
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end">
                      Rs.250.00
                    </td>
                  </tr>
                  <tr>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Setup Cost
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end">
                      Rs.498.00
                    </td>
                  </tr>
                  <tr>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Setup Cost
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end">
                      Rs.348.00
                    </td>
                  </tr>
                </table>
              </div>
              <div className="d-flex flex-wrap ">
                <div className="col-md-4">
                  <p className="f3 fs-xs-9 fs-sm-10 fs-md-12 fs-lg-12 fs-xl-12 fs-xxl-13 primary">
                    Colors:
                  </p>
                  <div className="d-flex">
                    <div className="primary">
                      <FiberManualRecordIcon />
                    </div>
                    <div className="lt-Blue">
                      <FiberManualRecordIcon />
                    </div>
                    <div className="gray">
                      <FiberManualRecordIcon />
                    </div>
                    <div className="gray1">
                      <FiberManualRecordIcon />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <p className="f3 ms-xl-3 fs-xs-9 fs-sm-10 fs-md-12 fs-lg-12 fs-xl-12 fs-xxl-13 primary">
                    Sizes:
                  </p>
                  <div className="d-flex">
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                      XS
                    </h2>
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                      S
                    </h2>
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                      M
                    </h2>
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                      L
                    </h2>
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                      XL
                    </h2>
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                      XXL
                    </h2>
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray m-2 ">
                      XXXL
                    </h2>
                  </div>
                </div>
              </div>
              <div className="w-100">
              <textarea
                className="editBtn bg-lt-blue3 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                style={{ height: "100px" }} placeholder="Comment on this item..."
              ></textarea>
            </div>
            <button
                className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4"
              >
                Add Comment{" "}<PlaylistAddCheckIcon />
                
              </button>
            </div>
          </div>
        </div> */}
        <div className="d-flex justify-content-end">
                      <div>
                        <button
                          className=""
                          style={{
                            backgroundColor: "#f82a2a",
                            borderRadius: "10px",
                            padding: "10px",
                            border: "none",
                          }}
                          onClick={() => {
                            navigate("/poproduct-request-a");
                          }}
                        >
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                            Request Change
                          </p>
                        </button>
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor: "#0082ca",
                            borderRadius: "10px",
                            padding: "10px",
                            border: "none",
                            marginLeft: "5px",
                          }}
                          onClick={() => {
                            navigate("/poproduct-request-a");
                          }}
                        >
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white ">
                            Approve Order
                          </p>
                        </button>
                      </div>
                    </div>
      </div>

      <div className="">
        <div
          className="mt-3  px-2"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="d-flex flex-wrap ac-jb w-90 m-auto">
            <div className="">
              <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
                Roger Daniel
              </h2>
              <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
                roger@companystore.io
              </a>
              <button
                class=" d-flex ac-jc ps-3 mt-5"
                style={{
                  backgroundColor: "#0082ca",
                  border: "none",
                  borderRadius: "7px",
                  padding: "5px",
                  color: "#fff",
                }}
              >
                <UnsubscribeIcon />

                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                  Contact us
                </p>
              </button>
            </div>

            <div className="col-md-4">
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                merchHQ Pvt.Ltd.
              </p>
              <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
                #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
                Kartnataka, India.
              </p>
              <div class="d-flex white mt-sm-2 ">
                <FacebookRoundedIcon className="me-2" />

                <TwitterIcon className="mx-2" />

                <LinkedInIcon className="mx-2" />

                <InstagramIcon className="mx-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationListA;
