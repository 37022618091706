import React from "react";
import { head, logos, tshirt1 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import CircleIcon from "@mui/icons-material/Circle";

const PoProductSupplierApproved = () => {
  return (
    <div className="bg-white py-3" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
      <div
          className="d-flex mt-3  banner"
          style={{
            backgroundImage:
              "url( https://media.geeksforgeeks.org/wp-content/uploads/rk.png)",
            padding: "20px",
            flexWrap: "wrap",
          }}
        >
          <div className="">
            <img
              alt="img"
              src={logos}
              className=" ps-3"
              style={{ width: "150px", objectFit: "cover" }}
            />
          </div>

          <div className="ps-2">
            <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
              MerchHQ Pvt.Ltd.
            </h2>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
              INVOICE for 5SK
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Shankar cement
            </p>
          </div>
        </div>
        <div className="my-3">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Important! Please read the following carefully.
          </p>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            By approving the Purchase order, you are acknowledging you have
            thoroughly reviewed all of the following aspects of this order:
          </p>
        </div>
        <div className="d-flex flex-wrap my-2">
          <div className="col-md-6">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              - Quantities
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              - Size Breakdowns
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              - Product colors
            </p>
          </div>
          <div className="col-md-6">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              - Imprint Colors
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              - Decoration Methods
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              - Artwork (including spelling)
            </p>
          </div>
        </div>
        <div>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 my-2">
            Please fill out the form below to put the order into production.
          </p>
          <div className="d-flex flex-wrap ">
            <div className="col-md-6">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 my-2">
                Your Name:
              </p>
              <input
                className="p-2  w-70"
                placeholder="Enter your full name"
              ></input>
            </div>
            <div className="col-md-6">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 my-2">
                Change Purchase Order status to:
              </p>
              <div className="d-flex my-2 ">
                <div className="green1 ">
                  <CircleIcon /> <span className="gray">Approved</span>
                </div>
                <div className="gray ms-3">
                  <CircleIcon /> <span className="gray">Change Required</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 my-2">
                Additional Notes:
              </p>
              <textarea
                className="p-2  w-100 "
                placeholder="Enter your Additional Notes  "
              ></textarea>
            </div>
          </div>
        </div>
        <div className="d-flex ac-jc my-3">
          <button
            class=" d-flex px-3 mt-sm-2 bg-green1"
            style={{
              border:"0",
              padding: "5px",
              color: "#fff",
            }}
          >
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
              Submit
            </p>
          </button>
        </div>
      </div>
      
    </div>
  );
};

export default  PoProductSupplierApproved ;
