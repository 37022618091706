import React, { useState } from "react";
import TvIcon from "@mui/icons-material/Tv";
import {
  sub_menu,
  Country,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  Supplier,
  supplier,
  supplier_rating,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Edit, EditCalendarOutlined } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const LibraryLeftComp = ({ edit }) => {
  const navigate = useNavigate();
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);
  const [hsnTypeShow, setHsnTypeShow] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(false);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [catogory2, setCatogory2] = useState("");
  const [catogory3, setCatogory3] = useState("");
  const [selectBox, setSelectBox] = useState([]);

  const [editComp, setEditComp] = useState(false);

  const [Dropdown, setDrop] = useState(0);

  // input box
  const [catogory, setCatogory] = useState("Mobile phones");
  const [productName, setProductName] = useState("Vivo");
  const [productSuplierCode, setProductSuplierCode] = useState("CODE1003");
  const [decorativeMethod, setDecorativeMethod] = useState("");
  const [hsnType, sethsnType] = useState("");
  const [packingSize, setPackingSize] = useState("");
  const [country, setCountry] = useState("India");
  const [MRP, setMRP] = useState("10,00.00");
  const [color, setColor] = useState("Red");
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);

  let options = [
    { value: "Bottle", label: "Bottle" },
    { value: "Shirts", label: "Shirts" },
    { value: "T-Shirts", label: "T-Shirts" },
  ];

  const onTagInputChange = (e) => {
    let tmp = {
      value: e,
      label: e,
      type: "input",
    };
    let filtered = options?.filter((item) => item?.type === "input");
    if (filtered?.length === 0) {
      options.push(tmp);
    } else {
      options.pop();
      options.push(tmp);
    }
  };

  const employees = [
    { id: 4, name: "Dean", country: "Denmark" },
    { id: 3, name: "Carl", country: "Canada" },
    { id: 2, name: "Bob", country: "Belgium" },
    { id: 1, name: "Alice", country: "Austria" },
    { id: 5, name: "Ethan", country: "Egypt" },
  ];

  const strAscending = [...employees].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  console.log(strAscending);

  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 2) {
      setCatogoryShow(true);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };
  const onCatogory2 = (e) => {
    setCatogory2(e.target.value);
    if (catogory2.length >= 2) {
      setCatogoryShow2(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onCatogory3 = (e) => {
    setCatogory3(e.target.value);
    if (catogory3.length >= 2) {
      setCatogoryShow3(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onProduct = (e) => {
    setProductName(e.target.value);
    if (productName.length >= 2) {
      setProductNameShow(true);
    } else {
      if (productName.length >= 0) setProductNameShow(false);
      // setProductSuplierCode('')
    }
    setCatogoryShow(false);
  };
  const onDecorative = (e) => {
    setDecorativeMethod(e.target.value);
    if (decorativeMethod.length >= 2) {
      setDecorativeShow(true);
    } else {
      if (decorativeMethod.length >= 0) setDecorativeShow(false);
    }
  };
  const onHsnTyoe = (e) => {
    sethsnType(e.target.value);
    if (hsnType.length >= 2) {
      setHsnTypeShow(true);
    } else {
      if (hsnType.length >= 0) setHsnTypeShow(false);
    }
  };
  const onPackingSize = (e) => {
    setPackingSize(e.target.value);
    if (packingSize.length >= 2) {
      setPackingSizeShow(true);
    } else {
      if (packingSize.length >= 0) setPackingSizeShow(false);
    }
  };
  const onCountry = (e) => {
    setCountry(e.target.value);
    if (country.length >= 2) {
      setCountryShow(true);
    } else {
      if (country.length >= 0) setCountryShow(false);
    }
  };

  const [number, setNumber] = useState([]);
  const handleSort = (value) => {
    console.log(value);
  };
  return (
    <div className="w-50">
      <div className="w-100">
        <p
          className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 mb-2"
          style={{ color: "#07679c", fontWeight: "600" }}
        >
          Current Status :
        </p>
        <div className="d-flex ac-jb flex-wrap">
          <div className="col-md-4 d-flex align-items-center">
            <label className="form-label">E-commerce:</label>
            <button className="100 cust-btn ps-1 pb-1">
              <div class="toggle_btn">
                <input type="checkbox" class="checkbox" />
                <div class="yes"></div>
                <div class="no"></div>
              </div>
            </button>
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="form-label">Enterprises:</label>
            <button className="100 cust-btn ps-1 pb-1">
              <div class="toggle_btn">
                <input type="checkbox" class="checkbox" />
                <div class="yes"></div>
                <div class="no"></div>
              </div>
            </button>
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="form-label">Reseller:</label>
            <button className="100 cust-btn ps-1 pb-1">
              <div class="toggle_btn">
                <input type="checkbox" class="checkbox" />
                <div class="yes"></div>
                <div class="no"></div>
              </div>
            </button>
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="form-label">Vendor:</label>
            <button className="100 cust-btn ps-1 pb-1">
              <div class="toggle_btn">
                <input type="checkbox" class="checkbox" />
                <div class="yes"></div>
                <div class="no"></div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex ac-jb flex-m-r flex-wrap">
        <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
          <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Category
          </p>
          <div className="position-relative z-3">
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={catogory}
              onChange={onCatogory}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setCatogoryShow(!catogoryShow);
                setChildMenu(false);
                setCatogoryShow2(false);
                setCatogoryShow3(false);
                setProductNameShow(false);
                setPackingSizeShow(false);
                setCountryShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
          </div>
          {catogoryShow && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setCatogoryShow(!catogoryShow)}
            />
          )}
          <div
            className={`${
              catogoryShow && "submenu_1"
            } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setCatogoryShow(false);
                      setCatogory(item?.list);
                      setChildMenu(false);
                      setSubcatogory(true);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
          <div className={childMenu ? "submenu_child_1" : "submenu_child_2"}>
            <div
              className={`${
                catogoryShow && "submenu_2"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    {item.list !== "Game consoles" && (
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setCatogoryShow(false);
                          setCatogory(item?.list);
                          setChildMenu(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    )}
                    {item.list === "Game consoles" && (
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => setChildMenu(!childMenu)}
                      >
                        {item?.list}
                      </button>
                    )}
                    {item.list === "Game consoles" && (
                      <button
                        className="cust-btn position-relative"
                        onClick={() => {
                          setChildMenu(!childMenu);
                          setProductNameShow(false);
                          setCountryShow(false);
                          setPackingSizeShow(false);
                        }}
                      >
                        {childMenu ? (
                          <KeyboardArrowRightIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {subcatogory && (
          <>
            <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category 1
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={catogory2}
                  onChange={onCatogory2}
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setCatogoryShow2(!catogoryShow2);
                    setCatogoryShow(false);
                    setCatogoryShow3(false);
                    setProductNameShow(false);
                    setPackingSizeShow(false);
                    setCountryShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              </div>
              {catogoryShow2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setCatogoryShow2(!catogoryShow2)}
                />
              )}
              <div
                className={`${
                  catogoryShow2 && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setCatogoryShow2(false);
                          setCatogory2(item?.list);
                          setSubcatogory2(true);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category Code
              </p>
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                disabled={edit ? false : true}
                value={productSuplierCode}
                // onChange={(e) => setProductSuplierCode(e.target.value)}
              />
            </div>{" "}
          </>
        )}
        {subcatogory2 && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Sub Category 2
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={catogory3}
                onChange={onCatogory3}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCatogoryShow3(!catogoryShow3);
                  setChildMenu(false);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {catogoryShow3 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setCatogoryShow3(!catogoryShow3)}
              />
            )}
            <div
              className={`${
                catogoryShow3 && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setCatogoryShow3(false);
                        setCatogory3(item?.list);
                        setChildMenu(false);
                        setSubcatogory3(true);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product
          </p>
          <div className="position-relative">
            <input
              placeholder="Required Field"
              className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={productName}
              onChange={onProduct}
              disabled={edit ? false : true}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setProductNameShow(!productNameShow);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            <div
              className={`${
                productNameShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setProductNameShow(false);
                        setProductName(item?.list);
                        setProductSuplierCode("code1003");
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            SKU Code
          </p>
          <input
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            disabled={edit ? false : true}
            value={productSuplierCode}
            // onChange={(e) => setProductSuplierCode(e.target.value)}
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            MRP
          </p>
          <input
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={MRP}
            onChange={(e) => setMRP(e.target.value)}
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Decoration Method
          </p>
          <textarea
            value={
              "Game consoles,Game consoles,Game consoles,Game consoles,Game consoles,Game consoles"
            }
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Country of Origin
          </p>
          <div className="position-relative">
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={country}
              onChange={onCountry}
              disabled={edit ? false : true}
            />
            {/* <button
              className="drop_down cust-btn"
              onClick={() => {
                edit ? setCountryShow(!countryShow) : setCountryShow(false);
              }}>
              <KeyboardArrowDownIcon />
            </button> */}
          </div>
          <div
            className={`${
              countryShow && "submenu_1"
            } submenu_cont_1 overflow-scroll z-3`}
          >
            {Country?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setCountryShow(false);
                      setCountry(item?.list);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Packaging Size
          </p>
          <input
            value={color}
            onChange={(e) => setColor(e.target.value)}
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            HSN Code
          </p>
          <input
            value={"HSN0001"}
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Tags
          </p>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#07679c",
                borderRadius: "8px",
              }),
            }}
            onInputChange={onTagInputChange}
            options={options}
            isMulti
            classNamePrefix=""
            className="w-100  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2"
            onChange={(e) => setSelectBox(e)}
          />
        </div>
      </div>
      <div className="w-100">
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
          Product Description
        </p>
        <textarea
          placeholder="Autogenerate"
          disabled={edit ? false : true}
          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          value="Click on an image to download it, and on a file name to edit it. You can also create folders, and drag artwork into them."
        />
      </div>
      <div className="d-flex ac-jb flex-m-r">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Size
          </p>
          <input
            value={"XL"}
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Weight
          </p>
          <input
            value={"20KG"}
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
      </div>
      <div className="d-flex ac-jb flex-m-r">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Dimension
          </p>
          <input
            value={"30 x 30 x 45"}
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Material
          </p>
          <input
            value={"Classic"}
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
      </div>
      <div className="d-flex ac-jb flex-m-r">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Style
          </p>
          <input
            value={"Gold"}
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Color
          </p>
          <input
            value={"Red, Blue"}
            placeholder="Required Field"
            disabled={edit ? false : true}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
      </div>

      <div className="w-100 overflow-scroll mt-4">
        <table className="w-100">
          <tr className="mt-4">
            <div className="">
              <td>
                <div className="d-flex w-100">
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 cp fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "50px" }}
                    >
                      {true ? (
                        <CheckBoxOutlined onClick={null} />
                      ) : (
                        <CheckBoxOutlineBlank onClick={null} />
                      )}
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                      style={{ width: "200px" }}
                    >
                      Variant
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "100px" }}
                    >
                      Available
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "100px" }}
                    >
                      SKU
                    </p>
                  </div>
                </div>
              </td>
            </div>
          </tr>
          <tr className="mt-2">
            <div className="my-3">
              <td>
                <div className="d-flex w-100 my-3">
                  <div className="d-flex ac-jc mx-2" style={{ width: "50px" }}>
                    <button className="mx-2 d-flex ac-jc cust-btn">
                      {true ? (
                        <CheckBoxOutlined
                          className="primary cp"
                          onClick={null}
                        />
                      ) : (
                        <CheckBoxOutlineBlank
                          className="primary cp"
                          onClick={null}
                        />
                      )}
                    </button>
                  </div>
                  <div className="mx-2">
                    <div
                      class="num-cls-btn d-flex overflow-scroll p-2"
                      style={{ width: "200px" }}
                    >
                      <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                        XL/Red/Classic/Gold
                      </p>
                    </div>
                  </div>
                  <div className="mx-2">
                    <input
                      class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      type="text"
                      placeholder="100"
                      style={{ width: "100px" }}
                    />
                  </div>
                  <div className="mx-2">
                    <input
                      class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      type="text"
                      placeholder="150"
                      style={{ width: "100px" }}
                    />
                  </div>
                </div>
                <div className="d-flex w-100 my-3">
                  <div className="d-flex ac-jc mx-2" style={{ width: "50px" }}>
                    <button className="mx-2 d-flex ac-jc cust-btn">
                      {true ? (
                        <CheckBoxOutlined
                          className="primary cp"
                          onClick={null}
                        />
                      ) : (
                        <CheckBoxOutlineBlank
                          className="primary cp"
                          onClick={null}
                        />
                      )}
                    </button>
                  </div>
                  <div className="mx-2">
                    <div
                      class="num-cls-btn d-flex overflow-scroll p-2"
                      style={{ width: "200px" }}
                    >
                      <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                        XL/Blue/Classic/Gold
                      </p>
                    </div>
                  </div>
                  <div className="mx-2">
                    <input
                      class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      type="text"
                      placeholder="100"
                      style={{ width: "100px" }}
                    />
                  </div>
                  <div className="mx-2">
                    <input
                      class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      type="text"
                      placeholder="150"
                      style={{ width: "100px" }}
                    />
                  </div>
                </div>
                <div className="d-flex w-100 my-3">
                  <div className="d-flex ac-jc mx-2" style={{ width: "50px" }}>
                    <button className="mx-2 d-flex ac-jc cust-btn">
                      {true ? (
                        <CheckBoxOutlined
                          className="primary cp"
                          onClick={null}
                        />
                      ) : (
                        <CheckBoxOutlineBlank
                          className="primary cp"
                          onClick={null}
                        />
                      )}
                    </button>
                  </div>
                  <div className="mx-2">
                    <div
                      class="num-cls-btn d-flex overflow-scroll p-2"
                      style={{ width: "200px" }}
                    >
                      <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                        XL/Blue/Standard/Gold
                      </p>
                    </div>
                  </div>
                  <div className="mx-2">
                    <input
                      class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      type="text"
                      placeholder="100"
                      style={{ width: "100px" }}
                    />
                  </div>
                  <div className="mx-2">
                    <input
                      class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      type="text"
                      placeholder="150"
                      style={{ width: "100px" }}
                    />
                  </div>
                </div>
              </td>
            </div>
          </tr>
        </table>
      </div>

      <div className="w-100 my-3">
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
          Product Website
        </p>
        <div className="d-flex ac-js gap-2">
          <TvIcon className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start" />
          <p className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
            Link will open a new tab
          </p>
        </div>
      </div>
      <div className="w-100 mt-5">
        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
          Last updated on August 28, 2023 at 03:10 AM
        </p>
      </div>
      <div className="btn-w-cust-cont mt-4 w-100">
        <button
          class="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default LibraryLeftComp;
