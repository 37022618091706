import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import Product1Comp from "../components/OurProductComp/Product1/Product1Comp";
import Product2Comp from "../components/OurProductComp/Product2/Product2Comp";
import Product3Comp from "../components/OurProductComp/Product3/Product3Comp";
import Product4Comp from "../components/OurProductComp/Product4/Product4Comp";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toggleOff, toggleOn } from "../assets/img";
import { useNavigate } from "react-router-dom";

const AddOurProductScreen = () => {
  const navigate = useNavigate();
  const [totalProd, setTotalProd] = useState([1]);
  const [selProd, setSelprod] = useState(1);

  const onAddProdClick = () => {
    let tmp = [...totalProd];
    tmp.push(1);
    setTotalProd([...tmp]);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />

      <div className="d-flex ac-jb mt-4">
        <div className="d-flex ac-js w-80 overflow-scroll">
          {/* <DoneIcon className="white bg-green1 f2 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " /> */}

          {/* <div onClick={() => setSelprod(1)} className="d-flex ac-jc">
            <p
              className={`${
                selProd === 1 ? "bg-primar" : "bg-primary1"
              } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}>
              1
            </p>
            <p
              className={`${
                selProd === 1 ? "black" : "primary1"
              } f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-nowrap`}>
              Product 1
            </p>
          </div> */}

          <div className="d-flex ac-js mt-4">
            <p
              className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary d-flex align-items-center gap-1"
              role={"button"}
              onClick={() => navigate(-1)}
            >
              <KeyboardBackspaceIcon />
            </p>
            {totalProd?.map((it, ind) => {
              return (
                <>
                  {ind === 0 ? null : (
                    <div className="border-bottom w-5 border-primary1" />
                  )}
                  <div
                    role="button"
                    onClick={() => setSelprod(ind + 1)}
                    className="d-flex ac-jc"
                  >
                    <p
                      className={`${
                        selProd === ind + 1 ? "bg-primar" : "bg-primary1"
                      } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
                    >
                      {ind + 1}
                    </p>
                    <p
                      className={`${
                        selProd === ind + 1 ? "black" : "primary1"
                      } f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-nowrap`}
                    >
                      Product {ind + 1}
                    </p>
                  </div>
                </>
              );
            })}{" "}
          </div>
        </div>

        <div className="d-flex ac-je w-20">
          <button
            onClick={onAddProdClick}
            className="cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add another
          </button>
        </div>
      </div>

      <div className="d-flex ac-jb  mt-5">
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary d-flex ac-jc">
          Product {selProd}{" "}
          <span className="m-0 p-0 d-flex">
            <img src={toggleOn} className="toggleOnDes mx-3" alt="icon" />
          </span>
        </p>
      </div>

      {totalProd?.map((item, ind) => {
        console.log(selProd, ind + 1);
        return (
          <Product1Comp
            ind={ind}
            setSelprod={setSelprod}
            showComp={selProd === ind + 1 ? true : false}
          />
        );
      })}
    </div>
  );
};

export default AddOurProductScreen;
