import { useNavigate } from "react-router-dom";
import {
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
  searchIcon,
} from "../../assets/img";
import {
  customerDecoList,
  noncustomerDecoList,
} from "../../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";

const NonRegisteredReqList = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Non-Registered Request List
        </p>
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div>
          <div>
            <p className="w-100 f3 primary fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
              Filter
            </p>
          </div>
          <div className="w-100 mx-3 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 position-relative">
            <select
              placeholder="Filter"
              className="w-100 bg-transparent border-0 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 "
              name=""
              id=""
            >
              <option>Preset</option>
              <option>Bulk</option>
              <option>Individual pack</option>
              <option>Ecommerce</option>
            </select>
          </div>
        </div>
        <div className="d-flex ac-jb flex-md-row flex-column">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4">
            Total Count : {"03"}
          </p>
          <div className="pointerView d-flex mt-4 me-3">
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          {/* <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            + Add Non-Registered Request List
          </button> */}
        </div>
      </div>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  E-Commerce ID
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Request ID
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Order Rep name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Client Rep Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Request Type
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Order Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {noncustomerDecoList?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.customerId}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                      {item?.reqId}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.OrderRepname}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.ClientRepName}
                    </p>
                  </td>

                  <td>
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2"
                      name=""
                      id=""
                    >
                      <option>Preset</option>
                      <option>Bulk</option>
                      <option>Individual pack</option>
                      <option>Ecommerce</option>
                    </select>
                  </td>

                  <td>
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2"
                      name=""
                      id=""
                    >
                      <option>Request</option>
                      <option>In-process</option>
                      <option>Collaborate</option>
                      <option>Presentation Ready</option>

                      <option>Presentation Accept</option>
                      <option>Presentation Reject</option>
                      <option>Estimation Ready</option>

                      <option>Estimation Accept</option>
                      <option>Estimation Reject</option>
                      <option>Customer Paid</option>
                    </select>
                  </td>

                  <td>
                    <div className="d-flex ac-jc">
                      {item?.status === false ? (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      ) : (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className=" d-flex ac-jc primary1">
                      <div
                        onClick={() =>
                          navigate("/view-request-details-customer", {
                            state: { data: item },
                          })
                        }
                        className="viewBoxDes pointerView p-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                      
                      <div
                        onClick={() =>
                          navigate("/view-request-details-customer", {
                            state: { type: "edit", data: item },
                          })
                        }
                        className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                  4{" "}
                </p>
              </td>
              <td>
                <p
                  className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                  // onClick={() => tabData(3)}
                >
                  NCMHQ004
                </p>
              </td>
              <td>
                <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                  RMHQ004
                </p>
              </td>
              <td>
                <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                  OR765625
                </p>
              </td>
              <td>
                <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                  CR896873
                </p>
              </td>

              <td>
                <select
                  style={{ backgroundColor: "#eee" }}
                  placeholder=""
                  className="w-100 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2"
                  name=""
                  id=""
                  disabled
                >
                  <option>Ecommerce</option>
                </select>
              </td>

              <td>
                <button className="w-100 bg-transparent editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2">
                  <p class="w-100 f3 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                    Paid
                  </p>
                </button>
              </td>

              <td>
                <div className="d-flex ac-jc">
                  <div>
                    <img src={toggleOn} className="toggleOnDes" />
                  </div>
                </div>
              </td>
              <td>
                <div className=" d-flex ac-jc primary1">
                  <div
                    onClick={() =>
                      navigate("/view-request-details-customer", {
                        state: { data2: true },
                      })
                    }
                    className="viewBoxDes pointerView p-2 bg-white rounded-2"
                  >
                    <img src={viewIcon} className="viewDes" />
                  </div>
                  <div
                        onClick={() =>
                          navigate("/pending-order", {
                            // state: { data: item },
                          })
                        }
                        className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                      >
                        <p className="pointerView text-nowrap  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          View Manage Order
                        </p>
                      </div>
                  <div
                    style={{ display: "none" }}
                    onClick={() =>
                      navigate("/view-request-details-customer", {
                        // state: { type: "edit", data: item },
                        state: { data2: true },
                      })
                    }
                    className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                  >
                    <img src={editIcon} className="viewDes" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default NonRegisteredReqList;
