import React, { useEffect, useState } from "react";
import { emptyImg, product, profilePic, uploadIcon } from "../../../assets/img";
import Carousel from "react-bootstrap/Carousel";
import { productDetailImg } from "../../../redux/api/constants";
import { EditAttributes } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { owl_List, product_image } from "../../../redux/api/DummyJson";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ImageCroper from "../../Popup/ImageCroper";

const ImageUplodeComp = ({ vendor, vendor2, edit, colorImg }) => {
  const [logo, setLogo] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [index, setIndex] = useState(0);
  const [imageShow, setImageShow] = useState(false);

  const [image, setImage] = useState([]);

  const [Indeximage, setIndexImage] = useState("");
  const [imageName, setImageName] = useState("defauld");
  const [defaultImage, setDefaultImage] = useState(profilePic);

  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  // const [textShow2, setTextShow2] = useState(false)
  const [fistShow, setFirstShow] = useState(false);
  const [croperCol, setCroperCol] = useState(false);
  const [smallimage, setSmallImage] = useState([]);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });

  const imageAdd = () => {};
  const removeimg = () => {
    const arr = [...image];
    const index = arr.indexOf();
    arr.splice(Indeximage);
    setImage([...arr]);
    console.log(arr);
  };
  // useEffect((ind) => {
  //     setIndexImage(ind)
  //     console.log(ind)
  // })

  const smallimg = (item) => {
    setSmallImage(item);
    // console.log(item)
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // Slide
  const [file, setFile] = useState([]);

  const imageUplode = (e) => {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
    console.log("file", file);
  };

  const deleteimg = (e) => {
    const s = file.filter((item, index) => item !== smallimage);
    setFile(s);
    console.log(s, index);
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const dualToggele = (type) => {
    if (type == "color") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setTextShow(false);
      setFirstShow(true);

      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallImage(temp);

      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    } else if (!croperCol && croperImage) {
      imageUplode(croperImage);
    }
  }, [croperImage]);

  const imageSelector = (item, ind) => {
    setBigImage({ image: item, ind: ind });
  };

  const removeImg = (ind) => {
    let temp = [...smallimage];

    temp.splice(ind, 1);
    setSmallImage(temp);
    if (temp?.length - 1 === 0) {
      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
    } else {
      setBigImage({ image: temp[ind - 1], ind: ind - 1 });
    }
  };

  return (
    <div className="d-flex flex-column gap-3 ac-jc w-100">
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2 text-center mb-3">
        Product Image
      </p>
      <div className="d-flex ac-jc flex-column">
        <div>
          <label
            className="img_up_cont d-flex ac-jc cp"
            onClick={() => !fistShow && dualToggele("product")}
          >
            <div className="d-flex ac-jc flex-column">
              <div className="position-relative">
                <img
                  // className={logo?.name ? "img_up_cont object-fit-contain" : "placeholder_icon object-fit-contain"}
                  // src={logo?.name ? window.URL.createObjectURL(logo) : uploadIcon}
                  // alt="logo"
                  className={
                    bigImage?.image
                      ? "img_up_cont object-fit-contain "
                      : "placeholder_icon object-fit-contain"
                  }
                  src={bigImage?.image ? bigImage?.image : uploadIcon}
                />
                {!textShow && (
                  <div>
                    <ul
                      className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                    >
                      <li className="set-default-image">Set&nbsp;Default</li>
                      {smallimage?.length > 1 && (
                        <li
                          className="set-default-image"
                          onClick={() => removeImg(bigImage?.ind)}
                        >
                          Remove
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              {textShow ? (
                <p className="f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 primary">
                  {" "}
                  Click here to upload
                </p>
              ) : null}
            </div>

            {/* <input
              // onChange={(e) => { setLogo(e.target.files[0]); setTextShow(false) }}
              // onChange={imgclick}
              className="d-none"
              type="file"
              accept="image/png, image/jpeg"
            /> */}
          </label>
          <div className="d-flex ac-jc gap-3 w-100">
            {fistShow && (
              <div
                class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                style={{ width: "300px", overflow: "scroll" }}
              >
                {smallimage?.map((item, ind) => {
                  return (
                    <div className="gap-3 d-flex ac-j w-100">
                      <div
                        class="add_img d-flex ac-jc"
                        onClick={() => imageSelector(item, ind)}
                      >
                        {" "}
                        <img src={item} class="cp add_img object-fit-fill" />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {smallimage?.length > 0 && (
              <label
                className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                  smallimage?.length == 0 && "mt-3"
                }`}
                onClick={() => dualToggele("product")}
              >
                +
              </label>
            )}
          </div>
        </div>
      </div>
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary text-center">
        Color Image
      </p>
      <div
        className="d-flex gap-3 mt-3 ac-jc flex-wrap"
        style={{ width: "350px" }}
      >
        <div className="d-flex flex-column ac-jc for_hover">
          <label className="add_img d-flex ac-jc cp f3 primary fs-3">
            <img
              alt="product"
              src={product}
              className="add_img object-fit-contain"
            />
          </label>
          <div className="d-flex w-100 justify-content-between  in_hover text-dark">
            <FileDownloadOutlinedIcon />
            <ClearOutlinedIcon />
          </div>
          <p className="f3" style={{ color: "red" }}>
            Red
          </p>
        </div>
        <div className="d-flex flex-column ac-jc for_hover">
          <label className="add_img d-flex ac-jc cp f3 primary fs-3">
            <img
              alt="product"
              src={product}
              className="add_img object-fit-contain"
            />
          </label>
          <div className="d-flex w-100 justify-content-between position-absolute in_hover text-dark">
            <FileDownloadOutlinedIcon />
            <ClearOutlinedIcon />
          </div>
          <p className="f3" style={{ color: "blue" }}>
            Blue
          </p>
        </div>
      </div>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
    </div>
  );
};

export default ImageUplodeComp;
