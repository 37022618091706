import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchIcon } from "../../assets/img";
import GetAppIcon from "@mui/icons-material/GetApp";
import CachedIcon from "@mui/icons-material/Cached";
import OverallArtworkRequestComp from "../../components/MarckupArtwork/Request";
import Request from "../../components/MarckupArtwork/Request";
import Inprocess from "../../components/MarckupArtwork/Inprocess";
import Accepted from "../../components/MarckupArtwork/Accepted";
import Rejected from "../../components/MarckupArtwork/Rejected";

const OverallArtworkRequestScreen = () => {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("Request");

  const navigate = useNavigate();
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const onShowPopupHander = () => {
    setShow(!show);
  };
  return (
    <div className="dashRightView p-5 home_section trans">
      <p className="f4 fs-sm-19 fs-md-20 fs-lg-20 fs-xl-22 fs-xxl-23 primary mt-2">
        Mockup Artwork Request
      </p>
      <div className="d-flex ac-jb flex-md-row flex-column">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        
        <div className="d-flex ac-jb flex-md-row flex-column mt-5 ">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 px-3">
            Total Count : {"03"}
          </p>
          <div className="d-flex border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          <button className="cust-btn addbtn_rel p-4 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 rounded">
            <CachedIcon />
          </button>
        </div>
      </div>
      <div>
      <div className="d-flex mb-4 ac-jb flex-wrap me-md-3 rk2">
          <div className="d-flex mt-4 rk ">
          <button
              onClick={() => onTabClick("Request")}
              style={{ cursor: "pointer" }}
              className={`${
                activeTab === "Request"
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                  : ""
              }  pointerView cust-btn px-4 py-2 tranc`}
            >
              <p
                className={`${
                  activeTab === "Request" ? "text-light" : "primary"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Request
              </p>
            </button>
            <button
              onClick={() => onTabClick("Inprocess")}
              style={{ cursor: "pointer" }}
              className={`${
                activeTab === "Inprocess"
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                  : ""
              }  pointerView cust-btn px-4 py-2 tranc`}
            >
              <p
                className={`${
                  activeTab === "Inprocess" ? "text-light" : "primary"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Inprocess
              </p>
            </button>
            <button
              onClick={() => onTabClick("Accepted")}
              style={{ cursor: "pointer" }}
              className={`${
                activeTab === "Accepted"
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                  : ""
              }  pointerView cust-btn px-4 py-2 tranc`}
            >
              <p
                className={`${
                  activeTab === "Accepted" ? "text-light" : "primary"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Accepted
              </p>
            </button>
            <button
              onClick={() => onTabClick("Rejected")}
              style={{ cursor: "pointer" }}
              className={`${
                activeTab === "Rejected"
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                  : ""
              }  pointerView cust-btn px-4 py-2 tranc`}
            >
              <p
                className={`${
                  activeTab === "Rejected" ? "text-light" : "primary"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Rejected
              </p>
            </button>
          </div>
        </div>

      <div className="d-flex as-js gap-2 mt-3">
        <button className=" bg-lt-blue primary1 border-0 rounded-1 px-3">
          <select className="bg-transparent border-0 py-2 primary1">
            <option value="0">Tagged</option>
            <option value="1">Audi</option>
            <option value="2">BMW</option>
            <option value="3">Citroen</option>
            <option value="4">Ford</option>
            <option value="5">Honda</option>
            <option value="6">Jaguar</option>
            <option value="7">Land Rover</option>
            <option value="8">Mercedes</option>
            <option value="9">Mini</option>
            <option value="10">Nissan</option>
            <option value="11">Toyota</option>
            <option value="12">Volvo</option>
          </select>
        </button>
        <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
          <select className="bg-transparent border-0 py-2 primary1">
            <option value="0">Select Order Rep</option>
            <option value="10">Nissan</option>
            <option value="11">Toyota</option>
            <option value="12">Volvo</option>
          </select>
        </button>
        <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
          <select className="bg-transparent border-0 py-2 primary1">
            <option value="0">Select Client Rep</option>
            <option value="11">Toyota</option>
            <option value="12">Volvo</option>
          </select>
        </button>
        <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
          <select className="bg-transparent border-0 py-2 primary1">
            <option value="0">Select Design Status</option>
            <option value="11">Toyota</option>
            <option value="12">Volvo</option>
          </select>
        </button>
        <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
          <select className="bg-transparent border-0 py-2 primary1">
            <option value="0">Select Customer Status</option>
            <option value="11">Toyota</option>
            <option value="12">Volvo</option>
          </select>
        </button>
      </div>
      {activeTab == "Request" && (
        <Request onShowPopupHander={onShowPopupHander} />
      )}
      {activeTab == "Inprocess" && (
        <Inprocess onShowPopupHander={onShowPopupHander} />
      )}
      {activeTab == "Accepted" && (
        <Accepted onShowPopupHander={onShowPopupHander} />
      )}
      {activeTab == "Rejected" && (
        <Rejected onShowPopupHander={onShowPopupHander} />
      )}

     </div>
    </div>
  );
};

export default OverallArtworkRequestScreen;
