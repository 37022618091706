import React, { useState } from "react";
import { product, searchIcon, viewIcon } from "../assets/img";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EditShippingInfo from "../components/ShippingPopup/EditShippingInfo";
import { collaborateList, Shipping_supplier } from "../redux/api/DummyJson";
import EditShippingPoppup from "../components/ShippingPopup/EditShippingPoppup";
import NavbarHead from "../components/Sub_Layout/NavbarHead";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Inprocess from "../components/POallRequest/Inprocess";
import Accepted from "../components/POallRequest/Accepted";
import Rejected from "../components/POallRequest/Rejected";

const ShippingScreen = () => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  const onEditClick = () => {
    setShowEditPopup(!showEditPopup);
  };
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("Shipping");

  const navigate = useNavigate();
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const onShowPopupHander = () => {
    setShow(!show);
  };
  return (
    <div className="dashRightView2 overview_pages overflow-scroll ">
      <NavbarHead />
      {/* <div className="w-100">
        <div className="mx-2">
          <p class=" f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary text-start primary ">
            Project
          </p>
        </div>
        <div className="d-flex mt-4 ">
          <button
            onClick={() => onTabClick("Shipping")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Shipping"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }   cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Shipping" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Shipping
            </p>
          </button> */}
      {/* <button
            onClick={() => onTabClick("Po")}
            style={{ cursor: "pointer" }}
  Ontao
            className={`${
              activeTab === "Po"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Po" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Po
            </p>
          </button>
          <button
            onClick={() => onTabClick("Bills")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Bills"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Bills" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Bills
            </p>
          </button>
          <button
            onClick={() => onTabClick("Commissions")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Commissions"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Commissions" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Commissions
            </p>
          </button>
          <button
            onClick={() => onTabClick("Invoice")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Invoice"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Invoice" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Invoice
            </p>
          </button>
          <button
            onClick={() => onTabClick("FeedBack")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "FeedBack"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "FeedBack" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Feed Back
            </p>
          </button> */}
      {/* </div>
      </div> */}
      <div className="w-100 ">
        <div className="d-flex flex-wrap justify-content-end">
          <div className="d-flex align-items-center justify-content-center gap-2">
            <div className="d-flex mt-md-0 mt-3 border-search ">
              <img alt="img" src={searchIcon} className="searchiConImg" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
              />
            </div>
            {/* <button class="fs-16 w-50 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 fs-14">
              <select className="w-100 bg-transparent border-0 px-2 py-3 primary1">
                <option selected>Suppliers</option>
                <option value="Garg Enterprises">Garg Enterprises</option>
              </select>
            </button> */}
          </div>
          {/* <button
            // onClick={onEditClick}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            Action
          </button> */}
        </div>
      </div>
      {/* <div className="w-100 mt-5 overflow-scroll">
        <div className="w-100 d-flex border-bottom pb-1">
          <div className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 ">
            <input className="mx-2" type="checkbox" />
          </div>
          <p style={{ width: '260px' }} className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 ">
            Product
          </p>
          <p className="w-30  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 ">
            Supplier Shipping
          </p>
          <p className="w-30  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 ">
            Decorator Shipping
          </p>
        </div>
        <div role="button" className="decor-list d-flex mb-3 mt-4 w-100 ">
          <div className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex">
            <input className="mx-2" type="checkbox" />
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  <div className="w-100 d-flex">
                    <div className="w-100">
                      <img
                        alt="product"
                        src={product}
                        className="object-fit-contain img_size2"
                      />
                    </div>
                    <div className="w-100">
                      <p className="f2 fs-13 ">Stainless Steel Hot...</p>
                      <br />
                      <p className="f1 fs-12">3016829jh2</p>
                      <br />
                      <p className="f1 fs-12">Garg Enterprises</p>
                      <br />
                    </div>
                  </div>
                </Tooltip>
              )}
              placement="bottom">
              <img
                alt="product"
                src={product}
                className="object-fit-contain img_size2"
              />
            </OverlayTrigger>
          </div>
          <div className="w-25 ">
            <p className="primary1 f1 fs-16 me-1">
              Stainless Steel Hot N Cold Bottle
            </p>
            <button
              onClick={onEditClick}
              className="cust-btn addbtn mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              ✎ Edit
            </button>
          </div>
          <div className="w-30">
            <p className="gray">
              <span className="f3 text-black me-5">Name</span> Garg Enterprises
            </p>
          </div>
          <div className="w-30"></div>
        </div>
      </div> */}
      {/* {showEditPopup && <EditShippingInfo togglePopup={onEditClick} />} */}
      {showEditPopup && <EditShippingPoppup togglePopup={onEditClick} />}
      <div className="w-100 overflow-scroll mt-4 edit_page_cust">
        <table className="w-100 px-4">
          <tr className=" bg-lt-blue  ">
            <th className="col-md-4 ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Product
              </p>
            </th>
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Supplier Shipping
              </p>
            </th>{" "}
            <th className="col-md-4">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Decorator Shipping
              </p>
            </th>
          </tr>

          {Shipping_supplier?.map((item, ind) => {
            return (
              <tr className="my-5 border-bottom">
                <td className="col-md-4 my-3">
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    <button className="mx-2 d-flex ac-jc cust-btn">
                      <img
                        src={item.img_list}
                        className="object-fit-contain img_size2 "
                      />
                      <p className="primary1 ms-2 f1 fs-16  fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                        {item?.id}
                      </p>
                    </button>
                  </p>
                </td>
                <td className="col-md-4 my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      <div className="d-flex mb-1 ">
                        <div className="col-md-6">
                          <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                            Name :
                          </p>
                        </div>
                        <div className="col-md-6 text-start">
                          {item?.ship_name}
                        </div>
                      </div>
                      <div className="d-flex  mb-1 ">
                        <div className="col-md-6">
                          <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                            In Hand Date :
                          </p>
                        </div>
                        <div className="col-md-6 text-start">
                          {item?.ship_inhand}
                        </div>
                      </div>
                      <div className="d-flex mb-1  ">
                        <div className="col-md-6">
                          <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                            Ship To :
                          </p>
                        </div>
                        <div className="col-md-6 text-start">
                          {item?.ship_To}
                        </div>
                      </div>
                      <div className="d-flex  mb-1 ">
                        <div className="col-md-6">
                          <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                            Address :
                          </p>
                        </div>

                        <div className="col-md-6 text-start">
                          {item?.Address}
                        </div>
                      </div>
                      <div className="d-flex  mb-1 ">
                        <div className="col-md-6">
                          <p className="fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end primary f3">
                            Method :
                          </p>
                        </div>

                        <div className="col-md-6 text-start">
                          {item?.Method}
                        </div>
                      </div>
                    </p>
                  </div>
                </td>{" "}
                <td className="col-md-4 my-3">
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                    For More Details
                  </p>
                  <p className="text-center">
                    <button
                      onClick={() => navigate("/shipping-details")}
                      className="cust-btn addbtn  mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                    >
                      View <RemoveRedEyeIcon />
                    </button>
                  </p>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};
//         <table className="w-100">
//           <tr className="mt-4 border_cust">
//             <div className="bg-soft-gray1 py-3 bottom_cust_bord">

//               <td>
//                 <div className="d-flex w-100">
//                   {/* <div className="mx-2">
//                     <div
//                       class="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary f3"
//                       style={{ width: "200px" }}
//                     >
//                       <input className="mx-2" type="checkbox" />
//                     </div>
//                   </div> */}
//                   <div className="mx-2">
//                     <td>
//                     <p
//                       class=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-start primary f3"
//                       style={{ width: "250px" }}
//                     >
//                       Product
//                     </p>
//                     </td>
//                   </div>
//                   <div className="mx-2">
//                     <td>
//                     <p
//                       class=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center primary f3"
//                       style={{ width: "200px" }}
//                     >
//                       Supplier Shipping
//                     </p>
//                     </td>
//                   </div>
//                   {/* <div className="mx-2" >
//                     <p class=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center primary f3"
//                       style={{ width: '150px' }}
//                     >Vendor Name</p>
//                   </div> */}
//                   <div className="mx-2">
//                     <td>
//                     <p
//                       class=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center primary f3"
//                       style={{ width: "200px" }}
//                     >
//                       {" "}
//                       Decorator Shipping
//                     </p>
//                     </td>
//                   </div>
//                 </div>
//               </td>
//             </div>
//           </tr>

//           <tr className="mt-4">
//             <div className="">
//               <td>
//                 <div className="d-flex w-100">
//                   <div className="mx-2 d-flex ac-jc" >
//                     <div class="d-flex ac-jc pb-3 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 text-center primary f3">
//                       <input className="mx-2" type="checkbox" />
//                       <button className="mx-2 d-flex ac-jc cust-btn">
//                         <img
//                           src={product}
//                           className="object-fit-contain img_size2"
//                         />
//                       </button>
//                       <p className="primary1 f1 fs-16 me-1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
//                       SKU-001 Steel Hot
//                       </p>
//                     </div>
//                   </div>
//                   <div className="mx-2 d-flex ac-jc" style={{ width: "250px" }}>
//                     {/* <div>
//                       <p className="primary1 f1 fs-16 me-1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
//                         Stainless Steel Hot N Cold Bottle
//                       </p>
//                       <button
//                         onClick={onEditClick}
//                         className="cust-btn addbtn mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
//                       >
//                         ✎ Edit
//                       </button>
//                     </div> */}
//                   </div>
//                   <div className="mx-2 d-flex ac-jc" style={{ width: "200px" }}>
//                     <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView primary1">
//                       Name
//                     </p>
//                     {Shipping_supplier?.map((item, ind) => {
//           return (
// {item?.shipping}
//           )}
//                   </div>

//                   <div className="mx-2 d-flex ac-jc" style={{ width: "200px" }}>
//                     <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView gray">
//                       Garg Enterprises
//                     </p>
//                   </div>
//                 </div>
//               </td>
//             </div>
//           </tr>
//         </table>
{
  /* {activeTab == "Inprocess" && (
        <Inprocess onShowPopupHander={onShowPopupHander} />
      )}
      {activeTab == "Accepted" && (
        <Accepted onShowPopupHander={onShowPopupHander} />
      )}
      {activeTab == "Rejected" && (
        <Rejected onShowPopupHander={onShowPopupHander} />
      )} */
}
// </div>

export default ShippingScreen;
