import React, { useState } from "react";
import { animatedImg, packImg, toggleOff, toggleOn } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { ourPacks } from "../../redux/api/DummyJson";

const OurProductListComp = ({ loc, place, products }) => {
  const navigate = useNavigate();

  let sourceElement = null;

  const [sortedList, setSortedList] = useState(ourPacks);

  /* add a new entry at the end of the list.  */
  const newLine = () => {
    console.log(sortedList);
    setSortedList(sortedList.concat(""));
  };

  /* change opacity for the dragged item. 
    remember the source item for the drop later */
  const handleDragStart = (event) => {
    event.target.style.opacity = 0.5;
    sourceElement = event.target;
    event.dataTransfer.effectAllowed = "move";
  };

  /* do not trigger default event of item while passing (e.g. a link) */
  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  /* add class .over while hovering other items */
  const handleDragEnter = (event) => {
    event.target.classList.add("over_new");
  };

  /* remove class .over when not hovering over an item anymore*/
  const handleDragLeave = (event) => {
    event.target.classList.remove("over_new");
  };

  const handleDrop = (event) => {
    console.log("event.target.id", event.target.id);
    /* prevent redirect in some browsers*/
    event.stopPropagation();

    /* only do something if the dropped on item is 
      different to the dragged item*/
    if (sourceElement !== event.target) {
      /* remove dragged item from list */
      const list = sortedList.filter(
        (item, i) => i.toString() !== sourceElement.id
      );

      /* this is the removed item */
      const removed = sortedList.filter(
        (item, i) => i.toString() === sourceElement.id
      )[0];

      /* insert removed item after this number. */
      let insertAt = Number(event.target.id);

      console.log("list with item removed", list);
      console.log("removed:  line", removed);
      console.log("insertAt index", insertAt);

      let tempList = [];

      /* if dropped at last item, don't increase target id by +1. 
           max-index is arr.length */
      if (insertAt >= list.length) {
        tempList = list.slice(0).concat(removed);
        setSortedList(tempList);
        event.target.classList.remove("over_new");
      } else if (insertAt < list.length) {
        /* original list without removed item until the index it was removed at */
        tempList = list.slice(0, insertAt).concat(removed);

        console.log("tempList", tempList);
        console.log("insert the rest: ", list.slice(insertAt));

        /* add the remaining items to the list */
        const newList = tempList.concat(list.slice(insertAt));
        console.log("newList", newList);

        /* set state to display on page */
        setSortedList(newList);
        event.target.classList.remove("over_new");
      }
    } else console.log("nothing happened");
    event.target.classList.remove("over_new");
  };

  const handleDrops = (event, i) => {
    console.log("event.target.id", event.target.id, i);
    /* prevent redirect in some browsers*/
    event.stopPropagation();

    /* only do something if the dropped on item is 
      different to the dragged item*/
    if (sourceElement !== event.target) {
      /* remove dragged item from list */
      const list = sortedList.filter(
        (item, i) => i.toString() !== sourceElement.id
      );

      /* this is the removed item */
      const removed = sortedList.filter(
        (item, i) => i.toString() === sourceElement.id
      )[0];

      /* insert removed item after this number. */
      let insertAt = Number(i);

      console.log("list with item removed", list);
      console.log("removed:  line", removed);
      console.log("insertAt index", insertAt);

      let tempList = [];

      /* if dropped at last item, don't increase target id by +1. 
           max-index is arr.length */
      if (insertAt >= list.length) {
        tempList = list.slice(0).concat(removed);
        setSortedList(tempList);
        event.target.classList.remove("over_new");
      } else if (insertAt < list.length) {
        /* original list without removed item until the index it was removed at */
        tempList = list.slice(0, insertAt).concat(removed);

        console.log("tempList", tempList);
        console.log("insert the rest: ", list.slice(insertAt));

        /* add the remaining items to the list */
        const newList = tempList.concat(list.slice(insertAt));
        console.log("newList", newList);

        /* set state to display on page */
        setSortedList(newList);
        event.target.classList.remove("over_new");
      }
    } else console.log("nothing happened");
    event.target.classList.remove("over_new");
  };

  const handleDragEnd = (event) => {
    event.target.style.opacity = 1;
    console.log(
      "-------------------------------------------------------------"
    );
  };

  /* log changes in current input field */
  const handleChange = (event) => {
    event.preventDefault();
    console.log("event.target.value", event.target.value);

    /* create new list where everything stays the same except that the current
      item replaces the existing value at this index */
    const list = sortedList.map((item, i) => {
      if (i !== Number(event.target.id)) {
        return item;
      } else return event.target.value;
    });
    setSortedList(list);
  };

  /* filter list where only items with id unequal to current id's are allowed */
  const handleDelete = (event) => {
    event.preventDefault();
    const list = sortedList.filter((item, i) => i !== Number(event.target.id));
    console.log(event.target.id);
    setSortedList(list);
  };

  return (
    <>
      <div className="w-100 d-flex align-items-center justify-content-start justify-content-md-center mt-5 flex-wrap">
        {sortedList.map((item, i) => {
          return (
            <div
              className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp"
              id={i}
              draggable="true"
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrops(e, i)}
              onDragEnd={handleDragEnd}
              onChange={handleChange}
            >
              <div className="prod-list-box">
                <div
                  className="cust-btn d-flex ac-jc rounded-3"
                  // onClick={() => navigate("/add-our-packs")}
                  onDoubleClick={() => navigate("/add-our-packs")}
                >
                  <img
                    src={packImg}
                    alt=""
                    className="custom-w-h rounded-3"
                    draggable="false"
                  />
                </div>
                <p
                  className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2"
                  onClick={() => navigate("/add-our-packs")}
                >
                  Welcome Pack
                </p>
                <p
                  className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2"
                  onClick={() => navigate("/add-our-packs")}
                >
                  {item?.welcome_pack}
                </p>
                <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                  Status
                  <span>
                    <img
                      src={item?.status == 1 ? toggleOn : toggleOff}
                      className="toggleOnDes"
                      alt="icon"
                    />
                  </span>
                </p>
                {item?.priority == 1 ? (
                  <p className="d-flex priority mb-0 success">
                    <button className="btn btn-default">
                      <FormatListBulletedIcon className="icon" />
                    </button>
                    <span>Priority</span>
                  </p>
                ) : (
                  <p className="d-flex priority mb-0 danger">
                    <button className="btn btn-default">
                      <FormatListBulletedIcon className="icon" />
                    </button>
                    <span>Set Priority</span>
                  </p>
                )}
              </div>{" "}
            </div>
          );
        })}

        {/* <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp">
          <div className="prod-list-box">
            <div
              className="cust-btn d-flex ac-jc rounded-3"
              onClick={() => navigate("/add-our-packs")}
            >
              <img src={packImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p
              className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2"
              onClick={() => navigate("/add-our-packs")}
            >
              Celebration Kit
            </p>
            <p
              className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2"
              onClick={() => navigate("/add-our-packs")}
            >
              $99/pack
            </p>
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
              Status
              <span>
                <img src={toggleOn} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            <p className="d-flex priority mb-0 success">
              <button className="btn btn-default">
                <FormatListBulletedIcon className="icon" />
              </button>
              <span>Priority</span>
            </p>
          </div>
        </div>
        <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp">
          <div className="prod-list-box">
            <div
              className="cust-btn d-flex ac-jc rounded-3"
              onClick={() => navigate("/add-our-packs")}
            >
              <img src={packImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p
              className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2"
              onClick={() => navigate("/add-our-packs")}
            >
              Appraisal Pack
            </p>
            <p
              className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2"
              onClick={() => navigate("/add-our-packs")}
            >
              $149/pack
            </p>
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
              Status
              <span>
                <img src={toggleOff} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            <p className="d-flex priority mb-0 success">
              <button className="btn btn-default">
                <FormatListBulletedIcon className="icon" />
              </button>
              <span>Priority</span>
            </p>
          </div>
        </div>
        <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp">
          <div className="prod-list-box">
            <div
              className="cust-btn d-flex ac-jc rounded-3"
              onClick={() => navigate("/add-our-packs")}
            >
              <img src={packImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p
              className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2"
              onClick={() => navigate("/add-our-packs")}
            >
              Send Off Kit
            </p>
            <p
              className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2"
              onClick={() => navigate("/add-our-packs")}
            >
              $79/pack
            </p>
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
              Status
              <span>
                <img src={toggleOff} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            <p className="d-flex priority mb-0 success">
              <button className="btn btn-default">
                <FormatListBulletedIcon className="icon" />
              </button>
              <span>Priority</span>
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default OurProductListComp;
