import React from 'react'
import { tshirt1 , art2} from '../assets/img';

const SalesArtEnlarge2 = () => {
  return (
    
    <div className='full-img'>
      <img src={art2} style={{width:"100%",height:"100%",objectFit:"contain",objectPosition:"center",}}/>
    </div>
  )
}

export default SalesArtEnlarge2;