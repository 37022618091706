import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import {
  Delete,
  Edit,
  Password,
  RemoveRedEye,
  Search,
} from "@mui/icons-material";
import ProjectDetailPopup from "../../Popup/ProjectDetailPopup";
import AddTaskPopup from "../../Popup/AddTaskPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link, useNavigate } from "react-router-dom";
import {
  Accept_Tic,
  Accept_Tic_close,
  salesForce,
} from "../../../redux/api/DummyJson";

const CollaborateAcceptedTableComp = () => {
  const navigate = useNavigate();
  const [ticketStatus, setTicketStatus] = useState(1);
  const [status, setStatus] = useState();
  return (
    <div className="presentaion_pages overflow-scroll mt-4 w-100">
      <div className="w-100 ac-js d-flex gap-3">
        <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
          <button
            className="d-flex gap-3  ac-js cust-btn"
            onClick={() => setTicketStatus(1)}
          >
            <div className="radio_btn d-flex ac-jc">
              <div
                className={`${ticketStatus === 1 ? "sm_radio" : "sm_radio1"}`}
              />
            </div>
            <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Open Ticket
            </p>
          </button>
        </div>
        <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
          <button
            onClick={() => setTicketStatus(2)}
            className="d-flex gap-3  ac-js cust-btn"
          >
            <div className="radio_btn d-flex ac-jc">
              <div
                className={`${ticketStatus === 2 ? "sm_radio" : "sm_radio1"}`}
              />
            </div>
            <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Close Ticket
            </p>
          </button>
        </div>
      </div>
      <div className="w-100 cust_table overflow-scroll table_border menu-table">
        <table>
          <thead className="py-3">
            <tr className="bg-soft-gray1">
              <th className="text-nowrap">S.No</th>
              <th>Collabrate ID</th>
              <th>Project ID</th>
              <th>Product</th>
              <th>Requested by</th>
              <th>Assigned</th>
              <th>Date & Time</th>
              <th>Ticket Status</th>
              <th>Action</th>
            </tr>
            {ticketStatus == 1 &&
              Accept_Tic?.map((item, ind) => {
                return (
                  <tr className="position-relative">
                    <td>{ind + 1}</td>
                    <td>{item?.collabrate_id}</td>
                    <td>{item?.project_id}</td>
                    <td>{item?.prodect}</td>
                    <td>{item?.requst_by}</td>
                    <td>{item?.assignd}</td>
                    <td>
                      <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        22/01/2024
                      </p>
                      <p className="primary1 f2"> 2:30PM</p>
                    </td>
                    {/* {status === "Open" && ( */}
                    <td>
                      <button className="editBtn bg-transparent rounded-3 px-3 py-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {item?.ticket_status}
                      </button>
                    </td>
                    {/* <td className="px-0">
                      <select
                        // value={status}
                        onChange={(e) => setStatus(e)}
                        className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      >
                        <option value={"Open"}>Open</option>
                        <option value={"Close"}>Close</option>
                      </select>
                    </td> */}
                    {/* // )} */}
                    {/* {item?.ticket_status === "Open" && (
                    <td className="px-0">
                      <select className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        <option>{item?.ticket_status}</option>
                        <option>Close</option>
                      </select>
                    </td>
                  )} */}
                    {/* {item?.ticket_status === "Close" && (
                      <td className="px-0">
                        <p className="px-1 py-2 bg-danger text-light rounded-3 cp">
                          Close
                        </p>
                      </td>
                    )} */}
                    <td>
                      <button
                        onClick={() =>
                          navigate("/collabrate-accept-edit", {
                            state: { type: "edit", ticket: ticketStatus },
                          })
                        }
                        className="primary text-white border-0 rounded-1 p-1 me-2 bg-transparent"
                      >
                        <Edit />
                      </button>
                    </td>
                  </tr>
                );
              })}

            {ticketStatus === 2 &&
              Accept_Tic_close?.map((item, ind) => {
                return (
                  <tr className="position-relative">
                    <td>{ind + 1}</td>
                    <td>{item?.collabrate_id}</td>
                    <td>{item?.project_id}</td>
                    <td>{item?.prodect}</td>
                    <td>{item?.requst_by}</td>
                    <td>{item?.assignd}</td>
                    <td>
                      <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        22/01/2024
                      </p>
                      <p className="primary1 f2"> 2:30PM</p>
                    </td>
                    {/* {status === "Open" && ( */}
                    <td className="px-0">
                      <select
                        value={"Close"}
                        onChange={(e) => setStatus(e)}
                        className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      >
                        <option value={"Open"}>Open</option>
                        <option value={"Close"}>Close</option>
                      </select>
                      {/* <button
                        // value={status}
                        className="editBtn bg-transparent rounded-3 px-3 py-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      >
                        Close
                      </button> */}
                    </td>
                    {/* // )} */}
                    {/* {item?.ticket_status === "Open" && (
                    <td className="px-0">
                      <select className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        <option>{item?.ticket_status}</option>
                        <option>Close</option>
                      </select>
                    </td>
                  )} */}
                    {/* {item?.ticket_status === "Close" && (
                      <td className="px-0">
                        <p className="px-1 py-2 bg-danger text-light rounded-3 cp">
                          Close
                        </p>
                      </td>
                    )} */}
                    <td>
                      <button
                        onClick={() =>
                          navigate("/collabrate-accept-edit", {
                            state: { type: "view", ticket: ticketStatus },
                          })
                        }
                        className="primary text-white border-0 rounded-1 p-1 me-2 bg-transparent"
                      >
                        <RemoveRedEye />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </thead>
        </table>
      </div>
    </div>
  );
};

export default CollaborateAcceptedTableComp;
