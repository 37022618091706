import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import FormComp from "../components/AddNewProductComps/FormComp";
import ImageUplodeComp from "../components/AddNewProductComps/ImageUplodeComp";
import { useLocation, useNavigate } from "react-router-dom";
import RequestFormComp from "../components/CreateNewRequest/RequestFormComp";
import RequestImageUplodeComp from "../components/CreateNewRequest/RequestImageUplodeComp";
import PricingBoxComp from "../components/MyProductListComp/PricingBoxComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const CreateNewRequestScreen = () => {
  const navigat = useNavigate();
  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigat(-1)}
      >
        <KeyboardBackspaceIcon /> Create New Request
      </p>
      {/* <div className='d-flex as-jb w-100 mt-5 res-flex gap-5'> */}
      <RequestFormComp />
      <div>
        <PricingBoxComp />
      </div>
      <div className="d-flex ac-jb w-80">
        <button
          className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigat(-1)}
        >
          Back
        </button>
        <button
          className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigat(-1)}
        >
          Save
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CreateNewRequestScreen;
