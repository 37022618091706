import React, { useState } from "react";
import { animatedImg, toggleOff, toggleOn } from "../../assets/img";
import { useNavigate } from "react-router-dom";

const DecorativeListComp = ({ loc, place, decorative2 }) => {
  // const [productDetail, setProductDetail] = useState(false)
  const navigate = useNavigate();
  console.log(loc, place, "lshfidjbghj");
  return (
    <div className="w-100 d-flex align-items-center justify-content-start justify-content-md-center mt-5 flex-wrap">
      <div
        className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
        onClick={() =>
          navigate("/decorative-detail", { state: { type: place } })
        }
      >
        <div className="prod-list-box">
          <div className="cust-btn d-flex ac-jc rounded-3">
            <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
          </div>
          <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
            {place === "John Rolph" ? "John Rolph" : "Jake Adams"}
          </p>
          <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
            Game consoles
          </p>
          {/* <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0"></p> */}
          <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
            Status
            <span>
              <img src={toggleOff} className="toggleOnDes" alt="icon" />
            </span>
          </p>
        </div>
      </div>
      <div
        className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
        onClick={() =>
          navigate("/decorative-detail", { state: { type: place } })
        }
      >
        <div className="prod-list-box">
          <div className="cust-btn d-flex ac-jc rounded-3">
            <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
          </div>
          <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
            {place === "John Rolph" ? "John Rolph" : "Jake Adams"}
          </p>
          <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
            Game consoles
          </p>
          {/* <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0"></p> */}
          <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
            Status
            <span>
              <img src={toggleOn} className="toggleOnDes" alt="icon" />
            </span>
          </p>
        </div>
      </div>
      <div
        className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
        onClick={() =>
          navigate("/decorative-detail", { state: { type: place } })
        }
      >
        <div className="prod-list-box">
          <div className="cust-btn d-flex ac-jc rounded-3">
            <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
          </div>
          <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
            {place === "John Rolph" ? "John Rolph" : "Jake Adams"}
          </p>
          <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
            Game consoles
          </p>
          {/* <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0"></p> */}
          <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
            Status
            <span>
              <img src={toggleOn} className="toggleOnDes" alt="icon" />
            </span>
          </p>
        </div>
      </div>
      <div
        className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
        onClick={() =>
          navigate("/decorative-detail", { state: { type: place } })
        }
      >
        <div className="prod-list-box">
          <div className="cust-btn d-flex ac-jc rounded-3">
            <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
          </div>
          <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
            {place === "John Rolph" ? "John Rolph" : "Jake Adams"}
          </p>
          <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
            Game consoles
          </p>
          {/* <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0"></p> */}
          <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
            Status
            <span>
              <img src={toggleOff} className="toggleOnDes" alt="icon" />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DecorativeListComp;
