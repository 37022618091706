import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { product } from "../../assets/img";

const ArtWorkerData = ({
  poppupDataToggle,
  setAddArt,
  poppupImageToggle,
  toggleShowPopups,
}) => {
  const [ticketStatus, setTicketStatus] = useState(1);
  return (
    <div className="add-client-contact-popup2 ">
      <div className="addArt">
        <div className="d-flex justify-content-between">
          <div>
            <p className="mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              {" "}
              Add Artwork
            </p>
          </div>


          <div className="d-flex mt-2">
          <button
              className=" cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
              onClick={() => {
                poppupDataToggle();
                toggleShowPopups();
                poppupImageToggle();
              }}>
              Set
            </button>
            <button
              className=" cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
              onClick={() => {
                poppupDataToggle();
                toggleShowPopups();
                poppupImageToggle();
              }}>
              Back
            </button>
            {/* <button
              className="cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
              onClick={() => poppupDataToggle()}>
              Save
            </button> */}
          </div>
        </div>

        <div className="popUpForm d-flex flex-wrap">
          <Col sm={12} md={6}>
            <div className="w-100 d-flex ac-js">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    {/* Imprint Type: */}
                    Decorative Method
                  </p>
                  <div className="w-100 ms-3">
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      name=""
                      id="">
                      <option hidden>
                        Please Select an Decorative Method...
                      </option>
                      <option>3D</option>
                      <option>Fabric</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Proof Required:
                  </p>
                  <div className="w-100 ms-3">
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      name=""
                      id="">
                      <option hidden>Please Select a Proof Type...</option>
                      <option>Email Proof</option>
                      <option>Digital Photo</option>
                      <option>Pre Production</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Logo Name:
                  </p>
                  <div className="w-100 ms-3">
                    <textarea
                      disabled
                      placeholder="Required Field"
                      value={"MerhhQ"}
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    UOM:
                  </p>
                  <div className="w-100 ac-js d-flex gap-3 ms-3">
                    <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                      <button
                        className="d-flex gap-3  ac-js cust-btn"
                        onClick={() => setTicketStatus(1)}>
                        <div className="radio_btn d-flex ac-jc">
                          <div
                            className={`${
                              ticketStatus === 1 ? "sm_radio" : "sm_radio1"
                            }`}
                          />
                        </div>
                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Inches
                        </p>
                      </button>
                    </div>
                    <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                      <button
                        onClick={() => setTicketStatus(2)}
                        className="d-flex gap-3  ac-js cust-btn">
                        <div className="radio_btn d-flex ac-jc">
                          <div
                            className={`${
                              ticketStatus === 2 ? "sm_radio" : "sm_radio1"
                            }`}
                          />
                        </div>
                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Stitch
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Logo Size:
                  </p>
                  <div className="w-100 ms-3">
                    <input
                      disabled
                      value={"200×300"}
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Logo Color:
                  </p>
                  <div className="w-100 ms-3">
                    <textarea
                      value={"Red"}
                      disabled
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Repeat Logo?:
                  </p>
                  <div className="w-100 ms-3">
                    <select
                      disabled
                      placeholder=""
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      name=""
                      value={"Yes"}
                      id="">
                      <option></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Supplier Notes:
                  </p>
                  <div className="w-100 ms-3">
                    <textarea
                      disabled
                      value={"Sample Supplier Notes"}
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="field_row mt-3 mx-2 ">
              <div
                className="art-placeholder"
                // onClick={() => toggleShowdataPopups()}
              >
                <img src={product} className="img_pop field_row" />
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default ArtWorkerData;
