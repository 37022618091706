import { PointOfSale } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from '@mui/icons-material/NearMe';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { Country, state_list, sub_menu } from "../../../redux/api/DummyJson";

const AddressPopup = ({ poppupAddressToggle }) => {
    const [dropDwon, setDropDown] = useState(undefined)


    const [department, setDepartment] = useState('')
    const [address, setAddress] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    return (
        <div className="modal-popup">
            <div onClick={poppupAddressToggle} className="back-close" />
            <div className="z_ind_cust center-content-contact2 w-90 w-md-55 " >
                <div className="w-100 d-flex ac-jb mt-4 px-md-5 px-3">
                    <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
                        Add Address :
                    </p>
                    <div onClick={poppupAddressToggle} className="" >
                        <button class="cust-btn addbtn_cancel bg-danger text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">Cancel</button>
                        <button class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">save</button>
                    </div>
                </div>
                <div className='mt-3 h_cust_res overflow-scroll w-100 px-4 px-md-5 '>
                    <div className='d-flex as-jb my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            Address Name :
                        </p>
                        <input
                            placeholder="Required Field"
                            className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        />
                    </div>
                    <div className='d-flex as-jb my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            Company Name :
                        </p>
                        <input
                            placeholder="Required Field"
                            className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                    </div>
                    <div className='d-flex as-jb my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            Street Address :
                        </p>
                        <input
                            placeholder="Required Field"
                            className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                    </div>
                    <div className='d-flex as-jb my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            Suite, Apt etc :
                        </p>
                        <input
                            placeholder="Required Field"
                            className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                    </div>
                    <div className='d-flex as-jb my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            Optional 1 :
                        </p>
                        <input
                            placeholder="Required Field"
                            className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                    </div>
                    <div className='d-flex as-jb my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            Optional 2 :
                        </p>
                        <input
                            placeholder="Required Field"
                            className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                    </div>
                    <div className='d-flex as-jb my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            City :
                        </p>
                        <input
                            placeholder="Required Field"
                            className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                    </div>
                    <div className='d-flex as-jb position-relative my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            Country :
                        </p>
                        <div className="position-relative md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                            <input
                                value={country}
                                onChange={(e) => { setCountry(e.target.value); country.length === 0 ? setDropDown(undefined) : setDropDown(1) }}
                                placeholder="Required Field"
                                className="editBtn rounded-3 bg-white w-100 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                            <button
                                onClick={() => setDropDown(1)}
                                className="drop_down cust-btn">
                                <KeyboardArrowDownIcon />
                            </button>
                            {dropDwon === 1 && <div
                                className="invisible-cont2"
                                onClick={() => setDropDown(undefined)}
                            />}
                            <div
                                className={`${dropDwon === 1 && "submenu_1 w-100"
                                    } submenu_cont_1 overflow-scroll z-3`} style={{ height: '120px' }}>
                                {Country?.map((item) => {
                                    return (
                                        <div className="d-flex hover-cust">
                                            <button
                                                className="px-2 cust-btn text-start py-1 w-100"
                                                onClick={() => {
                                                    setDropDown(undefined)
                                                    setCountry(item?.list)
                                                }}>
                                                {item?.list}
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='d-flex as-jb my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            State/Province :
                        </p>
                        <div className="position-relative md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                            <input
                                value={state}
                                onChange={(e) => { setState(e.target.value); state.length === 0 ? setDropDown(undefined) : setDropDown(2) }}
                                placeholder="Required Field"
                                className="editBtn rounded-3 bg-white w-100 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                            <button
                                className="drop_down cust-btn" onClick={() => setDropDown(2)} >
                                <KeyboardArrowDownIcon />
                            </button>
                            {dropDwon === 2 && <div
                                className="invisible-cont2"
                                onClick={() => setDropDown(undefined)}
                            />}
                            <div
                                className={`${dropDwon === 2 && "submenu_1 w-100"
                                    } submenu_cont_1 overflow-scroll z-3`} style={{ height: '100px' }}>
                                {state_list?.map((item) => {
                                    return (
                                        <div className="d-flex hover-cust">
                                            <button
                                                className="px-2 cust-btn text-start py-1 w-100"
                                                onClick={() => {
                                                    setDropDown(undefined)
                                                    setState(item?.list)
                                                }}>
                                                {item?.list}
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='d-flex as-jb my-2 flex-column flex-md-row'>
                        <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                            Postal/Zip Code :

                        </p>
                        <input
                            placeholder="Required Field"
                            className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddressPopup;
