import React, { useState } from "react";

const AddEventCategory = ({ handleClose, handleOnSubmit }) => {
  const [addFormData, setAddFormData] = useState({
    image: "",
    category_name: "",
  });

  //   onchange Event
  const handleOnChange = (e) => {
    const { name, value, files } = e && e.target;
    let temp = {};
    if (name) {
      if (name === "image") {
        temp[name] = files[0];
        setAddFormData({
          ...addFormData,
          ...temp,
        });
      } else {
        temp[name] = value;
        setAddFormData({
          ...addFormData,
          ...temp,
        });
      }
    }
  };
  return (
    <div className="popup-wrap">
      <div className="popup-inner_box position-relative">
        <div className="position-absolute popup_close" onClick={handleClose}>
          <button className="btn">&#x2715;</button>
        </div>
        <h5 className="primary my-2">Add Event Category</h5>
        <div className="d-flex row my-2">
          <div className="col-6 mb-2">
            <label className="form-label">Category Image</label>
            <input
              type="file"
              name="image"
              className="form-control"
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="form-label">Category Name</label>
            <input
              type="text"
              className="form-control"
              name="category_name"
              placeholder="Enter Category Name"
              value={addFormData?.category_name || ""}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {" "}
          <button
            className="border-0 add_event_cat_btn"
            onClick={(e) => handleOnSubmit(addFormData)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEventCategory;
