import ReactDatePicker from "react-datepicker";
import { posBills } from "../../redux/api/DummyJson";
import { useRef, useState } from "react";
import { product } from "../../assets/img";
import DescriptionIcon from "@mui/icons-material/Description";
import { Col } from "react-bootstrap";

const EnterprisesProjects = () => {
  const [date, setDate] = useState(new Date());
  const [showPopupHander, setShowPopupHander] = useState(false);
  const [showPopupHander2, setShowPopupHander2] = useState(false);
  const [tab, setTab] = useState(0);

  const onShowPopupHander = () => {
    setShowPopupHander(!showPopupHander);
  };
  const onShowPopupHander2 = () => {
    setShowPopupHander2(!showPopupHander2);
  };
  const { innerWidth: width, innerHeight: height } = window;

  return (
    <div className="mt-5">
      <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
        Projects
      </p>
      <div className="d-flex flex-column flex-md-row w-100 as-jb gap-3">
        <div className="d-flex flex-column">
          <button
            onClick={() => {
              setTab(0);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 0
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style2"
                : ""
            } 
                 pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                tab == 0 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Opportunities
            </p>
          </button>
          <button
            onClick={() => {
              setTab(1);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style2"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                tab == 1 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Presentations
            </p>
          </button>
          <button
            onClick={() => {
              setTab(2);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 2
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style2"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                tab == 2 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Estimates
            </p>
          </button>
          <button
            onClick={() => {
              setTab(3);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 3
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style2"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                tab == 3 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Sales Orders
            </p>
          </button>
          <button
            onClick={() => {
              setTab(4);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 4
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style2"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                tab == 4 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Invoices
            </p>
          </button>
        </div>
        <div className="" style={{ width: "100%", overflow: "scroll" }}>
          <div className="mt-3 w-100">
            <table
              className="table-design-new"
              style={{ width: "100%", border: "1px solid #dee2e6" }}
            >
              <tr className="mt-4 border-bottom">
                <td>
                  <p className="w-100 f4 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    S.No
                  </p>
                </td>
                <td>
                  <p className="w-100 f4 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Stages
                  </p>
                </td>
                <td>
                  <p className="w-100 f4 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Project ID
                  </p>
                </td>
                <td>
                  <p className="w-100 f4 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Project Name
                  </p>
                </td>
                <td>
                  <p className="w-100 f4 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Rep
                  </p>
                </td>
                {tab == 0 || tab == 1 ? null : (
                  <td>
                    <p className="w-100 f4 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                      Sub Total
                    </p>
                  </td>
                )}
                <td>
                  <p className="w-100 f4 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Date
                  </p>
                </td>
              </tr>
              <tbody>
                {[0, 1, 2].map((item, ind) => {
                  return (
                    <tr>
                      <td>
                        <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc pointerView">
                          {ind + 1}
                        </p>
                      </td>
                      <td>
                        <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc pointerView">
                          Estiamte #22019
                        </p>
                      </td>
                      <td>
                        <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc pointerView">
                          29828
                        </p>
                      </td>
                      <td>
                        <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc pointerView ">
                          Different Product same vendor
                        </p>
                      </td>
                      <td>
                        <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc pointerView">
                          Neeraj Harlalka
                        </p>
                      </td>
                      {tab == 0 || tab == 1 ? null : (
                        <td>
                          <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                            $44,724.83
                          </p>
                        </td>
                      )}
                      <td>
                        <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                          2023-11-08
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-end gap-2 mt-3">
              <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                Previous
              </button>
              <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterprisesProjects;
