import React, { useState } from "react";
import NavbarHead from "../Sub_Layout/NavbarHead";
import EstimateHeadComp from "../EstimateComp/EstimateHeadComp";
import TableComp from "../EstimateComp/TableComp";
import { Col } from "react-bootstrap";
import TableComptwo from "../EstimateComp/TableComptwo";
import AddContactPopup from "../EstimateComp/Poppup/AddContactPopup";
import AddressPopup from "../EstimateComp/Poppup/AddressPopup";
import SalesOrderHeadComp from "../sales_order/SalesOrderHeadComp";
import SalesOrderTableComp from "../sales_order/SalesOrderTableComp";
import SalesOrderTableComptwo from "../sales_order/SalesOrderTableComptwo";
import ViewStockPoppup from "../EstimateComp/ViewStockPoppup";
import DeletePopup from "../Popup/DeletePopup";
import TableDetaile from "../sales_order/TableDetaileComp";
import PaymentDetails from "../sales_order/Poppup/PaymentDetails";
import SalesOrderTrancDetails from "../sales_order/SalesOrderTrancDetails";
import PresentationCopyPopup from "./PresentationCopyPopup";
import { CheckBox } from "@mui/icons-material";

const InvoicePage = () => {
  const [addContactComp, setAddContactComp] = useState(false);
  const [addAddressComp, setAddAddressComp] = useState(false);
  const [actionBtn, setActionBtn] = useState(false);
  const [addTitle, setAddTitle] = useState(false);
  const [deletes, setDeletes] = useState(false);
  const [viewStock, setViewStock] = useState(false);

  const [paymentDetail, setPaymentDeta] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [collaboratClick, setCollaboratClick] = useState(true);

  const [boxRadioBtn, setBoxRadioBtn] = useState(null);
  const [checkBox, setCheckBox] = useState();

  const onCollaborat = () => {
    setCollaboratClick(!collaboratClick);
  };
  const poppupToggle = () => {
    setAddContactComp(!addContactComp);
  };
  const poppupAddressToggle = () => {
    setAddAddressComp(!addAddressComp);
  };

  const [tab, setTab] = useState(1);
  const [toggleBtn1, setToggleBtn1] = useState(2);
  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };

  const deleteToggle = () => {
    setDeletes(!deletes);
  };
  const viewStockToggle = () => {
    setViewStock(!viewStock);
  };
  const PaymenDetTogg = () => {
    setPaymentDeta(!paymentDetail);
  };

  return (
    <div className="dashRightView2 overview_pages overflow-scroll ">
      {viewStock && <ViewStockPoppup viewStockToggle={viewStockToggle} />}
      {deletes && (
        <DeletePopup deleteToggle={deleteToggle} setAddTitle={setAddTitle} />
      )}
      {/* <PaymentDetails /> */}
      <div className=" d-flex justify-content-end position-relative mb-2">
        <div className="action_overview mx-4">
          <NavbarHead onLinkShow={onLinkShow} type={"invoice"} />
          {actionBtn && (
            <div
              className="invisible-cont2" 
              style={{ zIndex: 200 }}
              onClick={() => setActionBtn(false)}
            />
          )}
          {actionBtn && (
            <div className="action_btn ml-1" style={{ zIndex: 300 }}>
              <button
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                onClick={() => {
                  setActionBtn(false);
                }}
              >
                Create Invoice
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Copy Sales Order
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Preview Sales Order
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 text-dark bg-white text-start f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Email Sales Order
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                View Portal
              </button>
            </div>
          )}
        </div>
      </div>
      {addContactComp && <AddContactPopup poppupToggle={poppupToggle} />}
      {addAddressComp && (
        <AddressPopup poppupAddressToggle={poppupAddressToggle} />
      )}
      <SalesOrderHeadComp
        PaymenDetTogg={PaymenDetTogg}
        setPaymentDeta={setPaymentDeta}
        paymentDetail={paymentDetail}
        onCollaborat={onCollaborat}
        collaboratClick={collaboratClick}
        setAddContComp={setAddContactComp}
        poppupAddressToggle={poppupAddressToggle}
        boxRadioBtn={boxRadioBtn}
        setBoxRadioBtn={setBoxRadioBtn}
        setCheckBox={setCheckBox}
        checkBox={checkBox}
        type={"invoice"}
      />
      <div className="mt-3 d-flex ac-jb mt-5 flex-column flex-md-row">
        <div className="d-flex ac-js">
          <button
            onClick={() => setTab(1)}
            className={`${
              tab == 1 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Products
          </button>
          <button
            onClick={() => setTab(2)}
            className={`${
              tab == 2 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Artwork
          </button>
          {boxRadioBtn == 3 && (
            <button
              onClick={() => setTab(3)}
              className={`${
                tab == 3 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Transaction Details
            </button>
          )}
        </div>
        {tab === 1 && (
          <div className="d-flex ac-jc gap-3 mt-3 mt-md-0 flex-column flex-sm-row ">
            {/* {tab == 1 && ( */}
            <div className="d-flex">
              <button
                onClick={() => setToggleBtn1(1)}
                className={` ${
                  toggleBtn1 === 1 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Compacts
              </button>
              <button
                onClick={() => setToggleBtn1(2)}
                className={` ${
                  toggleBtn1 === 2 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Detailed
              </button>
            </div>
          </div>
        )}
      </div>

      {tab === 1 && toggleBtn1 === 1 && (
        <SalesOrderTableComp
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
        />
      )}
      {tab === 1 && toggleBtn1 !== 1 && (
        <TableDetaile
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
        />
      )}
      {tab === 2 && (
        <SalesOrderTableComptwo
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
        />
      )}

      {tab === 3 && (
        <SalesOrderTrancDetails
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
        />
      )}

      {addTitle && (
        <div className="presen-list mb-3">
          <div className="add-tit-btn presen-detail presen-list mt-3">
            <div className="m-2">
              <input
                placeholder="Add a title"
                className="w-100 b-color-add mt-3"
              />
              <div className="d-flex mt-2 position-absolute m-2">
                <div className="dropdown mx-2">
                  <button
                    className="add-tit-btn2 mx-1 bottom_show_btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span className="dropdown-item">Product</span>
                    </li>
                    <li>
                      <span className="dropdown-item">Tittle</span>
                    </li>
                  </ul>
                </div>
                {/* <button className="add-tit-btn1">+ Add</button> */}
                <button
                  className="add-tit-btn2 mx-1 bottom_show_btn"
                  onClick={() => setAddShow(!addShow)}
                >
                  {addShow ? "Show" : "hide"}
                </button>
                <button
                  className="add-tit-btn3 mx-1 bottom_delete_btn"
                  onClick={() => setDeletes(!deletes)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {tab !== 3 && (
        <div className="d-flex as-jb flex-column flex-md-row gap-3 w-100">
          <div class="summary bg-soft-gray1 w-35 md_t_W">
            <div className="d-flex ac-jb py-3 px-1 px-md-3">
              <p className="">Booked Margin Amount</p>
              <p className="">$0.00</p>
            </div>
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">Booked Margin Amount</p>
              <p className="">$0.00</p>
            </div>
          </div>
          {tab == 1 && (
            <div class="summary bg-soft-gray1 w-45 md_t_W ">
              <div className="d-flex ac-jb py-3 px-1 px-md-3">
                <p className="">Subtotal</p>
                <p className="">$0.00</p>
              </div>
              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <p className="">CGST (2.5%)</p>
                <p className="">$0.00</p>
              </div>
              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <p className="">Total</p>
                <p className="">$0.00</p>
              </div>
              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <div className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="">Hide Totals</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InvoicePage;
