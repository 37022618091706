import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import ProfileList from "../components/AddRole/ProfileList";
import ProfileRolesList from "../components/AddRole/ProfileRolesList";
import VendorList from "../components/AddRole/VendorList";
import ProductAdminList from "../components/AddRole/ProductAdminList";
import ClientList from "../components/AddRole/ClientList";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ImageCroper from "../components/Popup/ImageCroper";

const DecorativeMethodEdit = () => {
  const navigate = useNavigate();
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [showPricing, setShowPricing] = useState(false);
  const toggleImagePopup = () => {
    setCroper(!croper);
  };
  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          Edit Decoration Method
        </p>

        <div className="d-flex ac-jc add-content">
          <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Method Name
            </p>
            <div className="w-100 d-flex w-100 mt-xs-5 position-relative">
              <input
                className="cust-btn searchFill w-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Decoration Method"
              />
            </div>
          </div>
          <div
            className="w-100 d-flex ac-jb mt-4 flex-wrap"
            onClick={() => toggleImagePopup()}
          >
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Image (Optional)
            </p>
            <div className="w-100 d-flex w-100 mt-xs-5 position-relative">
              <input
                className="cust-btn searchFill w-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                // placeholder="Search"
                // type="file"
                value={
                  croperImage ? "Image Uploaded Successfuly" : "Upload File"
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div className="overflow-scroll mt-4">
            <p
              role="button"
              className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary"
              style={{ textDecoration: "underline" }}
            >
              John Rolph Supplier
            </p>
            <table className="w-100">
              <tr className="mt-4">
                <div className="">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          UOM
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "150px" }}
                        >
                          Inches
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 1 Rate
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 2 Rate
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 3 Rate
                        </p>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr className="mt-2">
                <div className="my-3">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          disabled
                          value={"Inches"}
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="3"
                          disabled
                          style={{ width: "150px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="100"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="150"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="250"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
            <table className="w-100">
              <tr className="mt-4">
                <div className="">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          UOM
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "150px" }}
                        >
                          Inches
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 1 Rate
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 2 Rate
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 3 Rate
                        </p>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr className="mt-2">
                <div className="my-3">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          disabled
                          value={"Stitch"}
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="3"
                          disabled
                          style={{ width: "150px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="100"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="150"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="250"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
          <div className="overflow-scroll mt-4">
            <p
              role="button"
              className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
            >
              5SK Supplier
            </p>
            <table className="w-100">
              <tr className="mt-4">
                <div className="">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          UOM
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "150px" }}
                        >
                          Inches
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 1 Rate
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 2 Rate
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 3 Rate
                        </p>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr className="mt-2">
                <div className="my-3">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          disabled
                          value={"Inches"}
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="3"
                          disabled
                          style={{ width: "150px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="75"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="100"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="150"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
            <table className="w-100">
              <tr className="mt-4">
                <div className="">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          UOM
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "150px" }}
                        >
                          Inches
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 1 Rate
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 2 Rate
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Color 3 Rate
                        </p>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr className="mt-2">
                <div className="my-3">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          disabled
                          value={"Stitch"}
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="3"
                          disabled
                          style={{ width: "150px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="90"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="150"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          value="180"
                          disabled
                          style={{ width: "100px" }}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
          <button
            class="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mt-4"
            onClick={() => setShowPricing(!showPricing)}
          >
            {showPricing ? "Hide Pricing" : "View Pricing"}
          </button>
          {showPricing && (
            <div className="overflow-scroll mt-4">
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Inches"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="75"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Stitch"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="90"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="180"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div>
          )}
        </div>
        <div className="d-flex mt-3 justify-content-start m-2">
          <button
            className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => navigate("/decorative-method")}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DecorativeMethodEdit;
