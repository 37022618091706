import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const PresentationPricingTable = ({ priceCharge, setPriceCharge }) => {
  const [imprintCost, setImprintCost] = useState({
    0: "Setup Cost",
    1: "Setup Cost",
  });

  const onNoNameChange = (e, index) => {
    const value = e.target.value;
    setImprintCost((pre) => ({ ...pre, [index]: value }));
  };

  const addpriceCharge = () => {
    let temp = [...priceCharge];

    temp.push({ num1: "", num2: "", num3: "", num4: "" });
    setPriceCharge(temp);
  };

  const removepriceCharge = (e, ind) => {
    let temp = [...priceCharge];
    temp.splice(ind, 1);
    setPriceCharge(temp);
  };

  const handlepriceCharge = (e, index) => {
    let temp = [...priceCharge];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setPriceCharge(temp);
  };
  return (
    <div style={{ width: "450px" }} className="my-4">
      <table className="w-100  overflow-scroll">
        <thead>
          <tr className="w-100 presen-pricing-tab-head my-4">
            <th className="table_head">
              <div className="d-flex ac-jc">
                <p className="mx-4 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                  Label
                </p>
              </div>
            </th>
            <th className="table_head">
              <div className="d-flex ac-jc">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                  Cost
                </p>
              </div>
            </th>
            <th className="table_head">
              <div className="d-flex ac-jc">
                <p className="mx-2 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                  Margin
                </p>
              </div>
            </th>
            <th className="table_head">
              <div className="d-flex ac-jc">
                <p className="mx-2 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                  Retail
                </p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {priceCharge?.map((item, ind) => {
            return (
              <tr className="">
                <th
                  className="d-flex primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
                  style={{ padding: "10px 0px" }}>
                  <span className="table_data d-flex ac-jc">
                    <button
                      className="cust-btn"
                      onClick={() => removepriceCharge(item, ind)}>
                      <HighlightOffIcon />
                    </button>
                  </span>
                  <div className="table_head d-flex ac-jc">
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#07679c",
                      }}
                      className=" num-cls-btn rounded-3 w-100 mx-3 d-flex border-0 ac-jc">
                      <p className="p-0 text-light fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {ind + 1}
                      </p>
                    </div>
                    <input
                      className="editBtn num-cls-btn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      // value={item?.num1}
                      // value={"Setup Cost"}
                      value={imprintCost[ind]}
                      name="num1"
                      onChange={(e) => {
                        handlepriceCharge(e, ind);
                        onNoNameChange(e, ind);
                      }}
                    />
                  </div>
                </th>
                <td>
                  <div className="mx-2 d-flex ac-jc">
                    <input
                      class="num-cls-btn table_head f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      placeholder="$100.00"
                      value={item?.num2}
                      name="num2"
                      type={"number"}
                      onChange={(e) => handlepriceCharge(e, ind)}
                    />
                  </div>
                </td>
                <td style={{ padding: "5px 0px" }}>
                  <div className="mx-2 d-flex ac-jc">
                    <input
                      class="num-cls-btn table_head f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      value={item?.num3}
                      placeholder="0.000"
                      name="num3"
                      type={"number"}
                      onChange={(e) => handlepriceCharge(e, ind)}
                    />
                  </div>
                </td>
                <td style={{ padding: "5px 0px" }}>
                  <div className="mx-2  d-flex ac-jc">
                    <input
                      class="num-cls-btn table_head f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      value={item?.num4}
                      placeholder="0.000"
                      name="num4"
                      type={"number"}
                      onChange={(e) => handlepriceCharge(e, ind)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
          <tr>
            <td></td>
            <td className="text-end">
              <button
                className="border-0 text-nowrap mt-3 text-info primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                style={{ backgroundColor: "#f4f8ff" }}
                onClick={() => addpriceCharge()}>
                + add fixed charge
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PresentationPricingTable;
