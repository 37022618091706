import EventNoteIcon from "@mui/icons-material/EventNote";
import CallIcon from "@mui/icons-material/Call";
import GroupsIcon from "@mui/icons-material/Groups";
import { animatedImg, empty_profile } from "../../assets/img";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LinkIcon from "@mui/icons-material/Link";
import { replyData } from "../../redux/api/DummyJson";
import SendIcon from "@mui/icons-material/Send";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import React, { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import EnterpriseAddNotes from "./EnterpriseAddNotes";

const EnterprisesHistoryDetails = () => {
  const [reply, setReply] = useState(false);
  const [note, setNote] = useState(false);
  const [call, setCall] = useState(false);
  const [meeting, setMeeting] = useState(false);
  const [repIndex, setRepIndex] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [noteIndex, setNoteIndex] = useState("");
  const navigate = useNavigate();

  const handleReply = (ind) => {
    if (repIndex === ind) {
      setReply(!reply);
    }
  };

  const handleNotes = (ind) => {
    if (noteIndex === ind) {
      setOpenNotes(!openNotes);
    }
  };

  return (
    <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
      <div className="w-100 replyPaddings mt-1">
        {!note && !call && !meeting && (
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Hey Charles,
          </p>
        )}
        {!note && !call && !meeting && (
          <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Find below your recent feed:
          </p>
        )}
        {!note && !call && !meeting && (
          <div className="w-100 ac-jc d-flex">
            <div className="d-flex w-lg-90 w-md-60 w-sm-70 flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row ac-jb">
              <div
                onClick={() => setNote(true)}
                className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
              >
                <EventNoteIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Note
                </p>
              </div>
              <div
                onClick={() => setCall(true)}
                className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
              >
                <CallIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Call
                </p>
              </div>
              <div
                onClick={() => setMeeting(true)}
                className="d-flex pointerView px-3 py-2 rounded-3 bg-soft-primary mt-3"
              >
                <GroupsIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Meeting
                </p>
              </div>
            </div>
          </div>
        )}
        {note && <EnterpriseAddNotes type="note" setNote={setNote} />}
        {call && <EnterpriseAddNotes type="call" setNote={setCall} />}
        {meeting && <EnterpriseAddNotes type="meet" setNote={setMeeting} />}

        <div className="w-100 mt-4 d-flex ac-jb">
          <div className="d-flex">
            <PlayArrowIcon className="black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
            <p className="f4 ms-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-16 primary">
              New messages
            </p>
          </div>
          <p className="f2 ms-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-16 text-primary">
            10 new messages
          </p>
        </div>

        <div className="w-100 d-flex ac-je mt-4">
          <div className="d-flex flex-row">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show:
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">All</option>
            </select>
            <p className="f2 ms-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Time Period:
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">All</option>
            </select>
          </div>
        </div>
        {replyData?.map((item, index) => {
          return (
            <div className=" w-xl-100 w-lg-100 w-md-90 w-sm-100 w-xs-100 d-grid ac-jc mt-5">
              <div className="w-100 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row ac-jc gap-3">
                <div>
                  <img src={empty_profile} className="replyImg rounded-3" />
                </div>
                <div>
                  <p className="primary f3 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                    {item?.name}{" "}
                    <span className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Hourglass Essentials Pvt. Ltd.
                    </span>
                  </p>
                  <p className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    Roger added the vendor account{" "}
                    <span className="text-black f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      {item?.referName}
                    </span>
                  </p>
                  <p className="primary1 f3 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Tagged in this post.
                  </p>
                </div>
              </div>
              <div className="mt-3 w-xl-100 w-lg-100 w-xs-100 w-sm-100 d-flex ac-jb">
                <div
                  onClick={() => {
                    handleReply(index);
                    setRepIndex(index);
                    setNoteIndex("");
                  }}
                  className="d-flex pointerView"
                >
                  <ChatBubbleOutlineIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Reply
                  </p>
                </div>
                <div
                  onClick={() => {
                    setNoteIndex(index);
                    handleNotes(index);
                    setRepIndex("");
                  }}
                  className="d-flex pointerView"
                >
                  <NoteAddOutlinedIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Add Note
                  </p>
                </div>
                <div
                  onClick={() => navigate("/view-thread")}
                  className="d-flex pointerView"
                >
                  <LinkIcon className="black linkImg f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Full thread
                  </p>
                </div>
                <div className="d-flex pointerView">
                  <DownloadIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Download
                  </p>
                </div>
                {/* <div className="d-flex pointerView">
                  <DeleteIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Delete
                  </p>
                </div> */}
              </div>
              <div className="mt-2">
                <p className="primary f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  {item?.date}
                </p>
              </div>
              {repIndex === index && reply && (
                <div className="w-100 mt-2">
                  <div className="d-flex ac-je">
                    <textarea
                      className="editBtn rounded-3 p-2 w-90"
                      placeholder="Type here..."
                    />
                    <div className="mt-4 ms-1">
                      <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                    </div>
                  </div>
                </div>
              )}
              {noteIndex === index && openNotes && (
                <EnterpriseAddNotes type="thread" setOpenNotes={setOpenNotes} />
              )}
            </div>
          );
        })}

        {/* {reply && (
            <div className="w-100 mt-5">
              <div className="d-flex">
                <textarea
                  className="cust-btn w-90"
                  placeholder="Type here..."
                />
                <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
              </div>
            </div>
          )}
          <div className="w-100 ac-jc d-flex mt-4">
            <p className="pointerView f3 mt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
              See more
            </p>
          </div> */}
      </div>
    </div>
  );
};

export default EnterprisesHistoryDetails;
