import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import manImage from "../../../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import {
  animatedImg,
  editIcon,
  searchIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../../../../assets/img";
import { RiDeleteBack2Line } from "react-icons/ri";
import { TiTick } from "react-icons/ti";
import { RxTriangleUp } from "react-icons/rx";
import { adminData } from "../../../../redux/api/DummyJson";
import { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ProductTransferView = () => {
  const navigate = useNavigate();
  const [checkProd, setCheckProd] = useState(false);
  const [open, setOpen] = useState(false);

  const handleShow = () => {
    setOpen(!open);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-sm-flex w-100 ac-jb text-center">
        <p
          className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-21 primary p-0 d-flex align-items-center gap-1"
          role={"button"}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon /> Product Tranfer Detail
        </p>
        <div className="position-relative">
          <button
            className="px-3 py-1 bg-dark cust-btn f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-white mt-2 mt-sm-0 rounded"
            onClick={handleShow}
          >
            Total warehouse Stock - 800
          </button>
          {open && (
            <div className="product-transfer-view">
              <RxTriangleUp className="arw-svg" />
              <div className="d-flex justify-content-between border-bottom align-items-center pb-2">
                <p className="f4 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 primary p-0">
                  Stocks From Warehouse
                </p>
                <div className="fnt-fam-f3 fs-5" onClick={handleShow}>
                  <RiDeleteBack2Line />
                </div>
              </div>
              <div className="d-flex flex-column">
                {/* <div className="product-transfer-view-model-tick-cont-active">
                    <TiTick />
                  </div> */}
                <div className="d-flex w-100 justify-content-between p-2">
                  <div className="d-flex gap-1">
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Location:{" "}
                    </p>{" "}
                    <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Trichy
                    </p>
                  </div>
                  <div className="d-flex gap-1 ">
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Quantity:{" "}
                    </p>{" "}
                    <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      200
                    </p>
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between p-2">
                  <div className="d-flex gap-1">
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Location:{" "}
                    </p>{" "}
                    <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Erode
                    </p>
                  </div>
                  <div className="d-flex gap-1 ">
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Quantity:{" "}
                    </p>{" "}
                    <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      300
                    </p>
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between  p-2">
                  <div className="d-flex gap-1">
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Location:{" "}
                    </p>{" "}
                    <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Namakkal
                    </p>
                  </div>
                  <div className="d-flex gap-1 ">
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Quantity:{" "}
                    </p>{" "}
                    <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      300
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4 mb-3">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex align-items-center">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2  px-3">
            Total Count : {"04"}
          </p>
          <div className="d-flex ">
            <p className="px-2 cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15  border-primar-2 rounded">
              SKU ID - SKU200
            </p>
          </div>
          <div className="d-flex">
            <p className="px-2 cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15  border-primar-2  rounded">
              Bottle
            </p>
          </div>
        </div>
      </div>

      <table className="overflow-scroll d-block">
        <tr className="d-flex border-bottom" style={{ width: "fit-content" }}>
          <th
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
            style={{ width: "70px", padding: " 10px" }}
          >
            S.No
          </th>
          <th
            style={{ width: "180px", padding: " 10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Project ID with Name
          </th>
          <th
            style={{ width: "230px", padding: " 10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Customer ID with Name
          </th>
          <th
            style={{ minWidth: "230px", padding: " 10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Requested Qty
          </th>
          <th
            style={{ width: "200px", padding: " 10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Assign Warehouse Qty
          </th>
          <th
            style={{ width: "200px", padding: " 10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Branded Stock
          </th>
          <th
            style={{ width: "200px", padding: " 10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Supplier Requested Qty
          </th>
          <th
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            View
          </th>
        </tr>
        <tr className="d-flex" style={{ width: "fit-content" }}>
          <td
            style={{ width: "70px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            1
          </td>
          <td
            style={{ width: "180px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Pro-001 Ninos Entrepries
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            EPC001 Ninos
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            1100
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            <input
              style={{ minWidth: "70px", width: "70px" }}
              type="number"
              className="editBtn  rounded-3 p-1 w-90 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder=""
            />
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            98
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            1100
          </td>
          <td
            style={{ width: "200px" }}
            className="d-flex justify-content-center position-relative prod-check-box align-items-center"
          >
            <div
              onClick={() => setCheckProd(!checkProd)}
              className="tick-mark1"
            >
              <div
                style={{
                  width: checkProd ? "0px" : "17px",
                  height: checkProd ? "0px" : "17px",
                }}
                className="tick-mark"
              ></div>
            </div>
            <div
              className=""
              style={{ width: "50px" }}
              onClick={() => {
                navigate("/product-vendor-details", {
                  state: { type: "Charles" },
                });
              }}
            >
              <div className="ms-1 rounded-2 text-center">
                <img src={viewIcon} className="viewDes" />
              </div>
            </div>
          </td>
        </tr>
        <tr className="d-flex" style={{ width: "fit-content" }}>
          <td
            style={{ width: "70px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            2
          </td>
          <td
            style={{ width: "180px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Pro-002 Viswa
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            CMHQ001 Viswa
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            500
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            <input
              style={{ minWidth: "70px", width: "70px" }}
              type="number"
              className="editBtn  rounded-3 p-1 w-90 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder=""
            />
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            -
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            -
          </td>
          <td
            style={{ width: "200px" }}
            className="d-flex justify-content-center position-relative prod-check-box align-items-center"
          >
            <div
              onClick={() => setCheckProd(!checkProd)}
              className="tick-mark1"
            >
              <div
                style={{
                  width: checkProd ? "0px" : "17px",
                  height: checkProd ? "0px" : "17px",
                }}
                className="tick-mark"
              ></div>
            </div>
            <div
              className=""
              style={{ width: "50px" }}
              onClick={() => {
                navigate("/product-vendor-details", {
                  state: { type: "Charles" },
                });
              }}
            >
              <div className="ms-1 rounded-2 text-center">
                <img src={viewIcon} className="viewDes" />
              </div>
            </div>
          </td>
        </tr>
        <tr className="d-flex" style={{ width: "fit-content" }}>
          <td
            style={{ width: "70px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            3
          </td>
          <td
            style={{ width: "180px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Pro-003 Krishna Brand
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            EPC002 Krishna
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            700
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            <input
              style={{ minWidth: "70px", width: "70px" }}
              type="number"
              className="editBtn  rounded-3 p-1 w-90 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder=""
            />
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            -
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            400
          </td>
          <td
            style={{ width: "200px" }}
            className="d-flex justify-content-center position-relative prod-check-box align-items-center"
          >
            <div
              onClick={() => setCheckProd(!checkProd)}
              className="tick-mark1"
            >
              <div
                style={{
                  width: checkProd ? "0px" : "17px",
                  height: checkProd ? "0px" : "17px",
                }}
                className="tick-mark"
              ></div>
            </div>
            <div
              className=""
              style={{ width: "50px" }}
              onClick={() => {
                navigate("/product-vendor-details", {
                  state: { type: "Charles" },
                });
              }}
            >
              <div className="ms-1 rounded-2 text-center">
                <img src={viewIcon} className="viewDes" />
              </div>
            </div>
          </td>
        </tr>
        <tr className="d-flex" style={{ width: "fit-content" }}>
          <td
            style={{ width: "70px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            4
          </td>
          <td
            style={{ width: "180px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Pro-004 5SK
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            EPC003 Sakthi
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            700
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            <input
              style={{ minWidth: "70px", width: "70px" }}
              type="number"
              className="editBtn  rounded-3 p-1 w-90 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder=""
            />
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            -
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            700
          </td>
          <td
            style={{ width: "200px" }}
            className="d-flex justify-content-center position-relative prod-check-box align-items-center"
          >
            <div
              onClick={() => setCheckProd(!checkProd)}
              className="tick-mark1"
            >
              <div
                style={{
                  width: checkProd ? "0px" : "17px",
                  height: checkProd ? "0px" : "17px",
                }}
                className="tick-mark"
              ></div>
            </div>
            <div
              className=""
              style={{ width: "50px" }}
              onClick={() => {
                navigate("/product-vendor-details", {
                  state: { type: "Charles" },
                });
              }}
            >
              <div className="ms-1 rounded-2 text-center">
                <img src={viewIcon} className="viewDes" />
              </div>
            </div>
          </td>
        </tr>
        <tr className="d-flex bg-primar" style={{ width: "fit-content" }}>
          <td
            style={{ width: "70px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          ></td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f3 text-white fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            Total
          </td>
          <td
            style={{ width: "180px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          ></td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="f3 text-white fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            3000
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          ></td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f3 text-white fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            120
          </td>
          <td
            style={{ width: "200px", padding: "10px" }}
            className="f3 text-white fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-center"
          >
            1100
          </td>
          <td
            style={{ width: "200px" }}
            className="d-flex justify-content-center position-relative prod-check-box"
          ></td>
        </tr>
      </table>

      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default ProductTransferView;
