import React, { useEffect, useState } from "react";
import { emptyImg, product, profilePic, uploadIcon } from "../../assets/img";
import Carousel from "react-bootstrap/Carousel";
import { productDetailImg } from "../../redux/api/constants";
import { EditAttributes } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { owl_List, product_image } from "../../redux/api/DummyJson";
import ImageCroper from "../Popup/ImageCroper";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const RightComp = ({ edit }) => {
  const [logo, setLogo] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [index, setIndex] = useState(0);
  const [imageShow, setImageShow] = useState(false);
  const [smallMockimage, setSmallMockImage] = useState([]);
  const [colorImg, setColorImg] = useState(null);
  const [smallimage, setSmallImage] = useState([]);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });
  const [mockImage, setMockImage] = useState({ image: "", ind: "" });
  const [fistShow, setFirstShow] = useState(false);
  const [fistShows, setFirstShows] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const [textShows, setTextShows] = useState(true);
  const [croperCol, setCroperCol] = useState(0);

  const [image, setImage] = useState([]);

  const [Indeximage, setIndexImage] = useState("");
  const [imageName, setImageName] = useState("defauld");

  const imageUplode = (e) => {
    setColorImg(e);
  };

  const imageSelector = (item, ind, type) => {
    if (type == "mock") {
      setMockImage({ image: item, ind: ind });
    } else if (type == "product") {
      setBigImage({ image: item, ind: ind });
    }
  };

  const dualToggele = (type) => {
    if (type == "color") {
      setCroperCol(1);
      toggleImagePopup();
    } else if (type == "product") {
      setCroperCol(0);
      toggleImagePopup();
    } else {
      setCroperCol(2);
      toggleImagePopup();
    }
  };

  useEffect(() => {
    if (croperCol == 0 && croperImage) {
      setTextShow(false);
      setFirstShow(true);

      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallImage(temp);
      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    } else if (croperCol == 1 && croperImage) {
      imageUplode(croperImage);
    } else if (croperCol == 2 && croperImage) {
      setTextShows(false);
      setFirstShows(true);

      let temp = [...smallMockimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallMockImage(temp);
      setMockImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    }
  }, [croperImage]);

  const removeImg = (ind, type) => {
    if (type == "product") {
      let temp = [...smallimage];

      temp.splice(ind, 1);
      setSmallImage(temp);
      if (temp?.length - 1 === 0) {
        setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      } else {
        setBigImage({ image: temp[ind - 1], ind: ind - 1 });
      }
    } else if (type == "mock") {
      let temps = [...smallMockimage];

      temps.splice(ind, 1);
      setSmallMockImage(temps);
      if (temps?.length - 1 === 0) {
        setMockImage({ image: temps[temps.length - 1], ind: temps.length - 1 });
      } else {
        setMockImage({ image: temps[ind - 1], ind: ind - 1 });
      }
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  // Slide
  return (
    <div className="d-flex gap-2 flex-column ac-jc w-100 mb-5">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="w-100 d-flex flex-column ac-jc pb-4">
        <div className="d-flex ac-js mb-3 gap-3">
          <h4 className="primary">Product Image</h4>
        </div>
        <div>
          <div className="">
            <div
              className="position-relative"
              onClick={() => !fistShow && dualToggele("product")}
            >
              <img
                className={"img_up_cont"}
                alt="img"
                src={bigImage?.image ? bigImage?.image : uploadIcon}
              />
              {!textShow && (
                <div>
                  <ul
                    className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                  >
                    <li className="set-default-image">Set&nbsp;Default</li>
                    {smallimage?.length > 1 && (
                      <li
                        className="set-default-image"
                        onClick={() => removeImg(bigImage?.ind, "product")}
                      >
                        Remove
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
            <div className="d-flex ac-jc gap-3 w-100">
              {fistShow && (
                <div
                  class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                  style={{ width: "300px", overflow: "scroll" }}
                >
                  {smallimage?.map((item, ind) => {
                    return (
                      <div className="gap-3 d-flex ac-j w-100">
                        <div
                          class="add_img d-flex ac-jc"
                          onClick={() => imageSelector(item, ind, "product")}
                        >
                          {" "}
                          <img src={item} class="cp add_img object-fit-fill" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {smallimage?.length > 0 && (
                <label
                  className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                    smallimage?.length == 0 && "mt-3"
                  }`}
                  onClick={() => dualToggele("product")}
                >
                  +
                </label>
              )}
            </div>
          </div>
        </div>

        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-2">
          Color Image
        </p>
        <div
          className="d-flex gap-3 mt-3 ac-jc flex-wrap"
          style={{ width: "350px" }}
        >
          <div className="d-flex flex-column ac-jc position-relative for_hover">
            <label className="add_img d-flex ac-jc cp f3 primary fs-3 ">
              <img src={product} className="add_img object-fit-contain" />
            </label>{" "}
            <div className="d-flex w-100 justify-content-between position-absolute in_hover">
              <FileDownloadOutlinedIcon />
              <ClearOutlinedIcon />
            </div>
            <p className="f3" style={{ color: "red" }}>
              Red
            </p>
          </div>
          <div className="d-flex flex-column ac-jc position-relative for_hover">
            <label className="add_img d-flex ac-jc cp f3 primary fs-3 ">
              <img src={product} className="add_img object-fit-contain" />
            </label>{" "}
            <div className="d-flex w-100 justify-content-between position-absolute in_hover">
              <FileDownloadOutlinedIcon />
              <ClearOutlinedIcon />
            </div>
            <p className="f3" style={{ color: "blue" }}>
              Blue
            </p>
          </div>
          {edit ? (
            <div className="d-flex flex-column ac-jc">
              <label className="add_img d-flex ac-jc cp f3 primary fs-3">
                +
                <input
                  className="d-none"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={imageUplode}
                />
              </label>
              <p className="f3"> Add </p>
            </div>
          ) : null}
        </div>
        <div className="d-flex ac-js mt-5 mb-3 gap-3">
          <h4 className="primary">Mockup Image</h4>
        </div>

        <div>
          {" "}
          <div className="">
            <div
              className="position-relative"
              onClick={() => !fistShows && dualToggele("mock")}
            >
              <img
                className={"img_up_cont"}
                src={mockImage?.image ? mockImage?.image : uploadIcon}
              />
              {!textShows && (
                <div>
                  <ul
                    className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                  >
                    <li className="set-default-image">Set&nbsp;Default</li>
                    {smallMockimage?.length > 1 && (
                      <li
                        className="set-default-image"
                        onClick={() => removeImg(mockImage?.ind, "mock")}
                      >
                        Remove
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
            <div className="d-flex ac-jc gap-3 w-100">
              {fistShows && (
                <div
                  class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                  style={{ width: "300px", overflow: "scroll" }}
                >
                  {smallMockimage?.map((item, ind) => {
                    return (
                      <div className="gap-3 d-flex ac-j w-100">
                        <div
                          class="add_img d-flex ac-jc"
                          onClick={() => imageSelector(item, ind, "mock")}
                        >
                          {" "}
                          <img src={item} class="cp add_img object-fit-fill" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {smallMockimage?.length > 0 && (
                <label
                  className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                    smallMockimage?.length == 0 && "mt-3"
                  }`}
                  onClick={() => dualToggele("mock")}
                >
                  +
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightComp;
