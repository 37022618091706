import { createSlice } from "@reduxjs/toolkit";

const colorSize = createSlice({
  name: "colorSize",
  initialState: {},
  reducers: {
    saveColorSize: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveColorSize } = colorSize.actions;
export default colorSize.reducer;
