import React, { useState } from "react";
import { product, profilePic } from "../../assets/img";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const OverviewTab = () => {
  const navigate = useNavigate();
  const [smallimage, setSmallImage] = useState("");

  return (
    <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
      <div className="d-flex flex-wrap as-jb flex-m-r w-100">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Category
          </p>
          <input
            disabled={true}
            value={"Game Consoles"}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Sub Category 1
          </p>
          <input
            value={"Game Consoles"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Category Code
          </p>
          <input
            value={"Game0005"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Sub Category 2
          </p>
          <input
            value={"Game Consoles"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Name
          </p>
          <input
            value={"Game Consoles"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            SKU Code
          </p>
          <input
            value={"SKU0001"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Product Code
          </p>
          <input
            value={"SM10001"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Decoration Method
          </p>
          <textarea
            value={
              "Game consoles,Game consoles,Game consoles,Game consoles,Game consoles,Game consoles"
            }
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Country of Origin
          </p>
          <input
            value={"India"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Packaging Size
          </p>
          <input
            value={"sample Content"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            HSN Code
          </p>
          <input
            value={"HSN0001"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Product Description
          </p>
          <textarea
            value={
              "If you arent satisfied with the build tool and configuration choices, you can eject at any time.This command will remove the single build dependency from your project."
            }
            disabled
            placeholder="Autogenerate"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Size
          </p>
          <input
            value={"XL"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Weight
          </p>
          <input
            value={"10kg"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Dimension
          </p>
          <input
            value={"Dummy Cont"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Material
          </p>
          <input
            value={"Classic, Standard"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Style
          </p>
          <input
            value={"Gold"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Color
          </p>
          <input
            value={"Red, Blue"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-100 overflow-scroll mt-4">
          <table className="w-100">
            <tr className="mt-4">
              <div className="">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 cp fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "50px" }}
                      >
                        {true ? (
                          <CheckBoxOutlined onClick={null} />
                        ) : (
                          <CheckBoxOutlineBlank onClick={null} />
                        )}
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                        style={{ width: "200px" }}
                      >
                        Variant
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Available
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        SKU
                      </p>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr className="mt-2">
              <div className="my-3">
                <td>
                  <div className="d-flex w-100 my-3">
                    <div
                      className="d-flex ac-jc mx-2"
                      style={{ width: "50px" }}
                    >
                      <button className="mx-2 d-flex ac-jc cust-btn">
                        {true ? (
                          <CheckBoxOutlined
                            className="primary cp"
                            onClick={null}
                          />
                        ) : (
                          <CheckBoxOutlineBlank
                            className="primary cp"
                            onClick={null}
                          />
                        )}
                      </button>
                    </div>
                    <div className="mx-2">
                      <div
                        class="num-cls-btn d-flex overflow-scroll p-2"
                        style={{ width: "200px" }}
                      >
                        <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                          XL/Red/Classic/Gold
                        </p>
                      </div>
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        placeholder="100"
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        placeholder="150"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                  <div className="d-flex w-100 my-3">
                    <div
                      className="d-flex ac-jc mx-2"
                      style={{ width: "50px" }}
                    >
                      <button className="mx-2 d-flex ac-jc cust-btn">
                        {true ? (
                          <CheckBoxOutlined
                            className="primary cp"
                            onClick={null}
                          />
                        ) : (
                          <CheckBoxOutlineBlank
                            className="primary cp"
                            onClick={null}
                          />
                        )}
                      </button>
                    </div>
                    <div className="mx-2">
                      <div
                        class="num-cls-btn d-flex overflow-scroll p-2"
                        style={{ width: "200px" }}
                      >
                        <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                          XL/Blue/Classic/Gold
                        </p>
                      </div>
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        placeholder="100"
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        placeholder="150"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                  <div className="d-flex w-100 my-3">
                    <div
                      className="d-flex ac-jc mx-2"
                      style={{ width: "50px" }}
                    >
                      <button className="mx-2 d-flex ac-jc cust-btn">
                        {true ? (
                          <CheckBoxOutlined
                            className="primary cp"
                            onClick={null}
                          />
                        ) : (
                          <CheckBoxOutlineBlank
                            className="primary cp"
                            onClick={null}
                          />
                        )}
                      </button>
                    </div>
                    <div className="mx-2">
                      <div
                        class="num-cls-btn d-flex overflow-scroll p-2"
                        style={{ width: "200px" }}
                      >
                        <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                          XL/Blue/Standard/Gold
                        </p>
                      </div>
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        placeholder="100"
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        placeholder="150"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
        </div>
        <div className="btn-w-cust-cont mt-4">
          <button
            class="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded w-100"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
      <div className="d-flex gap-2 flex-column ac-jc w-100 mb-5">
        <div className="w-100 d-flex flex-column ac-jc pb-4">
          <div className="d-flex ac-js mb-3 gap-3">
            <h4 className="primary">Product Image</h4>
          </div>
          <div className="">
            <div>
              <img
                alt="product"
                src={smallimage.length === 0 ? profilePic : smallimage}
                className="img_up_cont"
              />
            </div>
            <div className="w-100 mt-3 gap-3 d-flex flex-wrap ac-jc pb-4">
              <div className="add_img d-flex ac-jc">
                <img
                  alt="product"
                  src={profilePic}
                  onClick={() => setSmallImage(profilePic)}
                  className="cp add_img object-fit-fill"
                />
              </div>
              <div className="add_img d-flex ac-jc">
                <img
                  alt="product"
                  src={product}
                  onClick={() => setSmallImage(product)}
                  className="cp add_img object-fit-fill"
                />
              </div>
              <div className="add_img d-flex ac-jc">
                <img
                  alt="product"
                  src={profilePic}
                  onClick={() => setSmallImage(profilePic)}
                  className="cp add_img object-fit-fill"
                />
              </div>
              <div className="add_img d-flex ac-jc">
                <img
                  alt="product"
                  src={product}
                  onClick={() => setSmallImage(product)}
                  className="cp add_img object-fit-fill"
                />
              </div>
            </div>
          </div>
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Color Image
          </p>
          <div
            className="d-flex gap-3 mt-3 ac-jc flex-wrap"
            style={{ width: "350px" }}
          >
            <div className="d-flex flex-column ac-jc">
              <label className="add_img d-flex ac-jc cp f3 primary fs-3">
                <img
                  alt="product"
                  src={product}
                  className="add_img object-fit-contain"
                />
              </label>
              <p className="f3" style={{ color: "red" }}>
                Red
              </p>
            </div>
            <div className="d-flex flex-column ac-jc">
              <label className="add_img d-flex ac-jc cp f3 primary fs-3">
                <img
                  alt="product"
                  src={product}
                  className="add_img object-fit-contain"
                />
              </label>
              <p className="f3" style={{ color: "blue" }}>
                Blue
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
