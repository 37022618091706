import React from 'react'
import { tshirt1 , art1} from '../assets/img';

const SalesArtEnlarge1 = () => {
  return (
    
    <div className='full-img'>
      <img src={art1} style={{width:"100%",height:"100%",objectFit:"contain",objectPosition:"center",}}/>
    </div>
  )
}

export default SalesArtEnlarge1;