import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { useLocation, useNavigate } from "react-router-dom";
import PriceBookListComp from "../components/PriceBookLibraryComp/PriceBookListComp";
import { searchIcon } from "../assets/img";

const PriceBookLibraryScreen = () => {
  const navigat = useNavigate();
  const location = useLocation();
  const navigater = () => {
    if (location?.state?.method === "product-library") {
      navigat("/pricebook-library", {
        state: {
          method: "add",
        },
      });
    } else {
      navigat("/add-price-book-library", {
        state: {
          method:
            location?.state?.method === "product-library"
              ? null
              : "enterprises",
        },
      });
    }
  };
  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />

      <div className="d-flex ac-js flex-wrap rk2 mb-3">
        <p className="f4  fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          Price Book
          {location?.state?.data?.pricebook_name ? "-" : "Library"}
        </p>
        {location?.state?.data?.pricebook_name ? (
          <div className="">
            <p
              className="mx-1 rounded-3 p-2 w-100 f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
              style={{ border: "#07679c 0.5px solid", color: "#839dd1" }}
            >
              {location?.state?.data?.pricebook_name}
            </p>
          </div>
        ) : (
          "Library"
        )}
        {/* <button
          onClick={() => navigat("/add-price-book-library")}
          className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          + Add Pricebook
        </button> */}
      </div>

      {/* <div className="d-flex as-jb flex-column flex-md-row">
        <div className="d-flex as-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 mb-md-0  mb-4">
          <input
            type="text"
            className="get-start-btn2 respon-width bg-lt-blue primary1 border-0 my-0 py-4 px-3"
            placeholder="Product Name"
          />
          <button class="get-start-btn2 respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option value="7">Vendors </option>
              <option value="8">Mercedes</option>
              <option value="9">Mini</option>
              <option value="10">Nissan</option>
              <option value="11">Toyota</option>
              <option value="12">Volvo</option>
            </select>{" "}
          </button>
          <input
            type="text"
            className="get-start-btn2 respon-width bg-lt-blue primary1 border-0 my-0 py-4 px-3"
            placeholder="Lead time"
          />
          <button class="get-start-btn2 respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option value="0">Tagged</option>
              <option value="1">Audi</option>
              <option value="2">BMW</option>
              <option value="3">Citroen</option>
              <option value="4">Ford</option>
              <option value="5">Honda</option>
              <option value="6">Jaguar</option>
              <option value="7">Land Rover</option>
              <option value="8">Mercedes</option>
              <option value="9">Mini</option>
              <option value="10">Nissan</option>
              <option value="11">Toyota</option>
              <option value="12">Volvo</option>
            </select>
          </button>
          <input
            type="text"
            className="get-start-btn2 respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
            placeholder="Net min"
          />
          <input
            type="text"
            className="get-start-btn2 respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
            placeholder="Net max"
          />
          <button class="get-start-btn2 respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option value="1">Ratings </option>
              <option value="2">1</option>
              <option value="3">2</option>
              <option value="4">3</option>
              <option value="5">4</option>
              <option value="6">5</option>
            </select>
          </button>
          <button class="get-start-btn2 respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option value="1">Type </option>
              <option value="Ecommerce">Ecommerce</option>
              <option value="Reseller">Reseller</option>
              <option value="Both">Both</option>
            </select>
          </button>
        </div>
        <div className="d-flex ac-jc gap-3">
          <button
            // onClick={() => navigate("/add-vendor")}
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
          >
            <Search />
          </button>
          <button
            // onClick={() => navigate("/add-vendor")}
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
          >
            <CachedIcon />
          </button>
        </div>
      </div> */}
      {/* <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
      </div> */}
      <div className="d-flex flex-md-row flex-column justify-content-between">
        <div className="w-50 ac-jb d-flex flex-md-row flex-column mt-2">
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">10</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex mt-sm-3">
            <img src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          {!location?.state?.method === "product-library" && (
            <button
              onClick={() => navigater()}
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              + Add Pricebook
            </button>
          )}
        </div>
      </div>
      <PriceBookListComp view={"view"} />
    </div>
  );
};
export default PriceBookLibraryScreen;
