import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import AddClientContactPopup from "./AddClientContactPopup";
import PresentaionList from "./PresentaionList";
import NavbarHead from "../Sub_Layout/NavbarHead";
import PresentaionListTable from "./PresentaionListTable";
import { useNavigate } from "react-router-dom";
import { BackupOutlined, Close } from "@mui/icons-material";
import ImageCroper from "../Popup/ImageCroper";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PorejectPoppup from "../PODetails/PorejectPoppup";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
const UploadArtworkUploadScreen = () => {
  const navigete = useNavigate();
  const [actionBtn, setActionBtn] = useState(false);
  const [salsestatust, setSalseStatust] = useState("Inprogress");
  const [designStatust, setdesignStatust] = useState("Inprogress");
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [smallimage, setSmallImage] = useState([]);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });
  const [rejectpoppup, setRejectPoppup] = useState(false);

  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };

  const status = [
    {
      list: "Inprogress",
    },
    { list: "Approved" },
    {
      list: "Rejected",
    },
  ];
  const design_status = [
    {
      list: "Inprogress",
    },
    { list: "Completed" },
  ];
  const statushandl = (e) => {
    setSalseStatust(e.target.value);
    if (e.target.value == "Rejected") {
      setRejectPoppup(true);
    } else {
      setRejectPoppup(false);
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };
  const imgUpload = () => {
    setCroper(true);
  };
  const imageSelector = (item, ind) => {
    setBigImage({ image: item, ind: ind });
  };

  const [file, setFile] = useState([]);

  const imageUplode = (e) => {
    console.log("img", e);
    setFile([...file, e]);
    console.log("file", file);
  };

  console.log("filesss", file);
  useEffect(() => {
    if (croperImage) {
      imageUplode(croperImage);
    }
  }, [croperImage]);
  // useEffect(() => {
  //   if (croperCol && croperImage) {
  //     // setTextShow(false);
  //     // setFirstShow(true);

  //     let temp = [...file];

  //     if (croperImage) {
  //       temp.push(croperImage);
  //     }

  //     setSmallImage(temp);

  //     setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
  //     setCropImage("");
  //   } else if (!croperCol && croperImage) {
  //     imageUplode(croperImage);
  //   }
  // }, [croperImage]);
  console.log("croperImage", croperImage, smallimage);

  const removeImg = (ind) => {
    let temp = [...file];
    temp.splice(ind, 1);
    setFile(temp);
  };

  const rejPopFun = () => {
    setRejectPoppup(!rejectpoppup);
  };
  return (
    <>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      {rejectpoppup && (
        <PorejectPoppup
          rejPopFun={rejPopFun}
          setRejectPoppup={setRejectPoppup}
        />
      )}

      <div className="dashRightView2 presentaion_pages  overflow-scroll ">
        <div className=" d-flex justify-content-end position-relative mb-2">
          <div className="action_overview mx-4">
            <NavbarHead onLinkShow={onLinkShow} />
            {actionBtn && (
              <div
                className="invisible-cont2"
                style={{ zIndex: 200 }}
                onClick={() => setActionBtn(false)}
              />
            )}
          </div>
        </div>
        <button
          className="mb-4 border-0 bg-transparent"
          onClick={() => navigete(-1)}>
          <KeyboardBackspaceOutlinedIcon className="f4 text fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary" />
        </button>
        <div className="d-flex ac-js">
          <Col sm={4} md={4} lg={2} className="">
            <div className="d-flex flex-column flex-md-row flex-wrap m-2">
              <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Sales Status
              </p>
              <select
                placeholder="Status"
                onChange={statushandl}
                value={salsestatust}
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                {status?.map((item, ind) => {
                  return (
                    <option key={item?.list} value={item?.list}>
                      {item?.list}
                    </option>
                  );
                })}
              </select>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} className="position-relative rel_sty">
            <div className="d-flex flex-column flex-md-row flex-wrap m-2">
              <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Design Status
              </p>

              {/* {(salsestatust == "Rejected" ||
                salsestatust != "Inprogress" ||
                salsestatust != "Approved") && (
                <button class="editBtn bg-danger text-light w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  {salsestatust}
                  <ErrorOutlineOutlinedIcon className="ms-3" />
                </button>
              )} */}

              {salsestatust == "Rejected" && (
                <button class="editBtn bg-danger text-light w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  {salsestatust}
                  <ErrorOutlineOutlinedIcon className="ms-3" />
                </button>
              )}
              {salsestatust == "Rejected" && (
                <div className="hov_popp2 viewBoxDes rounded-2">
                  <p class="f1 fs-xs-11 fs-sm-12 border-3 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-10 text-dark text-center py-2 px-3">
                    Rejected reason will be shown here...
                  </p>
                </div>
              )}

              {salsestatust == "Approved" && (
                <button
                  className={
                    salsestatust == "Approved"
                      ? "editBtn bg-success text-light w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      : "editBtn bg-transparent text-dark w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  }>
                  {salsestatust}
                  {salsestatust == "Approved" && (
                    <CheckCircleOutlineOutlinedIcon className="ms-3 text-light" />
                  )}
                </button>
              )}

              {salsestatust == "Inprogress" && (
                <select
                  placeholder="Status"
                  // onChange={(e) => setDesignStatust(e.target.value)}
                  // value={designstatust}
                  onChange={(e) => setdesignStatust(e.target.value)}
                  value={designStatust}
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  {design_status?.map((item, ind) => {
                    return (
                      <option key={item?.list} value={item?.list}>
                        {item?.list}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          </Col>
        </div>
        <div className="viewBoxDes w-90 rounded-2 px-3 py-2">
          <p class="f1 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-18 fs-xl-19 fs-xxl-20">
            Upload Image
          </p>
          <div className="d-flex flex-wrap">
            {file?.map((item, ind) => {
              return (
                <div className="imageup_cont2 mt-3 mx-2 rounded-3 position-relative d-flex ac-jc">
                  <button
                    className="close_up_btn d-flex ac-jc"
                    onClick={() => removeImg(ind)}>
                    <Close className="text-light" />
                  </button>
                  <div
                    class="up_img rounded-3"
                    onClick={() => imageSelector(item, ind)}>
                    <img src={item} class="cp up_img rounded-3" />
                  </div>
                </div>
              );
            })}
            <label className="imageup_cont mt-3 mx-2 d-flex ac-jc rounded-3">
              <button
                className="bg-transparent border-0 d-flex flex-column ac-jc"
                onClick={() => imgUpload()}>
                <div className="upicon d-flex ac-jc">
                  <BackupOutlined className=" f4 fs-sm-19 fs-md-20 fs-lg-20 fs-xl-22 fs-xxl-23 primary1" />
                </div>
                Click here to Upload
              </button>
            </label>
          </div>
          <div className="w-90 d-flex ae-jb mt-4 flex-column ">
            <div className="w-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                Description
              </p>
              <textarea
                // placeholder="Type..."
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            </div>
            <div className="d-flex ac-jb w-100">
              <button
                onClick={() => navigete(-1)}
                className="position-relative  cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                Back
              </button>
              <button
                onClick={() => navigete(-1)}
                className="position-relative  cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadArtworkUploadScreen;
