import {
  Edit,
  ViewAgenda,
  ViewArraySharp,
  ViewList,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { product, viewIcon } from "../../assets/img";
import { collaborateList } from "../../redux/api/DummyJson";
import AddFilesPopup from "../Popup/AddFilesOverview";
import AddArtWorker from "../Projects/AddArtWorker";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { width } from "@mui/system";
import { useRef } from "react";
import SalesOrderFileView from "./Poppup/SalesOrderFileView";

const SalesOrderTrancDetails = ({ deleteToggle, setAddTitle }) => {
  const navigate = useNavigate();
  const [addClient, setAddClient] = useState(false);
  const [addArt, setAddArt] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);
  const fileRef = useRef();

  // Image
  const [file, setFile] = useState("");
  const [viewfile, setViewFile] = useState(false);

  const fileOnChange = (e) => {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    console.log(file, "file");
  };

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };
  const artWork = () => {
    toggleShowPopup();
  };
  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };

  const fileViewToggle = () => {
    setViewFile(!viewfile);
  };
  return (
    <div className="presentaion_pages overflow-scroll mt-4">
      {addClient && (
        <AddArtWorker
          toggleShowPopup={toggleShowPopup}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
        />
      )}
      {AddFilesOverview && (
        <AddFilesPopup
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
        />
      )}
      {viewfile && (
        <SalesOrderFileView file={file} fileViewToggle={fileViewToggle} />
      )}
      <div className="w-100 overflow-scroll table_border menu-table">
        <table>
          <thead className="">
            <tr className="bg-soft-gray1">
              <th className="px-5 text-nowrap py-4">S.NO</th>
              <th className="px-5 text-nowrap py-4">Payment Type</th>
              <th className="px-5 text-nowrap py-4">Transaction ID</th>
              <th className="px-5 text-nowrap py-4">Transaction Date</th>
              <th className="px-5 text-nowrap py-4">File</th>
              <th className="px-5 text-nowrap py-4">Amount</th>
            </tr>
          </thead>
          <tr>
            <td className="py-4">1</td>
            <td className="py-4">Payment Quantity</td>
            <td className="py-4">CNR001</td>
            <td className="py-4">13/11/2023</td>
            <td className="py-4 hover_eff2 flex-column d-flex ac-jc gap-2">
              <input
                onChange={fileOnChange}
                className=" sm-btn w-60"
                type="file"
                accept=".pdf"
                // ref={fileRef}
              />
              {file?.length !== 0 && (
                <div>
                  <button className="sm-btn invisible" onClick={fileViewToggle}>
                    View File
                  </button>
                </div>
              )}
            </td>
            <td className="py-4">20000</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default SalesOrderTrancDetails;
