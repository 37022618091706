import React from "react";
import FormComp from "../AddNewProductComps/FormComp";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import RightComp from "./RequestRightComp";
import ImageRightComp from "./RequestImageRightComp";
import RequestLeftComp from "./RequestLeftComp";
import RequestRightComp from "./RequestRightComp";
import { useNavigate } from "react-router-dom";
import PricingBoxComp from "../MyProductListComp/PricingBoxComp";

const RequestOverViewComp = ({ edit, types, status }) => {
  const navigat = useNavigate();
  return (
    <div className="mb-4">
      <RequestLeftComp edit={edit} types={types} status={status} />
      {/* <ImageRightComp /> */}
      <PricingBoxComp />
      <div className="d-flex ac-jb w-10">
        <button
          className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigat(-1)}
        >
          Back
        </button>
        {status == "request" && (
          <button
            className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
            onClick={() => navigat(-1)}
          >
            Submit
          </button>
        )}
      </div>
      {/* <div className="w-100 mt-5">
        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
          Last updated on August 28, 2023 at 03:10 AM{" "}
        </p>
      </div> */}
    </div>
  );
};

export default RequestOverViewComp;
