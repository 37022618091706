import { CloseOutlined } from "@mui/icons-material";

const RejectPopup = ({ onShowPopupHander }) => {
  return (
    <div className="modal-popup5">
      <div onClick={onShowPopupHander} className="back-close" />
      <div className="center-content5 px-3 mb-4 p-4">
        <div
          className="w-100 d-flex ac-jb cp p-4"
          onClick={onShowPopupHander}
          // style={{ marginTop: "-55px" }}
        >
          <p className="f4 fs-xs-10 fs-sm-14 fs-md-16 fs-lg-17 fs-xl-18 fs-xxl-20 primary">
            Reason For Rejection
          </p>
          <CloseOutlined className="primary f5 fs-2" />
        </div>
        <div className="d-flex ac-jc w-100 mb-4 mt-3">
          <textarea
            placeholder="Text Here..."
            className="editBtn w-95 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
          />
        </div>
        <div className="d-flex ac-jc">
          <button
            class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
            onClick={() => onShowPopupHander()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectPopup;
