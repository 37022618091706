import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const AddTaskPopup = ({ toggleShowPopup }) => {
  return (
    <div className="add-category-popup">
      <div>
        <p className="w-100 d-flex ac-jb f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary ">
          Add Task
          <span>
            <button className="mx-3 cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Save
            </button>
            <button onClick={toggleShowPopup} className="cust-btn">
              <HighlightOffIcon />
            </button>
          </span>
        </p>

        <div className="w-90 margin-auto mt-3">
          <div className="d-flex ac-jc my-2">
            <p className="w-40 f3">Task Date</p>
            <input
              type="date"
              className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="d-flex ac-jc my-2">
            <p className="w-40 f3">Task</p>
            <textarea
              type="Task"
              className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="d-flex ac-jc my-2">
            <p className="w-40 f3">User</p>
            <select
              className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder=" ">
              <option> Roger Daniel </option>
            </select>
          </div>
          <div className="d-flex ac-jc my-2 ">
            <p className=" f3">Completed</p>
            <input type="checkbox" className="mx-3" />
            <div className="w-100"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTaskPopup;
