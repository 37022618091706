import { useLocation, useNavigate } from "react-router-dom";
import { product } from "../../assets/img";
import { product_library } from "../../redux/api/DummyJson";

const PriceBookListComp = ({ type, view }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div>
      <div className="w-100 d-flex ac-js mt-5 flex-wrap">
        {product_library?.map((item, ind) => {
          return (
            <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl3">
              <div
                className="prod-list-box"
                onClick={() =>
                  navigate("/add-price-book-library", {
                    state: {
                      type: view == "view" ? "view" : "edit",
                      data: item,
                      method: type,
                    },
                  })
                }
              >
                <div className="cust-btn d-flex ac-jc rounded-3">
                  <img src={product} alt="" className="custom-w-h rounded-3" />
                </div>
                <div className="d-flex ac-jb w-100 my-1">
                  <div>
                    <p className="f5 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 primary mt-3">
                      PRICE
                    </p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2  mb-0">
                      Min qty(25)
                    </p>
                  </div>
                  <p className="f5 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                    {item?.price}
                  </p>
                </div>
                <div className="d-flex ac-jb w-100 my-1">
                  <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 primary parag">
                    {/* PRICEBOOK CODE : */}
                    NEW SKU CODE :
                  </p>
                  <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                    {item?.pricebook_code}
                  </p>
                </div>
                <div className="d-flex ac-jb w-100 my-1">
                  <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 primary parag">
                    PRODUCT SKU CODE:
                  </p>
                  <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                    {item?.product_code}
                  </p>
                </div>
                <div className="d-flex ac-jb w-100 my-1">
                  {/* <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 primary parag">
                    PRODUCT NAME:
                  </p>
                  <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                    {item?.product_name}
                  </p> */}
                  <p className="f4 fs-12 fs-sm-13 text-custom-res fs-lg-14 ms-2 primary1 parag w-100">
                    Polo White T-shirt size S,M,L,XL
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={
          type == "enterprises"
            ? "d-flex justify-content-end gap-2 mt-3"
            : "d-flex justify-content-between mt-3"
        }
      >
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};
export default PriceBookListComp;
