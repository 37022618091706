import { useEffect, useState } from "react";
import { animatedImg } from "../assets/img";
import { Search } from "@mui/icons-material";
import ProductVendorSettings from "../components/VendorSettings/ProductVendorSettings";
import { useNavigate } from "react-router-dom";

const VendorSettings = () => {
  const [prodVendor, setProdVendor] = useState(false);
  const [serVendor, setSerVendor] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setProdVendor(true);
  }, []);

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} alt="img" className="searchManImg" />
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Vendor Settings
        </p>
      </div>
      <div className="d-flex mt-4 ac-jb flex-wrap">
        {/* <div className="d-flex mt-3">
          <div
            onClick={() => {
              setProdVendor(true);
              setSerVendor(false);
            }}
            className={`${
              prodVendor
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } `}
          >
            <p
              className={`${
                prodVendor ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Product Supplier
            </p>
          </div>
          <div
            onClick={() => {
              setSerVendor(true);
              setProdVendor(false);
            }}
            className={`${
              serVendor
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                serVendor ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Service Vendor
            </p>
          </div>
        </div> */}
      </div>
      {/* {prodVendor && <ProductVendorSettings />} */}
      {/* {serVendor && <ProductVendorSettings />} */}
      <ProductVendorSettings />
    </div>
  );
};

export default VendorSettings;
