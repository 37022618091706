import React, { useState } from "react";
import Detailing from "./Tab/Detailing";
import OverView from "./Tab/OverView";
import Proofing from "./Tab/Proofing";
import Shipping from "./Tab/Shipping";
import { useDispatch, useSelector } from "react-redux";
import { savepopupProduction } from "../../../redux/slice/popupget";

const ProofPopup = () => {
  const [overViewTab, setOverViewTab] = useState(true);
  const [proofingTab, setProofingTab] = useState(false);
  const [shippingTab, setShippingTab] = useState(false);
  const [detailTab, setDetailTab] = useState(false);

  const proofPopup = useSelector((state) => state.saveshowproductionProof);
  console.log(proofPopup);

  const dispatch = useDispatch();

  const onClickPopupShowHandler = () => {
    dispatch(savepopupProduction("close report"));
  };

  return (
    <>
      {proofPopup == "export report" && <div className="back-popup"></div>}
      <div
        className="primt-popup"
        style={{ right: proofPopup == "export report" ? "0px" : "-600px" }}
      >
        <div className="d-flex ac-jb  w-100">
          <h4>PO Tasks & Notes</h4>
          <div className="d-flex ac">
            <button className="bg-primar addbtn cust-btn px-3 text-white rounded f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mx-2">
              Actions
            </button>
            <button
              onClick={() => onClickPopupShowHandler()}
              className="bg-primary1  cust-btn px-2 text-white rounded f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mx-2"
            >
              Close
            </button>
          </div>
        </div>
        <p className="primary f3 fs-14 fs-md-15 fs-xl-17 mt-4">
          Sakthi BestLuck client - 5SK designer Sakthi - PO #330045
        </p>
        <div className="prodution-tab mt-2">
          <div className="d-flex ac jc">
            <button
              onClick={() => {
                setOverViewTab(true);
                setProofingTab(false);
                setShippingTab(false);
                setDetailTab(false);
              }}
              className="d-flex ac jc cust-btn px-2 py-1"
            >
              <h4
                className={`f3 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                  overViewTab ? "primary" : "gray"
                }`}
              >
                OverView
              </h4>
            </button>
            <button
              onClick={() => {
                setOverViewTab(false);
                setProofingTab(true);
                setShippingTab(false);
                setDetailTab(false);
              }}
              className="d-flex ac jc cust-btn px-2 py-1"
            >
              <h4
                className={`f3 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                  proofingTab ? "primary" : "gray"
                }`}
              >
                Proofing
              </h4>
            </button>
            <button
              onClick={() => {
                setOverViewTab(false);
                setProofingTab(false);
                setShippingTab(true);
                setDetailTab(false);
              }}
              className="d-flex ac jc cust-btn px-2 py-1"
            >
              <h4
                className={`f3 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                  shippingTab ? "primary" : "gray"
                }`}
              >
                Shipping
              </h4>
            </button>
            <button
              onClick={() => {
                setOverViewTab(false);
                setProofingTab(false);
                setShippingTab(false);
                setDetailTab(true);
              }}
              className="d-flex ac jc cust-btn px-2 py-1"
            >
              <h4
                className={`f3 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                  detailTab ? "primary" : "gray"
                }`}
              >
                Details
              </h4>
            </button>
          </div>
        </div>
        {overViewTab && <OverView />}
        {proofingTab && <Proofing />}
        {shippingTab && <Shipping />}
        {detailTab && <Detailing />}
      </div>
    </>
  );
};
export default ProofPopup;
