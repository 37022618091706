// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../../../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const OurDealsView = () => {
  const location = useLocation();
  const type = location?.state?.type;
  const data = location?.state?.data;
  const [action, setAction] = useState(false);
  const [input, setInput] = useState([]);
  const [image, setImage] = useState("");
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let temp = {};

    temp.name = data?.customerName;
    temp.designation = data?.slNo;
    temp.priority = data?.ClientRepName;
    temp.status = data?.status;

    setInput(temp);
    setImage(data?.img);
  }, [data]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInput((val) => ({ ...val, [name]: value }));
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-21 primary p-0 d-flex align-items-center gap-1 mb-4"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Testimonial{" "}
        {type == "edit"
          ? "Edit"
          : type == "view"
          ? "View"
          : type == "add"
          ? "Add"
          : ""}
      </p>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Name:
              </p>
              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="text"
                name="name"
                value={input?.name}
                disabled={type == "view" ? true : false}
                onChange={handleChange}
              />
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Designation:
              </p>
              <select
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name="designation"
                id=""
                value={input?.designation}
                disabled={type == "view" ? true : false}
                onChange={handleChange}
              >
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Priority:
              </p>

              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="text"
                name="priority"
                value={input?.priority}
                disabled={type == "view" ? true : false}
                onChange={handleChange}
              />
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status
              </p>

              <select
                placeholder=""
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name="status"
                id=""
                value={input?.status}
                disabled={type == "view" ? true : false}
                onChange={handleChange}
              >
                <option value={"true"}>Active</option>
                <option value={"false"}>De Active</option>
              </select>
            </div>

            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Team image:
              </p>
              <div className="w-70 ms-3" onClick={() => toggleImagePopup()}>
                <img
                  src={croperImage ? croperImage : data?.img ? data?.img : ""}
                  className="imgDashboard"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex ac-jb">
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default OurDealsView;
