import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sub_menu, event_category } from "../../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const FormComp = ({ setColorImg, colorImg, setSelprod, ind }) => {
  const navigate = useNavigate();

  // Toggle List
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(false);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);
  const [eventCatogoryShow, setEventCategoryShow] = useState(false);

  // input box
  const [catogory, setCatogory] = useState("");
  const [catogory2, setCatogory2] = useState("");
  const [catogory3, setCatogory3] = useState("");
  const [productName, setProductName] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [packingSize, setPackingSize] = useState("");
  const [country, setCountry] = useState("");
  const [hsnType, sethsnType] = useState("");
  const [colorsName, setColorsName] = useState("");
  const [sizeOfDim, setSizeOfDim] = useState("");
  const [weight, setWeight] = useState("");
  const [designFeatures, setDesignFeatures] = useState("");
  const [meterialUse, setMeterialUse] = useState("");
  const [productDisc, setProductDisc] = useState("");
  const [decorativeMethod, setDecorativeMethod] = useState("");
  const [meterial, setMeterial] = useState("");
  const [style, setStyle] = useState("");
  const [sku, setSku] = useState("");
  const [vendorProductCode, setVendorProductCode] = useState("");
  const [count, setCount] = useState(1);

  const incrementFun = () => {
    setCount(count + 1);
  };
  const decrementFun = () => {
    setCount(count - 1);
    if (count > 1) {
      setCount(1);
    }
  };
  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 2) {
      setCatogoryShow(true);
      setProductNameShow(false);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };

  const onCatogory2 = (e) => {
    setCatogory2(e.target.value);
    if (catogory2.length >= 2) {
      setCatogoryShow2(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onCatogory3 = (e) => {
    setCatogory3(e.target.value);
    if (catogory3.length >= 2) {
      setCatogoryShow3(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onProduct = (e) => {
    // setProductName(e.target.value)
    if (productName.length >= 2) {
      setProductNameShow(true);
      // setProductSuplierCode('code1003');
    } else {
      if (productName.length >= 0) setProductNameShow(false);
      setProductSuplierCode("");
    }
    setCatogoryShow(false);
  };

  const onBackPress = () => {
    setSelprod(ind);
  };

  const handleOnchange = (e) => {
    const { value } = e && e.target;
    setEventCategory(value);
    if (value?.length >= 2) {
      setEventCategoryShow(true);
    } else {
      setEventCategoryShow(false);
    }
  };

  return (
    <div className="w-100">
      <div className="d-flex flex-wrap ac-jb flex-m-r">
        {ind === 0 ? (
          <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            <div className="position-relative z-3">
              <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Event Category
              </p>
              <input
                type="text"
                placeholder="Required Field"
                value={eventCategory || ""}
                onChange={handleOnchange}
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down position-absolute custom-btn cust-btn"
                onClick={() => {
                  setCatogoryShow(false);
                  setEventCategoryShow(!eventCatogoryShow);
                  setChildMenu(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {eventCatogoryShow && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setEventCategoryShow(!eventCatogoryShow)}
              />
            )}
            <div
              className={`${
                eventCatogoryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
              style={{ height: "auto" }}
            >
              {event_category?.map((item, key) => {
                return (
                  <div className="d-flex hover-cust" key={key}>
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={(e) => {
                        setCatogoryShow(false);
                        setEventCategoryShow(false);
                        setChildMenu(false);
                        setSubcatogory(false);
                        setEventCategory(e.target.value);
                      }}
                    >
                      {item?.event_name}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
          <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Category
          </p>
          <div className="position-relative z-3">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={catogory}
              onChange={onCatogory}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setCatogoryShow(!catogoryShow);
                setChildMenu(false);
                setCatogoryShow2(false);
                setCatogoryShow3(false);
                setProductNameShow(false);
                setPackingSizeShow(false);
                setCountryShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
          </div>
          {catogoryShow && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setCatogoryShow(!catogoryShow)}
            />
          )}
          <div
            className={`${
              catogoryShow && "submenu_1"
            } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setCatogoryShow(false);
                      setCatogory(item?.list);
                      setChildMenu(false);
                      setSubcatogory(true);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
          <div className={childMenu ? "submenu_child_1" : "submenu_child_2"}>
            <div
              className={`${
                catogoryShow && "submenu_2"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    {item.list !== "Game consoles" && (
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setCatogoryShow(false);
                          setCatogory(item?.list);
                          setChildMenu(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    )}
                    {item.list === "Game consoles" && (
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => setChildMenu(!childMenu)}
                      >
                        {item?.list}
                      </button>
                    )}
                    {item.list === "Game consoles" && (
                      <button
                        className="cust-btn position-relative"
                        onClick={() => {
                          setChildMenu(!childMenu);
                          setProductNameShow(false);
                          setCountryShow(false);
                          setPackingSizeShow(false);
                        }}
                      >
                        {childMenu ? (
                          <KeyboardArrowRightIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {subcatogory && (
          <>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category 1
              </p>
              <div className="position-relative">
                <input
                  type="text"
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={catogory2}
                  onChange={onCatogory2}
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setCatogoryShow2(!catogoryShow2);
                    setCatogoryShow(false);
                    setCatogoryShow3(false);
                    setProductNameShow(false);
                    setPackingSizeShow(false);
                    setCountryShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              </div>
              {catogoryShow2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setCatogoryShow2(!catogoryShow2)}
                />
              )}
              <div
                className={`${
                  catogoryShow2 && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setCatogoryShow2(false);
                          setCatogory2(item?.list);
                          setSubcatogory2(true);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category Code
              </p>
              <input
                type="text"
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={sku}
                onChange={(e) => {
                  setSku(e.target.value);
                }}
                disabled
              />
            </div>
          </>
        )}
        {subcatogory2 && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Sub Category 2
            </p>
            <div className="position-relative">
              <input
                type="text"
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={catogory3}
                onChange={onCatogory3}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCatogoryShow3(!catogoryShow3);
                  setChildMenu(false);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {catogoryShow3 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setCatogoryShow3(!catogoryShow3)}
              />
            )}
            <div
              className={`${
                catogoryShow3 && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setCatogoryShow3(false);
                        setCatogory3(item?.list);
                        setChildMenu(false);
                        setSubcatogory3(true);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Name
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="p-cust  cp editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={productName}
              onChange={onProduct}
              onClick={() => {
                setProductNameShow(!productNameShow);
                setCatogoryShow(false);
                setCatogoryShow2(false);
                setCatogoryShow3(false);
                setPackingSizeShow(false);
                setCountryShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setProductNameShow(!productNameShow);
                // setProductSuplierCode('code1003');
                setCatogoryShow(false);
                setCatogoryShow2(false);
                setCatogoryShow3(false);
                setPackingSizeShow(false);
                setCountryShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
          </div>
          {productNameShow && (
            <div
              className="invisible-cont2"
              onClick={() => setProductNameShow(!productNameShow)}
            />
          )}
          <div
            className={`${
              productNameShow && "submenu_1"
            } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setProductNameShow(false);
                      setProductName(item?.list);
                      setProductSuplierCode("CODE1003");
                      setSizeOfDim("XL");
                      setWeight("100Kg");
                      setDesignFeatures("Dummy Content");
                      setMeterialUse("Dummy Content");
                      setCountry("India");
                      setPackingSize("150");
                      setColorsName("Red, blue, Purple");
                      sethsnType("200");
                      setDecorativeMethod(
                        "Game consoles, Game consoles, Game consoles, Game consoles, Game consoles, Game consoles"
                      );
                      setProductDisc(
                        "If you arent satisfied with the build tool and configuration choices, you can eject at any time.This command will remove the single build dependency from your project."
                      );
                      setMeterial("meterial cont");
                      setStyle("Gold");
                      setVendorProductCode("SM10001");
                      setSku("SKU0001");
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            SKU Code
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={sku}
            onChange={(e) => {
              setSku(e.target.value);
            }}
            disabled
          />
        </div>
        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Product Supplier Code
                    </p>
                    <input
                        type='text'
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value={productSuplierCode}
                        onChange={(e) => { setProductSuplierCode(e.target.value) }}
                        disabled
                    />
                </div> */}

        {/* COUNT */}
        {/* <div className="w-100 d-flex flex-column mt-4">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Count
          </p>
          <div className="w-xl-48 w-lg-48 w-md-50 w-sm-50 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            </p>
            <div class="counter d-flex ac-jc mt-4">
              <div className="in_btn d-flex ac-jc cp" onClick={incrementFun}>
                <button className="cust-btn text-light">+</button>
              </div>
              <p className="f3  primary1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 mx-auto">
                {count}
              </p>
              <div className="in_btn d-flex ac-jc cp" onClick={decrementFun}>
                <button className="cust-btn text-light">-</button>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className='d-flex ac-je w-100'>
                    <button className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                        onClick={() =>
                            navigat('/vendor-details')} >
                        Save
                    </button>
                </div> */}
      </div>
      {ind === 0 ? null : (
        <div className="mt-5">
          <p
            onClick={onBackPress}
            className="primary f2 pointer fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1"
          >
            <ArrowBackIcon />
            Prev
          </p>
        </div>
      )}
      <div className="d-flex ac-js mt-3">
        <button
          onClick={() => navigate(-1)}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        <button
          onClick={() => navigate("/add-pack-details")}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FormComp;
