import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import CallIcon from "@mui/icons-material/Call";
import EditIcon from "@mui/icons-material/Edit";
import { toggleOn } from "../../assets/img";

const AddContactsEnterprises = ({
  setContactPopup,
  setPositionPopup,
  type,
}) => {
  return (
    <div className="w-100 d-grid mt-4 ac-jc searchField bg-white">
      <div className="d-flex w-100 ac-jb">
        <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 black">
          Viswa Enterprises
        </p>
        {type !== "view" && (
          <div className=" d-flex ms-5 ac-jc">
            <img src={toggleOn} className="toggleOnDes1" />
            <CalendarMonthIcon className="pointerView ms-1 primary f2 fs-xs-19 fs-sm-20 fs-md-21 fs-lg-22 fs-xl-23 fs-xxl-24" />
            <EditIcon
              onClick={() => setContactPopup(true)}
              className="pointerView primary ms-1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
            />
            <DeleteIcon className="pointerView ms-1 primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
          </div>
        )}
      </div>
      <div className="mt-3">
        <p className="f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 primary">
          Production Management
        </p>
      </div>
      <div className="d-flex mt-3">
        <EmailOutlinedIcon className="pointerView primary f4 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
        <p className="f2 ms-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
          viswa@ecphasisinfotech.com
        </p>
      </div>
      <div className=" d-flex mt-1 ac-jb gap-2">
        <div className="d-flex">
          <CallIcon className="pointerView primary f4 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
          <p className="f2 ms-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
            viswa@ecphasisinfotech.com
          </p>
        </div>
        {type !== "view" && (
          <button
            onClick={() => setPositionPopup(true)}
            className="cust-btn addbtn mt-sm-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Position
          </button>
        )}
      </div>
    </div>
  );
};

export default AddContactsEnterprises;
