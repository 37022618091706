import React from "react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const Shipping = () => {
  const onClickHandlerNotify = () => {
    console.log("jkhjk");
  };
  return (
    <div className="shipping">
      <div className="d-flex ac-jc w-100 flex-wrap">
        <div className="w-50">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-2"
            >
              Date Shipped
            </label>
            <input
              placeholder="Date Shipped"
              type="date"
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 px-2 rounded"
            />
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Shipping Cost
            </label>
            <input
              placeholder="Shipping Cost"
              type="text"
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 px-2 rounded"
            />
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Overrun / Underrun
            </label>
            <input
              placeholder="Overrun / Underrun"
              type="text"
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 px-2 rounded"
            />
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Projected Ship Date
            </label>
            <input
              placeholder="Overrun / Underrun"
              type="date"
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 px-2 rounded"
            />
          </div>
        </div>
        <div className="w-100">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Tracking numbers (comma separated)
            </label>
            <input
              placeholder="Shipping Tracking"
              type="text"
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 px-2 rounded"
            />
          </div>
        </div>
      </div>
      {/* <button
        onClick={() => onClickHandlerNotify}
        className="f3 mt-3 ms-1 cust-btn fs-14 fs-md-15 
            fs-lg-16 bg-primar addbtn rounded text-white"
      >
        <LocalShippingIcon className="me-2" />
        Notify Shipping Changes
      </button> */}
    </div>
  );
};
export default Shipping;
