import CloseIcon from "@mui/icons-material/Close";

const PositionPopup = ({ showPopupHander, type }) => {
  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      <div className="center-content-contact3 w-40 p-4">
        <div className="w-100 d-flex ac-jb">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Positions
          </p>
          <CloseIcon
            onClick={() => showPopupHander()}
            className="pointerView white p-1 rounded-5 f2 bg-primar fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-25"
          />
        </div>
        <div className="mt-4 w-xl-100 w-lg-100 w-md-100 w-sm-100 w-xs-100">
          <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Change Position of{" "}
            <span className="gray f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              {type == "enterprises"
                ? "Viswa Enterprises"
                : "Sakthi BestLuck Prod Supplier"}
            </span>
          </p>
          <select
            placeholder=""
            className="w-100 editBtnSelect mt-4 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
            name=""
            id=""
          >
            <option>Primary</option>
            <option>Secondary</option>
          </select>
        </div>
        <div className="d-flex mt-4 ac-jc">
          <button className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default PositionPopup;
