import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownTap from "./ProductDropdownTap";
import ProductSearchHeader from "./ProductSearchHeader";
import ProductListComp from "./ProductListComp";
import ProductDropdownTap from "./ProductDropdownTap";

const MapProductComp = ({ place, products }) => {
  const [priceBook, setpriceBook] = useState(false);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [tagged, setTagged] = useState(false);
  const navigate = useNavigate();
  const Path = useLocation();
  console.log(Path?.state);
  const location = Path?.state?.type;
  const loc = Path?.state?.type;
  console.log(place);
  return (
    <div className="">
      {/* <ProductDropdownTap products={products} /> */}
      <ProductListComp products={products} place={place} />
    </div>
  );
};

export default MapProductComp;
