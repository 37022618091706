import { Search } from "@mui/icons-material";
import { animatedImg, profilePic } from "../assets/img";
import BusinessIcon from "@mui/icons-material/Business";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ProfileDetails from "../components/VendorDetails/ProfileDetails";
import EditProfileDetails from "../components/VendorDetails/EditProfileDetails";
import HistoryDetails from "../components/VendorDetails/HistoryDetails";
import EditContactPopup from "../components/Popup/EditContactPopup";
import EditAddressPopup from "../components/Popup/EditAddressPopup";
import ContactDetails from "../components/VendorDetails/ContactDetails";
import PositionPopup from "../components/Popup/PositionPopup";
import LibraryDetails from "../components/VendorDetails/LibraryDetails";
import ProductDetails from "../components/VendorDetails/ProductDetails";
import { useSelector } from "react-redux";
import POSandBills from "../components/VendorDetails/POSandBills";
import CollaborateList from "../components/VendorDetails/CollaborateList";
import DecorativeDetails from "../components/VendorDetails/DecorativeDetails";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import ProductVendorPoScreen from "./ProductVendorPoScreen";

const ProductVendorDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.state.type);
  // console.log(location.state);
  const [profile, setProfile] = useState(false);
  const [contact, setContact] = useState(false);
  const [library, setLibrary] = useState(false);
  const [products, setProducts] = useState(false);
  const [bills, setBills] = useState(false);
  const [history, setHistory] = useState(false);
  const [collaborate, setCollaborate] = useState(false);
  const [decorative, setDecorative] = useState(false);

  const [editProfile, setEditProfile] = useState(false);
  const [contactPopup, setContactPopup] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [positionPopup, setPositionPopup] = useState(false);

  const [action, setAction] = useState(false);
  console.log(location?.state?.type);
  console.log(location?.state?.form);
  const vendor = location?.state?.form;
  const place = location?.state?.type;
  const type = location?.state?.name;
  const place2 = location?.state?.place;
  console.log(place, place2);
  console.log(place);
  console.log(type);
  const category = location?.state?.category;
  console.log(category);
  const path = location?.pathname;
  useEffect(() => {
    if (location?.state?.type === "library") {
      setLibrary(true);
    } else if (location?.state?.type === "collaborate") {
      setCollaborate(true);
    }
    // else if (type === 'decorative') {
    //   setProfile(false);
    //   setContact(false);
    //   setLibrary(false);
    //   setProducts(false);
    //   setBills(false);
    //   setHistory(false);
    //   setEditProfile(false);
    //   setCollaborate(false);
    //   setDecorative(true)
    // }
    // else if (type === 'product') {
    //   setProfile(false);
    //   setContact(false);
    //   setLibrary(false);
    //   setProducts(true);
    //   setBills(false);
    //   setHistory(false);
    //   setEditProfile(false);
    //   setCollaborate(false);
    //   setDecorative(false)
    // }
    else {
      setProfile(true);
    }
    // if (place === 'Jake Adams') {
    //   setProducts(true)
    //   setDecorative(true)
    // }
  }, []);

  const showPopupHander = () => {
    setContactPopup(false);
    setAddressPopup(false);
    setPositionPopup(false);
  };

  return (
    <>
      {contactPopup && (
        <EditContactPopup
          setContactPopup={setContactPopup}
          showPopupHander={showPopupHander}
        />
      )}
      {addressPopup && (
        <EditAddressPopup
          setAddressPopup={setAddressPopup}
          showPopupHander={showPopupHander}
        />
      )}
      {positionPopup && <PositionPopup showPopupHander={showPopupHander} />}
      <div className="dashRightView p-5 home_section trans">
        <ProductSearchHeader />
        <div className="w-100 bannerVendor p-1">
          <div className="d-flex as-jb flex-md-row flex-column ms-xl-5 ms-lg-5 ms-md-5 vendorDetSpace w-xs-100 w-sm-100 w-md-90 w-lg-90 w-xl-90">
            <div className=" d-lg-flex d-xl-flex d-md-flex as-jc">
              <img src={profilePic} className="profileImg" />
              <div className="ms-xl-5 ms-lg-5 ms-md-5">
                <p className="f4 fs-xs-22 fs-sm-23 fs-md-24 fs-lg-25 fs-xl-26 fs-xxl-27">
                  {location?.state?.type === "John Rolph"
                    ? "John Rolph"
                    : location?.state?.type === "Jake Adams"
                    ? "Jake Adams"
                    : "Charles"}
                </p>
                {/* <p className="f4 fs-xs-22 fs-sm-23 fs-md-24 fs-lg-25 fs-xl-26 fs-xxl-27">
                {location?.state?.type === "Charles"
                  && "Charles"
                }
              </p> */}
                {/* <p className="f4 fs-xs-22 fs-sm-23 fs-md-24 fs-lg-25 fs-xl-26 fs-xxl-27">
                {location?.state?.type === "Jake Adams"
                  && "Jake Adams"
                }
              </p> */}

                <div className="d-flex mt-2">
                  <BusinessIcon className="text-light f4 fs-xs-15 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-20 fs-xxl-21" />
                  <p className="text-light ms-2 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Ninos IT Solution
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex ac-jc position-relative ">
              <button
                onClick={() => setAction(!action)}
                className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                Actions
              </button>
              {action && (
                <div
                  onClick={() =>
                    navigate("/add-vendor", { state: { type: "email" } })
                  }
                  className="actionPopup pointerView px-2 py-2 ac-jc rounded-3">
                  <h6 className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                    Email Accounts
                  </h6>
                  <h6 className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                    Edit Profile
                  </h6>
                </div>
              )}
              {action && (
                <div className="actionPopup pointerView px-2 py-2 ac-jc rounded-3">
                  <button
                    onClick={() =>
                      navigate("/add-vendor", { state: { type: "email" } })
                    }
                    className="cust-btn primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                    Email Accounts
                  </button>
                  <button
                    onClick={() =>
                      navigate("/add-vendor", { state: { type: "email" } })
                    }
                    className="cust-btn primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                    Edit Profile
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-100 d-flex mt-4">
          <div className="d-flex flex-wrap mt-3 w-xs-100 ac-jc">
            <div
              onClick={() => {
                setProfile(true);
                setContact(false);
                setLibrary(false);
                setProducts(false);
                setBills(false);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                profile || editProfile
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              } pointerView`}>
              <p
                className={`${
                  profile || editProfile ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
                Profile
              </p>
            </div>
            <div
              onClick={() => {
                setProfile(false);
                setContact(true);
                setLibrary(false);
                setProducts(false);
                setBills(false);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                contact
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              } ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}>
              <p
                className={`${
                  contact ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
                Contact
              </p>
            </div>
            <div
              onClick={() => {
                setProfile(false);
                setContact(false);
                setLibrary(true);
                setProducts(false);
                setBills(false);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                library
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}>
              <p
                className={`${
                  library ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
                Library
              </p>
            </div>
            <div
              onClick={() => {
                setProfile(false);
                setContact(false);
                setLibrary(false);
                setProducts(true);
                setBills(false);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                products
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}>
              <p
                className={`${
                  products ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
                Products
              </p>
            </div>
            {/* {category !== 'product' && <div
            onClick={() => {
              setDecorative(true)
              setProfile(false);
              setContact(false);
              setLibrary(false);
              setProducts(false);
              setBills(false);
              setHistory(false);
              setEditProfile(false);
              setCollaborate(false);
            }}
            className={`${decorative
              ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
              : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
          >
            <p
              className={`${decorative ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Decoration
            </p>
          </div>} */}
            <div
              onClick={() => {
                setProfile(false);
                setContact(false);
                setLibrary(false);
                setProducts(false);
                setBills(true);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                bills
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}>
              <p
                className={`${
                  bills ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
                PO
              </p>
            </div>
            <div
              onClick={() => {
                setProfile(false);
                setContact(false);
                setLibrary(false);
                setProducts(false);
                setBills(false);
                setHistory(true);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                history
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-3 pointerView`}>
              <p
                className={`${
                  history ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
                Meeting History
              </p>
            </div>
            <div
              onClick={() => {
                setProfile(false);
                setContact(false);
                setLibrary(false);
                setProducts(false);
                setBills(false);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(true);
                setDecorative(false);
              }}
              className={`${
                collaborate
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-3 pointerView`}>
              <p
                className={`${
                  collaborate ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
                Collaborate
              </p>
            </div>
          </div>
        </div>
        {profile && (
          <ProfileDetails
            setEditProfile={setEditProfile}
            setProfile={setProfile}
            setAddressPopup={setAddressPopup}
            setContactPopup={setContactPopup}
            setHistory={setHistory}
            setPositionPopup={setPositionPopup}
            place={place}
          />
        )}
        {editProfile && (
          <EditProfileDetails
            setContactPopup={setContactPopup}
            setAddressPopup={setAddressPopup}
            setPositionPopup={setPositionPopup}
            setEditProfile={setEditProfile}
            setProfile={setProfile}
            setHistory={setHistory}
            place={place}
          />
        )}
        {history && <HistoryDetails />}
        {contact && (
          <ContactDetails
            setContactPopup={setContactPopup}
            setAddressPopup={setAddressPopup}
            setPositionPopup={setPositionPopup}
          />
        )}
        {library && <LibraryDetails />}
        {products && (
          <ProductDetails products={products} place={place} path={path} />
        )}
        {/* {decorative && <DecorativeDetails place={place} place2={place2} decorative2={decorative} />} */}
        {/* {bills && <POSandBills />} */}
        {bills && <ProductVendorPoScreen />}
        {collaborate && <CollaborateList />}
        <div className="w-80 mt-1 d-flex flex-md-row flex-column ac-jb">
          <button
            onClick={() => {
              if (place === "John Rolph") {
                navigate("/vendor-home", { state: { type: "John Rolph" } });
              } else if (place === "Charles") {
                navigate("/vendor-home", { state: { type: "Charles" } });
              } else {
                navigate("/vendor-home", { state: { type: "Jake Adams" } });
              }
            }}
            className="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductVendorDetailsPage;
