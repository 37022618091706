import React, { useState } from "react";
import { product, viewIcon } from "../../assets/img";
import { collaborateList } from "../../redux/api/DummyJson";
import { useNavigate } from "react-router-dom";
import DeletePopup from "../Popup/DeletePopup";
import AddArtWorker from "../Projects/AddArtWorker";
import AddFilesPopup from "../Popup/AddFilesOverview";

const TableComptwo = ({ deleteToggle, setAddTitle }) => {
  const navigate = useNavigate();
  const [addClient, setAddClient] = useState(false);
  const [addArt, setAddArt] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };
  const artWork = () => {
    toggleShowPopup();
  };
  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };
  return (
    <div className="presentaion_pages overflow-scroll mt-4">
      {addClient && (
        <AddArtWorker
          toggleShowPopup={toggleShowPopup}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
        />
      )}
      {AddFilesOverview && (
        <AddFilesPopup
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
        />
      )}
      <div className="w-100 overflow-scroll table_border menu-table">
        <table>
          <thead className="">
            <tr className="bg-soft-gray1">
              <th style={{ width: "500px" }} className="">
                <div className="dropdown mx-2 d-flex- flex-column ac-js d-flex w-100">
                  <button
                    className="dropdown-toggle align-self-start cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => navigate("/product-library")}
                      >
                        Product
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => setAddTitle(true)}
                      >
                        Tittle
                      </span>
                    </li>
                  </ul>
                </div>
              </th>
              <th className="">Artwork</th>
            </tr>
            {/* <tr className="border_cust position-relative">
              <td className="position-relative hover-cust2 ">
                <div className="d-flex w-100  py-3 hover-cust2">
                  <div className="mx-2" style={{ width: "500px" }}>
                    <div className="d-flex ac-js">
                      <img
                        src={product}
                        className="object-fit-contain img_size2"
                      />
                      <p className="text-start f1 text-dark fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        Material: Stainless Steel 304, Capacity: 500ml, Weight:
                        257gm, BPA Free, Screw Type Cop *Keeps liquid Hot &amp;
                        Cold: 12H-18Hr
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="position-relative hover-cust2">
                <div className="d-flex w-100  py-3 hover-cust2">
                  <div className="mx-2 " style={{ width: "500px" }}>
                    <div className="d-flex ac-js">
                      <img
                        src={product}
                        className="object-fit-contain img_size2"
                      />
                      <p className="f1 text-start text-dark fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        Material: Stainless Steel 304, Capacity: 500ml, Weight:
                        257gm, BPA Free, Screw Type Cop *Keeps liquid Hot &amp;
                        Cold: 12H-18Hr
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="position-relative hover-cust2">
                <div className="d-flex w-100  py-3 hover-cust2">
                  <div className="mx-2" style={{ width: "500px" }}>
                    <div className="d-flex ac-js">
                      <img
                        src={product}
                        className="object-fit-contain img_size2"
                      />
                      <p className="f1 text-start text-dark fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        Material: Stainless Steel 304, Capacity: 500ml, Weight:
                        257gm, BPA Free, Screw Type Cop *Keeps liquid Hot &amp;
                        Cold: 12H-18Hr
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              
            </tr> */}
            <tr className="position-relative color-for-hecd bor_t value_tr">
              <td className="text-start ps-2">
                <div className="mx-2" style={{ width: "500px" }}>
                  <div className="d-flex ac-js">
                    <img
                      src={product}
                      className="object-fit-contain img_size2"
                    />
                    <p className="text-start f1 text-dark fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                      Material: Stainless Steel 304, Capacity: 500ml, Weight:
                      257gm, BPA Free, Screw Type Cop *Keeps liquid Hot &amp;
                      Cold: 12H-18Hr
                    </p>
                  </div>
                </div>
              </td>
              <td className="art-work-tab1 align-items-start d-flex text-start">
                <div className="d-inline">
                  <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                    Location 1:
                  </label>
                  <div className="" style={{ width: "300px" }}>
                    <div
                      className="artwork_images d-flex ac-jc"
                      onClick={() => artWork()}
                    >
                      {addArt ? (
                        <div>
                          <img src={""} />
                        </div>
                      ) : (
                        <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          {" "}
                          Add Artwork
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-inline">
                  <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                    Location 2:
                  </label>
                  <div className="" style={{ width: "300px" }}>
                    <div
                      className="artwork_images d-flex ac-jc"
                      onClick={() => artWork()}
                    >
                      {addArt ? (
                        <div>
                          <img src={""} />
                        </div>
                      ) : (
                        <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          {" "}
                          Add Artwork
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="position-relative color-for-hecd bor_t value_tr text-start">
              <td className="text-start ps-2">
                <div className="mx-2" style={{ width: "500px" }}>
                  <div className="d-flex ac-js">
                    <img
                      src={product}
                      className="object-fit-contain img_size2"
                    />
                    <p className="text-start f1 text-dark fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                      Material: Stainless Steel 304, Capacity: 500ml, Weight:
                      257gm, BPA Free, Screw Type Cop *Keeps liquid Hot &amp;
                      Cold: 12H-18Hr
                    </p>
                  </div>
                </div>
              </td>
              <td className="art-work-tab1 align-items-start d-flex text-start">
                <div className="d-inline">
                  <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                    Location 1:
                  </label>
                  <div className="" style={{ width: "300px" }}>
                    <div
                      className="artwork_images d-flex ac-jc"
                      onClick={() => artWork()}
                    >
                      {addArt ? (
                        <div>
                          <img src={""} />
                        </div>
                      ) : (
                        <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          {" "}
                          Add Artwork
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-inline text-start">
                  <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                    Location 2:
                  </label>
                  <div className="" style={{ width: "300px" }}>
                    <div
                      className="artwork_images d-flex ac-jc"
                      onClick={() => artWork()}
                    >
                      {addArt ? (
                        <div>
                          <img src={""} />
                        </div>
                      ) : (
                        <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          {" "}
                          Add Artwork
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="position-relative color-for-hecd bor_t value_tr text-start">
              <td className="text-start ps-2">
                <div className="mx-2" style={{ width: "500px" }}>
                  <div className="d-flex ac-js">
                    <img
                      src={product}
                      className="object-fit-contain img_size2"
                    />
                    <p className="text-start f1 text-dark fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                      Material: Stainless Steel 304, Capacity: 500ml, Weight:
                      257gm, BPA Free, Screw Type Cop *Keeps liquid Hot &amp;
                      Cold: 12H-18Hr
                    </p>
                  </div>
                </div>
              </td>
              <td className="art-work-tab1 align-items-start text-start">
                <div className="d-inline">
                  <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                    Location 1:
                  </label>
                  <div className="" style={{ width: "300px" }}>
                    <div
                      className="artwork_images d-flex ac-jc"
                      onClick={() => artWork()}
                    >
                      {addArt ? (
                        <div>
                          <img src={""} />
                        </div>
                      ) : (
                        <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          {" "}
                          Add Artwork
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default TableComptwo;
