import React from "react";
import { head, logos, tshirt1, tshirt2, tshirt3 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Carousel from "react-bootstrap/Carousel";


const PresentationGalleryComment = () => {
  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        
        <div
          className="d-flex flex-wrap align-items-center mt-3  banner"
          style={{
            backgroundImage:
              "url( https://media.geeksforgeeks.org/wp-content/uploads/rk.png)",
            padding: "20px",
            flexWrap: "wrap",
          }}
        >
          <div className="">
            <img
              alt="img"
              src={logos}
              className=" ps-3"
              style={{ width: "150px", objectFit: "cover" }}
            />
          </div>

          <div className="ps-2">
          <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                PRESENTATION #110208 for AAHIKA
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project Name
              </p>
            
          </div>
        </div>
         
      
        <div className="d-flex flex-wrap mt-3">
          <div className="col-md-6 mt-3">
            <Carousel className="" >
              <Carousel.Item >
                <img src={tshirt1} className=" " alt="" style={{height:"500px",width:"100%",objectFit:"cover"}} />
              </Carousel.Item>
              <Carousel.Item >
                <img src={tshirt2} className="" alt="" style={{height:"500px",width:"100%",objectFit:"cover"}} />
              </Carousel.Item>
              <Carousel.Item  >
                <img src={tshirt3} className="" alt="" style={{height:"500px",width:"100%",objectFit:"cover"}} />
              </Carousel.Item>     
            </Carousel>
            {/* <div className="carousel-indicators">
               <img src={tshirt1} alt="" style={{width:"30px", height:"30px"}} />
               <img src={tshirt2} alt="" style={{width:"30px", height:"30px"}}/>
               <img src={tshirt3} alt="" style={{width:"30px", height:"30px"}}/>
            </div> */}
          </div>
          <div className="p-3 col-md-6 my-4">
            <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 primary  my-3">
              SOIL Soft Journal
            </h2>
            <div className="my-2">
              <button className="border-0 bg-gray p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                Product Description
              </button>
            </div>
            <div className="" style={{ height: "250px" }}>
              <div className="d-flex ac-jb my-3">
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                  vishwa:
                </p>
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                  2023-04-22
                </p>
              </div>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
                Some Changes are here please Check it.
              </p>
              <div className="d-flex ac-jb my-3">
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                  MK4:
                </p>
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                  2024-04-22
                </p>
              </div>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
                Supra
              </p>
            </div>
            <div className="my-3">
              <input
                className="bg-gray3 p-2 py-3 w-100 rounded border-0"
                placeholder="Your Name "
              />
            </div>
            <div>
              <input
                className="bg-lt-blue3 p-2 py-3 w-100 rounded border-0"
                placeholder="Comment On this Product......."
              />
            </div>
            <div>
              <button className="my-3 bg-primary1 px-3 border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                Add Comments{" "}
                {/* <span className="bg-blue1 rounded px-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  12
                </span> */}
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        class=" d-flex ac-jc px-3 mt-2 ms-4 py-2"
        style={{
          backgroundColor: "#0082ca",
          border: "none",
          borderRadius: "7px",
          padding: "5px",
          color: "#fff",
        }}
      >
        <UnsubscribeIcon />

        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-2 py-2">
          Contact us
        </p>
      </button>
    </div>
  );
};

export default PresentationGalleryComment;
