import { useEffect, useState } from "react";
import {
  Country,
  Vendor_offer,
  Vendor_offer_type,
  address_type,
  currency,
  currency_list,
  line_business,
  payment_Method,
  payment_terms,
  type_business,
  vendor_category,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageCroper from "../Popup/ImageCroper";

const AddEnterpriseDetails1 = ({ setVendorCat, vendorCat, setNames }) => {
  const [vendorCatShow, setVendorCatShow] = useState(false);
  const [lineBusinessShow, setLineBusinessShow] = useState(false);
  const [dropDownToggle, setDropDownToggle] = useState(undefined);
  const [paymentMethodShow, setPaymentMethodShow] = useState(false);
  const [profileEditBtn, setProfileEditBtn] = useState(false);

  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croperCol, setCroperCol] = useState(false);

  // Input States
  const [legalName, setLegalName] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorCatogory, setVendorCatogory] = useState("");
  const [lineBusiness, setLineBusiness] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [vendorOfferType, setVendorOfferType] = useState("");
  const [email, setEmail] = useState("");
  const [vendorOffer, setVendorOffer] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [GstID, setGstID] = useState("GST001");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressType1, setAddressType1] = useState("");
  const [addressType2, setAddressType2] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [currency, setCurrency] = useState("");
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();
  const [name, setName] = useState("");

  const onClickVendorCat = (e) => {
    if (vendorCatogory.includes(e)) {
      const listtemp = vendorCatogory.indexOf(e);
      const list = [...vendorCatogory];
      list.splice(listtemp, 1);
      setVendorCatogory(list);
    } else {
      setVendorCatogory([...vendorCatogory, e]);
    }
  };
  const onClickLineBusiness = (e) => {
    if (lineBusiness.includes(e)) {
      const listtemp = lineBusiness.indexOf(e);
      const list = [...lineBusiness];
      list.splice(listtemp, 1);
      setLineBusiness(list);
    } else {
      setLineBusiness([...lineBusiness, e]);
    }
  };

  const onClickPaymentMethod = (e) => {
    if (paymentMethod.includes(e)) {
      const listtemp = paymentMethod.indexOf(e);
      const list = [...paymentMethod];
      list.splice(listtemp, 1);
      setPaymentMethod(list);
    } else {
      setPaymentMethod([...paymentMethod, e]);
    }
  };

  const dualToggele = (type) => {
    if (type == "logo") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setBanner(croperImage);
    } else if (!croperCol && croperImage) {
      setLogo(croperImage);
    }
  }, [croperImage]);

  return (
    <div className="w-100">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="d-flex flex-wrap as-jb flex-m-r">
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Choose Parent Account*
          </p>
          <div className="position-relative ">
            <input
              placeholder="Choose Parent Account"
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={addressLine1}
              onChange={(e) => {
                setAddressLine1(e.target.value);
              }}
              onClick={() => {
                setDropDownToggle(3);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              // onPointerEnter={setVendorCat('others')}
              onClick={() => {
                setDropDownToggle(3);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownToggle === 3 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownToggle(undefined)}
              />
            )}
            <div
              className={`${
                dropDownToggle === 3 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {[
                "Ad Agency",
                "Auto",
                "Aviation",
                "Beverages",
                "Business Services",
              ]?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setAddressLine1(item);
                      }}
                    >
                      {item}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Enterprises Name*
          </p>
          <input
            placeholder="Enter Legal Name"
            value={legalName}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => {
              setLegalName(e.target.value);
              setNames(e.target.value);
            }}
          />
        </div>
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Industrial types*
          </p>
          <div className="position-relative ">
            <input
              placeholder="Select Industrial types"
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={vendorName}
              onChange={(e) => {
                setPaymentTerms(e.target.value);
              }}
              onClick={() => {
                setDropDownToggle(2);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              // onPointerEnter={setVendorCat('others')}
              onClick={() => {
                setDropDownToggle(2);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownToggle === 2 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownToggle(undefined)}
              />
            )}
            <div
              className={`${
                dropDownToggle === 2 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {[
                "Ad Agency",
                "Auto",
                "Aviation",
                "Beverages",
                "Business Services",
              ]?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setVendorName(item);
                      }}
                    >
                      {item}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Trade Name
          </p>
          <input
            placeholder="Enter Trade Name"
            value={vendorName}
            onChange={(e) => setVendorName(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div> */}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            GST ID
          </p>
          <input
            placeholder="Enter GST ID"
            value={GstID}
            onChange={(e) => setGstID(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Website
          </p>
          <input
            placeholder="Enter Website"
            value={vendorCatogory}
            onChange={(e) => setVendorCatogory(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          />
        </div>
        {/* <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Category*
          </p>
          <div className="position-relative">
            <input
              placeholder="Select Category"
              type="text"
              value={vendorCatogory}
              onClick={() => setVendorCatShow(!vendorCatShow)}
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
            <button
              className="drop_down cust-btn"
              onClick={() => setVendorCatShow(!vendorCatShow)}
            >
              <KeyboardArrowDownIcon />
            </button>
            {vendorCatShow && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setVendorCatShow(false)}
              />
            )}
            <div
              className={`${
                vendorCatShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {vendor_category?.map((item, ind) => {
                return (
                  <button
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    onClick={() => {
                      onClickVendorCat(item?.list);
                    }}
                  >
                    <button className="px-2 cust-btn text-start py-1 w-100 ">
                      {item?.list}
                    </button>
                    <button className="px-2 cust-btn text-start">
                      {vendorCatogory.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </button>
                );
              })}
            </div>
          </div>
        </div> */}
        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Line of Business*
          </p>
          <div className="position-relative">
            <input
              placeholder="Select Category"
              type="text"
              value={lineBusiness}
              onClick={() => {
                setLineBusinessShow(!lineBusinessShow);
                setVendorCatShow(false);
                setDropDownToggle(undefined);
                setPaymentMethodShow(false);
              }}
              className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setLineBusinessShow(!lineBusinessShow);
                setVendorCatShow(false);
                setDropDownToggle(undefined);
                setPaymentMethodShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {lineBusinessShow && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setLineBusinessShow(false)}
              />
            )}
            <div
              className={`${
                lineBusinessShow && "submenu_1 h-auto py-2"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {line_business?.map((item, ind) => {
                return (
                  <button
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    onClick={() => {
                      onClickLineBusiness(item?.list);
                    }}
                  >
                    <button className="px-2 cust-btn text-start py-1 w-100 ">
                      {item?.list}
                    </button>
                    <button className="px-2 cust-btn text-start">
                      {lineBusiness.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </button>
                );
              })}
            </div>
          </div>
        </div> */}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Budget
          </p>
          <input
            placeholder="Enter Budget"
            value={lineBusiness}
            onChange={(e) => setLineBusiness(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Person Name
          </p>
          <input
            placeholder="Enter Primary Person Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Person Mobile
          </p>
          <input
            placeholder="Enter Primary Person Mobile"
            value={vendorOfferType}
            type={"number"}
            onChange={(e) => setVendorOfferType(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Person Email
          </p>
          <input
            placeholder="Enter Primary Person Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          />
        </div>
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Purchase type*
          </p>
          <div className="position-relative ">
            <input
              placeholder="Select Purchase type"
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={vendorCat}
              onChange={(e) => {
                setVendorCat(e.target.value);
              }}
              onClick={() => {
                setDropDownToggle(1);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              // onPointerEnter={setVendorCat('others')}
              onClick={() => {
                setDropDownToggle(1);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownToggle === 1 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownToggle(undefined)}
              />
            )}
            <div
              className={`${
                dropDownToggle === 1 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {type_business?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setVendorCat(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Type of Business*
          </p>
          <div className="position-relative ">
            <input
              placeholder=""
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={vendorCat}
              onChange={(e) => {
                console.log(e.target.value);
                setVendorCat(e.target.value);
              }}
              onClick={() => {
                setDropDownToggle(1);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              // onPointerEnter={setVendorCat('others')}
              onClick={() => {
                setDropDownToggle(1);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownToggle === 1 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownToggle(undefined)}
              />
            )}
            <div
              className={`${
                dropDownToggle === 1 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {type_business?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setVendorCat(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          {vendorCat === "others" && (
            <input
              placeholder=""
              className="editBtn  w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          )}
        </div> */}
        {/* <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Offering Type*
          </p>
          <div className="position-relative ">
            <input
              placeholder=""
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={vendorOfferType}
              onChange={(e) => {
                console.log(e.target.value);
                setVendorOfferType(e.target.value);
              }}
              onClick={() => {
                setDropDownToggle(2);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              // onPointerEnter={setVendorCat('others')}
              onClick={() => {
                setDropDownToggle(2);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownToggle === 2 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownToggle(undefined)}
              />
            )}
            <div
              className={`${
                dropDownToggle === 2 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {Vendor_offer_type?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setVendorOfferType(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
        {/* <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Offerings*
          </p>
          <div className="position-relative ">
            <input
              placeholder=""
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={vendorOffer}
              onChange={(e) => {
                console.log(e.target.value);
                setVendorOffer(e.target.value);
              }}
              onClick={() => {
                setDropDownToggle(3);
                setVendorCatShow(false);
                setPaymentMethodShow(false)
                setLineBusinessShow(false)
              }}
            />
            <button
              className="drop_down cust-btn"
              // onPointerEnter={setVendorCat('others')}
              onClick={() => {
                setDropDownToggle(3);
                setVendorCatShow(false);
                setPaymentMethodShow(false)
                setLineBusinessShow(false)
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownToggle === 3 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownToggle(undefined)}
              />
            )}
            <div
              className={`${dropDownToggle === 3 && "submenu_1 h-auto"
                } submenu_cont_1 overflow-scroll z-3`}>
              {Vendor_offer?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setVendorOffer(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Tags
          </p>
          <input
            placeholder="Enter the Tags"
            value={paymentTerms}
            onChange={(e) => setPaymentTerms(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Notes
          </p>
          <input
            placeholder="Enter the Notes"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>

        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Enter Enterprises Prefix
          </p>
          <input
            placeholder="Enter Enterprises Prefix"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Order Rep*
          </p>
          <div className="position-relative ">
            <input
              placeholder="Select Order Rep"
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={addressType1}
              onChange={(e) => {
                setAddressType1(e.target.value);
              }}
              onClick={() => {
                setDropDownToggle(4);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              // onPointerEnter={setVendorCat('others')}
              onClick={() => {
                setDropDownToggle(4);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownToggle === 4 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownToggle(undefined)}
              />
            )}
            <div
              className={`${
                dropDownToggle === 4 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {[
                "Ad Agency",
                "Auto",
                "Aviation",
                "Beverages",
                "Business Services",
              ]?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setAddressType1(item);
                      }}
                    >
                      {item}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Currency
          </p>
          <div className="position-relative ">
            <input
              placeholder="Enter currency"
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={currency}
              onChange={(e) => {
                console.log(e.target.value);
                setCurrency(e.target.value);
              }}
              onClick={() => {
                setDropDownToggle(10);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              // onPointerEnter={setVendorCat('others')}
              onClick={() => {
                setDropDownToggle(10);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownToggle === 10 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownToggle(undefined)}
              />
            )}
            <div
              className={`${
                dropDownToggle === 10 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {currency_list?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setCurrency(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Client Rep*
          </p>
          <div className="position-relative ">
            <input
              placeholder="Select Client Rep"
              className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={addressType2}
              onChange={(e) => {
                setAddressType2(e.target.value);
              }}
              onClick={() => {
                setDropDownToggle(5);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              // onPointerEnter={setVendorCat('others')}
              onClick={() => {
                setDropDownToggle(5);
                setVendorCatShow(false);
                setPaymentMethodShow(false);
                setLineBusinessShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownToggle === 5 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownToggle(undefined)}
              />
            )}
            <div
              className={`${
                dropDownToggle === 5 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {[
                "Ad Agency",
                "Auto",
                "Aviation",
                "Beverages",
                "Business Services",
              ]?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setAddressType2(item);
                      }}
                    >
                      {item}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
          onClick={() => dualToggele("logo")}
        >
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Logo Image
          </p>
          <input
            // type="file"
            // onChange={(e) => {
            //   console.log(e.target.files[0]);
            // }}
            style={{ cursor: "pointer" }}
            value={
              logo ? "Logo Uploaded Successfully" : "Browse or Drag a file"
            }
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div
          className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
          onClick={() => dualToggele("ban")}
        >
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Banner Image
          </p>
          <input
            // type="file"
            // onChange={(e) => {
            //   console.log(e.target.files[0]);
            // }}
            style={{ cursor: "pointer" }}
            value={
              banner ? "Banner Uploaded Successfully" : "Browse or Drag a file"
            }
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        {/* <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Profile Description
          </p>
          <textarea
            placeholder="Write a Short Description of the Company"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div> */}
      </div>
    </div>
  );
};

export default AddEnterpriseDetails1;
