import React from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import OurProductListComp from "../components/OurProductComp/OurProductListComp";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const OurPackScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />

      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-3 w-100 ac-jb pt-4">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Our Packs
          </p>
          <div className="d-flex ac-jb">
            <h5 className="preset_count bold pe-3">Total Count: 20</h5>
            <button
              onClick={() => navigate("/add-our-packs")}
              className="cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              + Add Product
            </button>
          </div>
        </div>
        <div className="d-flex mt-3 w-100 ac-jb pt-4">
          <div className="d-flex ac-jb">
            <p className="tag_filter">
              Tags{" "}
              <button>
                All <KeyboardArrowDownIcon />
              </button>
            </p>
            <p className="tag_filter">
              Event Tags{" "}
              <button>
                All <KeyboardArrowDownIcon />
              </button>
            </p>
            <p className="tag_filter">
              Icon Tags{" "}
              <button>
                All <KeyboardArrowDownIcon />
              </button>
            </p>
          </div>
          <div className="d-flex ac-jb">
            <input
              type="text"
              className="get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3 w-auto fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder={"Search List"}
            />
            <div className="ps-2">
              <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
                <Search />
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex mt-2 ac-jc w-100 flex-wrap me-md-3 rk2">
          {/* <EmptyProductComp /> */}
        </div>
        <OurProductListComp />
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default OurPackScreen;
