import React, { useState } from "react";
import { searchIcon, toggleOff, toggleOn } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { editIcon, viewIcon } from "../../assets/img";
import GetAppIcon from "@mui/icons-material/GetApp";
import AddEventCategory from "./AddEventCategory";
import EditEventCategory from "./EditEventCategory";

function EventCategory() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [addPopUp, setAddPopUp] = useState(false);
  const [editPopUp, setEditPopUp] = useState(false);
  const [priority, setPriority] = useState("");

  let [dummiData, setDummiData] = useState([]);

  //   Event Functions
  const handleClose = () => {
    setAddPopUp(!addPopUp);
  };

  const handleOnSubmit = (e) => {
    setAddPopUp(!addPopUp);
    if (e && typeof e === "object") {
      Array.isArray(dummiData) && setDummiData([...dummiData, e]);
    }
  };

  const handleEditClose = () => {
    setEditPopUp(!editPopUp);
  };

  const handleChangePriority = (e) => {
    const { value } = e && e.target;
    if (value?.length) {
      let temp = value?.padStart(1, "0");
      setPriority(temp);
    } else {
      setPriority(value);
    }
  };

  const handleEditOnSubmit = (e) => {
    // console.log(e);
  };
  return (
    <div className="dashRightView p-5 home_section trans">
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        Event Category
      </p>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex flex-md-row flex-column">
          <div className="pointerView d-flex mt-4 me-3">
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search">
            <img alt="img" src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          <button
            onClick={() => setAddPopUp(!addPopUp)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Category
          </button>
        </div>
      </div>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Event Image
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Event Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Priority
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Action
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(dummiData) &&
              dummiData?.map((item, index) => {
                let src = item?.image !=='' ? URL.createObjectURL(item?.image) : item?.image;
                return (
                  <tr key={index}>
                    <td>
                      <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {index + 1}
                      </p>
                    </td>
                    <td>
                      <div className="table_img_container">
                        <img src={src} alt="img" className="table_img" />
                      </div>
                    </td>
                    <td>
                      <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {item?.category_name}
                      </p>
                    </td>
                    <td>
                      <div className="d-flex ac-jc">
                        <input
                          className="w-20"
                          value={priority+index || ""}
                          onChange={(e) => handleChangePriority(e)}
                          type="number"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex ac-jc">
                        {item?.status === 3 ? (
                          <div>
                            <img
                              alt="img"
                              src={toggleOff}
                              className="toggleOnDes"
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              alt="img"
                              src={toggleOn}
                              className="toggleOnDes"
                            />
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex ac-jc primary1">
                        <div
                          onClick={() => setEditPopUp(!editPopUp)}
                          className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                        >
                          <img alt="img" src={editIcon} className="viewDes" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
      {addPopUp && (
        <AddEventCategory
          handleClose={handleClose}
          handleOnSubmit={handleOnSubmit}
        />
      )}
      {editPopUp && (
        <EditEventCategory
          handleClose={handleEditClose}
          handleOnSubmit={handleEditOnSubmit}
        />
      )}
    </div>
  );
}

export default EventCategory;
