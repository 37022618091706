import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownTap from "../components/ProductComp/ProductDropdownTap";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import ProductListComp from "../components/ProductComp/ProductListComp";

const ProductScreen = () => {
  const [priceBook, setpriceBook] = useState(false);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [tagged, setTagged] = useState(false);
  const navigate = useNavigate();
  const Path = useLocation()
  console.log(Path?.state)
  const location = Path?.state?.type
  const loc = Path?.state?.type
  console.log(location)
  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5">
        Products
      </p>
      <div className="d-flex mt-2 ac-jb flex-wrap">
        <div className="d-flex mt-3">
          <div
            onClick={() => {
              setpriceBook(true);
              setPreviousOrder(false);
              setTagged(false);
            }}
            style={{ cursor: "pointer" }}
            className="border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
          >
            <p className="f3 fs-xs-11 fs-sm-12 black fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              OverAll Products
            </p>
          </div>
        </div>
        {
          location === undefined &&
          <div>
            <button
              onClick={() => navigate(-1)}

              className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              + Add Product
            </button>
          </div>
        }
        {
          location === 'product' &&
          <div>
            <button
              onClick={() => navigate("/products/add-new-product", {
                state: { type: "Charles" },
              })}
              className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              + Add Product
            </button>
          </div>
        }
        {
          location === 'servisec' && <div>
            <button
              onClick={() => navigate("/products/add-new-product", {
                state: { type: "John Rolph" },
              })}
              className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              + Add Product
            </button>
          </div>
        }
        {
          location === 'both' && <div>
            <button
              onClick={() => navigate("/products/add-new-product", {
                state: { type: "Jake Adams" },
              })}
              className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              + Add Product
            </button>
          </div>
        }
      </div>
      <DropdownTap location={location} />
      <ProductListComp loc={loc} />
    </div>
  );
};

export default ProductScreen;
