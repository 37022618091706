import ProductSearchHeader from "../../components/ProductComp/ProductSearchHeader";
import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { useLocation, useNavigate } from "react-router-dom";
import PriceBookListComp from "../../components/PriceBookLibraryComp/PriceBookListComp";
import { searchIcon, product, toggleOn } from "../../assets/img";
import { product_library } from "../../redux/api/DummyJson";

const PriceBookView = () => {
  const navigat = useNavigate();
  const location = useLocation();

  console.log("location", location?.state?.method);
  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />

      <div className="d-flex ac-js flex-wrap rk2 mb-3">
        <p className="f4  fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          Price Book
          {location?.state?.data?.pricebook_name ? "-" : "Library"}
        </p>
        {location?.state?.data?.pricebook_name ? (
          <div className="">
            <p
              className="mx-1 rounded-3 p-2 w-100 f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
              style={{ border: "#07679c 0.5px solid", color: "#839dd1" }}
            >
              {location?.state?.data?.pricebook_name}
            </p>
          </div>
        ) : null}
      </div>
      <div className="d-flex flex-md-row flex-column justify-content-between">
        <div className="w-50 ac-jb d-flex flex-md-row flex-column mt-2">
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">10</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
          </div>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4">
            Total Count : {"05"}
          </p>
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          <button
            onClick={() =>
              navigat("/add-price-book-library", {
                state: {
                  method: location?.state?.method
                    ? "enterprises"
                    : "pricebook-lib",
                },
              })
            }
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Pricebook Product
          </button>
        </div>
      </div>
      <div>
        <div className="w-100 d-flex ac-js mt-5 flex-wrap">
          {product_library?.map((item, ind) => {
            return (
              <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl3">
                <div
                  className="prod-list-box"
                  onClick={() =>
                    navigat("/add-price-book-library", {
                      state: {
                        type: "edit",
                        data: item,
                        method: location?.state?.method
                          ? "enterprises"
                          : "pricebook-lib",
                      },
                    })
                  }
                >
                  <div className="cust-btn d-flex ac-jc rounded-3">
                    <img
                      src={product}
                      alt=""
                      className="custom-w-h rounded-3"
                    />
                  </div>
                  <div className="d-flex ac-jb w-100 my-1">
                    <div>
                      <p className="f5 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 primary mt-3">
                        PRICE
                      </p>
                      <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2  mb-0">
                        Min qty(25)
                      </p>
                    </div>
                    <p className="f5 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                      {item?.price}
                    </p>
                  </div>
                  <div className="d-flex ac-jb w-100 my-1">
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 primary parag">
                      NEW SKU CODE :
                    </p>
                    <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                      {item?.pricebook_code}
                    </p>
                  </div>
                  <div className="d-flex ac-jb w-100 my-1">
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 primary parag">
                      PRODUCT SKU CODE:
                    </p>
                    <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                      {item?.product_code}
                    </p>
                  </div>
                  <div className="d-flex ac-jb w-100 my-1">
                    <p className="f4 fs-12 fs-sm-13 text-custom-res fs-lg-14 ms-2 primary1 parag w-100">
                      Polo White T-shirt size S,M,L,XL
                    </p>
                  </div>
                  <div className="d-flex ac-jb w-100 my-1">
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 primary parag">
                      Status
                    </p>

                    <span>
                      <img src={toggleOn} className="toggleOnDes" alt="icon" />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={"d-flex justify-content-between mt-3"}>
          <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Previous
          </button>
          <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
export default PriceBookView;
