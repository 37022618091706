import React, { useState } from "react";
import { product, viewIcon } from "../../assets/img";
import { collaborateList, Compact_table } from "../../redux/api/DummyJson";
import { useNavigate } from "react-router-dom";
import DeletePopup from "../Popup/DeletePopup";
import ViewStockPoppup from "./ViewStockPoppup";

const TableComp2 = ({
  setAddTitle,
  viewStockToggle,
  deleteToggle,
  toggleBtn1,
  tab,
}) => {
  const navigate = useNavigate();
  const [hide, setHide] = useState(false);
  return (
    <div className="presentaion_pages overflow-scroll mt-4 w-100">
      <div className="w-100 overflow-scroll table_border menu-table">
        <table>
          <thead className="py-3">
            <tr className="bg-soft-gray1">
              <th style={{ width: "600px" }} className="py-3">
                <div className="dropdown mx-2 d-flex- flex-column ac-js d-flex w-100">
                  <button
                    className="dropdown-toggle align-self-start cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => navigate("/product-library")}
                      >
                        Product
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => setAddTitle(true)}
                      >
                        Tittle
                      </span>
                    </li>
                  </ul>
                </div>
              </th>
              <th>Units</th>
              <th>Margin</th>
              <th>Tax</th>
              <th>Amount</th>
            </tr>
            {Compact_table?.map((item, ind) => {
              return (
                <tr
                  className={`position-relative color-for-hecd bor_t value_tr ${
                    hide && "opacity-50"
                  }`}
                >
                  <td className="text-start ps-2">
                    <div className=" d-flex flex-column as-je">
                      <div className=" w-100">
                        <div className="d-flex">
                          <img
                            src={product}
                            className="object-fit-contain img_size2"
                          />
                          <div className="d-flex flex-column mt-4">
                            <span className="text-info f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                              {item?.sku_code}
                            </span>
                            <span className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                              {item?.name}
                            </span>
                            <span className="f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                              {item?.para}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex mb-3">
                          <button className="" onClick={() => setHide(!hide)}>
                            Hide
                          </button>
                          <button
                            onClick={() => navigate("/estimate-edit-item")}
                          >
                            Edit
                          </button>
                          {/* <button>Copy</button> */}
                          <button className="del-colo-d" onClick={deleteToggle}>
                            Delete
                          </button>
                          {/* <button
                          className={
                            item?.status === "Estimate Rejected"
                              ? "del-colo-d"
                              : item?.status == "In Process"
                              ? "pending"
                              : item?.status == "Ready" && "paid"
                          }
                        >
                          {item?.status}
                        </button> */}
                          <button onClick={() => viewStockToggle()}>
                            {" "}
                            View Stock
                          </button>
                        </div>
                        {/* <p className="f1 text-start text-dark fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 text-wrap">
                        {item?.dis}
                      </p> */}
                      </div>
                      <div
                        className=" status_btn"
                        style={{
                          bottom: 0,
                        }}
                      >
                        <div
                          className={` ${
                            item?.status == "Ready"
                              ? "accepted_btn"
                              : item?.status == "Estimate Rejected"
                              ? "reject_btn"
                              : item?.status == "In Process" && "process_btn"
                          } px-4 py-2  rounded-3 d-flex mb-2`}
                        >
                          {/* <DoneIcon className="text_btn me-2" /> */}
                          <p className="text_btn">{item?.status}</p>
                        </div>
                      </div>
                    </div>

                    {/* <h1
                      // className={
                      //   item?.status == "Client Paid" &&
                      //   ""
                      // }
                      className={` ${
                        item?.status == "Ready"
                          ? "paid_text"
                          : item?.status == "Estimate Rejected"
                          ? "reject_text"
                          : item?.status == "In Process" && "proces_text"
                      } pointerView py-2 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start`}
                    >
                      {item?.status}
                    </h1> */}
                  </td>
                  <td>20</td>
                  <td>4.99%</td>
                  <td>CGST (6%)</td>
                  <td>$1010.45</td>
                </tr>
              );
            })}
          </thead>
        </table>
      </div>
    </div>
  );
};

export default TableComp2;
