// @ts-nocheck
import { Delete } from "@mui/icons-material";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { editIcon, toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { customerDecoList } from "../../redux/api/DummyJson";

const OurDealsList = () => {
  const navigate = useNavigate();

  return (
    <div className="overflow-scroll mt-4">
      <div className="wholeDesignCustomerDeco1 d-flex mt-4 border-bottom">
        <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc mw-100px w-200px text-center">
          Sl.no
        </p>
        <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc mw-100px w-200px text-center">
          Name
        </p>
        <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc mw-100px w-200px text-center">
          Priority
        </p>
        <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc mw-100px w-200px text-center">
          Status
        </p>
        <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc mw-100px w-200px text-center">
          Action
        </p>
      </div>
      {customerDecoList?.map((item) => {
        return (
          <div className="wholeDesignCustomerDeco1 d-flex mt-5 pb-2">
            <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc mw-100px w-200px">
              {item?.slNo}
            </p>
            <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc mw-100px w-200px">
              {item?.orderId}
            </p>

            <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc mw-100px w-200px">
              5
            </p>

            <div className=" d-flex ac-jc mw-100px w-200px">
              <Form.Check type="switch" id="toggle-switch" label="" />
            </div>
            <div className=" d-flex ac-jc primary1 mw-100px w-200px">
              <div
                onClick={() =>
                  navigate("/our-deals-view", {
                    state: { data: item, type: "view" },
                  })
                }
                className="viewBoxDes pointerView p-2 bg-white rounded-2"
              >
                <img src={viewIcon} className="viewDes" />
              </div>
              <div
                onClick={() =>
                  navigate("/our-deals-view", {
                    state: { type: "edit", data: item },
                  })
                }
                className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
              >
                <img src={editIcon} className="viewDes" />
              </div>
              <div className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2">
                <Delete />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OurDealsList;
