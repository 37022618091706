import React, { useState } from "react";
import manImage from "../../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { Search } from "@mui/icons-material";
import Select from "react-select";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const DashboardTop = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  return (
    <div className="">
      <div className="d-flex w-95 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={manImage} className="searchManImg" />
      </div>
      {/* <div className="d-flex w-100 ac-jb">
        <h2 className="f5 fs-17 fs-md-22 fs-lg-25 mb-0 pb-0">
          Production DashBoard
        </h2>
        <button className="f3 px-3 py-2 fs-14 fs-md-15 fs-lg-16 cust-btn text-white rounded bg-primar">
          New Project
        </button>
      </div>
      <div className="w-100">
        <p className="f2 fs-15 fs-md-16 fs-lg-17 mb-0 pb-0">Production Rep</p>
        <Select
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={options}
          className="w-50"
        />
      </div> */}
    </div>
  );
};

export default DashboardTop;
