import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  sub_menu,
  Product_Name,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  catogory_setting,
  counting,
  colors,
  Select_list,
  selecLibList,
  selected_list,
  Select_list2,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import RequestImageUplodeComp from "./RequestImageUplodeComp";
import VendorPricingComp from "../MyProductListComp/VendorPricingComp";
import PricingCompNew from "./PricingCompNew";
import PricingBoxComp from "../MyProductListComp/PricingBoxComp";
const RequestFormComp = () => {
  const navigat = useNavigate();

  // Toggle List
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(false);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);
  const [colorsShow, setColorsShow] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);
  const [attributesShow, setAttributesShow] = useState(false);
  const [attributesShow2, setAttributesShow2] = useState(false);
  const [hsnTypeShow, setHsnTypeShow] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [Dropdown, setDrop] = useState(0);
  const [details, setDetails] = useState(false);
  const [prodNameicon, setProdNameicon] = useState(false);
  const [distable, setDistable] = useState(false);
  const [check, setCheck] = useState(undefined);
  const [checkBox, setCheckBox] = useState(false);
  const [addIcon, setAddIcon] = useState(undefined);
  const [addIcon1, setAddIcon1] = useState(undefined);
  const [addIcon2, setAddIcon2] = useState(undefined);

  // input box
  const [catogory, setCatogory] = useState("");
  const [catogory2, setCatogory2] = useState("");
  const [catogory3, setCatogory3] = useState("");
  const [productName, setProductName] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [packingSize, setPackingSize] = useState("");
  const [country, setCountry] = useState("");
  const [colorsName, setColorsName] = useState([]);
  const [ind1, setInd] = useState([]);
  const [decorativeName, setDecorativeName] = useState([]);
  const [Ind2, setInd2] = useState([]);
  const [attributesName, setAttributesName] = useState([]);
  const [attributesName2, setAttributesName2] = useState([]);
  const [inputSellect, setInputSellect] = useState("");
  const [hsnType, sethsnType] = useState("");
  const [size, setSize] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const [varient, setVarient] = useState("");
  const [meterial, setMeterial] = useState("");
  const [slash, setslash] = useState("/");
  const [textShow2, settextShow2] = useState(false);

  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 2) {
      setCatogoryShow(true);
      setProductNameShow(false);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };
  const onCatogory2 = (e) => {
    setCatogory2(e.target.value);
    if (catogory2.length >= 2) {
      setCatogoryShow2(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onCatogory3 = (e) => {
    setCatogory3(e.target.value);
    if (catogory3.length >= 2) {
      setCatogoryShow3(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onProduct = (e) => {
    setProductName(e.target.value);
    if (productName.length <= 0) {
      setProdNameicon(false);
      setSkuCode("");
      setProductSuplierCode("");
    } else {
      setProdNameicon(true);
    }
  };
  const onPackingSize = (e) => {
    setPackingSize(e.target.value);
    if (packingSize.length >= 2) {
      setPackingSizeShow(true);
    } else {
      if (packingSize.length >= 0) setPackingSizeShow(false);
    }
  };
  const onColors = (e) => {
    setColorsName(e.target.value);
    if (colorsName.length >= 2) {
      setColorsShow(true);
    } else {
      if (colorsName.length >= 0) setColorsShow(false);
    }
  };
  const onCountry = (e) => {
    setCountry(e.target.value);
    if (country.length >= 2) {
      setCountryShow(true);
    } else {
      if (country.length >= 0) setCountryShow(false);
    }
  };
  const ColorSlect = (e) => {
    if (colorsName.includes(e)) {
      const listtemp = colorsName.indexOf(e);
      const list = [...colorsName];
      list.splice(listtemp, 1);
      setColorsName(list);
    } else {
      setColorsName([...colorsName, e]);
    }
  };
  const OnsetInd = (e) => {
    const arr = [...ind1, e];
    setInd(arr);
  };
  const onDecorative = (e) => {
    setDecorativeName(e.target.value);
    if (decorativeName.length >= 2) {
      setDecorativeShow(true);
    } else {
      if (decorativeName.length >= 0) setDecorativeShow(false);
    }
  };
  const onAttribute = (e) => {
    setAttributesName(e.target.value);
    if (decorativeName.length >= 2) {
      setAttributesShow(true);
    } else {
      if (decorativeName.length >= 0) setAttributesShow(false);
    }
  };
  const onAttribute2 = (e) => {
    setAttributesName2(e.target.value);
    if (decorativeName.length >= 2) {
      setAttributesShow2(true);
    } else {
      if (decorativeName.length >= 0) setAttributesShow2(false);
    }
  };

  const onDecorativeClick = (e) => {
    if (decorativeName.includes(e)) {
      const listtemp = decorativeName.indexOf(e);
      const list = [...decorativeName];
      list.splice(listtemp, 1);
      setDecorativeName(list);
    } else {
      setDecorativeName([...decorativeName, e]);
    }
    // const arr = [...decorativeName, e]
    // setDecorativeName(arr)
  };
  // const selectBox = (ind) => {
  //     if (check2.includes(ind)) {
  //         const indtemp = check2.indexOf(ind);
  //         const temp = [...check2];
  //         temp.splice(indtemp, 1);
  //         setCheck2(temp);
  //     } else {
  //         setCheck2([...check2, ind]);
  //     }
  //     console.log(check2);
  // };
  const onAttributeClick = (e) => {
    if (attributesName.includes(e)) {
      const listtemp = attributesName.indexOf(e);
      const list = [...attributesName];
      list.splice(listtemp, 1);
      setAttributesName(list);
    } else {
      setAttributesName([...attributesName, e]);
    }
  };
  const onAttributeClick2 = (e) => {
    if (attributesName2.includes(e)) {
      const listtemp = attributesName2.indexOf(e);
      const list = [...attributesName2];
      list.splice(listtemp, 1);
      setAttributesName2(list);
    } else {
      setAttributesName2([...attributesName2, e]);
    }
  };

  const onSizeClick = () => {
    const temp = [varient][size];
    setVarient(temp);
  };

  const onSizeChange = (e) => {
    setSize(e.target.value);
    // if (size.length > 0) {
    //     setAddIcon(true)
    // } else {
    //     setAddIcon(false)
    // }
  };

  return (
    <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
      <div className="w-100">
        <div className="d-flex flex-wrap ac-jb flex-m-r">
          <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Category
            </p>
            <div className="position-relative z-3">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={catogory}
                onChange={onCatogory}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCatogoryShow(!catogoryShow);
                  setChildMenu(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setDecorativeShow(false);
                  setAttributesShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {catogoryShow && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setCatogoryShow(!catogoryShow)}
              />
            )}
            <div
              className={`${
                catogoryShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {selected_list?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setCatogoryShow(false);
                        setCatogory(item?.list);
                        setChildMenu(false);
                        setSubcatogory(true);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
            <div className={childMenu ? "submenu_child_1" : "submenu_child_2"}>
              <div
                className={`${
                  catogoryShow && "submenu_2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      {item.list !== "Game consoles" && (
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setCatogoryShow(false);
                            setCatogory(item?.list);
                            setChildMenu(false);
                          }}
                        >
                          {item?.list}
                        </button>
                      )}
                      {item.list === "Game consoles" && (
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => setChildMenu(!childMenu)}
                        >
                          {item?.list}
                        </button>
                      )}
                      {item.list === "Game consoles" && (
                        <button
                          className="cust-btn position-relative"
                          onClick={() => {
                            setChildMenu(!childMenu);
                            setProductNameShow(false);
                            setCountryShow(false);
                            setPackingSizeShow(false);
                          }}
                        >
                          {childMenu ? (
                            <KeyboardArrowRightIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {subcatogory && (
            <>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Sub Category 1
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={catogory2}
                    onChange={onCatogory2}
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setCatogoryShow2(!catogoryShow2);
                      setCatogoryShow(false);
                      setCatogoryShow3(false);
                      setProductNameShow(false);
                      setPackingSizeShow(false);
                      setCountryShow(false);
                      setDecorativeShow(false);
                      setAttributesShow(false);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                </div>
                {catogoryShow2 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setCatogoryShow2(!catogoryShow2)}
                  />
                )}
                <div
                  className={`${
                    catogoryShow2 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setCatogoryShow2(false);
                            setCatogory2(item?.list);
                            setSubcatogory2(true);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>{" "}
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Sub Category Code
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={productSuplierCode}
                    onChange={(e) => setProductSuplierCode(e.target.value)}
                    type="text"
                  />
                </div>
              </div>
            </>
          )}
          {subcatogory2 && (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category 2
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={catogory3}
                  onChange={onCatogory3}
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setCatogoryShow3(!catogoryShow3);
                    setChildMenu(false);
                    setCatogoryShow(false);
                    setCatogoryShow2(false);
                    setProductNameShow(false);
                    setPackingSizeShow(false);
                    setCountryShow(false);
                    setDecorativeShow(false);
                    setAttributesShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              </div>
              {catogoryShow3 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setCatogoryShow3(!catogoryShow3)}
                />
              )}
              <div
                className={`${
                  catogoryShow3 && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setCatogoryShow3(false);
                          setCatogory3(item?.list);
                          setChildMenu(false);
                          setSubcatogory3(true);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Name
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={productName}
                onChange={onProduct}
                type="text"
                disabled={distable ? true : false}
              />
              {prodNameicon && (
                <button
                  className="add_icon cust-btn primary f3"
                  onClick={() => {
                    setProdNameicon(false);
                    setSkuCode("SKU0001");
                    // setProductSuplierCode('VSUP0001');
                    setDistable(true);
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
          {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                            SKU Code
                        </p>
                        <div className='position-relative'>
                            <input
                                placeholder="Required Field"
                                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={skuCode}
                                type='text'
                            />
                        </div>
                    </div> */}
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Product Code
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={productSuplierCode}
                onChange={(e) => setProductSuplierCode(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Decoration Method
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={decorativeName}
                onChange={onDecorativeClick}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDecorativeShow(!decorativeShow);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setColorsShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {decorativeShow && (
              <div
                className="invisible-cont2"
                onClick={() => setDecorativeShow(!decorativeShow)}
              />
            )}
            <div
              className={`${
                decorativeShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item, ind) => {
                return (
                  <div className="d-flex ac-jb hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onDecorativeClick(item?.list);
                        // checkBox(ind);
                      }}
                    >
                      {item?.list}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onDecorativeClick(item?.list);
                      }}
                    >
                      {decorativeName.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Country of Origin
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={country}
                onChange={onCountry}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCountryShow(!countryShow);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {countryShow && (
              <div
                className="invisible-cont2"
                onClick={() => setCountryShow(!countryShow)}
              />
            )}
            <div
              className={`${
                countryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setCountryShow(false);
                        setCountry(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Packaging Size
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={packingSize}
                onChange={onPackingSize}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setPackingSizeShow(!PackingSizeShow);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setCountryShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {PackingSizeShow && (
              <div
                className="invisible-cont2"
                onClick={() => setPackingSizeShow(!PackingSizeShow)}
              />
            )}
            <div
              className={`${
                PackingSizeShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setPackingSizeShow(false);
                        setPackingSize(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              HSN Code
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={hsnType}
                // onChange={onHsnTyoe}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setHsnTypeShow(!hsnTypeShow);
                  setChildMenu2(false);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setDecorativeShow(false);
                  setAttributesShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {hsnTypeShow && (
              <div
                className="invisible-cont2"
                onClick={() => setHsnTypeShow(!hsnTypeShow)}
              />
            )}
            <div
              className={`${
                hsnTypeShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {Hsn_type?.map((item, ind) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      // onClick={() => {
                      //     Dropdown === ind
                      //         ? setChildMenu2(!childMenu2)
                      //         : setDrop(ind);
                      // }}
                      onClick={() => {
                        setHsnTypeShow(false);
                        sethsnType(item?.list);
                        setChildMenu2(false);
                      }}
                    >
                      {item?.list}
                    </button>

                    {/* <button
                                            className="cust-btn position-relative"
                                            onClick={() => {
                                                Dropdown === ind
                                                    ? setChildMenu2(!childMenu2) && setDrop(ind)
                                                    : setDrop(ind);
                                            }}
                                        >
                                            <KeyboardArrowRightIcon />
                                        </button> */}
                  </div>
                );
              })}
            </div>
            {/* {Dropdown === 0 && (
                            <div
                                className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}
                            >
                                <div
                                    className={`${hsnTypeShow && "submenu_2 h-auto sub-2"
                                        } submenu_cont_1 overflow-scroll z-3`}
                                >
                                    {Hsn_type_Child2?.map((item) => {
                                        return (
                                            <div className="d-flex hover-cust">
                                                <button
                                                    className="px-2 cust-btn text-start py-1 w-100"
                                                    onClick={() => {
                                                        setHsnTypeShow(false);
                                                        sethsnType(item?.list);
                                                        setChildMenu2(false);
                                                    }}
                                                >
                                                    {item?.list}
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {Dropdown === 1 && (
                            <div
                                className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}
                            >
                                <div
                                    className={`${hsnTypeShow && "submenu_2 h-auto sub-2"
                                        } submenu_cont_1 overflow-scroll z-3`}
                                >
                                    {Hsn_type_Child3?.map((item) => {
                                        return (
                                            <div className="d-flex hover-cust">
                                                <button
                                                    className="px-2 cust-btn text-start py-1 w-100"
                                                    onClick={() => {
                                                        setHsnTypeShow(false);
                                                        sethsnType(item?.list);
                                                        setChildMenu2(false);
                                                    }}
                                                >
                                                    {item?.list}
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {Dropdown === 2 && (
                            <div
                                className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}
                            >
                                <div
                                    className={`${hsnTypeShow && "submenu_2 h-auto sub-2"
                                        } submenu_cont_1 overflow-scroll z-3`}
                                >
                                    {Hsn_type_Child4?.map((item) => {
                                        return (
                                            <div className="d-flex hover-cust">
                                                <button
                                                    className="px-2 cust-btn text-start py-1 w-100"
                                                    onClick={() => {
                                                        setHsnTypeShow(false);
                                                        sethsnType(item?.list);
                                                        setChildMenu2(false);
                                                    }}
                                                >
                                                    {item?.list}
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )} */}
          </div>
          <div className="w-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Product Description
            </p>
            <textarea
              placeholder="Write a Short Description of the Product"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Add Variants
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={attributesName}
                onChange={onAttribute}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setAttributesShow(!attributesShow);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setColorsShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {attributesShow && (
              <div
                className="invisible-cont2"
                onClick={() => setAttributesShow(!attributesShow)}
              />
            )}
            <div
              className={`${
                attributesShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {Select_list?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onAttributeClick(item?.list);
                        // selectBox(ind);
                      }}
                    >
                      {item?.list}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onAttributeClick(item?.list);
                      }}
                    >
                      {attributesName.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Other Product Information
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={attributesName2}
                onChange={onAttribute2}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setAttributesShow2(!attributesShow2);
                  setAttributesShow(false);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setColorsShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {attributesShow2 && (
              <div
                className="invisible-cont2"
                onClick={() => setAttributesShow2(!attributesShow2)}
              />
            )}
            <div
              className={`${
                attributesShow2 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {Select_list2?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onAttributeClick2(item?.list);
                        // selectBox(ind);
                      }}
                    >
                      {item?.list}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onAttributeClick2(item?.list);
                      }}
                    >
                      {attributesName2.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>

          {attributesName2.includes("Weight") && (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Weight
              </p>
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            </div>
          )}
          {attributesName2.includes("Dimention") && (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Dimention
              </p>
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            </div>
          )}
          {attributesName.includes("Size") && (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Size
              </p>
              <div className="position-relative add">
                <input
                  value={size}
                  onChange={(e) => onSizeChange(e)}
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
                {!addIcon ? (
                  <button
                    className="add_icon cust-btn primary f3"
                    onClick={() => {
                      setAddIcon(true);
                      onSizeClick();
                      setDetails(true);
                      setVarient([...size]);
                    }}
                  >
                    +Add
                  </button>
                ) : (
                  <button
                    className="add_icon cust-btn primary f3"
                    onClick={() => {
                      setAddIcon(true);
                      setDetails(true);
                      setVarient([...size]);
                      onSizeClick();
                    }}
                  >
                    +Add New
                  </button>
                )}
              </div>
            </div>
          )}
          {attributesName.includes("Materials") && (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Materials
              </p>
              <div className="position-relative">
                <input
                  value={meterial}
                  onChange={(e) => setMeterial(e.target.value)}
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
                {!addIcon2 ? (
                  <button
                    className="add_icon cust-btn primary f3"
                    onClick={() => {
                      setAddIcon2(true);
                      // onSizeClick()
                      setDetails(true);
                      // setVarient([...size])
                    }}
                  >
                    +Add
                  </button>
                ) : (
                  <button
                    className="add_icon cust-btn primary f3"
                    onClick={() => {
                      setAddIcon2(true);
                      setDetails(true);
                      // setVarient([...size])
                      // onSizeClick()
                    }}
                  >
                    +Add New
                  </button>
                )}
              </div>
            </div>
          )}
          {/* {attributesName.includes('Materials used in construction') && <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                            Materials used in construction
                        </p>
                        <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                    </div>} */}
          {attributesName.includes("Style") && (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Style
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
                {!addIcon1 ? (
                  <button
                    className="add_icon cust-btn primary f3"
                    onClick={() => {
                      setAddIcon1(true);
                      // onSizeClick()
                      setDetails(true);
                      // setVarient([...size])
                    }}
                  >
                    +Add
                  </button>
                ) : (
                  <button
                    className="add_icon cust-btn primary f3"
                    onClick={() => {
                      setAddIcon1(true);
                      setDetails(true);
                      // setVarient([...size])
                      // onSizeClick()
                    }}
                  >
                    +Add New
                  </button>
                )}
              </div>
            </div>
          )}
          {attributesName.includes("Color") && (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Color
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={colorsName}
                  onChange={onColors}
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setColorsShow(!colorsShow);
                    setCatogoryShow(false);
                    setCatogoryShow2(false);
                    setCatogoryShow3(false);
                    setProductNameShow(false);
                    setCountryShow(false);
                    setPackingSizeShow(false);
                    setDecorativeShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              </div>
              {colorsShow && (
                <div
                  className="invisible-cont2"
                  onClick={() => setColorsShow(!colorsShow)}
                />
              )}
              <div
                className={`${
                  colorsShow && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {colors?.map((item, ind) => {
                  console.log(ind);
                  return (
                    <button
                      className="d-flex ac-jb hover-cust cust-btn w-100 px-2"
                      onClick={() => {
                        ColorSlect(item?.color);
                        settextShow2(true);
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100">
                        {item?.color}
                      </button>
                      {colorsName.includes(item?.color) ? (
                        <Circle style={{ color: item?.color }} />
                      ) : (
                        <CircleOutlined className="primary" />
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
          {details && (
            <div className="w-100 overflow-scroll mt-4">
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 cp fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "50px" }}
                          >
                            {check === 1 ? (
                              <CheckBoxOutlined
                                onClick={() => setCheck(undefined)}
                              />
                            ) : (
                              <CheckBoxOutlineBlank
                                onClick={() => setCheck(1)}
                              />
                            )}
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                            style={{ width: "200px" }}
                          >
                            Variant
                          </p>
                        </div>
                        {/* <div className="mx-2" >
                                                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                                        style={{ width: '100px' }}
                                                    >Available</p>
                                                </div> */}
                        {/* <div className="mx-2" >
                                                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                                        style={{ width: '100px' }}
                                                    >SKU</p>
                                                </div> */}
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div
                          className="d-flex ac-jc mx-2"
                          style={{ width: "50px" }}
                        >
                          <button className="mx-2 d-flex ac-jc cust-btn">
                            {checkBox || check == 1 ? (
                              <CheckBoxOutlined
                                className="primary cp"
                                onClick={() => setCheckBox(false)}
                              />
                            ) : (
                              <CheckBoxOutlineBlank
                                className="primary cp"
                                onClick={() => setCheckBox(true)}
                              />
                            )}
                          </button>
                        </div>
                        <div className="mx-2">
                          <div
                            className="num-cls-btn d-flex overflow-scroll p-2"
                            style={{ width: "200px" }}
                          >
                            {/* <p className='f2 fs-12 px-3 fs-sm-13 fs-md-14 fs-lg-15 text-dark'>{[varient, colorsName, meterial]}</p> */}
                            <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                              XL/Blue/Classic
                            </p>
                          </div>
                        </div>
                        {/* <div className="mx-2" >
                                                    <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                        type="text"
                                                        placeholder='100'
                                                        style={{ width: '100px' }}
                                                    />
                                                </div> */}
                        {/* <div className="mx-2" >
                                                    <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                        type="text"
                                                        value={meterial}
                                                        placeholder='150'
                                                        style={{ width: '100px' }}
                                                    />
                                                </div> */}
                        <button className="mx-2 d-flex ac-jc cp cust-btn">
                          <DeleteForever
                            className="primary"
                            onClick={() => setDetails(false)}
                          />
                        </button>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div>
          )}
        </div>
      </div>
      <RequestImageUplodeComp
        textShow2={textShow2}
        colors={colors}
        colorsName={colorsName}
      />
    </div>
  );
};

export default RequestFormComp;
