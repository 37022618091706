import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import FormComp from "../AddNewProductComps/FormComp";
import { Col } from "react-bootstrap";
import { currency_list, DefaultTax, sub_menu } from "../../redux/api/DummyJson";
import PorejectPoppup from "../PODetails/PorejectPoppup";

const EstimateHeadComp = ({
  setAddContComp,
  poppupAddressToggle,
  collaboratClick,
  onCollaborat,
}) => {
  const [dropDown, setDropDown] = useState(undefined);

  // input states

  const [billingAddress, setBillingAddress] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [mainShipAddress, setMainShipAddress] = useState("");
  const [mainShipAddress2, setMainShipAddress2] = useState("");
  const [terms, setTerms] = useState("");
  const [currency, setCurrency] = useState("");
  const [defaultTax, setDefaultTax] = useState("");
  const [statust, setStatust] = useState("");

  const status = [
    {
      list: "Artwork Completed",
    },
    { list: "Artwork Approved" },
    {
      list: "Artwork Rejected",
    },

    { list: "Artwork Request" },
  ];
  const statushandl = (e) => {
    setStatust(e.target.value);
    if (e.target.value == "Artwork Rejected") {
      setRejectPoppup(true);
    } else {
      setRejectPoppup(false);
    }
    console.log("statustss", statust);
  };

  console.log("statust", statust);

  const [rejectpoppup, setRejectPoppup] = useState(false);

  const rejPopFun = () => {
    setRejectPoppup(!rejectpoppup);
  };
  return (
    <>
      {rejectpoppup && (
        <PorejectPoppup
          rejPopFun={rejPopFun}
          setRejectPoppup={setRejectPoppup}
        />
      )}
      <div className="w-100">
        <div className="w-100 d-flex ac-je">
          <button
            onClick={onCollaborat}
            className="d-flex m-3 pointerView btn_border py-2 px-3 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            - {collaboratClick ? "Collapse Info" : "Expand Info"}
          </button>
        </div>
        <div className="d-flex ">
          <div className="d-flex flex-column flex-md-row flex-wrap w-50">
            <Col sm={12} md={12} lg={4} className="me-md-3">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}>
                Status
              </p>

              <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                <option>Over All</option>
                <option>In Process</option>
                <option>Estimate Ready</option>
                <option>Estimate Accepted</option>
                <option>Estimate Rejected</option>
              </select>
            </Col>
            <Col sm={12} md={12} lg={4} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}>
                Estimate Date
              </p>

              <input
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                type="date"
              />
            </Col>
          </div>

          <Col sm={4} md={4} lg={2} className="">
            <div className="d-flex flex-column flex-md-row flex-wrap m-2">
              <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Sales Status
              </p>
              <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <option>In Process</option>
                <option>Move to sales order</option>
              </select>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} className="">
            <div className="d-flex flex-column flex-md-row flex-wrap m-2">
              <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Mockup Status
              </p>
              <select
                placeholder="Status"
                onChange={statushandl}
                value={statust}
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                {status?.map((item, ind) => {
                  return (
                    <option key={item?.list} value={item?.list}>
                      {item?.list}
                    </option>
                  );
                })}
                {/* <option>Rejected</option>
            <option>In process</option>
            <option>Request</option> */}
              </select>
            </div>
          </Col>
        </div>
        {collaboratClick && (
          <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}>
              {" "}
              Introduction
            </p>
            <textarea
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}
        {collaboratClick && (
          <div className="w-100 d-flex as-jb gap-3 flex-column flex-md-row">
            <div className="d-flex as-jb gap-3 w-100 w-md-50 res-flex">
              {/* <div className=''> */}
              <div className="w-100">
                <div className="w-100 drop-dow position-relative ">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}>
                    Billing Address
                  </p>
                  <div className="d-flex position-relative">
                    <input
                      type="text"
                      value={billingAddress}
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down3 cust-btn "
                      onClick={() => setDropDown(1)}>
                      <KeyboardArrowDownIcon className="primary" />
                    </button>
                    <input
                      onClick={() => setAddContComp(true)}
                      type="button"
                      value={"+"}
                      placeholder="Required Field"
                      className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                    />
                    {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> */}
                  </div>
                  {dropDown === 1 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 1 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-2`}>
                    {sub_menu?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 cust-btn text-start py-1 w-100"
                            onClick={() => {
                              setBillingAddress(item?.list);
                              setDropDown(undefined);
                            }}>
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-3 w-100 drop-dow position-relative ">
                  <div className="d-flex position-relative">
                    <input
                      type="text"
                      value={billingAddress2}
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down3 cust-btn "
                      onClick={() => setDropDown(3)}>
                      <KeyboardArrowDownIcon className="primary" />
                    </button>
                    <input
                      type="button"
                      value={"+"}
                      onClick={poppupAddressToggle}
                      placeholder="Required Field"
                      className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                    />
                    {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> */}
                  </div>
                  {dropDown === 3 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 3 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-2`}>
                    {sub_menu?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                            onClick={() => {
                              setBillingAddress2(item?.list);
                              setDropDown(undefined);
                            }}>
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-100 drop-dow mt-3">
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    3SK billa
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Forum Mall
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Arcot road,Vadapalani
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Chennai
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Chennai, India
                  </p>
                </div>
                <div className="w-100 drop-dow mt-3">
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    sakthivelsninos@gmail.com
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    W: 7358144805
                  </p>
                </div>
              </div>
              <div className="w-100">
                <div className="w-100 drop-dow position-relative ">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}>
                    Main Shipping Address
                  </p>
                  <div className="d-flex position-relative">
                    <input
                      type="text"
                      value={mainShipAddress}
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down3 cust-btn "
                      onClick={() => setDropDown(2)}>
                      <KeyboardArrowDownIcon className="primary" />
                    </button>
                    <input
                      onClick={() => setAddContComp(true)}
                      type="button"
                      value={"+"}
                      placeholder="Required Field"
                      className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                    />
                    {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> */}
                  </div>
                  {dropDown === 2 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 2 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-2`}>
                    {sub_menu?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                            onClick={() => {
                              setMainShipAddress(item?.list);
                              setDropDown(undefined);
                            }}>
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-100 drop-dow position-relative mt-3">
                  <div className="d-flex position-relative">
                    <input
                      type="text"
                      value={mainShipAddress2}
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down3 cust-btn "
                      onClick={() => setDropDown(4)}>
                      <KeyboardArrowDownIcon className="primary" />
                    </button>
                    <input
                      type="button"
                      value={"+"}
                      onClick={poppupAddressToggle}
                      placeholder="Required Field"
                      className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                    />
                    {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> */}
                  </div>
                  {dropDown === 4 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 4 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-2`}>
                    {sub_menu?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                            onClick={() => {
                              setMainShipAddress2(item?.list);
                              setDropDown(undefined);
                            }}>
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-100 d-flex flex-column flex-sm-row">
                  <div className="ps-sm-4 w-100 drop-dow mt-3">
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      3SK billa
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Forum Mall
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Arcot road,Vadapalani
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Chennai
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      Chennai, India
                    </p>
                  </div>
                </div>
                <div className="w-100 d-flex flex-column flex-sm-row">
                  <div className="ps-sm-4 w-100 drop-dow mt-3">
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      sakthivelsninos@gmail.com
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      W: 7358144805
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex as-jb gap-3 flex-column flex-md-row w-100 w-md-50 res-flex">
              <div className="w-100">
                <div className="d-flex flex-wrap ac-jb flex-m-r">
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}>
                      Terms
                    </p>
                    <div className="position-relative">
                      <input
                        value={terms}
                        type="text"
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      <button
                        onClick={() => setDropDown(5)}
                        className="drop_down cust-btn">
                        <KeyboardArrowDownIcon />
                      </button>
                    </div>
                    {dropDown === 5 && (
                      <div
                        className="invisible-cont2 z-1"
                        onClick={() => setDropDown(undefined)}
                      />
                    )}
                    <div
                      className={`${
                        dropDown === 5 && "submenu_1"
                      } submenu_cont_1 overflow-scroll z-2`}>
                      {sub_menu?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                              onClick={() => {
                                setTerms(item?.list);
                                setDropDown(undefined);
                              }}>
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}>
                      Currency
                    </p>
                    <div className="position-relative">
                      <input
                        type="text"
                        value={"INR"}
                        placeholder="Required Field"
                        disabled
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      {/* <button
                      className="drop_down cust-btn"
                      //   onClick={() => setDropDown(6)}
                    >
                      <KeyboardArrowDownIcon />
                    </button> */}
                    </div>
                    {dropDown === 6 && (
                      <div
                        className="invisible-cont2 z-1"
                        onClick={() => setDropDown(undefined)}
                      />
                    )}
                    <div
                      className={`${
                        dropDown === 6 && "submenu_1 h-auto"
                      } submenu_cont_1 overflow-scroll z-2`}>
                      {currency_list?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                              onClick={() => {
                                setCurrency(item?.list);
                                setDropDown(undefined);
                              }}>
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}>
                      In Hands Date
                    </p>
                    <div className="position-relative">
                      <input
                        type="date"
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      />
                      {/* <button className='drop_down cust-btn'>
                                        <KeyboardArrowDownIcon />
                                    </button> */}
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}>
                      Default Margin
                    </p>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      />
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}>
                      Tax Type
                    </p>
                    <div className="position-relative">
                      <input
                        value={defaultTax}
                        type="text"
                        disabled
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      <button
                        onClick={() => setDropDown(7)}
                        className="drop_down cust-btn">
                        <KeyboardArrowDownIcon />
                      </button>
                    </div>
                    {dropDown === 7 && (
                      <div
                        className="invisible-cont2 z-1"
                        onClick={() => setDropDown(undefined)}
                      />
                    )}
                    <div
                      className={`${
                        dropDown === 7 && "submenu_1"
                      } submenu_cont_1 overflow-scroll z-2 h-auto`}>
                      {DefaultTax?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                              onClick={() => {
                                setDefaultTax(item?.list);
                                setDropDown(undefined);
                              }}>
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}>
                      Customer PO
                    </p>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

export default EstimateHeadComp;
