// @ts-nocheck
import React, { useState } from "react";
// import { StickyTable, Row, Cell } from "react-sticky-table";
import { StickyTable, Row, Cell } from "react-sticky-table";
import PrintIcon from "@mui/icons-material/Print";
import { savepopupProduction } from "../../redux/slice/popupget";
import { useDispatch } from "react-redux";

const ProductionTable = () => {
  const [stages, setStages] = useState("Created");

  const dispatch = useDispatch();

  const onClickPopupShowHandler = () => {
    dispatch(savepopupProduction("export report"));
  };

  const onChangeStageHandler = (e) => {
    setStages(e.target.value);
    console.log(e.target.value);
  };

  return (
    <div style={{ width: "100%", maxHeight: "400px", marginTop: "30px" }}>
      <StickyTable leftStickyColumnCount="2">
        <Row>
          <Cell className="th-sticky bg-primar text-white ">Supplier</Cell>
          <Cell className="th-sticky bg-primar text-white ">PO</Cell>
          <Cell className="th-sticky bg-primar text-white ">Status</Cell>
          <Cell className="th-sticky bg-primar text-white ">Stage</Cell>
          <Cell className="th-sticky bg-primar text-white ">Proof</Cell>
          <Cell className="th-sticky bg-primar text-white ">Client</Cell>
          <Cell className="th-sticky bg-primar text-white ">Project Name</Cell>
          <Cell className="th-sticky bg-primar text-white ">Project#</Cell>
          <Cell className="th-sticky bg-primar text-white ">Next Action</Cell>
          <Cell className="th-sticky bg-primar text-white ">NA Date</Cell>
          <Cell className="th-sticky bg-primar text-white ">IH Date</Cell>
          <Cell className="th-sticky bg-primar text-white ">PS</Cell>
        </Row>
        <Row>
          <Cell className="td-sticky primary1">Cell 2</Cell>
          <Cell className="td-sticky primary1">Cell 1</Cell>
          <Cell className="td-sticky text-center rounded-2 text-white pinks f3">
            Problem
          </Cell>
          <Cell className="td-sticky primary1">
            <select
              onChange={(e) => onChangeStageHandler(e)}
              name=""
              className={`border-0 py-2 rounded-1 px-1 ${(stages == "Created" && "bg-soft-primary") ||
                (stages == "Submitted" && "bg-lt-pending") ||
                (stages == "Confirmed" && "bg-soft-gray") ||
                (stages == "Shipped" && "bg-lt-ready") ||
                (stages == "Ready for Billing" && "bg-lt-primary") ||
                (stages == "Closed" && "bg-lt-approved") ||
                (stages == "Billed" && "bg-lt-blue2")
                }`}
              id=""
            >
              <option value="Created">Created</option>
              <option value="Submitted">Submitted</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Shipped">Shipped</option>
              <option value="Ready for Billing">Ready for Billing</option>
              <option value="Closed">Closed</option>
              <option value="Billed">Billed</option>
            </select>
          </Cell>
          <Cell className="td-sticky primary1 text-center">
            <button className="cust-btn primary">
              <PrintIcon />
            </button>
          </Cell>
          <Cell className="td-sticky primary1">Cell 1</Cell>
          <Cell className="td-sticky primary1">Cell 2</Cell>
          <Cell className="td-sticky primary1">Cell 1</Cell>
          <Cell className="td-sticky primary1">Cell 2</Cell>
          <Cell className="td-sticky primary1">Cell 1</Cell>
          <Cell className="td-sticky primary1">Cell 2</Cell>
          <Cell className="td-sticky primary1">Cell 2</Cell>
        </Row>
        <Row>
          <Cell className="td-sticky primary1">Cell 2</Cell>
          <Cell className="td-sticky primary1">Cell 1</Cell>
          <Cell className="td-sticky text-center rounded-2 approved f3">
            OK
          </Cell>
          <Cell className="td-sticky primary1">
            <select
              onChange={(e) => onChangeStageHandler(e)}
              name=""
              className={`border-0 py-2 rounded-1 px-1 ${(stages == "Created" && "bg-soft-primary") ||
                (stages == "Submitted" && "bg-lt-pending") ||
                (stages == "Confirmed" && "bg-soft-gray") ||
                (stages == "Shipped" && "bg-lt-ready") ||
                (stages == "Ready for Billing" && "bg-lt-primary") ||
                (stages == "Closed" && "bg-lt-approved") ||
                (stages == "Billed" && "bg-lt-blue2")
                }`}
              id=""
            >
              <option value="Created">Created</option>
              <option value="Submitted">Submitted</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Shipped">Shipped</option>
              <option value="Ready for Billing">Ready for Billing</option>
              <option value="Closed">Closed</option>
              <option value="Billed">Billed</option>
            </select>
          </Cell>
          <Cell className="td-sticky primary1 text-center">
            <button
              onClick={() => onClickPopupShowHandler()}
              className="cust-btn primary"
            >
              <PrintIcon />
            </button>
          </Cell>
          <Cell className="td-sticky primary1">Cell 1</Cell>
          <Cell className="td-sticky primary1">Cell 2</Cell>
          <Cell className="td-sticky primary1">Cell 1</Cell>
          <Cell className="td-sticky primary1">Cell 2</Cell>
          <Cell className="td-sticky primary1">Cell 1</Cell>
          <Cell className="td-sticky primary1">Cell 1</Cell>
          <Cell className="td-sticky primary1">Cell 2</Cell>
        </Row>
      </StickyTable>
    </div>
  );
};
export default ProductionTable;
