import React from "react";

const CollaborateLists = () => {
  return (
    <div className="home_section trans px-4 py-2">
      <p>CollaborateList</p>
    </div>
  );
};

export default CollaborateLists;
