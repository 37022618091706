import React from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const CustomerContact = ({ list }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <div className="d-flex flex-wrap mt-4 mx-3 details_tab f2">
      <Col lg={12} xl={12} xs={12} className={""}>
        <div className="d-flex mb-3">
          <select className="w-100  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2">
            <option value={0}>All</option>
            <option value={1}>Executive</option>
            <option value={2}>Administration</option>
            <option value={3}>Other</option>
          </select>
        </div>
      </Col>
      <Col lg={12} xl={12} xs={12} className={""}>
        <div className="border-bottom w-100">
          <h5 className="primary ">Executive</h5>
        </div>
        <div className="d-flex gap-2 mt-3 mb-2">
          <h6 className="primary1 mt-1">Sakthi Cli </h6>
          <span class="bg_primary text-white rounded p-1 fs-12 ">Primary</span>
        </div>
        <p>{"Founder"}</p>
        <label className="primary1 w-100">email@gmail.com</label>
        <label className="w-100">W 7358144805</label>
      </Col>
      <Col lg={12} xl={12} xs={12} className={"mt-3"}>
        <div className="border-bottom w-100">
          <h5 className="primary ">Administration</h5>
        </div>
        <div className="d-flex gap-2 mt-3 mb-2">
          <h6 className="primary1 mt-1">Viswa Cli </h6>
          {/* <span class="bg-info text-white rounded p-1 fs-12">Primary</span> */}
        </div>
        <p>{"Founder"}</p>
        <label className="primary1 w-100">email@gmail.com</label>
        <label className="w-100">W 7358144806</label>
      </Col>
      <Col lg={12} xl={12} xs={12} className={"mt-3"}>
        <div className="border-bottom w-100">
          <h5 className="primary ">Other</h5>
        </div>
        <div className="d-flex gap-2 mt-3 mb-2">
          <h6 className="primary1 mt-1">Krishna Cli </h6>
          {/* <span class="bg-info text-white rounded p-1 fs-12">Primary</span> */}
        </div>
        <p>{"Founder"}</p>
        <label className="primary1 w-100">email@gmail.com</label>
        <label className="w-100">W 7358144807</label>
      </Col>
    </div>
  );
};

export default CustomerContact;
