import React, { useState } from "react";
import AddFilesPopup from "../Popup/AddFilesOverview";
import AddClientAccount from "./AddClientAccount";

const EditShippingPoppup = ({ togglePopup }) => {
  const [addFilesPopup, setAddFilesPopup] = useState(false);
  const [selAddress, setSelAddress] = useState("");
  const [selShipAcc, setSelShipAcc] = useState("");
  const [selShipTo, setSelShipTo] = useState("client");
  const [addClientAccPopup, setAddClientAccPopup] = useState(false);
  const [boxRadioBtn, setBoxRadioBtn] = useState(undefined)
  const [boxRadioBtn2, setBoxRadioBtn2] = useState(undefined)
  const [boxRadioBtn3, setBoxRadioBtn3] = useState(undefined)


  const addClientAccPopupToggle = () => {
    setAddClientAccPopup(!addClientAccPopup);
  };

  const addFilesPopupToggle = () => {
    setAddFilesPopup(!addFilesPopup);
  };

  const onAddressChange = (e) => {
    console.log(e?.target?.value);
    setSelAddress(e?.target?.value);
  };

  const onShipAccChange = (e) => {
    setSelShipAcc(e?.target?.value);
  };

  const onShipToChange = (e) => {
    setSelShipTo(e?.target?.value);
  };

  return (
    <div className="modal-popup">
      <div onClick={togglePopup} className="back-close" />
      <div className="z_ind_cust center-content-contact2 w-90 w-md-90 py-3" >
        <div className="w-100 d-flex as-jb mt-4 px-md-5 px-3">
          <div className="w-100">
            <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
              Edit Shipping Information
            </p>
            <p className="f1 fs-sm-11 fs-md-11 fs-lg-11 fs-xl-13 fs-xxl-14 gray">
              Sales Order In Hands Date: 2024-01-24
            </p>
          </div>
          <div onClick={togglePopup} className="d-flex as-je gap-2" >
            <button class="cust-btn addbtn_cancel bg-danger text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 rounded">Cancel</button>
            <button class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 rounded">save</button>
          </div>
        </div>
        <div className="overflow-scroll mt-md-4 h_cust_res w-100 px-md-5 px-3 d-flex ac-jc flex-column flex-xl-row gap-3">
          <div className="w-100 px-2 h_cust_res">
            <div className="w-100">
              <div className='d-flex as-jb gap-3 flex-column flex-sm-row my-2'>
                <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Supplier :
                </p>
                <select
                  className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  placeholder="stage "
                >
                  <option> Garg Enterprises </option>
                  <option> 5SK Supplier </option>
                  <option> Anmol Enterprises </option>
                  <option> Flames N Flavours </option>
                  <option> Excel Systems </option>
                  <option> Gift Maker </option>
                  <option> Helix Medicare </option>
                  <option> Nine Dots </option>
                </select>
              </div>
              <div className='d-flex as-jb gap-3 flex-column flex-sm-row my-2'>
                <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Sample Order :
                </p>
                <div className="w-100">
                  <input type="checkbox" className="" />
                </div>
              </div>

              <div className='d-flex as-jb gap-3 flex-column flex-sm-row my-2'>
                <div className="w-100">
                  <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                    In Hands Date :
                  </p>
                </div>
                {/* <div className="w-100"> */}
                <div class="w-100 d-flex ac-js gap-3 me-3 ">
                  <input placeholder="Required Field" type="date" class="editBtn md_cust_w w-100 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                  <label placeholder="Ext." class=" d-flex ac-jc" style={{ width: "43px" }} >
                    <input type="checkbox" className="me-2" /> <p className="f1 gray">Firm</p>
                  </label>
                </div>
              </div>

              <div className="d-flex as-jb gap-3 flex-column flex-sm-row">
                <div className="w-100">
                  <p class="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Ship To :
                  </p>
                </div>
                <div className="w-100 d-flex ac-js">
                  <div className='my-2 w-100' onChange={onShipToChange}>
                    <div onClick={() => { setBoxRadioBtn(1); setSelShipTo('client'); setBoxRadioBtn2(undefined) }} className={`${boxRadioBtn === 1 ? '  border-2' : ' '} d-flex gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}>
                      <button className='d-flex gap-3 ac-js cust-btn' >
                        <div className='radio_btn d-flex ac-jc'>
                          <div className={` ${boxRadioBtn === 1 ? 'sm_radio' : 'sm_radio1'}  `} />
                        </div>
                        <p className='text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>Client (5SK)</p>
                      </button>
                    </div>
                    <div onClick={() => { setBoxRadioBtn(2); setSelShipTo('decor'); setBoxRadioBtn2(1) }} className={`${boxRadioBtn === 2 ? '  border-2 ' : ' '}  d-flex  gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}>
                      <button className=' d-flex gap-3 ac-js cust-btn' >
                        <div className='radio_btn d-flex ac-jc'>
                          <div className={` ${boxRadioBtn2 === 1 || boxRadioBtn2 === 2 || boxRadioBtn === 2 ? 'sm_radio' : 'sm_radio1'}  `} />
                        </div>
                        <p className='text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>Decorator Vendor</p>
                      </button>
                    </div>
                    <div onClick={() => { setBoxRadioBtn(3); setSelShipTo('prod'); setBoxRadioBtn2(3) }} className={`${boxRadioBtn === 3 ? '  border-2 ' : ' '}  d-flex  gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}>
                      <button className=' d-flex gap-3 ac-js cust-btn'>
                        <div className='radio_btn d-flex ac-jc'>
                          <div className={` ${boxRadioBtn2 === 3 || boxRadioBtn2 === 4 || boxRadioBtn === 3 ? 'sm_radio' : 'sm_radio1'}  `} />
                        </div>
                        <p className='text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>Product Supplier</p>
                      </button>
                    </div>
                    <div onClick={() => { setBoxRadioBtn(4); setSelShipTo('hour'); setBoxRadioBtn2(undefined) }} className={`${boxRadioBtn === 4 ? '  border-2 ' : ' '}  d-flex  gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}>
                      <button className=' d-flex gap-3 ac-js cust-btn'>
                        <div className='radio_btn d-flex ac-jc'>
                          <div className={` ${boxRadioBtn === 4 ? 'sm_radio' : 'sm_radio1'}  `} />
                        </div>
                        <p className='text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>Warehouse</p>
                      </button>
                    </div>
                    <div onClick={() => { setBoxRadioBtn(5); setSelShipTo('other'); setBoxRadioBtn2(undefined) }} className={`${boxRadioBtn === 5 ? '  border-2 ' : ' '}  d-flex  gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}>
                      <button className=' d-flex gap-3 ac-js cust-btn'>
                        <div className='radio_btn d-flex ac-jc'>
                          <div className={` ${boxRadioBtn === 5 ? 'sm_radio' : 'sm_radio1'}  `} />
                        </div>
                        <p className='text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>Other Supplier</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {(selShipTo === "client" || selShipTo === "warehouse") && (
                <div className="w-100 as-jb d-flex flex-column flex-sm-row  my-3">
                  <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Address :</p>
                  <div className="w-100">
                    <select
                      onChange={onAddressChange}
                      className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      placeholder="stage"
                    >
                      <option selected disabled>
                        Select Address
                      </option>
                      <option value={"split"}>Split Shipping</option>
                      <option value={"kodambakkam"}>Kodambakkam</option>
                      <option value={"tnagar"}>T-Nagar</option>
                      <option value={"vadapalani"}>Vadapalani, Chennai</option>
                    </select>
                    {selAddress === "split" && (
                      <button
                        onClick={addFilesPopupToggle}
                        className="cust-btn addbtn mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                      >
                        Upload Split Shipping
                      </button>
                    )}
                    {selAddress === "kodambakkam" && (
                      <p className="f1 gray m-2">
                        Basement Floor, Kodambakkam <br />
                        Chennai, <br />
                        600096
                      </p>
                    )}
                    {selAddress === "tnagar" && (
                      <p className="f1 gray m-2">
                        T-Nagar
                        <br />
                        Arcot road,Vadapalani
                        <br />
                        Chennai, TAMIL NADU
                        <br />
                        NINOS125
                      </p>
                    )}
                    {selAddress === "vadapalani" && (
                      <p className="f1 gray m-2">
                        Raahat Plaza
                        <br />
                        Arcot road,Vadapalani
                        <br />
                        Chennai, TAMIL NADU
                        <br />
                        NINOS125
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className="w-100 as-jb w-100 flex-column flex-sm-row  as-jb d-flex my-3">
                <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Shipping Account :</p>
                <div className="w-100">
                  <select
                    onChange={onShipAccChange}
                    className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  >
                    <option selected>Select Account </option>
                    <option value={"supplier"}>Supplier's Account</option>
                    <option value={"our"}>Our Account</option>
                    <option value={"client"}>Client's Account</option>
                    <option value={"other"}>other</option>
                  </select>
                  {selShipAcc === "supplier" && (
                    <input
                      className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      placeholder="Shipping Quote "
                    />
                  )}
                  {selShipAcc === "client" && (
                    <div className="d-flex ac-jc">
                      <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                        <option selected>Sakthi Account (0992ncewjed9)</option>
                        <option selected>5SK Info (Info7728289)</option>
                      </select>
                      <button
                        onClick={addClientAccPopupToggle}
                        className="w-20 cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-12 px-5 rounded"
                      >
                        Add
                      </button>
                    </div>
                  )}
                  {selShipAcc === "our" && (
                    <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                      <option selected>Select Account</option>
                    </select>
                  )}
                  {selShipAcc === "other" && (
                    <>
                      <input
                        className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        placeholder="Shipping Method (Others) "
                      />
                      <input
                        className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        placeholder="Shipping Method (Details) "
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="flex-column flex-sm-row  d-flex ac-js my-3 flex-column flex-sm-row gap-3">
                <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Shipping Method :</p>
                <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                  <option> Ground </option>
                  <option> Same Day </option>
                </select>
              </div>
              <div className=" d-flex ac-js my-3 flex-column flex-sm-row flex-column gap-3 flex-sm-row ">
                <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Supplier Notes :</p>
                <textarea className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
              </div>
            </div>
          </div>
          <div className="w-100 overflow-scroll px-2 h_cust_res" >
            <div
              className="w-100 h_cust_res"
            >
              {selShipTo === "other" && (
                <>
                  <div className="d-flex flex-column flex-sm-row ac-js my-3 ">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Supplier :</p>
                    <select
                      className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      placeholder="stage"
                    >
                      <option> Garg Enterprises </option>
                      <option> 5SK Supplier </option>
                      <option> Anmol Enterprises </option>
                      <option> Flames N Flavours </option>
                      <option> Excel Systems </option>
                      <option> Gift Maker </option>
                      <option> Helix Medicare </option>
                      <option> Nine Dots </option>
                    </select>
                  </div>
                  <div className=" d-flex ac-js my-3 flex-column flex-sm-row ">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Supplier Address :</p>
                    <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                      <option>Required Field</option>
                    </select>
                  </div>
                </>
              )}
              {selShipTo === "decor" && (
                <>
                  <div className=" d-flex ac-js my-3 flex-column flex-sm-row gap-3">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Decorator Vendor Name :</p>
                    <select
                      className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      placeholder="stage "
                    >
                      <option> Garg Enterprises </option>
                      <option> 5SK Supplier </option>
                      <option> Anmol Enterprises </option>
                      <option> Flames N Flavours </option>
                      <option> Excel Systems </option>
                      <option> Gift Maker </option>
                      <option> Helix Medicare </option>
                      <option> Nine Dots </option>
                    </select>
                  </div>
                  <div className=" d-flex ac-js my-3 flex-column flex-sm-row gap-3">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Decorative Address :</p>
                    <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                      <option>Required Field</option>
                    </select>
                  </div>
                  <div className="d-flex my-3 flex-column flex-sm-row gap-3">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Ship To :</p>
                    <div className="w-100">
                      <div onClick={() => { setBoxRadioBtn2(1); setSelShipTo('decor') }} className={`${boxRadioBtn2 === 1 ? '  border-2 ' : ' '}  d-flex  gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}>
                        <button className=' d-flex gap-3 ac-js cust-btn' >
                          <div className='radio_btn d-flex ac-jc'>
                            <div className={` ${boxRadioBtn2 === 1 ? 'sm_radio' : 'sm_radio1'}  `} />
                          </div>
                          <p className='text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>Client (5SK)</p>
                        </button>
                      </div>
                      <div onClick={() => { setBoxRadioBtn2(2); setSelShipTo('decor'); }} className={`${boxRadioBtn2 === 2 ? '  border-2 ' : ' '}  d-flex  gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}>
                        <button className=' d-flex gap-3 ac-js cust-btn' >
                          <div className='radio_btn d-flex ac-jc'>
                            <div className={` ${boxRadioBtn2 === 2 ? 'sm_radio' : 'sm_radio1'}  `} />
                          </div>
                          <p className='text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>Warehouse</p>
                        </button>
                      </div>
                      {/* <fieldset onChange={null}>
                        <input
                          name="shipto"
                          type="radio"
                          // id="client"
                          value="client"
                          defaultChecked
                        />
                        <label for="client" className="f2 mx-3">
                          Client (5SK)
                        </label>
                        <br />
                        <input
                          name="shipto"
                          type="radio"
                          // id="hour"
                          value="warehouse"
                        />
                        <label for="hour" className="f2 mx-3">
                          Warehouse
                        </label>
                      </fieldset> */}
                    </div>
                  </div>
                  <div className=" w-100 as-jb d-flex flex-column flex-sm-row my-3">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Client Address :</p>
                    <div className="w-100">
                      <select
                        onChange={onAddressChange}
                        className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        placeholder="stage "
                      >
                        <option selected disabled>
                          Select Address
                        </option>
                        <option value={"split"}>Split Shipping</option>
                        <option value={"kodambakkam"}>Kodambakkam</option>
                        <option value={"tnagar"}>T-Nagar</option>
                        <option value={"vadapalani"}>Vadapalani, Chennai</option>
                      </select>
                      {selAddress === "split" && (
                        <button
                          onClick={addFilesPopupToggle}
                          className="cust-btn addbtn mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                        >
                          Upload Split Shipping
                        </button>
                      )}
                      {selAddress === "kodambakkam" && (
                        <p className="f1 gray m-2">
                          Basement Floor, Kodambakkam <br />
                          Chennai, <br />
                          600096
                        </p>
                      )}
                      {selAddress === "tnagar" && (
                        <p className="f1 gray m-2">
                          T-Nagar
                          <br />
                          Arcot road,Vadapalani
                          <br />
                          Chennai, TAMIL NADU
                          <br />
                          NINOS125
                        </p>
                      )}
                      {selAddress === "vadapalani" && (
                        <p className="f1 gray m-2">
                          Raahat Plaza
                          <br />
                          Arcot road,Vadapalani
                          <br />
                          Chennai, TAMIL NADU
                          <br />
                          NINOS125
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='d-flex as-jb gap-3 flex-column flex-sm-row my-2'>
                    <div className="w-100 d-flex flex-column flex-sm-row ac-jb">
                      <div className="w-100">
                        <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                          In Hands Date :
                        </p>
                      </div>
                      {/* <div className="w-100"> */}
                      <div class="w-100 d-flex ac-js gap-3 ">
                        <input placeholder="Required Field" type="date" class="editBtn md_cust_w w-100 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                        <label placeholder="Ext." class=" d-flex ac-jc" style={{ width: "43px" }} >
                          <input type="checkbox" className="me-2" /> <p className="f1 gray">Firm</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className=" w-100 as-jb d-flex my-3 flex-column flex-sm-row">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Shipping Account :</p>
                    <div className="w-100">
                      <select
                        onChange={onShipAccChange}
                        className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      >
                        <option selected>Select Account</option>
                        <option value={"supplier"}>Supplier's Account</option>
                        <option value={"our"}>Our Account</option>
                        <option value={"client"}>Client's Account</option>
                        <option value={"other"}>other</option>
                      </select>
                      {selShipAcc === "supplier" && (
                        <input
                          className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          placeholder="Shipping Quote "
                        />
                      )}
                      {selShipAcc === "client" && (
                        <div className="d-flex ac-jc">
                          <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                            <option selected>
                              Sakthi Account (0992ncewjed9)
                            </option>
                            <option selected>5SK Info (Info7728289)</option>
                          </select>
                          <button
                            onClick={addClientAccPopupToggle}
                            className="w-20 cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-12 px-5 rounded"
                          >
                            Add
                          </button>
                        </div>
                      )}
                      {selShipAcc === "our" && (
                        <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                          <option selected>Select Account</option>
                        </select>
                      )}
                      {selShipAcc === "other" && (
                        <>
                          <input
                            className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Shipping Method (Others) "
                          />
                          <input
                            className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Shipping Method (Details) "
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className=" d-flex ac-js my-3 flex-column flex-sm-row gap-4 ">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Shipping Method :</p>
                    <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                      <option> Ground </option>
                      <option> Same Day </option>
                    </select>
                  </div>
                  <div className=" d-flex ac-js my-3 flex-column flex-sm-row gap-4">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Decorator Notes :</p>
                    <textarea className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                  </div>
                </>
              )}
              {selShipTo === "prod" && (
                <>
                  <div className=" d-flex ac-js my-3 flex-column flex-sm-row ">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Product Supplier Name :</p>
                    <select
                      className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      placeholder="stage "
                    >
                      <option> Garg Enterprises </option>
                      <option> 5SK Supplier </option>
                      <option> Anmol Enterprises </option>
                      <option> Flames N Flavours </option>
                      <option> Excel Systems </option>
                      <option> Gift Maker </option>
                      <option> Helix Medicare </option>
                      <option> Nine Dots </option>
                    </select>
                  </div>
                  <div className=" d-flex ac-js my-3 flex-column flex-sm-row ">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Product Address :</p>
                    <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                      <option>Required Field</option>
                    </select>
                  </div>
                  <div className=" d-flex  my-3">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Ship To :</p>
                    <div className="w-100">
                      <div onClick={() => { setBoxRadioBtn2(3); setSelShipTo('prod') }} className={`${boxRadioBtn2 === 3 ? '  border-2 ' : ' '}  d-flex  gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}>
                        <button className=' d-flex gap-3 ac-js cust-btn' >
                          <div className='radio_btn d-flex ac-jc'>
                            <div className={` ${boxRadioBtn2 === 3 ? 'sm_radio' : 'sm_radio1'}  `} />
                          </div>
                          <p className='text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>Client (5SK)</p>
                        </button>
                      </div>
                      <div onClick={() => { setBoxRadioBtn2(4); setSelShipTo('prod'); }} className={`${boxRadioBtn2 === 4 ? '  border-2 ' : ' '}  d-flex  gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}>
                        <button className=' d-flex gap-3 ac-js cust-btn' >
                          <div className='radio_btn d-flex ac-jc'>
                            <div className={` ${boxRadioBtn2 === 4 ? 'sm_radio' : 'sm_radio1'}  `} />
                          </div>
                          <p className='text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>Warehouse</p>
                        </button>
                      </div>
                    </div>
                    {/* <div className="w-100">
                      <fieldset onChange={null}>
                        <input
                          name="shipto"
                          type="radio"
                          // id="client"
                          value="client"
                          defaultChecked
                        />
                        <label for="client" className="f2 mx-3">
                          Client (5SK)
                        </label>
                        <br />
                        <input
                          name="shipto"
                          type="radio"
                          // id="hour"
                          value="warehouse"
                        />
                        <label for="hour" className="f2 mx-3">
                          Warehouse
                        </label>
                      </fieldset>
                    </div> */}
                  </div>
                  <div className=" w-100 as-jb d-flex flex-column flex-sm-row my-3 gap-3  ">
                    <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Client Address :</p>
                    <div className="w-100">
                      <select
                        onChange={onAddressChange}
                        className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        placeholder="stage "
                      >
                        <option selected disabled>
                          Select Address
                        </option>
                        <option value={"split"}>Split Shipping</option>
                        <option value={"kodambakkam"}>Kodambakkam</option>
                        <option value={"tnagar"}>T-Nagar</option>
                        <option value={"vadapalani"}>Vadapalani, Chennai</option>
                      </select>
                      {selAddress === "split" && (
                        <button
                          onClick={addFilesPopupToggle}
                          className="cust-btn addbtn mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                        >
                          Upload Split Shipping
                        </button>
                      )}
                      {selAddress === "kodambakkam" && (
                        <p className="f1 gray m-2">
                          Basement Floor, Kodambakkam <br />
                          Chennai, <br />
                          600096
                        </p>
                      )}
                      {selAddress === "tnagar" && (
                        <p className="f1 gray m-2">
                          T-Nagar
                          <br />
                          Arcot road,Vadapalani
                          <br />
                          Chennai, TAMIL NADU
                          <br />
                          NINOS125
                        </p>
                      )}
                      {selAddress === "vadapalani" && (
                        <p className="f1 gray m-2">
                          Raahat Plaza
                          <br />
                          Arcot road,Vadapalani
                          <br />
                          Chennai, TAMIL NADU
                          <br />
                          NINOS125
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='d-flex as-jb gap-3 flex-column flex-sm-row my-2'>
                    <div className="w-100 d-flex ac-jb">
                      <div className="w-100">
                        <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                          In Hands Date :
                        </p>
                      </div>
                      {/* <div className="w-100"> */}
                      <div class="w-100 d-flex ac-js gap-3 ">
                        <input placeholder="Required Field" type="date" class="editBtn md_cust_w w-100 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                        <label placeholder="Ext." class=" d-flex ac-jc" style={{ width: "43px" }} >
                          <input type="checkbox" className="me-2" /> <p className="f1 gray">Firm</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className=" w-100 d-flex as-jb my-3">
                    <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Shipping Account :</p>
                    <div className="w-100">
                      <select
                        onChange={onShipAccChange}
                        className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      >
                        <option selected>Select Account</option>
                        <option value={"supplier"}>Supplier's Account</option>
                        <option value={"our"}>Our Account</option>
                        <option value={"client"}>Client's Account</option>
                        <option value={"other"}>other</option>
                      </select>
                      {selShipAcc === "supplier" && (
                        <input
                          className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          placeholder="Shipping Quote "
                        />
                      )}
                      {selShipAcc === "client" && (
                        <div className="d-flex ac-jc">
                          <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                            <option selected>
                              Sakthi Account (0992ncewjed9)
                            </option>
                            <option selected>5SK Info (Info7728289)</option>
                          </select>
                          <button
                            onClick={addClientAccPopupToggle}
                            className="w-20 cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-12 px-5 rounded"
                          >
                            Add
                          </button>
                        </div>
                      )}
                      {selShipAcc === "our" && (
                        <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                          <option selected>Select Account</option>
                        </select>
                      )}
                      {selShipAcc === "other" && (
                        <>
                          <input
                            className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Shipping Method (Others) "
                          />
                          <input
                            className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Shipping Method (Details) "
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className=" d-flex ac-js my-3 flex-column flex-sm-row ">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Shipping Method :</p>
                    <select className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                      <option> Ground </option>
                      <option> Same Day </option>
                    </select>
                  </div>
                  <div className=" d-flex ac-js my-3 flex-column flex-sm-row ">
                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 w-100">Decorator Notes :</p>
                    <textarea className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust" />
                  </div>
                </>
              )}
              {addFilesPopup && (
                <AddFilesPopup
                  toggleShowPopup={addFilesPopupToggle}
                  popupType={"addClient"}
                />
              )}
              {addClientAccPopup && (
                <AddClientAccount togglePopup={addClientAccPopupToggle} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default EditShippingPoppup;
