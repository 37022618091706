import React from "react";
import { head, logos } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const ShippingTo = () => {
  return (
    <div style={{ backgroundColor: "#e5e5e5", padding: "20px 0px" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div
          className="d-flex flex-wrap align-items-center mt-3  banner"
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            flexWrap: "wrap",
          }}
        >
          <div className="col-md-6">
            <div className="d-flex ">
              <div>
                <img
                  alt="img"
                  src={logos}
                  className=" ps-3"
                  style={{ width: "150px", objectFit: "cover" }}
                />
              </div>

              <div className="common ms-4">
                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  SHIPPPING TO:
                </p>
                <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-50">
                  5SK Designer Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
                  NADU NINOS 125, INDIA
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CUSTOMER PO:
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingTo;
