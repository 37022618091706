import React from "react";
import { animatedImg, searchIcon } from "../assets/img";
import { Notifications, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { notif } from "../redux/api/DummyJson";

const Notification = () => {
  const navigate = useNavigate();
  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} alt="animate_img" className="searchManImg" />
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Notifications
        </p>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex ac-jb flex-md-row flex-column">
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="overflow-scroll p-3">
          <table className="w-100 overflow-scroll text-center  p-2 mt-4 ">
            <tr className="notify  my-2" style={{ backgroundColor: "#f1f7fc" }}>
              <th
                className="py-3 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 "
                style={{ width: "150px" }}
              >
                SI.No
              </th>
              <th
                className="py-3 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 "
                style={{ width: "300px" }}
              >
                {" "}
                Description
              </th>
              <th
                className="py-3 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-nowrap "
                style={{ width: "150px" }}
              >
                Date & Time
              </th>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            {notif?.map((item) => {
              return (
                <tr className="notify ">
                  <td className="py-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1">
                    {item?.id}
                  </td>
                  <td
                    onClick={() => {
                      navigate("/home");
                    }}
                    className="py-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1 pointer"
                    style={{ padding: "10px", height: "100px", width: "300px" }}
                  >
                    {item?.desc}
                  </td>
                  <td className="py-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1 text-nowrap">
                    {item?.date}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default Notification;
