import React, { useEffect, useState } from "react";
import { emptyImg, product, profilePic, uploadIcon } from "../../assets/img";
import Carousel from "react-bootstrap/Carousel";
import { productDetailImg } from "../../redux/api/constants";
import { EditAttributes } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { owl_List, product_image } from "../../redux/api/DummyJson";
import ImageCroper from "../Popup/ImageCroper";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const RightComp = ({ edit, types }) => {
  console.log(types);
  const [logo, setLogo] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [index, setIndex] = useState(0);
  const [imageShow, setImageShow] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const [bigImage, setBigImage] = useState({ image: profilePic, ind: 0 });
  const [fistShow, setFirstShow] = useState(true);

  const [image, setImage] = useState([]);

  const [Indeximage, setIndexImage] = useState("");
  const [imageName, setImageName] = useState("defauld");

  const imageAdd = () => { };
  const removeimg = () => {
    const arr = [...image];
    const index = arr.indexOf();
    arr.splice(Indeximage);
    setImage([...arr]);
    console.log(arr);
  };
  // useEffect((ind) => {
  //     setIndexImage(ind)
  //     console.log(ind)
  // })

  const smallimg = (item) => {
    setSmallImage(item);
    // console.log(item)
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // Slide
  const [file, setFile] = useState([]);
  const [smallimage, setSmallImage] = useState([]);
  const [defaultImage, setDefaultImage] = useState(profilePic);

  const imageUplode = (e) => {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
    console.log("file", file);
  };

  const deleteimg = (e) => {
    const s = file.filter((item, index) => item !== smallimage);

    setFile(s);
    console.log(s, index);
  };

  console.log(smallimage);
  const [ind, setInd] = useState();
  console.log(types);
  // Slide

  const imageSelector = (item, ind) => {
    setBigImage({ image: item, ind: ind });
  };

  useEffect(() => {
    if (croperImage) {
      setTextShow(false);
      setFirstShow(true);

      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallImage(temp);

      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    }
  }, [croperImage]);

  const removeImg = (ind) => {
    let temp = [...smallimage];

    temp.splice(ind, 1);
    setSmallImage(temp);
    if (temp?.length - 1 === 0) {
      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
    } else {
      setBigImage({ image: temp[ind - 1], ind: ind - 1 });
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  return (
    <div className="d-flex gap-2 flex-column ac-jc w-100 mb-5">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="w-100 d-flex flex-column ac-jc pb-4">
        <div className="d-flex ac-js mb-3 gap-3">
          <h4 className="primary">Product Image</h4>
        </div>
        <div className="">
          <div
            className="position-relative"
            onClick={() => smallimage?.length == 0 && toggleImagePopup()}
          >
            <img
              className={"img_up_cont"}
              src={bigImage?.image ? bigImage?.image : uploadIcon}
            />
            {!textShow && edit && (
              <div>
                <ul
                  className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                >
                  <li className="set-default-image">Set&nbsp;Default</li>
                  {smallimage?.length > 1 && (
                    <li
                      className="set-default-image"
                      onClick={() => removeImg(bigImage?.ind)}
                    >
                      Remove
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className="d-flex ac-jc gap-3 w-100">
            {fistShow && (
              <div
                class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                style={{ width: "300px", overflow: "scroll" }}
              >
                {smallimage?.map((item, ind) => {
                  return (
                    <div className="gap-3 d-flex ac-j w-100">
                      <div
                        class="add_img d-flex ac-jc"
                        onClick={() => imageSelector(item, ind)}
                      >
                        {" "}
                        <img src={item} class="cp add_img object-fit-fill" />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {smallimage?.length > 0 && (
              <label
                className={`add_img d-flex ac-jc cp f3 primary fs-3 ${smallimage?.length == 0 && "mt-3"
                  }`}
                onClick={() => toggleImagePopup()}
              >
                +
              </label>
            )}
          </div>
        </div>
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
          Color Image
        </p>
        <div
          className="d-flex gap-3 mt-3 ac-jc flex-wrap"
          style={{ width: "350px" }}
        >
          <div className="d-flex flex-column ac-jc position-relative for_hover">
            <label className="add_img d-flex ac-jc cp f3 primary fs-3 ">
              <img src={product} className="add_img object-fit-contain" />
            </label>{" "}
            <div className="d-flex w-100 justify-content-between position-absolute in_hover">
              <FileDownloadOutlinedIcon />
              <ClearOutlinedIcon />
            </div>
            <p className="f3" style={{ color: "red" }}>
              Red
            </p>
          </div>
          <div className="d-flex flex-column ac-jc position-relative for_hover">
            <label className="add_img d-flex ac-jc cp f3 primary fs-3 ">
              <img src={product} className="add_img object-fit-contain" />
            </label>{" "}
            <div className="d-flex w-100 justify-content-between position-absolute in_hover">
              <FileDownloadOutlinedIcon />
              <ClearOutlinedIcon />
            </div>
            <p className="f3" style={{ color: "blue" }}>
              Blue
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightComp;
