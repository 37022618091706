import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { toggleOn } from "../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import RequestOverViewComp from "../components/RequestDetailComp/RequestOverViewComp";
import VendorPricingComp from "../components/RequestDetailComp/VendorPricingComp";
import PricingBoxComp from "../components/MyProductListComp/PricingBoxComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const RequestListDetaileScreen = () => {
  const [priceBook, setpriceBook] = useState(true);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [tagged, setTagged] = useState(false);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.state);
  const types = location?.state?.type;
  const status = location?.state?.status;
  console.log(types?.place);

  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3 d-flex ac-jb px-4">
        <h5 className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          {types?.place}
        </h5>
        {status === "request" && (
          <div className="mt-md-4 mt-3">
            <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
              Status :
              <span className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 request-text">
                {" "}
                Request
              </span>
            </h5>
          </div>
        )}
        {status === "inprocess" && (
          <div className="mt-md-4 mt-3">
            <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
              Status :
              <span className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 Inprocess-text">
                {" "}
                Inprocess
              </span>
            </h5>
          </div>
        )}
        {status === "accept" && (
          <div className="mt-md-4 mt-3">
            <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
              Status :
              <span className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 Accept-text">
                {" "}
                Accepted
              </span>
            </h5>
          </div>
        )}
        {status === "regected" && (
          <div className="mt-md-4 mt-3">
            <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
              Status :
              <span className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 Regected-text">
                {" "}
                Regected
              </span>
            </h5>
          </div>
        )}
      </div>
      <div className="d-flex mt-2 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc">
          <p
            className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary d-flex align-items-center "
            role={"button"}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />
          </p>
          <div
            onClick={() => {
              setpriceBook(true);
              setTagged(false);
              setPreviousOrder(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              priceBook
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4 text-center`}
          >
            <p
              className={`${
                priceBook ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center `}
            >
              Overview
            </p>
          </div>
          {/* <div
            onClick={() => {
              setPreviousOrder(true);
              setpriceBook(false);
              setTagged(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              previousOrder
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4  text-center`}
          >
            <p
              className={`${
                previousOrder ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center `}
            >
              Pricing
            </p>
          </div> */}
          {/* <button onClick={() => setEdit(!edit)}>
                        edit
                    </button> */}
          {status === "request" && (
            <div className="ms-5">
              <button
                className={
                  edit
                    ? "cust_two_E cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                    : "cust_two_S cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                }
                onClick={() => setEdit(!edit)}
              >
                {edit ? "Submit" : "Edit"}
              </button>
            </div>
          )}
        </div>
        <div className="mt-md-4 mt-3">
          <h5 class="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              {" "}
              INR
            </span>
          </h5>
        </div>
      </div>
      {priceBook && (
        <RequestOverViewComp edit={edit} types={types} status={status} />
      )}
      {/* {previousOrder && <VendorPricingComp types={types} />} */}

      {/* {priceBook && (
        <div className="d-flex as-jb w-100 h-50 mt-5">
          <button
            class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      )} */}
      {/* {previousOrder && (
        <div className="d-flex as-jb w-100 h-50 mt-5">
          <button
            class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => {
              setPreviousOrder(false);
              setpriceBook(true);
            }}
          >
            Back
          </button>
        </div>
      )} */}
      <div className="w-100 mt-5">
        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
          Last updated on August 28, 2023 at 03:10 AM{" "}
        </p>
      </div>
    </div>
  );
};

export default RequestListDetaileScreen;
