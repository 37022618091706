import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const PresentationCopyPopup = ({ toggleCopy, copyPresent }) => {
  const [check, setCheck] = useState(true);
  return (
    <div className="delete-popup">
      <div className="w-90 w-md-40">
        {" "}
        <div className="d-flex ac-jb w-100">
          <h3 className="primary f3 fs-xs-14 fs-sm-16 fs-md-18 fs-lg-20 fs-xl-22 fs-xxl-24">
            Copy Presentation
          </h3>
          <div className="d-flex gap-2">
            <button
              onClick={() => toggleCopy()}
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Cancel
            </button>
            <button
              onClick={() => copyPresent()}
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Copy
            </button>
          </div>
        </div>
        <div className=" mx-3 mt-3">
          <div className="w-100 d-flex as-jb">
            <p
              className="w-50 f3 fs-15 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-18 fs-xxl-20 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Status
            </p>

            <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
              <option>Presentation</option>
            </select>
          </div>
          <div className="w-100 d-flex as-jb mt-2">
            <p
              className="w-50 f3 fs-15 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-18 fs-xxl-20 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Client
            </p>

            <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
              <option>5SK</option>
            </select>
          </div>
          <div className="w-100 d-flex as-jb mt-2">
            <p
              className="w-50 f3 fs-15 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-18 fs-xxl-20 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Client
            </p>

            <input
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              placeholder="New Project Name"
            />
          </div>
          <div
            className="w-100 p-2 mt-3"
            style={{ border: "1px solid #968f8f24", background: "#a9a1a117" }}
          >
            <div className="d-flex ac gap-2 py-1">
              <input
                type={"checkbox"}
                className="mx-5"
                checked={check}
                onClick={() => setCheck(!check)}
              />{" "}
              <h5 className="ac-jc mx-3 f3 fs-15 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-18 fs-xxl-20 primary1">
                All Products
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PresentationCopyPopup;
