import React, { useState } from "react";

const DecorativePricing = () => {
  const [selectedMethod, setSelectedMethod] = useState("screen");
  const [showPricing, setShowPricing] = useState(true);

  return (
    <div className="h-auto mt-4">
      <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Supplier Priority List
      </p>
      <div style={{ width: "250px" }} className="my-3 f2 fs-14">
        <div className="d-flex my-3 ac-jb">
          <label className="">John Rolph Supplier</label>
          <select
            className="cust-btn primary p-1 mx-2"
            style={{ border: "1px solid #07679c", borderRadius: "10px" }}
          >
            <option hidden selected>
              Select
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select>
        </div>
        <div className="d-flex my-3 ac-jb">
          <label className="">5SK Supplier</label>
          <select
            className="cust-btn primary p-1 mx-2"
            style={{ border: "1px solid #07679c", borderRadius: "10px" }}
          >
            <option hidden selected>
              Select
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select>
        </div>
        <div className="d-flex my-3 ac-jb">
          <label className="">Starlight Supplier</label>
          <select
            className="cust-btn primary p-1 mx-2"
            style={{ border: "1px solid #07679c", borderRadius: "10px" }}
          >
            <option hidden selected>
              Select
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select>
        </div>
      </div>

      <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Vendor Pricing
      </p>
      <div className="mt-3">
        <div role="button" className="d-flex">
          <div
            onClick={() => setSelectedMethod("screen")}
            className={
              selectedMethod === "screen"
                ? "mx-3 border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : "mx-3"
            }
          >
            <p
              className={`${
                selectedMethod === "screen" ? "f4 primary" : "f3  gray"
              }  fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 `}
            >
              Screen Printing
            </p>
          </div>
          <div
            onClick={() => setSelectedMethod("uv")}
            className={
              selectedMethod === "uv"
                ? "mx-3 border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : "mx-3"
            }
          >
            <p
              className={`${
                selectedMethod === "uv" ? "f4 primary" : "f3  gray"
              }  fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 `}
            >
              UV Printing
            </p>
          </div>
          <div
            onClick={() => setSelectedMethod("engraving")}
            className={
              selectedMethod === "engraving"
                ? "mx-3 border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : "mx-3"
            }
          >
            <p
              className={`${
                selectedMethod === "engraving" ? "f4 primary" : "f3  gray"
              }  fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 `}
            >
              Engraving
            </p>
          </div>
        </div>
        {selectedMethod === "screen" && (
          <>
            {/* <div className="overflow-scroll mt-4">
              <p
                role="button"
                className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
                John Rolph Supplier
              </p>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}>
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Inches"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="250"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}>
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Stitch"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="250"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div>
            <div className="overflow-scroll mt-4">
              <p
                role="button"
                className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
                5SK Supplier
              </p>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}>
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Inches"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="75"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}>
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}>
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Stitch"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="90"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="180"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div> */}
          </>
        )}
        {selectedMethod === "uv" && (
          <>
            {/* <div className="overflow-scroll mt-4">
              <p
                role="button"
                className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
              >
                Starlight Supplier
              </p>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Inches"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="250"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Stitch"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="250"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div>
            <div className="overflow-scroll mt-4">
              <p
                role="button"
                className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
              >
                Blue Moon Supplier
              </p>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Inches"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="75"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Stitch"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="90"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="180"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div>
            <div className="overflow-scroll mt-4">
              <p
                role="button"
                className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
              >
                Star Supplier
              </p>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Inches"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="75"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Stitch"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="90"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="180"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div> */}
          </>
        )}
        {selectedMethod === "engraving" && (
          <>
            {/* <div className="overflow-scroll mt-4">
              <p
                role="button"
                className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
              >
                Star Supplier
              </p>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Inches"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="75"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Stitch"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="90"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="180"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div>
            <div className="overflow-scroll mt-4">
              <p
                role="button"
                className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
              >
                Blue Moon Supplier
              </p>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Inches"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="75"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="100"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            UOM
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "150px" }}
                          >
                            Inches
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 1 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 2 Rate
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "100px" }}
                          >
                            Color 3 Rate
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={"Stitch"}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="3"
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="90"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="150"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value="180"
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div> */}
          </>
        )}
      </div>
      <button
        class="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mt-4"
        onClick={() => setShowPricing(!showPricing)}
        disabled
      >
        {/* {showPricing ? "Hide Pricing" : "View Pricing"} */}
        Admin Pricing
      </button>
      {showPricing && (
        <div className="overflow-scroll mt-4">
          <table className="w-100">
            <tr className="mt-4">
              <div className="">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        UOM
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "150px" }}
                      >
                        Inches
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 1 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 2 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 3 Rate
                      </p>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr className="mt-2">
              <div className="my-3">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        disabled
                        value={"Inches"}
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        value="3"
                        disabled
                        style={{ width: "150px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        value="75"
                        disabled
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        value="100"
                        disabled
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        value="150"
                        disabled
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
          <table className="w-100">
            <tr className="mt-4">
              <div className="">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        UOM
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "150px" }}
                      >
                        Inches
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 1 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 2 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 3 Rate
                      </p>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr className="mt-2">
              <div className="my-3">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        disabled
                        value={"Stitch"}
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        value="3"
                        disabled
                        style={{ width: "150px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        value="90"
                        disabled
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        value="150"
                        disabled
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        value="180"
                        disabled
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
        </div>
      )}
    </div>
  );
};

export default DecorativePricing;
