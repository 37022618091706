import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import ProfileList from "../components/AddRole/ProfileList";
import ProfileRolesList from "../components/AddRole/ProfileRolesList";
import VendorList from "../components/AddRole/VendorList";
import ProductAdminList from "../components/AddRole/ProductAdminList";
import ClientList from "../components/AddRole/ClientList";
import { useLocation, useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useLazyRoleViewQuery,
  useLazyRoleViewWithTypeQuery,
  useMasterActiveListMutation,
  useRoleCreateQuery,
  useRoleEditMutation,
  useRoleStoreMutation,
} from "../redux/api/api";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { catRolesList } from "../redux/api/DummyJson";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const RoleAddProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const [permissonList, setPermissionList] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [roleNameErr, setRoleNameErr] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryErr, setCategoryErr] = useState(false);
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const { isSuccess: isRoleSuccess, data: roleData } = useRoleCreateQuery();
  const [createRole] = useRoleStoreMutation();
  const [updateRole] = useRoleEditMutation();
  // const [viewRole] = useLazyRoleViewQuery();
  const [viewRole] = useLazyRoleViewWithTypeQuery();
  const [activeMasterListApi] = useMasterActiveListMutation();

  const getMasterList = () => {
    let formdata = new FormData();
    formdata.append("type", 7);
    activeMasterListApi(formdata)
      .unwrap()
      .then((res) => {
        setCategoryList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getMasterList();
  }, []);

  const getView = () => {
    let data = {
      type: 1,
    };

    viewRole({ id: location?.state?.data?.id, payload: data })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = [];
          setRoleName(res?.editData?.role);
          setCategory(res?.editData?.categoryRole?.id);
          res?.permissions?.map((item, ind) => {
            temp.push(item);
          });
          setPermissions(temp);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const rolefunction = (id) => {
    let roleId = [...permissions];

    if (roleId.includes(id)) {
      let ids = roleId.indexOf(id);
      roleId.splice(ids, 1);
      setPermissions(roleId);
    } else {
      roleId.push(id);
      setPermissions(roleId);
    }
  };

  const submitHandle = () => {
    if (roleName?.length == 0 || category?.length == 0) {
      setRoleNameErr(true);
      setCategoryErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("role", roleName);
      formdata.append("type", 1);
      formdata.append("department_id", category);

      permissions?.map((item, ind) => {
        formdata.append(`permissions[${ind}]`, item);
      });

      dispatch(saveLoader(true));
      setBtn(true);
      if (type == "edit") {
        formdata.append("_method", "PUT");
        updateRole({ id: location?.state?.data?.id, payload: formdata })
          .unwrap()
          .then((res) => {
            toast.success(res?.data?.message || res?.message);
            dispatch(saveLoader(false));
            setBtn(false);
            if (res?.status == "success") {
              navigate("/role-profile");
            }
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err");
          });
      } else {
        createRole(formdata)
          .unwrap()
          .then((res) => {
            toast.success(res?.data?.message || res?.message);
            dispatch(saveLoader(false));
            setBtn(false);
            if (res?.status == "success") {
              navigate("/role-profile");
            }
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err");
          });
      }
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandle();
    }
  };

  useEffect(() => {
    setPermissionList(roleData?.modules);
  }, [roleData]);

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  return (
    <div className="dashRightView  p-5 home_section trans">
      <div className="d-flex w-95 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={manImage} className="searchManImg" />
      </div>
      <p
        className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon />{" "}
        {type == "view" ? "View" : type == "edit" ? "Update" : "Add"} Profile
        Department/ Roles
      </p>
      <div className="w-95 ac-js gap-5 d-flex flex-md-row flex-column mt-4 ">
        <div className="w-20">
          <select
            className="dashTotalDes  w-100 py-2 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 rounded-3 editBtnSelect"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
            disabled={type == "view" ? true : false}
            onKeyDown={keydownHandler}
          >
            <option hidden>Select</option>
            {categoryList?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>

          {category?.length == 0 && categoryErr && (
            <p className="err-input_new mt-1">Select Category</p>
          )}
        </div>
        <div className="w-auto position-relative">
          <img
            src={manImage}
            className="searchManImgMan rounded-4 position-absolute"
          />
          <input
            placeholder="Create Department/Role"
            onChange={(e) => setRoleName(e.target.value)}
            value={roleName}
            disabled={type == "view" ? true : false}
            className="dashTotalDes ps-5 w-lg-40 w-xl-40 py-2 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 rounded-3"
          />
          {roleName?.length == 0 && roleNameErr && (
            <p className="err-input_new mt-1">Enter Department/Role Name</p>
          )}
        </div>
      </div>
      <div className="d-flex flex-wrap">
        {" "}
        {permissonList?.map((item, ind) => {
          return (
            <Col key={ind} className="mb-4" xs={12} sm={6} lg={4} xxl={4}>
              <div className=" mt-4">
                <p
                  className={`f3 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-18 fs-xxl-19 mt-1`}
                >
                  {item?.code}
                </p>

                {item?.permissions?.map((permission, index) => {
                  return (
                    <div
                      className="d-flex mt-4 ms-1"
                      role={"button"}
                      onClick={() =>
                        type == "view" ? false : rolefunction(permission?.id)
                      }
                    >
                      <DoneIcon
                        className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                          permissions?.includes(permission?.id)
                            ? "checkIcon"
                            : "checkIcon1"
                        }`}
                      />
                      <p
                        className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                          permissions?.includes(permission?.id)
                            ? "black"
                            : "primary1"
                        }`}
                      >
                        {permission?.code}
                      </p>
                    </div>
                  );
                })}
              </div>{" "}
            </Col>
          );
        })}
      </div>
      <div className="w-80 mt-5 d-flex flex-md-row flex-column ac-jb">
        {type == "view" ? (
          <p className="f2 primary1 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17">
            There are the roles that were provided for{" "}
            <span className="f2 text-dark ms-md-2 ms-lg-2 ms-xl-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17">
              Digital Marketing.
            </span>
          </p>
        ) : (
          <p className="f2 primary1 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17">
            Once you have updated the Admin roles, kindly press the
            <span className="f2 text-dark ms-md-2 ms-lg-2 ms-xl-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17">
              "{type == "edit" ? "Update" : "Create"} role"
            </span>{" "}
            button.
          </p>
        )}
      </div>

      <div className="w-80 mt-1 d-flex flex-md-row flex-column ac-jb">
        <button
          onClick={() => navigate("/role-profile")}
          className="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        {type !== "view" && permissions?.length > 0 && (
          <button
            className={`cust-btn mt-2 addbtn ms-1 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={submitHandle}
            disabled={btn}
          >
            {type == "edit" ? "Update" : "Create"} Roles
          </button>
        )}
      </div>
    </div>
  );
};

export default RoleAddProfile;
