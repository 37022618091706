import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "../redux/api/api";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotPasswordApi] = useForgotPasswordMutation();
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [btn, setBtn] = useState(false);

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const submitHandle = () => {
    if (email?.length == 0) {
      setEmailErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("email", email);
      setBtn(true);
      dispatch(saveLoader(true));
      forgotPasswordApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          if (res?.data?.status == "success" || res?.status == "success") {
            toast.success(res?.data?.message || res?.message);
            navigate("/");
            console.log("res", res?.data);
          }
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandle();
    }
  };

  return (
    <div className="signInDes d-flex">
      <div className="w-50 h-100 p-4 bg-back-blue">
        <p className="w-100 f4 fs-xs-25 fs-sm-26 fs-md-27 fs-lg-28 fs-xl-29 fs-xxl-30 primary">
          Merchhq
        </p>
        <div className="signLeftDes" />
      </div>
      <div className="w-50 position-relative">
        <div className="viewBoxDesSign1 w-sm-100 w-md-90 w-lg-80 w-xl-70 p-5 rounded-5 bg-white">
          <p className="w-100 f4 fs-sm-26 fs-md-32 fs-lg-33 fs-xl-34 fs-xxl-35 primary">
            Forgot Password
          </p>
          <div className="mt-4">
            <p className="f2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-17">
              Enter your email address
            </p>
            <input
              placeholder="Username or email address"
              className="editBtn w-100 mt-2 f2 py-2 ps-2 rounded-3 fs-xs-12 fs-sm-13 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              onKeyDown={keydownHandler}
            />
            {email?.length == 0 && emailErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Email ID</p>
              </div>
            )}

            {email?.length > 0 && validEmail.test(email) == false && (
              <div className="d-flex gap-1 mt-2">
                {" "}
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Valid Email ID</p>
              </div>
            )}
          </div>
          <button
            // onClick={() => navigate("/reset-password")}
            onClick={() => submitHandle()}
            disabled={btn}
            className={`cust-btn addbtn w-100 mt-4 mt-sm-4 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
