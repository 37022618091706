import React, { useState } from "react";
import { danger, product } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Close } from "@mui/icons-material";
import CheckingPricePoppup from "../EstimateComp/Poppup/CheckingPricePoppup";
import ImageUpload from "../EstimateComp/Poppup/ImageUpload";
import ImageView from "../EstimateComp/Poppup/ImageView";
import PresntationImage from "./PresntationImage";
import PresentationEditTable from "./PresentationEditTable";
import PresentationPricingTable from "./PresentationPricingTable";
import AddArtWorker from "./AddArtWorker";
import AddFilesPopup from "../Popup/AddFilesOverview";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sub_menu } from "../../redux/api/DummyJson";

const PresentationEditScreen = () => {
  const navigate = useNavigate();
  const [productName, setProductName] = useState("Brand Shirt");
  const [description, setDescription] = useState(
    "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop*Keeps liquid Hot & Cold: 12H-18Hr"
  );
  const [warnings, setWarnings] = useState([]);
  const [personalCom, setPersonalCom] = useState("");
  const [personalComs, setPersonalComs] = useState([]);
  const [addClient, setAddClient] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);
  const [addArt, setAddArt] = useState(false);
  const [priceCharge, setPriceCharge] = useState([]);
  const [runCharge, setRunCharge] = useState([]);

  const addWarn = () => {
    let temp = [...warnings];

    temp.push({ warn: "" });
    setWarnings(temp);
  };

  const removeWarn = (e, ind) => {
    let temp = [...warnings];
    temp.splice(ind, 1);
    setWarnings(temp);
  };

  const handleChangeWarn = (e, index) => {
    let temp = [...warnings];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setWarnings(temp);
  };

  const addPost = () => {
    if (personalCom?.length > 0) {
      let temp = [...personalComs];

      temp.push({
        name: "Roger",
        command: personalCom,
        date: moment(new Date()).format("YYYY-MM-DD"),
      });

      setPersonalCom("");
      setPersonalComs(temp.reverse());
    }
  };

  const removePost = (item, index) => {
    let temp = [...personalComs];
    temp.splice(index, 1);
    setPersonalComs(temp);
  };

  const artWork = () => {
    toggleShowPopup();
  };

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };

  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };

  const [dropdown, setDropDown] = useState(null);
  return (
    <div className="dashRightView p-5">
      {/* {checkPricePoppup && <CheckingPricePoppup poppupAddressToggle={poppupAddressToggle} />}
            {imageUpload && <ImageUpload poppupImageToggle={poppupImageToggle} />}
            {imageViewPoppup && <ImageView poppupImageView={poppupImageView} />} */}
      <div className="w-100 d-flex ac-jb px-4">
        <div>
          <p className="f1 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23">
            Edit Item
          </p>
        </div>
        <div className="position-fixed close_position z-3">
          <button
            onClick={() => navigate("/presentation")}
            class="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            Close
          </button>
        </div>
      </div>
      <div className="d-flex as-jb w-100 mt-5 edit_screen_cont res-flex_img gap-5">
        <div className="d-flex flex-wrap w-100 w-md-70 ac-jb res-flex_im flex-m-r">
          <div className="d-flex w-100 gap-3 ac-jb flex-m-r">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Product Name
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
              </div>
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Supplier
              </p>
              <div className="position-relative">
                <input
                  value={"Sample Supplier"}
                  placeholder="Required Field"
                  disabled
                  type={"text"}
                  className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDown(1);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropdown == 1 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropdown == 1 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setDropDown(null);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-xl-30 w-lg-30 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                SKU
              </p>
              <div className="position-relative">
                <p className="w-100 rounded-3 mt-2 py-2 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                  301WH1026
                </p>
              </div>
            </div>
            <div className="w-xl-30 w-lg-30 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Decoration Methods
              </p>

              <div className="position-relative">
                <input
                  value={"Unspecified"}
                  placeholder="Required Field"
                  disabled
                  type={"text"}
                  className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDown(2);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropdown == 2 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropdown == 2 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setDropDown(null);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100">
            <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Description
            </p>
            <div className="position-relative">
              <textarea
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
            </div>
          </div>
          {warnings?.map((item, ind) => (
            <div className="w-100 d-flex align-items-start  align-items-md-center gap-0 gap-md-3 flex-column flex-md-row">
              <button className="black d-flex gap-1 ac-jc cust-btn w-auto text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                <img src={danger} className="danger_icon" />
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  WARNING:
                </p>
              </button>
              <div className="w-100 d-flex ac-jc gap-2">
                <div className="position-relative w-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => handleChangeWarn(e, ind)}
                  />
                </div>
                <button
                  className="cust-btn"
                  onClick={() => removeWarn(item, ind)}
                >
                  <Close className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16" />
                </button>
              </div>
            </div>
          ))}
          <div className="d-flex ac-js gap-2 mt-3 flex-column flex-sm-row w-100 mt-4">
            <button
              class="text-nowrap cust-btn addbtn ms-1 bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
              onClick={() => addWarn()}
            >
              Add Warning
            </button>
            <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-13 fs-xxl-14">
              Please check supplier website for any warnings now shown
            </p>
          </div>
          <div className="d-flex mt-5 ac-jb flex-column flex-md-row w-100">
            <div className="d-flex mt-3 ac-jc gap-2 flex-column flex-sm-row">
              <button
                onClick={() => navigate("/product-library-details")}
                className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
              >
                View Product Page
              </button>
              <button
                onClick={() =>
                  navigate("/product-library-details", {
                    state: { type: "price" },
                  })
                }
                className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
              >
                Check Pricing
              </button>
            </div>
            <div className="mt-md-4 mt-3">
              <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
                Currency :
                <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
                  INR
                </span>
              </h5>
            </div>
          </div>
          <div className="overflow-scroll w-100">
            <PresentationEditTable
              runCharge={runCharge}
              setRunCharge={setRunCharge}
            />
          </div>
          <div className="mt-4">
            <div className="d-flex">
              <input type={"checkbox"} />
              <label className="ms-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                Fixed charges:
              </label>
            </div>
          </div>
          <div className="overflow-scroll w-100 mt-3">
            <PresentationPricingTable
              setPriceCharge={setPriceCharge}
              priceCharge={priceCharge}
            />
          </div>
          <div className="w-100 mt-3">
            <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Price Label:
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
            </div>
          </div>
          <div className="d-flex w-100 gap-3 ac-jb flex-m-r my-3">
            <div className="w-xl-100 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Personal Comment:
              </p>
              <div className="position-relative">
                <textarea
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  style={{
                    position: "absolute",
                    top: -40,
                    zIndex: 100,
                    right: 0,
                  }}
                  class="cust-btn addbtn ms-1 mt-2 bg-primar text-white f2 fs-10 fs-xs-9 fs-sm-9 fs-md-9 fs-lg-9 fs-xl-10 fs-xxl-10 py-2 py-md-2 px-5 rounded"
                >
                  Post
                </button>
              </div>
            </div>
            <div className="w-xl-100 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Private Notes:
              </p>
              <div className="position-relative">
                <textarea
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
              </div>
            </div>
          </div>
        </div>
        <PresntationImage
          artWork={artWork}
          runCharge={runCharge}
          setRunCharge={setRunCharge}
          setPriceCharge={setPriceCharge}
          priceCharge={priceCharge}
        />
        {addClient && (
          <AddArtWorker
            toggleShowPopup={toggleShowPopup}
            setAddArt={setAddArt}
            toggleShowPopups={toggleShowPopups}
          />
        )}
        {AddFilesOverview && (
          <AddFilesPopup
            toggleShowPopup={toggleShowPopups}
            popupType={"addClient"}
          />
        )}
      </div>
    </div>
  );
};

export default PresentationEditScreen;
