import React from "react";
import { head, logos } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const PackingSlip = () => {
  return (
    <div style={{ backgroundColor: "#e5e5e5",padding:"20px 0px" }}   >
      <div className=" " style={{ width: "90%", margin: "auto" }}>
      <div
          className="d-flex flex-wrap align-items-center mt-3  banner"
          style={{
            backgroundImage:
              "url( https://media.geeksforgeeks.org/wp-content/uploads/rk.png)",
            padding: "20px",
            flexWrap: "wrap",
          }}
        >
          <div className="">
            <img
              alt="img"
              src={logos}
              className=" ps-3"
              style={{ width: "150px", objectFit: "cover" }}
            />
          </div>

          <div className="ps-2">
            <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
              Packing Slip
            </h2>
            
          </div>
        </div>
        <div
          className="d-flex w-100 flex-wrap mt-4  me-3 p-4"
          style={{
            backgroundColor: "#fff",
           
            lineHeight: "25px",
          }}
        >
          <div className="col-md-6">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                SHIPPPING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-50">
                5SK Designer Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
                NADU NINOS 125, INDIA
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              PROJECT#
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              210234
            </p>
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CUSTOMER PO
            </p>
          </div>
        </div>
        <div
          className="mt-4"
          style={{
            backgroundColor: "#fff",
          
            padding: "20px",
          }}
        >
          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
            Product:20oz Matt Solid State Himalayan Tumbler
          </p>
          <table className="w-100 mt-3">
            <tr>
              <th className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                DESCRIPTION
              </th>
              <th className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                QTY
              </th>
            </tr>
            <tr>
              <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Color:TBD - Size: TBD
              </td>
              <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                300
              </td>
            </tr>
            <tr>
              <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Color:Blue - Size: M
              </td>
              <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                10
              </td>
            </tr>
            <tr>
              <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Color:TBD - Size: TBD
              </td>
              <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                0
              </td>
            </tr>
            <tr>
              <td className="f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Total Shipped
              </td>
              <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                310
              </td>
            </tr>
          </table>
        </div>
      </div>
     
    </div>
  );
};

export default PackingSlip;
