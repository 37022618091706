import { Outlet } from "react-router-dom";
import ProfileNavBar from "./ProfileNavBar";
import ExampleSideBar from "./ExampleSideBar";
import NavbarHead from "./NavbarHead";
import ProfileNavBar2 from "./ProfileNavBar2";

const SubLayout = () => {
  return (
    <div className="dashboard">
      {/* <NavbarHead /> */}
      <div className="nav_show trans">
        <ProfileNavBar />
      </div>
      <div className="d-none nav_show_mini navBar trans">
        <ProfileNavBar2 />
      </div>
      <Outlet />
    </div>
  );
};

export default SubLayout;
