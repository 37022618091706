import React, { useState } from "react";
import { searchIcon } from "../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { event_category } from "../redux/api/DummyJson";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DownloadIcon from "@mui/icons-material/Download";

const PendingOrderScreen = () => {
  const [activeTab, setActiveTab] = useState("Inprocess");

  const navigate = useNavigate();
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex justify-content-between flex-wrap">
        <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2">
          Pending Order List
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
        <div className="d-flex ac-jb w-xs-100 mt-xs-5 position-relative">
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary me-3">
            From
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mx-3">
            To
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <h5 className="preset_count bold pe-3 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Total Count: 20
          </h5>
          <div className="d-flex jc-ac align-items-center">
            <DownloadIcon className="primary" />
            <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Export
            </p>
            {/* <ListAltIcon className="primary" />
            <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Download List
            </p> */}
          </div>
        </div>
        <div className="d-flex mt-md-0 mt-3 border-search">
          <img alt="img" src={searchIcon} className="searchiConImg" />
          <input
            className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
        </div>
      </div>

      <div className="d-flex mt-3 mb-4 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-4 ">
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">10</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-lg-5 ms-2">
              Filter
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="all">all</option>
            </select>
          </div>
        </div>
        {/* <div className="d-flex mt-4 rk">
          <button
            onClick={() => onTabClick("Inprocess")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Inprocess"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                activeTab === "Inprocess" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Inprocess
            </p>
          </button>
          <button
            onClick={() => onTabClick("Accepted")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Accepted"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                activeTab === "Accepted" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Accepted
            </p>
          </button>
          <button
            onClick={() => onTabClick("Rejected")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Rejected"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                activeTab === "Rejected" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Rejected
            </p>
          </button>
        </div> */}
      </div>
      {/* <div className="d-flex gap-3">
        <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
          <option hidden>Requested PO ID</option>
          <option>Mobile Phone</option>
          <option>Water Bottle</option>
        </select>
        <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
          <option hidden>vendor ID</option>
          <option>Active</option>
          <option>Deactive</option>
        </select>
        <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
          <option hidden>Vendor Name</option>
          <option>Active</option>
          <option>Deactive</option>
        </select>
      </div> */}
      <div className="w-100 overflow-scroll mt-3">
        <table className="w-100">
          <tr>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                S.No
              </p>
            </th>
            {/* <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Order ID
              </p>
            </th>{" "}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Request ID
              </p>
            </th> */}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Order Rep Name
              </p>
            </th>{" "}
            {/* <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor Email
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor Mobile Number
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Total Req Qty
              </p>
            </th> */}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Client Rep Name
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Product Count
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Order Status
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                View
              </p>
            </th>
          </tr>
          {event_category?.map((item, ind) => {
            return (
              <tr className="my-3">
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {ind + 1}
                  </p>
                </th>
                {/* <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    CMHQ001
                  </p>
                </th>{" "}
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    RMHQ001
                  </p>
                </th> */}
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    OR765625
                  </p>
                </th>{" "}
                {/* <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    dhanush@gmail.com
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    9080877665
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    2900
                  </p>
                </th> */}
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    CR896873
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    05
                  </p>
                </th>
                <th>
                  <div className="pb-4">
                    <select className="cust-btn  f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 ">
                      <option>Delivered</option>
                      <option> Reached at Destination</option>
                      <option>Pickup Assigned</option>
                      <option>Pickup Awaited</option>
                    </select>
                  </div>
                </th>
                <th>
                  <button
                    onClick={() => navigate("/pending-detail-view")}
                    className="w-100 text-center bg-transparent border-0"
                  >
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 pb-4">
                      <VisibilityIcon />
                    </p>
                  </button>
                </th>
              </tr>
            );
          })}
        </table>
      </div>
      <div className="d-flex ac-jb w-100 mt-3">
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Previous
        </button>
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default PendingOrderScreen;
