import { Search } from "@mui/icons-material";
import {
  animatedImg,
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ViewCustComp from "../components/Ecommerce/ViewCustComp";
import RequestCustList from "../components/Customers/RequestCustList";
import { useEffect, useState } from "react";
import NonRegisteredReqList from "../components/Customers/NonRegisteredReqList";
import CachedIcon from "@mui/icons-material/Cached";
import { Col } from "react-bootstrap";
import CustomerContact from "./CustomerContact";
import CustomerAddress from "./CustomerAddress";
import CustomerProjects from "./CustomerProjects";
import CustomerFiles from "./CustomerFiles";
import CustomerNote from "./CustomerNote";
import CustomerDetails from "./CustomerDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ViewCustomer = () => {
  const location = useLocation();
  const [regList, setRegList] = useState(false);
  const [slide, setSlide] = useState(false);
  const [list, setList] = useState({});
  const [nonRegList, setNonRegList] = useState(false);
  const [actionBtn, setActionBtn] = useState(false);
  const [detailEdit, setDetailsEdit] = useState(true);
  const [tabBtn, setTabBtn] = useState(4);
  const tabData = (item) => {
    setList(item);
    setSlide(true);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setList((values) => ({ ...values, [name]: value }));
  };
  // console.log("LOCATION_____", location);

  useEffect(() => {
    setRegList(true);
  }, []);

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} alt="animate" className="searchManImg" />
      </div>

      {/* <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Request List
        </p>
        <div className="d-flex w-xs-100 mt-xs-5 position-relative">
          <input
            className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
          <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
        </div>
      </div> */}
      <div className="d-flex mt-2 ac-jb flex-wrap">
        <div className="d-flex mt-3 ">
          <button
            onClick={() => {
              setRegList(true);
              setNonRegList(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              regList
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                regList ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Registered Request List
            </p>
          </button>
          <button
            onClick={() => {
              setRegList(false);
              setNonRegList(true);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              nonRegList
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                nonRegList ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Non-Registered Request List
            </p>
          </button>
        </div>
      </div>

      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="w-100 d-flex flex-md-row flex-column">
          <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <input
              type="text"
              className="w-15 get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
              placeholder="Name"
            />
            <button class="w-17 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
              <select className="bg-transparent border-0 py-3 primary1">
                <option value="1">Order Rep</option>
                <option value="Order Rep Admin1">Order Rep Admin1</option>
                <option value="Order Rep Admin2">Order Rep Admin2</option>
              </select>
            </button>
            <button class="w-17 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
              <select className="bg-transparent border-0 py-3 primary1">
                <option value="1">Client Rep</option>
                <option value="Client Rep Admin1">Client Rep Admin1</option>
                <option value="Client Rep Admin2">Client Rep Admin2</option>
              </select>
            </button>
            <button class="w-17 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
              <select className="bg-transparent border-0 py-3 primary1">
                <option value="1">Status</option>
                <option value="">All</option>
                <option value="">Active</option>
                <option value="">Deactive</option>
              </select>
            </button>
            <button class="w-17 mx-3 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 position-relative">
              <select className="bg-transparent border-0 py-3 primary1 position-absolute top-0">
                <option value="1">Tags</option>
                <option value="">Bottle</option>
                <option value="">Shirts</option>
              </select>
            </button>
          </div>
        </div>
        <div className="d-flex">
          <button className="mx-2 cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <Search />
          </button>
          <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <CachedIcon />
          </button>
        </div>
      </div>

      {/* {location?.pathname !== "/customer-request-list" && <ViewCustComp />} */}
      {regList && <RequestCustList tabData={tabData} />}
      {nonRegList && <NonRegisteredReqList />}
      <div
        style={{
          width: slide ? "50%" : "0px",
          transition: "0.2s all linear",
          position: "fixed",
          right: "0",
          top: 0,
          zIndex: 1111,
          background: "#fff",
        }}>
        <Col className="customer_list_slide px-2">
          <div className="d-flex justify-content-end">
            <div className="mx-2 mt-3">
              <div className="position-relative f3">
                <button
                  className="slide_btn"
                  onClick={() => setActionBtn(!actionBtn)}>
                  Actions
                  <KeyboardArrowDownIcon />
                </button>
                {actionBtn && (
                  <div className="action_btn">
                    <button
                      className="border-0 bg-white text-center"
                      onClick={() => {
                        setDetailsEdit(!detailEdit);
                        setActionBtn(!actionBtn);
                      }}>
                      Edit Details
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mx-2 ">
              <button
                // className="slide_btns"
                className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                style={{ backgroundColor: "#01a0b6" }}
                onClick={() => setSlide(false)}>
                Close
              </button>
            </div>
          </div>
          <div className="mt-3 mx-2 slide_head">
            <h5 className="f4 primary">{list?.name}</h5>
            <div className="d-flex">
              <p className="mt-2 f3">Last Activity: </p>
              <p className="mt-2 mx-1 f1">2023-10-11</p>
              <p className="mt-2 mx-2 primary1">CID #{list?.CustomerId}</p>
            </div>
            <div className="slide_tabs f2">
              <button
                className={
                  tabBtn == 4
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(4)}>
                Details
              </button>
              {/* <button
                className={
                  tabBtn == 1
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(1)}>
                Contacts
              </button> */}
              {list != "ecommerce" && (
                <button
                  className={
                    tabBtn == 2
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                      : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                  }
                  onClick={() => setTabBtn(2)}>
                  Addresses
                </button>
              )}
              {list != "ecommerce" && (
                <button
                  className={
                    tabBtn == 3
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                      : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                  }
                  onClick={() => setTabBtn(3)}>
                  Projects
                </button>
              )}
              <button
                className={
                  tabBtn == 7
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(7)}>
                Order Request ID
              </button>
              {list != "ecommerce" && (
                <button
                  className={
                    tabBtn == 5
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                      : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                  }
                  onClick={() => setTabBtn(5)}>
                  Files
                </button>
              )}
              <button
                className={
                  tabBtn == 6
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(6)}>
                Notes
              </button>
            </div>
            {tabBtn == 1 && <CustomerContact list={list} />}
            {tabBtn == 2 && <CustomerAddress list={list} />}
            {tabBtn == 3 && <CustomerProjects list={list} />}
            {tabBtn == 4 && (
              <CustomerDetails
                list={list}
                detailEdit={detailEdit}
                setDetailsEdit={setDetailsEdit}
                handleChange={handleChange}
              />
            )}
            {tabBtn == 5 && <CustomerFiles list={list} />}
            {tabBtn == 6 && <CustomerNote list={list} />}
            {tabBtn == 7 && (
              <div className="d-flex flex-wrap mt-4 mx-3 details_tab link_projects">
                <Col lg={6} xl={6} xs={12}>
                  <h4 className="f3 fs-15 fs-xs-16 text-nowrap  primary mb-2 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                    Order Request ID
                  </h4>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16  text-nowrap fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>RMHQ001</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16  text-nowrap fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>RMHQ002</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16  text-nowrap fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>RMHQ003</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 text-nowrap  fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>RMHQ004</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 text-nowrap  fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>RMHQ005</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 text-nowrap  fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16  primary1">
                    <p>RMHQ006</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16  text-nowrap fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16  primary1 ">
                    <p>RMHQ007</p>
                  </Link>
                </Col>
                <Col lg={6} xl={6} xs={12}>
                  <h4 className="f3 fs-15 fs-xs-16 text-nowrap  primary mb-2 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                    Order Request Type
                  </h4>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16">
                    <p>Bulk Products</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>E-commerce</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>Preset Product</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>E-commerce</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>Bulk Products</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>Bulk Products</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  ">
                    <p>RMHQ007</p>
                  </Link>
                </Col>
              </div>
            )}
          </div>
        </Col>
      </div>
    </div>
  );
};

export default ViewCustomer;
