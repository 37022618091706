import { useState } from "react";
import ReactDatePicker from "react-datepicker";

const WarHouse = ({ setShipping }) => {
  const [editWar, setEditWar] = useState(false);
  const [date, setDate] = useState("");
  return (
    <div className="searchField w-100 mt-5 bg-white">
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-2">
        WareHouse
      </p>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Total Pack:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="22"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            22
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Available Pack:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="20"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            20
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          From Date:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="Augest 01,2023 09:00AM"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            Augest 01,2023 09:00AM
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          To Date:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="October 29,2023 09:00AM"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            October 29,2023 09:00AM
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Remaining Days:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="90"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            90
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Cost:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="₹100"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            ₹100
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Size:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="20"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            20
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Address:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <textarea
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099,
              Tamil Nadu, India."
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099,
            Tamil Nadu, India.
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Floor, Suit, Unit (optional):
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="3rd Floor"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            3rd Floor
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          City:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="Chennai"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            Chennai
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          State:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <select className="w-50 cust-btn searchFill fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              <option>Tamil Nadu</option>
              <option>Pondichery</option>
              <option>Kerala</option>
            </select>
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            Tamil Nadu
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Pincode:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <input
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="602978"
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            602978
          </p>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Country:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <select className="w-50 cust-btn searchFill fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              <option>India</option>
              <option>America</option>
              <option>France</option>
            </select>
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            India
          </p>
        )}
      </div>
      {/* <div className="w-100 d-flex ac-js mt-3">
        <p className="w-25 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
          Shipping Address:
        </p>
        {editWar && (
          <div className="w-75 ms-3">
            <textarea
              placeholder=""
              className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value="No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099,
              Tamil Nadu, India."
            />
          </div>
        )}
        {!editWar && (
          <p className="w-75 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
            No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099,
            Tamil Nadu, India.
          </p>
        )}
      </div> */}
      <div className="w-100 d-flex ac-jb mt-4">
        <button
          onClick={() => setEditWar(!editWar)}
          className="pointerView cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          {editWar ? "Submit" : "Edit"}
        </button>
        {/* <button
          onClick={null}
          className="pointerView cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Add Shipping Address
        </button> */}
        <div className="w-25 ms-3">
          <select
            placeholder=""
            className="w-100  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
            name=""
            onChange={(e) => setShipping(e.target.value)}>
            <option selected hidden value="">
              Add Shipping Address
            </option>
            <option value="Single">Single Shipping</option>
            <option value="Multiple">Multiple Shipping</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default WarHouse;
