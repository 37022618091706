import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const CustomerAddress = ({ list }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [method, setMethod] = useState(0);

  return (
    <div className="d-flex flex-wrap mt-3 mx-3 details_tab f2 mb-3">
      <Col lg={12} xl={12} xs={12} className={""}>
        <div className="d-flex mb-3">
          <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary mt-3 mx-3">
            Storing Method:
          </p>
          <select
            className="w-50  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
            onChange={(e) => setMethod(e.target.value)}
            value={method}
          >
            <option hidden>Select Method</option>
            <option value={1}>Ware House</option>
            <option value={2}>Shipping</option>
          </select>
        </div>
      </Col>
      {method == 1 && (
        <>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Total Pack</label>
            <p>{"22"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Available Pack</label>
            <p>{"20"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">From Date</label>
            <p>{"Augest 01,2023 09:00AM"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">To Date</label>
            <p>{"October 29,2023 09:00AM"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Remaining Days</label>
            <p>{"90"}</p>
          </Col>{" "}
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Cost</label>
            <p>{"₹100"}</p>
          </Col>{" "}
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Size</label>
            <p>{"20"}</p>
          </Col>{" "}
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Address</label>
            <p>
              {
                "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India."
              }
            </p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Floor, Suit, Unit (optional)</label>
            <p>{"2nd street, Lakshmipuram"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">City</label>
            <p>{"Chennai"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">State</label>
            <p>{"Tamil Nadu"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Pincode</label>
            <p>{"600099"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Country</label>
            <p>{"India"}</p>
          </Col>
        </>
      )}
      {method == 2 &&
        splitLocation[1] !== "enterprises-request-list" &&
        splitLocation[1] !== "enterprises-list" && (
          <>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">Name</label>
              <p>{"Json"}</p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">Email</label>
              <p>{"json@gmail.com"}</p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">Phone Number</label>
              <p>{"9876543210"}</p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">Pack</label>
              <p>{"25"}</p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">Products Request Count</label>
              <p>{"5"}</p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">Address</label>
              <p>
                {
                  "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India."
                }
              </p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">Floor, Suit, Unit (optional)</label>
              <p>{"2nd street, Lakshmipuram"}</p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">City</label>
              <p>{"Chennai"}</p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">State</label>
              <p>{"Tamil Nadu"}</p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">Pincode</label>
              <p>{"600099"}</p>
            </Col>
            <Col lg={6} xl={6} xs={12}>
              <label className="primary1">Country</label>
              <p>{"India"}</p>
            </Col>
          </>
        )}

      {method == 2 && splitLocation[1] == "enterprises-request-list" && (
        <>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Kodampakkam</label>
            <p>{"3SK Ships"}</p>
            <p>{"Chennai, , India"}</p>
            <p>{"600096"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Vadapalani</label>
            <p>{"3SK billa"}</p>
            <p>{"Forum Mall"}</p>
            <p>{"Arcot road,Vadapalani"}</p>
            <p>{"Chennai"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">T-Nagar</label>
            <p>{"5SK"}</p>
            <p>{"Forom mall"}</p>
            <p>{"Arcot road,Vadapalani"}</p>
            <p>{"Chennai"}</p>
          </Col>
        </>
      )}
      {method == 2 && splitLocation[1] == "enterprises-list" && (
        <>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Kodampakkam</label>
            <p>{"3SK Ships"}</p>
            <p>{"Chennai, , India"}</p>
            <p>{"600096"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">Vadapalani</label>
            <p>{"3SK billa"}</p>
            <p>{"Forum Mall"}</p>
            <p>{"Arcot road,Vadapalani"}</p>
            <p>{"Chennai"}</p>
          </Col>
          <Col lg={6} xl={6} xs={12}>
            <label className="primary1">T-Nagar</label>
            <p>{"5SK"}</p>
            <p>{"Forom mall"}</p>
            <p>{"Arcot road,Vadapalani"}</p>
            <p>{"Chennai"}</p>
          </Col>
        </>
      )}
    </div>
  );
};

export default CustomerAddress;
