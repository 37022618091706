// @ts-nocheck
import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import {
  searchIcon,
  toggleOff,
  toggleOn,
  editIcon,
  viewIcon,
} from "../../assets/img";
import { adminData } from "../../redux/api/DummyJson";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../components/Production/SearchComponent";
import ProductionTable from "../../components/Production/ProductionTable";
import ProofPopup from "../../components/Production/ProofPopup/ProofPopup";
import NotifyShipping from "../../components/Production/ProofPopup/NotifyShipping";

const ProductionScreen = () => {
  const navigate = useNavigate();
  const [searchTab, setSearchTab] = useState(true);
  const [lookupTab, setLookupTab] = useState(false);
  const [idleTab, setIdleTab] = useState(false);
  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProofPopup />
      <div className="d-flex w-95 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={manImage} className="searchManImg" />
      </div>
      <div className="prodution-tab">
        <div className="d-flex ac jc">
          <button
            onClick={() => {
              setSearchTab(true);
              setLookupTab(false);
              setIdleTab(false);
            }}
            className="d-flex ac jc cust-btn px-2 py-1"
          >
            <h4
              className={`f2 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                searchTab ? "primary" : "gray"
              }`}
            >
              Search
            </h4>
          </button>
          {/* <button
            onClick={() => {
              setSearchTab(false);
              setLookupTab(true);
              setIdleTab(false);
            }}
            className="d-flex ac jc cust-btn px-2 py-1"
          >
            <h4
              className={`f2 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                lookupTab ? "primary" : "gray"
              }`}
            >
              LookUp
            </h4>
          </button>
          <button
            onClick={() => {
              setSearchTab(false);
              setLookupTab(false);
              setIdleTab(true);
            }}
            className="d-flex ac jc cust-btn px-2 py-1"
          >
            <h4
              className={`f4 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                idleTab ? "primary" : "gray"
              }`}
            >
              |||
            </h4>
          </button> */}
        </div>
      </div>
      {searchTab && <SearchComponent />}

      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3">
          Total Count : {"02"}
        </p>
        {/* <div className="d-flex flex-md-row flex-column">
          <div className="d-flex mt-sm-3">
            <img src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          <button
            onClick={() => navigate("/profiles")}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Profile
          </button>
        </div> */}
      </div>
      <ProductionTable />
      {/* <NotifyShipping /> */}
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        Production List
      </p>
    </div>
  );
};
export default ProductionScreen;
