import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import manImage from "../../../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import {
  animatedImg,
  editIcon,
  searchIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../../../../assets/img";
import { adminData } from "../../../../redux/api/DummyJson";
import GetAppIcon from "@mui/icons-material/GetApp";

const ProductTransfer = () => {
  const navigate = useNavigate();

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} alt="animate_img" className="searchManImg" />
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Product Transfer
        </p>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex ac-jb flex-md-row flex-column">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 mt-4 px-3">
            Total Count : {"02"}
          </p>
          <div className="pointerView d-flex mt-4 me-3">
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            + Add Product Transfer
          </button>
        </div>
      </div>

      <table className="overflow-scroll d-block mt-3">
        <tr className="d-flex border-bottom" style={{ width: "fit-content" }}>
          <th
            style={{ width: "70px", padding: " 10px" }}
            className="text-center"
          >
            S.No
          </th>
          <th
            style={{ width: "150px", padding: " 10px" }}
            className="text-center"
          >
            Category
          </th>
          <th
            style={{ width: "150px", padding: " 10px" }}
            className="text-center"
          >
            SKU Code
          </th>
          <th
            style={{ minWidth: "230px", padding: " 10px" }}
            className="text-center"
          >
            Product Name
          </th>
          <th
            style={{ width: "230px", padding: " 10px" }}
            className="text-center"
          >
            Total Qty Request
          </th>
          <th
            style={{ width: "150px", padding: " 10px" }}
            className="text-center"
          >
            Warehouse Qty
          </th>
          <th
            style={{ width: "130px", padding: " 10px" }}
            className="text-center"
          >
            No.of.Order
          </th>
          <th
            style={{ width: "130px", padding: "10px" }}
            className="text-center"
          >
            View
          </th>
        </tr>
        <tr className="d-flex" style={{ width: "fit-content" }}>
          <td
            style={{ width: "70px", padding: "10px" }}
            className="text-center"
          >
            1
          </td>
          <td
            style={{ width: "150px", padding: "10px" }}
            className="text-center"
          >
            T-shirt
          </td>
          <td
            style={{ width: "150px", padding: "10px" }}
            className="text-center"
          >
            SKU-001
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="text-center"
          >
            Cotton T-shirt
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="text-center"
          >
            5000
          </td>
          <td
            style={{ width: "150px", padding: "10px" }}
            className="text-center"
          >
            2000
          </td>
          <td
            style={{ width: "130px", padding: "10px" }}
            className="text-center"
          >
            2
          </td>
          <td
            style={{ width: "130px", padding: "10px" }}
            className="d-flex ac-jc"
          >
            <div
              className=""
              style={{ width: "50px" }}
              onClick={() => {
                navigate("/product-transfer-view", {
                  state: { type: "Charles" },
                });
              }}
            >
              <div className="viewBoxDes p-2 mb-2 bg-white rounded-2 text-center">
                <img src={viewIcon} alt="viewicon" className="viewDes" />
              </div>
            </div>
          </td>
        </tr>
        <tr className="d-flex" style={{ width: "fit-content" }}>
          <td
            style={{ width: "70px", padding: "10px" }}
            className="text-center"
          >
            2
          </td>
          <td
            style={{ width: "150px", padding: "10px" }}
            className="text-center"
          >
            Bottle-1
          </td>
          <td
            style={{ width: "150px", padding: "10px" }}
            className="text-center"
          >
            SKU-002
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="text-center"
          >
            Steel Bottle
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="text-center"
          >
            3000
          </td>
          <td
            style={{ width: "150px", padding: "10px" }}
            className="text-center"
          >
            800
          </td>
          <td
            style={{ width: "130px", padding: "10px" }}
            className="text-center"
          >
            4
          </td>
          <td
            style={{ width: "130px", padding: "10px" }}
            className="d-flex ac-jc"
          >
            <div
              className=""
              style={{ width: "50px" }}
              onClick={() => {
                navigate("/product-transfer-view", {
                  state: { type: "Charles" },
                });
              }}
            >
              <div className="viewBoxDes p-2 mb-2 bg-white rounded-2 text-center">
                <img src={viewIcon} className="viewDes" />
              </div>
            </div>
          </td>
        </tr>
      </table>

      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default ProductTransfer;
