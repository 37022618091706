import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { sub_menu } from "../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { profilePic } from "../assets/img";
import { useNavigate } from "react-router-dom";
import OverviewTab from "../components/RequestDetailView/OverviewTab";
import PricingBoxComp from "../components/MyProductListComp/PricingBoxComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RejectPopup from "../components/Popup/RejectPopup";

const RequestDetailView = () => {
  const navigate = useNavigate();
  const [curTab, setCurTab] = useState("overview");

  const onStatusChange = (e) => {
    console.log(e?.target?.value);
    if (e?.target?.value === "Accept") {
      // navigate("/products/add-new-product", {
      //   state: { type: "Charles", path: "library" },
      // });
      navigate("/request-accept");
    } else if (e.target.value == "Reject") {
      onShowPopupHander();
    }
  };

  const [show, setShow] = useState(false);

  const onShowPopupHander = () => {
    setShow(!show);
  };

  return (
    <div className="dashRightView  p-5 home_section trans">
      {show && <RejectPopup onShowPopupHander={onShowPopupHander} />}
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3 d-flex ac-jb px-3">
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          John Rolph
        </h5>
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 black">
          Status :{" "}
          <span>
            {/* Requested */}
            <select
              name="status"
              className="primary p-1 f2 fs-18"
              onChange={onStatusChange}
              style={{ border: "1px solid #07679c", borderRadius: "10px" }}
            >
              <option disabled hidden value="">
                Select
              </option>
              <option value="Request">Request</option>
              <option value="In Process">In Process</option>
              <option value="Accept">Accept</option>
              <option value="Reject">Reject</option>
            </select>
          </span>
        </h5>
      </div>

      <div className="d-flex mt-2 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc">
          <div>
            <p
              className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary"
              role={"button"}
              onClick={() => navigate(-1)}
            >
              <KeyboardBackspaceIcon />
            </p>
          </div>
          <div
            onClick={() => {
              setCurTab("overview");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              curTab === "overview"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4 text-center`}
          >
            <p
              className={`${
                curTab === "overview" ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center`}
            >
              Overview
            </p>
          </div>
          {/* <div
            onClick={() => {
              setCurTab("pricing");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              curTab === "pricing"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4  text-center`}
          >
            <p
              className={`${
                curTab === "pricing" ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center `}
            >
              Pricing
            </p>
          </div> */}
        </div>
        <div className="mt-md-4 mt-3">
          <h5 class="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              {" "}
              INR
            </span>
          </h5>
        </div>
      </div>

      {curTab === "overview" && <OverviewTab />}
      {/* {curTab === "pricing" && ( */}
      {/* <div className="">
        <p className="mt-5 f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          Vendor Pricing
        </p>
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
          5SK Supplier
        </p>
        <div className="overflow-scroll">
          <table className="w-100">
            <tr className="mt-4">
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Qty
              </th>
              <div className="my-3">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="25"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="50"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="100"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="150"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="250"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="500"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="1000"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Net
              </th>
              <div className="mt-2">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="$100.00"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$90.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$85.00"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$80.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$70.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$70.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="$70.00"
                        type="number"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
        </div>
      </div> */}
      {/* )} */}
      <PricingBoxComp />
      <div className="btn-w-cust-cont mt-4 w-100">
        <button
          class="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default RequestDetailView;
