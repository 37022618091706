import React from "react";
import { Bottle, head, logos, tshirt1, tshirt2,banner } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useNavigate } from "react-router-dom";

const PresentationGallery = () => {
  const navigate=useNavigate();
  return (
    <div className="bg-gray3 py-3" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div
          className="d-flex mt-3  banner"
          style={{
            backgroundImage:
              `url(${banner})`,
            padding: "20px",
            flexWrap: "wrap",
          }}
        >
          <div className="">
            <img
              alt="img"
              src={logos}
              className=" ps-3"
              style={{ width: "150px", objectFit: "cover" }}
            />
          </div>

          <div className="ps-2">
            <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
              MerchHQ Pvt.Ltd.
            </h2>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
              INVOICE for 5SK
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Shankar cement
            </p>
          </div>
        </div>

        <div className="my-5">
          <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center">
            This Pen Product is not Available in MHQ
          </p>
        </div>
        <div className="d-flex flex-wrap ac-jb  my-3 ">
          <div className="hove" style={{ position: "relative" }}>
            <div class="list">
              <div
                onClick={() => {
                  navigate("/presentation-gallery-page-3a");
                }}
                className="image "
                style={{
                  backgroundImage: `url(${Bottle})`,
                  width: "300px",
                  height: "300px",
                  objectFit: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="hidden">
                  <div
                    className=" primary "
                    style={{ margin: "0px 0px 0px 20px", paddingTop:"20px"}}
                  >
                    <ChatBubbleIcon />
                  </div>
                  <div style={{ marginTop: "100px" }}>
                    <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18  text-center">
                      Price Label 500
                    </p>
                    <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 primary text-center">
                      Rs.99.00 - Rs.131.58
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center my-3">
              Gilden Easy Care Solid Tea - Royal
            </p>
          </div>
          <div className="hove" style={{ position: "relative" }}>
            <div class="list">
              <div
                className=" image "
                style={{
                  backgroundImage: `url(${tshirt1})`,
                  width: "300px",
                  height: "300px",
                  objectFit: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="hidden">
                  <div
                    className=" primary "
                    style={{ margin: "0px 0px 0px 20px", paddingTop:"20px"}}
                  >
                    <ChatBubbleIcon />
                  </div>
                  <div style={{ marginTop: "100px" }}>
                    <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18  text-center">
                      Price Label 500
                    </p>
                    <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 primary text-center">
                      Rs.99.00 - Rs.131.58
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center my-3">
              Adventure Sipper - Silver
            </p>
          </div>
          <div className="hove" style={{ position: "relative" }}>
            <div class="list">
              <div
                className=" image"
                style={{
                  backgroundImage: `url(${tshirt2})`,
                  width: "300px",
                  height: "300px",
                  objectFit: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div className="hidden">
                  <div
                    className=" primary "
                    style={{ margin: "0px 0px 0px 20px", paddingTop:"20px"}}
                  >
                    <ChatBubbleIcon />
                  </div>
                  <div style={{ marginTop: "100px" }}>
                    <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18  text-center">
                      Price Label 500
                    </p>
                    <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 primary text-center">
                      Rs.99.00 - Rs.131.58
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center my-3">
              Gilden Easy Care Solid Tea - Royal
            </p>
          </div>
        </div>
        <div className="d-flex flex-wrap ac-jb my-4" >
          <div>
            <h4 className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ">
              Roger Daniel -{" "}
              <span className="f1 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 gray">
                Marketing Technologist
              </span>
            </h4>
            <a className="f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 blue1 ">
              roger@companystore.io
            </a>
          </div>
          <div className="w-30">
            <p className="f1 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ">
              MerchHQ Pvt. Ltd. #39 Krishna Reddy Colony, Domlur Layout,
              Chennai, Tamil Nadu 560071 India
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationGallery;
