import React from "react";
import { Bottle, head, logos, tshirt1, tshirt2 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const PresentationGalleryA1 = () => {
  return (
    <div className=" " style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="ps-2 font-blue2" >
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                PRESENTATION #110208 for AAHIKA
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project Name
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>

        <div className="my-5">
          <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center">
            This Pen Product is not Available in MHQ
          </p>
        </div>
        <div className="d-flex flex-wrap ac-jb my-3">
          <div>
            <img
              src={Bottle}
              style={{ width: "250px", height: "250px", borderRadius: "20px" }}
              alt=""
            />
            <p className="f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center">
              Gilden Easy Care Solid Tea - Royal
            </p>
          </div>
          <div>
            <img
              src={tshirt1}
              style={{ width: "250px", height: "250px", borderRadius: "20px" }}
              alt=""
            />
            <p className="f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center">
              Adventure Sipper - Silver
            </p>
          </div>
          <div>
            <img
              src={tshirt2}
              style={{ width: "250px", height: "250px", borderRadius: "20px" }}
              alt=""
            />
            <p className="f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center">
              Gilden Easy Care Solid Tea - Royal
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className="mt-3  px-2"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="w-90 m-auto d-flex flex-wrap ac-jb">
            <div className="">
              <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
                Roger Daniel
              </h2>
              <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
                roger@companystore.io
              </a>
              <button
                class=" d-flex ac-jc px-3 mt-sm-2"
                style={{
                  backgroundColor: "#0082ca",
                  border: "none",
                  borderRadius: "7px",
                  padding: "5px",
                  color: "#fff",
                }}
              >
                <UnsubscribeIcon />

                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                  Contact us
                </p>
              </button>
            </div>
            <div className="col-md-4">
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                merchHQ Pvt.Ltd.
              </p>
              <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
                #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
                Kartnataka, India.
              </p>
              <div class="d-flex white mt-sm-2 ">
              <FacebookRoundedIcon className="me-2"/>

<TwitterIcon className="mx-2"/>

<LinkedInIcon className="mx-2"/>

<InstagramIcon className="mx-2"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationGalleryA1;
