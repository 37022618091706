import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import AddFilesPopup from "../Popup/AddFilesOverview";
import NavbarHead from "../Sub_Layout/NavbarHead";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  danger_sm,
  product,
  profilePic,
  uploadIcon,
  vendorBannerImg,
} from "../../assets/img";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CloseOutlined,
  Download,
  UploadFile,
} from "@mui/icons-material";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import Upload from "@mui/icons-material/Upload";

const CollaborateNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location?.pathname);
  const [post, setPost] = useState("");
  // const [selectFiles, setSelectFiles] = useState("");
  const [selectFile, setSelectFile] = useState(false);
  const [selectBox, setSelectBox] = useState([]);
  const [selecter, setSelecter] = useState("");
  const [actionBtn, setActionBtn] = useState(false);

  const [file, setFile] = useState([]);
  const [images, setImages] = useState([]);
  const [showAllImg, setShowAllImg] = useState(false);
  const [chooseImg, setChooseImg] = useState();

  let options = [
    { value: "ab prime", label: "ab Prime" },
    { value: "kti promos", label: "KTI  Promos" },
  ];

  let selected = [{ value: "Viswa", label: "Viswa" }];

  let showImages = [
    [
      {
        title: "images",
        date: "12 dec 2023 - 12:23PM",
        desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione eligendi animi excepturi dolore quasi maxime, assumenda voluptas laboriosam consequuntur perspiciatis expedita numquam placeat, et harum deleniti praesentium. Aspernatur, non molestiae.",
      },
      [
        {
          img: uploadIcon,
        },
        {
          img: profilePic,
        },
        {
          img: danger_sm,
        },
        {
          img: "https://nestasia.in/cdn/shop/products/DSCF0635.jpg?v=1678173254",
        },
        {
          img: product,
        },
      ],
    ],
    // [
    //   {
    //     title: "Lorem ipsum dolor sit",
    //     date: "23 Nov 2023",
    //     desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione eligendi animi excepturi dolore quasi maxime, assumenda voluptas laboriosam consequuntur perspiciatis expedita numquam placeat, et harum deleniti praesentium. Aspernatur, non molestiae.",
    //   },
    //   [
    //     {
    //       img: uploadIcon,
    //     },
    //     {
    //       img: profilePic,
    //     },
    //     {
    //       img: danger_sm,
    //     },
    //     {
    //       img: product,
    //     },
    //   ],
    // ],
  ];
  // console.log(showImages);
  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const selectedFIles = [];
    const targetFiles = event.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    // selectBox?.map((item) => {
    //   if (item?.value !== "Viswa") {
    //     setSelecter(item?.value);
    //   } else {
    //     setSelecter("");
    //   }
    // });

    let selected = [{ value: "Viswa", label: "Viswa" }];
    setSelectBox(selected);
  }, []);

  const toggleShowPopups = () => {
    setSelectFile(!selectFile);
  };

  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };

  const loc = useLocation();
  // console.log(loc?.pathname);
  return (
    <div
      className={`${
        location?.pathname === "/view-collaborate"
          ? "dashRightView2"
          : "dashRightView p-5 trans"
      }`}
    >
      {location?.pathname !== "/view-collaborate" && <ProductSearchHeader />}
      {showAllImg && (
        <>
          <button
            onClick={() => setShowAllImg(false)}
            className="close-pop-btn cust-btn"
          >
            <CloseOutlined className="" />
          </button>
          <button className="lft-arw-pop-btn cust-btn">
            <ArrowForwardIos className="f4 f-18 text-white" />
          </button>
          <button className="rit-arw-pop-btn cust-btn">
            <ArrowBackIos className="f4 f-18 text-white" />
          </button>
          <div className="show-img-coll-pop">
            {showImages?.map((item, index) => {
              console.log("zdfds:", index, item[1]);
              return item[1].map((it, ind) => {
                console.log(it);
                return <img className="big-view-pop" src={it?.img} />;
              });
            })}
          </div>
        </>
      )}
      <div className="d-flex justify-content-end position-relative">
        <div className="action_overview mx-4">
          {location?.pathname === "/view-collaborate" && (
            <NavbarHead onLinkShow={onLinkShow} />
          )}
          {actionBtn && (
            <div
              className="invisible-cont2"
              style={{ zIndex: 200 }}
              onClick={() => setActionBtn(false)}
            />
          )}
          {actionBtn && (
            <div className="action_btn ml-1" style={{ zIndex: 300 }}>
              <button
                className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                onClick={() => navigate("/presentation")}
              >
                Presentation
              </button>
              <button
                className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                onClick={() => navigate("/estimate")}
              >
                Create Estimate
              </button>
              <button
                className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                onClick={() => navigate("/sales-order")}
              >
                Create Sales Order
              </button>
              <button
                className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                onClick={() => {
                  setActionBtn(false);
                }}
              >
                Create Invoice
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
              >
                View Portal
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex ac-je mb-2 w-100">
        <button
          className="cust-btn addbtn  align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
      <div className="d-flex gap-4 as-jb mt-3 flex-column flex-md-row w-100">
        <div className="w-100">
          <div className="py-0 rounded-3 bg-lt-blue2 my-0 py-0 rounded-3">
            <div className="acti_box">
              <div className="d-flex as-jb flex-wrap w-100 ">
                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16">
                      Choose Category
                    </p>
                    <select
                      className="editBtn w-100 rounded-3 mt-0 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value={2}
                    >
                      <option value={0}>Select Rep</option>
                      <option value={1}>AJ kind</option>
                      <option value={2}>SARA FETTE</option>
                    </select>
                  </div>
                </div>
                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16">Choose Vendor</p>
                    <select
                      className="editBtn w-100 rounded-3 mt-0 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value={2}
                    >
                      <option value={0}>Select Rep</option>
                      <option value={1}>AJ kind</option>
                      <option value={2}>SARA FETTE</option>
                    </select>
                  </div>
                </div>
                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16">Product Name</p>

                    <input
                      className="editBtn w-100 rounded-3 mt-0 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value={"Bag"}
                    />
                  </div>
                </div>
                <div className="w-50 px-2 supp-select">
                  <p className="f3 fs-12 fs-sm-15 fs-md-16">Assigned To</p>
                  <Select
                    options={options}
                    value={selectBox}
                    isMulti
                    classNamePrefix="select"
                    className="editBtn w-100 rounded-3 border-0 mt-0 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => setSelectBox(e)}
                  />
                </div>
                <div className="w-50 px-2 supp-select">
                  <p className="f3 fs-12 fs-sm-15 fs-md-16">Status</p>
                  <select
                    className="editBtn w-100 rounded-3 mt-0 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={1}
                  >
                    <option value={1}>Request</option>
                    <option value={2}>Accept</option>
                    <option value={3}>Reject</option>
                  </select>
                </div>
                <div className="w-100 px-2 mt-3">
                  <p className="f3 fs-12 fs-sm-15 fs-md-16">Descriptions</p>
                  {/* {selectBox?.length > 0 && selecter !== "all suppliers" && (
                    <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      <option>Select Rep</option>
                      <option>AJ kind</option>
                      <option>SARA FETTE</option>
                    </select>
                  )} */}
                </div>
              </div>
              <div className=" w-100 px-2">
                <textarea
                  className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100"
                  onChange={(e) => setPost(e.target.value)}
                />
              </div>
              <div className="d-flex w-100 as-jb px-2">
                <div>
                  {location?.pathname === "/view-collaborate" ? (
                    <>
                      <div className="d-flex ac-jb">
                        <input
                          type="file"
                          multiple
                          onChange={handleChange}
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                        <button
                          onClick={handleClick}
                          className="cust-btn addbtn text-nowrap ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-0 rounded"
                        >
                          <img
                            src={uploadIcon}
                            alt=""
                            className="me-2"
                            style={{ height: "30px", width: "30px" }}
                          />
                          upload your Files
                        </button>
                      </div>
                      <div className="w-100 d-flex flex-wrap mt-3">
                        {images?.length > 0 &&
                          images.map((url, ind) => {
                            console.log(url);
                            return (
                              <div className="prev-img position-relative">
                                <img src={url} />
                                <button
                                  onClick={() => {
                                    setImages(
                                      images.filter((o, i) => ind !== i)
                                    );
                                  }}
                                  className="icon-delete cust-btn"
                                >
                                  <CloseOutlined />
                                </button>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    <div className="w-100 d-flex flex-wrap p-3">
                      {showImages?.map((item, index) => {
                        return (
                          <div className="w-100 d-flex flex-wrap">
                            {item[1]?.map((it, ind) => {
                              console.log(it);
                              return (
                                <div className="w-25">
                                  <img src={it?.img} className="w-100" alt="" />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div>
                  {location?.pathname === "/view-collaborate" ? (
                    <div className=" mb-3">
                      <button className="cust-btn addbtn ms-1  bg-primar text-white f3 fs-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-0 rounded">
                        Submit
                      </button>
                    </div>
                  ) : (
                    <div className="w-100 d-flex">
                      <div className="d-flex flex-wrap  mb-3">
                        <button className="cust-btn addbtn ms-1  bg-primar text-white f3 fs-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-0 rounded">
                          Accept
                        </button>
                      </div>
                      <div className="d-flex flex-wrap mb-3">
                        <button className="cust-btn addbtn_cancel ms-1  bg-red text-white f3 fs-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-0 rounded">
                          Reject
                        </button>
                      </div>
                      <div className="d-flex flex-wrap mb-3">
                        <button className="cust-btn cancelbtn ms-1  bg-gray text-white f3 fs-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-0 rounded">
                          Back
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {loc?.pathname !== "/view-collaborate" && (
                  <div>
                    {showImages?.map((item, index) => {
                      return (
                        <div className="">
                          <div className="w-100 as-jb">
                            <h5 className="f3 mt-4 fs-12 fs-sm-15 fs-md-16 pe-3">
                              {item[0]?.title} vdfvxdv asfsdf asd asdasdas as
                              ada da sxcvcxv df
                            </h5>
                            <div className="d-flex mb-2">
                              <p className="f1 mt-1 fs-11 fs-sm-13 fs-md-14 text-gray">
                                <i>{item[0]?.date} </i>
                              </p>
                              <p className="ms-2 f3 mt-1 fs-11 fs-sm-13 fs-md-14 text-gray text-success">
                                Accepted
                              </p>
                              <p className="ms-2 f3 mt-1 fs-11 fs-sm-13 fs-md-14 text-gray">
                                By: Esthaki
                              </p>
                            </div>
                          </div>
                          <h5 className="f1 fs-12 fs-sm-15 fs-md-16 mb-2">
                            {item[0]?.desc}
                          </h5>
                          <div className="w-100 d-flex flex-wrap p-3">
                            {item[1]?.map((it, ind) => {
                              return (
                                <button
                                  onClick={() => setShowAllImg(true)}
                                  className="w-25 cust-btn"
                                >
                                  <img
                                    className="big-img w-100"
                                    src={it.img}
                                    alt=""
                                  />
                                </button>
                              );
                            })}
                          </div>
                          <div className=" w-100 px-2">
                            <textarea
                              placeholder="Enter Comments"
                              className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100"
                              onChange={(e) => setPost(e.target.value)}
                            />
                          </div>
                          <div className="d-flex ac-jb w-100">
                            <div className=" d-flex flex-wrap mt-0 mb-3">
                              <button className="cust-btn addbtn ms-1  bg-primar text-white f3 fs-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-0 rounded">
                                Send
                              </button>
                            </div>
                            <div className="d-flex ac-jb">
                              <input
                                type="file"
                                multiple
                                onChange={handleChange}
                                ref={hiddenFileInput}
                                style={{ display: "none" }}
                              />

                              <button
                                onClick={handleClick}
                                className="cust-btn ms-1 bg-primar text-white f3 px-3 py-2 mb-3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-0 rounded"
                              >
                                {/* <img src={uploadIcon} alt="" className="me-2" /> */}
                                upload your Files
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              {/* Profile Update View */}
              <div>
                <li className="d-flex flex-wrap mb-3">
                  <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc mb-3 mb-md-0">
                      <img src={profilePic} className="sm_img_size" />
                    </div>
                    <div className="d-flex px-0 px-md-3 w-100">
                      <div className="mx-3 w-100">
                        <div className="d-flex ac-jb">
                          <h5 className="author">
                            <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                              Sakthi
                            </span>
                            <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              5.00 PM
                            </span>
                          </h5>
                        </div>
                        {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                        <div>
                          <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                            <p>
                              like readable English. Many desktop publishing
                              packages and web page editors now use Lorem Ipsum
                              as their default readable English. Many desktop
                              publishing packages and web page editors now use
                              Lorem Ipsum as their default readable English.
                              Many desktop publishing packages and web page
                              editors now use Lorem Ipsum as their default model
                              text and a search for lorem ipsum will uncover
                              many web sites still in
                            </p>
                          </div>
                          <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                    <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                      <img src={profilePic} className="sm_img_size2" />
                      <div
                        className="position-absolute invisible down_icon"
                        style={{ top: 0, right: 0 }}
                      >
                        <Download className="primary" />
                      </div>
                    </div>
                  </div>
                </li>
                <ul>
                  <div className="w-100 line my-4" />
                  <div className="w-100 d-flex ac-jc">
                    <div className="bg_today px-3 py-2 rounded-3">
                      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                        Today
                      </p>
                    </div>
                  </div>
                  <li className="d-flex flex-wrap">
                    <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                      <div className="d-flex-ac-jc mb-3 mb-md-0">
                        <img src={profilePic} className="sm_img_size" />
                      </div>
                      <div className="d-flex px-0 px-md-3 w-100">
                        <div className="mx-3 w-100">
                          <div className="d-flex ac-jb">
                            <h5 className="author">
                              <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                Sakthi
                              </span>
                              <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                5.00 PM
                              </span>
                            </h5>
                          </div>
                          {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                          <div>
                            <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                              <p>
                                like readable English. Many desktop publishing
                                packages and web page editors now use Lorem
                                Ipsum as their default readable English. Many
                                desktop publishing packages and web page editors
                                now use Lorem Ipsum as their default readable
                                English. Many desktop publishing packages and
                                web page editors now use Lorem Ipsum as their
                                default model text and a search for lorem ipsum
                                will uncover many web sites still in
                              </p>
                            </div>
                            <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul>
                  <div className="w-100 line my-4" />
                  <div className="w-100 d-flex ac-jc">
                    <div className="bg_today px-3 py-2 rounded-3">
                      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                        Yesterday
                      </p>
                    </div>
                  </div>
                  <li className="d-flex flex-wrap">
                    <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                      <div className="d-flex-ac-jc mb-3 mb-md-0">
                        <img src={profilePic} className="sm_img_size" />
                      </div>
                      <div className="d-flex px-0 px-md-3 w-100">
                        <div className="mx-3 w-100">
                          <div className="d-flex ac-jb">
                            <h5 className="author">
                              <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                Viswa
                              </span>
                              <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                5.00 PM
                              </span>
                            </h5>
                          </div>
                          {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                          <div>
                            <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                              <p>
                                like readable English. Many desktop publishing
                                packages and web page editors now use Lorem
                                Ipsum as their default readable English. Many
                                desktop publishing packages and web page editors
                                now use Lorem Ipsum as their default readable
                                English. Many desktop publishing packages and
                                web page editors now use Lorem Ipsum as their
                                default model text and a search for lorem ipsum
                                will uncover many web sites still in
                              </p>
                            </div>
                            <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex flex-wrap mt-5">
                    <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                      <div className="d-flex-ac-jc mb-3 mb-md-0">
                        <img src={profilePic} className="sm_img_size" />
                      </div>
                      <div className="d-flex px-0 px-md-3 w-100">
                        <div className="mx-3 w-100">
                          <div className="d-flex ac-jb">
                            <h5 className="author">
                              <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                Krishna Vendor
                              </span>
                              <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                5.00 PM
                              </span>
                            </h5>
                          </div>
                          {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                          <div>
                            <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                              <p>
                                like readable English. Many desktop publishing
                                packages and web page editors now use Lorem
                                Ipsum as their default readable English. Many
                                desktop publishing packages and web page editors
                                now use Lorem Ipsum as their default readable
                                English. Many desktop publishing packages and
                                web page editors now use Lorem Ipsum as their
                                default model text and a search for lorem ipsum
                                will uncover many web sites still in
                              </p>
                            </div>
                            <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                      <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                        <img src={profilePic} className="sm_img_size2" />
                        <div
                          className="position-absolute invisible down_icon"
                          style={{ top: 0, right: 0 }}
                        >
                          <Download className="primary" />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Profile Update View */}

              {loc?.pathname !== "/view-collaborate" && (
                <div className="d-flex ac-jb w-100">
                  <div className="d-flex ac-jb">
                    <input
                      type="file"
                      multiple
                      onChange={handleChange}
                      ref={hiddenFileInput}
                      style={{ display: "none" }}
                    />

                    <button
                      onClick={handleClick}
                      className="cust-btn ms-1 bg-primar text-white f3 px-3 py-2 mb-3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-0 rounded"
                    >
                      {/* <img src={uploadIcon} alt="" className="me-2" /> */}
                      upload your Files
                    </button>
                  </div>
                  <div className=" d-flex flex-wrap mt-0 mb-3">
                    <button className="cust-btn addbtn ms-1  bg-primar text-white f3 fs-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-0 rounded">
                      Send
                    </button>
                  </div>
                </div>
              )}

              {/* {showImages?.map((item, index) => {
                console.log(item[1][0]);
                return item[1]?.length > 2 ? (
                  showAllImg ? (
                    <div className="">
                      <h5>{item[0]?.title}</h5>
                      <h5>{item[0]?.desc}</h5>
                      <div className="w-100 d-flex flex-wrap bg-success p-3">
                        {item[1]?.map((it, ind) => {
                          console.log("it:", it);
                          return (
                            <div className="w-50">
                              <img
                                className="big-img w-100"
                                src={it.img}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="w-100 d-flex bg-success p-3">
                      <p>lklk</p>
                      <div className={item[1]?.length > 1 ? "w-100" : "w-100"}>
                        <img
                          className="big-img w-100"
                          src={item[1][0].img}
                          alt=""
                        />
                      </div>
                      <div className="w-50">
                        <div className="img-box-collab">
                          <img
                            className="big-img"
                            src={item[1][1].img}
                            alt=""
                          />
                        </div>
                        <div className="img-box-collab position-relative">
                          <img
                            className="big-img"
                            src={item[2][2].img}
                            alt=""
                          />
                          {item?.length > 2 && (
                            <button
                              onClick={() => setShowAllImg(true)}
                              className="over-flow-collab cust-btn f2 fs-15 fs-xs-21 fs-sm-22 fs-md-23 fs-lg-25 fs-xl-26 fs-xxl-27"
                            >
                              +2
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="w-100 d-flex flex-wrap bg-success p-3">
                    <p>asdasd</p>
                    {showImages?.map((it, ind) => {
                      return (
                        <div className="w-50">
                          <img className="big-img w-100" src={it.img} alt="" />
                        </div>
                      );
                    })}
                  </div>
                );
              })} */}
            </div>
          </div>
        </div>

        <div className="w-50 box_width2">
          <h5 className="f3 fs-13 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark">
            Project Status
          </h5>
          <div className="d-flex ac-js gap-3 mt-3">
            <div className="over_pro_st m-0">
              <button className="cust-btn addbtn m-0  bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2">
                P
              </button>
            </div>
            <div className="flex-wrap">
              <p className="over_p_tag f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 m-0">
                Presentation
              </p>
              <p className="over_open f1 fs-15 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-12 fs-xl-13 fs-xxl-14 primary1 m-0">
                New
              </p>
            </div>
          </div>
          <div className="collab_tip rounded-3 mt-4 ms-0 w-100">
            <h4 className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
              Collaborate with your suppliers
            </h4>
            <p className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries
            </p>
            <p className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
              as opposed to using 'Content here, content here', making it look
              like readable English. Many desktop publishing packages and web
              page editors now use Lorem Ipsum as their default model text, and
              a search for 'lorem ipsum' will uncover many web sites still in
              their infancy. Various versions have evolved over the years,
              sometimes by accident, sometimes on purpose (injected humour and
              the like).
            </p>
          </div>
        </div>
      </div>
      {selectFile && <AddFilesPopup toggleShowPopup={toggleShowPopups} />}
    </div>
  );
};

export default CollaborateNew;
