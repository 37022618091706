import React from "react";
import { ChatProfile } from "../../redux/api/DummyJson";
import { Mail, Star, profilePic } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { Cached, GetApp, Search } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const SupportScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex ac-jb">
        <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-24 fs-xxl-25 primary mt-5">
          Support
        </p>
        <div className="d-flex gap-1">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-16">
            Total Count
          </p>
          <select className="editBtn rounded-1  d-flex ac-jc f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView btn-success">
            <option>Opened</option>
            <option>Closed</option>
            <option>Not Opened</option>
          </select>
        </div>
      </div>
      <div className="d-flex ac-jb mt-5">
        <div className="d-flex ac-jb flex-column flex-md-row">
          <div className="d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <input
              type="text"
              className="get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3 w-auto fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder={"Search Request List"}
            />
            <button class="get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 fs-14">
              <select className="bg-transparent border-0 py-3 primary1">
                <option selected hidden value="">
                  Status
                </option>
                <option value="Request">Request</option>
                <option value="In Process">In Process</option>
                <option value="Accept">Accept</option>
                <option value="Reject">Reject</option>
              </select>
            </button>
            <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
              <Search />
            </button>
            <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
              <Cached />
            </button>
          </div>
        </div>
        <div className="d-flex ac-je w-xs-100 mt-xs-5 position-relative">
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mx-3">
            From
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mx-3">
            To
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
        </div>
      </div>
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="pointerView d-flex ">
          <GetApp className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
          <p
            className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
          >
            Export
          </p>
        </div>
      </div>

      <div
        className="mt-5 cust-table"
        style={{ width: "100%", overflow: "auto" }}
      >
        <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-3">
          All Tickets
        </p>
        <table className="table-design-new " style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc text-nowrap">
                  Profile picture
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc text-nowrap">
                  Client ID
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Email
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc text-nowrap">
                  Phone Number
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc text-nowrap">
                  Project ID
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Date
                </p>
              </th>

              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Action
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {ChatProfile?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img className="w-50 h-50" src={profilePic} alt="" />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                      {item?.ClientID}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.first_name}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.email}
                    </p>
                  </td>

                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.phoneNumber}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.projectId}
                    </p>
                  </td>

                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.date}
                    </p>
                  </td>

                  <td>
                    <div className="text-nowrap">
                      <button className="btn btn-warning f2 fs-14 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-14 fs-xl-16 fs-xxl-16 ac-jc rounded-5">
                        {item?.status}
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex ac-jc primary1">
                      {/* <div
                        onClick={() =>
                          navigate("/chat", {
                            state: { type: "view", data: item },
                          })
                        }
                        className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={Mail} className="viewDes" />
                      </div> */}
                      <button
                        onClick={() =>
                          navigate("/chat", {
                            state: { type: "view", data: item },
                          })
                        }
                        className="p-2 mb-2 bg-white rounded-2 border-0 viewBoxDes"
                      >
                        <p className=" f2 fs-xs-11  fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                          <VisibilityIcon />
                        </p>
                      </button>
                      <div
                        // onClick={() =>
                        //   navigate("/profiles", {
                        //     state: { type: "edit", data: item },
                        //   })
                        // }
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={Star} className="viewDes" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SupportScreen;
