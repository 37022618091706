import { createSlice } from "@reduxjs/toolkit";

const imageSave = createSlice({
  name: "image",
  initialState: false,
  reducers: {
    saveImage: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveImage } = imageSave.actions;
export default imageSave.reducer;
