import { useRef } from "react";
import { empty_profile, uploadIcon } from "../../assets/img";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";


const UploadLibraryImage = ({ setImage, image, type, folderName }) => {
  const fileRef = useRef();
  const navigate = useNavigate();

  console.log(image);
  return (
    <div className="w-100 ms-xxl-5">
      <p className="w-100 f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 gray">
        Add Image to{" "}
        <span className="f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 text-black">
          {type === "product"
            ? "Product Library"
            : type === "vendor"
              ? "Vendor Library"
              : folderName}
        </span>
      </p>
      {image === "" ? (
        <div className="pointerView w-100 mt-5">
          <div
            onClick={() => fileRef.current.click()}
            className="w-100 d-flex ac-jc"
          >
            <img src={uploadIcon} className="uploadImgLib" />
          </div>
          <div className="w-100 d-flex ac-jc">
            <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
              Drag and drop to upload or Click
            </p>
          </div>
          <div className="w-100 d-flex ac-jc">
            <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
              here to upload
            </p>
          </div>
        </div>
      ) : (
        <div className="w-100 mt-4 d-flex ac-jb">
          <div
            onClick={() => fileRef.current.click()}
            className="w-100 d-flex ac-jc"
          >
            <img src={image} className="uploadImgLib" />
            <div className="w-20">
              {image?.type === "image/png" ||
                image?.type === "image/jpeg" ||
                image?.type === "image/jpg" ? (
                <img src={image} className="uploadImgLib" />
              ) : null}
              {image?.type === "application/pdf" ||
                image?.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                image?.type === "application/doc" ||
                image?.type === "application/msword" ? (
                <iframe src={image} className="uploadImgLib" />
              ) : null}
            </div>
            <p className="f2 w-100 fs-xs-13 ms-2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 primary1">
              {image?.name}
            </p>
          </div>
          <div className="w-20 ac-jc d-flex">
            <DeleteIcon
              onClick={() => setImage("")}
              className="pointerView ms-1 primary1 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20"
            />
          </div>
        </div>
      )}

      <div className="w-100 mt-4">
        <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
          Image Title
        </p>
        <input
          placeholder=""
          value="Hoodie"
          className="editBtn rounded-3 mt-3 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
        />
      </div>
      <div className="w-100 d-flex ac-jc gap-3 w-md-100">
        {image === "" && (
          <button
            onClick={() => fileRef.current.click()}
            className=" cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            Upload Image
          </button>
        )}
        {/* <button
          className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
          Import
        </button> */}
        {image !== "" && (
          <button
            onClick={() => {
              if (type === "vendor") {
                navigate("/view-library-list", {
                  state: { name: "Vendor" },
                });
              } else if (type === "product") {
                navigate("/view-library-list", {
                  state: { name: "Product" },
                });
              } else {
                navigate("/view-library-list", {
                  state: { name: folderName },
                });
              }
            }}
            className=" cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            Submit
          </button>
        )}
      </div>

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => {
          if (e?.target?.files.length > 0) {
            setImage(window?.URL?.createObjectURL(e?.target?.files?.[0]));
          }
        }}
        className="d-none"
        ref={fileRef}
      />
    </div>
  );
};

export default UploadLibraryImage;
