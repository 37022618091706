import React from "react";
import { head, logos } from "../assets/img";
import { banner } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const InvoiceClientA = () => {
  return (
    <div className=".bg-white" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex align-items-center flex-wrap mt-3 py-4 banner col-md-4"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="px-2 ms-2 font-blue2">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                MerchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                INVOICE for 5SK
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Shankar cement
              </p>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div
          className="d-flex w-100  mt-4  me-3 p-4 bg-primary2"
          style={{
            // backgroundColor: "#ecfaff",
            borderRadius: "20px",
            lineHeight: "25px",
            flexWrap: "wrap",
          }}
        >
          <div className="col-md-3 mt-sm-2">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                SHIPPPING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-80">
                Sakthi Cli 5SK Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
                NADU NINOS125 ,INDIA
              </p>
            </div>
          </div>
          <div className="col-md-3 mt-sm-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              SHIPPPING ADDRESS
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-80">
              Sakthi Cli 5SK Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
              NADU NINOS125 ,INDIA
            </p>
          </div>
          {/* <div className="ryt-line"
            style={{
              height: "150px",
              width: "1px",
              backgroundColor: "#000",
            }}
          ></div> */}
          <div
            className="col-md-3 ps-lg-4 mt-sm-2 left-line"
            style={{
              borderLeft: "1px",
              borderStyle: "solid",
              borderColor: "#cacaca",
              borderRight: "0",
              borderBottom: "0",
              borderTop: "0",
            }}
          >
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              AMOUNT DUE
            </p>
            <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
              Rs:8,500796.30 INR
            </h2>

            <div class="d-flex mt-4 gap-3">
              <div>
                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  DATE
                </p>
                <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Feb 4,2024
                </p>
              </div>
              <div className="ms-2">
                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  TERMS
                </p>
                <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Purchase Order
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-sm-2 ps-3">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              PROJECT#
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              210234
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              INVOICE#
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              220393
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CUSTOMER PO
            </p>
          </div>
        </div>
        <div
          class=" p-4 mt-4 overflow-scroll bg-primary2"
          style={{ borderRadius: "20px" }}
        >
          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 mb-3">
            Summary
          </p>
          <table className="w-100 ">
            <thead>
              <tr>
                <th className="f3 col-md-8  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                  ITEM
                </th>
                <th>
                  <p className=" col-md-1 f3 col-md-8  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                    QTY
                  </p>
                </th>
                <th>
                  <p className=" col-md-1 f3 col-md-8  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-3">
                    UNIT COST
                  </p>
                </th>
                <th>
                  <p className=" col-md-1 f3 col-md-8  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                    TOTAL COST
                  </p>
                </th>
              </tr>
            </thead>
            <tbody style={{ lineHeight: "30px" }}>
              <tr>
                <div className="d-flex align-item-start">
                  <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Stainless Steel And hot N Cold bottle
                  </td>
                </div>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    300
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                    10
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    0
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS25,765.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS25,765.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS254,5.00
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,987,9879.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,3479.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,4569.00
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Fixed pre Charges
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Run Charges
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    310
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Fixed Charges
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Setup Cost-top
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Setup Cost-
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr
                className="mt-2"
                style={{
                  borderBottom: "1px",
                  borderTop: "1px",
                  borderRight: "0",
                  borderColor: "#787b7f",
                  borderStyle: "solid",
                  borderLeft: 0,
                }}
              >
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Navy BLUE PLAY DRY T-SHIRTS WITH WHITE TIPPING.
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    400
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS780.83
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS348,332.00
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex mt-4 ps-3 ac-jb" style={{ flexWrap: "wrap" }}>
          <div className="col-md-5 " style={{ lineHeight: "25px" }}>
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18  primary">
              Questions About this estimate?
            </p>
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              merchHQ Pvt.Ltd.
            </p>
            <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-40">
              #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
              Kartnataka, India.
            </p>
          </div>
          <div
            className="d-flex ac-jb col-md-7  white"
            style={{
              backgroundColor: "#0082ca",
              borderRadius: "20px",
              padding: "30px 20px",
              margin: "20px 0px 10px",
              lineHeight: "25px",
            }}
          >
            <div>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                SUBTOTAL
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TAX-CGST 6:
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                TOTAL
              </p>
            </div>
            <div>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end">
                Rs9,8767,8765.15
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-end">
                Rs9,5467,865.15
              </p>
<hr style={{margin:"1px", color:"#fff"}}/>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                 INR RS8,500,768.30
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#e5e5e5 ", padding: "20px" }}>
        <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 primary w-90 m-auto">
          TERMS AND CONDITIONS
        </h2>
        <div
          className="d-flex ac-jb w-90 mt-3 flex-wrap  px-4"
          style={{
            backgroundColor: "#fff",
            margin: "auto",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <button
            class=" d-flex ps-3 mt-sm-2"
            style={{
              backgroundColor: "#07679c",
              border: "none",
              borderRadius: "7px",
              padding: "5px",
              color: "#fff",
            }}
          >
            <UnsubscribeIcon />

            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
              Contact us
            </p>
          </button>
          <div className="m-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary mt-sm-2">
              Powered By mHQ
            </p>
          </div>

          <div class="d-flex align-items-center primary mt-sm-2">
          <FacebookRoundedIcon className="me-2"/>

<TwitterIcon className="mx-2"/>

<LinkedInIcon className="mx-2"/>

<InstagramIcon className="mx-2"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceClientA;
