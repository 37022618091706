import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  animatedImg,
  Bottle,
  mailSendImg,
  packImg,
  product,
  profilePic,
  searchIcon,
  tshirt1,
  tshirt2,
  tshirt3,
  uploadIcon,
} from "../../assets/img";

export const dummyDashboardData = [
  {
    slNo: "01",
    siteName: "Intel www.intel.com",
    productName: "Mercury Stainless Steel Bottle",
    status: "Success",
    imgDec: require("../../assets/img/intel-new-cpu-branding.webp"),
    img: require("../../assets/img/download.jpeg"),
  },
  {
    slNo: "02",
    siteName: "Intel www.intel.com",
    productName: "Mercury Stainless Steel Bottle",
    status: "Fail",
    img: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    imgDec: require("../../assets/img/download.jpeg"),
  },
  {
    slNo: "03",
    siteName: "Intel www.intel.com",
    productName: "Mercury Stainless Steel Bottle",
    status: "Processing",
    img: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    imgDec: require("../../assets/img/download.jpeg"),
  },
];

export const productVendorData = [
  {
    slNo: "01",
    vendorId: "VMHQ001",
    entityName: "Ninos IT Solution",
    primaryName: "Charles",
    offer: "Plastic Bottle",
    mobileNo: "9876543210",
    status: true,
  },
  {
    slNo: "02",
    vendorId: "VMHQ002",

    entityName: "GMM Solutions",
    primaryName: "Jake Adams",
    offer: "Water Bottle",
    mobileNo: "9876543210",
    status: true,
  },
];
export const productVendor_list = [
  {
    slNo: "01",
    vendorId: "VMHQ001",
    entityName: "Ecphasis Infotech",
    primaryName: "Charles",
    offer: "Silver Mug",
    mobileNo: "9876543210",
    status: false,
  },
];

export const serviceVendorData = [
  {
    slNo: "02",
    vendorId: "VMHQ002",
    entityName: "Ecphasis Infotech",
    primaryName: "John Rolph",
    offer: "Silver Mug",
    mobileNo: "9876543210",
    status: false,
  },
];
export const serviceVendorDataBoth = [
  {
    slNo: "03",
    vendorId: "VMHQ003",
    entityName: "Ecphasis Infotech",
    primaryName: "Jake Adams",
    offer: "Silver Mug",
    mobileNo: "9876543210",
    status: false,
  },
];

export const adminData = [
  {
    sNo: "1",
    img: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    name: "Charles Kuanda",
    email: "charles @gmail .com",
    role: "Digital Marketing",
    status: true,
  },
  {
    sNo: "2",
    img: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    name: "Sam Dunning",
    email: "sam @gmail .com",
    role: "Developer",
    status: false,
  },
  {
    sNo: "3",
    img: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    name: "Peter Jones",
    email: "peter @gmail .com",
    role: "UI/UX Developer",
    status: true,
  },
  {
    sNo: "4",
    img: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    name: "John Rolph",
    email: "john @gmail .com",
    role: "Product Manager",
    status: true,
  },
];

export const categoryData = [
  {
    sNo: "1",
    img: product,
    name: "T-Shirts",
    status: true,
  },
  {
    sNo: "2",
    img: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    name: "Bags",
    status: false,
  },
  {
    sNo: "3",
    img: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    name: "Shoes",
    status: true,
  },
  {
    sNo: "4",
    img: profilePic,
    name: "Shirts",
    status: true,
  },
];

export const replyData = [
  {
    name: "Roger Daniel",
    referName: "Charles",
    date: "Augest 24, 2023. 3:00 PM",
  },
  {
    name: "Roger Daniel",
    referName: "John",
    date: "Augest 24, 2023. 10:53 AM",
  },
  {
    name: "Roger Daniel",
    referName: "Sam",
    date: "Augest 23, 2023. 3:00 AM",
  },
  {
    name: "Roger Daniel",
    referName: "Peter",
    date: "Augest 22, 2023. 4:50 AM",
  },
  {
    name: "Roger Daniel",
    referName: "SakthiVel",
    date: "Augest 22, 2023. 10:53 AM",
  },
];

export const event_category = [
  {
    id: 1,
    event_name: "Onboarding",
  },
  {
    id: 1,
    event_name: "Relieving Kit",
  },
  {
    id: 1,
    event_name: "Birthday Kit",
  },
];

export const sub_menu = [
  {
    id: 1,
    list: "Game consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 2,
    list: "Household furniture",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 3,
    list: " Home appliances",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 4,
    list: "Mobile phones",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 5,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 6,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 7,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 8,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 9,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 10,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 11,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 12,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];
export const sub_menu_overview = [
  {
    id: 1,
    list: "Game consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 2,
    list: "Household furniture",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 3,
    list: " Home appliances",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 4,
    list: "Mobile phones",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 5,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 6,
    list: "consoles",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];
export const Product_Name = [
  {
    list: "Game consoles",
  },
  {
    list: "Household furniture",
  },
  {
    list: " Home appliances",
  },
  {
    list: "Mobile phones",
  },
  {
    list: "consoles",
  },
  {
    list: "consoles",
  },
  {
    list: "consoles",
  },
  {
    list: "consoles",
  },
  {
    list: "consoles",
  },
  {
    list: "consoles",
  },
  {
    list: "consoles",
  },
  {
    list: "consoles",
  },
];

export const Hsn_type = [
  {
    list: "HSN1001",
  },
  {
    list: "HSN1002",
  },
  {
    list: "HSN1003",
  },
];
export const Hsn_type_Child = [
  {
    list: "HSN1001",
  },
  {
    list: "HSN1002",
  },
  {
    list: "HSN1003",
  },
];
export const Hsn_type_Child2 = [
  {
    list: "CGST-85",
  },
  {
    list: "IGST-70",
  },
  {
    list: "SGST-20",
  },
];
export const Hsn_type_Child3 = [
  {
    list: "CGST-15",
  },
  {
    list: "SGST-50",
  },
  {
    list: "IGST-85",
  },
];
export const Hsn_type_Child4 = [
  {
    list: "CGST-35",
  },
  {
    list: "SGST-25",
  },
  {
    list: "IGST-85",
  },
];
export const Country = [
  {
    list: "India",
  },
  {
    list: "Albania",
  },
  {
    list: "Algeria",
  },
  {
    list: "Andorra",
  },
  {
    list: "Angola",
  },
  {
    list: "Antigua and Barbuda",
  },
  {
    list: "Argentina",
  },
  {
    list: "Armenia",
  },
  {
    list: "Australia",
  },
  {
    list: "Austria",
  },
  {
    list: "Azerbaijan",
  },
  {
    list: "Bahamas",
  },
  {
    list: "Bahrain",
  },
  {
    list: "Bangladesh",
  },
  {
    list: "Barbados",
  },
  {
    list: "Belarus",
  },
  {
    list: "Belgium",
  },
  {
    list: "Belize",
  },
  {
    list: "Benin",
  },
  {
    list: "Bhutan",
  },
  {
    list: "Bolivia",
  },
  {
    list: "Bosnia and Herzegovina",
  },
];

export const supplier = [
  {
    list: "5SK Supplier",
    rat: 1,
  },
  {
    list: "Charles Supplier",
    rat: 2,
  },
  {
    list: "Garment Supplier",
    rat: 3,
  },
  {
    list: " Sakthivel Supplier",
    rat: 4,
  },
  {
    list: " Sakthivel Supplier",
    rat: 5,
  },
];

export const options = {
  loop: true,
  center: false,
  items: 6,
  autoplay: true,
  dots: false,
  autoplayTimeout: 5000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
      margin: 5,
    },
    576: {
      items: 2,
      margin: 5,
    },
    767: {
      items: 3,
      margin: 10,
    },
    1199: {
      items: 3,
      margin: 10,
    },
    1600: {
      items: 5,
      margin: 10,
    },
  },
};
export const dummyImages = [
  {
    imgUrl: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    name: "Intel",
    description: "Description",
  },
  {
    imgUrl: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    name: "Intel",
    description: "Description",
  },
  {
    imgUrl: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    name: "Intel",
    description: "Description",
  },
];

export const posBills = [
  {
    vendorId: "VMHQ001",
    name: "Roger",
    poNo: "PO1234",
    soNo: "SO001",
    productName: "Bags",
    qty: 100,
    value: "Data details",
  },
];

export const collaborateList = [
  {
    vendorId: "VMHQ001",
    // VendorName: "Charles Roger",
    collaborat_id: "CLID1001",
    name: "Roger Daniel",
    description: "Product Description",
    file: require("../../assets/img/58568d224f6ae202fedf2720.png"),
    sendBy: "Admin",
    time: "Augest 22,2023 10:53AM",
    status: "Open",
  },
];

export const tagSuggestion = [
  { data: "Shipping" },
  { data: "Labelling" },
  { data: "Both" },
];

export const catogory_setting = [
  {
    catogory: "Inches",
  },
  {
    catogory: "Stitch",
  },
];

export const counting = [
  {
    count: 1,
  },
  {
    count: 2,
  },
  {
    count: 3,
  },
  {
    count: 4,
  },
  {
    count: 5,
  },
  {
    count: 6,
  },
];
export const customerList = [
  {
    CustomerId: "CMHQ001",
    reqId: "RMHQ001",
    email: "Charles@ninosit.com",
    name: "Charles",
    lname: "Kaunda",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    reqId: "RMHQ002",
    CustomerId: "CMHQ002",
    email: "Amelia@ninosit.com",
    name: "Amelia",
    lname: "Reddy",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: false,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "CMHQ003",
    reqId: "RMHQ003",

    email: "AbineshKumar@gmail.com",
    name: "Abinesh",
    lname: "Kumar",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: false,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "CMHQ004",
    reqId: "RMHQ004",

    email: "Sathish@ghj.com",
    name: "Sathish",
    lname: "Baskar",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "CMHQ005",
    reqId: "RMHQ005",

    email: "Gopal@ghj.com",
    name: "Gopal",
    lname: "Varma",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "CMHQ006",
    reqId: "RMHQ006",

    email: "98765@ghj.com",
    name: "Sundara",
    lname: "Moorthi",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "CMHQ007",
    reqId: "RMHQ007",

    email: "98765@ghj.com",
    name: "Krishna",
    lname: "Prasadh",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: false,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
];

export const enterprisesList = [
  {
    CustomerId: "EPC001",
    reqId: "RMHQ001",
    email: "Charles@ninosit.com",
    name: "Charles",
    lname: "Kaunda",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    reqId: "RMHQ002",
    CustomerId: "EPC002",
    email: "Amelia@ninosit.com",
    name: "Amelia",
    lname: "Reddy",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: false,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "EPC003",
    reqId: "RMHQ003",

    email: "AbineshKumar@gmail.com",
    name: "Abinesh",
    lname: "Kumar",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: false,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "EPC004",
    reqId: "RMHQ004",

    email: "Sathish@ghj.com",
    name: "Sathish",
    lname: "Baskar",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "EPC005",
    reqId: "RMHQ005",

    email: "Gopal@ghj.com",
    name: "Gopal",
    lname: "Varma",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "EPC006",
    reqId: "RMHQ006",

    email: "98765@ghj.com",
    name: "Sundara",
    lname: "Moorthi",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "EPC007",
    reqId: "RMHQ007",

    email: "98765@ghj.com",
    name: "Krishna",
    lname: "Prasadh",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: false,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
];

export const shippingAddress = [
  {
    CustomerId: "CMHQ001",
    reqId: "RMHQ001",

    email: "98765@ghj.com",
    name: "First Name",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    reqId: "RMHQ002",
    CustomerId: "CMHQ002",
    email: "98765@ghj.com",
    name: "First Name",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: false,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "CMHQ003",
    reqId: "RMHQ003",

    email: "98765@ghj.com",
    name: "First Name",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: false,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
  {
    CustomerId: "CMHQ004",
    reqId: "RMHQ004",

    email: "98765@ghj.com",
    name: "First Name",
    phoneNo: "9876543210",
    organization: "Ninos IT Solution",
    custType: "E-Commerce",
    status: true,
    reqCount: 10,

    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
  },
];

export const noncustomerDecoList = [
  {
    slNo: "1",
    orderId: "MNMN3019",
    customerName: "Json",
    customerId: "NCMHQ001",
    reqId: "RMHQ001",
    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 20,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: true,
    OrderRepname: "OR764425",
    ClientRepName: "CR809873",
  },
  {
    slNo: "2",
    orderId: "MNMN3020",
    customerName: "Json",
    customerId: "NCMHQ002",
    reqId: "RMHQ002",

    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 20,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: false,
    OrderRepname: "OR765625",
    ClientRepName: "CR896873",
  },
  {
    slNo: "3",
    orderId: "MNMN3029",
    customerName: "Json",
    customerId: "NCMHQ003",
    reqId: "RMHQ003",

    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 100,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: true,
    OrderRepname: "-",
    ClientRepName: "-",
  },
];

export const nonEnterprisesDecoList = [
  {
    slNo: "1",
    orderId: "MNMN3019",
    customerName: "Json",
    customerId: "NEPC001",
    reqId: "RMHQ001",
    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 20,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: true,
    OrderRepname: "OR764425",
    ClientRepName: "CR809873",
  },
  {
    slNo: "2",
    orderId: "MNMN3020",
    customerName: "Json",
    customerId: "NEPC002",
    reqId: "RMHQ002",

    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 20,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: false,
    OrderRepname: "OR765625",
    ClientRepName: "CR896873",
  },
  {
    slNo: "3",
    orderId: "MNMN3029",
    customerName: "Json",
    customerId: "NEPC003",
    reqId: "RMHQ003",

    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 100,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: true,
    OrderRepname: "-",
    ClientRepName: "-",
  },
];

export const customerDecoList = [
  {
    slNo: "1",
    orderId: "MNMN3019",
    customerName: "Json",
    customerId: "CMHQ001",
    reqId: "RMHQ001",
    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 20,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: true,
    OrderRepname: "-",
    ClientRepName: "-",
  },
  {
    slNo: "2",
    orderId: "MNMN3020",
    customerName: "Json",
    customerId: "CMHQ002",
    reqId: "RMHQ002",

    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 20,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: false,
    OrderRepname: "OR765625",
    ClientRepName: "CR896873",
  },
  {
    slNo: "3",
    orderId: "MNMN3029",
    customerName: "Json",
    customerId: "CMHQ003",
    reqId: "RMHQ003",

    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 100,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: true,
    OrderRepname: "OR87672",
    ClientRepName: "CR87637",
  },
];

export const enterprisesDecoList = [
  {
    slNo: "1",
    orderId: "MNMN3019",
    customerName: "Json",
    customerId: "EPC001",
    reqId: "RMHQ001",
    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 20,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: true,
    OrderRepname: "-",
    ClientRepName: "-",
  },
  {
    slNo: "2",
    orderId: "MNMN3020",
    customerName: "Json",
    customerId: "EPC002",
    reqId: "RMHQ002",

    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 20,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: false,
    OrderRepname: "OR765625",
    ClientRepName: "CR896873",
  },
  {
    slNo: "3",
    orderId: "MNMN3029",
    customerName: "Json",
    customerId: "EPC003",
    reqId: "RMHQ003",

    email: "json@gmail.com",
    phoneNo: "9876543210",
    productName: "Bags and Boxes",
    categoryType: "Things",
    qtySize: 100,
    NoOfLog: "4",
    price: 100,
    img: require("../../assets/img/intel-new-cpu-branding.webp"),
    ContactDetails:
      "No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai - 600099, Tamil Nadu, India.",
    status: true,
    OrderRepname: "OR87672",
    ClientRepName: "CR87637",
  },
];

export const selecLibList = [
  {
    name: "Hoodie",
    price: "$10.00 (INR)",
    data: "#5SK 001",
    img: require("../../assets/img/product_detail_img.png"),
    description: "Description should be more",
  },
  {
    name: "Intel Cup",
    price: "$10.00 (INR)",
    data: "#5SK 001",
    img: require("../../assets/img/download.jpeg"),

    description: "Description should be more",
  },
  {
    name: "Hoodie",
    price: "$10.00 (INR)",
    data: "#5SK 001",
    img: require("../../assets/img/product_detail_img.png"),

    description: "Description should be more",
  },
  {
    name: "Intel Cup",
    price: "$10.00 (INR)",
    data: "#5SK 001",
    img: require("../../assets/img/download.jpeg"),

    description: "Description should be more",
  },
];

export const productTableData = [
  {
    catType: "convenience Goods",
    productName: "Veggies",
    qty: 2,
    price: 20,
  },
  { catType: "Shoppig Goods", productName: "Veggies", qty: 4, price: 20 },
  { catType: "Toys", productName: "Doll", qty: 6, price: 20 },
  { catType: "Goods", productName: "Wooden", qty: 8, price: 20 },
  { catType: "Products", productName: "Bags", qty: 2, price: 20 },
];

export const owl_List = [
  { image: product },
  { image: product },
  { image: product },
  { image: product },
  { image: product },
  { image: product },
  { image: product },
  { image: product },
];

export const product_image = [
  {
    image: profilePic,
  },
];

export const categoryList = [
  {
    image: product,
    name: "Hoodies",
  },
  {
    image: profilePic,
    name: "Shirts",
  },
  {
    image: product,
    name: "T-Shirts",
  },
];

export const subCategoryList = [
  {
    name: "Mens",
    code: "MEN001",
  },
  {
    name: "Womens",
    code: "WOMEN001",
  },
  {
    name: "Popular",
    code: "POP001",
  },
  {
    name: "Top Rated",
    code: "TOP001",
  },
];

export const decorativeMethodList = [
  { name: "Screen Printing", image: uploadIcon, active: 1 },
  { name: "UV printing", image: searchIcon, active: 1 },
  { name: "DTF printing", image: uploadIcon, active: 0 },
  { name: "Engraving", image: product, active: 1 },
  { name: "Embossing", image: profilePic, active: 1 },
  {
    name: "Dye Sublimation",
    image: require("../../assets/img/download.jpeg"),
    active: 0,
  },
  {
    name: "Foil Printing",
    image: require("../../assets/img/product_detail_img.png"),
    active: 0,
  },
];

export const hsnListData = [
  {
    hsn: "61091000",
    product: "TShirts cotton",
    sgst: "12%",
    cgst: "12%",
    igst: "24%",
    active: true,
  },
  {
    hsn: "61051000",
    product: "Shirts cotton",
    cgst: "4%",
    sgst: "4%",
    active: false,
  },
  {
    hsn: "61051000",
    product: "Tshirts polyester",
    sgst: "8%",
    cgst: "8%",
    active: true,
  },
  { hsn: "65050090", product: "Caps", igst: "12%", active: false },
  {
    hsn: "61051000",
    product: "Jackets",
    igst: "28%",
    active: true,
  },
];

export const pricebookList = [
  {
    pricebook_name: "AMZ",
    enterprises_name: "Amzon",
    pricebook_margin: "12%",
    status: 1,
  },
  {
    pricebook_name: "KIT",
    enterprises_name: "KIT Promos",
    pricebook_margin: "20%",
    status: 0,
  },
  {
    pricebook_name: "ALL",
    enterprises_name: "All",
    pricebook_margin: "13%",
    status: 1,
  },
  {
    pricebook_name: "AMZ",
    enterprises_name: "Amazon",
    pricebook_margin: "12%",
    status: 0,
  },
  {
    pricebook_name: "AB",
    enterprises_name: "Ab Prime",
    pricebook_margin: "16%",
    status: 1,
  },
];

export const pricebookLists = [
  {
    pricebook_name: "AMZ",
    enterprises_name: "Amzon",
    pricebook_margin: "12%",
    status: 1,
  },
  {
    pricebook_name: "AMZ01",
    enterprises_name: "Amzon",
    pricebook_margin: "20%",
    status: 0,
  },
  {
    pricebook_name: "AMZ02",
    enterprises_name: "Amzon",
    pricebook_margin: "13%",
    status: 1,
  },
  {
    pricebook_name: "AMZ03",
    enterprises_name: "Amazon",
    pricebook_margin: "12%",
    status: 0,
  },
  {
    pricebook_name: "AMZ04",
    enterprises_name: "Amzon",
    pricebook_margin: "16%",
    status: 1,
  },
];

export const attributeList = [
  { name: "Dimension", image: uploadIcon, active: 1 },
  { name: "Weight", image: searchIcon, active: 0 },
];

export const catRolesList = [
  { name: "Sales", image: uploadIcon, active: 1 },
  { name: "Design", image: searchIcon, active: 0 },
];

export const relesList = [
  { name: "Super Admin", image: uploadIcon, active: 1 },
  { name: "Admin", image: searchIcon, active: 0 },
];

export const vendor_category = [
  {
    list: "Bags",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Mobile",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Bottle",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Shoes",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Books",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const line_business = [
  {
    id: 1,
    list: "Enterprise",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 2,
    list: "MHQ",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 3,
    list: "Market Place",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const static_attributes = [
  {
    id: 1,
    list: "Size",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 2,
    list: "Color",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 3,
    list: "Material",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 4,
    list: "Style",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const dynamic_attributes = [
  {
    id: 1,
    list: "Dimension",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 2,
    list: "Weight",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const type_business = [
  {
    list: "MSME",
  },
  {
    list: "Proprietorship",
  },
  {
    list: "Partnership",
  },
  {
    list: "Pvt Ltd",
  },
  {
    list: "LLP",
  },
  {
    list: "Public Limited",
  },
  {
    list: "others",
  },
];

export const Vendor_offer_type = [
  {
    list: "Prodcut",
  },
  {
    list: "Services",
  },
  {
    list: "Both",
  },
];
export const Vendor_offer = [
  {
    list: "MHQ",
  },
];
export const payment_terms = [
  {
    list1: "Immediate",
    list2: "0-7 Days",
    list3: "7-14 Days ",
    list4: "14-30 Days ",
    list5: "30-45 Days",
    list6: "45-60 Days",
    list7: "3 Days",
    list8: "7 Days",
    list9: "14 Days",
    list10: "30 Days",
    list11: "45 Days",
    list12: "60 Days",
    list13: "50% advance",
  },
];

export const payment_Method = [
  {
    id: 1,
    list: "Bank Transfer",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 2,
    list: "Card",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 3,
    list: "UPI",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 4,
    list: "Others",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const address_type = [
  {
    list: "Shipping",
  },
  {
    list: "Billing",
  },
  {
    list: "Both",
  },
];

export const currency_list = [
  {
    list: "INR",
  },
  {
    list: "Euro",
  },
  {
    list: "Dollar",
  },
];

export const colors = [
  {
    color: "red",
    id: 1,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    color: "blue",
    id: 2,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    color: "black",
    id: 3,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    color: "Pink",
    id: 4,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    color: "skyblue",
    id: 5,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    color: "Yellow",
    id: 6,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    color: "Brown",
    id: 7,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    color: "purple",
    id: 8,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const colorsAndSize = [
  {
    color: "XL/Red/Classic/Gold",
    id: 1,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    color: "XL/Blue/Classic/Gold",
    id: 2,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    color: "XL/Blue/Standard/Gold",
    id: 3,
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const Select_list = [
  {
    list: "Size",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  // {
  //   list: "Weight",
  //   check: <CheckCircleIcon className="primary" />,
  //   uncheck: <RadioButtonUncheckedIcon className="primary" />,
  // },
  // {
  //   list: "Dimention",
  //   check: <CheckCircleIcon className="primary" />,
  //   uncheck: <RadioButtonUncheckedIcon className="primary" />,
  // },
  {
    list: "Color",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  // {
  //   list: "Meterial",
  //   check: <CheckCircleIcon className="primary" />,
  //   uncheck: <RadioButtonUncheckedIcon className="primary" />,
  // },
  {
    list: "Style",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Materials",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const selected_list = [
  {
    list: "Mobile",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Bags",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Bottle",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const salesForce = [
  {
    custRep: "Roger Daniels",
    orderRep: "Roger Daniels",
    project: {
      id: "#28282 - Ninos Demo",
      company: "CompanyStore.IO",
    },
    stage: {
      order: "Sales Order",
      status: "New",
    },
    overdue: "$ 0",
    oct: null,
    nov: null,
    dec: null,
    nextTask: "sample test",
    taskDate: "2023-10-18",
    client: "5SK",
    projectName: "sample",
    progressBar: {
      type: "ideation",
      percentage: "30",
    },
  },
  {
    custRep: "Roger Daniels",
    orderRep: "Roger Daniels",
    project: {
      id: "#25382 - Ninos 2",
      company: "CompanyStore.IO",
    },
    stage: {
      order: "Sales Order",
      status: "In Production",
    },
    overdue: "$ 35,814",
    oct: null,
    nov: null,
    dec: null,
    nextTask: "New Task",
    taskDate: "2023-10-18",
    client: "5SK",
    projectName: "demo 1",
    progressBar: {
      type: "in-production",
      percentage: "60",
    },
  },
  {
    custRep: "Roger Daniels",
    orderRep: "Roger Daniels",
    project: {
      id: "#39399 - Sample",
      company: "5SK",
    },
    stage: {
      order: "Presentation",
      status: "Open",
    },
    overdue: null,
    oct: null,
    nov: null,
    dec: "$ 250,000",
    nextTask: "Create product Deck",
    taskDate: "2023-10-18",
    client: "CompanyStore.IO",
    projectName: "New Hire Kit",
    progressBar: {
      type: "sales-order",
      percentage: "20",
    },
  },
  {
    custRep: "Roger Daniels",
    orderRep: "Roger Daniels",
    project: {
      id: "#39399 - Demo 2",
      company: "15SK",
    },
    stage: {
      order: "Sales Order",
      status: "Client Approved",
    },
    overdue: "$ 1000",
    oct: null,
    nov: "$ 2913",
    dec: null,
    nextTask: "I am adding some sample data",
    taskDate: "2023-10-18",
    client: "Camel Pen client",
    projectName: "Camel Pen Market",
    progressBar: {
      type: "invoice",
      percentage: "50",
    },
  },
];

export const salesForceEcom = [
  {
    custRep: "Roger Daniels",
    orderRep: "Roger Daniels",
    project: {
      id: "John",
      company: "johnwick.com",
    },
    stage: {
      order: "Sales Order",
      status: "New",
    },
    overdue: "$ 0",
    oct: null,
    nov: null,
    dec: null,
    nextTask: "sample test",
    taskDate: "2023-10-18",
    client: "5SK",
    projectName: "sample",
    progressBar: {
      type: "ideation",
      percentage: "30",
    },
  },
  {
    custRep: "Roger Daniels",
    orderRep: "Roger Daniels",
    project: {
      id: "tony",
      company: "tonystark.co.in",
    },
    stage: {
      order: "Sales Order",
      status: "In Production",
    },
    overdue: "$ 35,814",
    oct: null,
    nov: null,
    dec: null,
    nextTask: "New Task",
    taskDate: "2023-10-18",
    client: "5SK",
    projectName: "demo 1",
    progressBar: {
      type: "in-production",
      percentage: "60",
    },
  },
  {
    custRep: "Roger Daniels",
    orderRep: "Roger Daniels",
    project: {
      id: "steve",
      company: "steveroger.in",
    },
    stage: {
      order: "Presentation",
      status: "Open",
    },
    overdue: null,
    oct: null,
    nov: null,
    dec: "$ 250,000",
    nextTask: "Create product Deck",
    taskDate: "2023-10-18",
    client: "CompanyStore.IO",
    projectName: "New Hire Kit",
    progressBar: {
      type: "sales-order",
      percentage: "20",
    },
  },
  {
    custRep: "Roger Daniels",
    orderRep: "Roger Daniels",
    project: {
      id: "clint",
      company: "clintbarton.com",
    },
    stage: {
      order: "Sales Order",
      status: "Client Approved",
    },
    overdue: "$ 1000",
    oct: null,
    nov: "$ 2913",
    dec: null,
    nextTask: "I am adding some sample data",
    taskDate: "2023-10-18",
    client: "Camel Pen client",
    projectName: "Camel Pen Market",
    progressBar: {
      type: "invoice",
      percentage: "50",
    },
  },
];
export const Select_list2 = [
  // {
  //   list: "Size",
  //   check: <CheckCircleIcon className="primary" />,
  //   uncheck: <RadioButtonUncheckedIcon className="primary" />
  // },
  {
    list: "Weight",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Dimention",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  // {
  //   list: "Color",
  //   check: <CheckCircleIcon className="primary" />,
  //   uncheck: <RadioButtonUncheckedIcon className="primary" />
  // },
  // {
  //   list: "Materials",
  //   check: <CheckCircleIcon className="primary" />,
  //   uncheck: <RadioButtonUncheckedIcon className="primary" />
  // },
  // {
  //   list: "Style",
  //   check: <CheckCircleIcon className="primary" />,
  //   uncheck: <RadioButtonUncheckedIcon className="primary" />
  // },
  // {
  //   list: 'Materials used in construction',
  //   check: <CheckCircleIcon className="primary" />,
  //   uncheck: <RadioButtonUncheckedIcon className="primary" />
  // }
];

export const img_list = [
  {
    list: profilePic,
    icon: <CheckCircleIcon className="primary position-absolute icon_posi" />,
  },
  {
    list: product,
    icon: <CheckCircleIcon className="primary position-absolute icon_posi" />,
  },
  {
    list: animatedImg,
    icon: <CheckCircleIcon className="primary position-absolute icon_posi" />,
  },
  {
    list: mailSendImg,
    icon: <CheckCircleIcon className="primary position-absolute icon_posi" />,
  },
];

export const state_list = [
  {
    list: "Tamil Nadu ",
  },
  {
    list: "Andhra Pradesh",
  },
  {
    list: "Arunachal Pradesh ",
  },
  {
    list: "Assam",
  },
  {
    list: "Goa ",
  },
  {
    list: "Gujarat ",
  },
];

export const department_list = [
  { list: "Accounting" },
  { list: "Administration" },
  { list: "Executive" },
  { list: "Human Resources" },
  { list: "Marketing" },
  { list: "Other" },
  { list: "Purchasing" },
  { list: "Sales" },
  { list: "Shop" },
];
export const addres_list = [
  { list: "Kodambakkam" },
  { list: "T-Nagar" },
  { list: "Vadapalani, Chennai" },
  { list: "Redhils, Chennai" },
];
export const phone_list = [
  { list: "WORK" },
  { list: "HOME" },
  { list: "FAX" },
  { list: "CELL" },
];

export const size_comp = [
  {
    list: "M",
  },
  {
    list: "TBD",
  },
  {
    list: "XL",
  },
];

export const overview_input1 = [
  { list: "Show everyones comments" },
  { list: "Roger Daniel" },
];
export const overview_input2 = [
  { list: "All" },
  { list: "Presentation" },
  { list: "Task" },
];

export const Compact_table = [
  {
    sku_code: "301WH1026",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "Ready",
  },
  {
    sku_code: "301WH1027",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "In Process",
  },
  {
    sku_code: "301WH1028",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "In Process",
  },
  {
    sku_code: "301WH1029",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "Estimate Rejected",
  },
];
export const Compact_table2 = [
  {
    sku_code: "301WH1026",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "Ready",
  },
  {
    sku_code: "301WH1027",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "Ready",
  },
  {
    sku_code: "301WH1028",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "Ready",
  },
  {
    sku_code: "301WH1029",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "Ready",
  },
];

export const sales_compact_table = [
  {
    sku_code: "301WH1026",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "Client Paid",
  },
  {
    sku_code: "301WH1027",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    // status: "Payment Pending",
    status: "Client Paid",
  },
  {
    sku_code: "301WH1028",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    // status: "Payment Pending",
    status: "Client Paid",
  },
  {
    sku_code: "301WH1029",
    name: " Sample(0571 x2415039)",
    para: "stainless street Hot N Cold Bottle",
    dis: "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop *Keeps liquid Hot & Cold: 12H-18Hr",
    units: 24,
    margin: "4.99%",
    tax: "CGST (6%)",
    amount: "$1010.45",
    status: "Client Paid",
  },
];

export const DefaultTax = [
  {
    list: "Highest tax",
  },
  {
    list: "Individual tax",
  },
];
// f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16

export const product_library = [
  {
    img: product,
    price: "₹200/-",
    pricebook_code: "PKC0001",
    product_code: "SKU0001",
    product_name: "SHIRT",
  },
  {
    img: product,
    price: "₹200/-",
    pricebook_code: "PKC0001",
    product_code: "SKU0001",
    product_name: "SHIRT",
  },
  {
    img: product,
    price: "₹200/-",
    pricebook_code: "PKC0001",
    product_code: "SKU0001",
    product_name: "SHIRT",
  },
  {
    img: product,
    price: "₹200/-",
    pricebook_code: "PKC0001",
    product_code: "SKU0001",
    product_name: "SHIRT",
  },
  {
    img: product,
    price: "₹200/-",
    pricebook_code: "PKC0001",
    product_code: "SKU0001",
    product_name: "SHIRT",
  },
];

export const entreprisename = [
  {
    list: "All Supplier",
  },
  {
    list: "Amazon",
  },
  {
    list: "Ninos",
  },
  {
    list: "FlipCart",
  },
];

export const Accept_Tic = [
  {
    collabrate_id: "CLID1001",
    project_id: "PRO001",
    prodect: "Shirt",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Open",
  },
  {
    collabrate_id: "CLID1002",
    project_id: "PRO002",
    prodect: "Shirt",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Open",
  },
  {
    collabrate_id: "CLID1003",
    project_id: "PRO003",
    prodect: "Pants",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Open",
  },
  {
    collabrate_id: "CLID1004",
    project_id: "PRO004",
    prodect: "Shirt",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Open",
  },
  {
    collabrate_id: "CLID1005",
    project_id: "PRO005",
    prodect: "Pants",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Open",
  },
];
export const Accept_Tic_close = [
  {
    collabrate_id: "CLID1001",
    project_id: "PRO001",
    prodect: "Shirt",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Close",
  },
  {
    collabrate_id: "CLID1002",
    project_id: "PRO002",
    prodect: "Shirt",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Close",
  },
  {
    collabrate_id: "CLID1003",
    project_id: "PRO003",
    prodect: "Pants",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Close",
  },
  {
    collabrate_id: "CLID1004",
    project_id: "PRO004",
    prodect: "Shirt",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Close",
  },
  {
    collabrate_id: "CLID1005",
    project_id: "PRO005",
    prodect: "Pants",
    requst_by: "Sakthi",
    assignd: "Vishwa",
    date: "22/01/2024 ",
    time: "2:30PM",
    ticket_status: "Close",
  },
];

export const ourPacks = [
  {
    welcome_pack: "$49/pack",
    status: 1,
    priority: 0,
  },
  {
    welcome_pack: "$99/pack",
    status: 1,
    priority: 1,
  },
  {
    welcome_pack: "$79/pack",
    status: 0,
    priority: 1,
  },
  {
    welcome_pack: "$149/pack",
    status: 1,
    priority: 1,
  },
  {
    welcome_pack: "$169/pack",
    status: 0,
    priority: 0,
  },
  {
    welcome_pack: "$129/pack",
    status: 1,
    priority: 0,
  },
];

export const ChatProfile = [
  {
    id: 1,
    ClientID: "CLI_001",
    first_name: "Dhanush",
    email: "dhanush@gmail.com",
    phoneNumber: "9876543133",
    projectId: "PO-001",
    date: "23/2/2024",
    status: "Not Opened",
    image_url:
      "https://static.vecteezy.com/system/resources/thumbnails/019/900/322/small/happy-young-cute-illustration-face-profile-png.png",
  },
  {
    id: 2,
    ClientID: "CLI_002",
    first_name: "Dhanush",
    email: "dhanush@gmail.com",
    phoneNumber: "9876543133",
    projectId: "PO-002",
    date: "23/2/2024",
    status: "Opened",
    image_url:
      "https://static.vecteezy.com/system/resources/thumbnails/019/900/322/small/happy-young-cute-illustration-face-profile-png.png",
  },
  {
    id: 3,
    ClientID: "CLI_003",
    first_name: "Dhanush",
    email: "dhanush@gmail.com",
    phoneNumber: "9876543133",
    projectId: "PO-003",
    date: "23/2/2024",
    status: "Not Opened",
    image_url:
      "https://static.vecteezy.com/system/resources/thumbnails/019/900/322/small/happy-young-cute-illustration-face-profile-png.png",
  },
  {
    id: 4,
    ClientID: "CLI_004",
    first_name: "Dhanush",
    email: "dhanush@gmail.com",
    phoneNumber: "9876543133",
    projectId: "PO-004",
    date: "23/2/2024",
    status: "Opened",
    image_url:
      "https://static.vecteezy.com/system/resources/thumbnails/019/900/322/small/happy-young-cute-illustration-face-profile-png.png",
  },
];
export const ChatUserData = [
  {
    name: "Dhanush",
    Vendor: "Ven_001",
    Email: "dhanush@gmail.com",
    Mobile: "+91 99999 90908",
    PoId: "PO-002",
    image_url:
      "https://static.vecteezy.com/system/resources/thumbnails/019/900/322/small/happy-young-cute-illustration-face-profile-png.png",
  },
];

export const mockup_artwork = [
  {
    id: 1,
    project_id: "PR-001",
    project_name: "TCS",
    customer_id: "Char-05",
    estimate_id: "Char-05",
    order_rep: "lorm ipsum",
    client_rep: "lorm ipsum",
    customer_status: "Inprocess",
    disign_status: "Requested",
    sale_status: "Pending",
    design_inprocess: "Completed",
    sale_inprocess: "Pending",
    design_reject: "Re-do",
    sale_reject: "Rejected",
    design_accepted: "Approved",
    sale_accepted: "Approved",
    presentation_status: "Approved",
    Estimate_status: "Approved",
    design_art_rej: "Artwork Redo",
    sale_art_rej: "Rejected",
    design_art_appr: "Artwork Approved",
    sale_art_appr: "Artwork Approved",
    design_art_inp: "Artwork Completed",
    sale_art_inp: "Pending",
    design_art_req: "Artwork Requested",
    sale_art_req: "Pending",
    Customer_status_art: "Approved",
  },
  {
    id: 2,
    project_id: "PR-002",
    project_name: "HCL",
    customer_id: "Joh-06",
    estimate_id: "Joh-06",
    order_rep: "lorm ipsum",
    client_rep: "lorm ipsum",
    customer_status: "Approved",
    disign_status: "Requested",
    sale_status: "Pending",
    design_inprocess: "Completed",
    sale_inprocess: "Pending",
    design_reject: "Re-do",
    sale_reject: "Rejected",
    design_accepted: "Approved",
    sale_accepted: "Approved",
    presentation_status: "Approved",
    Estimate_status: "Approved",
    design_art_rej: "Artwork Redo",
    sale_art_rej: "Rejected",
    design_art_appr: "Artwork Approved",
    sale_art_appr: "Artwork Approved",
    design_art_inp: "Artwork Completed",
    sale_art_inp: "Pending",
    design_art_req: "Artwork Requested",
    sale_art_req: "Pending",
    Customer_status_art: "Approved",
  },
  {
    id: 3,
    project_id: "PR-003",
    project_name: "ATIT",
    customer_id: "Alb-07",
    estimate_id: "Alb-07",
    order_rep: "lorm ipsum",
    client_rep: "lorm ipsum",
    customer_status: "Rejected  ",
    disign_status: "Requested",
    sale_status: "Pending",
    design_inprocess: "Completed",
    sale_inprocess: "Pending",
    design_reject: "Re-do",
    sale_reject: "Rejected",
    design_accepted: "Approved",
    sale_accepted: "Approved",
    presentation_status: "Approved",
    Estimate_status: "Approved",
    design_art_rej: "Artwork Redo",
    sale_art_rej: "Rejected",
    design_art_appr: "Artwork Approved",
    sale_art_appr: "Artwork Approved",
    design_art_inp: "Artwork Completed",
    sale_art_inp: "Pending",
    design_art_req: "Artwork Requested",
    sale_art_req: "Pending",
    Customer_status_art: "Approved",
  },
];
export const Estimate_req = [
  {
    id: 1,
    project_id: "PR-001",
    project_name: "TCS",
    customer_id: "Char-05",
    estimate_id: "Char-05",
    order_rep: "lorm ipsum",
    client_rep: "lorm ipsum",
    customer_status: "Inprocess",
    salse_list: "Accepted",
    sales_pro: "InProcess",
    sales_rej: "Rejected",
  },
  {
    id: 2,
    project_id: "PR-002",
    project_name: "HCL",
    customer_id: "Joh-06",
    estimate_id: "Joh-06",
    order_rep: "lorm ipsum",
    client_rep: "lorm ipsum",
    customer_status: "Approved",
    salse_list: "Accepted",
    sales_pro: "InProcess",
    sales_rej: "Rejected",
  },
  {
    id: 3,
    project_id: "PR-003",
    project_name: "ATIT",
    customer_id: "Alb-07",
    estimate_id: "Alb-07",
    order_rep: "lorm ipsum",
    client_rep: "lorm ipsum",
    customer_status: "Rejected",
    salse_list: "Accepted",
    sales_pro: "InProcess",
    sales_rej: "Rejected",
  },
];
export const pre_artwork = [
  {
    id: 1,
    Project_ID: "PR-001",
    Project_Name: "TCS",
    Customer_ID: "Char-05",
    SKU_Code: "SKU005",
    Product_Name: "Hoodie",
    Estimated_Quantity: "500",
    Sales_Status: "Inprocess",
    Client_Status: "Inprocess",
    Release_Date: " 05-05-2024",
  },
  {
    id: 2,
    Project_ID: "PR-002",
    Project_Name: "HCL",
    Customer_ID: "Char-06",
    SKU_Code: "SKU006",
    Product_Name: "T-shirt",
    Estimated_Quantity: "300",
    Sales_Status: "Approved",
    Client_Status: "Approved",
    Release_Date: " 06-06-2024",
  },
  {
    id: 3,
    Project_ID: "PR-003",
    Project_Name: "ATIT",
    Customer_ID: "Char-07",
    SKU_Code: "SKU007",
    Product_Name: "Water Bottle",
    Estimated_Quantity: "900",
    Sales_Status: "Inprocess",
    Client_Status: "Inprocess",
    Release_Date: " 07-07-2024",
  },
];

export const Price_Request = [
  {
    id: 1,
    Request_ID: "REQ001",
    Project_id: "PRO001",
    Project_Name: "Ninos Enterprises",
    Product_Name: "T-Shirts",
    Max_Margin: "15%",
    Min_Margin: "12%",
    Vendor_Margin: "10%",
    Status: "Accepted",
  },
  {
    id: 2,
    Request_ID: "REQ002",
    Project_id: "PRO002",
    Project_Name: "Ninos Enterprises",
    Product_Name: "Hoodies",
    Max_Margin: "15%",
    Min_Margin: "12%",
    Vendor_Margin: "10%",
    Status: "Accepted",
  },
  {
    id: 3,
    Request_ID: "REQ003",
    Project_id: "PRO003",
    Project_Name: "Ninos Enterprises",
    Product_Name: "T-shirts",
    Max_Margin: "15%",
    Min_Margin: "12%",
    Vendor_Margin: "10%",
    Status: "Rejected",
  },
];

export const Shipping_supplier = [
  {
    id: "SKU-001 Steel Hot",
    img_list: product,
    ship_name: "Chennai",
    ship_inhand: "12/12/23",
    ship_To: "Vishwa",
    Address:
      "52, 100 Feet Rd, Sidharata Colony, Santhosapuram, Koramangala 2nd Block, Koramangala, Bengaluru, Karnataka 560034, India",
    Method: "Ground",
  },
  {
    id: "SKU-002 Water Bottle",
    img_list: Bottle,
    ship_name: "Chennai",
    ship_inhand: "12/12/23",
    ship_To: "Vishwa",
    Address:
      "52, 100 Feet Rd, Sidharata Colony, Santhosapuram, Koramangala 2nd Block, Koramangala, Bengaluru, Karnataka 560034, India",
    Method: "Ground",
  },
  {
    id: "SKU-003 Pack Bag",
    img_list: packImg,
    ship_name: "Chennai",
    ship_inhand: "12/12/23",
    ship_To: "Vishwa",
    Address:
      "52, 100 Feet Rd, Sidharata Colony, Santhosapuram, Koramangala 2nd Block, Koramangala, Bengaluru, Karnataka 560034, India",
    Method: "Ground",
  },
  {
    id: "SKU-004 Rare T-shirt",
    img_list: product,
    ship_name: "Chennai",
    ship_inhand: "12/12/23",
    ship_To: "Vishwa",
    Address:
      "52, 100 Feet Rd, Sidharata Colony, Santhosapuram, Koramangala 2nd Block, Koramangala, Bengaluru, Karnataka 560034, India",
    Method: "Ground",
  },
];
export const Po_products = [
  {
    img_po: product,
    id: "SKU-001",
    po: "PO-001",
    POD: "5SK supplier",
  },
  {
    img_po: Bottle,
    id: "SKU-002",
    po: "PO-002",
    POD: "5SK Designer supplier",
  },
  {
    img_po: packImg,
    id: "SKU-001",
    po: "PO-001",
    POD: "5SK supplier",
  },
  {
    img_po: product,
    id: "SKU-001",
    po: "PO-001",
    POD: "5SK Designer supplier",
  },
];
export const Supplier_Details = [
  {
    id: 1,
    id1: 1,
    id2: 2,
    id3: 3,
    id4: 4,
    ware_num: "20",
    WareLocation1: "(Chennai)",
    WareLocation2: "(Salem)",
    WareLocation3: "(Banglore)",
    In_Date: "12/12/23",
    Decor_name: "Vishwa",
    client_name1: "Hari",
    client_name2: "Bala",
    client_name3: "Sathish",
    decor_num1: "7",
    decor_num2: "8",
    decor_num3: "5",
    decor_num4: "20",
  },
  {
    id: 2,
    id1: 1,
    id2: 2,
    id3: 3,
    id4: 4,
    ware_num: "20",
    WareLocation1: "(Madurai)",
    WareLocation2: "(Trichy)",
    WareLocation3: "(Vizag)",
    In_Date: "12/12/23",
    Decor_name: "Krishna",
    client_name1: "Abinesh",
    client_name2: "Surya",
    client_name3: "Rubesh",
    decor_num1: "7",
    decor_num2: "8",
    decor_num3: "5",
    decor_num4: "20",
  },
  {
    id: 3,
    id1: 1,
    id2: 2,
    id3: 3,
    id4: 4,
    ware_num: "20",
    WareLocation1: "(Chennai)",
    WareLocation2: "(Salem)",
    WareLocation3: "(Banglore)",
    In_Date: "12/12/23",
    Decor_name: "Vishwa",
    client_name1: "Hari",
    client_name2: "Bala",
    client_name3: "Sathish",
    decor_num1: "7",
    decor_num2: "8",
    decor_num3: "5",
    decor_num4: "20",
  },
];
export const Shipping_bills = [
  {
    id: 1,
    po: "001",
    po_status: "proof_required",
    po_supplier: "Shakthi",
    po_amount: "10,000",
    bill: "paid",
    bill_supplier: "Shakthi",
    all_bills: "$20,000",
    amount: "10,000",
  },
];
export const Sales_order = [
  {
    id: 22354,
    item_name: "T-shirts",
    qty: "05",
    unitcost: " 2300",
    subtotal: "$0.00%",
    tax: "CGST(2.5% 2.50%)",
    total: "$0.00%",
  },
];
export const bill_total = [
  {
    id: 1,
    po: "",
    po_status: "",
    po_supplier: "",
    po_amount: "$5000.00",
    bill: "65467",
    bill_supplier: "5SK Designer",
    amount: "$5000.00",
  },
  {
    id: 2,
    po: "",
    po_status: "",
    po_supplier: "",
    po_amount: "$5000.00",
    bill: "65467",
    bill_supplier: "5SK Designer",
    amount: "$0.00",
  },
  {
    id: 3,
    po: "",
    po_status: "",
    po_supplier: "",
    po_amount: "$5000.00",
    bill: "65467",
    bill_supplier: "5SK Designer",
    amount: "$100.00",
  },
  {
    id: 4,
    po: "76576",
    po_status: "Submitted",
    po_supplier: "5SK Designer",
    po_amount: "$5000.00",
    bill: "+Add Bill",
    bill_supplier: "",
    amount: "",
  },
  {
    id: 5,
    po: "76576",
    po_status: "Submitted",
    po_supplier: "5SK Designer",
    po_amount: "$5000.00",
    bill: "+Add Bill",
    bill_supplier: "",
    amount: "",
  },
  {
    id: 6,
    po: "76576",
    po_status: "Submitted",
    po_supplier: "5SK Designer",
    po_amount: "$5000.00",
    bill: "+Add Bill",
    bill_supplier: "",
    amount: "",
  },
];
export const presentation_list = [
  {
    id: 1,
    image1: [
      {
        original: tshirt1,
        thumbnail: tshirt1,
      },
      {
        original: tshirt2,
        thumbnail: tshirt2,
      },
      {
        original: tshirt3,
        thumbnail: tshirt3,
      },
    ],
    head: "Gildan Easy Care Solid Tee - Royal",
    para: "220 GSM ,classic fit. Style code:64800.100% ring spun co on. Contoured welt collar. Tearaway label. Rolled forward topstiched shoulder .Clean finished placket with reinforced bo on box.",
    price_label1: "650.00",
    qty1: "25",
    qty2: "50",
    qty3: "100",
    qty4: "150",
    qty5: "250",
    rs1: "131.58",
    rs2: "118.33",
    rs3: "345.95",
    rs4: "345.09",
    rs5: "467.90",
    fc1: "22.46",
    fc2: "5.31",
    fc3: "306",
  },
  {
    id: 2,
    image1: [
      {
        original: tshirt2,
        thumbnail: tshirt2,
      },
      {
        original: tshirt1,
        thumbnail: tshirt1,
      },
      {
        original: Bottle,
        thumbnail: Bottle,
      },
    ],
    head: "20 oz Matt Solid State Himalayan Tumbler",
    para: "304 stainless steel, After filled with cold breverages, Contoured welt collar. Tearaway label. Rolled forward topstiched shoulder .Clean finished placket with reinforced bo on box.",
    price_label1: "470.00",
    qty1: "25",
    qty2: "50",
    qty3: "100",
    qty4: "150",
    qty5: "250",
    rs1: "131.58",
    rs2: "118.33",
    rs3: "345.95",
    rs4: "345.09",
    rs5: "467.90",
    fc1: "22.46",
    fc2: "5.31",
    fc3: "306",
  },
  {
    id: 3,
    image1: [
      {
        original: tshirt1,
        thumbnail: tshirt1,
      },
      {
        original: tshirt2,
        thumbnail: tshirt2,
      },
      {
        original: tshirt3,
        thumbnail: tshirt3,
      },
    ],
    head: "Gildan Easy Care Solid Tee - Royal",
    para: "220 GSM ,classic fit. Style code:64800.100% ring spun co on. Contoured welt collar. Tearaway label. Rolled forward topstiched shoulder .Clean finished placket with reinforced bo on box.",
    price_label1: "650.00",
    qty1: "25",
    qty2: "50",
    qty3: "100",
    qty4: "150",
    qty5: "250",
    rs1: "131.58",
    rs2: "118.33",
    rs3: "345.95",
    rs4: "345.09",
    rs5: "467.90",
    fc1: "22.46",
    fc2: "5.31",
    fc3: "306",
  },
];

export const faq_list = [
  {
    id: "1",
    question: "Automatically ship products..........",
    desc: "Automatically ship products..........",
    status: true,
  },
  {
    id: "2",
    question: "Automatically ship products..........",
    desc: "Automatically ship products..........",
    status: false,
  },
  {
    id: "3",
    question: "Automatically ship products..........",
    desc: "Automatically ship products..........",
    status: true,
  },
];

export const navigation_list = [
  {
    catname: "Screen Printing",
    name: "Screen Printing",
    status: true,
    order: "1",
    image: product,
    active: 1,
  },
  {
    catname: "DTF printing",
    name: "Screen Printing",
    status: false,
    order: "2",
    image: uploadIcon,
    active: 1,
  },
  {
    catname: "Screen Printing",
    name: "UV printing",
    status: true,
    order: "3",
    image: searchIcon,
    active: 1,
  },
  {
    catname: "UV printing",
    name: "DTF printing",
    status: false,
    order: "4",
    image: profilePic,
    active: 0,
  },
];

export const icon_tags = [
  {
    list: "Bags",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Mobile",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Bottle",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Shoes",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    list: "Books",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];
export const Nav_tags = [
  {
    id: 1,
    list: "Enterprise",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 2,
    list: "MHQ",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
  {
    id: 3,
    list: "Market Place",
    check: <CheckCircleIcon className="primary" />,
    uncheck: <RadioButtonUncheckedIcon className="primary" />,
  },
];

export const notif = [
  {
    id: 1,
    desc: "If users selected do not have the permission rights to view content, they will not receive the notification.",
    date: "24-03-2024 (04.00PM)",
  },
  {
    id: 2,
    desc: "A new post is added to a discussion topic you are subscribed to New topic added.",
    date: "23-06-2024 (12.00PM)",
  },
  {
    id: 3,
    desc: "No record is kept to know which specific users received the message once it is sent out.",
    date: "12-04-2024 (02.00PM)",
  },
  {
    id: 4,
    desc: "Content you have submitted through a workflow has been approved or declined.",
    date: "24-03-2024 (04.00PM)",
  },
];
export const Currency_list = [
  {
    id: "1",
    cname: "Rupees",
    ccode: "INR",
    toinr: "Rs.84",
    status: true,
  },
  {
    id: "2",
    cname: "Dollar",
    ccode: "$",
    toinr: "Rs.764",
    status: false,
  },
  {
    id: "3",
    cname: "Rupees",
    ccode: "INR",
    toinr: "Rs.799",
    status: true,
  },
];
export const Colours_list = [
  {
    id: "1",
    cname: "Blue",
    ccode: "#0000FF",
    toinr: "Rs.84",
    status: true,
  },
  {
    id: "2",
    cname: "Black",
    ccode: "#000000",
    toinr: "Rs.764",
    status: false,
  },
  {
    id: "3",
    cname: "Red",
    ccode: "#FF0000",
    toinr: "Rs.799",
    status: true,
  },
];
