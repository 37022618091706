import { Close, PointOfSale } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import {
  addres_list,
  department_list,
  phone_list,
  sub_menu,
} from "../../redux/api/DummyJson";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const AddProjectPoppupComp = ({ closeToggle, popupType }) => {
  const [dropDwon, setDropDown] = useState(undefined);

  const [department, setDepartment] = useState("");
  const [clientName, setClientName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [form, setForm] = useState(1);
  const navigate = useNavigate();

  // Function
  const onDepartment = (e) => {
    setDepartment(e.target.value);
    department.length === 0 ? setDropDown(undefined) : setDropDown(1);
  };
  return (
    <div className="modal-popup">
      <div onClick={closeToggle} className="back-close" />
      <div className="z_ind_cust center-content-contact2 w-md-40">
        <div className="w-100 d-flex ac-jb mt-4 px-md-5 px-3">
          <div className="d-flex">
            {form === 2 && (
              <button
                onClick={() => setForm(1)}
                className="cust-btn f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary"
              >
                <KeyboardBackspaceIcon className="me-3 fs-2 cp" />
              </button>
            )}
            <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
              New Project
            </p>
          </div>

          <div onClick={closeToggle} className="">
            <button class="cust-btn  text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">
              <Close className="primary fs-2" />
            </button>
          </div>
        </div>
        {form === 1 && (
          <div className="mt-3 overflow-scroll h_cust_res mt-5">
            <div className="w-100 px-4 px-md-5">
              <div className="w-100">
                <div className="my-2 flex-column flex-md-row">
                  <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                    Enterprises Name
                  </p>
                  <div className="position-relative md_cust_w w-100">
                    <input
                      onChange={(e) => {
                        setClientName(e.target.value);
                        clientName.length === 0
                          ? setDropDown(undefined)
                          : setDropDown(2);
                      }}
                      value={clientName}
                      placeholder="Required Field"
                      className="editBtn rounded-3 bg-white w-100 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button className="drop_down cust-btn">
                      <KeyboardArrowDownIcon onClick={() => setDropDown(2)} />
                    </button>
                    {dropDwon === 2 && (
                      <div
                        className="invisible-cont2"
                        onClick={() => setDropDown(undefined)}
                      />
                    )}
                    <div
                      className={`${
                        dropDwon === 2 && "submenu_1 w-100"
                      } submenu_cont_1 overflow-scroll z-3`}
                      style={{ height: "110px" }}
                    >
                      {addres_list?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                              onClick={() => {
                                setDropDown(undefined);
                                setClientName(item?.list);
                              }}
                            >
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {clientName.length > 0 && (
                  <div>
                    <div className=" ">
                      <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                        Project Name *
                      </p>
                      <input
                        placeholder="Required Field"
                        className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                    </div>
                    <div className="w-100">
                      <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                        Event Type *
                      </p>
                      <div className="w-100 md_cust_w">
                        <div className="position-relative w-100 ">
                          <input
                            value={phoneNo}
                            onChange={(e) => {
                              setPhoneNo(e.target.value);
                              phoneNo.length === 0
                                ? setDropDown(undefined)
                                : setDropDown(3);
                            }}
                            placeholder="Required Field"
                            className="editBtn rounded-3 bg-white w-100 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          />
                          <button className="drop_down cust-btn">
                            <KeyboardArrowDownIcon
                              onClick={() => setDropDown(3)}
                            />
                          </button>
                          {dropDwon === 3 && (
                            <div
                              className="invisible-cont2"
                              onClick={() => setDropDown(undefined)}
                            />
                          )}
                          <div
                            className={`${
                              dropDwon === 3 && "submenu_1 w-100"
                            } submenu_cont_1 overflow-scroll z-3`}
                            style={{ height: "110px" }}
                          >
                            {phone_list?.map((item) => {
                              return (
                                <div className="d-flex hover-cust">
                                  <button
                                    className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                    onClick={() => {
                                      setDropDown(undefined);
                                      setPhoneNo(item?.list);
                                    }}
                                  >
                                    {item?.list}
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {form === 2 && (
          <div className="mt-3 overflow-scroll h_cust_res mt-5">
            <div className="w-100 px-4 px-md-5">
              <div className="w-100">
                <div className="my-2 flex-column flex-md-row">
                  <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                    Client Contact
                  </p>
                  <div className="position-relative md_cust_w w-100">
                    <input
                      onChange={(e) => {
                        setClientName(e.target.value);
                        clientName.length === 0
                          ? setDropDown(undefined)
                          : setDropDown(2);
                      }}
                      value={clientName}
                      placeholder="Required Field"
                      className="editBtn rounded-3 bg-white w-100 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button className="drop_down cust-btn">
                      <KeyboardArrowDownIcon onClick={() => setDropDown(2)} />
                    </button>
                    {dropDwon === 2 && (
                      <div
                        className="invisible-cont2"
                        onClick={() => setDropDown(undefined)}
                      />
                    )}
                    <div
                      className={`${
                        dropDwon === 2 && "submenu_1 w-100"
                      } submenu_cont_1 overflow-scroll z-3`}
                      style={{ height: "110px" }}
                    >
                      {addres_list?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                              onClick={() => {
                                setDropDown(undefined);
                                setClientName(item?.list);
                              }}
                            >
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="d-flex ac-jb w-100 gap-3">
                  <div className="my-2 flex-column flex-md-row w-100 ">
                    <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                      Budget *
                    </p>
                    <input
                      placeholder="Required Field"
                      className="editBtn ps-4 position-relative md_cust_w w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <div className="box_icon d-flex ac-jc">
                      <AttachMoneyIcon className="primary f2" />
                    </div>
                  </div>
                  <div className="my-2 flex-column flex-md-row w-100">
                    <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                      In-Hands Date *
                    </p>
                    <input
                      type="date"
                      placeholder="Required Field"
                      className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="w-100 d-flex ac-jc">
          {form === 1 && (
            <button
              onClick={() => setForm(2)}
              class="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Continue
            </button>
          )}
          {form === 2 && (
            <button
              onClick={() => navigate("/overview")}
              class="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProjectPoppupComp;
