import React from "react";
import { useNavigate } from "react-router-dom";
import ChartBar from "../../components/ChartBar/ChartBar";
import LineChart from "../../components/ChartBar/LineChart";
import DashboardTop from "../../components/Production/Dashboard/DashboardTop";
import PurchaseOrderStage from "../../components/Production/Dashboard/PurchaseOrderStage";
import PurchaseOrderStatus from "../../components/Production/Dashboard/PurchaseOrderStatus";
const ProductionDashBoard = () => {
  const navigate = useNavigate();
  return (
    <div className="production-dash-board dashRightView  p-5 home_section trans">
      <DashboardTop />
      <div className="d-flex w-100 flex-wrap">
        {/* <PurchaseOrderStage /> */}
        {/* <PurchaseOrderStatus /> */}

        <div className="d-flex flex-md-row flex-column mt-5 w-100">
          <div className="w-lg-50 w-md-100 w-sm-100 w-xs-100 dailyStatus mt-4 rounded-3">
            <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Purchase Orders by Stage
            </p>
            <div className="totalSiteDesWid flex-xs-column flex-wrap gap-3 d-flex mt-4 w-100">
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc text-start"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Ready for Billing
                  </p>
                  <div className="bg-green1 p-1 mt-2 rounded-3 dotColor" />
                </div>
                <p className="d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  70
                </p>
              </div>
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Shipped
                  </p>
                  <div className="bg-red rounded-3 mt-2 p-1 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  10
                </p>
              </div>
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Confirmed
                  </p>
                  <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  60
                </p>
              </div>
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Submitted
                  </p>
                  <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  20
                </p>
              </div>
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Created
                  </p>
                  <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  100
                </p>
              </div>
            </div>
          </div>
          <div className="w-lg-50 mt-4 w-md-100 w-sm-100 w-xs-100 dailyStatus ms-lg-4 rounded-3">
            <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Purchase Orders by Status
            </p>
            <div className="totalSiteDesWid flex-xs-column flex-wrap gap-3 d-flex mt-4 w-100">
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div className="d-flex acs-jc" style={{ width: "100px" }}>
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Client Approval
                  </p>
                  <div className="bg-green1 p-1 mt-2 rounded-3 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  70
                </p>
              </div>
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Change
                  </p>
                  <div className="bg-red rounded-3 mt-2 p-1 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  10
                </p>
              </div>
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Requested
                  </p>
                  <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  10
                </p>
              </div>
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Pending Approval
                  </p>
                  <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  60
                </p>
              </div>
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Proof Received
                  </p>
                  <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  20
                </p>
              </div>
              <div
                onClick={() => navigate("/production")}
                className="cp dashTotalDesTotalSites mb-0"
              >
                <div
                  className="d-flex as-jc"
                  style={{ wordWrap: "break-word", width: "100px" }}
                >
                  <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                    Awaiting Proof
                  </p>
                  <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
                </div>
                <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                  100
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sheduel */}
      <div className="mt-5">
        <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Schedules
        </p>
        <div className="w-100 d-flex ac-jb flex-md-row flex-column mt-1">
          <div className="dashTotalDesChart w-lg-45 w-md-45 dailyStatus1 rounded-3">
            <p className="f2 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Daily Status
            </p>
            <p className="f1 w-xs-100 w-sm-100 fs-sm-8 fs-md-9 fs-lg-10 fs-xl-11 fs-xxl-12 gray">
              Task completed daily this month
            </p>
            <div className="mt-5 w-xs-100 w-sm-100 d-flex flex-wrap">
              <div className="chartSpace d-flex">
                <ChartBar height={60} />
              </div>
              <div className="chartSpace d-flex">
                <ChartBar height={70} />
                <ChartBar height={35} />
                <ChartBar height={70} type="static" />
                <ChartBar height={60} />
                <ChartBar height={40} type="static" />
              </div>
              <div className="chartSpace d-flex">
                <ChartBar height={70} type="static" />
                <ChartBar height={35} />
                <ChartBar height={15} />
                <ChartBar height={40} type="static" />
                <ChartBar height={50} />
              </div>
              <div className="chartSpace d-flex">
                <ChartBar height={70} />
                <ChartBar height={45} type="static" />
                <ChartBar height={70} type="static" />
                <ChartBar height={50} />
                <ChartBar height={40} type="static" />
              </div>
              <div className="chartSpace d-flex dataDis">
                <ChartBar height={50} type="static" />
                <ChartBar height={30} />
                <ChartBar height={20} />
                <ChartBar height={35} />
              </div>
            </div>
          </div>
          <div className="dashTotalDesMonths w-50 dailyStatus1 rounded-3 ms-sm-4">
            <p className="f2 w-xs-100 w-sm-100 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Updates By Months
            </p>
            <LineChart />
          </div>
        </div>
      </div>
      {/* Sheduel */}
    </div>
  );
};
export default ProductionDashBoard;
