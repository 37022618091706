import { animatedImg, emptyImg } from "../assets/img";
import { Search } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { dummyDashboardData } from "../redux/api/DummyJson";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ProductVendor from "../components/Vendor/ProductVendor";
import { useLocation, useNavigate } from "react-router-dom";
import ServiceVendor from "../components/Vendor/ServiceVendor";
import ImportListPopup from "../components/Popup/ImportListPopup";
import UploadIcon from "@mui/icons-material/Upload";
import BothVendor from "../components/Vendor/BothVendor";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import GetAppIcon from "@mui/icons-material/GetApp";

const EnterprisesDashboard = () => {
  const [prodVendor, setProdVendor] = useState(false);
  const [serVendor, setSerVendor] = useState(false);
  const [serBoth, setSerBoth] = useState(false);
  const [importList, setImportList] = useState(false);
  const [bothLis, setBothLis] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const showPopupHander = () => {
    setImportList(false);
  };

  useEffect(() => {
    if (location?.state?.type === "service") {
      setSerVendor(true);
    } else {
      setProdVendor(true);
    }
  }, []);

  return (
    <div className="dashRightView p-5 home_section trans">
      {importList && (
        <ImportListPopup
          showPopupHander={showPopupHander}
          setImportList={setImportList}
        />
      )}
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} className="searchManImg" />
      </div>
      <div className="d-flex flex-md-row flex-column mt-5 w-100">
        <div className="w-lg-50 w-md-100 w-sm-100 w-xs-100 dailyStatus mt-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Enterprises Dashboard
          </p>
          <div className="totalSiteDesWid flex-xs-column flex-wrap gap-3 d-flex mt-4 w-100">
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc text-start"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Ready for Billing
                </p>
                <div className="bg-green1 p-1 mt-2 rounded-3 dotColor" />
              </div>
              <p className="d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                70
              </p>
            </div>
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Shipped
                </p>
                <div className="bg-red rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                10
              </p>
            </div>
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Confirmed
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                60
              </p>
            </div>
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Submitted
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                20
              </p>
            </div>
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Created
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                100
              </p>
            </div>
          </div>
        </div>
        <div className="w-lg-50 mt-4 w-md-100 w-sm-100 w-xs-100 dailyStatus ms-lg-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Enterprises Dashboard
          </p>
          <div className="totalSiteDesWid flex-xs-column flex-wrap gap-3 d-flex mt-4 w-100">
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div className="d-flex acs-jc" style={{ width: "100px" }}>
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Client Approval
                </p>
                <div className="bg-green1 p-1 mt-2 rounded-3 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                70
              </p>
            </div>
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Change
                </p>
                <div className="bg-red rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                10
              </p>
            </div>
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Requested
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                10
              </p>
            </div>
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Pending Approval
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                60
              </p>
            </div>
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Proof Received
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                20
              </p>
            </div>
            <div
              // onClick={() => navigate("/production")}
              className="cp dashTotalDesTotalSites mb-0"
            >
              <div
                className="d-flex as-jc"
                style={{ wordWrap: "break-word", width: "100px" }}
              >
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Awaiting Proof
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className=" d-flex ac-jc f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                100
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EnterprisesDashboard;
