import React, { useState } from "react";
import { product } from "../../assets/img";

const VendorProductView = ({ handleClose }) => {
  const [fileData, setFileData] = useState([]);

  // upload image event
  const handleChangeUpload = (e) => {
    const { files } = e && e.target;
    if (files) {
      setFileData((prev) => [...prev, files[0]]);
    }
  };
  return (
    <div className="popup-wrap">
      <div className="popup-inner_box">
        <div className="position-relative">
          <h5 className="primary">View Proof</h5>
          <div className="position-absolute popup_close" onClick={handleClose}>
            <button className="btn">&#x2715;</button>
          </div>
        </div>
        <div className="d-flex w-100 mb-4 mt-3 flex-wrap">
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Title
            </p>
            <input
              type="text"
              placeholder="Enter Title"
              value={"Charles"}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Upload Product Images
            </p>
            <input
              style={{ cursor: "pointer" }}
              type="file"
              multiple={true}
              onChange={(e) => {
                handleChangeUpload(e);
              }}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 upload_img"
            />
          </div>
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Images
            </p>
            <img
              src={product}
              style={{ width: "100px", height: "100px" }}
              alt="img"
            />
          </div>
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Price
            </p>
            <input
              type="text"
              placeholder="Enter Price here.."
              value={1000}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorProductView;
