import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const CustomerProjects = ({ list }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <div className="d-flex flex-wrap mt-4 mx-3 details_tab link_projects mb-3">
      <div className="w-100">
        <div className="d-flex ac-jb w-100 mt-xs-5 mb-3 position-relative px-2 flex-wrap">
          <input
            className="cust-btn searchFill w-45 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-45 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
          <select className="mt-3 w-45 cust-btn searchFill fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            <option>Opportunity</option>
            <option>Presentation</option>
            <option>Estimate</option>
            <option>Sales Order</option>
            <option>Invoice</option>
          </select>
        </div>
      </div>

      <div className=" d-flex mt-3 border-bottom w-100">
        <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Project
        </p>
        <p className="w-40 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Name
        </p>
        <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Stage
        </p>
        <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          In Hands Date
        </p>
      </div>
      <div className="w-100 d-flex mt-5">
        <p className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc ">
          21028
        </p>
        <p className="w-40 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
          Client Portal - New Project
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          Opportunity
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          2023-10-25
        </p>
      </div>
      <div className="w-100 d-flex mt-5">
        <p className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc ">
          21097
        </p>
        <p className="w-40 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
          Client Portal - New Project
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          Opportunity
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          2023-10-25
        </p>
      </div>
      <div className="w-100 d-flex mt-5">
        <p className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc ">
          21033
        </p>
        <p className="w-40 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
          New Hire 20-Sep-2023
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          Opportunity
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          2023-10-25
        </p>
      </div>
      <div className="w-100 d-flex mt-5">
        <p className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc ">
          21032
        </p>
        <p className="w-40 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
          Client Portal - New Project
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          Opportunity
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          2023-10-25
        </p>
      </div>
      <div className="w-100 d-flex mt-5">
        <p className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc ">
          21022
        </p>
        <p className="w-40 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
          sample
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          Opportunity
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          2023-10-25
        </p>
      </div>
      <div className="w-100 d-flex mt-5">
        <p className="w-20 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc ">
          21023
        </p>
        <p className="w-40 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
          demo 1
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          Opportunity
        </p>
        <p className="w-20 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
          2023-10-20
        </p>
      </div>
    </div>
  );
};

export default CustomerProjects;
