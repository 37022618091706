import React, { useState } from "react";
import { product, viewIcon } from "../../assets/img";
import {
  collaborateList,
  Compact_table,
  sales_compact_table,
} from "../../redux/api/DummyJson";
import { useNavigate } from "react-router-dom";
import DeletePopup from "../Popup/DeletePopup";

const TableDetaile = ({
  setAddTitle,
  viewStockToggle,
  deleteToggle,
  toggleBtn1,
  tab,
}) => {
  const navigate = useNavigate();
  const [hide, setHide] = useState(null);

  const hider = (num) => {
    if (hide == num) {
      setHide(null);
    } else {
      setHide(num);
    }
  };
  return (
    <div className="presentaion_pages overflow-scroll mt-4">
      <div className="w-100 overflow-scroll table_border menu-table">
        <table>
          <thead className="py-3">
            <tr className="bg-soft-gray1">
              <th style={{ width: "600px" }} className="py-3">
                <div className="dropdown mx-2 d-flex- flex-column ac-js d-flex w-100">
                  <button
                    className="dropdown-toggle align-self-start cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => navigate("/product-library")}
                      >
                        Product
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => setAddTitle(true)}
                      >
                        Tittle
                      </span>
                    </li>
                  </ul>
                </div>
              </th>
              <th className="text-start">Item</th>
              <th className="text-start">Units</th>
              <th className="text-start">Margin</th>
              <th className="text-start">Tax</th>
              <th className="text-start">Amount</th>
            </tr>
            {sales_compact_table?.map((item, ind) => {
              return (
                <tr
                  className={`position-relative color-for-hecd bor_t py-3 ${
                    hide == ind && "opacity-50"
                  }`}
                >
                  <td className="text-start ps-2">
                    <div className="d-flex flex-column as-jb gap-3">
                      <div className=" w-100">
                        <div className="d-flex">
                          <img
                            src={product}
                            className="object-fit-contain img_size2"
                          />
                          <div className="d-flex flex-column mt-4">
                            <span className="text-info f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                              {item?.sku_code}
                            </span>
                            <span className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                              {item?.name}
                            </span>
                            <span className="f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                              {item?.para}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex mb-3">
                          <button className="" onClick={() => hider(ind)}>
                            Hide
                          </button>
                          <button
                            onClick={() => navigate("/sales-order-edit-item")}
                          >
                            Edit
                          </button>
                          {/* <button>Copy</button> */}
                          <button className="del-colo-d" onClick={deleteToggle}>
                            Delete
                          </button>
                          {/* <button
                          className={
                            item?.status == "Client Paid"
                              ? "paid"
                              : item?.status == "Payment Pending" && "pending"
                          }
                        >
                          {item?.status}
                        </button> */}
                          <button onClick={() => viewStockToggle()}>
                            {" "}
                            View Stock
                          </button>
                        </div>
                        <p className="f1 text-start text-dark fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 text-wrap">
                          {item?.dis}
                        </p>
                      </div>
                      <div
                        className="status_btn d-flex as-js"
                        style={{
                          bottom: 0,
                          width: "200px",
                        }}
                      >
                        <div
                          className={` ${
                            item?.status == "Client Paid"
                              ? "accepted_btn"
                              : item?.status == "Payment Pending"
                              ? "pending_btn"
                              : item?.status == "reject_btn" && "process_btn"
                          } px-4 py-2  rounded-3 d-flex mb-2`}
                        >
                          {/* <DoneIcon className="text_btn me-2" /> */}
                          <p className="text_btn f2">{item?.status}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="ac-jc d-flex flex-column text-start">
                    <tr>TBD/TBD</tr>
                    <tr>Pink/M</tr>
                    <tr>TBD/TBD</tr>
                    <tr>Total Units</tr>
                    <tr>Run 1</tr>
                    <tr>Fixed 1</tr>
                    <tr>Location 1 : Center</tr>
                    <tr>Import Cost</tr>
                    <tr>Setup Cost</tr>
                  </td>
                  <td className="text-start">20</td>
                  <td className="text-start">4.99%</td>
                  <td className="text-start">CGST (6%)</td>
                  <td className="text-start">$1010.45</td>
                </tr>
              );
            })}
          </thead>
        </table>
      </div>
    </div>
  );
};

export default TableDetaile;
