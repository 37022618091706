import React, { useState } from "react";
import { Estimate_req, } from "../../redux/api/DummyJson";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useNavigate } from "react-router";
import { viewIcon } from "../../assets/img";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Rejected = () => {
  const navigate = useNavigate();
  const [designStatus, setDesignStatus] = useState("Approved");
  const [salseStatus, setSalseStatus] = useState("Completed");
 
  return (
    <div className="w-100 overflow-scroll mt-5">
      <table className="w-100 px-4">
        <tr>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              S.No
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Project ID
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Project Name
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Customer ID
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Estimate ID
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Order Rep
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Client Rep
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Customer Status
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-5 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Sales Status
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-5 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Options
            </p>
          </th>
        </tr>
        {Estimate_req ?.map((item, ind) => {
          return (
            <tr className="my-3">
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.id}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.project_id}
                </p>
              </th>{" "}
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.project_name}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.customer_id}
                </p>
              </th>{" "}
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.estimate_id}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.order_rep}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.client_rep}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.customer_status}
                </p>
              </th>
              <th className="d-flex justify-content-center align-items-center mt-2">
                <div >
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                  {item?.sales_rej}
                </p>
                <div className=" position-relative rel_sty d-flex flex-column ac-jc">
                  {designStatus == "Approved" && (
                    <div className="hov_popp viewBoxDes rounded-2">
                      <p className="f1 fs-xs-11 fs-sm-12 border-3 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-10 text-dark text-center py-2">
                        Rejected by hari
                      </p>
                    </div>
                  )}
                </div>
                </div>
                <div className="card_info">
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        Product Rejected reason will show here
                      </Tooltip>
                    )} 
                    placement="bottom"
                  >
                    <InfoOutlinedIcon
                      className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1  parag "
                      style={{ color: "#06679c" }}
                    />
                  </OverlayTrigger>
                </div>
              </th>
              <th>
                <div className="d-flex ac-jc pb-4">
                  <div
                    // onClick={() => navigate("/estimate")}
                    // className="text-center bg-transparent border-0 pointerView viewBoxDes p-2 bg-white rounded-2">
                    // <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 ">
                    //   <BorderColorIcon />
                    // </p>
                    
                        onClick={() =>
                          navigate("")}
                        className="viewBoxDes pointerView p-2 bg-white rounded-2">
                        <img src={viewIcon} className="viewDes" />
                      
                  </div>
                </div>
              </th>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default Rejected;
