import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const PaymentDetails = ({ PaymenDetTogg, setBoxRadioBtn, boxRadioBtn }) => {
  return (
    <div className="delete-popup">
      <div className="w-90 w-md-40">
        {" "}
        <button
          onClick={() => PaymenDetTogg()}
          className="cust-btn d-flex ac-je w-100"
        >
          <HighlightOffIcon />
        </button>
        <div className="mt-3 gap-4 d-flex w-100 ac-jc">
          <div
            onClick={() => setBoxRadioBtn(1)}
            className={`${
              boxRadioBtn === 1 ? "ps-2 bg-transparent" : " "
            } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
          >
            <button className="d-flex gap-3  ac-js cust-btn">
              <div className="radio_btn d-flex ac-jc">
                <div
                  className={`${boxRadioBtn === 1 ? "sm_radio" : "sm_radio1"}`}
                />
              </div>
              <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                PO
              </p>
            </button>
          </div>
          <div
            onClick={() => setBoxRadioBtn(2)}
            className={`${
              boxRadioBtn === 2 ? "ps-2 bg-transparent" : " "
            } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
          >
            <button className="d-flex gap-3  ac-js cust-btn">
              <div className="radio_btn d-flex ac-jc">
                <div
                  className={`${boxRadioBtn === 2 ? "sm_radio" : "sm_radio1"}`}
                />
              </div>
              <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Email
              </p>
            </button>
          </div>
          <div
            onClick={() => setBoxRadioBtn(3)}
            className={`${
              boxRadioBtn === 2 ? "ps-2 bg-transparent" : " "
            } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
          >
            <button className="d-flex gap-3  ac-js cust-btn">
              <div className="radio_btn d-flex ac-jc">
                <div
                  className={`${boxRadioBtn === 3 ? "sm_radio" : "sm_radio1"}`}
                />
              </div>
              <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Online
              </p>
            </button>
          </div>
        </div>
        <div className="mt-3 gap-4 d-flex w-100 ac-jc">
          <button
            onClick={() => PaymenDetTogg()}
            class="position-relative align-selfe-center cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
export default PaymentDetails;
