import { PointOfSale } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import {
  addres_list,
  department_list,
  phone_list,
  sub_menu,
} from "../../redux/api/DummyJson";

const AddClientContactPopup = ({ toggleShowPopup, popupType }) => {
  const [dropDwon, setDropDown] = useState(undefined);

  const [department, setDepartment] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  // Function
  const onDepartment = (e) => {
    setDepartment(e.target.value);
    department.length === 0 ? setDropDown(undefined) : setDropDown(1);
  };
  return (
    <div className="modal-popup">
      <div onClick={toggleShowPopup} className="back-close" />
      <div className="z_ind_cust center-content-contact2 w-90 w-md-55">
        <div className="w-100 d-flex ac-jb mt-4 px-md-5 px-3">
          <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
            Add Contact
          </p>
          <div onClick={toggleShowPopup} className="">
            <button class="cust-btn addbtn_cancel bg-danger text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">
              Cancel
            </button>
            <button class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded">
              save
            </button>
          </div>
        </div>
        <div className="mt-3 overflow-scroll h_cust_res">
          <div className="w-100 px-4 px-md-5 ">
            <div className="w-100">
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  First Name :
                </p>
                <input
                  placeholder="Required Field"
                  className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Last Name :
                </p>
                <input
                  placeholder="Required Field"
                  className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Position :
                </p>
                <input
                  placeholder="Required Field"
                  className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Email :
                </p>
                <input
                  placeholder="Required Field"
                  className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
              </div>
              <div className="d-flex as-jb position-relative my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Department :
                </p>
                <div className="position-relative md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      department.length === 0
                        ? setDropDown(undefined)
                        : setDropDown(1);
                    }}
                    placeholder="Required Field"
                    className="editBtn rounded-3 bg-white w-100 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button className="drop_down cust-btn">
                    <KeyboardArrowDownIcon onClick={() => setDropDown(1)} />
                  </button>
                  {dropDwon === 1 && (
                    <div
                      className="invisible-cont2"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDwon === 1 && "submenu_1 w-100"
                    } submenu_cont_1 overflow-scroll z-3`}
                    style={{ height: "110px" }}
                  >
                    {department_list?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => {
                              setDropDown(undefined);
                              setDepartment(item?.list);
                            }}
                          >
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Address :
                </p>
                <div className="position-relative md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    onChange={(e) => {
                      setAddress(e.target.value);
                      address.length === 0
                        ? setDropDown(undefined)
                        : setDropDown(2);
                    }}
                    value={address}
                    placeholder="Required Field"
                    className="editBtn rounded-3 bg-white w-100 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button className="drop_down cust-btn">
                    <KeyboardArrowDownIcon onClick={() => setDropDown(2)} />
                  </button>
                  {dropDwon === 2 && (
                    <div
                      className="invisible-cont2"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDwon === 2 && "submenu_1 w-100"
                    } submenu_cont_1 overflow-scroll z-3`}
                    style={{ height: "110px" }}
                  >
                    {addres_list?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => {
                              setDropDown(undefined);
                              setAddress(item?.list);
                            }}
                          >
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row w-100">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Phone :
                </p>
                <div className="w-xl-50 w-lg-50 w-sm-50 md w-xs-100 md_cust_w">
                  <div className="position-relative w-100 ">
                    <input
                      value={phoneNo}
                      onChange={(e) => {
                        setPhoneNo(e.target.value);
                        phoneNo.length === 0
                          ? setDropDown(undefined)
                          : setDropDown(3);
                      }}
                      placeholder="Required Field"
                      className="editBtn rounded-3 bg-white w-100 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button className="drop_down cust-btn">
                      <KeyboardArrowDownIcon onClick={() => setDropDown(3)} />
                    </button>
                    {dropDwon === 3 && (
                      <div
                        className="invisible-cont2"
                        onClick={() => setDropDown(undefined)}
                      />
                    )}
                    <div
                      className={`${
                        dropDwon === 3 && "submenu_1 w-100"
                      } submenu_cont_1 overflow-scroll z-3`}
                      style={{ height: "110px" }}
                    >
                      {phone_list?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                              onClick={() => {
                                setDropDown(undefined);
                                setPhoneNo(item?.list);
                              }}
                            >
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-100 d-flex ac-js gap-3 ">
                    <input
                      placeholder="Required Field"
                      className="editBtn md_cust_w w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <input
                      style={{ width: "43px" }}
                      placeholder="Ext."
                      className="editBtn rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Tags :
                </p>
                <input
                  placeholder="Required Field"
                  className="editBtn md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClientContactPopup;
