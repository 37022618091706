import React from "react";
import { art1, art2, head, logos, tshirt1 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";

const SalesOrderA = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="ps-2 font-blue2 ">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Sales Order #110208 for AAHIKA
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project Name
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div
          className="d-flex flex-wrap    w-100  mt-4  me-3 p-4"
          style={{
            backgroundColor: "#ecfaff",
            borderRadius: "20px",
            lineHeight: "35px",
            flexWrap: "wrap",
          }}
        >
          <div className="col-md-3 mt-sm-2 p-2">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                SHIPPPING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                5SK Designer Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
                NADU NINOS 125, INDIA
              </p>
            </div>
          </div>
          <div className="col-md-3 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
              BILLING ADDRESS
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              5SK Designer Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
              NADU , INDIA
            </p>
          </div>
          {/* <div className="ryt-line"
            style={{
              height: "150px",
              width: "1px",
              backgroundColor: "#000",
            }}
          ></div> */}
          <div className="col-md-2 ps-2 mt-sm-2 p-2" style={{
          borderLeft:"1px",
          borderStyle:"solid",
          borderColor:"#cacaca",
          borderRight:"0",
          borderBottom:"0",
          borderTop:"0",
          }}>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                PROJECT #
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                2134567
              </p>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TERMS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Purchse Order
              </p>
            </div>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
              ESTIMATE #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              1213123
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CUSTOMER PO #
            </p>
            {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              777777777
            </p> */}
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
              IN HAND DATE
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              FEB 4,2024
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CURRENCY
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              INR
            </p>
          </div>
        </div>
        <div className="my-3 d-flex  flex-wrap ">
          <div
            className="col-md-5"
            style={{
              backgroundColor: "#ecfaff",
              borderRadius: "32px",
              padding: "12px",
              textAlign: "center",
            }}
          >
            <img
              alt="img"
              src={tshirt1}
              className=" "
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Product image for reference only. <br />
              See your artwork below.
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 font-blue2 pointer" onClick={()=>{
              navigate("/sales-order-enlarge-a");
            }}>
              Click to enlarge
            </p>
          </div>
          <div className="col-md-7 p-4">
            <div style={{}}>
              <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                SOIL Classic Journal -mHQ Blue
              </h2>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                220 GSM, Classic Fit. Style code: 64520.100% ring-spun co on.
                Countered welt collar. tereaway label Tumble Try Medium. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Eligendi
                cupiditate quisquam
              </p>
            </div>
            <div class=" tab mt-4 overflow-scroll">
              <table className="w-100 ">
                <thead>
                  <tr>
                    <th className="f3 col-md-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                      ITEM
                    </th>
                    <th className="f3 col-md-6  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2"></th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                        QTY
                      </p>
                    </th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                        PRICE
                      </p>
                    </th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                        AMOUNT
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ lineHeight: "30px" }}>
                  <tr>
                    <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Size: TBD - Color: TBD
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        Size: M - Color: Blue
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Size: TBD - Color: TBD
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap "></p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center mx-3">
                        300
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center mx-3">
                        10
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center mx-3">
                        0
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS6,987,9879.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS6,4569.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS6,987,9879.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS6,4569.00
                      </p>
                    </td>
                  </tr>

                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                    TOTAL UNITS
                  </p>
                  <td></td>
                  <td>
                    <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center mx-3">
                      310
                    </p>
                  </td>

                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Fixed pre Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap ">
                        RS15.0000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS15.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Run Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap mx-3">
                        310
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS10.0000000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS31.002796
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Fixed Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap ">
                        RS10.0000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS10.00
                      </p>
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderBottom: "0px",
                      borderTop: "1px",
                      borderRight: "0",
                      borderColor: "#787b7f",
                      borderStyle: "solid",
                      borderLeft: 0,
                    }}
                  >
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Setup Cost - top
                      </p>
                    </td>
                    <td></td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        Rs.10.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        Rs.10.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Setup Cost -
                      </p>
                    </td>
                    <td></td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        Rs.20.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center  text-nowrap">
                        Rs.20.00
                      </p>
                    </td>
                  </tr>

                  <tr className="">
                    <td>
                      <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                        TOTAL
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                    </td>
                    <td>
                      <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap text-center font-blue2">
                        RS348,332.00
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-lt-blue3  rounded p-4">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
              ARTWORK DETAILS
            </p>
          </div>
          <div className="d-flex flex-wrap ac-ja  m-4">
            <table className=" mt-2 w-50" style={{ lineHeight: "30px" }}>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN NAME
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Shakthi
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    IMPRINT TYPE
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    3D Laser Engraving
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN LOCATION
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Top
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN SIZE
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    20*20
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN COLOR
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    blue
                  </p>
                </td>
              </tr>
            </table>
            <div className="bg-lt-blue3 rounded py-2 w-50 text-center">
              <img src={art1} className="w-40" alt="" />
              <p className="font-blue2 pointer "  onClick={()=>{
              navigate("/sales-art-enlarge-1");
            }}>Click here to enlarge</p>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-lt-blue3  rounded p-4 mt-3">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
              ARTWORK DETAILS
            </p>
          </div>
          <div className="d-flex flex-wrap ac-ja  m-4">
            <table className="mt-2 w-50" style={{ lineHeight: "30px" }}>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN NAME
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Vel
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    IMPRINT TYPE
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Applique
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN LOCATION
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN SIZE
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    20*30
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    DESIGN COLOR
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    black
                  </p>
                </td>
              </tr>
            </table>
            <div className="bg-lt-blue3 rounded py-2 w-50 text-center">
              <img src={art2} className="w-40" alt="" />
              <p className="font-blue2 pointer " onClick={()=>{
              navigate("/sales-art-enlarge-2");
            }}>Click here to enlarge</p>
            </div>
          </div>
        </div>
        <div className="my-3 d-flex  flex-wrap ">
          <div
            className="col-md-5"
            style={{
              backgroundColor: "#ecfaff",
              borderRadius: "32px",
              padding: "12px",
              textAlign: "center",
            }}
          >
            <img
              alt="img"
              src={tshirt1}
              className=" "
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Product image for reference only.
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 font-blue2 pointer"  onClick={()=>{
              navigate("/sales-order-enlarge-b");
            }}>
              Click to enlarge
            </p>
          </div>
          <div className="col-md-7 p-4">
            <div style={{}}>
              <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                20 oz Matt Solid State Himalayan Tumbler
              </h2>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                220 GSM, Classic Fit. Style code: 64520.100% ring-spun co on.
                Countered welt collar. tereaway label Tumble Try Medium. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Eligendi
                cupiditate quisquam Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Ad quidem eligendi maxime consectetur non
              </p>
            </div>
            <div class="tab  mt-4 overflow-scroll">
              <table className="w-100">
                <thead>
                  <tr>
                    <th className="f3 col-md-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                      ITEM
                    </th>
                    <th className="f3 col-md-6  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2"></th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                        QTY
                      </p>
                    </th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                        PRICE
                      </p>
                    </th>
                    <th>
                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                        AMOUNT
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ lineHeight: "30px" }}>
                  <tr>
                    <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Size: TBD - Color: TBD
                      </p>
                      {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        Size: M - Color: Blue
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Size: TBD - Color: TBD
                      </p> */}
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap "></p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap mx-3">
                        300
                      </p>
                      {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        10
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        0
                      </p> */}
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS6,987,9879.00
                      </p>
                      {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,4569.00
                      </p> */}
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap">
                        RS6,987,9879.00
                      </p>
                      {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,4569.00
                      </p> */}
                    </td>
                  </tr>

                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                    TOTAL UNITS
                  </p>
                  <td></td>
                  <td>
                    <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-center text-nowrap mx-3">
                      310
                    </p>
                  </td>

                  {/* <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Fixed pre Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        RS15.0000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS15.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Run Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        310
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS10.0000000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS31.002796
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Fixed Charges
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        RS10.0000
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS10.00
                      </p>
                    </td>
                  </tr>
                  <tr
                   
                  >
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Setup Cost - top
                      </p>
                    </td>
                    <td></td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.10.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.10.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Setup Cost -
                      </p>
                    </td>
                    <td></td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        1
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.20.00
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Rs.20.00
                      </p>
                    </td>
                  </tr> */}

                  <tr
                    className=""
                    style={{
                      borderBottom: "0px",
                      borderTop: "1px",
                      borderRight: "0",
                      borderColor: "#787b7f",
                      borderStyle: "solid",
                      borderLeft: 0,
                    }}
                  >
                    <td>
                      <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                        TOTAL
                      </p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                    </td>
                    <td>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                    </td>
                    <td>
                      <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap text-center font-blue2">
                        RS348,332.00
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        className="mt-3 d-flex flex-wrap ac-jb px-2 w-100"
        style={{
          backgroundColor: "#07679c",
          padding: "20px",
          lineHeight: "30px",
        }}
      >
        <div className="w-90 m-auto d-flex ac-jb flex-wrap">
          <div className="col-md-5">
            <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
              Questions About this estimate?
            </p>
            <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
              Roger Daniel
            </h2>
            <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
              roger@companystore.io
            </a>
            <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
              merchHQ Pvt.Ltd.
            </p>
            <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
              #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
              Kartnataka, India.
            </p>
            <button
              class=" d-flex ac-jc ps-3 mt-sm-2"
              style={{
                backgroundColor: "#0082ca",
                border: "none",
                borderRadius: "7px",
                padding: "5px",
                color: "#fff",
              }}
            >
              <UnsubscribeIcon />

              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                Contact us
              </p>
            </button>
          </div>
          <div className="col-md-5  mt-3">
            <div className=" bg-blue1 rounded px-3 py-2">
              <table className="w-100">
                <tr>
                  <td>
                    {" "}
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      SUBTOTAL
                    </p>
                  </td>
                  <td>
                    {" "}
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      RS8,8,396,095.67
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      TAX - CGST 6:
                    </p>
                  </td>
                  <td>
                    {" "}
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      RS8,481,095.67
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      TOTAL
                    </p>
                  </td>
                  <td
                    style={{
                      borderBottom: "0px",
                      borderTop: "1px",
                      borderRight: "0",
                      borderColor: "#fff",
                      borderStyle: "solid",
                      borderLeft: 0,
                    }}
                  >
                    {" "}
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      INR RS,8,500,876.30
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <div class="d-flex white mt-2 ac-je">
              <FacebookRoundedIcon />

              <TwitterIcon />

              <LinkedInIcon />

              <InstagramIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesOrderA;
