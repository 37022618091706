import React, { useState } from "react";
import { product, searchIcon, viewIcon } from "../assets/img";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import EditShippingInfo from "../components/ShippingPopup/EditShippingInfo";
import {
  collaborateList,
  Po_products,
  Shipping_supplier,
} from "../redux/api/DummyJson";
import EditShippingPoppup from "../components/ShippingPopup/EditShippingPoppup";
import NavbarHead from "../components/Sub_Layout/NavbarHead";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Inprocess from "../components/POallRequest/Inprocess";
import Accepted from "../components/POallRequest/Accepted";
import Rejected from "../components/POallRequest/Rejected";

const PoScreen = () => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  const onEditClick = () => {
    setShowEditPopup(!showEditPopup);
  };
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("Shipping");

  const navigate = useNavigate();
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const onShowPopupHander = () => {
    setShow(!show);
  };
  return (
    <div className="dashRightView2 overflow-scroll ">
      <NavbarHead />
      {/* <div className="w-100">
        <div className="mx-2">
          <p class=" f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary text-start primary ">
            Project
          </p>
        </div>
        <div className="d-flex mt-4 ">
          <button
            onClick={() => onTabClick("Shipping")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Shipping"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }   cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Shipping" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              POs
            </p>
          </button>
        </div>
      </div> */}
      <div className="w-100 ">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex  gap-2">
            <button className="border-0 d-inline-block py-3 ms-1 bg-darkBlue text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Purchase Order
            </button>
            {/* <button class="fs-16 w-50 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0  fs-14">
              Proofs(0)
            </button> */}
          </div>
          {/* <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Action
          </button> */}
          <div className="d-flex mt-md-0 mt-3 border-search ">
            <img alt="img" src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      {showEditPopup && <EditShippingPoppup togglePopup={onEditClick} />}
      <div className="w-100 overflow-scroll mt-4 edit_page_cust bg-soft-gray1  bottom_cust_bord ">
        <table className="w-100 px-4">
          <tr className=" bg_fun">
            <th className="col-md-2 ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-3 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                {/* SKU's */}
              </p>
            </th>
            <th className="col-md-1  ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-3 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                SKU's
              </p>
            </th>
            <th className="col-md-1  ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-3 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                PO#
              </p>
            </th>
            <th className="col-md-2  ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-3 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                PO Details
              </p>
            </th>
            <th className="col-md-2 ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-3 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Status
              </p>
            </th>{" "}
            <th className="col-md-2 ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-3 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Production Rep
              </p>
            </th>
            <th className=" col-md-2 ">
              <p className=" p-3 f2 fs-xs-11 fs-sm-12 px-3 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                {/* Product */}
              </p>
            </th>
          </tr>

          {Po_products?.map((item, ind) => {
            return (
              <tr className="my-5 border-bottom"> 
                <td className=" my-3 pb-4">
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    <button className="mx-2 d-flex ac-jc cust-btn">
                      <div class="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary f3">
                        <input className="mx-2" type="checkbox" />
                      </div>
                      <img
                        src={item.img_po}
                        className="object-fit-contain img_size2 "
                      />
                    </button>
                  </p>
                </td>{" "}
                <td className=" my-3 pb-4">
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    <button className="mx-2 cust-btn">
                      <p className="primary1 ms-2 f1 fs-16  fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                        {item?.id}
                      </p>
                    </button>
                  </p>
                </td>{" "}
                <td className=" my-3 pb-4">
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    <button className="mx-2 cust-btn">
                      <p className="primary1 ms-2 f1 fs-16  fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                        {item?.po}
                      </p>
                    </button>
                  </p>
                </td>{" "}
                <td className=" my-3 pb-4">
                  <div className="mt-3">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-2">
                      {item?.POD}
                    </p>
                    <p className=" f1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 text-center  pb-4">
                      shipping Label | Packing slip
                    </p>
                  </div>
                </td>{" "}
                <td className=" my-3  pb-4">
                  <div className="d-flex justify-content-center align-item-center">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                      <button className=" border-primar bg-transparent get-start-btn rounded ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                        <select className="w-100 bg-transparent border-0 px-2 py-3 primary1">
                          <option selected>Created</option>
                          <option value="Garg Enterprises">Not Created</option>
                        </select>
                      </button>
                    </p>
                  </div>
                  <div className="d-flex justify-content-center align-item-center">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                      <button
                        className=" border-primar bg-transparent get-start-btn rounded ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                        placeholder="Search"
                      >
                        <select className="w-100 bg-transparent border-0 px-2 py-3 primary1">
                          <option selected>Ok</option>
                          <option value="Garg Enterprises">Not Ok</option>
                        </select>
                      </button>
                    </p>
                  </div>
                  <div className="d-flex justify-content-center align-item-center">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                      <button
                        className=" border-0 get-start-btn respon-width bg-primary1  rounded ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                        placeholder="Search"
                      >
                        <select className="w-100 bg-transparent border-0 px-2 py-3 blue">
                          <option selected>No Proof Required</option>
                          <option value="Garg Enterprises">
                            Proof Required
                          </option>
                        </select>
                      </button>
                    </p>
                  </div>
                </td>{" "}
                <td className=" my-3 pb-4">
                  <div className="d-flex justify-content-center align-item-center">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                      <button
                        className=" border-primar bg-transparent get-start-btn rounded ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                        placeholder="Search"
                      >
                        <select className="w-100 bg-transparent border-0 px-2 py-3 primary1">
                          <option selected>Roger Daniel</option>
                          <option value="Garg Enterprises">ShakthiVel</option>
                        </select>
                      </button>
                    </p>
                  </div>
                </td>{" "}
                <td className=" my-3 pb-4">
                  <div className="d-flex justify-content-center align-item-center">
                    <button
                      // onClick={onEditClick}
                      className=" border-primar mt-sm-3 ms-1 bg-darkBlue text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-4 rounded"
                    >
                      Email to Supplier
                    </button>
                  </div>
                  <div className="d-flex justify-content-center align-item-center">
                    <button
                      // onClick={onEditClick}
                      className=" border-primar mt-sm-3 ms-1 bg-darkBlue  text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                    >
                      Notes
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default PoScreen;
