import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DecorativeComp from '../DecorativeComp/DecorativeComp'
import UploadIcon from "@mui/icons-material/Upload";

const DecorativeDetails = ({ place, place2, decorative2 }) => {
  const navigate = useNavigate()
  const [decorative, setDecorative] = useState(false);
  const [productList, setParoductList] = useState("");
  console.log(place, place2)
  const Path = useLocation()
  console.log(Path?.state)
  const location = Path?.state?.type
  const loc = Path?.state?.type
  const loc2 = Path?.state
  console.log(location)
  console.log(loc)
  console.log(place)
  return (
    <div className="w-100 mt-5">
      <div className="d-flex ac-jb flex-column flex-md-row mt-5">
        <div className="d-flex ac-jc flex-wrap">
          <button
            onClick={() => navigate('/decorative-product')
            }
            className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            +{" "}Add Decorative
          </button>
        </div>
        <div className="d-flex ac-jc gap-3">
          <button
            className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            Export
          </button>
        </div>
      </div>
      <DecorativeComp place={place} decorative2={decorative2} />
      {/* {Path?.pathname === "/vendor-details" && place === 'Charles' && <ProductListComp loc={loc} />} */}
    </div>
  );
};

export default DecorativeDetails;
