import React, { useState } from "react";
import { danger, product } from "../../../assets/img";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Close } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sub_menu } from "../../../redux/api/DummyJson";

const EstimateEditComp = ({ poppupImageView, toggleShowPopups }) => {
  const [warnings, setWarnings] = useState([]);
  const [dropdown, setDropDown] = useState(null);

  const handleChangeWarn = (e, index) => {
    let temp = [...warnings];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setWarnings(temp);
  };
  const removeWarn = (e, ind) => {
    let temp = [...warnings];
    temp.splice(ind, 1);
    setWarnings(temp);
  };
  const addWarn = () => {
    let temp = [...warnings];
    temp.push({ warn: "" });
    setWarnings(temp);
  };
  return (
    <div className="d-flex as-jb w-100 mt-5 res-flex_img gap-5">
      <div className="d-flex flex-wrap w-100 w-md-70 ac-jb res-flex_im flex-m-r">
        <div className="d-flex w-100 gap-3 ac-jb flex-m-r">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Name
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
            </div>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Supplier
            </p>
            <div className="position-relative">
              <input
                value={"Sample Supplier"}
                placeholder="Required Field"
                disabled
                type={"text"}
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(1);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropdown == 1 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropdown == 1 && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDown(null);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-xl-30 w-lg-30 w-md-100 w-sm-100 w-xs-100">
            <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              SKU
            </p>
            <div className="position-relative">
              <p className="w-100 rounded-3 mt-2 py-2 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                301WH1026
              </p>
            </div>
          </div>
          <div className="w-xl-30 w-lg-30 w-md-100 w-sm-100 w-xs-100">
            <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Decoration Methods
            </p>

            <div className="position-relative">
              <input
                value={"Unspecified"}
                placeholder="Required Field"
                disabled
                type={"text"}
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(2);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropdown == 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropdown == 2 && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDown(null);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="w-100">
          <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Description
          </p>
          <div className="position-relative">
            <textarea
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div>
        </div>
        <div className="w-100">
          <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Private Note
          </p>
          <div className="position-relative">
            <textarea
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div>
        </div>
        <div className="w-100">
          <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Discount Margin
          </p>
          <div className="position-relative">
            <div className="d-flex mt-3 mx-3">
              <lable className="d-flex">
                <input type="checkbox" className="mx-1 " />
                <p className="me-2 cp f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                  Show to Customer
                </p>
              </lable>
              <lable className="d-flex">
                <input type="checkbox" className="mx-1 " />
                <p className="me-2 cp f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                  Show to Enterprise
                </p>
              </lable>
            </div>
          </div>
        </div>
        {/* <div className="w-100 d-flex align-items-start  align-items-md-center gap-0 gap-md-3 flex-column flex-md-row" >
                    <button className="black d-flex gap-1 ac-jc cust-btn w-auto text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        <img src={danger} className='danger_icon' />
                        <p className='f2 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>WARNING:</p>
                    </button>
                    <div className='w-100 d-flex ac-jc gap-2'>
                        <div className="position-relative w-100">
                            <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            />
                        </div>
                        <button className="cust-btn" >
                            <Close className='f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16' />
                        </button>
                    </div>
                </div> */}
        {warnings?.map((item, ind) => (
          <div className="w-100 d-flex align-items-start  align-items-md-center gap-0 gap-md-3 flex-column flex-md-row">
            <button className="black d-flex gap-1 ac-jc cust-btn w-auto text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              <img src={danger} className="danger_icon" />
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                WARNING:
              </p>
            </button>
            <div className="w-100 d-flex ac-jc gap-2">
              <div className="position-relative w-100">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  onChange={(e) => handleChangeWarn(e, ind)}
                />
              </div>
              <button
                className="cust-btn"
                onClick={() => removeWarn(item, ind)}
              >
                <Close className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16" />
              </button>
            </div>
          </div>
        ))}
        <div className="d-flex ac-js gap-2 mt-3 flex-column flex-sm-row w-100 mt-4">
          <button
            class="text-nowrap cust-btn addbtn ms-1 bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
            onClick={() => addWarn()}
          >
            Add Warning
          </button>
          <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-13 fs-xxl-14">
            Please check supplier website for any warnings now shown
          </p>
        </div>
      </div>
      <div className="d-flex gap-2 flex-column ac-jc mb-5 w_auto_cust">
        <div className="present-edit-img">
          <div className="imgs w-100 position-relative ">
            <img
              src={product}
              className="img_up_cont_hover object-fit-contain"
            />
            <div className="position-absolute click-to-edit">
              <span className="border-0 " onClick={() => toggleShowPopups()}>
                Click to Edit
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='d-flex gap-2 flex-column ac-jc w-30 mb-5'>
                <div class="w-100 mt-3 gap-3 d-flex flex-wrap ac-jc pb-4">
                    <div class="add_img d-flex ac-jc">
                        <img src={product} class="cp add_img object-fit-fill" />
                    </div>
                    <label class="add_img d-flex ac-jc cp f3 primary fs-3">
                        +
                        <input class="d-none" type="file" accept="image/png, image/gif, image/jpeg" />
                    </label>
                </div>
            </div> */}
    </div>
  );
};

export default EstimateEditComp;
