import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import OverviewTab from "../components/RequestDetailView/OverviewTab";

const RequestAcceptScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <p className="d-flex ac-jb f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary ">
        Confirm Request
        <span>
          <button
            class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
            onClick={() => navigate("/pricing-page")}>
            Next
          </button>
        </span>
      </p>
      <div className="d-flex as-jb w-100 res-flex gap-5">
        <OverviewTab />
      </div>
    </div>
  );
};

export default RequestAcceptScreen;
