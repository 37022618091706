import AutorenewIcon from "@mui/icons-material/Autorenew";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Rejected = ({ onShowPopupHander }) => {
  const navigate = useNavigate();
  const [remove, setRemove] = useState(null);
  const [removePoopup, setRemovePoppup] = useState(false);
  const removPopp = () => {
    setRemovePoppup(false);
  };
  const CheckCl = () => {
    setRemove(!remove);
    setRemovePoppup(!removePoopup);
  };

  const data = [
    {
      id: 1,
      vendor_id: "Ven_001",
      req_po_id: "Pq-PO-001",
      vendor_name: "Dhanush",
      vendor_email: "dhanush@gmail.com",
      mobile: "9080877665",
      total_qty: "600",
      total: "30000",
      ex_date: "12/12/2024",
      ex_date: "12/12/2024",
      reje_date: "12/12/2024",
    },
  ];

  return (
    <div className="w-100 overflow-scroll mt-4 position-relative">
      <table className="w-100 position-relative">
        {removePoopup && (
          <div className="min_pop d-flex ac-jc p-2 flex-column viewBoxDes rounded-2 mx-4 mt-4">
            <p className="px-2 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 py-2">
              Are you sure want to remove the vendor
            </p>
            <button
              onClick={() => removPopp()}
              class="cust-btn addbtn bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
            >
              Submit
            </button>
          </div>
        )}
        <thead>
          <tr>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                S.No
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor ID
              </p>
            </th>{" "}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Requested PO ID
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor Name
              </p>
            </th>{" "}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor Email
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Vendor Mobile Number
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Total Requested Quantity
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Total Amount
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Expected Delivery Date
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Rejected Date
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Re-Assign
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Notes
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                View
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Reject Deatils
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, ind) => {
            return (
              <tr className="my-3">
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {ind + 1}
                  </p>
                </td>
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.vendor_id}
                  </p>
                </td>{" "}
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.req_po_id}
                  </p>
                </td>
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.vendor_name}
                  </p>
                </td>{" "}
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.vendor_email}
                  </p>
                </td>
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.mobile}
                  </p>
                </td>
                <td>
                  <div className="d-flex ac-jc pb-4">
                    <input
                      placeholder=""
                      value={item?.total_qty}
                      className="datepicker-input w-50 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                    />
                  </div>
                </td>
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.total}
                  </p>
                </td>
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.ex_date}
                  </p>
                </td>
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.reje_date}
                  </p>
                </td>
                <td>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 pb-4">
                    <AutorenewIcon />
                  </p>
                </td>
                <td>
                  <p
                    className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4"
                    role={"button"}
                    onClick={onShowPopupHander}
                  >
                    <CommentOutlinedIcon />
                  </p>
                </td>
                <td>
                  <button
                    onClick={() => navigate("/po-detail-reject-view")}
                    className="w-100 text-center bg-transparent border-0"
                  >
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 pb-4">
                      <VisibilityIcon />
                    </p>
                  </button>
                </td>
                <td>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 pb-4">
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            Product Rejected reason will show here
                          </Tooltip>
                        )}
                        placement="bottom"
                      >
                        <ErrorOutlineIcon className="fs-16 fs-sm-18 text-custom-res fs-lg-20 ms-1 mt-1" />
                      </OverlayTrigger>
                    </p>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Rejected;
