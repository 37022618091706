import { createSlice } from "@reduxjs/toolkit";

const saveshowproductionProof = createSlice({
  name: "get report",
  initialState: "",
  reducers: {
    savepopupProduction: (state, action) => {
      return action.payload;
    },
  },
});

export const { savepopupProduction } = saveshowproductionProof.actions;
export default saveshowproductionProof.reducer;
