import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SingleShipping = () => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  console.log(data);

  return (
    <div className="w-100 searchField mt-5 bg-white ">
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-2">
        Single Shipping Details
      </p>
      <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Name:
            </p>
            <div className="w-70 ms-3">
              <input
                disabled={!edit}
                placeholder=""
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value="Json"
              />
            </div>
          </div>
          {/* <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Customer ID:
            </p>
            {edit && (
              <div className="w-70 ms-3">
                <input
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value="CMHQ001"
                />
              </div>
            )}
            {!edit && (
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                CMHQ001
              </p>
            )}
          </div> */}
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Email:
            </p>
            <div className="w-70 ms-3">
              <input
                disabled={!edit}
                placeholder=""
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={data?.email}
              />
            </div>
          </div>
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Phone Number:
            </p>
            <div className="w-70 ms-3">
              <input
                disabled={!edit}
                placeholder=""
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={data?.phoneNo}
              />
            </div>
          </div>
          {/* <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Organization:
            </p>
            {edit && (
              <div className="w-70 ms-3">
                <input
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value="Ninos IT Solution"
                />
              </div>
            )}
            {!edit && (
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                Ninos IT Solution
              </p>
            )}
          </div> */}
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Pack:
            </p>
            <div className="w-70 ms-3">
              <input
                disabled={!edit}
                placeholder=""
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value="25"
              />
            </div>
          </div>
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Products Request Count:
            </p>
            <div className="w-70 ms-3">
              <input
                disabled={!edit}
                placeholder=""
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value="5"
              />
            </div>
          </div>
          {/* <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Customer Type:
            </p>
            {edit && (
              <div className="w-70 ms-3">
                <input
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value="E-Commerce"
                />
              </div>
            )}
            {!edit && (
              <div className="w-70 ms-3">
                <input
                  disabled
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value="E-Commerce"
                />
              </div>
            )}
          </div> */}
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Address:
            </p>
            <div className="w-70 ms-3">
              <textarea
                disabled={!edit}
                placeholder=""
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={data?.ContactDetails}
              />
            </div>
          </div>
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Floor, Suit, Unit (optional):
            </p>
            <div className="w-70 ms-3">
              <input
                disabled={!edit}
                placeholder=""
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value="3rd Floor"
              />
            </div>
          </div>
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              City:
            </p>
            <div className="w-70 ms-3">
              <input
                disabled={!edit}
                placeholder=""
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value="Chennai"
              />
            </div>
          </div>
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              State:
            </p>
            <div className="w-70 ms-3">
              <select
                disabled={!edit}
                className="w-50 cust-btn searchFill fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                <option>Tamil Nadu</option>
                <option>Pondichery</option>
                <option>Kerala</option>
              </select>
            </div>
          </div>
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Pincode:
            </p>
            <div className="w-70 ms-3">
              <input
                disabled={!edit}
                placeholder=""
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value="603211"
              />
            </div>
          </div>
          <div className="w-100 d-flex ac-js mt-3">
            <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Country:
            </p>
            <div className="w-70 ms-3">
              <select
                disabled={!edit}
                className="w-50 cust-btn searchFill fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                <option>India</option>
                <option>America</option>
                <option>France</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
        <button
          onClick={() => setEdit(!edit)}
          className="pointerView cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          {edit ? "Submit" : "Edit"}
        </button>
      </div>
    </div>
  );
};

export default SingleShipping;
