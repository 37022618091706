const AddEnterpriseDetails2 = ({ setVendorCat, vendorCat }) => {
  return (
    <>
      <div className="w-90 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Enterprises Name*
          </p>
          <input
            placeholder=""
            value="Roger"
            className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Person Email*
          </p>
          <input
            placeholder=""
            value="john@gmail.com"
            className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
      </div>
      <div className="w-90 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Person Number*
          </p>
          <input
            placeholder=""
            value="9876543210"
            className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
      </div>
    </>
  );
};

export default AddEnterpriseDetails2;
