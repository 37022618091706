import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import { colors } from "../../redux/api/DummyJson";

const VariationsPopUp = ({ toggleShowPopup, variationType }) => {
  const [btn, setBtn] = useState(0);
  const [addData, setAddData] = useState("");
  const [addColor, setAddColor] = useState([]);
  const [addSize, setAddSize] = useState([]);

  //   COLOR DROPDOWN

  const [colorsName, setColorsName] = useState([]);
  const [colorsShow, setColorsShow] = useState(false);

  const onColors = (e) => {
    setColorsName(e.target.value);
    if (colorsName.length >= 2) {
      setColorsShow(true);
    } else {
      if (colorsName.length >= 0) setColorsShow(false);
    }
  };
  const ColorSlect = (e) => {
    if (colorsName.includes(e)) {
      const listtemp = colorsName.indexOf(e);
      const list = [...colorsName];
      list.splice(listtemp, 1);
      setColorsName(list);
      setAddColor(list);
    } else {
      setColorsName([...colorsName, e]);
      setAddColor([...addColor, e]);
    }
  };

  useEffect(() => {
    if (variationType == "project-colors") {
      setBtn(0);
    } else {
      setBtn(1);
    }
  }, []);

  const addSizeCol = (type) => {
    let color = [...addColor];
    let size = [...addSize];

    if (type == 0) {
      color.push(addData);
      setAddColor(color);
    } else {
      size.push(addData);
      setAddSize(size);
    }
    setAddData("");
  };

  return (
    <div className="variation-popup">
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          Select File
          <span>
            <button
              onClick={() => toggleShowPopup("variation")}
              // className="cust-btn"
              className="cust-btn addbtn ms-1  bg-primar text-white f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 py-md-2 px-5 rounded"
            >
              {/* <HighlightOffIcon /> */}
              Done
            </button>
          </span>
        </p>
        <div className="d-flex over_files_pop mt-4">
          <button
            class={btn == 0 ? "border_click_cust bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3  tranc" : 'cust-btn  bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3  tranc'}
            // className={btn == 0 ? "var-btn btn_overview_line" : "var-btn"}
            onClick={() => setBtn(0)}
          >
            Colors
          </button>
          <button
            class={btn == 1 ? "border_click_cust bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3  tranc" : 'cust-btn  bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3  tranc'}
            onClick={() => setBtn(1)}
          >
            Sizes
          </button>
        </div>
        <div className="d-flex justify-content-between mt-4 flex-column flex-md-row">
          {btn == 1 ? (
            <div className="d-flex w-100 ac-jc">
              <input
                className="editBtn w-100 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                onChange={(e) => setAddData(e.target.value)}
                value={addData}
              />
              <button
                className="cust-btn addbtn ms-2 bg-primar text-white f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 py-md-2 px-5 rounded text-nowrap" onClick={() => addSizeCol(btn)}
              >
                Add
              </button>
            </div>
          ) : (
            <div className="d-flex w-100 flex-column flex-md-row">
              <div className="w-100 position-relative">
                <div className="position-relative">
                  <input
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={colorsName}
                    onChange={onColors}
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setColorsShow(!colorsShow);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                </div>
                {colorsShow && (
                  <div
                    className="invisible-cont2"
                    onClick={() => setColorsShow(!colorsShow)}
                  />
                )}
                <div
                  className={`${colorsShow && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-3`}
                >
                  {colors?.map((item, ind) => {
                    // console.log(ind)
                    return (
                      <button
                        className="d-flex ac-jb hover-cust cust-btn w-100 px-2"
                        onClick={() => {
                          ColorSlect(item?.color);
                        }}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100">
                          {item?.color}
                        </button>
                        {colorsName.includes(item?.color) ? (
                          <Circle style={{ color: item?.color }} />
                        ) : (
                          <CircleOutlined className="primary" />
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          <div className="d-flex w-100 ac-je mt-4 mt-md-0">
            <button className="cust-btn addbtn ms-1  bg-primar text-white f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 py-md-2 px-5 rounded text-nowrap">
              Select all
            </button>
            <button className="cust-btn addbtn ms-1  bg-primar text-white f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 py-md-2 px-5 rounded text-nowrap">
              Select none
            </button>
          </div>
        </div>
        {btn == 0 && <div className="d-flex gap-3 as-js mt-3 flex-wrap flex-md-row cust_w_color">
          {btn == 0 &&
            addColor?.map((item, ind) => {
              return (
                <div className="vari-col-size editBtn color_w  rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <input type={"checkbox"} />
                      <p className="mx-2">{item}</p>
                    </div>
                    <Circle style={{ color: item }} className="" />
                  </div>
                </div>
              );
            })}
        </div>}
        <div className="d-flex flex-wrap justi-fy-content-center cust_w_color mt-4">
          {btn == 1 &&
            addSize?.map((item, ind) => {
              return (
                <>
                  <div className="mt-2 mx-2 vari-col-size editBtn  rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <input type={"checkbox"} />
                        <p className="mx-2">{item}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default VariationsPopUp;
