import React, { useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Col } from "react-bootstrap";
import { product } from "../../assets/img";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { saveImage } from "../../redux/slice/imageSave";

const AddFilesPopupCopy = ({
  toggleShowPopup,
  popupType,
  toggleImagePopup,
}) => {
  const dispatch = useDispatch();
  const [btn, setBtn] = useState(1);
  const [tab, setTab] = useState(0);
  const [upload, setUpload] = useState(false);
  const [progress_Bar, setProgress_Bar] = useState(false);
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState(false);

  // PROGRESS BAR
  const [countOfProgess, setCountOfProgess] = useState(0);

  useEffect(() => {
    if (progress_Bar) {
      const timer = setInterval(() => {
        setCountOfProgess((oldProgress) => {
          if (100 == oldProgress) {
            setProgress_Bar(false);
            setImageUrl(true);
            return 0;
          }
          return Math.min(oldProgress + Math.random() * 10, 100);
        });
      }, 499);

      return () => {
        clearInterval(timer);
      };
    }
  }, [progress_Bar]);

  const handleImage = (file) => {
    setImage(file);
    if (file?.name) {
      dispatch(saveImage(URL.createObjectURL(file)));
    } else {
      dispatch(saveImage(file));
    }
  };

  return (
    <>
      <div className="add-files-overiew-popup">
        <div>
          <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            Select File
            <span>
              <button onClick={() => toggleShowPopup()} className="cust-btn">
                <HighlightOffIcon />
              </button>
            </span>
          </p>
          <div>
            <div className="files_pop_pro">
              <div className="over_img ">
                <img src={product} className="w-100 " />
                <div className="files_btn">
                  {/* <button className="mx-1">Edit</button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <Col lg={8} md={8}>
              <div className="d-flex over_files_pop">
                <button
                  className={btn == 0 && "btn_overview_line"}
                  onClick={() => setBtn(0)}
                >
                  Item Files
                </button>
                <button
                  className={btn == 1 && "btn_overview_line"}
                  onClick={() => setBtn(1)}
                >
                  Project Files
                </button>
                <button
                  className={btn == 2 && "btn_overview_line"}
                  onClick={() => setBtn(2)}
                >
                  Client Files
                </button>
              </div>
            </Col>
            <Col lg={4} md={4}>
              {btn == 2 && (
                <select className="w-40 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2">
                  <option>All Files</option>
                </select>
              )}
              {popupType == "addClient" && (
                <button
                  onClick={toggleImagePopup}
                  // value={"Upload"}
                  // onClick={() => setUpload(!upload)}
                  className="w-100 cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                >
                  Upload
                </button>
              )}
            </Col>
          </div>
          {btn == 1 && (
            <div className="d-flex">
              <div className="files_pop_pro">
                <div className="over_img">
                  <img src={product} className="w-100" />
                  <div className="files_btn">
                    <button className="mx-1">
                      <FileDownloadIcon />
                    </button>
                  </div>
                </div>
                <p className="im1 mt-3">Jobs1.jpeg</p>
                <p className="im2">Updated 14 October 2023</p>
              </div>
              {image && (
                <div className="files_pop_pro mx-2">
                  <div className="over_img">
                    <img
                      src={image?.name ? URL.createObjectURL(image) : image}
                      className="w-50"
                    />
                    <div className="files_btn">
                      <button className="mx-1">
                        <FileDownloadIcon />
                      </button>
                    </div>
                  </div>
                  <p className="im1 mt-3">Jobs1.jpeg</p>
                  <p className="im2">Updated 14 October 2023</p>
                </div>
              )}
            </div>
          )}
          {btn == 2 && (
            <div className="d-flex justify-content-around">
              <div className="files_pop_pro">
                <div className="over_img">
                  <img src={product} className="w-100" />
                  <div className="files_btn">
                    <button className="mx-1">
                      <FileDownloadIcon />
                    </button>
                  </div>
                </div>
                <p className="im1 mt-3">Jobs1.jpeg</p>
                <p className="im2">Updated 14 October 2023</p>
              </div>
              <div className="files_pop_pro">
                <div className="over_img">
                  <img src={product} className="w-100" />
                  <div className="files_btn">
                    <button className="mx-1">
                      <FileDownloadIcon />
                    </button>
                  </div>
                </div>
                <p className="im1 mt-3">Jobs1.jpeg</p>
                <p className="im2">Updated 14 October 2023</p>
              </div>
              <div className="files_pop_pro">
                <div className="over_img">
                  <img src={product} className="w-100" />
                  <div className="files_btn">
                    <button className="mx-1">
                      <FileDownloadIcon />
                    </button>
                  </div>
                </div>
                <p className="im1 mt-3">Jobs1.jpeg</p>
                <p className="im2">Updated 14 October 2023</p>
              </div>
            </div>
          )}

          <div className="d-flex mt-3 justify-content-end m-2">
            <button
              onClick={() => toggleShowPopup()}
              className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {upload && (
        <div className="upload-file-popup z-3">
          <div>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Upload File
              <span>
                <button onClick={() => setUpload(!upload)} className="cust-btn">
                  <HighlightOffIcon />
                </button>
              </span>
            </p>

            <div className="d-flex over_files_pop">
              <button
                className={tab == 0 && "btn_overview_line"}
                onClick={() => setTab(0)}
              >
                From Computer
              </button>
              <button
                className={tab == 1 && "btn_overview_line"}
                onClick={() => setTab(1)}
              >
                From Web
              </button>
            </div>
            {tab == 1 ? (
              <div className="mt-3">
                <div className="d-flex">
                  <input
                    type={"url"}
                    className="w-50"
                    placeholder="http://www..."
                    onChange={(e) => handleImage(e.target.value)}
                  />
                  <button
                    className={
                      image?.length > 0
                        ? "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                        : "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded opacity-50"
                    }
                    onClick={() => setProgress_Bar(true)}
                    disabled={image?.length > 0 ? false : true}
                  >
                    Select
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="text-center fileUp-load-box  mt-3">
                  <label className="mt-3 text-info">
                    Drag or click to upload file
                    <input
                      type={"file"}
                      className="d-none"
                      accept="image/png, image/jpeg"
                      onChange={(e) => handleImage(e.target.files[0])}
                    />
                  </label>
                </div>
                <div className="mt-2 text-end">
                  {image?.name && (
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => setProgress_Bar(true)}
                    >
                      Select
                    </button>
                  )}
                </div>
              </>
            )}

            {progress_Bar && (
              <div className="d-block w-50 mt-2">
                <ProgressBar now={countOfProgess} />
                <div className="text-end">
                  <span>{parseInt(countOfProgess)} %</span>
                </div>
              </div>
            )}
            {imageUrl && (
              <div onClick={() => setUpload(false)}>
                <img
                  src={image?.name ? URL.createObjectURL(image) : image}
                  className="mt-2 w-25"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AddFilesPopupCopy;
