import { Outlet } from "react-router-dom";
import ExampleSideBar from "./ExampleSideBar";
import NavbarHead from "./NavbarHead";
import ProfileNavBar2 from "./ProfileNavBar2";

const Layout2 = () => {
  return (
    <div className="dashboard">
      <ProfileNavBar2 />
      <Outlet />
    </div>
  );
};

export default Layout2;
