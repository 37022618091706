import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";

import GetAppIcon from "@mui/icons-material/GetApp";
import { animatedImg, searchIcon, viewIcon } from "../assets/img";

const ActivityLogs = () => {
  const navigate = useNavigate();

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} alt="animate_img" className="searchManImg" />
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Activity Logs
        </p>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex ac-jb flex-md-row flex-column">
          {/* <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 mt-4 px-3">
            Total Count : {"02"}
          </p>
          <div className="pointerView d-flex mt-4 me-3">
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div> */}
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          {/* <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            + Add Product Transfer
          </button> */}
        </div>
      </div>

      <table className="overflow-scroll d-block mt-5">
        <tr className="d-flex border-bottom" style={{ width: "fit-content" }}>
          <th
            style={{ width: "100px", padding: " 10px" }}
            className="text-center"
          >
            S.No
          </th>
          <th
            style={{ width: "180px", padding: " 10px" }}
            className="text-center"
          >
            Module
          </th>
          <th
            style={{ width: "300px", padding: " 10px" }}
            className="text-center"
          >
            Action By
          </th>
          <th
            style={{ width: "150px", padding: " 10px" }}
            className="text-center"
          >
            Profile Type
          </th>
          <th
            style={{ minWidth: "230px", padding: " 10px" }}
            className="text-center"
          >
            Message
          </th>
          <th
            style={{ width: "230px", padding: " 10px" }}
            className="text-center"
          >
            Date & Time
          </th>
        </tr>
        <tr className="d-flex" style={{ width: "fit-content" }}>
          <td
            style={{ width: "100px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            1
          </td>
          <td
            style={{ width: "180px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            Enterprises
          </td>
          <td
            style={{ width: "300px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            Hari - 34355464
          </td>
          <td
            style={{ width: "150px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            Admin
          </td>
          <td
            onClick={() => {
              navigate("/home");
            }}
            style={{ width: "230px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1 pointer"
          >
            Hari changed the List status
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            21/04/2024 (10.15AM)
          </td>
        </tr>
        <tr className="d-flex" style={{ width: "fit-content" }}>
          <td
            style={{ width: "100px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            2
          </td>
          <td
            style={{ width: "180px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            Product Transfer
          </td>
          <td
            style={{ width: "300px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            Sakthi - 32343456
          </td>
          <td
            style={{ width: "150px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            Vendor
          </td>
          <td
            onClick={() => {
              navigate("/home");
            }}
            style={{ width: "230px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1 pointer"
          >
            Sakthi increased No of orders in products.
          </td>
          <td
            style={{ width: "230px", padding: "10px" }}
            className="text-center pb-3 px-4 primary1"
          >
            24/4/2024 (01.00PM)
          </td>
        </tr>
      </table>

      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default ActivityLogs;
