import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const PresentationEditTable = ({ runCharge, setRunCharge }) => {
  const [numbers, setNumbers] = useState([]);
  const [imprintCost, setImprintCost] = useState({
    0: "Imprint Cost",
    1: "Imprint Cost",
  });

  const onNoNameChange = (e, index) => {
    const value = e.target.value;
    setImprintCost((pre) => ({ ...pre, [index]: value }));
  };
  const addRunCharge = () => {
    let temp = [...runCharge];

    temp.push({
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      num7: "",
      num8: "",
    });
    setRunCharge(temp);
  };

  const removeRunCharge = (e, ind) => {
    let temp = [...runCharge];
    temp.splice(ind, 1);
    setRunCharge(temp);
  };

  const handleRunCharge = (e, index) => {
    let temp = [...runCharge];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setRunCharge(temp);
  };

  const enableBtn = (num) => {
    let temp = [...numbers];

    let event = temp.indexOf(num);

    if (temp?.includes(num)) {
      temp.splice(event, 1);
    } else {
      temp.push(num);
    }
    setNumbers(temp);
  };

  return (
    <div className="" style={{ width: "500px" }}>
      <table className="w-100  overflow-scroll">
        <tr className="mt-4">
          <td>
            <div className="mx-2 d-flex ac-js table_head">
              <p className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Qty
              </p>
            </div>
          </td>
          <div className="my-3">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(1) && "opacity-50"
                    }`}
                    type="number"
                    placeholder="25"
                    disabled={numbers?.includes(1) ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(2) && "opacity-50"
                    }`}
                    type="number"
                    placeholder="50"
                    disabled={numbers?.includes(2) ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(3) && "opacity-50"
                    }`}
                    type="number"
                    placeholder="100"
                    disabled={numbers?.includes(3) ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(4) && "opacity-50"
                    }`}
                    type="number"
                    placeholder="150"
                    disabled={numbers?.includes(4) ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(5) && "opacity-50"
                    }`}
                    type="number"
                    placeholder="250"
                    disabled={numbers?.includes(5) ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(6) && "opacity-50"
                    }`}
                    type="number"
                    placeholder="500"
                    disabled={numbers?.includes(6) ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(7) && "opacity-50"
                    }`}
                    type="number"
                    placeholder="1000"
                    disabled={numbers?.includes(7) ? false : true}
                  />
                </div>
              </div>
            </td>
          </div>
        </tr>
        <tr>
          <th>
            <div className="mx-2 d-flex ac-js table_head">
              <p className="primary text-nowrap f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show to client?
              </p>
            </div>
          </th>
          <div className="mt-2">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="checkbox"
                    onClick={() => enableBtn(1)}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="checkbox"
                    onClick={() => enableBtn(2)}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="checkbox"
                    onClick={() => enableBtn(3)}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="checkbox"
                    onClick={() => enableBtn(4)}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-center"
                    type="checkbox"
                    onClick={() => enableBtn(5)}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-center"
                    type="checkbox"
                    onClick={() => enableBtn(6)}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-center"
                    type="checkbox"
                    onClick={() => enableBtn(7)}
                  />
                </div>
              </div>
            </td>
          </div>
        </tr>
        {/* <tr>

          <th>
            <div className="mx-2 d-flex ac-js table_head">
              <p className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Net
              </p>
            </div>
          </th>
          <div className="my-3">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="number"
                    placeholder="$100.00"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$90.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$85.00"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$80.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$90.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$100.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$120.00"
                    type="number"
                  />
                </div>
              </div>
            </td>
          </div>
        </tr> */}
        {runCharge?.map((item, ind) => {
          return (
            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                <div className="d-flex">
                  <span>
                    <button
                      className="cust-btn"
                      onClick={() => removeRunCharge(item, ind)}>
                      <HighlightOffIcon />
                    </button>
                  </span>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#07679c",
                    }}
                    className=" num-cls-btn rounded-3 d-flex border-0 ac-jc">
                    <p className="p-0 text-light fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {ind + 1}
                    </p>
                  </div>
                  <input
                    class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center mx-2"
                    // value={item?.num1}
                    // value={"Imprint Cost"}
                    value={imprintCost[ind]}
                    name="num1"
                    onChange={(e) => {
                      handleRunCharge(e, ind);
                      onNoNameChange(e, ind);
                    }}
                  />
                </div>
              </th>
              <div className="mt-2">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes(1) && "opacity-50"
                        }`}
                        value={item?.num2}
                        placeholder="0.000"
                        name="num2"
                        type={"number"}
                        disabled={numbers?.includes(1) ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes(2) && "opacity-50"
                        }`}
                        value={item?.num3}
                        placeholder="0.000"
                        name="num3"
                        type={"number"}
                        disabled={numbers?.includes(2) ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes(3) && "opacity-50"
                        }`}
                        value={item?.num4}
                        placeholder="0.000"
                        name="num4"
                        type={"number"}
                        disabled={numbers?.includes(3) ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes(4) && "opacity-50"
                        }`}
                        value={item?.num5}
                        placeholder="0.000"
                        name="num5"
                        type={"number"}
                        disabled={numbers?.includes(4) ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes(5) && "opacity-50"
                        }`}
                        value={item?.num6}
                        placeholder="0.000"
                        name="num6"
                        type={"number"}
                        disabled={numbers?.includes(5) ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes(6) && "opacity-50"
                        }`}
                        value={item?.num6}
                        placeholder="0.000"
                        name="num7"
                        type={"number"}
                        disabled={numbers?.includes(6) ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes(7) && "opacity-50"
                        }`}
                        value={item?.num6}
                        placeholder="0.000"
                        name="num8"
                        type={"number"}
                        disabled={numbers?.includes(7) ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          );
        })}
        <tr style={{ textAlign: "center" }}>
          <td colspan="9" style={{ padding: "20px 0px" }}>
            <button
              className="border-0 text-info primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              style={{ backgroundColor: "#f4f8ff" }}
              onClick={() => addRunCharge()}>
              + add run charge
            </button>
          </td>
        </tr>
        <tr className="mt-2">
          <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Margin
          </th>
          <div className="mt-2 mx-2 d-flex ac-jc table_data">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="number"
                    placeholder="$100.00"
                  />
                </div>
                {/* <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$90.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$85.00"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$80.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$70.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$70.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="$70.00"
                    type="number"
                  />
                </div> */}
              </div>
            </td>
          </div>
        </tr>
        <tr>
          <td>
            <div className="d-flex ac-js table_head">
              <p className="primary text-start f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Total
              </p>
            </div>
          </td>
          <div className="mt-2">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(1) && "opacity-50"
                    }`}
                    type="number"
                    placeholder="$100.00"
                    disabled={numbers?.includes(1) ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(2) && "opacity-50"
                    }`}
                    placeholder="$90.00"
                    type="number"
                    disabled={numbers?.includes(2) ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(3) && "opacity-50"
                    }`}
                    placeholder="$85.00"
                    disabled={numbers?.includes(3) ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(4) && "opacity-50"
                    }`}
                    placeholder="$80.00"
                    type="number"
                    disabled={numbers?.includes(4) ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(5) && "opacity-50"
                    }`}
                    placeholder="$70.00"
                    type="number"
                    disabled={numbers?.includes(5) ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(6) && "opacity-50"
                    }`}
                    placeholder="$70.00"
                    type="number"
                    disabled={numbers?.includes(6) ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes(7) && "opacity-50"
                    }`}
                    placeholder="$70.00"
                    type="number"
                    disabled={numbers?.includes(7) ? false : true}
                  />
                </div>
              </div>
            </td>
          </div>
        </tr>
      </table>
    </div>
  );
};

export default PresentationEditTable;
