import ProfileNavBar from "../components/ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AddVendorDetails1 from "../components/AddVendor/AddVendorDetails1";
import AddVendorDetails2 from "../components/AddVendor/AddVendorDetails2";
import DoneIcon from "@mui/icons-material/Done";
import AddVendorFinal from "../components/AddVendor/AddVendorFinal";
import AddVendorPopup from "../components/Popup/AddVendorPopup";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VendorSuccessPopup from "../components/Popup/VendorSuccessPopup";
import {
  useLazyCategoriesListVendorQuery,
  useVendorRegisterMutation,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const AddVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [secondDet, setSecondDet] = useState(false);
  const [finalView, setFinalView] = useState(false);
  const [vendorCat, setVendorCat] = useState("");
  const [popup, setPopup] = useState(false);
  const [successPop, setSuccessPop] = useState(false);
  const location = useLocation();
  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameErr, setCategoryNameErr] = useState(false);

  useEffect(() => {
    if (location?.state?.type === "email") {
      setSecondDet(true);
    }
  }, [location]);

  const showPopupHander = () => {
    setPopup(false);
    setSuccessPop(false);
  };

  // RTK QUERY
  const [categoryListApi] = useLazyCategoriesListVendorQuery();
  const [vendorRegisterApi] = useVendorRegisterMutation();

  const getList = () => {
    categoryListApi()
      .unwrap()
      .then((res) => {
        setCategoryList(res?.categories);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const [vendorName, setVendorName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryMobile, setPrimaryMobile] = useState("");
  const [vendorNameErr, setVendorNameErr] = useState(false);
  const [primaryEmailErr, setPrimaryEmailErr] = useState(false);
  const [primaryMobileErr, setPrimaryMobileErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [vendorCategories, setVendorCategories] = useState([]);

  const submitHandler = () => {
    if (
      vendorName?.length == 0 ||
      primaryEmail?.length == 0 ||
      primaryMobile?.length < 10 ||
      categoryName?.length == 0
    ) {
      setVendorNameErr(true);
      setPrimaryEmailErr(true);
      setPrimaryMobileErr(true);
      setCategoryNameErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", vendorName);
      formdata.append("email", primaryEmail);
      formdata.append("mobile", primaryMobile);

      vendorCategories?.map((item, ind) => {
        formdata.append(`category_ids[${ind}]`, item);
      });
      setBtn(true);
      dispatch(saveLoader(true));
      vendorRegisterApi(formdata)
        .unwrap()
        .then((res) => {
          toast.success(res?.data?.message || res?.message);
          dispatch(saveLoader(false));
          setPopup(true);
          setBtn(false);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // console.log("categoryList", categoryList);

  return (
    <div className="dashRightView p-5 home_section trans">
      {/* {popup && (
        <AddVendorPopup
          showPopupHander={showPopupHander}
          setPopup={setPopup}
          setSuccessPop={setSuccessPop}
        />
      )} */}
      {popup && <VendorSuccessPopup showPopupHander={showPopupHander} />}
      <div className="d-flex w-90 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={manImage} className="searchManImg" />
      </div>
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Add Vendor
      </p>

      <div className="d-flex ac-js mt-4">
        {!secondDet && !finalView && (
          <p className="white bg-primar f2 radiusVendDes fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            1
          </p>
        )}
        {(finalView || secondDet) && (
          <DoneIcon className="white bg-green1 f2 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " />
        )}
        <p className="black f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
          Primary Detail
        </p>
        <div className="border-bottom w-5 border-primary1" />
        {!finalView && (
          <p
            className={`${
              secondDet ? "bg-primar" : "bg-primary1"
            } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
          >
            2
          </p>
        )}
        {finalView && (
          <DoneIcon className="white bg-green1 f2 ms-3 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " />
        )}
        <p
          className={`
        ${
          secondDet || finalView ? "black" : "primary1"
        } f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          General Detail
        </p>
        <div className="border-bottom w-5 border-primary1" />

        <p
          className={`
        ${
          finalView ? "bg-primar" : "bg-primary1"
        } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          3
        </p>
        <p
          className={`${
            finalView ? "black" : "primary1"
          }  f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          Final Verification
        </p>
      </div>
      {!secondDet && !finalView && (
        <AddVendorDetails2
          setVendorCat={setVendorCat}
          vendorCat={vendorCat}
          categoryList={categoryList}
          vendorName={vendorName}
          setVendorName={setVendorName}
          primaryEmail={primaryEmail}
          setPrimaryEmail={setPrimaryEmail}
          primaryMobile={primaryMobile}
          setPrimaryMobile={setPrimaryMobile}
          primaryEmailErr={primaryEmailErr}
          primaryMobileErr={primaryMobileErr}
          vendorNameErr={vendorNameErr}
          vendorCategories={vendorCategories}
          setVendorCategories={setVendorCategories}
          categoryName={categoryName}
          setCategoryName={setCategoryName}
          categoryNameErr={categoryNameErr}
          submitHandler={submitHandler}
          btn={btn}
        />
      )}
      {secondDet && <AddVendorDetails1 />}
      {!secondDet && finalView && <AddVendorFinal />}
      <div className="w-100 ac-jb flex-md-row flex-column d-flex">
        <button
          onClick={() => {
            if (secondDet === true) {
              setSecondDet(false);
            } else if (finalView === true) {
              setFinalView(false);
              setSecondDet(true);
            } else {
              navigate("/vendor-home");
            }
          }}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        {!finalView && (
          <button
            onClick={() => {
              if (secondDet === false) {
                setSecondDet(true);
              } else if (secondDet === true) {
                setSecondDet(false);
                setFinalView(true);
              }
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Next
          </button>
        )}
        {finalView && (
          <button
            onClick={() => setPopup(true)}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default AddVendor;
