import { animatedImg } from "../assets/img";
import { Search } from "@mui/icons-material";
import { selecLibList } from "../redux/api/DummyJson";
import { useLocation, useNavigate } from "react-router-dom";

const ViewLibraryList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} className="searchManImg" />
      </div>
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        {location?.state?.name} Library List
      </p>
      <div className="d-flex flex-wrap">
        {selecLibList?.map((item) => {
          return (
            <div
              className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
            // onClick={() => navigate('/add-new-product/products/product-detail')}
            >
              <div className="prod-list-box">
                <div className="cust-btn d-flex ac-jc rounded-3">
                  <img
                    src={item?.img}
                    alt=""
                    className="custom-w-h rounded-3"
                  />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  {item?.name}
                </p>
                {/* <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  {item?.description}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  {item?.price}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                  {item?.data}
                </p> */}
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        {location?.state?.type === "edit" && (
          <button className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Update Details
          </button>
        )}
      </div>
    </div>
  );
};

export default ViewLibraryList;
