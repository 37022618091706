import React, { useState } from "react";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import CommentIcon from "@mui/icons-material/Comment";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteIcon from "@mui/icons-material/Delete";

const OverView = () => {
  const [addNote, setAddNote] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [commandshow, setCommandshow] = useState(false);

  const onClickCommandShowHandler = () => {
    setCommandshow(!commandshow);
  };

  return (
    <div>
      {!addNote && !addTask && (
        <div className="d-flex ac-jc w-100">
          <button
            onClick={() => {
              setAddNote(true);
            }}
            className="cust-btn rounded primary px-4 py-3 bg-lt-blue1 ac-jc mx-3 w-50 mt-3"
          >
            <PlaylistAddIcon className="f5 fs-23 fs-md-26 fs-xl-28" />
            <h5 className="f4 fs-14 fs-md-15 fs-xl-16">Add Note</h5>
          </button>
          <button
            onClick={() => {
              setAddTask(true);
            }}
            className="cust-btn rounded primary px-4 py-3 bg-lt-blue1 ac-jc mx-3 w-50 mt-3"
          >
            <AddToPhotosIcon className="f5 fs-23 fs-md-26 fs-xl-28" />
            <h5 className="f4 fs-14 fs-md-15 fs-xl-16">Add Task</h5>
          </button>
        </div>
      )}
      {addNote && (
        <div className="w-100 rounded p-3 bg-lt-blue2">
          <input
            type="text"
            className="w-100 mb-3 px-3 py-2 rounded border-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-17"
            placeholder="Note"
          />
          <div className="d-flex ac">
            <button className="bg-sec cust-btn px-3 text-white rounded f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 me-2">
              Select Files
            </button>
            <button className="bg-primar addbtn cust-btn px-2 text-white rounded f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mx-2">
              Create Note
            </button>
          </div>
        </div>
      )}
      {addTask && (
        <div className="w-100 rounded p-3 bg-lt-blue2">
          <input
            type="text"
            className="w-100 mb-3 px-3 py-2 rounded border-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-17"
            placeholder="Task"
          />
          <div className="d-flex ac">
            <div className="w-50">
              <div className="p-2">
                <p className="w-100 mb-1 primary rounded border-1 f2 fs-13 fs-xs-14 fs-sm-15 fs-md-16">
                  Task For
                </p>
                <select
                  name=""
                  className="w-100 mb-1 px-3 py-2 rounded border-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-17"
                  id=""
                >
                  <option value="Rubesh">Rubesh</option>
                  <option value="John Paul">John Paul</option>
                </select>
              </div>
            </div>
            <div className="w-50">
              <div className="p-2">
                <p className="w-100 mb-1 primary rounded border-1 f2 fs-13 fs-xs-14 fs-sm-15 fs-md-16">
                  Task Date
                </p>
                <input
                  type="date"
                  className="w-100 mb-1 px-3 py-2 rounded border-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-17"
                />
              </div>
            </div>
          </div>
          <button className="bg-primar addbtn cust-btn px-2 text-white rounded f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 mx-2">
            Create Task
          </button>
        </div>
      )}

      <h4 className="f3 fs-14 fs-md-16 fs-lg-18 mt-4 mb-2">Notes</h4>
      <p className="f1 fs-12 fs-md-14 fs-lg-15 mb-1">
        PO#330006 flagged as Submitted.
      </p>
      <div className="d-flex as-jb">
        <p className="f1 fs-12 fs-md-14 fs-lg-15 primary">
          by Roger Daniel on 2023-10-31
        </p>
        <div className="d-flex align-items-center">
          <button
            onClick={() => onClickCommandShowHandler()}
            className="cust-btn px-1"
          >
            <CommentIcon className="fs-13 fs-md-14 fs-lg-15 primary" />
          </button>
          <button className="cust-btn px-1">
            <PushPinIcon className="fs-13 fs-md-14 fs-lg-15 primary" />
          </button>
          <button className="cust-btn px-1">
            <DeleteIcon className="fs-13 fs-md-14 fs-lg-15 primary1" />
          </button>
        </div>
      </div>
      {commandshow && (
        <div className="bg-lt-blue1 p-2 rounded command-area mt-2">
          <textarea
            name=""
            id=""
            cols="30"
            placeholder="Your comment"
            rows="2"
            className="w-100 p-2 rounded"
          ></textarea>

          <button className="bg-primar addbtn cust-btn px-2 text-white rounded f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 my-1">
            Comment
          </button>
        </div>
      )}
    </div>
  );
};
export default OverView;
