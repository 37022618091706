import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductListComp from "../ProductComp/ProductListComp";
import MapProductComp from "../ProductComp/MapProductComp";
import UploadIcon from "@mui/icons-material/Upload";
import RequestListComp from "../ProductComp/RequestListComp";
import MyProductListComp from "../ProductComp/MyProductListComp";
import GetAppIcon from "@mui/icons-material/GetApp";
import DownloadIcon from "@mui/icons-material/Download";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import NewProductSuggestion from "./NewProductSuggestion";
import UploadImages from "../Popup/UploadImages";

const ProductDetails = ({ products, place, path }) => {
  const navigate = useNavigate();

  const [product, setProduct] = useState(false);
  const [decorative, setDecorative] = useState(false);
  const [myProducts, setMyProducts] = useState(true);
  const [newProductSuggestion, setNewProductSuggestion] = useState(false);
  const [productList, setParoductList] = useState("");
  const [uploadPopUp, setUploadPopUp] = useState(false);
  const location = useLocation();

  const tab = location?.state?.tab;
  useEffect(() => {
    if (tab === "myproduct") {
      setMyProducts(true);
      setDecorative(false);
      setProduct(false);
    } else if (tab === "mapproduct") {
      setMyProducts(false);
      setDecorative(false);
      setProduct(true);
    } else if (tab === "requestproduct") {
      setMyProducts(false);
      setDecorative(true);
      setProduct(false);
    }
  }, []);

  //   popUp upload function
  const handleUploadHandler = (e) => {
    // console.log(e, "e");
    setUploadPopUp(!uploadPopUp);
  };
  const handleClose = (e) => {
    setUploadPopUp(!uploadPopUp);
  };
  return (
    <div className="w-100">
      <div className="d-flex ac-je">
        <div>
          {product && (
            <button
              onClick={() => navigate("/map-product")}
              className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Map Product
            </button>
          )}
          {myProducts && (
            <button
              onClick={() => navigate("/map-product")}
              className="invisible cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Map Product
            </button>
          )}
          {/* <DownloadIcon /> */}

          {decorative && (
            <button
              onClick={() => navigate("/create-new-request")}
              className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Create New Request
            </button>
          )}
          {newProductSuggestion && (
            <button
              class="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded w-100"
              onClick={() => {
                setUploadPopUp(!uploadPopUp);
              }}
            >
              Upload Images
            </button>
          )}
        </div>
      </div>
      <div className="d-flex mt-5 ac-jb flex-wrap">
        <div className="d-flex mt-3 rk">
          <button
            onClick={() => {
              setMyProducts(true);
              setDecorative(false);
              setProduct(false);
              setDecorative(false);
              setParoductList("product");
              setNewProductSuggestion(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              myProducts
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                myProducts ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Approved Products
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(true);
              setDecorative(false);
              setParoductList("product");
              setMyProducts(false);
              setNewProductSuggestion(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              product
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                product ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Map Products
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(false);
              setDecorative(true);
              setMyProducts(false);
              setParoductList("decorative");
              setNewProductSuggestion(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              decorative
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                decorative ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Add New Products
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(false);
              setDecorative(false);
              setMyProducts(false);
              setNewProductSuggestion(true);
              setParoductList("new_suggestion");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              newProductSuggestion
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                newProductSuggestion ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              New Products Suggestions
            </p>
          </button>
        </div>

        <div>
          {!myProducts && !newProductSuggestion && (
            <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Import
            </button>
          )}
          {!myProducts && !newProductSuggestion && (
            <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Export
            </button>
          )}
          {!myProducts && !newProductSuggestion && (
            <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <ListAltRoundedIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Sample Download
            </button>
          )}
        </div>
        {/* <button className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => navigate("/products/add-new-product", {
              state: { type: "Charles", place: place },
            })}>
            + Add Products
          </button> */}
      </div>
      {myProducts && <MyProductListComp products={products} place={place} />}
      {product && <MapProductComp products={products} place={place} />}
      {decorative && <RequestListComp products={products} place={place} />}
      {newProductSuggestion && (
        <NewProductSuggestion product={product} place={place} />
      )}
      {/* {Path?.pathname === "/vendor-details" && place === 'Charles' && <ProductListComp loc={loc} />} */}
      {uploadPopUp && (
        <UploadImages
          onUploadHandler={handleUploadHandler}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default ProductDetails;
