import React, { useState } from "react";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { searchIcon } from "../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { event_category } from "../redux/api/DummyJson";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const PendingDetailView = () => {
  const [activeTab, setActiveTab] = useState("Inprocess");

  const navigate = useNavigate();
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="OT d-flex align-items-center  justify-content-start">
        <p
          className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary"
          role={"button"}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon />
        </p>
        <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary ms-3">
          Order Tracking
        </p>
      </div>
      <div className=" w-100 justify-content-between align-items-center  ms-lg-3">
        <div className="d-flex align-items-center mt-2">
          <p className=" col-lg-2 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 f3 ">
            Order ID
          </p>
          <div className="col-lg-2 mt-md-0 mt-3 border-search">
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="68501"
            />
          </div>
        </div>

        <div className="d-flex align-items-center mt-4 ">
          <p className="col-lg-2 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  primary1 f3 ">
            Order Place Date
          </p>
          <div className=" col-lg-2 mt-md-0 mt-3 border-search">
            <input
              className=" cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="04 Oct 2023"
            />
          </div>
        </div>
      </div>
      <div className="d-flex mt-4 ms-lg-3">
        <p className="col-lg-2 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  primary1 f3 ">
          Tracking ID
        </p>
        <p className="col-lg-2 f2 fs-xs-11 fs-sm-12  text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16   f3 ">
          175846981
        </p>
      </div>
      <div className="d-flex mt-4 ms-lg-3">
        <p className="col-lg-2 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  primary1 f3 ">
          Status
        </p>
        <p className="col-lg-2 f2 fs-xs-11 fs-sm-12 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 green1  f3 ">
          Delivered on Oct 05, 2023
        </p>
      </div>
      <div className="track mt-4">
        <div className="">
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2">
            Tracking History
          </p>
        </div>
      </div>
      <div className="date-time col-lg-4 mt-4">
        <div className="d-flex ">
          <div className="col-lg-4">
            <p class="f4 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 ">
              05 Oct
            </p>
            <p class="f1 fs-sm-8 fs-md-10 fs-lg-12 fs-xl-14 fs-xxl-16 ">
              01:30 PM
            </p>
          </div>
          <div className="icon jc-ac col-lg-4">
            <RadioButtonCheckedIcon className="ms-2 green1" />
            <div className="ps-3">
              <p>|</p>
              <p>|</p>
              <p>|</p>
            </div>
          </div>
          <div className="activity d-flex jc-ac flex-wrap w-100 col-lg-4">
            <p className=" f4 fs-xs-11 fs-sm-12 pe-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              Activity :
            </p>
            <p className=" f2 dark-gray fs-xs-11 fs-sm-12  text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16   f3 ">
              Delivered
            </p>
          </div>
        </div>
        <div className="d-flex ">
          <div className="col-lg-4">
            <p class="f4 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 ">
              05 Oct
            </p>
            <p class="f1 fs-sm-8 fs-md-10 fs-lg-12 fs-xl-14 fs-xxl-16 ">
              01:50 PM
            </p>
          </div>
          <div className="icon jc-ac col-lg-4">
            <RadioButtonCheckedIcon className="ms-2 " />
            <div className="ps-3">
              <p>|</p>
              <p>|</p>
              <p>|</p>
            </div>
          </div>
          <div className="activity d-flex jc-ac flex-wrap w-100 col-lg-4">
            <p className=" f4 fs-xs-11 fs-sm-12 pe-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              Activity :
            </p>
            <p className=" f2 dark-gray fs-xs-11 fs-sm-12  text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16   f3 ">
              Out for Delivery
            </p>
          </div>
        </div>
        <div className="d-flex ">
          <div className="col-lg-4">
            <p class="f4 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 ">
              05 Oct
            </p>
            <p class="f1 fs-sm-8 fs-md-10 fs-lg-12 fs-xl-14 fs-xxl-16 ">
              12:30 PM
            </p>
          </div>
          <div className="icon jc-ac col-lg-4">
            <RadioButtonCheckedIcon className="ms-2" />
            <div className="ps-3">
              <p>|</p>
              <p>|</p>
              <p>|</p>
            </div>
          </div>
          <div className="activity d-flex jc-ac flex-wrap w-100 col-lg-4">
            <p className=" f4 fs-xs-11 fs-sm-12 pe-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              Activity :
            </p>
            <p className=" f2 dark-gray fs-xs-11 fs-sm-12  text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16   f3 ">
              Reached at Destination
            </p>
          </div>
        </div>
        <div className="d-flex ">
          <div className="col-lg-4">
            <p class="f4 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 ">
              04 Oct
            </p>
            <p class="f1 fs-sm-8 fs-md-10 fs-lg-12 fs-xl-14 fs-xxl-16 ">
              09:45 PM
            </p>
          </div>
          <div className="icon jc-ac col-lg-4">
            <RadioButtonCheckedIcon className="ms-2" />
            <div className="ps-3">
              <p>|</p>
              <p>|</p>
              <p>|</p>
            </div>
          </div>
          <div className="activity d-flex jc-ac flex-wrap w-100 col-lg-4">
            <p className=" f4 fs-xs-11 fs-sm-12 pe-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              Activity :
            </p>
            <p className=" f2 dark-gray fs-xs-11 fs-sm-12  text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16   f3 ">
              In Transit
            </p>
          </div>
        </div>
        <div className="d-flex ">
          <div className="col-lg-4">
            <p class="f4 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 ">
              04 Oct
            </p>
            <p class="f1 fs-sm-8 fs-md-10 fs-lg-12 fs-xl-14 fs-xxl-16 ">
              09:30 AM
            </p>
          </div>
          <div className="icon jc-ac col-lg-4">
            <RadioButtonCheckedIcon className="ms-2 " />
            <div className="ps-3">
              <p>|</p>
              <p>|</p>
              <p>|</p>
            </div>
          </div>
          <div className="activity d-flex jc-ac flex-wrap w-100 col-lg-4">
            <p className=" f4 fs-xs-11 fs-sm-12 pe-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              Activity :
            </p>
            <p className=" f2 dark-gray fs-xs-11 fs-sm-12  text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16   f3 ">
              Pickup Assigned
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-lg-4">
            <p class="f4 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 ">
              03 Oct
            </p>
            <p class="f1 fs-sm-8 fs-md-10 fs-lg-12 fs-xl-14 fs-xxl-16 ">
              02:30 PM
            </p>
          </div>
          <div className="icon jc-ac col-lg-4">
            <RadioButtonCheckedIcon className="ms-2" />
            <div className="ps-3">
              <p>|</p>
              <p>|</p>
              <p>|</p>
            </div>
          </div>
          <div className="activity d-flex jc-ac flex-wrap w-100 col-lg-4">
            <p className=" f4 fs-xs-11 fs-sm-12 pe-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              Activity :
            </p>
            <p className=" f2 dark-gray fs-xs-11 fs-sm-12  text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16   f3 ">
              Softdata Updated
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-lg-4">
            <p class="f4 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 ">
              03 Oct
            </p>
            <p class="f1 fs-sm-8 fs-md-10 fs-lg-12 fs-xl-14 fs-xxl-16">
              02:15 PM
            </p>
          </div>
          <div className="icon jc-ac col-lg-4">
            <RadioButtonCheckedIcon className="ms-2" />
            <div className="ps-3">
              <p>|</p>
              <p>|</p>
              <p>|</p>
            </div>
          </div>
          <div className="activity d-flex jc-ac flex-wrap w-100 col-lg-4">
            <p className=" f4 fs-xs-11 fs-sm-12 pe-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              Activity :
            </p>
            <p className=" f2 dark-gray fs-xs-11 fs-sm-12  text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16   f3 ">
              Pickup Scheduled
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-lg-4">
            <p class="f4 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 ">
              03 Oct
            </p>
            <p class="f1 fs-sm-8 fs-md-10 fs-lg-12 fs-xl-14 fs-xxl-16 ">
              01:30 PM
            </p>
          </div>
          <div className="icon jc-ac col-lg-4">
            <RadioButtonCheckedIcon className="ms-2" />
          </div>
          <div className="activity d-flex jc-ac flex-wrap w-100 col-lg-4">
            <p className=" f4 fs-xs-11 fs-sm-12 pe-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              Activity :
            </p>
            <p className=" f2 dark-gray fs-xs-11 fs-sm-12  text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16   f3 ">
              Pickup Awaited
            </p>
          </div>
        </div>
      </div>
      <div className="btn-w-cust-cont mt-5 w-100">
        <button
          class="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default PendingDetailView;
