import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const PorejectPoppup = ({ rejPopFun, setRejectPoppup }) => {
  return (
    <div className="modal-popup">
      <div onClick={rejPopFun} className="back-close" />
      <div className="center-content-reject p-4 gap-3">
        <div className="w-100 d-flex ac-jb">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Reason For Rejection
          </p>
          <CloseIcon
            onClick={() => setRejectPoppup(false)}
            className="pointerView white p-1 rounded-5 f2 bg-primar fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-25"
          />
        </div>
        <div className="d-flex flex-column ac-jc w-xl-100 w-lg-100 w-md-100 w-sm-100 w-xs-100 mt-3 mt-md-5">
          <textarea
            style={{ resize: "none" }}
            className="datepicker-input texta w-80 viewBoxDes ms-2 p-2 bg-white rounded-3 border-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start text-dark f3 me-2"
            placeholder="Text Here..."
          />
          <button
            onClick={() => setRejectPoppup(false)}
            class="cust-btn addbtn mt-5  bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PorejectPoppup;
