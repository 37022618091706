import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const AddProjectPopup = ({ toggleShowPopup, popupType }) => {
  return (
    <div className="add-category-popup">
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          {popupType == "currency"
            ? "Change Project Currency"
            : "Create Project"}
          <span>
            <button onClick={toggleShowPopup} className="cust-btn">
              <HighlightOffIcon />
            </button>
          </span>
        </p>

        {popupType == "currency" ? (
          <div>
            <p className="mt-3">
              Note that the currency on items will not be updated and this may
              affect margin calculations.
            </p>
            <select className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-3 mb-2">
              <option>INR</option>
              <option>AED</option>
              <option>DOLLER</option>
            </select>
          </div>
        ) : (
          <div className="d-flex ac-jc add-content">
            <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
              <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                Order Rep
              </p>
              <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                <select
                  placeholder=""
                  className="w-80  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                  name=""
                >
                  <option selected hidden value="">
                    Select Order Rep
                  </option>
                  <option value="OR487637">OR487637</option>
                  <option value="OR382764">OR382764</option>
                </select>
              </div>
            </div>
            <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
              <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                Client Rep
              </p>
              <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                <select
                  placeholder=""
                  className=" w-80 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                  name=""
                >
                  <option selected hidden value="">
                    Select Client Rep
                  </option>
                  <option value="CR837467">CR837467</option>
                  <option value="CR873278">CR873278</option>
                </select>
              </div>
            </div>
          </div>
        )}

        <div className="d-flex mt-3 justify-content-end m-2">
          <button
            onClick={toggleShowPopup}
            className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProjectPopup;
