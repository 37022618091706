import React, { useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TuneIcon from "@mui/icons-material/Tune";
import { Search } from "@mui/icons-material";
import { searchIcon } from "../assets/img";
import { adminData, categoryData, pre_artwork } from "../redux/api/DummyJson";
import { useNavigate } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import {
  useLazyCategoriesQuery,
  useLazyCategoryStatusQuery,
} from "../redux/api/api";
import GetAppIcon from "@mui/icons-material/GetApp";

const PreProduction = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  // RTK QUERY

  const [listCategory] = useLazyCategoriesQuery();
  const [status] = useLazyCategoryStatusQuery();

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const change = (id) => {
    status(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="dashRightView p-5 home_section trans overflow-scroll">
      <div className="d-flex ac-jb mt-2 flex-wrap">
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          Pre Production
        </p>
        <div className="w-100 ac-jb  mt-4">
          <div className="d-flex d-flex flex-md-row flex-column gap-2">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select className="cust-btn d-inline f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
          </div>
          <div className="d-flex ac-jb flex-md-row flex-column">
            <div className="d-flex gap-2 flex-md-row flex-column">
              <div className="d-flex bg-lt-blue align-content-center border-0 rounded-1 p-2 mt-lg-4 mt-md-1">
                <TuneIcon />
                <button class="bg-transparent border-0">
                  <select className="bg-transparent border-0  grey">
                    <option value="0"> Select Client Status</option>

                    <option value="10">Inprocess</option>
                    <option value="11">Approved</option>
                  </select>
                </button>
              </div>
              <div className="d-flex bg-lt-blue align-content-center border-0 rounded-1 p-2 mt-lg-4 mt-md-1">
                <TuneIcon />
                <button class="bg-transparent border-0">
                  <select className="bg-transparent border-0  grey">
                    <option value="0"> Select Sales Status</option>
                    <option value="10">Inprocess</option>
                    <option value="11">Approved</option>
                  </select>
                </button>
              </div>
            </div>
            <div className="d-flex">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 mt-4 px-3">
                Total Count : {pre_artwork?.length}
              </p>
              <div className="d-flex mt-sm-3 border-search mx-1 mt-md-1">
                <img src={searchIcon} alt="search" className="searchiConImg" />
                <input
                  className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-100  mt-5"> */}
      {/* <div className=" w-100"> */}
      {/* tab */}
      <div className="presentaion_pages overflow-scroll mt-4 w-100">
        <div className="w-100 cust_table overflow-scroll table_border menu-table">
          <table>
            <thead className="py-3">
              <tr className="bg-soft-gray1">
                <th className="text-nowrap">S.No</th>
                <th>Project ID</th>
                <th>Project Name</th>
                <th>Customer ID</th>
                <th>SKU Code</th>
                <th>Product Name</th>
                <th>Estimated Quantity</th>
                <th>Sales Status</th>
                <th>Client Status</th>
                <th>Release Date</th>
              </tr>
            </thead>
            <tbody className="my-4">
              {pre_artwork?.map((item, index) => {
                return (
                  <tr className="position-relative">
                    <td>
                      <p className="pointerView text-nowrap text-center f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc p-4 primary1">
                        {index + 1}
                      </p>
                    </td>
                    <td>
                      <p className="text-nowrap text-center f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center p-4 primary1 f3">
                        {item?.Project_ID}
                      </p>
                    </td>
                    <td>
                      <p className="text-nowrap text-center f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center p-4 primary1 f3">
                        {item?.Project_Name}
                        {/* TCS */}
                      </p>
                    </td>
                    <td>
                      <p className="text-nowrap text-center f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center p-4 primary1 f3">
                        {item?.Customer_ID}
                        {/* Char-05 */}
                      </p>
                    </td>
                    <td>
                      <p className="text-nowrap text-center f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center p-4 primary1 f3">
                        {item?.SKU_Code}
                        {/* SKU005 */}
                      </p>
                    </td>
                    <td>
                      {" "}
                      <p className="text-nowrap text-center f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center  primary1 f3">
                        {item?.Product_Name}
                        {/* Hoodie */}
                      </p>
                    </td>
                    <td>
                      {" "}
                      <p className="text-nowrap text-center f2 bg-lt-blue border-0 rounded-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center px-2 py-1  d-inline-block primary1 f3">
                        {item?.Estimated_Quantity}
                        {/* 500 */}
                      </p>
                    </td>
                    <td>
                      <p className="text-nowrap text-center f2 bg-lt-blue border-0 rounded-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-1 px-2 text-center d-inline-block primary1 f3">
                        {item?.Sales_Status}
                        {/* Inprocess */}
                      </p>
                    </td>
                    <td>
                      <p className="text-nowrap text-center f2 bg-lt-blue border-0 rounded-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-1 px-2 text-center d-inline-block primary1 f3">
                        {item?.Client_Status}
                        {/* Inprocess */}
                      </p>
                    </td>
                    <td>
                      {" "}
                      <p className="text-nowrap text-center f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-4 text-center primary1 f3">
                        {item?.Release_Date} <CalendarMonthIcon />
                        {/* 05-05-2024 <CalendarMonthIcon /> */}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* tab1 */}
      {/* </div> */}
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default PreProduction;
