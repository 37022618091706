// export const BASE_URL = "http://192.168.1.7/new_laravel_mhq/public/crm/"; // local mukesh
// export const BASE_URL = "http://192.168.1.43/merchhq/public/crm/"; // local pushpa
// export const BASE_URL = "https://newmerchhq.premiumfreshfoods.com/crmapi/"; // live
export const BASE_URL = "https://test-backend-laravel.merchhq.io/crm/"; // develop

export const URL = {
  LOGIN: "admin/login",
  LOGOUT: "admin-logout",
  CHANGE_PASSWORD: "change-password",
  TOKEN_VERIFY: "admin-token-verify/",
  SET_PASSWORD: "admin-set-password",
  FORGOT_PASSWORD: "admin/forgot-password",

  // ROLE
  ROLE: "role",
  ROLES: "admin/role",
  ACTIVE_ROLES: "active-roles",
  DEPARTMENT_ACTIVE_ROLE: "admin/role/active-list",
  DEPARTMENT_ROLE: "/department-role-lists/",

  // ADMIN
  ADMIN: "admin",

  // CATEGORY
  CATEGORY: "parent-categories",
  // CATEGORY: "category-list",
  CATEGORY_LIST: "category/lists",
  CATEGORY_VIEW: "category-view",
  CATEGORY_ADD: "category-add",
  CATEGORY_UPDATE: "category-update",
  CATEGORY_STATUS: "category-status",

  // MASTER
  MASTER: "master",
  MASTER_LIST: "admin/master/list",
  MASTER_ACTIVE_LIST: "admin/master/active-list",

  // VENDOR
  VENDOR_PARENT_CATEGORIES: "parent-categories",
  VENDOR_REGISTER: "vendor-register",

  // TAGS
  TAGS: "tags",
  TAG: "tag",
  TAGS_STATUS: "tag-status/",
  TAGS_LIST: "admin/tag/list",

  // NAVIGATION
  NAVIGATION: "navigation",
  NAVIGATIONS: "navigations",

  // HSN
  HSNS: "admin/hsn/list",
  HSN: "hsn",

  // EXPORTS
  ADMIN_EXPORTS: "admin/exports",

  // CURRENCY
  CURRENCYS: "admin/currency/list",
  CURRENCY_ACTIVE: "active/currency",
  CURRENCY: "currency",

  // COLORS
  COLORS: "admin/colors/list",
  COLOR: "colors",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const productDetailImg = {
  loop: true,
  autoplay: false,
  center: true,
  dots: true,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};
