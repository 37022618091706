import React, { useEffect, useState } from "react";
import { product, uploadIcon } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import { productDetailImg } from "../../redux/api/constants";
import ImageCroper from "../Popup/ImageCroper";

const ImageUplodeComp = ({ vendor, vendor2, vendor3 }) => {
  const navigate = useNavigate();
  console.log(vendor);
  const [logo, setLogo] = useState({});
  const [textShow, setTextShow] = useState(true);
  // const [textShow2, setTextShow2] = useState(false)
  const [file, setFile] = useState("");
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  // const [textShow2, setTextShow2] = useState(false)
  const [fistShow, setFirstShow] = useState(false);
  const [croperCol, setCroperCol] = useState(false);
  const [smallimage, setSmallImage] = useState([]);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });
  const imageUplode = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    console.log(file, "windfine");
    // setTextShow(false)
  };
  console.log(vendor2);

  const imgclick = (e) => {
    setSmallImage(URL.createObjectURL(e.target.files[0]));
    console.log(smallimage, "windfine");
    setTextShow(false);
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const dualToggele = (type) => {
    if (type == "color") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setTextShow(false);
      setFirstShow(true);

      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallImage(temp);

      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    } else if (!croperCol && croperImage) {
      imageUplode(croperImage);
    }
  }, [croperImage]);

  const imageSelector = (item, ind) => {
    setBigImage({ image: item, ind: ind });
  };

  const removeImg = (ind) => {
    let temp = [...smallimage];

    temp.splice(ind, 1);
    setSmallImage(temp);
    if (temp?.length - 1 === 0) {
      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
    } else {
      setBigImage({ image: temp[ind - 1], ind: ind - 1 });
    }
  };
  return (
    <div className="d-flex gap-3 ac-jc w-100">
      <div>
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
          Product Image
        </p>
        <div>
          <label
            className="img_up_cont d-flex ac-jc cp"
            onClick={() => !fistShow && dualToggele("product")}
          >
            <div className="d-flex ac-jc flex-column">
              <div className="position-relative">
                <img
                  className={
                    bigImage?.image
                      ? "img_up_cont object-fit-contain "
                      : "placeholder_icon object-fit-contain"
                  }
                  src={bigImage?.image ? bigImage?.image : uploadIcon}
                />
                {!textShow && (
                  <div>
                    <ul
                      className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                    >
                      <li className="set-default-image">Set&nbsp;Default</li>
                      {smallimage?.length > 1 && (
                        <li
                          className="set-default-image"
                          onClick={() => removeImg(bigImage?.ind)}
                        >
                          Remove
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              {textShow ? (
                <p className="f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 primary">
                  {" "}
                  Click here to upload
                </p>
              ) : null}
            </div>
          </label>
          <div className="d-flex ac-jc gap-3 w-100">
            {fistShow && (
              <div
                class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                style={{ width: "300px", overflow: "scroll" }}
              >
                {smallimage?.map((item, ind) => {
                  return (
                    <div className="gap-3 d-flex ac-j w-100">
                      <div
                        class="add_img d-flex ac-jc"
                        onClick={() => imageSelector(item, ind)}
                      >
                        {" "}
                        <img src={item} class="cp add_img object-fit-fill" />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {smallimage?.length > 0 && (
              <label
                className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                  smallimage?.length == 0 && "mt-3"
                }`}
                onClick={() => dualToggele("product")}
              >
                +
              </label>
            )}
          </div>
        </div>
        {/* <div className='btn-w-cust-cont mt-4'>
                    <button className="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded w-100"
                        onClick={() =>
                            navigate('/request-details-list')}
                    >Save</button>
                </div> */}
      </div>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
    </div>
  );
};

export default ImageUplodeComp;
