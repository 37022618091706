import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { animatedImg, emptyImg, searchIcon } from "../assets/img";
import { Search } from "@mui/icons-material";
import ProductVendor from "../components/Vendor/ProductVendor";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import NoData from "../components/Vendor/NoData";
import ServiceVendor from "../components/Vendor/ServiceVendor";
import UploadIcon from "@mui/icons-material/Upload";
import ImportListPopup from "../components/Popup/ImportListPopup";
import BothVendor from "../components/Vendor/BothVendor";

const VendorManageScreen = () => {
  const [prodVendor, setProdVendor] = useState(false);
  const [serVendor, setSerVendor] = useState(false);
  const [serBothVendor, setSerBothVendor] = useState(false);
  const [importList, setImportList] = useState(false);
  const [both, setBoth] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  console.log("LOC____", location?.state?.type);
  useEffect(() => {
    if (location?.state?.type === "service") {
      setSerVendor(true);
    } else {
      setProdVendor(true);
    }
  }, []);

  const showPopupHander = () => {
    setImportList(false);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {importList && (
        <ImportListPopup
          showPopupHander={showPopupHander}
          setImportList={setImportList}
        />
      )}
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} alt="img" className="searchManImg" />
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Vendor Management
        </p>
        <div className="d-flex mt-sm-3 border-search mx-1">
          <img src={searchIcon} alt="search" className="searchiConImg" />
          <input
            className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="d-flex mt-4 ac-jb flex-wrap">
        <div className="d-flex mt-3">
          <div
            onClick={() => {
              setProdVendor(true);
              setSerVendor(false);
              setBoth(false);
              setSerBothVendor(false);
            }}
            className={`${
              prodVendor
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } `}
          >
            <p
              className={`${
                prodVendor ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              style={{ cursor: "pointer" }}
            >
              Product Supplier
            </p>
          </div>
          <div
            onClick={() => {
              setSerVendor(true);
              setProdVendor(false);
              setSerBothVendor(false);
              setBoth(false);
            }}
            className={`${
              serVendor
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                serVendor ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              style={{ cursor: "pointer" }}
            >
              Decorator
            </p>
          </div>
          <div
            onClick={() => {
              setProdVendor(false);
              setSerVendor(false);
              setBoth(true);
              setSerBothVendor(true);
            }}
            className={`${
              serBothVendor
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                serBothVendor ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              style={{ cursor: "pointer" }}
            >
              Both
            </p>
          </div>
        </div>
        <div className="d-flex w-xs-100 ac-jc ms-2 flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row flex-column">
          <div
            onClick={() => setImportList(!importList)}
            className="pointerView d-flex mt-4 me-3"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Import
            </p>
          </div>
          <div className="pointerView d-flex mt-4 me-3">
            <ListAltRoundedIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Download List
            </p>
          </div>
          <div>
            <button
              onClick={() => navigate("/add-vendor")}
              className="pointerView cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              + Add Vendor
            </button>
          </div>
        </div>
      </div>
      {prodVendor && <ProductVendor />}
      {serVendor && <ServiceVendor />}
      {both && <BothVendor />}
    </div>
  );
};

export default VendorManageScreen;
