import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  sub_menu,
  Product_Name,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  catogory_setting,
  counting,
  colors,
} from "../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import ImageUplodeComp from "../components/MapProductComp/ImageUplodeComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  DeleteForever,
} from "@mui/icons-material";
import PricingBoxComp from "../components/MyProductListComp/PricingBoxComp";
import ImageCroper from "../components/Popup/ImageCroper";
import { emptyImg, product, profilePic, uploadIcon } from "../assets/img";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const MapProduct = () => {
  const navigate = useNavigate();

  // Toggle List
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(false);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);
  const [hsnTypeShow, setHsnTypeShow] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [Dropdown, setDrop] = useState(0);
  const [colorsShow, setColorsShow] = useState(false);
  const [check, setCheck] = useState(undefined);
  const [checkBox, setCheckBox] = useState(false);
  const [colorImg, setColorImg] = useState(false);

  // input box
  const [catogory, setCatogory] = useState("");
  const [catogory2, setCatogory2] = useState("");
  const [catogory3, setCatogory3] = useState("");
  const [productName, setProductName] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [packingSize, setPackingSize] = useState("");
  const [country, setCountry] = useState("");
  const [hsnType, sethsnType] = useState("");
  const [colorsName, setColorsName] = useState("");
  const [sizeOfDim, setSizeOfDim] = useState("");
  const [weight, setWeight] = useState("");
  const [designFeatures, setDesignFeatures] = useState("");
  const [meterialUse, setMeterialUse] = useState("");
  const [productDisc, setProductDisc] = useState("");
  const [decorativeMethod, setDecorativeMethod] = useState("");
  const [meterial, setMeterial] = useState("");
  const [style, setStyle] = useState("");
  const [subsku, setSubSku] = useState("");
  const [sku, setSku] = useState("");
  const [vendorProductCode, setVendorProductCode] = useState("");

  // Image
  const [logo, setLogo] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [index, setIndex] = useState(0);
  const [imageShow, setImageShow] = useState(false);

  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  // const [textShow2, setTextShow2] = useState(false)
  const [fistShow, setFirstShow] = useState(false);
  const [croperCol, setCroperCol] = useState(false);
  const [smallimage, setSmallImage] = useState([]);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });

  const [image, setImage] = useState([]);

  const [Indeximage, setIndexImage] = useState("");
  const [imageName, setImageName] = useState("defauld");
  const [defaultImage, setDefaultImage] = useState(profilePic);

  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 2) {
      setCatogoryShow(true);
      setProductNameShow(false);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };
  const onCatogory2 = (e) => {
    setCatogory2(e.target.value);
    if (catogory2.length >= 2) {
      setCatogoryShow2(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onCatogory3 = (e) => {
    setCatogory3(e.target.value);
    if (catogory3.length >= 2) {
      setCatogoryShow3(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onProduct = (e) => {
    // setProductName(e.target.value)
    if (productName.length >= 2) {
      setProductNameShow(true);
      // setProductSuplierCode('code1003');
    } else {
      if (productName.length >= 0) setProductNameShow(false);
      setProductSuplierCode("");
    }
    setCatogoryShow(false);
  };
  const onPackingSize = (e) => {
    setPackingSize(e.target.value);
    if (packingSize.length >= 2) {
      setPackingSizeShow(true);
    } else {
      if (packingSize.length >= 0) setPackingSizeShow(false);
    }
  };
  const onCountry = (e) => {
    setCountry(e.target.value);
    if (country.length >= 2) {
      setCountryShow(true);
    } else {
      if (country.length >= 0) setCountryShow(false);
    }
  };
  const onHsnTyoe = (e) => {
    sethsnType(e.target.value);
    if (hsnType.length >= 2) {
      setHsnTypeShow(true);
    } else {
      if (hsnType.length >= 0) setHsnTypeShow(false);
    }
  };
  const onColors = (e) => {
    setColorsName(e.target.value);
    if (colorsName.length >= 2) {
      setColorsShow(true);
    } else {
      if (colorsName.length >= 0) setColorsShow(false);
    }
  };

  useEffect(() => {
    if (colorsName.length !== 0) {
      setColorImg(true);
    }
  });

  const imageAdd = () => {};
  const removeimg = () => {
    const arr = [...image];
    const index = arr.indexOf();
    arr.splice(Indeximage);
    setImage([...arr]);
    console.log(arr);
  };
  // useEffect((ind) => {
  //     setIndexImage(ind)
  //     console.log(ind)
  // })

  const smallimg = (item) => {
    setSmallImage(item);
    // console.log(item)
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // Slide
  const [file, setFile] = useState([]);

  const imageUplode = (e) => {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
    console.log("file", file);
  };

  const deleteimg = (e) => {
    const s = file.filter((item, index) => item !== smallimage);
    setFile(s);
    console.log(s, index);
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const dualToggele = (type) => {
    if (type == "color") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setTextShow(false);
      setFirstShow(true);
      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallImage(temp);

      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    } else if (!croperCol && croperImage) {
      imageUplode(croperImage);
    }
  }, [croperImage]);

  const imageSelector = (item, ind) => {
    setBigImage({ image: item, ind: ind });
  };

  const removeImg = (ind) => {
    let temp = [...smallimage];

    temp.splice(ind, 1);
    setSmallImage(temp);
    if (temp?.length - 1 === 0) {
      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
    } else {
      setBigImage({ image: temp[ind - 1], ind: ind - 1 });
    }
  };

  return (
    <>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="dashRightView p-5 home_section transs">
        <ProductSearchHeader />
        <p
          className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 d-flex align-items-center gap-1"
          role={"button"}
          onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon /> Map Product
        </p>
        <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
          <div className="w-100">
            <div className="d-flex flex-wrap ac-jb flex-m-r">
              <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Category
                </p>
                <div className="position-relative z-3">
                  <input
                    type="text"
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={catogory}
                    onChange={onCatogory}
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setCatogoryShow(!catogoryShow);
                      setChildMenu(false);
                      setCatogoryShow2(false);
                      setCatogoryShow3(false);
                      setProductNameShow(false);
                      setPackingSizeShow(false);
                      setCountryShow(false);
                    }}>
                    <KeyboardArrowDownIcon />
                  </button>
                </div>
                {catogoryShow && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setCatogoryShow(!catogoryShow)}
                  />
                )}
                <div
                  className={`${
                    catogoryShow && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}>
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setCatogoryShow(false);
                            setCatogory(item?.list);
                            setChildMenu(false);
                            setSubcatogory(true);
                          }}>
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={childMenu ? "submenu_child_1" : "submenu_child_2"}>
                  <div
                    className={`${
                      catogoryShow && "submenu_2"
                    } submenu_cont_1 overflow-scroll z-3`}>
                    {sub_menu?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          {item.list !== "Game consoles" && (
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => {
                                setCatogoryShow(false);
                                setCatogory(item?.list);
                                setChildMenu(false);
                              }}>
                              {item?.list}
                            </button>
                          )}
                          {item.list === "Game consoles" && (
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => setChildMenu(!childMenu)}>
                              {item?.list}
                            </button>
                          )}
                          {item.list === "Game consoles" && (
                            <button
                              className="cust-btn position-relative"
                              onClick={() => {
                                setChildMenu(!childMenu);
                                setProductNameShow(false);
                                setCountryShow(false);
                                setPackingSizeShow(false);
                              }}>
                              {childMenu ? (
                                <KeyboardArrowRightIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {subcatogory && (
                <>
                  <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                      Sub Category 1
                    </p>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        value={catogory2}
                        onChange={onCatogory2}
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setCatogoryShow2(!catogoryShow2);
                          setCatogoryShow(false);
                          setCatogoryShow3(false);
                          setProductNameShow(false);
                          setPackingSizeShow(false);
                          setCountryShow(false);
                        }}>
                        <KeyboardArrowDownIcon />
                      </button>
                    </div>
                    {catogoryShow2 && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => setCatogoryShow2(!catogoryShow2)}
                      />
                    )}
                    <div
                      className={`${
                        catogoryShow2 && "submenu_1"
                      } submenu_cont_1 overflow-scroll z-3`}>
                      {sub_menu?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => {
                                setCatogoryShow2(false);
                                setCatogory2(item?.list);
                                setSubcatogory2(true);
                              }}>
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                      Sub Category Code
                    </p>
                    <input
                      type="text"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value={sku}
                      onChange={(e) => {
                        setSku(e.target.value);
                      }}
                      disabled
                    />
                  </div>
                </>
              )}
              {subcatogory2 && (
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Sub Category 2
                  </p>
                  <div className="position-relative">
                    <input
                      type="text"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      value={catogory3}
                      onChange={onCatogory3}
                    />
                    <button
                      className="drop_down cust-btn"
                      onClick={() => {
                        setCatogoryShow3(!catogoryShow3);
                        setChildMenu(false);
                        setCatogoryShow(false);
                        setCatogoryShow2(false);
                        setProductNameShow(false);
                        setPackingSizeShow(false);
                        setCountryShow(false);
                      }}>
                      <KeyboardArrowDownIcon />
                    </button>
                  </div>
                  {catogoryShow3 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setCatogoryShow3(!catogoryShow3)}
                    />
                  )}
                  <div
                    className={`${
                      catogoryShow3 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-3`}>
                    {sub_menu?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100"
                            onClick={() => {
                              setCatogoryShow3(false);
                              setCatogory3(item?.list);
                              setChildMenu(false);
                              setSubcatogory3(true);
                            }}>
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Product Name
                </p>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Required Field"
                    className="p-cust  cp editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={productName}
                    onChange={onProduct}
                    onClick={() => {
                      setProductNameShow(!productNameShow);
                      setCatogoryShow(false);
                      setCatogoryShow2(false);
                      setCatogoryShow3(false);
                      setPackingSizeShow(false);
                      setCountryShow(false);
                    }}
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setProductNameShow(!productNameShow);
                      // setProductSuplierCode('code1003');
                      setCatogoryShow(false);
                      setCatogoryShow2(false);
                      setCatogoryShow3(false);
                      setPackingSizeShow(false);
                      setCountryShow(false);
                    }}>
                    <KeyboardArrowDownIcon />
                  </button>
                </div>
                {productNameShow && (
                  <div
                    className="invisible-cont2"
                    onClick={() => setProductNameShow(!productNameShow)}
                  />
                )}
                <div
                  className={`${
                    productNameShow && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}>
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setProductNameShow(false);
                            setProductName(item?.list);
                            setProductSuplierCode("CODE1003");
                            setSizeOfDim("XL");
                            setWeight("100Kg");
                            setDesignFeatures("Dummy Content");
                            setMeterialUse("Dummy Content");
                            setCountry("India");
                            setPackingSize("150");
                            setColorsName("Red, blue, Purple");
                            sethsnType("200");
                            setDecorativeMethod(
                              "Game consoles, Game consoles, Game consoles, Game consoles, Game consoles, Game consoles"
                            );
                            setProductDisc(
                              "If you arent satisfied with the build tool and configuration choices, you can eject at any time.This command will remove the single build dependency from your project."
                            );
                            setMeterial("meterial cont");
                            setStyle("Gold");
                            setVendorProductCode("SM10001");
                            setSku("SKU0001");
                          }}>
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  SKU Code
                </p>
                <input
                  type="text"
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={sku}
                  onChange={(e) => {
                    setSku(e.target.value);
                  }}
                  disabled
                />
              </div>
              {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Product Supplier Code
                    </p>
                    <input
                        type='text'
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value={productSuplierCode}
                        onChange={(e) => { setProductSuplierCode(e.target.value) }}
                        disabled
                    />
                </div> */}
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Vendor Product Code
                </p>
                <input
                  type="text"
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={vendorProductCode}
                  // onChange={(e) => { setVendorProductCode(e.target.value) }}
                  disabled
                />
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Decoration Method
                </p>
                <textarea
                  type="text"
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={decorativeMethod}
                  // onChange={(e) => { setDecorativeMethod(e.target.value) }}
                  disabled
                />
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                  Country of Origin
                </p>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={country}
                    disabled
                    // onChange={onCountry}
                  />
                  {/* <button className='drop_down cust-btn' onClick={() => {
                            setCountryShow(!countryShow);
                            setCatogoryShow(false);
                            setCatogoryShow2(false);
                            setCatogoryShow3(false);
                            setProductNameShow(false);
                            setPackingSizeShow(false);
                        }}>
                            <KeyboardArrowDownIcon />
                        </button> */}
                </div>
                {countryShow && (
                  <div
                    className="invisible-cont2"
                    onClick={() => setCountryShow(!countryShow)}
                  />
                )}
                <div
                  className={`${
                    countryShow && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}>
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setCountryShow(false);
                            setCountry(item?.list);
                          }}>
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Product Packaging Size
                </p>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={packingSize}
                    disabled
                    // onChange={onPackingSize}
                  />
                  {/* <button className='drop_down cust-btn' onClick={() => {
                            setPackingSizeShow(!PackingSizeShow);
                            setCatogoryShow(false);
                            setCatogoryShow2(false);
                            setCatogoryShow3(false);
                            setProductNameShow(false);
                            setCountryShow(false);
                        }}>
                            <KeyboardArrowDownIcon />
                        </button> */}
                </div>
                {PackingSizeShow && (
                  <div
                    className="invisible-cont2"
                    onClick={() => setPackingSizeShow(!PackingSizeShow)}
                  />
                )}
                <div
                  className={`${
                    PackingSizeShow && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}>
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setPackingSizeShow(false);
                            setPackingSize(item?.list);
                          }}>
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  HSN Code
                </p>
                <div className="position-relative">
                  <input
                    type="text"
                    disabled
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={hsnType}
                    onChange={onHsnTyoe}
                  />
                  {/* <button
                            className="drop_down cust-btn"
                            onClick={() => {
                                setHsnTypeShow(!hsnTypeShow);
                                setCatogoryShow(false);
                                setCatogoryShow2(false);
                                setCatogoryShow3(false);
                                setProductNameShow(false);
                                setPackingSizeShow(false);
                                setCountryShow(false);
                            }}
                        >
                            <KeyboardArrowDownIcon />
                        </button> */}
                </div>
                {hsnTypeShow && (
                  <div
                    className="invisible-cont2"
                    onClick={() => setHsnTypeShow(!hsnTypeShow)}
                  />
                )}
                <div
                  className={`${
                    hsnTypeShow && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}>
                  {Hsn_type?.map((item, ind) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            Dropdown === ind
                              ? setChildMenu2(!childMenu2)
                              : setDrop(ind);
                          }}>
                          {item?.list}
                        </button>

                        {/* {ind === childM/enu3 && */}
                        <button
                          className="cust-btn position-relative"
                          onClick={() => {
                            Dropdown === ind
                              ? setChildMenu2(!childMenu2) && setDrop(ind)
                              : setDrop(ind);
                          }}>
                          {/* {childMenu2 && Dropdown === ind ? */}
                          <KeyboardArrowRightIcon />
                        </button>
                      </div>
                    );
                  })}
                </div>
                {Dropdown === 0 && (
                  <div
                    className={
                      childMenu2 ? "submenu_child_1" : "submenu_child_2"
                    }>
                    <div
                      className={`${
                        hsnTypeShow && "submenu_2 h-auto sub-2"
                      } submenu_cont_1 overflow-scroll z-3`}>
                      {Hsn_type_Child2?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => {
                                setHsnTypeShow(false);
                                sethsnType(item?.list);
                                setChildMenu2(false);
                              }}>
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {Dropdown === 1 && (
                  <div
                    className={
                      childMenu2 ? "submenu_child_1" : "submenu_child_2"
                    }>
                    <div
                      className={`${
                        hsnTypeShow && "submenu_2 h-auto sub-2"
                      } submenu_cont_1 overflow-scroll z-3`}>
                      {Hsn_type_Child3?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => {
                                setHsnTypeShow(false);
                                sethsnType(item?.list);
                                setChildMenu2(false);
                              }}>
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {Dropdown === 2 && (
                  <div
                    className={
                      childMenu2 ? "submenu_child_1" : "submenu_child_2"
                    }>
                    <div
                      className={`${
                        hsnTypeShow && "submenu_2 h-auto sub-2"
                      } submenu_cont_1 overflow-scroll z-3`}>
                      {Hsn_type_Child4?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => {
                                setHsnTypeShow(false);
                                sethsnType(item?.list);
                                setChildMenu2(false);
                              }}>
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="w-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                  Product Description
                </p>
                <textarea
                  type="text"
                  value={productDisc}
                  disabled
                  style={{ height: "100px" }}
                  placeholder="Write a Short Description of the Product"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Size
                </p>
                <input
                  type="text"
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={sizeOfDim}
                  disabled
                />
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Weight
                </p>
                <input
                  type="text"
                  disabled
                  value={weight}
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Dimension
                </p>
                <input
                  type="text"
                  disabled
                  value={designFeatures}
                  placeholder="h × w × l"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Material
                </p>
                <input
                  type="text"
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={meterial}
                  disabled
                />
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Style
                </p>
                <input
                  type="text"
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={style}
                  disabled
                />
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Color
                </p>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Required Field"
                    disabled
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={colorsName}
                    // onChange={onColors}
                  />
                  <br />
                  {/* <button className='drop_down cust-btn' onClick={() => {
                            setColorsShow(!colorsShow);
                            setCatogoryShow(false);
                            setCatogoryShow2(false);
                            setCatogoryShow3(false);
                            setProductNameShow(false);
                            setCountryShow(false);
                            setPackingSizeShow(false)
                        }}>
                            <KeyboardArrowDownIcon />
                        </button> */}
                </div>
                {colorsShow && (
                  <div
                    className="invisible-cont2"
                    onClick={() => setColorsShow(!colorsShow)}
                  />
                )}
                <div
                  className={`${
                    colorsShow && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}>
                  {colors?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setColorsShow(false);
                            setColorsName(item?.color);
                          }}>
                          {item?.color}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              {colorImg && (
                <div className="w-100 overflow-scroll mt-4">
                  <table className="w-100">
                    <tr className="mt-4">
                      <div className="">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 cp fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                style={{ width: "50px" }}>
                                {check === 1 ? (
                                  <CheckBoxOutlined
                                    onClick={() => setCheck(undefined)}
                                  />
                                ) : (
                                  <CheckBoxOutlineBlank
                                    onClick={() => setCheck(1)}
                                  />
                                )}
                              </p>
                            </div>
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                                style={{ width: "200px" }}>
                                Variant
                              </p>
                            </div>
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                style={{ width: "100px" }}>
                                Available
                              </p>
                            </div>
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                style={{ width: "100px" }}>
                                Sub_SKU
                              </p>
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr className="mt-2">
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div
                              className="d-flex ac-jc mx-2"
                              style={{ width: "50px" }}>
                              <button className="mx-2 d-flex ac-jc cust-btn">
                                {checkBox || check == 1 ? (
                                  <CheckBoxOutlined
                                    className="primary cp"
                                    onClick={() => setCheckBox(false)}
                                  />
                                ) : (
                                  <CheckBoxOutlineBlank
                                    className="primary cp"
                                    onClick={() => setCheckBox(true)}
                                  />
                                )}
                              </button>
                            </div>
                            <div className="mx-2">
                              <div
                                className="num-cls-btn d-flex overflow-scroll p-2"
                                style={{ width: "200px" }}>
                                {/* <p className='f2 fs-12 px-3 fs-sm-13 fs-md-14 fs-lg-15 text-dark'>{[varient, colorsName, meterial]}</p> */}
                                <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                                  XL/Blue/Gold
                                </p>
                              </div>
                            </div>
                            <div className="mx-2">
                              <input
                                type="text"
                                className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="100"
                                style={{ width: "100px" }}
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                type="text"
                                className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                value={subsku}
                                placeholder="CODE1003_X"
                                style={{ width: "100px" }}
                                onChange={(e) => setSubSku(e.target.value)}
                              />
                            </div>
                            <button className="mx-2 d-flex ac-jc cp cust-btn">
                              <DeleteForever className="primary" />
                            </button>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
              )}
            </div>
          </div>
          {/* image section Start */}
          <div className="d-flex gap-3 ac-jc w-100">
            <div>
              <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
                Product Image
              </p>
              <div className="d-flex ac-jc flex-column">
                <div>
                  <label
                    className="img_up_cont d-flex ac-jc cp"
                    onClick={() => !fistShow && dualToggele("product")}>
                    <div className="d-flex ac-jc flex-column">
                      <div className="position-relative">
                        <img
                          // className={logo?.name ? "img_up_cont object-fit-contain" : "placeholder_icon object-fit-contain"}
                          // src={logo?.name ? window.URL.createObjectURL(logo) : uploadIcon}
                          // alt="logo"
                          className={
                            bigImage?.image
                              ? "img_up_cont object-fit-contain "
                              : "placeholder_icon object-fit-contain"
                          }
                          src={bigImage?.image ? bigImage?.image : uploadIcon}
                        />
                        {!textShow && (
                          <div>
                            <ul
                              className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}>
                              <li className="set-default-image">
                                Set&nbsp;Default
                              </li>
                              {smallimage?.length > 1 && (
                                <li
                                  className="set-default-image"
                                  onClick={() => removeImg(bigImage?.ind)}>
                                  Remove
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                      {textShow ? (
                        <p className="f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 primary">
                          {" "}
                          Click here to upload
                        </p>
                      ) : null}
                    </div>

                    {/* <input
              // onChange={(e) => { setLogo(e.target.files[0]); setTextShow(false) }}
              // onChange={imgclick}
              className="d-none"
              type="file"
              accept="image/png, image/jpeg"
            /> */}
                  </label>
                  <div className="d-flex ac-jc gap-3 w-100">
                    {fistShow && (
                      <div
                        class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                        style={{ width: "300px", overflow: "scroll" }}>
                        {smallimage?.map((item, ind) => {
                          return (
                            <div className="gap-3 d-flex ac-j w-100">
                              <div
                                class="add_img d-flex ac-jc"
                                onClick={() => imageSelector(item, ind)}>
                                {" "}
                                <img
                                  src={item}
                                  class="cp add_img object-fit-fill"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {smallimage?.length > 0 && (
                      <label
                        className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                          smallimage?.length == 0 && "mt-3"
                        }`}
                        onClick={() => dualToggele("product")}>
                        +
                      </label>
                    )}
                  </div>
                </div>
                {colorImg && (
                  <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
                    Color Image
                  </p>
                )}
                {colorImg && (
                  <div
                    className="d-flex gap-3 mt-3 ac-jc flex-wrap"
                    style={{ width: "350px" }}>
                    <div className="d-flex flex-column ac-jc for_hover">
                      <label className="add_img d-flex ac-jc cp f3 primary fs-3">
                        <img
                          src={product}
                          className="add_img object-fit-contain"
                        />
                      </label>
                      <div className="d-flex w-100 justify-content-between  in_hover text-dark">
                        <FileDownloadOutlinedIcon />
                        <ClearOutlinedIcon />
                      </div>
                      <p className="f3" style={{ color: "red" }}>
                        Red
                      </p>
                    </div>
                    <div className="d-flex flex-column ac-jc for_hover">
                      <label className="add_img d-flex ac-jc cp f3 primary fs-3">
                        <img
                          src={product}
                          className="add_img object-fit-contain"
                        />
                      </label>
                      <div className="d-flex w-100 justify-content-between  in_hover text-dark">
                        <FileDownloadOutlinedIcon />
                        <ClearOutlinedIcon />
                      </div>
                      <p className="f3" style={{ color: "blue" }}>
                        Blue
                      </p>
                    </div>
                    <div className="d-flex flex-column ac-jc for_hover">
                      <label className="add_img d-flex ac-jc cp f3 primary fs-3">
                        <img
                          src={product}
                          className="add_img object-fit-contain"
                        />
                      </label>
                      <div className="d-flex w-100 justify-content-between  in_hover text-dark">
                        <FileDownloadOutlinedIcon />
                        <ClearOutlinedIcon />
                      </div>
                      <p className="f3" style={{ color: "purple" }}>
                        Purple
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* image section end */}
        </div>
        <PricingBoxComp />
        <div className="d-flex ac-jb w-80">
          <button
            className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => navigate(-1)}>
            Back
          </button>
          <button
            className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => navigate(-1)}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default MapProduct;
