import React, { useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TuneIcon from "@mui/icons-material/Tune";
import { Search } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";

import GetAppIcon from "@mui/icons-material/GetApp";
import {
  editIcon,
  searchIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../../assets/img";
import {
  pre_artwork,
  PriceRequest,
  Price_Request,
} from "../../redux/api/DummyJson";
import {
  useLazyCategoriesQuery,
  useLazyCategoryStatusQuery,
} from "../../redux/api/api";

const PreProduction = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");

  const toggleShowPopup = (type) => {
    setShowPopup(!showPopup);
    if (type) setPopupType(type);
  };

  // RTK QUERY

  const [listCategory] = useLazyCategoriesQuery();
  const [status] = useLazyCategoryStatusQuery();

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const change = (id) => {
    status(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="dashRightView p-5 home_section trans overflow-scroll">
      <div className="d-flex ac-jb mt-2 flex-wrap">
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          Price Request
        </p>
        <div className="d-flex mt-sm-3 border-search mx-1 mt-md-1">
          <img src={searchIcon} alt="search" className="searchiConImg" />
          <input
            className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="d-flex w-100 ac-jb  mt-4">
        <div className="d-flex d-flex flex-md-row flex-column gap-2">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn d-inline f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 mt-4 px-3">
            Total Count : {Price_Request?.length}
          </p>
        </div>
      </div>

      {/* <div className="w-100  mt-5"> */}
      {/* <div className=" w-100"> */}
      <div className="w-100 overflow-scroll mt-5 ">
        <table className=" w-100 px-4">
          <thead>
            <tr className="border-bottom w-100 my-3 mb-2">
              <th>
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  Request ID
                </p>
              </th>
              <th>
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  Project ID
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  Project Name{" "}
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  Product Name
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  Max Margin
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  Min Margin
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  Vendor Margin
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex pb-3 px-2 as-jc">
                  Actions
                </p>
              </th>
            </tr>
          </thead>
          <tbody className="my-4">
            {Price_Request?.map((item, index) => {
              return (
                <tr className="my-4">
                  <td>
                    <p className="pointerView text-center text-nowrap f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc pb-3 px-4 primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <div className="f2 my-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-nowrap pb-3 px-4 primary1 f3 ">
                      {item?.Request_ID}
                      {/* <p>PR-001</p> */}
                    </div>
                  </td>
                  <td>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-nowrap pb-3 px-4 primary1 f3">
                      {item?.Project_id}
                      {/* TCS */}
                    </p>
                  </td>
                  <td>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-nowrap pb-3 px-4 primary1 f3">
                      {item?.Project_Name}
                      {/* Char-05 */}
                    </p>
                  </td>
                  <td>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-nowrap pb-3 px-4 primary1 f3">
                      {item?.Product_Name}
                      {/* SKU005 */}
                    </p>
                  </td>
                  <td>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-nowrap pb-3 px-4 primary1 f3">
                      {item?.Max_Margin}
                      {/* Hoodie */}
                    </p>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <p className="f2 border-0 rounded-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-nowrap pb-3 px-4 d-inline-block primary1 f3">
                        {item?.Min_Margin}
                        {/* 500 */}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <p className="f2  border-0 rounded-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  pb-3 px-4 text-center text-nowrap d-inline-block primary1 f3">
                        {item?.Vendor_Margin}
                        {/* Inprocess */}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center px-2">
                      <p
                        className={`f2 ${
                          item?.Status == "Rejected"
                            ? "bg-danger"
                            : "bg-success"
                        } border-0 rounded-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  p-2 text-center text-nowrap d-inline white f3 jc-ac`}
                      >
                        {item?.Status}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="w-100 d-flex ac-jc text-center text-nowrap mx-2">
                      <div
                        onClick={() => toggleShowPopup("view")}
                        className="viewBoxDes p-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" alt="icon" />
                      </div>
                      <div
                        onClick={() => toggleShowPopup("edit")}
                        className="viewBoxDes ms-2 p-2 bg-white rounded-2"
                      >
                        <img src={editIcon} className="viewDes" alt="icon" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* </div> */}
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default PreProduction;
