import { Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { useLocation, useNavigate } from "react-router-dom";
import PriceBookListComp from "../PriceBookLibraryComp/PriceBookListComp";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Select from "react-select";
import {
  searchIcon,
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../../assets/img";
import { pricebookLists } from "../../redux/api/DummyJson";

const ProductDetailsEnterprises = () => {
  const navigat = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [selectBox, setSelectBox] = useState([]);

  const toggleShowPopup = (type) => {
    setShowPopup(!showPopup);
    if (type) setPopupType(type);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showPopup]);

  let options = [
    { value: "all", label: "All" },
    { value: "amazon", label: "Amazon" },
    { value: "ab prime", label: "Ab Prime" },
    { value: "kti promos", label: "KTI  Promos" },
  ];
  return (
    <div className="dashRightView_enterprises  p-5 home_section2 trans">
      {/* <ProductSearchHeader /> */}
      {/* <div className="d-flex flex-md-row flex-column justify-content-between">
        <div className="w-50 ac-jb d-flex flex-md-row flex-column mt-4">
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">10</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex mt-sm-3">
            <img src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          <button
            onClick={() =>
              navigat("/add-price-book-library", {
                state: { method: "enterprises" },
              })
            }
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Pricebook
          </button>
        </div>
      </div> */}
      {/* <PriceBookListComp type={"enterprises"} /> */}

      <>
        <div className="">
          <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-2">
            <div className="d-flex">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Entries
              </p>
            </div>
            <div className="d-flex flex-md-row flex-column">
              <div className="d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2">
                  Total Count : {"05"}
                </p>
                <div className="d-flex my-1 mx-1 border-search">
                  <img
                    src={searchIcon}
                    alt="search_img"
                    className="searchiConImg"
                  />
                  <input
                    className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                    placeholder="Search"
                  />
                </div>
                <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
                  <Search />
                </button>
                <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
                  <CachedIcon />
                </button>
                <button
                  onClick={() => toggleShowPopup("add")}
                  className="cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add Pricebook
                </button>
              </div>
            </div>
          </div>
          <div className="mt-3" style={{ width: "100%", overflow: "auto" }}>
            <table className="table-design-new" style={{ width: "100%" }}>
              <thead>
                <tr className="border-bottom w-100">
                  <th>
                    <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                      S.no
                    </p>
                  </th>
                  <th>
                    <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                      Pricebook Name
                    </p>
                  </th>
                  <th>
                    {" "}
                    <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                      Enterprises Name
                    </p>
                  </th>
                  <th>
                    {" "}
                    <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                      Pricebook Margin
                    </p>
                  </th>
                  <th>
                    {" "}
                    <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                      Status
                    </p>
                  </th>
                  <th>
                    {" "}
                    <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                      Options
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pricebookLists?.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          {index + 1}
                        </p>
                      </td>
                      <td>
                        <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          {item?.pricebook_name}
                        </p>
                      </td>
                      <td>
                        <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          {item?.enterprises_name}
                        </p>
                      </td>
                      <td>
                        <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          {item?.pricebook_margin}
                        </p>
                      </td>
                      <td>
                        <div className="d-flex ac-jc">
                          {item?.status === 1 && (
                            <div>
                              <img src={toggleOn} className="toggleOnDes" />
                            </div>
                          )}
                          {item?.status === 0 && (
                            <div>
                              <img src={toggleOff} className="toggleOnDes" />
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex ac-jc primary1">
                          <div
                            className="viewBoxDes pointerView p-2 bg-white rounded-2"
                            onClick={() =>
                              navigat(`/pricebook-library-view`, {
                                state: {
                                  type: "view",
                                  method: "enterprises",
                                  data: item,
                                },
                              })
                            }
                          >
                            <img src={viewIcon} className="viewDes" />
                          </div>
                          <div
                            className="viewBoxDes ms-2 p-2 bg-white rounded-2"
                            onClick={() =>
                              navigat(`/pricebook-library-view`, {
                                state: {
                                  type: "edit",
                                  method: "enterprises",
                                  data: item,
                                },
                              })
                            }
                          >
                            <img src={editIcon} className="viewDes" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Previous
            </button>
            <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Next
            </button>
          </div>
        </div>

        {showPopup && (
          <div className="add-category-popup" style={{ position: "fixed" }}>
            <div>
              <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
                {"Add Pricebook Library"}
                <span>
                  <button onClick={toggleShowPopup} className="cust-btn">
                    <HighlightOffIcon />
                  </button>
                </span>
              </p>

              <div className="d-flex ac-jb add-content gap-2 w-100">
                <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                  <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                    Pricebook Name*
                  </p>
                  <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                    <input
                      className=" editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      placeholder="Pricebook Name"
                    />
                  </div>
                </div>
                <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                  <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                    Enterprises Name
                  </p>
                  <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                    <input
                      className=" editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      placeholder="Enterprises Name"
                      value={"Amazon"}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3 w-50">
                <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Pricebook Margin*
                </p>
                <input
                  placeholder="Pricebook Margin"
                  className=" editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
              </div>

              <div className="d-flex mt-3 justify-content-end m-2">
                <button
                  className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                  onClick={toggleShowPopup}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ProductDetailsEnterprises;
