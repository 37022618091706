import React from "react";
const SearchComponent = () => {
  return (
    <div className="">
      <div className="d-flex flex-wrap mt-2">
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Order Rep
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="Mattew">Mattew</option>
              <option value="Albert">Albert</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Client Rep
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="Mattew">Mattew</option>
              <option value="Albert">Albert</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Production Rep
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="Mattew">Mattew</option>
              <option value="Albert">Albert</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Supplier
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="Mattew">Ninos It Solution</option>
              <option value="Albert">Merch HQ</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Client
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="Mattew">Ninos It Solution</option>
              <option value="Albert">Merch HQ</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Shipping Method
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="Ground">Ground</option>
              <option value="Same Day">Same Day</option>
              <option value="Pick Up">Pick Up</option>
              <option value="UPS">UPS</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Proof Status
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="No Proof Required">No Proof Required</option>
              <option value="Awaiting Proof">Awaiting Proof</option>
              <option value="Proof Received">Proof Received</option>
              <option value="Pending Approval">Pending Approval</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Status
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="Ok">Ok</option>
              <option value="Problem">Problem</option>
              <option value="Follow Up">Follow Up</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Stage
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="Open">Open</option>
              <option value="Ground">Inproduction</option>
              <option value="Submitted">Submitted</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Shipped">Shipped</option>
              <option value="Read to Billing">Read to Billing</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Firm
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Next Action
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="None Set">None Set</option>
              <option value="Check Stock">Check Stock</option>
              <option value="Call Supplier">Call Supplier</option>
              <option value="Call Client">Call Client</option>
              <option value="Obtain Proof">Obtain Proof</option>
              <option value="Others">Others</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Data Type
            </p>
            <select
              name=""
              id=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              <option value="All">All</option>
              <option value="In Hand Dates">In Hand Dates</option>
              <option value="Project Ship Date">Project Ship Date</option>
              <option value="Next Action Date">Next Action Date</option>
            </select>
            {/* <input placeholder="Enter Legal Name" value="" /> */}
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Purchase Order
            </p>
            <input
              type="text"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder="Purchase Order 
"
            />
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Sales Order
            </p>
            <input
              type="text"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder="Sales Order 
"
            />
          </div>
        </div>
        <div className="w-xs-100 w-sm-50 w-lg-33 mb-3">
          <div className="px-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Project
            </p>
            <input
              type="text"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder="Project 
"
            />
          </div>
        </div>
      </div>
      <div className="d-flex ac">
        <button className="cust-btn py-2 px-3 mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 rounded">
          Get Report
        </button>
        <button className="cust-btn py-2 px-3 mt-sm-3 ms-1 bg-primary1 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 rounded">
          Export
        </button>
        <button className="cust-btn py-2 px-3 mt-sm-3 ms-1 bg-gray text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 rounded">
          Reset
        </button>
      </div>
    </div>
  );
};
export default SearchComponent;
