import React from "react";

const AddClientAccount = ({ togglePopup }) => {
  return (
    <div className="popup-bg d-flex ac-jc">
      <div className="w-50 h-80 bg-white p-4 overflow-scroll">
        <div className="w-100 d-flex ac-jb">
          <p className="f4 fs-24 primary">Add Third Party Shipping Account</p>
        </div>
        <br />
        <p className="f1 gray">Provide account details for 5SK's account.</p>
        <br />
        <p className="f1 gray">
          For Hourglass Essentials Pvt. Ltd. third party shipping accounts talk
          to your commonsku administrator
        </p>
        <div className=" d-flex ac-js my-3 ">
          <p className="w-40 f3 fs-16">Courier</p>
          <select className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            <option disabled> Required Field </option>
            <option> FedEx </option>
            <option> UPS </option>
            <option> Other </option>
          </select>
        </div>
        <div className=" d-flex ac-js my-3 ">
          <p className="w-40 f3 fs-16">Account Name</p>
          <input
            placeholder="Required Field"
            className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            type="text"
          />
        </div>
        <div className=" d-flex ac-js my-3 ">
          <p className="w-40 f3 fs-16">Account Number</p>
          <input
            placeholder="Required Field"
            className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            type="text"
          />
        </div>
        <div className=" d-flex ac-js my-3 ">
          <p className="w-40 f3 fs-16">Postal / Zip Codeer</p>
          <input
            placeholder="Required Field"
            className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            type="text"
          />
        </div>
        <div className="d-flex ac-jb">
          <div></div>
          <div>
            <button
              onClick={togglePopup}
              className="cust-btn btn-padding bg-gray ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Cancel
            </button>
            <button
              onClick={togglePopup}
              className="cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClientAccount;
