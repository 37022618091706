import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import { salesForce } from "../../redux/api/DummyJson";
import AddAttributesPopup from "../Popup/AddAttributesPopup";
import ProjectDetailPopup from "../Popup/ProjectDetailPopup";
import ProjectDetailPopup2 from "../Popup/ProjectDetailPopup2";
import AddTaskPopup from "../Popup/AddTaskPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Link } from "react-router-dom";

const Enterprise = () => {
  const [showDetailPopup, setShowDetailPopup] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false);

  const closeDetailPopup = () => {
    setShowDetailPopup(null);
  };

  const onClickAddTask = () => {
    setShowAddTask(!showAddTask);
  };

  return (
    <div className="mt-4 ">
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0 mb-4">
          <input
            type="text"
            className="w-20 get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
            placeholder="Search for project"
          />
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Client Rep
              </option>
              <option value="All">All</option>
              <option value="In Active Reps">In Active Reps</option>
            </select>
          </button>
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Order Rep
              </option>
              <option value="All">All</option>
              <option value="In Active Reps">In Active Reps</option>
            </select>
          </button>
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Stage
              </option>
              <option value="All">All</option>
              <option value="Open Projects">Open Projects</option>
              <option value="Opportunity">Opportunity</option>
              <option value="Presentation">Presentation</option>
              <option value="Estimate">Estimate</option>
              <option value="Sales Order">Sales Order</option>
              <option value="Invoice">Invoice</option>
            </select>
          </button>
        </div>
        <div className="d-flex ac-jc gap-3">
          <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <Search />
          </button>
          <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <CachedIcon />
          </button>
        </div>
      </div>
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
          Total Count : {"04"}
        </p>
      </div>
      <div
        role="button"
        className=" w-100 overflow-scroll mt-5  postion-relative">
        <div
          style={{ minWidth: "800px" }}
          className=" d-flex border-bottom pb-1">
          <p className="w-15  f2 fs-14 text-center">
            Customer Rep
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
          <p className="w-15  f2 fs-14 text-center">
            Order Rep
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
          <p className="w-20  f2 fs-14 text-center">
            Project
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
          <p className="w-10  f2 fs-14 text-center">
            Stage
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
          <p className="w-15  f2 fs-14 text-center">
            Overdue
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
          <p className="w-10  f2 fs-14 text-center">
            Oct
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
          <p className="w-10  f2 fs-14 text-center">
            Nov
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
          <p className="w-10  f2 fs-14 text-center">
            Dec
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
        </div>

        <div
          style={{ height: "250px" }}
          className="show-scrollbar overflow-y-scroll">
          {salesForce?.map((item, index) => {
            return (
              <div
                style={{ minWidth: "800px" }}
                className=" wholeDesign d-flex mb-3 mt-5 w-100">
                <p
                  className="w-15  f2 fs-14 text-center primary1"
                  onClick={() => setShowDetailPopup(item)}>
                  {item?.custRep}
                </p>
                <p
                  className="w-15  f2 fs-14 text-center primary1"
                  onClick={() => setShowDetailPopup(item)}>
                  {item?.orderRep}
                </p>
                <div className="w-20 fs-14 text-center">
                  <Link
                    className="f3 primary1"
                    to={"/overview"}
                    target="_blank">
                    {item?.project?.id}
                  </Link>
                  <p
                    className="f1 fs-13 gray"
                    onClick={() => setShowDetailPopup(item)}>
                    {item?.project?.company}
                  </p>
                </div>
                <div
                  className="w-15 fs-14 text-center"
                  onClick={() => setShowDetailPopup(item)}>
                  <p className="f3">{item?.stage?.order}</p>
                  <p className="f1 fs-13 gray">{item?.stage?.status}</p>
                </div>

                <p
                  className="w-15  f2 fs-14 text-center green1"
                  onClick={() => setShowDetailPopup(item)}>
                  {item?.overdue}
                </p>
                <p
                  className="w-10  f2 fs-14 text-center green1"
                  onClick={() => setShowDetailPopup(item)}>
                  {item?.oct}
                </p>
                <p
                  className="w-10  f2 fs-14 text-center green1"
                  onClick={() => setShowDetailPopup(item)}>
                  {item?.nov}
                </p>
                <p
                  className="w-10  f2 fs-14 text-center green1"
                  onClick={() => setShowDetailPopup(item)}>
                  {item?.dec}
                </p>
              </div>
            );
          })}
        </div>

        <div
          style={{ minWidth: "800px" }}
          className=" f3 d-flex border-bottom pb-1 mt-4 position-sticky top-0">
          <p className="w-15 fs-14 text-center"></p>
          <p className="w-15 fs-14 text-center"></p>
          <p className="w-20 fs-14 text-center"></p>
          <p className="w-15 fs-14 text-center">Total</p>
          <p className="w-15 fs-14 text-center">$ 1,021,618</p>
          <p className="w-10 fs-14 text-center">$ 5,994</p>
          <p className="w-10 fs-14 text-center">$ 0</p>
          <p className="w-10 fs-14 text-center">$ 271,733</p>
        </div>
      </div>
      {/* {showDetailPopup && (
  <ProjectDetailPopup
    item={showDetailPopup}
    toggleShowPopup={closeDetailPopup}
    onClickAddTask={onClickAddTask}
  />
)} */}

      {showDetailPopup && (
        <ProjectDetailPopup2
          item={showDetailPopup}
          toggleShowPopup={closeDetailPopup}
          onClickAddTask={onClickAddTask}
        />
      )}

      {showAddTask && <AddTaskPopup toggleShowPopup={onClickAddTask} />}
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default Enterprise;
