import React from 'react'

const SampleEmtyScreen = () => {
    return (
        <div className='home_section trans'>
            SampleEmtyScreen
        </div>
    )
}

export default SampleEmtyScreen