import React, { useEffect, useState } from "react";
import {
  collaborateList,
  colorsAndSize,
  size_comp,
  static_attributes,
} from "../../../redux/api/DummyJson";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Close, Search, CircleOutlined, Circle } from "@mui/icons-material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { danger_sm, product } from "../../../assets/img";
import { useNavigate } from "react-router-dom";
import VariationsPopUpCopy2 from "../../Popup/VariationsPopUpCopy2";
import { useSelector } from "react-redux";

const EstimateEditTableComp = ({ poppupAddressToggle, poppupImageToggle }) => {
  const navigate = useNavigate();
  const selector = useSelector((state) => state.colorSize);
  const [colorAndSize, setColorAndSize] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [dropDown, setDropDown] = useState(undefined);
  const [radioBtn, setRadioBtn] = useState(1);
  const [boxRadioBtn, setBoxRadioBtn] = useState(1);
  const [variationType, setVariationsType] = useState("project-colors");
  const [Variations, setVariations] = useState(false);
  const [colorsName, setColorsName] = useState([]);
  const [colorsShow, setColorsShow] = useState(false);
  const [addBreakdown, setAddBreakdown] = useState([""]);
  const [AddRunCharge, setAddRunCharge] = useState([""]);
  const [AddCharges, setAddCharges] = useState([]);
  const [international, setInternational] = useState([]);
  const [chargeIn, setChargeIn] = useState([]);
  const [AddFixedCharge, setAddFixedCharge] = useState([""]);
  const [inboundFreight, setInboundFreight] = useState([""]);
  const [brokerage, setBrokerage] = useState([""]);
  const [addFixedChargeDec, setAddFixedChargeDec] = useState([""]);
  const [addRunChargeDec, setAddRunChargeDec] = useState([""]);
  const [decoration, setDecoration] = useState([1, 2]);
  const [addItemLoc, setAddItemLoc] = useState([""]);
  const [addColor, setAddColor] = useState([]);
  const [run, setRun] = useState({});
  const [fixed, setFixed] = useState({});
  const [decorationData, setDecorationData] = useState({
    0: {
      0: { text: "Imprint Cost", select: 1 },
      1: { text: "Setup Cost", select: 1 },
    },
  });
  const [addFixedChargeDec2, setAddFixedChargeDec2] = useState([""]);
  const [addRunChargeDec2, setAddRunChargeDec2] = useState([""]);
  const [addItemLoc2, setAddItemLoc2] = useState([""]);

  const [addLocation, setAddLocation] = useState([""]);
  const [location, setLocation] = useState([0]);

  const [addSize, setAddSize] = useState([]);

  useEffect(() => {
    // console.log("selector", selector);
    if (selector?.length > 0) {
      setColorAndSize(selector);
      setColorsName([]);
    } else {
      setColorAndSize([]);
      setColorsName([]);
    }
  }, [selector]);

  //   Brakedown
  const removebreackdown = (e, ind) => {
    let temp = [...addBreakdown];
    temp.splice(ind, 1);
    setAddBreakdown(temp);
  };
  const addBreakClick = () => {
    let temp = [...addBreakdown];
    temp.push({ warn: "" });
    setAddBreakdown(temp);
  };

  //AddRunCharge

  const removeRunChargeClick = (e, ind) => {
    let temp = [...AddRunCharge];
    temp.splice(ind, 1);
    setAddRunCharge(temp);
  };
  const addRunChargeClick = () => {
    let temp = [...AddRunCharge];
    temp.push({ warn: "" });
    setAddRunCharge(temp);
  };

  //AddFixedCharge

  const removeFixedChargeClick = (e, ind) => {
    let temp = [...AddFixedCharge];
    temp.splice(ind, 1);
    setAddFixedCharge(temp);
  };
  const addFixedChargeClick = () => {
    let temp = [...AddFixedCharge];
    temp.push({ warn: "" });
    setAddFixedCharge(temp);
  };

  //inboundFreight

  const removeInboundFreightClick = (e, ind) => {
    let temp = [...inboundFreight];
    temp.splice(ind, 1);
    setInboundFreight(temp);
  };
  const addinboundFreightClick = () => {
    let temp = [...inboundFreight];
    temp.push({ warn: "" });
    setInboundFreight(temp);
  };

  //Brokerage

  const removeBrokerageClick = (e, ind) => {
    let temp = [...brokerage];
    temp.splice(ind, 1);
    setBrokerage(temp);
  };
  const addBrokerageClick = () => {
    let temp = [...brokerage];
    temp.push({ warn: "" });
    setBrokerage(temp);
  };

  // Size
  const addSizeClick = () => {
    let temp = [...addSize];
    temp.push({ warn: "" });
    setAddSize(temp);
  };

  // Decorator

  // RunCharge
  const removeRunChargeDecClick = (e, ind) => {
    let temp = [...addRunChargeDec];
    temp.splice(ind, 1);
    setAddRunChargeDec(temp);
  };
  const addRunChargeDecClick = () => {
    let temp = [...addRunChargeDec];
    temp.push({ warn: "" });
    setAddRunChargeDec(temp);
  };

  //AddFixedCharge

  const removeFixedChargeDecClick = (e, ind) => {
    let temp = [...addFixedChargeDec];
    temp.splice(ind, 1);
    setAddFixedChargeDec(temp);
  };
  const addFixedChargeDecClick = () => {
    let temp = [...addFixedChargeDec];
    temp.push({ warn: "" });
    setAddFixedChargeDec(temp);
  };
  //Add Item

  const removeAddItemClick = (e, ind, ind2) => {
    let temp = [...addItemLoc];
    temp.splice(ind2[ind], 1);
    setAddItemLoc(temp);
  };
  const addAddItemClick = () => {
    let temp = [...addItemLoc];
    temp.push({ warn: "" });
    setAddItemLoc(temp);
  };

  // Location2

  // RunCharge
  const removeAddLocClick2 = (e, ind) => {
    let temp = [...addLocation];
    temp.splice(ind, 1);
    setAddLocation(temp);
  };
  const addAddLocClick2 = () => {
    let temp = [...addLocation];
    temp.push({ warn: "" });
    setAddLocation(temp);
  };

  const toggleShowPopup = (type) => {
    setVariations(!Variations);
  };

  const ColorSlect = (e) => {
    if (colorsName.includes(e)) {
      const listtemp = colorsName.indexOf(e);
      const list = [...colorsName];
      list.splice(listtemp, 1);
      setColorsName(list);
      setAddColor(list);
    } else {
      setColorsName([...colorsName, e]);
      setAddColor([...addColor, e]);
    }
  };

  const onColors = (e) => {
    setColorsName(e.target.value);
    if (colorsName.length >= 2) {
      setColorsShow(true);
    } else {
      if (colorsName.length >= 0) setColorsShow(false);
    }
  };

  // ADD CHARGES COMMON

  const addCharges = (num) => {
    let temp = [...AddCharges];

    temp.push(num);
    setAddCharges(temp);
    setDropDown(undefined);
  };

  const removeCharge = (ind) => {
    let temp = [...AddCharges];

    temp.splice(ind, 1);
    setAddCharges(temp);
  };

  const chargeRun = (event, ind) => {
    let value = event.target.value;
    setRun((val) => ({ ...val, [ind]: value }));
  };

  const chargeFixed = (event, ind) => {
    let value = event.target.value;
    setFixed((val) => ({ ...val, [ind]: value }));
  };

  const addInternaional = (num) => {
    let temp = [...international];

    temp.push(num);
    setInternational(temp);
    setDropDown(undefined);
  };

  const removeInternational = (ind) => {
    let temp = [...international];

    temp.splice(ind, 1);
    setInternational(temp);
  };

  const removeColors = (event, ind) => {
    let temp = [...colorsName];

    temp.splice(ind, 1);
    setColorsName(temp);
  };

  const addDecoration = (ind) => {
    let temp = [...decoration];

    temp.push(ind);
    setDecoration(temp);
    setDropDown(undefined);
  };

  const removeDecoration = (item, ind, ind2) => {
    let temp = [...decoration];

    let num = decoration[ind2];

    temp.splice(ind, 1);
    setDecoration(temp);

    if (Object.values(decorationData)?.length > 0) {
      let obj = decorationData[ind2];

      console.log("obj", obj);

      if (obj !== undefined) {
        delete obj[ind];

        let tem = Object.values(obj);

        if (tem?.length == 0) {
          delete decorationData[ind2];
        }

        setDecorationData((val) => ({ ...val }));
      }
    }
  };

  const locationAdd = (num, type) => {
    let temp = [...location];
    temp.push(num);

    for (let i = 0; i < temp?.length; i++) {
      setDecorationData((val) => ({
        ...val,
        [i + 1]: {
          0: { text: "Imprint Cost", select: 1 },
          1: { text: "Setup Cost", select: 1 },
        },
      }));
    }

    if (type == "copy") {
      // console.log("ss");
      let obj = { ...decorationData };
    }
    setLocation(temp);
  };

  const locationRemove = (num) => {
    let temp = [...location];
    temp.splice(num, 1);
    setLocation(temp);
  };

  const chargeDecoration = (event, type, ind, ind2) => {
    let value = event.target.value;
    if (decorationData[ind2]) {
      let obj = { ...decorationData[ind2] };

      let tems = { ...obj[ind] };

      let tem = {
        ...obj,
        [ind]: { ...tems, [type]: value },
      };

      setDecorationData({ ...decorationData, [ind2]: tem });
    } else {
      let obj = { ...decorationData[ind2] };

      let tems = { ...obj[ind] };

      let tem = {
        ...obj,
        [ind]: {
          ...tems,
          [type]: value,
        },
      };

      setDecorationData({ ...decorationData, [ind2]: tem });
    }
  };

  console.log("decorationData", decorationData);

  return (
    <div className="w-100">
      {Variations && (
        <VariationsPopUpCopy2
          toggleShowPopup={toggleShowPopup}
          variationType={variationType}
        />
      )}
      <div className="d-flex mt-3 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc gap-2 flex-column flex-sm-row">
          <button
            onClick={() => navigate("/product-library-details")}
            className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            View Product Page
          </button>
          <button
            onClick={() => navigate("/product-library-details")}
            //  onClick={poppupAddressToggle}
            className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            Check Pricing
          </button>
        </div>
        <div className="mt-md-4 mt-3">
          <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              INR
            </span>
          </h5>
        </div>
      </div>
      <div className="w-100 mt-5">
        <p className="f3 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23 black">
          Pricing
        </p>
        <div
          className="w-100 overflow-scroll mt-5 edit_page_cust"
          style={colorsShow ? { height: "300px" } : { borderWidth: "1px" }}
        >
          <table className="w-100">
            <tr className="mt-4 border_cust ">
              <div className="bg-soft-gray1 py-3 bottom_cust_bord">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2" style={{ width: "20px" }}></div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        Varients
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        SKU
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        QTY
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        Net Cost
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Margin
                      </p>
                    </div>{" "}
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Discount Margin
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc"
                      style={{ width: "200px" }}
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                        {" "}
                        Retail
                      </p>
                      <LockOpenRoundedIcon />
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc"
                      style={{ width: "150px" }}
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                        Client Price
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc gap-1"
                      style={{ width: "100px" }}
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                        {" "}
                        Total
                      </p>
                      <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <div className="py-3 hide_res">
                <td>
                  <div className="d-flex w-100">
                    <div
                      className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                      style={{ width: "20px" }}
                    >
                      {/* <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" /> */}
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc "
                      style={{ width: "200px" }}
                    >
                      <div className="position-relative">
                        {/* <input
                              placeholder="Required Field"
                              className="editBtn position-relative w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            />
                            <button
                              className="drop_down cust-btn"
                              onClick={() => setDropDown(1)}
                            >
                              <KeyboardArrowDownIcon className="black" />
                            </button> */}
                        {/* {dropDown === 1 && (
                              <div
                                className="invisible-cont2 z-0"
                                onClick={() => setDropDown(undefined)}
                              />
                            )} */}
                        {/* <div
                              className={`${
                                dropDown === 1 &&
                                "drop_width z-3 h-auto py-2  px-2"
                              } submenu_cont_1 overflow-scroll z-3`}
                            >
                              <div className="d-flex as-jb gap-3">
                                <div className="position-relative w-100">
                                  <div className="d-flex ac-jc position-relative w-100">
                                    <input
                                      placeholder="Required Field"
                                      className="editBtn text-start rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                    />
                                    <button className="drop_down5 cust-btn">
                                      <Search className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black" />
                                    </button>
                                  </div>
                                  <div className="d-flex flex-column my-2">
                                    <div
                                      onClick={() => setBoxRadioBtn(1)}
                                      className={`${
                                        boxRadioBtn === 1
                                          ? "editBtn border-2 ps-2 bg-transparent"
                                          : "bg_fun"
                                      } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
                                    >
                                      <button className="d-flex gap-3  ac-js cust-btn">
                                        <div className="radio_btn d-flex ac-jc">
                                          <div
                                            className={`${
                                              boxRadioBtn === 1
                                                ? "sm_radio"
                                                : "sm_radio1"
                                            }`}
                                          />
                                        </div>
                                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                          Red
                                        </p>
                                      </button>
                                    </div>
                                    <div
                                      onClick={() => setBoxRadioBtn(2)}
                                      className={`${
                                        boxRadioBtn === 2
                                          ? "editBtn border-2 ps-2 bg-transparent"
                                          : "bg_fun"
                                      } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}
                                    >
                                      <button className=" d-flex gap-3 ac-js cust-btn">
                                        <div className="radio_btn d-flex ac-jc">
                                          <div
                                            className={` ${
                                              boxRadioBtn === 2
                                                ? "sm_radio"
                                                : "sm_radio1"
                                            }  `}
                                          />
                                        </div>
                                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                          Pink
                                        </p>
                                      </button>
                                    </div>
                                    <div
                                      onClick={() => setBoxRadioBtn(3)}
                                      className={`${
                                        boxRadioBtn === 3
                                          ? "editBtn border-2 ps-2 bg-transparent"
                                          : "bg_fun"
                                      } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}
                                    >
                                      <button className=" d-flex gap-3 ac-js cust-btn">
                                        <div className="radio_btn d-flex ac-jc">
                                          <div
                                            className={` ${
                                              boxRadioBtn === 3
                                                ? "sm_radio"
                                                : "sm_radio1"
                                            }  `}
                                          />
                                        </div>
                                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                          Blue
                                        </p>
                                      </button>
                                    </div>
                                    <p className="primary ps-2 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      + Add Color
                                    </p>
                                  </div>
                                </div>
                                <div className="w-100">
                                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                                    Size
                                  </p>
                                  <div className="d-flex flex-column my-2">
                                    <div className="d-flex gap-3 ac-js hover_Ef">
                                      <button
                                        onClick={() => setRadioBtn(1)}
                                        className="d-flex gap-3  ac-js cust-btn"
                                      >
                                        <div className="radio_btn d-flex ac-jc">
                                          <div
                                            className={`${
                                              radioBtn === 1
                                                ? "sm_radio"
                                                : "sm_radio1"
                                            }`}
                                          />
                                        </div>
                                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                          M
                                        </p>
                                      </button>
                                    </div>
                                    <div className="d-flex gap-3 ac-js hover_Ef">
                                      <button
                                        onClick={() => setRadioBtn(2)}
                                        className=" d-flex gap-3  ac-js cust-btn"
                                      >
                                        <div className="radio_btn d-flex ac-jc">
                                          <div
                                            className={` ${
                                              radioBtn === 2
                                                ? "sm_radio"
                                                : "sm_radio1"
                                            }  `}
                                          />
                                        </div>
                                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                          XL
                                        </p>
                                      </button>
                                    </div>
                                    <div className="d-flex gap-3 ac-js hover_Ef">
                                      <button
                                        onClick={() => setRadioBtn(3)}
                                        className=" d-flex gap-3  ac-js cust-btn"
                                      >
                                        <div className="radio_btn d-flex ac-jc">
                                          <div
                                            className={` ${
                                              radioBtn === 3
                                                ? "sm_radio"
                                                : "sm_radio1"
                                            }  `}
                                          />
                                        </div>
                                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                          TBD
                                        </p>
                                      </button>
                                    </div>
                                  </div>
                                  <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                    + Add Size
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex ae-je">
                                <button className="drop_btn px-2 f3">
                                  Done
                                </button>
                              </div>
                            </div> */}

                        {/* NEW DESIGN */}
                        <input
                          className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          // value={colorsName}
                          placeholder="Select Varients"
                          onChange={onColors}
                        />
                        <button
                          className="drop_down cust-btn"
                          onClick={() => {
                            setColorsShow(!colorsShow);
                          }}
                        >
                          <KeyboardArrowDownIcon />
                        </button>
                        {colorsShow && (
                          <div
                            className="invisible-cont2"
                            onClick={() => setColorsShow(!colorsShow)}
                          />
                        )}
                        <div
                          className={`${
                            colorsShow && "drop_width z-3 h-auto py-2  px-2"
                          } submenu_cont_1 overflow-scroll z-3`}
                        >
                          {colorAndSize?.length > 0 &&
                            colorAndSize?.map((item, ind) => {
                              // console.log(ind)
                              return (
                                <button
                                  className="d-flex ac-jb hover-cust cust-btn w-100 px-2"
                                  onClick={() => {
                                    ColorSlect(item);
                                  }}
                                >
                                  <button className="px-2 cust-btn text-start py-1 w-100">
                                    {item}
                                  </button>
                                  {colorsName.includes(item) ? (
                                    <Circle style={{ color: "#07679c" }} />
                                  ) : (
                                    <CircleOutlined className="primary" />
                                  )}
                                </button>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                      />
                      <button className="drop_down4 cust-btn">
                        <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                      />
                      <button className="drop_down4 cust-btn">
                        <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                          %
                        </p>
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                      />
                      <button className="drop_down4 cust-btn">
                        <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                          %
                        </p>
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                      />
                      <button className="drop_down4 cust-btn">
                        <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "150px" }}
                    >
                      <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                        $10.53
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                        $1316.25
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                      style={{ width: "10px" }}
                    >
                      <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                    </div>
                  </div>
                </td>
              </div>
            </tr>

            {colorsName?.map((item, ind) => {
              return (
                <tr>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="d-flex w-100">
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "20px" }}
                          onClick={() => removeColors(item, ind)}
                        >
                          <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "415px" }}
                        >
                          <input
                            placeholder="Required Field"
                            value={item}
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          />
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          <input
                            value={"1"}
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          />
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          {/* <input
                          placeholder="Required Field"
                          disabled
                          className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 opacity-50"
                        />
                        <button className="drop_down4 cust-btn">
                          <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                        </button> */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          {/* <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                          />
                          <button className="drop_down4 cust-btn">
                            <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                              %
                            </p>
                          </button> */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          {/* <input
                          placeholder="Required Field"
                          disabled
                          className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 opacity-50"
                        />
                        <button className="drop_down4 cust-btn">
                          <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                            %
                          </p>
                        </button> */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          {/* <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                          />
                          <button className="drop_down4 cust-btn">
                            <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                          </button> */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          {/* <select
                            placeholder="Required Field"
                            className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onChange={(e) => chargeRun(e, ind)}
                          >
                            <option value={1}>Include in price</option>
                            <option value={2}>Display to client</option>
                          </select>{" "} */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          {/* {run[ind] == 2 && (
                            <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                              $1316.25
                            </p>
                          )} */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "10px" }}
                        >
                          {/* <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" /> */}
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              );
            })}

            {AddCharges?.map((item, ind) => {
              return (
                <>
                  {item == 1 && (
                    <tr>
                      <div className="py-3 hide_res">
                        <td>
                          <div className="d-flex w-100">
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "20px" }}
                              onClick={() => removeCharge(ind)}
                            >
                              <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "415px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                                125
                              </p>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                              />
                              <button className="drop_down4 cust-btn">
                                <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                              />
                              <button className="drop_down4 cust-btn">
                                <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                  %
                                </p>
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              {/* <input
                                placeholder="Required Field"
                                disabled
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 opacity-50"
                              />
                              <button className="drop_down4 cust-btn">
                                <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                  %
                                </p>
                              </button> */}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                              />
                              <button className="drop_down4 cust-btn">
                                <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "150px" }}
                            >
                              <select
                                placeholder="Required Field"
                                className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                onChange={(e) => chargeRun(e, ind)}
                              >
                                <option value={1}>Include in price</option>
                                <option value={2}>Display to client</option>
                              </select>{" "}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              {run[ind] == 2 && (
                                <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                                  $1316.25
                                </p>
                              )}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "10px" }}
                            >
                              <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  )}
                  {item == 2 && (
                    <tr>
                      <div className="py-3 hide_res">
                        <td>
                          <div className="d-flex w-100">
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "20px" }}
                              onClick={() => removeCharge(ind)}
                            >
                              <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "415px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              <input
                                value={"1"}
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 "
                              />
                              <button className="drop_down4 cust-btn">
                                <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              {run[ind] == 2 && (
                                <>
                                  {" "}
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                      %
                                    </p>
                                  </button>
                                </>
                              )}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              {/* {run[ind] == 2 && (
                                <>
                                  {" "}
                                  <input
                                    placeholder="Required Field"
                                    disabled
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 opacity-50"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                      %
                                    </p>
                                  </button>
                                </>
                              )} */}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              {run[ind] == 2 && (
                                <>
                                  {" "}
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                                  </button>
                                </>
                              )}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "150px" }}
                            >
                              <select
                                placeholder="Required Field"
                                className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                onChange={(e) => chargeRun(e, ind)}
                              >
                                <option value={1}>Subtract from margin</option>
                                <option value={2}>Display to client</option>
                              </select>{" "}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              {run[ind] == 2 && (
                                <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                                  $1316.25
                                </p>
                              )}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "10px" }}
                            >
                              <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  )}
                </>
              );
            })}
          </table>
        </div>
      </div>
      <div className="d-flex ac-js mt-3">
        <div className="d-flex ac-js flex-column flex-md-row">
          <button
            // onClick={() => setDropDown(5)}
            onClick={toggleShowPopup}
            className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded d-flex ac-jc gap-2"
          >
            <FormatListBulletedIcon />
            Add sizes and colors
          </button>
          {dropDown === 5 && (
            <div
              onClick={() => setDropDown(undefined)}
              className="invisible-cont2 z-2 cp"
            />
          )}
        </div>
        <div className="d-flex flex-column flex-sm-row mt-3 mt-md-0">
          {/* <div className="position-relative">
            <button
              onClick={() => addBreakClick()}
              className="pointerView cust-btn px-4 py-2 tranc cp"
            >
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                + Add Breakdown
              </p>
            </button>
          </div> */}
          <div className="position-relative">
            <button
              onClick={() => setDropDown(6)}
              className="pointerView cust-btn px-4 py-2 tranc cp"
            >
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                + Add Cost
              </p>
            </button>
            {dropDown === 6 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDown(undefined)}
              />
            )}
            <div
              className={`${
                dropDown === 6 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    // setDropDown(undefined);
                    // addRunChargeClick();
                    addCharges(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Run Charge
                </button>
              </button>
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    // setDropDown(undefined);
                    // addFixedChargeClick();
                    addCharges(2);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Fixed Charge
                </button>
              </button>
            </div>
          </div>
          <div className="position-relative">
            <button
              onClick={() => setDropDown(7)}
              className="pointerView cust-btn px-4 py-2 tranc cp"
            >
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                + Add International Cost
              </p>
            </button>
            {dropDown === 7 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDown(undefined)}
              />
            )}
            <div
              className={`${
                dropDown === 7 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addinboundFreightClick();
                    addInternaional(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Inbound Freight
                </button>
              </button>
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addBrokerageClick();
                    addInternaional(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Brokerage
                </button>
              </button>
              {/* {!international?.includes(2) && ( */}
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addBrokerageClick();
                    addInternaional(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Others
                </button>
              </button>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          dropDown === 5 && "submenu_1"
        } submenu_cont_1 overflow-scroll z-3`}
        style={{ width: "550px", height: "300px" }}
      >
        <div className="d-flex as-jb gap-3 px-3">
          <div className="position-relative">
            <div className="position-relative w-100">
              <input
                placeholder="Required Field"
                className="editBtn text-start rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
              />
              <button className="drop_down5 cust-btn position-absolute">
                <Search className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black" />
              </button>
            </div>
            <div className="d-flex flex-column my-2">
              <div
                onClick={() => setBoxRadioBtn(1)}
                className={`${
                  boxRadioBtn === 1
                    ? "editBtn border-2 ps-2 bg-transparent"
                    : "bg_fun"
                } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
              >
                <button className="d-flex gap-3  ac-js cust-btn">
                  <div className="radio_btn d-flex ac-jc">
                    <div
                      className={`${
                        boxRadioBtn === 1 ? "sm_radio" : "sm_radio1"
                      }`}
                    />
                  </div>
                  <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Red
                  </p>
                </button>
              </div>
              <div
                onClick={() => setBoxRadioBtn(2)}
                className={`${
                  boxRadioBtn === 2
                    ? "editBtn border-2 ps-2 bg-transparent"
                    : "bg_fun"
                } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}
              >
                <button className=" d-flex gap-3 ac-js cust-btn">
                  <div className="radio_btn d-flex ac-jc">
                    <div
                      className={` ${
                        boxRadioBtn === 2 ? "sm_radio" : "sm_radio1"
                      }  `}
                    />
                  </div>
                  <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Pink
                  </p>
                </button>
              </div>
              <div
                onClick={() => setBoxRadioBtn(3)}
                className={`${
                  boxRadioBtn === 3
                    ? "editBtn border-2 ps-2 bg-transparent"
                    : "bg_fun"
                } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1`}
              >
                <button className=" d-flex gap-3 ac-js cust-btn">
                  <div className="radio_btn d-flex ac-jc">
                    <div
                      className={` ${
                        boxRadioBtn === 3 ? "sm_radio" : "sm_radio1"
                      }  `}
                    />
                  </div>
                  <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Blue
                  </p>
                </button>
              </div>

              {boxRadioBtn == 4 && (
                <div
                  // onClick={() => setBoxRadioBtn(4)}
                  className={`${
                    boxRadioBtn === 4
                      ? "editBtn border-2 ps-2 bg-transparent"
                      : "bg_fun"
                  } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
                >
                  <button className="d-flex gap-3 ac-js cust-btn">
                    <div className="radio_btn d-flex ac-jc">
                      <div
                        className={`${
                          boxRadioBtn === 4 ? "sm_radio" : "sm_radio1"
                        }`}
                      />
                    </div>
                    <input
                      placeholder="Type here"
                      className="primary bg-transparent border-0 pe-3  me-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </button>
                </div>
              )}

              {boxRadioBtn !== 4 && (
                <p
                  onClick={() => setBoxRadioBtn(4)}
                  className="primary ps-2 f3 fs-xs-11 cp fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                >
                  + Add Color
                </p>
              )}
            </div>
          </div>
          <div className="w-50">
            <div className="d-flex w-100 ac-jb mt-2">
              <p className="f2 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                Size
              </p>
              <p className="f2 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                Quantity
              </p>
            </div>
            <div className="d-flex w-100 ac-jb mt-2">
              <p className="f2 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                M
              </p>
              <input
                placeholder="0"
                style={{ width: "100px" }}
                className="editBtn rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
            </div>
            <div className="d-flex w-100 ac-jb mt-2">
              <p className="f2 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                TBD
              </p>
              <input
                placeholder="0"
                style={{ width: "100px" }}
                className="editBtn rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
            </div>
            {addSize?.map((item, ind) => {
              return (
                <div className="d-flex w-100 ac-jb my-2 " key={ind}>
                  <input
                    placeholder="Size Name"
                    style={{ width: "130px" }}
                    className="editBtn rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <input
                    placeholder="0"
                    style={{ width: "100px" }}
                    className="editBtn rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                </div>
              );
            })}
            <p
              onClick={() => addSizeClick()}
              className="primary ps-2 f3 fs-xs-11 cp fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            >
              + Add Size
            </p>
          </div>
        </div>
        <div className="d-flex ae-je me-3 mt-4">
          <button
            className="drop_btn px-2 f3"
            onClick={() => {
              setDropDown(undefined);
              console.log("working");
            }}
          >
            Done
          </button>
        </div>
      </div>
      {international?.length > 0 && (
        <div className="w-100 overflow-scroll mt-5 edit_page_cust">
          <table className="w-100">
            {/* {inboundFreight?.map((item, ind) => {
            return (
              <tr>
                <div className="py-3 hide_res">
                  <td>
                    <div className="d-flex w-100">
                      <div
                        className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                        style={{ width: "20px" }}
                        onClick={() => removeInboundFreightClick(item, ind)}
                      >
                        <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "415px" }}
                      >
                        <input
                          placeholder="Required Field"
                          className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        />
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      >
                        <input
                          value={"1"}
                          placeholder="Required Field"
                          className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        />
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      >
                        <input
                          placeholder="Required Field"
                          className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        />
                        <button className="drop_down4 cust-btn">
                          <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                        </button>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      >
                        <input
                          placeholder="Required Field"
                          className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        />
                        <button className="drop_down4 cust-btn">
                          <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                            %
                          </p>
                        </button>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      >
                        <input
                          placeholder="Required Field"
                          className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        />
                        <button className="drop_down4 cust-btn">
                          <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                            %
                          </p>
                        </button>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      >
                        <input
                          placeholder="Required Field"
                          className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        />
                        <button className="drop_down4 cust-btn">
                          <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                        </button>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "150px" }}
                      >
                        <select
                          placeholder="Required Field"
                          className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        >
                          <option>Subtract from margin</option>
                          <option>Display to client</option>
                        </select>{" "}
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      >
                        <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                          $1316.25
                        </p>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            );
          })}
          {brokerage?.map((item, ind) => {
            return (
              <tr>
                <div className="py-3 hide_res">
                  <td>
                    <div className="d-flex w-100">
                      <div
                        className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                        style={{ width: "20px" }}
                        onClick={() => removeBrokerageClick(item, ind)}
                      >
                        <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "415px" }}
                      >
                        <input
                          placeholder="Required Field"
                          className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        />
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      >
                        <input
                          value={"1"}
                          placeholder="Required Field"
                          className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        />
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      >
                        <input
                          placeholder="Required Field"
                          className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        />
                        <button className="drop_down4 cust-btn">
                          <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                        </button>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "150px" }}
                      >
                        <select
                          placeholder="Required Field"
                          className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        >
                          <option>Subtract from margin</option>
                          <option>Display to client</option>
                        </select>{" "}
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      ></div>
                    </div>
                  </td>
                </div>
              </tr>
            );
          })} */}

            {international?.map((item, ind) => {
              return (
                <>
                  {item == 1 && (
                    <tr>
                      <div className="py-3 hide_res">
                        <td>
                          <div className="d-flex w-100">
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "20px" }}
                              onClick={() => removeInternational(ind)}
                            >
                              <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "415px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              <input
                                value={"1"}
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 "
                              />
                              <button className="drop_down4 cust-btn">
                                <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              {fixed[ind] == 2 && (
                                <>
                                  {" "}
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                      %
                                    </p>
                                  </button>
                                </>
                              )}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              {/* {fixed[ind] == 2 && (
                                <>
                                  {" "}
                                  <input
                                    placeholder="Required Field"
                                    disabled
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 opacity-50"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                      %
                                    </p>
                                  </button>
                                </>
                              )} */}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              {fixed[ind] == 2 && (
                                <>
                                  {" "}
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                                  </button>
                                </>
                              )}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "150px" }}
                            >
                              <select
                                placeholder="Required Field"
                                className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                onChange={(e) => chargeFixed(e, ind)}
                              >
                                <option value={1}>Subtract from margin</option>
                                <option value={2}>Display to client</option>
                              </select>{" "}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              {fixed[ind] == 2 && (
                                <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                                  $1316.25
                                </p>
                              )}
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "10px" }}
                            >
                              <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  )}
                  {item == 2 && (
                    <tr>
                      <div className="py-3 hide_res">
                        <td>
                          <div className="d-flex w-100">
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "20px" }}
                              onClick={() => removeInternational(item, ind)}
                            >
                              <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc "
                              style={{ width: "200px" }}
                            >
                              <div className="position-relative">
                                <input
                                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                  placeholder="Required Varients"
                                />
                              </div>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                              />
                              <button className="drop_down4 cust-btn">
                                <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                              />
                              <button className="drop_down4 cust-btn">
                                <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                  %
                                </p>
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                              />
                              <button className="drop_down4 cust-btn">
                                <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                  %
                                </p>
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              <input
                                placeholder="Required Field"
                                className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                              />
                              <button className="drop_down4 cust-btn">
                                <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "150px" }}
                            >
                              <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                                $10.53
                              </p>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "100px" }}
                            >
                              <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                                $1316.25
                              </p>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "10px" }}
                            >
                              <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  )}
                </>
              );
            })}
          </table>
        </div>
      )}
      <div className="d-flex ac-js mt-5 gap-3 flex-column flex-md-row">
        <div className="me-5">
          <p className="f3 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23 black">
            Decoration
          </p>
        </div>
        <div className="d-flex toggle_box_cont ac-jc rounded-5">
          <div
            onClick={() => setToggle(1)}
            className={`${
              toggle === 1 ? "toggle_box1" : "empty_box"
            }  d-flex ac-jc  rounded-5 w-100`}
          >
            <button className="cust-btn f3 text-light py-2 py-sm-3  px-1 px-sm-3 d-flex ac-jc text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Supplier Decorator
            </button>
          </div>
          {/* <div
            onClick={() => setToggle(2)}
            className={`${
              toggle === 2 ? "toggle_box1" : "empty_box"
            }  d-flex ac-jc rounded-5 w-100`}
          >
            <button className="cust-btn f3 text-light py-2 py-sm-3 px-1 px-sm-3 text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              3rd Party Decorator
            </button>
          </div> */}
        </div>
      </div>
      {location?.length > 0 &&
        location?.map((item2, ind2) => {
          return (
            <div className="w-100 overflow-scroll mt-5 edit_page_cust">
              <table className="w-100">
                <div className="d-flex hide_res">
                  <div
                    className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                    style={{ width: "20px" }}
                    onClick={() => locationRemove(ind2)}
                  >
                    <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                  </div>{" "}
                  <p className="primary f3 ms-5 pt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Location {ind2 + 1}
                  </p>
                </div>
                {addItemLoc?.map((item, ind) => {
                  return (
                    <tr>
                      <div className="py-3 hide_res">
                        <td>
                          <div className="d-flex w-100">
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "20px" }}
                              onClick={() => {
                                removeAddItemClick(item, ind, ind2);
                                // ind === 0 && removeAddLocClick2(item2, ind2);
                              }}
                            >
                              <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "415px" }}
                            >
                              <div className="w-100">
                                <input
                                  placeholder="Required Field"
                                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                  value={"Top"}
                                />
                              </div>
                            </div>
                            <div className="mx-2 d-flex ac-jc position-relative">
                              <button
                                onClick={poppupImageToggle}
                                className=" bg-transparent doted_border d-flex ac-js "
                              >
                                <div className="">
                                  <img
                                    src={product}
                                    className="object-fit-contain img_style"
                                  />
                                </div>
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              <div className="d-flex ac-js gap-2 ">
                                <img src={danger_sm} className="danger_sm" />
                                <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                  Proof Required
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  );
                })}
                {decoration?.map((item, ind) => {
                  return (
                    <>
                      {item == 1 && (
                        <tr>
                          <div className="py-3 hide_res">
                            <td>
                              <div className="d-flex w-100">
                                <div
                                  className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                                  style={{ width: "20px" }}
                                  onClick={() =>
                                    removeDecoration(item, ind, ind2)
                                  }
                                >
                                  <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "415px" }}
                                >
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                    onChange={(e) =>
                                      chargeDecoration(e, "text", ind, ind2)
                                    }
                                    value={decorationData[ind2]?.[ind]?.text}
                                  />
                                  <button className="drop_down cust-btn">
                                    <ContentCopyRoundedIcon className="primary" />
                                  </button>
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "100px" }}
                                >
                                  <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                                    125
                                  </p>
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "200px" }}
                                >
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                                  </button>
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "100px" }}
                                >
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                      %
                                    </p>
                                  </button>
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "100px" }}
                                >
                                  {/* <input
                                  placeholder="Required Field"
                                  className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                />
                                <button className="drop_down4 cust-btn">
                                  <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                    %
                                  </p>
                                </button> */}
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "200px" }}
                                >
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                                  </button>{" "}
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "150px" }}
                                >
                                  <select
                                    placeholder="Required Field"
                                    className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                    onChange={(e) =>
                                      chargeDecoration(e, "select", ind, ind2)
                                    }
                                    value={decorationData[ind2]?.[ind]?.select}
                                  >
                                    <option value={1}>Include in price</option>
                                    <option value={2}>Display to client</option>
                                  </select>
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "100px" }}
                                >
                                  {decorationData[ind2]?.[ind]?.select == 2 && (
                                    <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                                      $1316.25
                                    </p>
                                  )}
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                                  style={{ width: "10px" }}
                                >
                                  <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                                </div>
                              </div>
                            </td>
                          </div>
                        </tr>
                      )}
                      {item == 2 && (
                        <tr>
                          <div className="py-3 hide_res">
                            <td>
                              <div className="d-flex w-100">
                                <div
                                  className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                                  style={{ width: "20px" }}
                                  onClick={() =>
                                    removeDecoration(item, ind, ind2)
                                  }
                                >
                                  <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "415px" }}
                                >
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                    onChange={(e) =>
                                      chargeDecoration(e, "text", ind, ind2)
                                    }
                                    value={decorationData[ind2]?.[ind]?.text}
                                  />
                                  <button className="drop_down cust-btn">
                                    <ContentCopyRoundedIcon className="primary" />
                                  </button>
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "100px" }}
                                >
                                  <input
                                    value={"1"}
                                    placeholder="Required Field"
                                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                  />
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "200px" }}
                                >
                                  <input
                                    placeholder="Required Field"
                                    className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                  />
                                  <button className="drop_down4 cust-btn">
                                    <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                                  </button>
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "100px" }}
                                >
                                  {decorationData[ind2]?.[ind]?.select == 2 && (
                                    <>
                                      {" "}
                                      <input
                                        placeholder="Required Field"
                                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                      />
                                      <button className="drop_down4 cust-btn">
                                        <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                          %
                                        </p>
                                      </button>
                                    </>
                                  )}
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "100px" }}
                                >
                                  {/* <input
                                  placeholder="Required Field"
                                  className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                />
                                <button className="drop_down4 cust-btn">
                                  <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                    %
                                  </p>
                                </button> */}
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "200px" }}
                                >
                                  {decorationData[ind2]?.[ind]?.select == 2 && (
                                    <>
                                      {" "}
                                      <input
                                        placeholder="Required Field"
                                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                                      />
                                      <button className="drop_down4 cust-btn">
                                        <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                                      </button>{" "}
                                    </>
                                  )}
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "150px" }}
                                >
                                  <select
                                    placeholder="Required Field"
                                    className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                    onChange={(e) =>
                                      chargeDecoration(e, "select", ind, ind2)
                                    }
                                    value={decorationData[ind2]?.[ind]?.select}
                                  >
                                    <option value={1}>
                                      Subtract from margin
                                    </option>
                                    <option value={2}>Display to client</option>
                                  </select>{" "}
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative"
                                  style={{ width: "100px" }}
                                >
                                  {decorationData[ind2]?.[ind]?.select == 2 && (
                                    <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                                      $1316.25
                                    </p>
                                  )}
                                </div>
                                <div
                                  className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                                  style={{ width: "10px" }}
                                >
                                  <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                                </div>
                              </div>
                            </td>
                          </div>
                        </tr>
                      )}
                    </>
                  );
                })}
                {/* {addRunChargeDec?.map((item, ind) => {
                return (
                  <tr>
                    <div className="py-3 hide_res">
                      <td>
                        <div className="d-flex w-100">
                          <div
                            className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                            style={{ width: "20px" }}
                            onClick={() => removeRunChargeDecClick(item, ind)}
                          >
                            <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "415px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            />
                            <button className="drop_down cust-btn">
                              <ContentCopyRoundedIcon className="primary" />
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "100px" }}
                          >
                            <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                              125
                            </p>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "200px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            />
                            <button className="drop_down4 cust-btn">
                              <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "100px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            />
                            <button className="drop_down4 cust-btn">
                              <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                %
                              </p>
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "100px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            />
                            <button className="drop_down4 cust-btn">
                              <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                %
                              </p>
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "200px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            />
                            <button className="drop_down4 cust-btn">
                              <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "150px" }}
                          >
                            <select
                              placeholder="Required Field"
                              className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            >
                              <option>Include in price</option>
                              <option>Display to client</option>
                            </select>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "100px" }}
                          >
                            {/* <p className='f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black'>$1316.25</p> 
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                            style={{ width: "10px" }}
                          >
                            <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                );
              })}
              {addFixedChargeDec?.map((item, ind) => {
                return (
                  <tr>
                    <div className="py-3 hide_res">
                      <td>
                        <div className="d-flex w-100">
                          <div
                            className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                            style={{ width: "20px" }}
                            onClick={() => removeFixedChargeDecClick(item, ind)}
                          >
                            <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "415px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            />
                            <button className="drop_down cust-btn">
                              <ContentCopyRoundedIcon className="primary" />
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "100px" }}
                          >
                            <input
                              value={"1"}
                              placeholder="Required Field"
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            />
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "200px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            />
                            <button className="drop_down4 cust-btn">
                              <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "100px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            />
                            <button className="drop_down4 cust-btn">
                              <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                %
                              </p>
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "100px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            />
                            <button className="drop_down4 cust-btn">
                              <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                                %
                              </p>
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "200px" }}
                          >
                            <input
                              placeholder="Required Field"
                              className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            />
                            <button className="drop_down4 cust-btn">
                              <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                            </button>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "150px" }}
                          >
                            <select
                              placeholder="Required Field"
                              className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            >
                              <option>Subtract from margin</option>
                              <option>Display to client</option>
                            </select>{" "}
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative"
                            style={{ width: "100px" }}
                          >
                            <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                              $1316.25
                            </p>
                          </div>
                          <div
                            className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                            style={{ width: "10px" }}
                          >
                            <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                );
              })} */}
              </table>
              <div>
                <button
                  className="  pointerView cust-btn px-4 py-2 tranc cp"
                  onClick={() => addDecoration(1, ind2)}
                >
                  <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    + Run charge for this decoration
                  </p>
                </button>
                <button
                  onClick={() => addDecoration(2, ind2)}
                  className="  pointerView cust-btn px-4 py-2 tranc cp"
                >
                  <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    + Fixed charge for this decoration
                  </p>
                </button>
                <button
                  // onClick={() => addAddLocClick2()}
                  onClick={() => locationAdd(ind2, "copy")}
                  className="  pointerView cust-btn px-4 py-2 tranc cp"
                >
                  <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    + Copy item location
                  </p>
                </button>
              </div>
            </div>
          );
        })}

      {/* <div className="w-100 overflow-scroll mt-5 edit_page_cust">
        <table className="w-100">
          <tr>
            <div className="py-3 hide_res">
              <td>
                <div className="d-flex w-100">
                  <div
                    className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                    style={{ width: "20px" }}
                  >
                    <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "415px" }}
                  >
                    <div className="w-100">
                      <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        Location {ind + 1}
                      </p>
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                    </div>
                  </div>
                  <div
                    onClick={poppupImageToggle}
                    className="cp mx-2 d-flex ac-jc position-relative"
                  >
                    <div className="doted_border d-flex ac-js">
                      <div className="px-1">
                        <img
                          src={product}
                          className="object-fit-contain img_style"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "200px" }}
                  >
                    <div className="d-flex ac-js gap-2 ">
                      <img src={danger_sm} className="danger_sm" />
                      <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        Proof Required
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </div>
          </tr>
          <tr>
            <div className="py-3 hide_res">
              <td>
                <div className="d-flex w-100">
                  <div
                    className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                    style={{ width: "20px" }}
                  >
                    <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "415px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button className="drop_down cust-btn">
                      <ContentCopyRoundedIcon className="primary" />
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "100px" }}
                  >
                    <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                      125
                    </p>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "200px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                    />
                    <button className="drop_down4 cust-btn">
                      <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "100px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                    />
                    <button className="drop_down4 cust-btn">
                      <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                        %
                      </p>
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "100px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                    />
                    <button className="drop_down4 cust-btn">
                      <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                        %
                      </p>
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "200px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                    />
                    <button className="drop_down4 cust-btn">
                      <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "150px" }}
                  >
                    <select
                      placeholder="Required Field"
                      className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    >
                      <option>Include in price</option>
                      <option>Display to client</option>
                    </select>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "100px" }}
                  >
                    <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                      $1316.25
                    </p>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                    style={{ width: "10px" }}
                  >
                    <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                  </div>
                </div>
              </td>
            </div>
          </tr>
          <tr>
            <div className="py-3 hide_res">
              <td>
                <div className="d-flex w-100">
                  <div
                    className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                    style={{ width: "20px" }}
                  >
                    <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "415px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button className="drop_down cust-btn">
                      <ContentCopyRoundedIcon className="primary" />
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "100px" }}
                  >
                    <input
                      value={"1"}
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "200px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                    />
                    <button className="drop_down4 cust-btn">
                      <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "100px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                    />
                    <button className="drop_down4 cust-btn">
                      <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                        %
                      </p>
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "100px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                    />
                    <button className="drop_down4 cust-btn">
                      <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                        %
                      </p>
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "200px" }}
                  >
                    <input
                      placeholder="Required Field"
                      className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                    />
                    <button className="drop_down4 cust-btn">
                      <AttachMoneyIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                    </button>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "150px" }}
                  >
                    <select
                      placeholder="Required Field"
                      className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    >
                      <option>Subtract from margin</option>
                      <option>Display to client</option>
                    </select>{" "}
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative"
                    style={{ width: "100px" }}
                  >
                    <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                      $1316.25
                    </p>
                  </div>
                  <div
                    className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                    style={{ width: "10px" }}
                  >
                    <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                  </div>
                </div>
              </td>
            </div>
          </tr>
        </table>
        <div>
          <button className="  pointerView cust-btn px-4 py-2 tranc cp">
            <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              + Run charge for this decoration
            </p>
          </button>
          <button className="  pointerView cust-btn px-4 py-2 tranc cp">
            <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              + Fixed charge for this decoration
            </p>
          </button>
          <button className="  pointerView cust-btn px-4 py-2 tranc cp">
            <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              + Copy item location
            </p>
          </button>
        </div>
      </div> */}

      <div className="position-relative">
        <div className="position-relative mt-3">
          <button
            onClick={() => {
              setDropDown(8);
            }}
            className="pointerView cust-btn px-4 py-2 tranc cp"
          >
            <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              + Decoration Location
            </p>
          </button>
          {dropDown === 8 && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setDropDown(undefined)}
            />
          )}
          <div
            className={`${
              dropDown === 8 && "submenu_1 h-auto"
            } submenu_cont_1 overflow-scroll z-3`}
            style={{ width: "300px" }}
          >
            <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
              <button
                onClick={() => {
                  setDropDown(undefined);
                  addAddLocClick2();
                  locationAdd(1);
                }}
                className="px-2 cust-btn text-start py-1 w-100 "
              >
                Add new decoration location
              </button>
            </button>
            {/* <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
              <button
                onClick={() => setDropDown(undefined)}
                className="px-2 cust-btn text-start py-1 w-100 "
              >
                Copy from another product on this order
              </button>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimateEditTableComp;
