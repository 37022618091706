import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { productTableData } from "../redux/api/DummyJson";

const CustomerViewDetailsScreen = () => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  console.log(data);

  return (
    <div className="dashRightView p-5 home_section trans">
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        {location?.state?.type === "shipping"
          ? "Shipping Product Details"
          : "Customer Product Details"}
      </p>
      <div className="w-100 searchField mt-5 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                {location?.state?.type === "shipping"
                  ? "Receipt Name:"
                  : "Customer Name:"}
              </p>
              <div className="w-70 ms-3">
                <input
                  disabled={location?.state?.edit === true ? false : true}
                  placeholder="Name"
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={data?.name}
                />
              </div>
            </div>
            {location?.state?.type === "shipping" ? null : (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Customer ID:
                </p>
                {location?.state?.edit === true && (
                  <div className="w-70 ms-3">
                    <input
                      placeholder=""
                      className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value={data?.CustomerId}
                    />
                  </div>
                )}
                {location?.state?.edit !== true && (
                  <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                    {data?.CustomerId}
                  </p>
                )}
              </div>
            )}
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                {location?.state?.type === "shipping"
                  ? "Recepit Email:"
                  : "Customer Email:"}
              </p>
              <div className="w-70 ms-3">
                <input
                  disabled={location?.state?.edit === true ? false : true}
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={data?.email}
                />
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                {location?.state?.type === "shipping"
                  ? "Receipt Phone Number:"
                  : "Customer Phone Number:"}
              </p>
              <div className="w-70 ms-3">
                <input
                  disabled={location?.state?.edit === true ? false : true}
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={data?.phoneNo}
                />
              </div>
            </div>
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Organization:
              </p>
              {location?.state?.edit === true && (
                <div className="w-70 ms-3">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={data?.organization}
                  />
                </div>
              )}
              {location?.state?.edit !== true && (
                <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                  {data?.organization}
                </p>
              )}
            </div> */}
            {location?.state?.type === "shipping" ? (
              <>
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Pack:
                  </p>
                  {location?.state?.edit === true && (
                    <div className="w-70 ms-3">
                      <input
                        placeholder=""
                        className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value="25"
                      />
                    </div>
                  )}
                  {location?.state?.edit !== true && (
                    <div className="w-70 ms-3">
                      <input
                        disabled
                        placeholder=""
                        className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value="25"
                      />
                      {/* <select
                  placeholder=""
                  className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>E-Commerce</option>
                  <option>Enterprises</option>
                </select> */}
                    </div>
                  )}
                </div>
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Products Request Count:
                  </p>
                  {location?.state?.edit === true && (
                    <div className="w-70 ms-3">
                      <input
                        placeholder=""
                        className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value="5"
                      />
                    </div>
                  )}
                  {location?.state?.edit !== true && (
                    <div className="w-70 ms-3">
                      <input
                        disabled
                        placeholder=""
                        className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value="5"
                      />
                      {/* <select
                  placeholder=""
                  className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>E-Commerce</option>
                  <option>Enterprises</option>
                </select> */}
                    </div>
                  )}
                  {/* <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.reqCount}
              </p> */}
                </div>
              </>
            ) : (
              <>
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Order ID:
                  </p>
                  <div className="w-70 ms-3">
                    {/* <input
                      disabled
                      placeholder=""
                      className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value="25"
                    /> */}
                    <p className="order-id-link">MNMN3019</p>
                    <p className="order-id-link">MNMN3013</p>
                    <p className="order-id-link">MNMN3014</p>
                    <p className="order-id-link">MNMN3012</p>
                    <p className="order-id-link">MNMN3015</p>
                  </div>
                </div>
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Order Request ID:
                  </p>
                  <div className="w-70 ms-3">
                    {/* <input
                      disabled
                      placeholder=""
                      className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value="5"
                    /> */}
                    <p className="order-id-link">RMHQ001</p>
                    <p className="order-id-link">RMHQ002</p>
                    <p className="order-id-link">RMHQ003</p>
                    <p className="order-id-link">RMHQ004</p>
                    <p className="order-id-link">RMHQ005</p>
                  </div>
                </div>
              </>
            )}
            {location?.state?.type === "shipping" ? null : (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Customer Type:
                </p>
                {location?.state?.edit === true && (
                  <div className="w-70 ms-3">
                    <input
                      placeholder=""
                      className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value="E-Commerce"
                    />
                  </div>
                )}
                {location?.state?.edit !== true && (
                  <div className="w-70 ms-3">
                    <input
                      disabled
                      placeholder=""
                      className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value="E-Commerce"
                    />
                    {/* <select
                  placeholder=""
                  className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>E-Commerce</option>
                  <option>Enterprises</option>
                </select> */}
                  </div>
                )}
              </div>
            )}
            {location?.state?.type === "shipping" && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Company Name:
                </p>
                <div className="w-70 ms-3">
                  <input
                    disabled={location?.state?.edit === true ? false : true}
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value="Ninositsolution"
                  />
                </div>
              </div>
            )}
            {location?.state?.type === "shipping" && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Job Title:
                </p>
                <div className="w-70 ms-3">
                  <input
                    disabled={location?.state?.edit === true ? false : true}
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value="Developer"
                  />
                </div>
              </div>
            )}
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Address:
              </p>
              <div className="w-70 ms-3">
                <textarea
                  disabled={location?.state?.edit === true ? false : true}
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={data?.ContactDetails}
                />
              </div>
            </div>
            {location?.state?.type === "shipping" && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Floor, Suit, Unit (optional):
                </p>
                <div className="w-70 ms-3">
                  <input
                    disabled={location?.state?.edit === true ? false : true}
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value="3rd Floor"
                  />
                </div>
              </div>
            )}
            {location?.state?.type === "shipping" && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  City:
                </p>
                <div className="w-70 ms-3">
                  <input
                    disabled={location?.state?.edit === true ? false : true}
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value="Chennai"
                  />
                </div>
              </div>
            )}
            {location?.state?.type === "shipping" && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  State:
                </p>
                <div className="w-70 ms-3">
                  <select
                    disabled={location?.state?.edit === true ? false : true}
                    className="w-50 cust-btn searchFill fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                    <option>Tamil Nadu</option>
                    <option>Pondichery</option>
                    <option>Kerala</option>
                  </select>
                </div>
              </div>
            )}
            {location?.state?.type === "shipping" && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Pincode:
                </p>
                <div className="w-70 ms-3">
                  <input
                    disabled={location?.state?.edit === true ? false : true}
                    placeholder=""
                    className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value="603219"
                  />
                </div>
              </div>
            )}
            {location?.state?.type === "shipping" && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Country:
                </p>
                <div className="w-70 ms-3">
                  <select
                    disabled={location?.state?.edit === true ? false : true}
                    className="w-50 cust-btn searchFill fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                    <option>India</option>
                    <option>America</option>
                    <option>France</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          {/* {location?.state?.type !== "shipping" && (
            <div className="w-xxl-15 w-xl-15 w-lg-15 position-relative">
              <button
                onClick={() => setAction(!action)}
                className="cust-btn addbtn mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                Actions
              </button>
              {action && (
                <div className="actionDetailPopup searchFieldShade px-2 py-3">
                  <div
                    onClick={() => navigate("/customer-request-list")}
                    className="pointerView ac-jc rounded-3">
                    <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                      Request List
                    </p>
                  </div>
                  <div
                    onClick={() => navigate("/order-list")}
                    className=" pointerView ac-jc mt-2 rounded-3">
                    <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                      Order List
                    </p>
                  </div>
                </div>
              )}
            </div>
          )} */}
        </div>
        <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
            Back
          </button>
          <button className="pointerView cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            {location?.state?.edit === true ? "Update Details" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerViewDetailsScreen;
