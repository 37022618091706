import React from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const CustomerDetails = ({
  list,
  detailEdit,
  handleChange,
  setDetailsEdit,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
      <div className="d-flex flex-wrap mt-4 mx-3 details_tab f2 mb-5">
        <Col lg={6} xl={6} xs={6} className={""}>
          <label>E-Commerce Name</label>
          {/* <p>{list?.name}</p> */}
          <input
            placeholder="Enter E-Commerce Name"
            value={list?.name}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            name="name"
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>E-Commerce ID</label>
          <input
            placeholder="Enter E-Commerce Name"
            value={list?.CustomerId}
            name="E-CommerceID"
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>E-Commerce Email</label>
          <input
            placeholder="Enter E-Commerce Name"
            value={list?.email}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            name="email"
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>E-Commerce Phone Number</label>
          <input
            placeholder="Enter E-Commerce Name"
            value={list?.phoneNo}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            name="phoneNo"
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>E-Commerce Type</label>
          <input
            placeholder="Enter E-Commerce Name"
            value={list?.custType}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            name="custType"
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>Address</label>
          <textarea
            placeholder="Enter Address"
            value={list?.ContactDetails}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            name="ContactDetails"
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>Floor, Suit, Unit (optional)</label>
          <input
            placeholder="Enter Floor, Suit, Unit"
            value={"3rd Floor"}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            // name="email"
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>City</label>
          <input
            placeholder="Enter Floor, Suit, Unit"
            value={"Chennai"}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            // name="email"
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>State</label>
          <input
            placeholder="Enter Floor, Suit, Unit"
            value={"Tamil Nadu"}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            // name="email"
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>Pincode</label>
          <input
            placeholder="Enter Floor, Suit, Unit"
            value={"60278"}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            // name="email"
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col lg={6} xl={6} xs={12}>
          <label>Country</label>
          <input
            placeholder="Enter Floor, Suit, Unit"
            value={"India"}
            className="editBtn w-60 rounded-3 p-2 w-95 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2"
            disabled={detailEdit ? true : false}
            // name="email"
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </div>
      {!detailEdit && (
        <div className="text-end">
          <button className="slide_btns" onClick={() => setDetailsEdit(true)}>
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default CustomerDetails;
