import React from "react";
import { logos } from "../assets/img";
import { banner } from "../assets/img";

const InvoiceClient = () => {
  return (
    <div style={{ backgroundColor: "#e5e5e5",padding:"20px 0px" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div
          className="d-flex mt-3  banner"
          style={{
            backgroundImage:
              "url( https://media.geeksforgeeks.org/wp-content/uploads/rk.png)",
            padding: "20px",
            flexWrap: "wrap",
          }}
        >
          <div className="">
            <img
              alt="img"
              src={logos}
              className=" ps-3"
              style={{ width: "150px", objectFit: "cover" }}
            />
          </div>

          <div className="ps-2">
            <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
              MerchHQ Pvt.Ltd.
            </h2>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
              INVOICE for 5SK
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Shankar cement
            </p>
          </div>
        </div>
        <div
          className="d-flex w-100  mt-4  me-3 p-4"
          style={{
            backgroundColor: "#fff",
            lineHeight: "25px",
            flexWrap:"wrap",
          }}
        >
          <div className="col-md-3 ">
          <div className="common">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              SHIPPPING ADDRESS
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Sakthi Cli 5SK Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
              NADU NINOS125 ,INDIA
            </p>
            </div>
          </div>
          <div className="col-md-3 ">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              SHIPPPING ADDRESS
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Sakthi Cli 5SK Forum Mall Arcot road, Vadapalani, Chennai, TAMIL
              NADU NINOS125 ,INDIA
            </p>
          </div>
          {/* <div className="ryt-line"
            style={{
              height: "150px",
              width: "1px",
              backgroundColor: "#000",
            }}
          ></div> */}
          <div className="col-md-3 ps-2 ">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              AMOUNT DUE
            </p>
            <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
              Rs:8,500796.30 INR
            </h2>

            <div class="d-flex mt-4 gap-3">
              <div>
                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  DATE
                </p>
                <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Feb 4,2024
                </p>
              </div>
              <div>
                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  TERMS
                </p>
                <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Purchase Order
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 ">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              PROJECT#
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              210234
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              INVOICE#
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              220393
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CUSTOMER PO
            </p>
          </div>
        </div>
        <div
          class=" p-4 mt-4 overflow-scroll w-100"
          style={{ backgroundColor: "#fff" }}
        >
          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18">
            Summary
          </p>
          <table className="w-100 ">
            <thead>
              <tr>
                <th className="f3 col-md-8  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                  ITEM
                </th>
                <th>
                  <p className=" col-md-1 f3 col-md-8  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap ">
                    QTY
                  </p>
                </th>
                <th>
                  <p className=" col-md-1 f3 col-md-8  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-3">
                    UNIT COST
                  </p>
                </th>
                <th>
                  <p className=" col-md-1 f3 col-md-8  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap">
                    TOTAL COST
                  </p>
                </th>
              </tr>
            </thead>
            <tbody style={{ lineHeight: "30px" }}>
              <tr>
                <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                  Stainless Steel And hot N Cold bottle
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    300
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                    10
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    0
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS25,765.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS25,765.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS254,5.00
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,987,9879.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,3479.00
                  </p>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS6,4569.00
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Fixed pre Charges
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Run Charges
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    310
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Fixed Charges
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Setup Cost-top
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Setup Cost-
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    1
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS15.15
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS15.15
                  </p>
                </td>
              </tr>
              <tr
                className=""
                style={{
                  borderBottom: "1px",
                  borderTop: "1px",
                  borderRight: "0",
                  borderColor: "#787b7f",
                  borderStyle: "solid",
                  borderLeft: 0,
                }}
              >
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    Navy BLUE PLAY DRY T-SHIRTS WITH WHITE TIPPING.
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    400
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                    RS780.83
                  </p>
                </td>
                <td>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                    RS348,332.00
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex mt-4  ac-jb" style={{ flexWrap: "wrap" }}>
          <div className="col-md-4 " style={{ lineHeight: "25px" }}>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Questions About this estimate?
            </p>
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              merchHQ Pvt.Ltd.
            </p>
            <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
              Kartnataka, India.
            </p>
          </div>
          <div
            className="d-flex ac-jc col-md-8 justify-content-around white"
            style={{
              backgroundColor: "#787b7f",
              padding: "20px",
              margin: "20px 0px 10px",
              lineHeight: "25px",
            }}
          >
            <div>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                SUBTOTAL
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TAX-CGST 6:
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TOTAL
              </p>
            </div>
            <div>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Rs9,8767,8765.15
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Rs9,5467,865.15
              </p>
              <hr style={{ margin: 0 }} />
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                RS8,500,768.30
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceClient;
