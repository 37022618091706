import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, TOKEN, URL } from "./constants";
import { getToken } from "../store/userTocken";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      // const token = getToken();
      const token = localStorage.getItem(TOKEN);
      headers.set("Authorization", "Bearer " + token);
      // headers.set("Content-Type", "multipart/form-data");
      // headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        body: payload,
        method: "POST",
      }),
    }),

    // LOGOUT
    logout: builder.query({
      query: () => URL.LOGOUT,
    }),

    // CHANGE PASSWORD
    changePassword: builder.mutation({
      query: (payload) => ({
        url: URL.CHANGE_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    // SET PASSWORD
    setPassword: builder.mutation({
      query: (payload) => ({
        url: URL.SET_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    // FORGOT PASSWORD
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: URL.FORGOT_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    // TOKEN VERIFY
    tokenVerify: builder.query({
      query: (data) => URL.TOKEN_VERIFY + data,
    }),

    // ROLE
    roleCreate: builder.query({
      query: () => URL.ROLE + "/create",
    }),

    // ROLE ADD
    roleStore: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE,
        body: payload,
        method: "POST",
      }),
    }),

    // ROLE EDIT
    roleEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ROLE + "/" + id,
        body: payload,
        method: "POST",
      }),
    }),

    // ROLE VIEW
    roleView: builder.query({
      query: (id) => URL.ROLE + "/" + id,
    }),

    // ROLE VIEW WITH TYPE
    roleViewWithType: builder.query({
      query: ({ id, payload }) => ({
        url: URL.ROLE + "/" + id + "/edit",
        params: payload,
        method: "GET",
      }),
    }),

    // DEPARTMENT ACTIVE ROLE
    departmentActiveRoleList: builder.query({
      query: (payload) => ({
        url: URL.DEPARTMENT_ACTIVE_ROLE,
        body: payload,
        method: "POST",
      }),
    }),
    // ROLE ALL
    roles: builder.query({
      query: () => URL.ROLE,
    }),

    // ROLE ALL WITH
    roleList: builder.mutation({
      query: ({ params, payload }) => ({
        url: URL.ROLES + "/lists" + params,
        body: payload,
        method: "POST",
      }),
    }),
    // ROLE STATUS
    roleStatus: builder.query({
      query: (id) => URL.ROLE + "/status/" + id,
    }),

    // ACTIVE ROLE
    activeRoles: builder.query({
      query: (id) => URL.ACTIVE_ROLES,
    }),

    // ADMIN ADD
    adminAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN,
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN EDIT
    adminEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ADMIN + "/" + id + "/update",
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN VIEW
    adminView: builder.query({
      query: (id) => URL.ADMIN + "/" + id,
    }),

    // ADMIN ALL
    Admins: builder.query({
      query: () => URL.ADMIN,
    }),

    // ADMIN ALL WITH SEARCH
    AdminsList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.ADMIN + "/lists" + params,
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN STATUS
    adminStatus: builder.query({
      query: (id) => URL.ADMIN + "/status/" + id,
    }),

    // ADMIN VIEW WITH ID
    adminViewId: builder.query({
      query: ({ id, payload }) => ({
        url: URL.ADMIN + "/" + id + "/edit",
        params: payload,
        method: "GET",
      }),
    }),

    // CATEGORY ADD
    categoryAdd: builder.mutation({
      query: (payload) => ({
        url: URL.CATEGORY_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // CATEGORY EDIT
    categoryEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.CATEGORY_UPDATE + "/" + id,
        body: payload,
        method: "POST",
      }),
    }),

    // CATEGORY VIEW
    categoryView: builder.query({
      query: (id) => URL.CATEGORY_VIEW + "/" + id,
    }),

    // CATEGORY ALL
    categories: builder.query({
      query: () => URL.CATEGORY,
    }),

    categoryLists: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CATEGORY_LIST + params,
        body: payload,
        method: "POST",
      }),
    }),

    // CATEGORY STATUS
    categoryStatus: builder.query({
      query: (id) => URL.CATEGORY_STATUS + "/" + id,
    }),

    // MASTER LIST
    masterList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.MASTER_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // MASTER ADD
    masterAdd: builder.mutation({
      query: (payload) => ({
        url: URL.MASTER,
        method: "POST",
        body: payload,
      }),
    }),

    // MASTER EDIT
    masterEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.MASTER + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // MASTER VIEW
    masterView: builder.query({
      query: (id) => URL.MASTER + "/" + id,
    }),

    // MASTER STATUS
    masterStatus: builder.query({
      query: (id) => URL.MASTER + "/status/" + id,
    }),

    // MASTER ACTIVE LIST
    masterActiveList: builder.mutation({
      query: (payload) => ({
        url: URL.MASTER_ACTIVE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // CATERGORY LIST VENDOR
    categoriesListVendor: builder.query({
      query: () => URL.VENDOR_PARENT_CATEGORIES,
    }),

    // VENDOR ADD
    vendorRegister: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_REGISTER,
        method: "POST",
        body: payload,
      }),
    }),

    // DEPARTMENT ROLE ALL WITH SEARCH
    departmentRole: builder.mutation({
      query: ({ payload, params, id }) => ({
        url: URL.DEPARTMENT_ROLE + id + params,
        body: payload,
        method: "POST",
      }),
    }),

    // TAGS LIST
    tagsList: builder.query({
      query: () => URL.TAGS,
    }),

    // TAGS CREATE
    tagsCreate: builder.mutation({
      query: (payload) => ({
        url: URL.TAGS,
        method: "POST",
        body: payload,
      }),
    }),

    // TAGS EDIT
    tagsEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.TAG + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // TAGS STATUS
    tagsStatus: builder.query({
      query: (id) => URL.TAGS_STATUS + id,
    }),

    // TAGS VIEW
    tagsView: builder.query({
      query: (id) => URL.TAGS + "/" + id + "/edit",
    }),

    // TAGS LIST WITH SEARCH
    tagsLists: builder.mutation({
      query: (payload) => ({
        url: URL.TAGS_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // NAVIGATION LIST
    navigationList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.NAVIGATION + "/lists" + params,
        method: "POST",
        body: payload,
      }),
    }),

    // NAVIGATION CREATE
    navigationCreate: builder.mutation({
      query: (payload) => ({
        url: URL.NAVIGATIONS,
        method: "POST",
        body: payload,
      }),
    }),

    // NAVIGATION EDIT
    navigationEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.NAVIGATION + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // NAVIGATION VIEW
    navigationView: builder.query({
      query: (id) => URL.NAVIGATIONS + "/" + id + "/edit",
    }),

    // NAVIGATION VIEW 2
    navigationView2: builder.query({
      query: (id) => URL.NAVIGATIONS + "/" + id,
    }),

    // NAVIGATION STATUS
    navigationStatus: builder.query({
      query: (id) => URL.NAVIGATION + "/status/" + id,
    }),

    // HSN LIST
    hsnList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.HSNS + params,
        method: "POST",
        body: payload,
      }),
    }),

    // HSN CREATE
    hsnCreate: builder.mutation({
      query: (payload) => ({
        url: URL.HSN,
        method: "POST",
        body: payload,
      }),
    }),

    // HSN EDIT
    hsnEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.HSN + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // HSN STATUS
    hsnStatus: builder.query({
      query: (id) => URL.HSN + "-status/" + id,
    }),

    // HSN VIEW
    hsnView: builder.query({
      query: (id) => URL.HSN + "/" + id,
    }),

    // ADMIN PROFILE EXPORTS
    adminExports: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN_EXPORTS,
        method: "POST",
        body: payload,
      }),
    }),

    // CURRENCY LIST
    currencyList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CURRENCYS + params,
        method: "POST",
        body: payload,
      }),
    }),

    // CURRENCY CREATE
    currencyCreate: builder.mutation({
      query: (payload) => ({
        url: URL.CURRENCY,
        method: "POST",
        body: payload,
      }),
    }),

    // CURRENCY EDIT
    currencyEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.CURRENCY + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // CURRENCY STATUS
    currencyStatus: builder.query({
      query: (id) => URL.CURRENCY + "-status/" + id,
    }),

    // CURRENCY VIEW
    currencyView: builder.query({
      query: (id) => URL.CURRENCY + "/" + id,
    }),

    // CURRENCY ACTIVE LIST
    currencyActiveList: builder.query({
      query: () => URL.CURRENCY_ACTIVE,
    }),

    // COLORS LIST
    colorList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.COLORS + params,
        method: "POST",
        body: payload,
      }),
    }),

    // COLORS CREATE
    colorCreate: builder.mutation({
      query: (payload) => ({
        url: URL.COLOR,
        method: "POST",
        body: payload,
      }),
    }),

    // COLORS EDIT
    colorEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.COLOR + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // COLORS STATUS
    colorStatus: builder.query({
      query: (id) => URL.COLOR + "-status/" + id,
    }),

    // COLORS VIEW
    colorView: builder.query({
      query: (id) => URL.COLOR + "/" + id,
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyLogoutQuery,
  useChangePasswordMutation,
  useSetPasswordMutation,
  useLazyTokenVerifyQuery,
  useRoleCreateQuery,
  useRoleEditMutation,
  useRoleStoreMutation,
  useLazyRoleStatusQuery,
  useLazyRoleViewQuery,
  useLazyRolesQuery,
  useActiveRolesQuery,
  useLazyRoleViewWithTypeQuery,
  useLazyAdminsQuery,
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminViewQuery,
  useLazyAdminStatusQuery,
  useLazyAdminViewIdQuery,
  useLazyCategoriesQuery,
  useCategoryAddMutation,
  useCategoryEditMutation,
  useLazyCategoryStatusQuery,
  useLazyCategoryViewQuery,
  useMasterAddMutation,
  useMasterEditMutation,
  useLazyMasterStatusQuery,
  useLazyMasterViewQuery,
  useMasterListMutation,
  useMasterActiveListMutation,
  useForgotPasswordMutation,
  useAdminsListMutation,
  useRoleListMutation,
  useLazyCategoriesListVendorQuery,
  useVendorRegisterMutation,
  useDepartmentRoleMutation,
  useLazyDepartmentActiveRoleListQuery,
  useCategoryListsMutation,
  useLazyTagsListQuery,
  useTagsCreateMutation,
  useLazyTagsStatusQuery,
  useTagsEditMutation,
  useLazyTagsViewQuery,
  useTagsListsMutation,
  useNavigationListMutation,
  useNavigationCreateMutation,
  useNavigationEditMutation,
  useLazyNavigationViewQuery,
  useLazyNavigationStatusQuery,
  useLazyNavigationView2Query,
  useHsnListMutation,
  useHsnCreateMutation,
  useHsnEditMutation,
  useLazyHsnViewQuery,
  useLazyHsnStatusQuery,
  useAdminExportsMutation,
  useCurrencyCreateMutation,
  useCurrencyEditMutation,
  useLazyCurrencyStatusQuery,
  useLazyCurrencyViewQuery,
  useCurrencyListMutation,
  useLazyCurrencyActiveListQuery,
  useColorCreateMutation,
  useColorEditMutation,
  useColorListMutation,
  useLazyColorStatusQuery,
  useLazyColorViewQuery,
} = api;
