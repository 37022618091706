export const logos = require("./owl-logo2.png");

export const banner = require("./Invoice-client-banner.jpg");
export const head = require("./Invoice-head.jpg");
export const tshirt1 = require("./tshirt-1.jpg");
export const tshirt3 = require("./tshirt-3.jpg");
export const tshirt2 = require("./tshirt-2.jpg");
export const art1 = require("./download.jpeg");
export const art2 = require("./4-3.jpg");

export const searchIcon = require("./bx-search-alt.png");
export const editIcon = require("./icons/edit.png");
export const viewIcon = require("./icons/Vector.png");
export const toggleOn = require("./icons/toggle_on.png");
export const toggleOff = require("./icons/toggle_off.png");
export const animatedImg = require("./cd8c65c7813855d5f58cf91801b1d256.jpg");
export const emptyImg = require("./empty_vendor.png");
export const uploadIcon = require("./upload_icon.png");
export const product = require("./product_detail_img.png");
export const uploadedImg = require("./sales-order-view-img.png");

export const profilePic = require("./admin_panel_icons/vendor_img.jpg");
export const empty_profile = require("./admin_panel_icons/empty_profile_img.jpg");
export const mailSendImg = require("./admin_panel_icons/mail_sent.png");
export const vendorSuccessImg = require("./admin_panel_icons/vendor_added.png");
export const vendorBannerImg = require("./In-the-News-02.png");
export const packImg = require("./multiple_img.png");
export const danger = require("./danger.png");
export const danger_sm = require("./danger_sm_icon.png");
export const Support = require("./Support.png");
export const Mail = require("./Mail.png");
export const Star = require("./Star.png");
export const profile = require("./avatar.png");
export const Empty = require("./empty_vendor.png");
export const Tick = require("./Tick.png");
export const MainLogo = require("./main-logo.png");
export const Bottle = require("./Bottle.jpg");
export const delivery = require("./shipping_delivery.png");
export const noLogo = require("./Group 51.png");
