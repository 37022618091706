import React, { useEffect, useState } from "react";
import {
  editIcon,
  searchIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../../assets/img";
import {
  decorativeMethodList,
  hsnListData,
  pricebookList,
} from "../../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import AddHsnPopup from "../Popup/AddHsnPopup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";

const PriceBookListProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [selectBox, setSelectBox] = useState([]);

  const toggleShowPopup = (type) => {
    setShowPopup(!showPopup);
    if (type) setPopupType(type);
  };

  let options = [
    { value: "all", label: "All" },
    { value: "amazon", label: "Amazon" },
    { value: "ab prime", label: "Ab Prime" },
    { value: "kti promos", label: "KTI  Promos" },
  ];

  return (
    <>
      <div className="dashRightView p-5 home_section trans">
        <div className="d-flex ac-jb mt-2 flex-wrap w-100">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Pricebook List
          </p>
        </div>
        <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
          </div>
          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3">
                Total Count : {"05"}
              </p>
              <div className="d-flex  border-search">
                <img src={searchIcon} alt="img" className="searchiConImg" />
                <input
                  className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                  placeholder="Search"
                />
              </div>
              <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
                <Search />
              </button>
              <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
                <CachedIcon />
              </button>
              <button
                onClick={() =>
                  navigate("/pricebook-library", {
                    state: { method: location?.state?.method, type: "add" },
                  })
                }
                className="cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                + Add Pricebook
              </button>
            </div>
          </div>
        </div>
        <div className="mt-3" style={{ width: "100%", overflow: "auto" }}>
          <table className="table-design-new" style={{ width: "100%" }}>
            <thead>
              <tr className="border-bottom w-100">
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    S.No
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Pricebook Name
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Enterprises Name
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Pricebook Margin
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Status
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Options
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {pricebookList?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {index + 1}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {item?.pricebook_name}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {item?.enterprises_name}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {item?.pricebook_margin}
                      </p>
                    </td>
                    <td>
                      <div className="d-flex ac-jc">
                        {item?.status === 1 && (
                          <div>
                            <img src={toggleOn} className="toggleOnDes" />
                          </div>
                        )}
                        {item?.status === 0 && (
                          <div>
                            <img src={toggleOff} className="toggleOnDes" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex ac-jc primary1">
                        <div
                          className="viewBoxDes pointerView p-2 bg-white rounded-2"
                          onClick={() =>
                            navigate(`/price-book-library`, {
                              state: {
                                method: location?.state?.method,
                                data: item,
                                type: "view",
                              },
                            })
                          }
                        >
                          <img src={viewIcon} className="viewDes" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-between mt-3">
          <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Previous
          </button>
          <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default PriceBookListProduct;
