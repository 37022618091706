import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  sub_menu,
  Product_Name,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  catogory_setting,
  counting,
  line_business,
  static_attributes,
  dynamic_attributes,
  colors,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Select from "react-select";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";

const FormComp = ({ vendor, selColors, setSelColors }) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  // Toggle List
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(false);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);
  const [hsnTypeShow, setHsnTypeShow] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [uomShow, setuomShow] = useState(false);
  const [isCountShow, setISCountShow] = useState(false);
  const [decorMethodShow, setDecorMethodShow] = useState(false);

  //

  const [subcatogory, setSubcatogory] = useState(false);
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);

  const [Dropdown, setDrop] = useState(0);

  // input box
  const [catogory, setCatogory] = useState("");
  const [catogory2, setCatogory2] = useState("");
  const [catogory3, setCatogory3] = useState("");
  const [productName, setProductName] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [decorativeMethod, setDecorativeMethod] = useState("");
  const [hsnType, sethsnType] = useState("");
  const [packingSize, setPackingSize] = useState("");
  const [country, setCountry] = useState("");
  const [uomCont, setuomCont] = useState("");
  const [ISCount, setISCount] = useState("");
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [color3, setColor3] = useState("");
  const [productLink, setProductLink] = useState("");
  const [statAttributes, setStatAttributes] = useState([]);
  const [showStatAttributes, setShowStatAttributes] = useState(false);
  const [selectBox, setSelectBox] = useState([]);
  // const [selColors, setSelColors] = useState([]);
  const [showColors, setShowColors] = useState(false);

  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [showDynamicAttr, setShowDynamicAttr] = useState(false);
  const [showVarient, setShowVarient] = useState(false);

  // Select Fuctction

  const [selectCondtion, setSelectCondtion] = useState("");

  const [check, setCheck] = useState([]);

  let options = [
    { value: "Bottle", label: "Bottle" },
    { value: "Shirts", label: "Shirts" },
    { value: "T-Shirts", label: "T-Shirts" },
  ];

  const onTagInputChange = (e) => {
    let tmp = {
      value: e,
      label: e,
      type: "input",
    };
    let filtered = options?.filter((item) => item?.type === "input");
    if (filtered?.length === 0) {
      options.push(tmp);
    } else {
      options.pop();
      options.push(tmp);
    }
  };

  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 1) {
      setCatogoryShow(true);
      setProductNameShow(false);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };
  const onCatogory2 = (e) => {
    setCatogory2(e.target.value);
    if (catogory2.length >= 1) {
      setCatogoryShow2(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onCatogory3 = (e) => {
    setCatogory3(e.target.value);
    if (catogory3.length >= 1) {
      setCatogoryShow3(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onProduct = (e) => {
    setProductName(e.target.value);
    if (productName.length >= 1) {
      setProductNameShow(true);
    } else {
      if (productName.length >= 0) setProductNameShow(false);
      setProductSuplierCode("");
    }
    setCatogoryShow(false);
  };
  const onDecorative = (e) => {
    setDecorativeMethod(e.target.value);
    if (decorativeMethod.length >= 1) {
      setDecorativeShow(true);
    } else {
      if (decorativeMethod.length >= 0) setDecorativeShow(false);
    }
  };
  const onHsnTyoe = (e) => {
    sethsnType(e.target.value);
    if (hsnType.length >= 1) {
      setHsnTypeShow(true);
    } else {
      if (hsnType.length >= 0) setHsnTypeShow(false);
    }
  };
  const onPackingSize = (e) => {
    setPackingSize(e.target.value);
    if (packingSize.length >= 1) {
      setPackingSizeShow(true);
    } else {
      if (packingSize.length >= 0) setPackingSizeShow(false);
    }
  };
  const onCountry = (e) => {
    setCountry(e.target.value);
    if (country.length >= 2) {
      setCountryShow(true);
    } else {
      if (country.length >= 0) setCountryShow(false);
    }
  };
  const onUom = (e) => {
    setuomCont(e.target.value);
    if (uomCont.length >= 1) {
      setuomShow(true);
    } else {
      if (uomCont.length >= 0) setuomShow(false);
    }
  };
  const onISCount = (e) => {
    setISCount(e.target.value);
    if (ISCount.length >= 1) {
      setISCountShow(true);
    } else {
      if (ISCount.length >= 0) setISCountShow(false);
    }
  };
  const checkBox = (ind) => {
    if (check.includes(ind)) {
      const indtemp = check.indexOf(ind);
      const temp = [...check];
      temp.splice(1, indtemp);
      setCheck(temp);
    } else {
      setCheck([...check, ind]);
    }
    console.log(check);
  };

  const onStatAttributeSelect = (e) => {
    if (statAttributes.includes(e)) {
      const listtemp = statAttributes.indexOf(e);
      const list = [...statAttributes];
      list.splice(listtemp, 1);
      setStatAttributes(list);
    } else {
      setStatAttributes([...statAttributes, e]);
    }
  };

  const onDynamicAttributeSelect = (e) => {
    if (dynamicAttr.includes(e)) {
      const listtemp = dynamicAttr.indexOf(e);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, e]);
    }
  };

  const onColorSelect = (e) => {
    if (selColors.includes(e)) {
      const listtemp = selColors.indexOf(e);
      const list = [...selColors];
      list.splice(listtemp, 1);
      setSelColors(list);
    } else {
      setSelColors([...selColors, e]);
    }
  };

  const onClickShowVarient = () => {
    setShowVarient(!showVarient);
  };

  return (
    <div className="w-100">
      <div className="d-flex flex-wrap ac-jb flex-m-r">
        {(vendor == "Charles" || vendor == "Jake Adams") && (
          <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Category
            </p>
            <div className="position-relative z-3">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={catogory}
                onChange={onCatogory}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCatogoryShow(!catogoryShow);
                  setChildMenu(false);

                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {catogoryShow && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setCatogoryShow(!catogoryShow)}
              />
            )}
            <div
              className={`${
                catogoryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setCatogoryShow(false);
                        setCatogory(item?.list);
                        setChildMenu(false);
                        setSubcatogory(true);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
            <div className={childMenu ? "submenu_child_1" : "submenu_child_2"}>
              <div
                className={`${
                  catogoryShow && "submenu_2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      {item.list !== "Game consoles" && (
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setCatogoryShow(false);
                            setCatogory(item?.list);
                            setChildMenu(false);
                          }}
                        >
                          {item?.list}
                        </button>
                      )}
                      {item.list === "Game consoles" && (
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => setChildMenu(!childMenu)}
                        >
                          {item?.list}
                        </button>
                      )}
                      {item.list === "Game consoles" && (
                        <button
                          className="cust-btn position-relative"
                          onClick={() => {
                            setChildMenu(!childMenu);
                            setProductNameShow(false);
                            setCountryShow(false);
                            setHsnTypeShow(false);
                            setPackingSizeShow(false);
                          }}
                        >
                          {childMenu ? (
                            <KeyboardArrowRightIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {subcatogory && (
          <>
            {" "}
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category 1
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={catogory2}
                  onChange={onCatogory2}
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setCatogoryShow2(!catogoryShow2);
                    setCatogoryShow(false);
                    setCatogoryShow3(false);
                    setProductNameShow(false);
                    setHsnTypeShow(false);
                    setPackingSizeShow(false);
                    setCountryShow(false);
                    setuomShow(false);
                    setISCountShow(false);
                    setDecorativeShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              </div>
              {catogoryShow2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setCatogoryShow2(!catogoryShow2)}
                />
              )}
              <div
                className={`${
                  catogoryShow2 && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setCatogoryShow2(false);
                          setCatogory2(item?.list);
                          setSubcatogory2(true);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category Code
              </p>
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            </div>
          </>
        )}
        {subcatogory2 && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Sub Category 2
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={catogory3}
                onChange={onCatogory3}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCatogoryShow3(!catogoryShow3);
                  setChildMenu(false);

                  setCatogoryShow(false);
                  setCatogoryShow2(false);

                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {catogoryShow3 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setCatogoryShow3(!catogoryShow3)}
              />
            )}
            <div
              className={`${
                catogoryShow3 && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setCatogoryShow3(false);
                        setCatogory3(item?.list);
                        setChildMenu(false);
                        setSubcatogory3(true);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {vendor !== "Charles" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Decoration method
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={decorativeMethod}
                onChange={onDecorative}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDecorativeShow(!decorativeShow);

                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {decorativeShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDecorativeShow(!decorativeShow)}
                />
              )}
              <div
                className={`${
                  decorativeShow && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item, ind) => {
                  return (
                    <div className="d-flex as-jb hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          setDecorativeMethod(item?.list);
                          checkBox(ind);
                        }}
                      >
                        {item?.list}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        onClick={() => {
                          setDecorativeMethod(item?.list);
                          checkBox(ind);
                        }}
                      >
                        {check.includes(ind) ? item?.check : item.uncheck}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {vendor !== "Charles" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              UOM
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={uomCont}
                onChange={onUom}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setuomShow(!uomShow);

                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);

                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {uomShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setuomShow(!uomShow)}
                />
              )}
              <div
                className={`${
                  uomShow && "submenu_1 h-auto"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {catogory_setting?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setuomShow(false);
                          setuomCont(item?.catogory);
                          item?.catogory === "Inches" &&
                            setSelectCondtion("Inches");
                          item?.catogory === "Stitch" &&
                            setSelectCondtion("Stitch");
                        }}
                      >
                        {item?.catogory}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {vendor !== "John Rolph" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Name
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={productName}
                onChange={onProduct}
              />
              {productNameShow && (
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setProductNameShow(!productNameShow);
                    setProductSuplierCode("code1003");
                    setCatogoryShow(false);
                    setCatogoryShow2(false);
                    setCatogoryShow3(false);

                    setHsnTypeShow(false);
                    setPackingSizeShow(false);
                    setCountryShow(false);
                    setuomShow(false);
                    setISCountShow(false);
                    setDecorativeShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
            </div>
            {productNameShow && (
              <div
                className="invisible-cont2"
                onClick={() => setProductNameShow(!productNameShow)}
              />
            )}
            <div
              className={`${
                productNameShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setProductNameShow(false);
                        setProductName(item?.list);
                        setProductSuplierCode("code1003");
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* {vendor !== 'Charles' &&
                    <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                        {selectCondtion === '' && <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                            Count
                        </p>}
                        {selectCondtion !== '' && <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                            Count of {selectCondtion}
                        </p>}
                        <div className='position-relative'>
                            <input placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={ISCount}
                                onChange={onISCount}
                                type='number'
                                disabled={uomCont.length === 0 ? true : false}
                            />
                        </div>
                    </div>
                } */}
        {/* {vendor !== "John Rolph" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Supplier Code
            </p>
            <input
              disabled
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={productSuplierCode}
              onChange={(e) => {
                setProductSuplierCode(e.target.value);
              }}
              // disabled
            />
          </div>
        )} */}
        {vendor !== "John Rolph" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              MRP
            </p>
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}
        {vendor !== "John Rolph" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Decoration method
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={decorativeMethod}
                onChange={onDecorative}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDecorativeShow(!decorativeShow);

                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {decorativeShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDecorativeShow(!decorativeShow)}
                />
              )}
              <div
                className={`${
                  decorativeShow && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item, ind) => {
                  return (
                    <div className="d-flex as-jb hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          setDecorativeMethod(item?.list);
                          checkBox(ind);
                        }}
                      >
                        {item?.list}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        onClick={() => {
                          setDecorativeMethod(item?.list);
                          checkBox(ind);
                        }}
                      >
                        {check.includes(ind) ? item?.check : item.uncheck}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {/* {vendor !== 'Charles' &&
                    <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex gap-3">
                        <div className='w-100'>
                            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                                Color 1 Rate
                            </p>
                            <div className='position-relative d-flex'>
                                <input placeholder="Required Field"
                                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
                                    value={color1}
                                    onChange={(e) => setColor1(e.target.value)}
                                    type='number'
                                    disabled={uomCont.length === 0 ? true : false}
                                />
                            </div>
                        </div>

                    </div>
                }
                {vendor !== 'Charles' &&
                    <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex gap-3">
                        <div className='w-100'>
                            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                                Color 2 Rate
                            </p>
                            <div className='position-relative d-flex'>
                                <input placeholder="Required Field"
                                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
                                    value={color2}
                                    onChange={(e) => setColor2(e.target.value)}
                                    type='number'
                                    disabled={uomCont.length === 0 ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                }
                {vendor !== 'Charles' &&
                    <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex gap-3">
                        <div className='w-100'>
                            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                                Color 3 Rate
                            </p>
                            <div className='position-relative d-flex'>
                                <input placeholder="Required Field"
                                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
                                    value={color3}
                                    onChange={(e) => setColor3(e.target.value)}
                                    type='number'
                                    disabled={uomCont.length === 0 ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                } */}
        {vendor !== "John Rolph" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Weight
            </p>
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}
        {vendor !== "John Rolph" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Design features
            </p>
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}
        {vendor !== "John Rolph" && (
          <div
            className={`${
              vendor !== "Charles"
                ? "w-100 position-relative"
                : "w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative"
            } `}
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Country of Origin
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={country}
                onChange={onCountry}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCountryShow(!countryShow);

                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setPackingSizeShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {countryShow && (
              <div
                className="invisible-cont2"
                onClick={() => setCountryShow(!countryShow)}
              />
            )}
            <div
              className={`${
                countryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setCountryShow(false);
                        setCountry(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {(vendor === "Charles" || vendor === "Jake Adams") && (
          <div className="w-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Product Description
            </p>
            <textarea
              placeholder="Autogenerate"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}
        {(vendor === "John Rolph" || vendor === "Jake Adams") && (
          <div className="w-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Decoration Description
            </p>
            <textarea
              placeholder="Autogenerate"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}
        {vendor !== "John Rolph" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              HSN Code
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={hsnType}
                onChange={onHsnTyoe}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setHsnTypeShow(!hsnTypeShow);
                  setChildMenu2(false);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {hsnTypeShow && (
              <div
                className="invisible-cont2"
                onClick={() => setHsnTypeShow(!hsnTypeShow)}
              />
            )}
            <div
              className={`${
                hsnTypeShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {Hsn_type?.map((item, ind) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      // onClick={() => {
                      //   Dropdown === ind
                      //     ? setChildMenu2(!childMenu2)
                      //     : setDrop(ind);
                      // }}
                      onClick={() => {
                        setHsnTypeShow(false);
                        sethsnType(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>

                    {/* {ind === childM/enu3 && */}
                    {/* <button
                      className="cust-btn position-relative"
                      onClick={() => {
                        Dropdown === ind
                          ? setChildMenu2(!childMenu2) && setDrop(ind)
                          : setDrop(ind);
                      }}>
                      <KeyboardArrowRightIcon />
                    </button> */}
                  </div>
                );
              })}
            </div>
            {Dropdown === 0 && (
              <div
                className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}
              >
                <div
                  className={`${
                    hsnTypeShow && "submenu_2 h-auto sub-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {Hsn_type_Child2?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setHsnTypeShow(false);
                            sethsnType(item?.list);
                            setChildMenu2(false);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {Dropdown === 1 && (
              <div
                className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}
              >
                <div
                  className={`${
                    hsnTypeShow && "submenu_2 h-auto sub-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {Hsn_type_Child3?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setHsnTypeShow(false);
                            sethsnType(item?.list);
                            setChildMenu2(false);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {Dropdown === 2 && (
              <div
                className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}
              >
                <div
                  className={`${
                    hsnTypeShow && "submenu_2 h-auto sub-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {Hsn_type_Child4?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setHsnTypeShow(false);
                            sethsnType(item?.list);
                            setChildMenu2(false);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Tags
          </p>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#07679c",
                borderRadius: "8px",
              }),
            }}
            onInputChange={onTagInputChange}
            options={options}
            isMulti
            classNamePrefix=""
            className="w-100  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2"
            onChange={(e) => setSelectBox(e)}
          />
        </div>
        {vendor !== "John Rolph" && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Packaging Size
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={packingSize}
                onChange={onPackingSize}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setPackingSizeShow(!PackingSizeShow);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setHsnTypeShow(false);
                  setCountryShow(false);
                  setuomShow(false);
                  setISCountShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {PackingSizeShow && (
              <div
                className="invisible-cont2"
                onClick={() => setPackingSizeShow(!PackingSizeShow)}
              />
            )}
            <div
              className={`${
                PackingSizeShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setPackingSizeShow(false);
                        setPackingSize(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* STATIC ATTRIBUTES */}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Add Variants
          </p>
          <div className="position-relative">
            <input
              disabled
              placeholder="Select Attributes"
              type="text"
              value={statAttributes}
              onClick={() => {}}
              className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
            <button
              className="drop_down cust-btn w-100 justify-content-end"
              onClick={() => {
                setShowStatAttributes(true);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {showStatAttributes && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setShowStatAttributes(false)}
              />
            )}
            <div
              className={`${
                showStatAttributes && "submenu_1 h-auto py-2"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {static_attributes?.map((item, ind) => {
                return (
                  <button
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    onClick={() => {
                      onStatAttributeSelect(item?.list);
                    }}
                  >
                    <button className="px-2 cust-btn text-start py-1 w-100 ">
                      {item?.list}
                    </button>
                    <button className="px-2 cust-btn text-start">
                      {statAttributes.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {statAttributes?.includes("Size") && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Size
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              <p
                role="button"
                onClick={onClickShowVarient}
                style={{ right: 10, bottom: 10 }}
                className="position-absolute f3 primary"
              >
                {showVarient ? "+ Add New" : "+ Add"}
              </p>
            </div>
          </div>
        )}
        {statAttributes?.includes("Color") && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Color
            </p>
            {/* <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            /> */}
            <div className="position-relative">
              <input
                disabled
                placeholder="Select Attributes"
                type="text"
                value={selColors}
                onClick={() => {}}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn w-100 justify-content-end"
                onClick={() => {
                  setShowColors(true);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {showColors && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setShowColors(false)}
                />
              )}
              <div
                className={`${
                  showColors && "submenu_1 h-auto py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {colors?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onColorSelect(item?.color);
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.color}
                      </button>
                      <button className="px-2 cust-btn text-start">
                        {selColors.includes(item?.color)
                          ? item?.check
                          : item.uncheck}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {statAttributes?.includes("Material") && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Material
            </p>
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}

        {/* DYNAMIC ATTRIBUTES */}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Other Product Information
          </p>
          <div className="position-relative">
            <input
              disabled
              placeholder="Select Attributes"
              type="text"
              value={dynamicAttr}
              onClick={() => {}}
              className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
            <button
              className="drop_down cust-btn w-100 justify-content-end"
              onClick={() => {
                setShowDynamicAttr(true);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {showDynamicAttr && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setShowDynamicAttr(false)}
              />
            )}
            <div
              className={`${
                showDynamicAttr && "submenu_1 h-auto py-2"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {dynamic_attributes?.map((item, ind) => {
                return (
                  <button
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    onClick={() => {
                      onDynamicAttributeSelect(item?.list);
                    }}
                  >
                    <button className="px-2 cust-btn text-start py-1 w-100 ">
                      {item?.list}
                    </button>
                    <button className="px-2 cust-btn text-start">
                      {dynamicAttr.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        {dynamicAttr?.includes("Dimension") && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Dimension
            </p>
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}
        {dynamicAttr?.includes("Weight") && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Weight
            </p>
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}
        {/* ----- */}
        {showVarient && (
          <div className="w-100 overflow-scroll mt-4">
            <table className="w-100">
              <tr className="mt-4">
                <div className="">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <p
                          class=" f2 fs-xs-11 fs-sm-12 cp fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "50px" }}
                        >
                          {/* <CheckBoxOutlined /> */}
                          <CheckBoxOutlineBlank />
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                          style={{ width: "200px" }}
                        >
                          Variant
                        </p>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr className="mt-2">
                <div className="my-3">
                  <td>
                    <div className="d-flex w-100">
                      <div
                        className="d-flex ac-jc mx-2"
                        style={{ width: "50px" }}
                      >
                        <button className="mx-2 d-flex ac-jc cust-btn">
                          {/* <CheckBoxOutlined className="primary cp" /> */}
                          <CheckBoxOutlineBlank className="primary cp" />
                        </button>
                      </div>
                      <div className="mx-2">
                        <div
                          class="num-cls-btn d-flex overflow-scroll p-2"
                          style={{ width: "200px" }}
                        >
                          <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark">
                            XL/Blue/Classic
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={onClickShowVarient}
                        className="mx-2 d-flex ac-jc cp cust-btn"
                      >
                        <DeleteForever className="primary" />
                      </button>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        )}
        {/* ----- */}
      </div>
      <div className="d-flex as-jb w-100 h-50 mt-5">
        <button
          class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        <button
          class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          // onClick={() =>
          //   navigate("/vendor-details", {
          //     state: { form: "vendor", type: vendor },
          //   })
          // }
          onClick={() => navigate("/product-library")}
        >
          Submit
        </button>
      </div>
      {/* <div className='w-100'>
                {vendor === 'Jake Adams' &&
                    <div className="w-100">
                        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                            Decoration method
                        </p>
                        <div className='position-relative'>
                            <input placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                value={decorativeMethod}
                                onChange={onDecorative}
                            />
                            <button className='drop_down cust-btn' onClick={() => {
                                setDecorativeShow(!decorativeShow);
                                setCatogoryShow(false);
                                setCatogoryShow2(false);
                                setCatogoryShow3(false);
                                setProductNameShow(false);
                                setHsnTypeShow(false);
                                setPackingSizeShow(false);
                                setCountryShow(false);
                                setuomShow(false);
                                setISCountShow(false)
                            }}>
                                <KeyboardArrowDownIcon />
                            </button>
                            <div className={`${decorativeShow && 'submenu_1'} submenu_cont_1 overflow-scroll z-3`}>
                                {sub_menu?.map((item) => {
                                    return (
                                        <div className='d-flex hover-cust'>
                                            <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { setDecorativeShow(false); setDecorativeMethod(item?.list) }}>{item?.list}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div> */}
    </div>
  );
};

export default FormComp;
