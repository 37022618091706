import React, { useState } from "react";
import {
  editIcon,
  profilePic,
  toggleOff,
  toggleOn,
  uploadIcon,
  viewIcon,
} from "../assets/img";
import {
  adminData,
  categoryList,
  subCategoryList,
} from "../redux/api/DummyJson";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ViewCategoryScreen = () => {
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [showCategoryAdd, setShowCategoryAdd] = useState(false);
  const [popupType, setPopupType] = useState("");

  const onCategoryClick = (item) => {
    setSelectedCat(item?.name);
  };

  const onSubCatSelect = (item) => {
    setSelectedSubCat(item?.name);
  };

  const toggleCategShow = (type) => {
    setShowCategoryAdd(!showCategoryAdd);
    if (type) setPopupType(type);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
        View Category
      </p>

      <div className="add-category">
        <div className="sub-category">
          <p className="f2 fs-16 primary">Parent Category</p>
          <div className="list-wrapper">
            {categoryList.map((item) => {
              return (
                <div
                  onClick={() => onCategoryClick(item)}
                  className={`list ${
                    item.name === selectedCat && "bg-soft-gray1"
                  } `}>
                  <div>
                    <img src={item?.image} className="categoryimg" alt="" />
                    <p className="text-center">{item?.name}</p>
                  </div>
                  <div>
                    <img src={toggleOn} className="toggleOnDes" alt="icon" />
                    <ArrowForwardIosIcon className="arrow primary1" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {selectedCat && (
          <div className="sub-category">
            <p className="f2 fs-16 primary">{selectedCat}</p>
            <div className="list-wrapper">
              {subCategoryList.map((item) => {
                return (
                  <div
                    onClick={() => onSubCatSelect(item)}
                    className={`list ${
                      item.name === selectedSubCat && "bg-soft-gray1"
                    } `}>
                    <div>
                      <p>{item?.name}</p>
                    </div>
                    <div>
                      <img src={toggleOn} className="toggleOnDes" alt="icon" />
                      <ArrowForwardIosIcon className="arrow primary1" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {selectedSubCat && (
          <div className="sub-category">
            <p className="f2 fs-16 primary">{selectedSubCat}</p>
            <div className="list-wrapper">
              {subCategoryList.map((item) => {
                return (
                  <div
                    onClick={() => onSubCatSelect(item)}
                    className={`list ${
                      item.name === selectedSubCat && "bg-soft-gray1"
                    } `}>
                    <div>
                      <p>{item?.name}</p>
                    </div>
                    <div>
                      <img src={toggleOn} className="toggleOnDes" alt="icon" />
                      <ArrowForwardIosIcon className="arrow primary1" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewCategoryScreen;
