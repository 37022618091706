import ProfileNavBar from "../components/ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import AddVendorPopup from "../components/Popup/AddVendorPopup";
import VendorSuccessPopup from "../components/Popup/VendorSuccessPopup";
import AddEnterpriseDetails1 from "../components/Enterprises/AddEnterpriseDetails1";
import AddEnterpriseDetails2 from "../components/Enterprises/AddEnterpriseDetails2";
import AddEnterprisesFinal from "../components/Enterprises/AddEnterprisesFinal";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const AddEnterprises = () => {
  const navigate = useNavigate();
  const [secondDet, setSecondDet] = useState(false);
  const [finalView, setFinalView] = useState(false);
  const [vendorCat, setVendorCat] = useState("");
  const [popup, setPopup] = useState(false);
  const [successPop, setSuccessPop] = useState(false);
  const location = useLocation();
  const [name, setName] = useState("");

  useEffect(() => {
    if (location?.state?.type === "email") {
      setSecondDet(true);
    }
  }, [location]);

  const showPopupHander = () => {
    setPopup(false);
    setSuccessPop(false);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {/* {popup && (
        <AddVendorPopup
          showPopupHander={showPopupHander}
          setPopup={setPopup}
          setSuccessPop={setSuccessPop}
        />
      )} */}
      {/* {popup && <VendorSuccessPopup showPopupHander={showPopupHander} />} */}
      <div className="d-flex w-90 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={manImage} className="searchManImg" />
      </div>
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Add Enterprises
      </p>

      <div className="d-flex ac-js mt-4">
        {!secondDet && !finalView && (
          <p className="white bg-primar f2 radiusVendDes fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            1
          </p>
        )}
        {(finalView || secondDet) && (
          <DoneIcon className="white bg-green1 f2 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " />
        )}
        <p className="black f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
          General Detail
        </p>
        <div className="border-bottom w-5 border-primary1" />
        {!finalView && (
          <p
            className={`${
              secondDet ? "bg-primar" : "bg-primary1"
            } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
          >
            2
          </p>
        )}
        {finalView && (
          <DoneIcon className="white bg-green1 f2 ms-3 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " />
        )}
        <p
          className={`
        ${
          secondDet || finalView ? "black" : "primary1"
        } f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          Primary Detail
        </p>
        <div className="border-bottom w-5 border-primary1" />

        <p
          className={`
        ${
          finalView ? "bg-primar" : "bg-primary1"
        } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          3
        </p>
        <p
          className={`${
            finalView ? "black" : "primary1"
          }  f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          Final Verification
        </p>
      </div>
      {!secondDet && !finalView && (
        <AddEnterpriseDetails1
          setVendorCat={setVendorCat}
          vendorCat={vendorCat}
          setNames={setName}
        />
      )}
      {secondDet && <AddEnterpriseDetails2 />}
      {!secondDet && finalView && <AddEnterprisesFinal setNames={setName} />}
      <div className="w-100 ac-jb flex-md-row flex-column d-flex">
        <button
          onClick={() => {
            if (secondDet === true) {
              setSecondDet(false);
            } else if (finalView === true) {
              setFinalView(false);
              setSecondDet(true);
            } else {
              navigate("/enterprises-view");
            }
          }}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        {!finalView && (
          <button
            onClick={() => {
              if (secondDet === false) {
                setSecondDet(true);
              } else if (secondDet === true) {
                setSecondDet(false);
                setFinalView(true);
              }
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Next
          </button>
        )}
        {finalView && (
          <button
            // onClick={() => setPopup(true)}
            onClick={() => navigate(`/enterprises-view?name=${name}`)}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default AddEnterprises;
