import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { img_list, productTableData } from "../redux/api/DummyJson";
import SendIcon from "@mui/icons-material/Send";
import { product, profilePic, uploadIcon } from "../assets/img";
import { DeleteForever, Download } from "@mui/icons-material";
import ImageCroper from "../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ViewCollaborateDetails = () => {
  const location = useLocation();
  const hiddenFileInput = useRef(null);

  const [images, setImages] = useState([]);
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const [moreProduct, setMoreProduct] = useState(false);
  const fileRef = useRef();
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  console.log(data);

  const [textShow, setTextShow] = useState(true);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [smallimage, setSmallImage] = useState("");
  const [check, setCheck] = useState([]);

  const imageUplode = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    console.log(file, "windfine");
    // setTextShow(false)
  };

  const changeHandler = (e) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      console.log(filename);
      setFileName(filename);
    }
  };
  console.log(fileName);

  const checkBox = (e) => {
    if (check.includes(e)) {
      const listtemp = check.indexOf(e);
      const list = [...check];
      list.splice(listtemp, 1);
      setCheck(list);
    } else {
      setCheck([...check, e]);
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const handleChange = (event) => {
    const selectedFIles = [];
    const targetFiles = event.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      {croperImage && (
        <DeleteForever
          className="primary cp"
          onClick={() => setCropImage("")}
        />
      )}
      <div className="dashRightView p-5 home_section trans">
        <p
          className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
          role={"button"}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon /> Collaborate Details
        </p>
        <div className="w-100 mt-4 searchField bg-white ">
          <div className="w-100 d-flex flex-lg-row flex-column px-3">
            <div className="w-100">
              <div className="overflow-scroll w-100">
                <div className="w-100 cust_table">
                  {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Vendor Name:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.VendorName}
              </p>
            </div> */}
                  <div className="w-100 d-flex ac-js mt-3">
                    <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                      Vendor ID:
                    </p>
                    <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                      {data?.vendorId}
                    </p>
                  </div>
                  <div className="w-100 d-flex ac-js mt-3">
                    <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                      Vendor Rep:
                    </p>
                    <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                      {data?.name}
                    </p>
                  </div>
                  {/* <div className="w-100 d-flex ac-js mt-3">
                    <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                      File:
                    </p>
                    <div className="d-flex flex-column">
                      <img
                        src={product}
                        className="imgDashboard ms-2 object-fit-contain"
                      />
                      <button
                        onClick={() => setMoreProduct(!moreProduct)}
                        className="cust-btn primary f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      >
                        More View
                      </button>
                    </div>
                  </div> */}
                  {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Description:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.description}
              </p>
            </div> */}
                  <div className="w-100 d-flex ac-js mt-3">
                    <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                      Category:
                    </p>
                    <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                      Shirt
                    </p>
                  </div>
                  <div className="w-100 d-flex ac-js mt-3">
                    <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                      Product:
                    </p>
                    <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                      T Shirt
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className={`${!moreProduct && "d-none"} w-100`}>
                <div className="mt-5 w-100 d-flex ac-jc flex-column flex-md-row">
                  {img_list?.map((item, ind) => {
                    return (
                      <div className="position-relative cp">
                        {check.includes(item?.list) && <div>{item?.icon}</div>}
                        <img
                          src={item?.list}
                          className="imgDashboard mx-3 object-fit-contain my-3"
                          onClick={() => checkBox(item?.list)}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex ac-je">
                  <button
                    className={` ${
                      check.length !== 0 && "bg-transparent"
                    } d-flex mt-4 me-3 pointerView btn_border py-2 px-3`}
                  >
                    <Download className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2 icon_bg" />
                    <p className="gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                      Download
                    </p>
                  </button>
                </div>
              </div> */}
            </div>
            <div className="w-100">
              <div className="d-flex ac-je mt-5">
                <textarea
                  className="editBtn rounded-3 p-2 w-100"
                  placeholder="Type here..."
                />
                <div className="mt-4 ms-1">
                  <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                </div>
              </div>
              <div className="mt-5 w-100 overflow-scroll">
                <div className="d-flex ac-jc w-lg-90">
                  <div className="d-flex ac-jb cp w-100">
                    <label className="" onClick={() => toggleImagePopup()}>
                      {!croperImage && (
                        <p className="cust-btn addbtn cp bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                          Select File
                        </p>
                      )}

                      {croperImage && (
                        <p className="primary f3">{"File Uploaded"}</p>
                      )}
                      {/* <input
                      onChange={changeHandler}
                      type="file"
                      accept="*"
                      className="d-none"
                    /> */}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-100 mt-5 px-2">
            <p className=" f3 fs-15 fs-xs-11 mb-3 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Description:
            </p>
            <div className="editBtn rounded-3 cust_dis_h p-2 w-100 text">
              A good example of a paragraph contains a topic sentence, details
              and a conclusion. There are many different kinds of animals that
              live in China. Tigers and leopards are animals that live in Chinas
              forests in the north. In the jungles, monkeys swing in the trees
              and elephants walk through the brush. A good example of a
              paragraph contains a topic sentence, details and a conclusion.
              There are many different kinds of animals that live in China.
              Tigers and leopards are animals that live in Chinas forests in the
              north. In the jungles, monkeys swing in the trees and elephants
              walk through the brush. A good example of a paragraph contains a
              topic sentence, details and a conclusion. There are many different
              kinds of animals that live in China. Tigers and leopards are
              animals that live in Chinas forests in the north. In the jungles,
              monkeys swing in the trees and elephants walk through the brush. A
              good example of a paragraph contains a topic sentence, details and
              a conclusion. There are many different kinds of animals that live
              in China. Tigers and leopards are animals that live in Chinas
              forests in the north. In the jungles, monkeys swing in the trees
              and elephants walk through the brush. A good example of a
              paragraph contains a topic sentence, details and a conclusion.
              There are many different kinds of animals that live in China.
              Tigers and leopards are animals that live in Chinas forests in the
              north. In the jungles, monkeys swing in the trees and elephants
              walk through the brush.
            </div>
          </div> */}
          <div className="w-100  mt-0 bg-white d-flex ac-jb">
            <div className="w-100 mt-0 ">
              <div className="w-100 ">
                <div>
                  <ul>
                    <div className="w-100 d-flex ac-jc mt-5">
                      <div className="bg_today px-3 py-2 rounded-3">
                        <p class="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                          Today
                        </p>
                      </div>
                    </div>
                    <li className="d-flex flex-wrap mb-5">
                      <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                        <div className="d-flex-ac-jc mb-3 mb-md-0">
                          <img src={profilePic} className="sm_img_size" />
                        </div>
                        <div className="d-flex px-0 px-md-3 w-100">
                          <div className="mx-3 w-100">
                            <div className="d-flex ac-jb">
                              <h5 className="author">
                                <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                  Sakthi
                                </span>
                                <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                  5.00 PM
                                </span>
                              </h5>
                            </div>
                            {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                            <div>
                              <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                                <p>
                                  text and a search for lorem ipsum will uncover
                                  many web sites still in
                                </p>
                              </div>
                              <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li className="d-flex flex-wrap">
                      <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                        <div className="d-flex-ac-jc mb-3 mb-md-0">
                          <img src={profilePic} className="sm_img_size" />
                        </div>
                        <div className="d-flex px-0 px-md-3 w-100">
                          <div className="mx-3 w-100">
                            <div className="d-flex ac-jb">
                              <h5 className="author">
                                <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                  Vishwa
                                </span>
                                <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                  5.20 PM
                                </span>
                              </h5>
                            </div>
                            {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                            <div>
                              <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                                <p>
                                  like readable English. Many desktop publishing
                                  packages and web page editors now use Lorem
                                  Ipsum as their default readable English. Many
                                  desktop publishing packages and web page
                                  editors now use Lorem Ipsum as their default
                                  readable English. Many desktop publishing
                                  packages and web page editors now use Lorem
                                  Ipsum as their default model text and a search
                                  for lorem ipsum will uncover many web sites
                                  still in
                                </p>
                              </div>
                              <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="line w-100 mt-5" />
                <div>
                  <ul>
                    <div className="w-100 d-flex ac-jc mt-5">
                      <div className="bg_today px-3 py-2 rounded-3">
                        <p class="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                          Yesterday
                        </p>
                      </div>
                    </div>
                    <li className="d-flex flex-wrap">
                      <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                        <div className="d-flex-ac-jc mb-3 mb-md-0">
                          <img src={profilePic} className="sm_img_size" />
                        </div>
                        <div className="d-flex px-0 px-md-3 w-100">
                          <div className="mx-3 w-100">
                            <div className="d-flex ac-jb">
                              <h5 className="author">
                                <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                  Vishwa
                                </span>
                                <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                  5.00 PM
                                </span>
                              </h5>
                            </div>
                            {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                            <div>
                              <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                                <p>
                                  like readable English. Many desktop publishing
                                  packages and web page editors now use Lorem
                                  Ipsum as their and web page editors now use
                                  Lorem Ipsum as their default model text and a
                                  search for lorem ipsum will uncover many web
                                  sites still in
                                </p>
                              </div>
                              <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="d-flex flex-wrap mt-5">
                      <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                        <div className="d-flex-ac-jc mb-3 mb-md-0">
                          <img src={profilePic} className="sm_img_size" />
                        </div>
                        <div className="d-flex px-0 px-md-3 w-100">
                          <div className="mx-3 w-100">
                            <div className="d-flex ac-jb">
                              <h5 className="author">
                                <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                  Krishna Vendor
                                </span>
                                <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                  5.00 PM
                                </span>
                              </h5>
                            </div>
                            {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                            <div>
                              <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                                <p>
                                  like readable English. Many desktop publishing
                                  packages and web page editors now use Lorem
                                  Ipsum as their and web page editors now use
                                  Lorem Ipsum as their default model text and a
                                  search for lorem ipsum will uncover many web
                                  sites still in
                                </p>
                              </div>
                              <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-3 as-js mt-4 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                        <div className="d-flex-ac-jc cp mb-3 mb-md-0 position-relative image_hover_eff">
                          <img src={profilePic} className="sm_img_size2" />
                          <div
                            className="position-absolute invisible down_icon"
                            style={{ top: 0, right: 0 }}
                          >
                            <Download className="primary" />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 pb-4 flex-md-row flex-column d-flex mt-5">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
            >
              Back
            </button>
            {location?.state?.type === "edit" && (
              <button className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                Update Details
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCollaborateDetails;
