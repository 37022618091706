import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import FormComp from "../components/AddNewProductComps/FormComp";
import ImageUplodeComp from "../components/AddNewProductComps/ImageUplodeComp";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const AddNewProductScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selColors, setSelColors] = useState([]);
  const vendor = location?.state?.type;
  const vendor2 = location?.state?.path;
  const vendor3 = location?.state?.place;
  // const ven = location?.state?.product
  console.log(vendor);

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Add New Library
      </p>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <FormComp
          selColors={selColors}
          setSelColors={setSelColors}
          vendor={vendor}
        />
        <ImageUplodeComp
          selColors={selColors}
          vendor={vendor}
          vendor2={vendor2}
        />
      </div>
      {/* <div className="d-flex as-jb w-100 h-50 mt-5">
        <button
          class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() =>
            navigate("/vendor-details", {
              state: { form: "vendor", type: vendor },
            })
          }>
          Back
        </button>
        <button
          class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() =>
            navigate("/vendor-details", {
              state: { form: "vendor", type: vendor },
            })
          }>
          Submit
        </button>
      </div> */}
    </div>
  );
};

export default AddNewProductScreen;
