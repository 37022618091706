import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  productVendorData,
  serviceVendorData,
  serviceVendorDataBoth,
} from "../../redux/api/DummyJson";
import {
  editIcon,
  emptyImg,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../../assets/img";
import { useNavigate } from "react-router-dom";

const BothVendor = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont mt-4 me-3">
            Total Count : 1
          </p>
        </div>
      </div>
      <div className="d-flex ac-jc w-100">
        <div className="overflow-scroll mt-4">
          {serviceVendorData?.length > 0 && (
            <div className="wholeDesignDash1 d-flex mt-4 border-bottom pb-3">
              <p
                style={{ width: "100px" }}
                className="w-5 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc"
              >
                S.no
              </p>
              <p
                style={{ width: "150px" }}
                className="f2 w-15 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc"
              >
                Vendor ID
              </p>
              <p
                style={{ width: "200px" }}
                className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc"
              >
                Legal Entity Name
              </p>
              <p
                style={{ width: "150px" }}
                className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc"
              >
                Primary Name
              </p>
              {/* <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Vendor Offers
          </p> */}
              <p
                style={{ width: "150px" }}
                className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc"
              >
                Phone Number
              </p>
              <p
                style={{ width: "150px" }}
                className="w-5 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc"
              >
                Status
              </p>
              <p
                style={{ width: "150px" }}
                className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc"
              >
                Options
              </p>
            </div>
          )}
          {serviceVendorDataBoth?.length > 0 &&
            serviceVendorDataBoth?.map((item) => {
              return (
                <div className="wholeDesignDash1 mb-3 d-flex mt-5">
                  <p
                    className="w-5 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc"
                    style={{ width: "100px" }}
                  >
                    {item?.slNo}
                  </p>
                  <p
                    style={{ width: "150px" }}
                    className="pointerView w-15 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc"
                  >
                    {item?.vendorId}
                  </p>
                  <p
                    style={{ width: "200px" }}
                    className="w-20 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc"
                  >
                    {item?.entityName}
                  </p>
                  <p
                    style={{ width: "150px" }}
                    className="w-15 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc"
                  >
                    {item?.primaryName}
                  </p>
                  {/* <p className="w-15 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc">
                {item?.offer}
              </p> */}
                  <p
                    style={{ width: "150px" }}
                    className="w-15 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc"
                  >
                    {item?.mobileNo}
                  </p>
                  <div
                    className="pointerView w-5 d-flex ac-jc"
                    style={{ width: "150px" }}
                  >
                    {item?.status === true && (
                      <div>
                        <img src={toggleOn} className="toggleOnDes" />
                      </div>
                    )}
                    {item?.status === false && (
                      <div>
                        <img src={toggleOff} className="toggleOnDes" />
                      </div>
                    )}
                  </div>
                  <div
                    style={{ width: "150px" }}
                    onClick={() => {
                      navigate("/product-vendor-details", {
                        state: { type: "decorative", name: "Jake Adams" },
                      });
                    }}
                    className="w-10 d-flex ac-jc"
                  >
                    <div className="pointerView viewBoxDes p-2 mb-2 bg-white rounded-2">
                      <img src={viewIcon} className="viewDes" />
                    </div>
                  </div>
                </div>
              );
            })}
          {serviceVendorData?.length < 0 && (
            <div className="w-100 d-grid mt-5">
              <div className="d-flex ac-jc">
                <img src={emptyImg} className="noDataImg mt-5" />
              </div>
              <div className="d-flex ac-jc">
                <p className="f3 fs-xs-10 mt-4 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 gray">
                  There are no vendors to be shown.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>{" "}
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default BothVendor;
