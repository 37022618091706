import { useNavigate } from "react-router-dom";
import { editIcon, toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { adminData } from "../../redux/api/DummyJson";
import GetAppIcon from "@mui/icons-material/GetApp";
import { searchIcon } from "../../assets/img";
import { useEffect, useState } from "react";
import {
  useRoleListMutation,
  useLazyRoleStatusQuery,
} from "../../redux/api/api";

const ProductVendorSettings = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});

  // RTK QUER
  const [roleListApi] = useRoleListMutation();
  const [status] = useLazyRoleStatusQuery();

  const getList = (event, row) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    formdata.append("type", 2);

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    roleListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const change = (id) => {
    status(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  return (
    <>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) => getList(searchValue, e.target.value)}
            value={pageRow}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex flex-md-row flex-column">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont mt-4 me-3">
            Total Count : {list?.length}
          </p>
          <div className="pointerView d-flex mt-4 me-3">
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 px-md-2 border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) => getList(e.target.value, pageRow)}
              value={searchValue}
            />
          </div>
          <button
            onClick={() => navigate("/view-role-profile")}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Department/ Role
          </button>
        </div>
      </div>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Role
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.role}
                    </p>
                  </td>

                  <td>
                    <div
                      className="d-flex ac-jc"
                      onClick={() => change(item?.id)}
                    >
                      {item?.status === 0 ? (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      ) : (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex ac-jc primary1">
                      <div
                        onClick={() =>
                          navigate("/view-role-profile", {
                            state: { type: "view", data: item },
                          })
                        }
                        className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                      <div
                        onClick={() =>
                          navigate("/view-role-profile", {
                            state: { type: "edit", data: item },
                          })
                        }
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default ProductVendorSettings;
