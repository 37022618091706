import React, { useState } from "react";
import { animatedImg, product, toggleOff, toggleOn } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";

const ProductListLibraryComp = ({ productList, setParoductList }) => {
  // const [productDetail, setProductDetail] = useState(false)
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  // console.log(productList);

  const rolefunction = (id) => {
    let roleId = [...permissions];
    let ids = roleId.indexOf(id);

    if (roleId.includes(id)) {
      roleId.splice(ids, 1);
    } else {
      roleId.push(id);
    }

    if (roleId.length == [1, 2, 3, 4].length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }

    setPermissions(roleId);
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setPermissions([1, 2, 3, 4].map((li) => li));
    if (isCheckAll) {
      setPermissions([]);
    }
  };

  return (
    <>
      <div className="d-flex ac-jb flex-column flex-md-row w-100 mt-5">
        <div className="d-flex gap-3">
          <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
            <option hidden>Select Categories</option>
            <option>Mobile Phone</option>
            <option>Water Bottle</option>
          </select>
          <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
            <option hidden>Select Status</option>
            <option>Active</option>
            <option>Deactive</option>
          </select>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : 04
          </p>
        </div>
      </div>
      <div className="w-95 d-flex flex-md-row flex-column mt-4 gap-2">
        <div
          className="d-flex gap-1 mt-4"
          onClick={() => handleSelectAll()}
          role={"button"}
        >
          <DoneIcon
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 mt-3 ${
              isCheckAll ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-3 ${
              isCheckAll ? "black" : "primary1"
            }`}
          >
            Select All
          </p>
        </div>
        {permissions?.length > 0 && (
          <div className="d-flex gap-2 mt-3">
            <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Active
            </button>
            <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Deactive
            </button>
          </div>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-5 flex-wrap">
        {(productList == "product" || productList == "both") && (
          <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative">
            <div className="prod-list-box">
              <div
                onClick={() =>
                  navigate("/product-library-details", {
                    state: { type: "productList" },
                  })
                }
              >
                <div className="cust-btn d-flex ac-jc rounded-3">
                  <img src={product} alt="" className="custom-w-h rounded-3" />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  Hoodie
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  Casual Wear
                </p>
                <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                  SKU0001
                  {/* <span>
                    <div
                      className="f4 fs-15"
                      style={{
                        color: "greenyellow",
                      }}
                    >
                      Customer
                    </div>
                  </span> */}
                </p>
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                Visible Users
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Vendor, Customer, Enterprices,
Reseller 
                </p>
            </div>
            <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
              <DoneIcon
                onClick={() => rolefunction(1)}
                className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                  permissions?.includes(1) ? "checkIcon" : "checkIcon1"
                }`}
              />
              <p
                className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                  permissions?.includes(1) ? "black" : "primary1"
                }`}
              ></p>
            </div>
          </div>
        )}
        {(productList == "product" || productList == "both") && (
          <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative">
            <div className="prod-list-box">
              <div
                onClick={() =>
                  navigate("/product-library-details", {
                    state: { type: productList },
                  })
                }
              >
                <div className="cust-btn d-flex ac-jc rounded-3">
                  <img src={product} alt="" className="custom-w-h rounded-3" />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  T Shirt
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  Clothing
                </p>
                <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                  SKU0001
                  {/* <span>
                    <div
                      className="f4 fs-15"
                      style={{
                        color: "greenyellow",
                      }}
                    >
                      Customer
                    </div>
                  </span> */}
                </p>
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Visible Users
                {/* <span>
                  <img src={toggleOn} className="toggleOnDes" alt="icon" />
                </span> */}
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Vendor, Customer, Enterprices,
Reseller 
                </p>
            </div>
            <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
              <DoneIcon
                onClick={() => rolefunction(2)}
                className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                  permissions?.includes(2) ? "checkIcon" : "checkIcon1"
                }`}
              />
              <p
                className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                  permissions?.includes(2) ? "black" : "primary1"
                }`}
              ></p>
            </div>
          </div>
        )}
        {(productList == "decorative" || productList == "both") && (
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 "
            onClick={() => {
              navigate("/product-library-details", {
                state: { type: productList },
              });
              setParoductList("decorative");
            }}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                Jacket
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                Trending
              </p>
              <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                SKU0001
                {/* <span>
                  <div
                    className="f4 fs-15"
                    style={{
                      color: "green",
                    }}
                  >
                    Both
                  </div>
                </span> */}
              </p>
            </div>
          </div>
        )}
        {(productList == "product" || productList == "both") && (
          <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative">
            <div className="prod-list-box">
              <div
                onClick={() =>
                  navigate("/product-library-details", {
                    state: { type: productList },
                  })
                }
              >
                <div className="cust-btn d-flex ac-jc rounded-3">
                  <img src={product} alt="" className="custom-w-h rounded-3" />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  Mens T-Shirt
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  Clothing
                </p>
                <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                  SKU0001
                  {/* <span>
                    <div
                      className="f4 fs-15"
                      style={{
                        color: "orange",
                      }}
                    >
                      Reseller
                    </div>
                  </span> */}
                </p>
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Visible Users
                {/* <span>
                  <img src={toggleOn} className="toggleOnDes" alt="icon" />
                </span> */}
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Vendor, Customer, Enterprices,
Reseller 
                </p>
            </div>
            <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
              <DoneIcon
                onClick={() => rolefunction(3)}
                className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                  permissions?.includes(3) ? "checkIcon" : "checkIcon1"
                }`}
              />
              <p
                className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                  permissions?.includes(3) ? "black" : "primary1"
                }`}
              ></p>
            </div>
          </div>
        )}
        {(productList == "decorative" || productList == "both") && (
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
            onClick={() => {
              navigate("/product-library-details", {
                state: { type: productList },
              });
              setParoductList("decorative");
            }}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                Mens T-Shirt
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                Clothing
              </p>
              <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                SKU0001
                <span>
                  <div
                    className="f4 fs-15"
                    style={{
                      color: "green",
                    }}
                  >
                    Both
                  </div>
                </span>
              </p>
            </div>
          </div>
        )}
        {(productList == "decorative" || productList == "both") && (
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
            decorative
            onClick={() => {
              navigate("/product-library-details", {
                state: { type: productList },
              });
              setParoductList("decorative");
            }}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                Mens T-Shirt
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                Clothing
              </p>
              <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                SKU0001
                <span>
                  <div
                    style={{
                      width: "18px",
                      height: "18px",
                      borderRadius: "50%",
                      backgroundColor: "green",
                    }}
                  ></div>
                </span>
              </p>
            </div>
          </div>
        )}
        {(productList == "decorative" || productList == "both") && (
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
            onClick={() => {
              navigate("/product-library-details", {
                state: { type: productList },
              });
              setParoductList("decorative");
            }}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                Unisex Hoodie
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                Clothing
              </p>
              <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                SKU0001
                <span>
                  <span>
                    <div
                      className="f4 fs-15"
                      style={{
                        color: "green",
                      }}
                    >
                      Both
                    </div>
                  </span>
                </span>
              </p>
            </div>
          </div>
        )}
        {(productList == "decorative" || productList == "both") && (
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
            onClick={() => {
              navigate("/product-library-details", {
                state: { type: productList },
              });
              setParoductList("decorative");
            }}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                Unisex Hoodie
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                Clothing
              </p>
              <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                SKU0001
                <span>
                  <div
                    className="f4 fs-15"
                    style={{
                      color: "green",
                    }}
                  >
                    Both
                  </div>
                </span>
              </p>
            </div>
          </div>
        )}
        {(productList == "decorative" || productList == "both") && (
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
            onClick={() => {
              navigate("/product-library-details", {
                state: { type: productList },
              });
              setParoductList("decorative");
            }}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                Cotton Hoodie
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                Clothing
              </p>
              <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                SKU0001
                <span>
                  <div
                    className="f4 fs-15"
                    style={{
                      color: "green",
                    }}
                  >
                    Both
                  </div>
                </span>
              </p>
              
            </div>
          </div>
        )}
        {(productList == "product" || productList == "both") && (
          <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative">
            <div className="prod-list-box">
              <div
                onClick={() =>
                  navigate("/product-library-details", {
                    state: { type: productList },
                  })
                }
              >
                <div className="cust-btn d-flex ac-jc rounded-3">
                  <img src={product} alt="" className="custom-w-h rounded-3" />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  Cotton Hoodie
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  Clothing
                </p>
                <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                  SKU0001
                  {/* <span>
                    <div
                      className="f4 fs-15"
                      style={{
                        color: "green",
                      }}
                    >
                      Both
                    </div>
                  </span> */}
                </p>
              </div>
              <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Visible Users
              </p>
              <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Vendor, Customer, Enterprices,
Reseller 
                </p>
              {/* <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
                Status
                <span>
                  <img src={toggleOff} className="toggleOnDes" alt="icon" />
                </span>
              </p> */}
            </div>
            <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
              <DoneIcon
                onClick={() => rolefunction(4)}
                className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                  permissions?.includes(4) ? "checkIcon" : "checkIcon1"
                }`}
              />
              <p
                className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                  permissions?.includes(4) ? "black" : "primary1"
                }`}
              ></p>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default ProductListLibraryComp;
