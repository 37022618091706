import React, { useState } from "react";
import { event_category, mockup_artwork } from "../../redux/api/DummyJson";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useNavigate } from "react-router";

const OverallProductionArtworkRequestComp = () => {
  const navigate = useNavigate();
  const [designStatus, setDesignStatus] = useState("Approved");
  const [salseStatus, setSalseStatus] = useState("Completed");
  const design_list = [{ list: "Inprocess" }, { list: "Approved" }];
  const salse_list = [{ list: "Completed" }, { list: "Inprocess" }];
  return (
    <div className="w-100 overflow-scroll mt-5">
      <table className="w-100 px-4">
        <tr>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              S.No
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Project ID
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Project Name
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Customer ID
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Estimate ID
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Order Rep
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Client Rep
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Estimate Status
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Customer Status
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-5 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Design Status
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-5 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Sales Status
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-5 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Options
            </p>
          </th>
        </tr>
        {mockup_artwork?.map((item, ind) => {
          return (
            <tr className="my-3">
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.id}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.project_id}
                </p>
              </th>{" "}
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.project_name}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.customer_id}
                </p>
              </th>{" "}
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.estimate_id}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.order_rep}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.client_rep}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.Estimate_status}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.Customer_status_art}
                </p>
              </th>
              <th>
                <div className=" position-relative rel_sty d-flex flex-column ac-jc mt-2">
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.design_art_req}
                </p>
                  {/* {designStatus == "Approved" && (
                    <div className="hov_popp viewBoxDes rounded-2">
                      <p className="f1 fs-xs-11 fs-sm-12 border-3 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-10 text-dark text-center py-2">
                        Completed by Hari
                      </p>
                    </div>
                  )} */}
                </div>
              </th>
              <th>
                <div className=" position-relative rel_sty d-flex flex-column ac-jc mt-2">
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.sale_art_req}
                </p>
                  {/* {salseStatus == "Completed" && (
                    <div className="hov_popp viewBoxDes rounded-2">
                      <p className="f1 fs-xs-11 fs-sm-12 border-3 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-10 text-dark text-center py-2">
                        Approved by Abinesh
                      </p>
                    </div>
                  )} */}
                </div>
              </th>
              <th>
                <div className="d-flex ac-jc pb-4">
                  <button
                    onClick={() => navigate("/prodection-artwork")}
                    className="text-center bg-transparent border-0 pointerView viewBoxDes p-2 bg-white rounded-2">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 ">
                      <VisibilityIcon />
                    </p>
                  </button>
                  <button
                    onClick={() => navigate("/prodection-artwork")}
                    className="text-center bg-transparent border-0 pointerView viewBoxDes p-2 bg-white rounded-2">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 ">
                      <BorderColorIcon />
                    </p>
                  </button>
                </div>
              </th>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default OverallProductionArtworkRequestComp;
