import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ViewStockPoppup = ({ viewStockToggle }) => {
  return (
    <div className="delete-popup">
      <div className="w-90 w-md-40">
        {" "}
        <button
          onClick={() => viewStockToggle()}
          className="cust-btn d-flex ac-je w-100"
        >
          <HighlightOffIcon />
        </button>
        <div className="d-flex as-jb gap-5 mx-3 ">
          <div className="mt-3">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Un-Branded:
            </p>
            <div className="mt-3">
              <div className="d-flex w-100 gap-3 my-2">
                <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  SKU Code :
                </p>
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Ninos_SKU001
                </p>
              </div>
              <div className="d-flex w-100 gap-3 my-2">
                <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Stock Qty :
                </p>
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  400
                </p>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Branded:
            </p>
            <div className="mt-3">
              <div className="d-flex w-100 gap-3 my-2">
                <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  SKU Code :
                </p>
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Ninos_SKU001
                </p>
              </div>
              <div className="d-flex w-100 gap-3 my-2">
                <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Stock Qty :
                </p>
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  400
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewStockPoppup;
