import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import category_img from "../../assets/img/Pack_New_Hire.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Nav_tags, icon_tags } from "../../redux/api/DummyJson";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  useLazyCategoriesQuery,
  useLazyCategoryViewQuery,
  useLazyNavigationView2Query,
  useLazyNavigationViewQuery,
  useLazyTagsListQuery,
  useNavigationCreateMutation,
  useNavigationEditMutation,
} from "../../redux/api/api";
import ErrorIcon from "@mui/icons-material/Error";
import { noLogo } from "../../assets/img";
import { Col, Row } from "react-bootstrap";
import ImageCroper from "../Popup/ImageCroper";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // RTK QUERY
  const [navigationCreateApi] = useNavigationCreateMutation();
  const [navigationUpdateApi] = useNavigationEditMutation();
  const [navigationViewApi] = useLazyNavigationViewQuery();
  const [navigationViewsApi] = useLazyNavigationView2Query();
  const [tagsListApi] = useLazyTagsListQuery();
  const [listCategory] = useLazyCategoriesQuery();
  const [viewCategory] = useLazyCategoryViewQuery();

  const [tagList, setTagList] = useState([]);
  const [normalTag, setNormalTag] = useState([]);
  const [iconsTag, setIconTag] = useState([]);
  const [list, setList] = useState([]);
  const [multiple, setMutiple] = useState([]);
  const [parent_id, setParent_id] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryColor, setSubCategoryColor] = useState([]);
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();

  const [name, setName] = useState("");
  const [priority, setPriority] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [iconImage, setIconImage] = useState("");
  const [category_id, setCategory_id] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [priorityErr, setPriorityErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);

  const [tagEnable, setTagEnable] = useState(true);
  const [iconTagEnable, setIconTagEnable] = useState(true);
  const [categoryShow, setCategoryShow] = useState(false);
  const [inputValue, setInputValue] = useState([]);
  const [IconTags, setIconTags] = useState("");
  const [IconTagShow, setIconTagShow] = useState(false);
  const [Tags, setTags] = useState("");
  const [TagShow, setTagShow] = useState(false);

  useEffect(() => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setTagList(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    if (croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setIconImage(file);
        });
    }
  }, [croperImage]);

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.categories);
          if (parent_id) {
            // getView(parent_id);
          }

          if (selectedId) {
            // getView(selectedId);
          }
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const getView = (item, ind, index, type, data, category) => {
    viewCategory(item?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // MULTIPLE
          let temp;
          if (data?.length > 0) {
            temp = [...data];
          } else {
            temp = [...multiple];
          }

          if (type == "subSumit") {
            let temX = res?.child_categories;

            let obj = {
              ...temp[index],
              child_categories: temX,
            };

            let final = multiple?.filter((i) => i?.category?.id !== item?.id);
            final.splice(index, 0, obj);

            setMutiple(final);
            if (category?.id) {
              getView2(category, final);
            }
          } else {
            let finder = temp.indexOf(item?.id);
            if (temp?.includes(res)) {
              temp.slice(0, 1);
              temp.push(res);
            } else {
              temp.push(res);
            }
            setMutiple(temp);
            if (category?.id) {
              getView2(category, temp);
            }
          }
          // addMultiple(item, index);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getView2 = (item, final) => {
    viewCategory(item?.id)
      .unwrap()
      .then((res) => {
        // MULTIPLE
        let temp = [...final];
        temp.push(res);
        setMutiple(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // console.log("multiple", multiple);

  const onCategoryClick = (item, index) => {
    multiple.splice(0, multiple.length);
    setMutiple(multiple);
    setSelectedCat(item?.id);
    setParent_id(item?.id);
    setCategory_id(item?.id);

    setSelectedSubCat("");
    setSelectedId("");
    getView(item);
    setCategoryColors(item?.id);
    setSubCategory([]);
    setSubCategoryColor([]);

    // ADD MULTIPLE
    // setMutiple([0]);
    // addMultiple(item, index);
  };

  const onSubCatSelect = (item, ind, index) => {
    // multiple.splice(index, multiple?.length);
    // console.log("item", item);
    setSelectedSubCat([index]?.item?.name);
    setSelectedId([index]?.item?.id);
    setParent_id([index]?.item?.id);

    if (index < multiple?.length - 1) {
      let temp = [...multiple];
      let slicer = temp.slice(0, index + 1);
      setMutiple(slicer);

      // console.log("slicer", item?.id);

      getView(item, ind, index, " ", slicer);
    } else {
      getView(item, ind, index);
    }

    setSubCategoryColors(item?.id, index);

    // ADD MULTIPLE
    // addMultiple(item, index);
  };

  // console.log("multiple", multiple);

  const setCategoryColors = (id) => {
    let temp = [];
    let inx = temp.indexOf(id);

    if (temp.includes(id)) {
      temp.splice(inx, 1);
    } else {
      temp.push(id);
    }

    setCategoryList(temp);
  };

  const setSubCategoryColors = (id, ind) => {
    let temp = [];
    let inx = temp.indexOf(id);

    if (temp.includes(id)) {
      temp.splice(inx, 1);
    } else {
      temp.push(id);
    }

    if (+ind == 0) {
      setSubCategory(temp);
      setCategory_id(id);
    }

    let tempx = [...subCategoryColor];
    let inx1 = tempx.indexOf(id);

    if (tempx.includes(id)) {
      tempx.splice(inx1, 1);
    } else {
      tempx.push(id);
    }

    setSubCategoryColor(tempx);
  };

  // console.log("category_id", category_id);

  useEffect(() => {
    getList();
  }, []);

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const onClickIconTags = (e, id) => {
    if (IconTags.includes(e)) {
      const listtemp = IconTags.indexOf(e);
      const list = [...IconTags];
      list.splice(listtemp, 1);
      setIconTags(list);
    } else {
      setIconTags([...IconTags, e]);
    }

    if (iconsTag.includes(id)) {
      const listtemp = iconsTag.indexOf(id);
      const list = [...iconsTag];
      list.splice(listtemp, 1);
      setIconTag(list);
    } else {
      setIconTag([...iconsTag, id]);
    }
  };

  const onClickTags = (e, id) => {
    if (Tags.includes(e)) {
      const listtemp = Tags.indexOf(e);
      const list = [...Tags];
      list.splice(listtemp, 1);
      setTags(list);
    } else {
      setTags([...Tags, e]);
    }

    if (normalTag.includes(id)) {
      const listtemps = normalTag.indexOf(id);
      const lists = [...normalTag];
      lists.splice(listtemps, 1);
      setNormalTag(lists);
    } else {
      setNormalTag([...normalTag, id]);
    }
  };

  //   event
  const handleOnchange = (e) => {
    const { value } = e && e.target;
    if (value?.length >= 2) {
      setCategoryShow(true);
    } else {
      setCategoryShow(false);
    }
  };

  // VIEW API
  const getViewNavigation = () => {
    navigationViewsApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        let data = res?.data;
        setName(data?.name);
        setPriority(data?.priority);
        setTitle(data?.title);
        setDescription(data?.description);
        setIconImage(data?.icon);

        if (data?.category?.id) {
          setCategoryShow(true);
        }

        if (data?.category?.parentCategory?.id) {
          getView(
            data?.category?.parentCategory,
            "",
            "",
            "",
            "",
            data?.category
          );
          let temp = [];
          let temps = [];
          temp.push(data?.category?.parentCategory?.id);
          temps.push(data?.category?.id);
          setCategory_id(data?.category?.id);
          setCategoryList(temp);
          setSubCategory(temps);
          setSubCategoryColor(temps);
        } else {
          let temps = [];
          temps.push(data?.category?.id);
          getView(data?.category);
          setCategory_id(data?.category?.id);
          setCategoryList(temps);
        }

        let iconTagName = [];
        let iconTagID = [];
        let normalTagName = [];
        let normalTagID = [];

        data?.normalTags?.map((item, ind) => {
          normalTagName.push(item?.name);
          normalTagID.push(item?.id);
        });

        data?.iconTags?.map((item, ind) => {
          iconTagName.push(item?.name);
          iconTagID.push(item?.id);
        });

        if (normalTagID?.length > 0) {
          setTagEnable(false);
        }

        if (iconTagID?.length > 0) {
          setIconTagEnable(false);
        }

        setTags(normalTagName);
        setIconTags(iconTagName);
        setIconTag(iconTagID);
        setNormalTag(normalTagID);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getViewNavigation();
    }
  }, []);

  const submitHandle = () => {
    if (
      name?.length == 0 ||
      priority?.length == 0 ||
      title?.length == 0 ||
      description?.length == 0
    ) {
      setNameErr(true);
      setPriorityErr(true);
      setTitleErr(true);
      setDescriptionErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", name);
      formdata.append("priority", priority);
      formdata.append("title", title);
      formdata.append("description", description);
      formdata.append("category_id", category_id);

      if (iconImage?.name) {
        formdata.append("icon", iconImage);
      }

      if (!tagEnable && normalTag?.length > 0) {
        normalTag?.map((item, ind) => {
          formdata.append(`normal_tag_ids[${ind}]`, item);
        });
      }

      if (!iconTagEnable && iconsTag?.length > 0) {
        iconsTag?.map((item, ind) => {
          formdata.append(`icon_tag_ids[${ind}]`, item);
        });
      }

      if (location?.state?.data?.id) {
        navigationUpdateApi({
          payload: formdata,
          id: location?.state?.data?.id,
        })
          .unwrap()
          .then((res) => {
            console.log("res", res);
            navigate("/navigation-list");
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        navigationCreateApi(formdata)
          .unwrap()
          .then((res) => {
            console.log("res", res);
            navigate("/navigation-list");
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  // console.log("multiple", multiple);

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-3 w-100 ac-jb pt-4">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Navigation
          </p>
        </div>
      </div>
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="col-md-5 col-lg-5 col-12">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Name
          </p>
          <input
            type="text"
            placeholder="Enter Name"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          {name?.length == 0 && nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Name</p>
            </div>
          )}
        </div>
        <div className="col-md-1 col-lg-1 col-12">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Priority
          </p>
          <input
            type="number"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setPriority(e.target.value)}
            value={priority}
          />
          {priority?.length == 0 && priorityErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Priority</p>
            </div>
          )}
        </div>
        <div
          className="col-md-5 col-lg-5 col-12"
          onClick={() => toggleImagePopup()}
        >
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Icon
          </p>
          <input
            style={{ cursor: "pointer" }}
            value={
              iconImage ? "Icon Uploaded Successfully" : "Browse or Drag a file"
            }
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="col-md-5 col-lg-5 col-12">
          <div className="d-flex my-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Tags
            </p>
            <button type="button" className="100 cust-btn ps-1 ">
              <div className="toggle_btn">
                <input
                  type="checkbox"
                  className="checkbox"
                  style={{ background: "#26D497" }}
                  onChange={(e) => {
                    e.target.checked
                      ? setTagEnable(!tagEnable)
                      : setTagEnable(!tagEnable);
                  }}
                  checked={tagEnable == true ? false : true}
                />
                <div className="yes"></div>
                <div className="no"></div>
              </div>
            </button>
          </div>

          <fieldset disabled={tagEnable}>
            <div className="position-relative">
              <input
                placeholder="Select Tags"
                type="text"
                value={Tags}
                onClick={() => setTagShow(!TagShow)}
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => setTagShow(!TagShow)}
              >
                <KeyboardArrowDownIcon />
              </button>
              {TagShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setTagShow(false)}
                />
              )}
              <div
                className={`${
                  TagShow && "submenu_1 h-auto"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {tagList?.normalTag?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onClickTags(item?.name, item?.id);
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.name}
                      </button>
                      <button className="px-2 cust-btn text-start">
                        {Tags?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </fieldset>
          {/* <div className="position-relative z-3">
            <input
              placeholder="Select Tags"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              disabled={tagEnable}
            />
            <button type="button" className="drop_down cust-btn">
              <KeyboardArrowDownIcon />
            </button>
          </div> */}
        </div>
        <div className="col-md-5 col-lg-5 col-12">
          <div className="d-flex my-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Icon Tags
            </p>
            <button type="button" className="100 cust-btn ps-1 ">
              <div className="toggle_btn">
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={(e) => {
                    e.target.checked
                      ? setIconTagEnable(!iconTagEnable)
                      : setIconTagEnable(!iconTagEnable);
                  }}
                  checked={iconTagEnable == true ? false : true}
                />

                <div className="yes"></div>
                <div className="no"></div>
              </div>
            </button>
          </div>
          <fieldset disabled={iconTagEnable}>
            <div className="position-relative">
              <input
                placeholder="Select Icon Tags"
                type="text"
                value={IconTags}
                onClick={() => setIconTagShow(!IconTagShow)}
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => setIconTagShow(!IconTagShow)}
              >
                <KeyboardArrowDownIcon />
              </button>
              {IconTagShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setIconTagShow(false)}
                />
              )}
              <div
                className={`${
                  IconTagShow && "submenu_1 h-auto"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {tagList?.iconTag?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onClickIconTags(item?.name, item?.id);
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.name}
                      </button>
                      <button className="px-2 cust-btn text-start">
                        {IconTags?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </fieldset>
          {/* <div className="position-relative z-3">
            <input
              placeholder="Select Icon Tags"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              disabled={iconTagEnable}
            />
            <button type="button" className="drop_down cust-btn">
              <KeyboardArrowDownIcon />
            </button>
          </div> */}
        </div>
        <div className="col-md-7 col-lg-7 col-12  position-relative my-1">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Set Priority
          </p>
          <input
            type="text"
            placeholder="Required Field"
            value={
              inputValue?.length > 0
                ? inputValue.join(",")
                : inputValue[0] || ""
            }
            onChange={handleOnchange}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          />
          <button
            className="drop_down position-absolute custom-btn cust-btn"
            onClick={() => {
              setCategoryShow(!categoryShow);
            }}
          >
            <KeyboardArrowDownIcon />
          </button>
        </div>
        <div className="col-md-4 col-lg-4 col-12 d-flex align-items-center mt-4">
          <div className="px-2">
            <button
              type="button"
              className=" cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Cancel
            </button>
          </div>
          <div className="px-2">
            <button
              // onClick={() => navigate("/navigation-list ")}
              onClick={() => submitHandle()}
              type="button"
              className=" cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Submit
            </button>
          </div>
        </div>
        {categoryShow && (
          <div className="col-md-12 col-lg-12 col-12 mt-3">
            <div className="col-12 d-flex flex-wrap">
              <Col md={4} lg={4} xl={4}>
                <div className="col-lg-3 col-md-3 col-12">
                  <div className="category_list">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                      Category
                    </p>
                    <ul>
                      {list?.map((item, ind) => {
                        return (
                          <fieldset>
                            <button
                              className="btn border-0"
                              onClick={(e) => {
                                onCategoryClick(item, ind);
                              }}
                            >
                              <li
                                className={`d-flex justify-content-between p-2 bg-white align-items-center ${
                                  categoryList?.includes(item?.id)
                                    ? "green"
                                    : "bg-white"
                                }`}
                              >
                                <img
                                  src={item?.icon ? item?.icon : noLogo}
                                  style={{ cursor: "pointer" }}
                                  className="category_list_img"
                                  alt="img"
                                  // onClick={(e) => {
                                  //   setCategoryColors(item?.id);
                                  // }}
                                />
                                <p
                                  className="mb-0"
                                  // onClick={(e) => {
                                  //   setCategoryColors(item?.id);
                                  // }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item?.name}
                                </p>
                                {!categoryList?.includes(item?.id) ? (
                                  <button
                                    className="btn border-0"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <KeyboardArrowDownIcon className="down-arrow" />
                                  </button>
                                ) : (
                                  <button className="btn border-0">
                                    <KeyboardArrowRightIcon className="down-arrow" />
                                  </button>
                                )}
                              </li>
                            </button>
                          </fieldset>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md={8} lg={8} xl={8} className="d-flex flex-wrap gap-3">
                {multiple?.map((item, i) => {
                  return (
                    <div className="col-lg-4 col-md-4 col-4" key={i}>
                      <div className="category_list">
                        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                          {item?.category?.name}
                        </p>

                        <ul className="col-lg-3 col-md-4 col-12">
                          <button type="button" className="btn border-0">
                            {item?.child_categories?.map((subList, ind) => {
                              return (
                                <li
                                  className={`d-flex justify-content-between align-items-center bg-white ${
                                    subCategory?.includes(subList?.id)
                                      ? "green"
                                      : "bg-white"
                                  }`}
                                  onClick={() => {
                                    onSubCatSelect(subList, ind, i);
                                  }}
                                >
                                  <p className="mb-0 px-2">{subList?.name}</p>
                                  {!subCategoryColor?.includes(subList?.id) ? (
                                    <button className="btn border-0">
                                      <KeyboardArrowDownIcon className="down-arrow" />
                                    </button>
                                  ) : (
                                    <button className="btn border-0">
                                      <KeyboardArrowRightIcon className="down-arrow" />
                                    </button>
                                  )}
                                </li>
                              );
                            })}
                          </button>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </Col>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-7 col-lg-7 col-12">
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
          Title
        </p>
        <input
          type="text"
          placeholder="Enter Tittle"
          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
        {title?.length == 0 && titleErr && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">Enter Title</p>
          </div>
        )}
      </div>
      <div className="col-md-7 col-lg-7 col-12 mt-1">
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
          Description
        </p>
        <textarea
          cols={5}
          placeholder="Description.."
          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
        {description?.length == 0 && descriptionErr && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">Enter Description</p>
          </div>
        )}
      </div>
      {/* <div className="my-5 col-12">
        <button
          type="button"
          className="  cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Submit
        </button>
      </div> */}
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
    </div>
  );
};

export default Navigation;
