import React, { useState } from "react";
import NavbarHead from "../Sub_Layout/NavbarHead";
import EstimateHeadComp from "../EstimateComp/EstimateHeadComp";
import TableComp from "../EstimateComp/TableComp";
import { Col } from "react-bootstrap";
import TableComptwo from "../EstimateComp/TableComptwo";
import AddContactPopup from "../EstimateComp/Poppup/AddContactPopup";
import AddressPopup from "../EstimateComp/Poppup/AddressPopup";
import CompactsTableComp from "../EstimateComp/CompactsTableComp";
import NewTableComp from "../EstimateComp/NewTableComp";
import TableComp2 from "../EstimateComp/TableComp2";
import ViewStockPoppup from "../EstimateComp/ViewStockPoppup";
import DeletePopup from "../Popup/DeletePopup";
import TableDetaile from "../EstimateComp/TableDetaileComp";

const EstimateScreen = () => {
  const [addContactComp, setAddContactComp] = useState(false);
  const [addAddressComp, setAddAddressComp] = useState(false);
  const [actionBtn, setActionBtn] = useState(false);
  const [collaboratClick, setCollaboratClick] = useState(true);
  const [tab, setTab] = useState(1);
  const [toggleBtn1, setToggleBtn1] = useState(2);
  const [toggleBtn2, setToggleBtn2] = useState(2);
  const [addShow, setAddShow] = useState(false);
  const [deletes, setDeletes] = useState(false);
  const [viewStock, setViewStock] = useState(false);

  const [addTitle, setAddTitle] = useState(false);

  const poppupToggle = () => {
    setAddContactComp(!addContactComp);
  };
  const poppupAddressToggle = () => {
    setAddAddressComp(!addAddressComp);
  };

  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };
  const onCollaborat = () => {
    setCollaboratClick(!collaboratClick);
  };
  const viewStockToggle = () => {
    setViewStock(!viewStock);
  };

  const deleteToggle = () => {
    setDeletes(!deletes);
  };

  return (
    <div className="dashRightView2 overview_pages overflow-scroll ">
      {viewStock && <ViewStockPoppup viewStockToggle={viewStockToggle} />}
      {deletes && (
        <DeletePopup deleteToggle={deleteToggle} setAddTitle={setAddTitle} />
      )}
      <div className=" d-flex justify-content-end position-relative mb-2">
        <div className="action_overview mx-4">
          <NavbarHead onLinkShow={onLinkShow} />
          {actionBtn && (
            <div
              className="invisible-cont2"
              style={{ zIndex: 200 }}
              onClick={() => setActionBtn(false)}
            />
          )}
          {actionBtn && (
            <div className="action_btn ml-1" style={{ zIndex: 300 }}>
              <button
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                onClick={() => {
                  setActionBtn(false);
                }}>
                Copy Estimate
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100">
                Preview Estimate
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100">
                Email Estimate
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 text-danger bg-white text-start f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100">
                Delete Estimate
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100">
                Create Sales Order
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100">
                View Portal
              </button>
            </div>
          )}
        </div>
      </div>
      {addContactComp && <AddContactPopup poppupToggle={poppupToggle} />}
      {addAddressComp && (
        <AddressPopup poppupAddressToggle={poppupAddressToggle} />
      )}
      <EstimateHeadComp
        onCollaborat={onCollaborat}
        collaboratClick={collaboratClick}
        setAddContComp={setAddContactComp}
        poppupAddressToggle={poppupAddressToggle}
      />
      <div className="mt-3 d-flex ac-jb mt-0 mt-md-3 flex-column flex-md-row">
        <div className="d-flex ac-js">
          <button
            onClick={() => setTab(1)}
            className={`${
              tab == 1 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}>
            Products
          </button>
          <button
            onClick={() => setTab(2)}
            className={`${
              tab == 2 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}>
            Artwork
          </button>
        </div>
        <div className="d-flex ac-jc gap-3 mt-3 mt-md-0 flex-column flex-sm-row ">
          {tab == 1 && (
            <div className="d-flex">
              <button
                onClick={() => setToggleBtn1(1)}
                className={` ${
                  toggleBtn1 === 1 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}>
                Compacts
              </button>
              <button
                onClick={() => setToggleBtn1(2)}
                className={` ${
                  toggleBtn1 === 2 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}>
                Detailed
              </button>
            </div>
          )}
          <div className="d-flex">
            <button
              onClick={() => setToggleBtn2(1)}
              className={` ${
                toggleBtn2 === 1 && "primary bg-lt-blue"
              } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}>
              Show All
            </button>
            <button
              onClick={() => setToggleBtn2(2)}
              className={` ${
                toggleBtn2 === 2 && "primary bg-lt-blue"
              } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}>
              Show Active
            </button>
          </div>
        </div>
      </div>
      {/* <TableComp /> */}
      {tab === 1 && toggleBtn1 === 1 && (
        <TableComp2
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
        />
      )}
      {tab === 1 && toggleBtn1 !== 1 && (
        <TableDetaile
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
        />
      )}
      {/* {tab === 1 && toggleBtn1 === 1 && <TableComp toggleBtn1={toggleBtn1} />} */}
      {/* {tab === 1 && toggleBtn1 !== 1 &&  */}
      {/* <CompactsTableComp /> */}

      {tab === 2 && (
        <TableComptwo
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
        />
      )}
      {/* 
      <div className="h-100 w-100">
        <NewTableComp />
      </div> */}
      {addTitle && (
        <div className="presen-list mb-3">
          <div className="add-tit-btn presen-detail presen-list mt-3">
            <div className="m-2">
              <input
                placeholder="Add a title"
                className="w-100 b-color-add mt-3"
              />
              <div className="d-flex mt-2 position-absolute m-2">
                <div className="dropdown mx-2">
                  <button
                    className="add-tit-btn2 mx-1 bottom_show_btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1">
                    <li>
                      <span className="dropdown-item">Product</span>
                    </li>
                    <li>
                      <span className="dropdown-item">Tittle</span>
                    </li>
                  </ul>
                </div>
                {/* <button className="add-tit-btn1">+ Add</button> */}
                <button
                  className="add-tit-btn2 mx-1 bottom_show_btn"
                  onClick={() => setAddShow(!addShow)}>
                  {addShow ? "Show" : "hide"}
                </button>
                <button
                  className="add-tit-btn3 mx-1 bottom_delete_btn"
                  onClick={() => setDeletes(!deletes)}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex as-jb flex-column flex-md-row gap-3 w-100">
        <div class="summary bg-soft-gray1 w-35 md_t_W">
          <div className="d-flex ac-jb py-3 px-1 px-md-3">
            <p className="">Booked Margin Amount</p>
            <p className="">$0.00</p>
          </div>
          <div className="d-flex ac-jb px-1 px-md-3 py-3">
            <p className="">Booked Margin Amount</p>
            <p className="">$0.00</p>
          </div>
        </div>
        {tab == 1 && (
          <div class="summary bg-soft-gray1 w-45 md_t_W ">
            <div className="d-flex ac-jb py-3 px-1 px-md-3">
              <p className="">Subtotal</p>
              <p className="">$0.00</p>
            </div>
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">CGST (2.5%)</p>
              <p className="">$0.00</p>
            </div>
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">Total</p>
              <p className="">$0.00</p>
            </div>
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <div className="d-flex gap-2">
                <input type="checkbox" />
                <p className="">Hide Totals</p>
              </div>
            </div>
          </div>
        )}
        {viewStock && <ViewStockPoppup viewStockToggle={viewStockToggle} />}
      </div>
    </div>
  );
};

export default EstimateScreen;
