import React, { useState } from "react";
import { searchIcon } from "../../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { event_category } from "../../redux/api/DummyJson";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RemovePoppup from "./RemovePoppup";
const   Accepted = ({ onShowPopupHander }) => {
  const navigate = useNavigate();
  const [remove, setRemove] = useState(null);
  const [removePoopup, setRemovePoppup] = useState(false);
  const removPopp = () => {
    setRemovePoppup(false);
  };
  const CheckCl = () => {
    setRemove(!remove);
    setRemovePoppup(!removePoopup);
  };

  const data = [
    {
      id: 1,
      vendor_id: "Ven_001",
      po_id: "PO001",
      vendor_name: "Dhanush",
      vendor_email: "dhanush@gmail.com",
      mobile: "9080877665",
      total_qty: "2900",
      total: "30000",
      ex_date: "23/02/2024",
    },
    {
      id: 2,
      vendor_id: "Ven_003",
      po_id: "PO003",
      vendor_name: "Vinoth",
      vendor_email: "vinoth@gmail.com",
      mobile: "9000000000",
      total_qty: "700",
      total: "20000",
      ex_date: "30/02/2024",
    },
  ];

  return (
    <div className="w-100 overflow-scroll mt-4 position-relative">
      <table className="w-100 position-relative">
        {removePoopup && (
          <div className="min_pop d-flex ac-jc p-2 flex-column viewBoxDes rounded-2 mx-4 mt-4">
            <p className="px-2 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 py-2">
              Are you sure want to remove the vendor
            </p>
            <button
              onClick={() => removPopp()}
              class="cust-btn addbtn bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
            >
              Submit
            </button>
          </div>
        )}
        <tr>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              S.No
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Vendor ID
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              PO ID
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Vendor Name
            </p>
          </th>{" "}
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Requested Qty
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Vendor Email
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Vendor Mobile Number
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Total Amount
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Expected Delivery Date
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              Notes
            </p>
          </th>
          <th>
            <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
              View
            </p>
          </th>
          {/* <th>
            <div className="d-flex ac-jc  pb-3">
              <p className=" f2 fs-xs-11 fs-sm-12 ps-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Remove
              </p>
              <button
                onClick={() => {
                  setRemovePoppup(!removePoopup);
                  setRemove(!remove);
                }}
                className=" border-0 bg-transparent f2 fs-xs-11 fs-sm-12 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
              >
                {remove ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )}
              </button>
            </div>
          </th> */}
        </tr>
        {data?.map((item, ind) => {
          return (
            <tr className="my-3">
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {ind + 1}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.vendor_id}
                </p>
              </th>{" "}
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.po_id}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.vendor_name}
                </p>
              </th>{" "}
              <th>
                <div className="d-flex ac-jc pb-4">
                  <input
                    placeholder=""
                    value={item?.total_qty}
                    className="datepicker-input w-50 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                  />
                </div>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.vendor_email}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.mobile}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.total}
                </p>
              </th>
              <th>
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  {item?.ex_date}
                </p>
              </th>
              <th>
                <p
                  className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4"
                  role={"button"}
                  onClick={onShowPopupHander}
                >
                  <CommentOutlinedIcon />
                </p>
              </th>
              <th>
                <button
                  onClick={() => navigate("/po-detail-accept-view")}
                  className="w-100 text-center bg-transparent border-0"
                >
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 pb-4">
                    <VisibilityIcon />
                  </p>
                </button>
              </th>
              {/* <th>
                <div className="d-flex ac-jc">
                  <button
                    onClick={() => {
                      setRemovePoppup(!removePoopup);
                      setRemove(!remove);
                    }}
                    className=" border-0 bg-transparent f2 fs-xs-11 fs-sm-12 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                  >
                    {remove ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankOutlinedIcon />
                    )}
                  </button>
                </div>
              </th> */}
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default Accepted;
