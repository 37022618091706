import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useLazyTokenVerifyQuery,
  useSetPasswordMutation,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [conPasswordErr, setConPasswordErr] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [tokenVerifyApi] = useLazyTokenVerifyQuery();
  const [setPasswordApi] = useSetPasswordMutation();

  const tokenVerifier = () => {
    tokenVerifyApi(searchParams?.get("token"))
      .then((res) => {})
      .catch((err) => {
        console.log("err", err);
      });
  };

  // useEffect(() => {
  //   if (searchParams?.get("token")) {
  //     tokenVerifier();
  //   }
  // }, []);

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPassword(new_pass);
    }
  };

  const submitHandler = () => {
    if (password?.length == 0 || conPassword?.length == 0) {
      setPasswordErr(true);
      setConPasswordErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("token", searchParams?.get("token"));
      formdata.append("password", password);
      formdata.append("password_confirmation", conPassword);
      setBtn(true);
      dispatch(saveLoader(true));
      setPasswordApi(formdata)
        .unwrap()
        .then((res) => {
          toast.success(res?.data?.message || res?.message);
          dispatch(saveLoader(false));
          setBtn(false);
          alert(res?.data?.message || res?.message);
          navigate("/");
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandler();
    }
  };

  return (
    <div className="signInDes d-flex">
      <div className="w-50 h-100 p-4 bg-back-blue">
        <p className="w-100 f4 fs-xs-25 fs-sm-26 fs-md-27 fs-lg-28 fs-xl-29 fs-xxl-30 primary">
          Merchhq
        </p>
        <div className="signLeftDes" />
      </div>
      <div className="w-50 position-relative">
        <div className="viewBoxDesSign  w-sm-100 w-md-90 w-lg-80 w-xl-70 p-5 rounded-5 bg-white">
          <p className="w-100 f4 fs-sm-26 fs-md-32 fs-lg-33 fs-xl-34 fs-xxl-35 primary">
            Reset Password
          </p>
          <div>
            <div className="mt-4 position-relative">
              <p className="f2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-17">
                Change Password
              </p>
              <input
                placeholder="Password"
                type={visible1 ? "text" : "password"}
                className="editBtn w-100 mt-2 f2 py-2 ps-2 rounded-3 fs-xs-12 fs-sm-13 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18"
                onChange={handlePassword}
                value={password}
                onKeyDown={keydownHandler}
              />
              <div className="visibleDes">
                {visible1 && (
                  <VisibilityIcon
                    className="fs-xs-18 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-23 fs-xxl-24"
                    onClick={() => setVisible1(!visible1)}
                  />
                )}
                {!visible1 && (
                  <VisibilityOffIcon
                    className="fs-xs-18 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-23 fs-xxl-24"
                    onClick={() => setVisible1(!visible1)}
                  />
                )}
              </div>
            </div>
            {password?.length == 0 && passwordErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Password</p>
              </div>
            )}
            {errorMessage && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">{errorMessage}</p>
              </div>
            )}
          </div>
          <div>
            <div className="mt-4 position-relative">
              <p className="f2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-17">
                Confirm Password
              </p>
              <input
                placeholder="Password"
                type={visible2 ? "text" : "password"}
                className="editBtn mt-2 f2 w-100 py-2 rounded-3 ps-2 fs-xs-12 fs-sm-13 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18"
                onChange={(e) => setConPassword(e.target.value)}
                value={conPassword}
                onKeyDown={keydownHandler}
              />
              <div className="visibleDes">
                {visible2 && (
                  <VisibilityIcon
                    className="fs-xs-18 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-23 fs-xxl-24"
                    onClick={() => setVisible2(!visible2)}
                  />
                )}
                {!visible2 && (
                  <VisibilityOffIcon
                    className="fs-xs-18 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-23 fs-xxl-24"
                    onClick={() => setVisible2(!visible2)}
                  />
                )}
              </div>
            </div>
            {conPassword?.length == 0 && conPasswordErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Confirm Password</p>
              </div>
            )}

            {conPassword?.length > 0 && password !== conPassword && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  Password And Confirm Password are Not Same
                </p>
              </div>
            )}
          </div>
          {/* <div className="w-100 forgotPassword">
            <p
              onClick={() => navigate("/forgot-password")}
              className="text-danger mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
            >
              Forgot Password
            </p>
          </div> */}
          <button
            // onClick={() => navigate("/")}
            onClick={() => submitHandler()}
            disabled={btn}
            className={`cust-btn addbtn w-100 mt-3 mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
