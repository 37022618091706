import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageCroper from "../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Select from "react-select";

const AddPackDetails = () => {
  const navigate = useNavigate();
  const [pointsArr, setPointsArr] = useState([1]);
  const [packImg, setPackImg] = useState(null);
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [tags, setTags] = useState([{ value: "red", label: "Red" }]);
  const [eventTags, setEventTags] = useState([
    { value: "green", label: "Green" },
  ]);
  const [icon, setIcon] = useState([{ value: "blue", label: "Blue" }]);

  const onAddPoints = () => {
    let tmp = [...pointsArr];
    tmp.push(1);
    setPointsArr([...tmp]);
  };

  const onDeletePoints = (ind) => {
    let tmp = [...pointsArr];
    tmp.splice(ind, 1);
    setPointsArr([...tmp]);
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  let options = [
    { value: "red", label: "Red" },
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
  ];

  const onTagInputChange = (e) => {
    let tmp = {
      value: e,
      label: e,
      type: "input",
    };
    let filtered = options?.filter((item) => item?.type === "input");
    if (filtered?.length === 0) {
      options.push(tmp);
    } else {
      options.pop();
      options.push(tmp);
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <ProductSearchHeader />
      <div className="d-flex ac-jb  mt-5">
        <div
          className="d-flex align-items-center gap-1"
          role={"button"}
          onClick={() => navigate(-1)}
        >
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary ">
            <KeyboardBackspaceIcon />
          </p>
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Pack Details
          </p>
        </div>
      </div>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <div className="w-100 d-flex flex-wrap ac-jb flex-m-r">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Title
            </p>
            <input
              placeholder="Required"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Bundle ID
            </p>
            <input
              placeholder="Required Field"
              disabled
              value={"300-0001"}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Price
            </p>
            <input
              disabled
              value={"800/pack"}
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Discount
            </p>
            <input
              placeholder="Required"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Total Price
            </p>
            <input
              placeholder="Required"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Tags
            </p>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#07679c",
                  borderRadius: "8px",
                }),
              }}
              options={options}
              defaultValue={tags}
              name="tags"
              onChange={(e) => setTags([...tags, ...e])}
              onInputChange={onTagInputChange}
              isMulti
              className="w-100  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2"
            />
          </div>
          <div className="w-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Event Tags
            </p>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#07679c",
                  borderRadius: "8px",
                }),
              }}
              options={options}
              onChange={(e) => setEventTags([...eventTags, ...e])}
              onInputChange={onTagInputChange}
              defaultValue={eventTags}
              isMulti
              className="w-100  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2"
            />
          </div>
          <div className="w-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Icons
            </p>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#07679c",
                  borderRadius: "8px",
                }),
              }}
              options={options}
              onChange={(e) => setIcon([...icon, ...e])}
              onInputChange={onTagInputChange}
              defaultValue={icon}
              isMulti
              className="w-100  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2"
            />
          </div>
          <div className="w-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Description
            </p>
            <textarea
              placeholder="Required"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Notes
            </p>
            <textarea
              placeholder="optional"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-100 mt-3">
            <p className="d-flex ac-jb black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Points
              <span>
                <button
                  onClick={onAddPoints}
                  className="  cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add
                </button>
              </span>
            </p>
            {pointsArr?.map((it, ind) => {
              return (
                <div className="d-flex mt-3 ac-jc">
                  <p className="f3 mx-2 fs-18 primary">{ind + 1}.</p>
                  <input
                    placeholder="Required Field"
                    className="editBtn w-80 rounded-3  p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  />
                  {ind === 0 ? (
                    <DeleteIcon className="gray fs-24 mx-2  " />
                  ) : (
                    <DeleteIcon
                      onClick={() => onDeletePoints(ind)}
                      className="text-danger fs-24 mx-2"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="my-5">
            <button
              onClick={() => navigate("/preset-packs")}
              className="  cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Submit
            </button>
          </div>
        </div>
        <div className="d-flex gap-3 ac-jc w-100 res-img">
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Pack Image
          </p>
          <div className="file-upload" style={{ cursor: "pointer" }}>
            <div className="file-icon d-flex ac-jc">
              <div className="d-flex flex-column ac-jc">
                <label className="upload-btn" onClick={toggleImagePopup}>
                  <img
                    className={
                      croperImage
                        ? "placeholder_icon1 object-fit-contain"
                        : "placeholder_icon object-fit-contain"
                    }
                    src={croperImage ? croperImage : uploadIcon}
                    alt="logo"
                  />
                  {packImg?.name ? null : (
                    <p className="fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 text-center">
                      Drag and drop to upload or Click here to upload
                    </p>
                  )}
                  {/* <input
                    onChange={(e) => {
                      setPackImg(e.target.files[0]);
                    }}
                    className="d-none"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                  /> */}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPackDetails;
