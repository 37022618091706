import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import { animatedImg, toggleOff, toggleOn } from "../../assets/img";
import { useNavigate } from "react-router-dom";

const ProductListComp = ({ loc, place, products }) => {
  // const [productDetail, setProductDetail] = useState(false)
  const navigate = useNavigate();
  console.log(loc, place, "lshfidjbghj");
  return (
    <>
      <div className="mt-5 banner-left">
        <div className="d-flex ac-jb flex-column flex-md-row">
          <div className="d-flex gap-3">
            <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
              <option hidden>Select Categories</option>
              <option>Mobile Phone</option>
              <option>Water Bottle</option>
            </select>
            <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
              <option hidden>Select Status</option>
              <option>Active</option>
              <option>Deactive</option>
            </select>
          </div>
          <div className="d-flex ac-jb flex-column flex-md-row gap-3 justify-content-md-end">
            <div className="d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
              <input
                type="text"
                className="get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3 w-auto fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                placeholder="Search Product Name"
              />
            </div>
            <div className="d-flex ac-jc gap-3">
              <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
                <Search />
              </button>
              <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
                <CachedIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : 15
          </p>
        </div>
      </div>
      <div className="w-100 d-flex align-items-center justify-content-start justify-content-md-center mt-5 flex-wrap">
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => {
            navigate("/map-product-detail", { state: { type: place } });
          }}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              {place}
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
              SM10001
            </p>
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
              Status
              <span>
                <img src={toggleOn} className="toggleOnDes" alt="icon" />
              </span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => {
            navigate("/map-product-detail", { state: { type: place } });
          }}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              {place}
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
              SM10001
            </p>{" "}
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
              Status
              <span>
                <img src={toggleOff} className="toggleOnDes" alt="icon" />
              </span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => {
            navigate("/map-product-detail", { state: { type: place } });
          }}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              {place}
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
              SM10001
            </p>{" "}
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
              Status
              <span>
                <img src={toggleOn} className="toggleOnDes" alt="icon" />
              </span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => {
            navigate("/map-product-detail", { state: { type: place } });
          }}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              {place}
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
              SM10001
            </p>{" "}
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
              Status
              <span>
                <img src={toggleOff} className="toggleOnDes" alt="icon" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductListComp;
