import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { salesForce, salesInprogress } from "../../redux/api/DummyJson";
import ProjectDetailPopup from "../Popup/ProjectDetailPopup";
import AddTaskPopup from "../Popup/AddTaskPopup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ProjectDetailPopup2 from "../Popup/ProjectDetailPopup2";

const SalesProgress = () => {
  const [showDetailPopup, setShowDetailPopup] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false);

  const closeDetailPopup = () => {
    setShowDetailPopup(null);
  };

  const onClickAddTask = () => {
    setShowAddTask(!showAddTask);
  };

  return (
    <div className="mt-4 ">
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0 mb-4">
          <input
            type="text"
            className="w-20 get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
            placeholder="Search for project"
          />
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Client Rep
              </option>
              <option value="All">All</option>
              <option value="In Active Reps">In Active Reps</option>
            </select>
          </button>
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Order Rep
              </option>
              <option value="All">All</option>
              <option value="In Active Reps">In Active Reps</option>
            </select>
          </button>
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Stage
              </option>
              <option value="All">All</option>
              <option value="Open Projects">Open Projects</option>
              <option value="Opportunity">Opportunity</option>
              <option value="Presentation">Presentation</option>
              <option value="Estimate">Estimate</option>
              <option value="Sales Order">Sales Order</option>
              <option value="Invoice">Invoice</option>
            </select>
          </button>
        </div>
        <div className="d-flex ac-jc gap-3">
          <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <Search />
          </button>
          <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <CachedIcon />
          </button>
        </div>
      </div>

      <div
        role="button"
        className=" w-100 overflow-scroll mt-5  postion-relative"
      >
        <div
          style={{ minWidth: "800px" }}
          className=" d-flex border-bottom pb-1"
        >
          <p className="w-20  f2 fs-14 ">
            Client
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
          <p className="w-20  f2 fs-14 ">
            Project
            <span>
              <ArrowDropDownIcon />
            </span>
          </p>
          <div className="w-60 d-flex">
            <div className="d-flex ac-jc ideation py-1 w-25">
              <p className="f3 fs-14 mx-2 white ">Ideation</p>
            </div>
            <div className="d-flex ac-jc sales-order py-1 w-25">
              <p className="f3 fs-14 mx-2 white ">Sales Order</p>
            </div>
            <div className="d-flex ac-jc in-production py-1 w-25">
              <p className="f3 fs-14 mx-2 white ">In Production</p>
            </div>
            <div className="d-flex ac-jc invoice py-1 w-25">
              <p className="f3 fs-14 mx-2 white ">Invoiced</p>
            </div>
          </div>
        </div>

        <div
          style={{ height: "250px" }}
          className="overflow-y-scroll show-scrollbar"
        >
          {salesForce?.map((item, index) => {
            return (
              <div
                onClick={() => setShowDetailPopup(item)}
                style={{ minWidth: "800px" }}
                className="overflow-scroll wholeDesign d-flex mb-3 mt-5 w-100"
              >
                <p className="w-20  f2 fs-14 primary1">{item?.client}</p>
                <p className="w-20  f2 fs-14 primary1">{item?.projectName}</p>
                <div className="w-60 d-flex">
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        <p className="text-capitalize text-start f4 fs-15">
                          {item?.progressBar?.type}
                        </p>
                        <p className="text-start">Open</p>
                        <br />
                        <p>In-Hands {item?.taskDate}</p>
                      </Tooltip>
                    )}
                    placement="top"
                  >
                    <div
                      style={{ borderRadius: "50px", height: "10px" }}
                      className={`${item?.progressBar?.type} w-${item?.progressBar?.percentage}`}
                    ></div>
                  </OverlayTrigger>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* {showDetailPopup && (
        <ProjectDetailPopup
          item={showDetailPopup}
          toggleShowPopup={closeDetailPopup}
          onClickAddTask={onClickAddTask}
        />
      )} */}
      {showDetailPopup && (
        <ProjectDetailPopup2
          item={showDetailPopup}
          toggleShowPopup={closeDetailPopup}
          onClickAddTask={onClickAddTask}
        />
      )}
      {showAddTask && <AddTaskPopup toggleShowPopup={onClickAddTask} />}
    </div>
  );
};

export default SalesProgress;
