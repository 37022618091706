import React from 'react'
import LibraryLeftComp from './LibraryLeftComp'
import LibraryRightComp from './LibraryRightComp'
import { useLocation } from 'react-router-dom'

const LibraryOverViewComp = ({ edit }) => {
    const location = useLocation()
    console.log(location?.state?.type, 'ojfkinhk')
    return (
        <div className='d-flex as-jb w-100 mt-5 res-flex gap-5'>
            <LibraryLeftComp edit={edit} />
            <LibraryRightComp edit={edit} />
            {/* <ImageRightComp /> */}
        </div>
    )
}

export default LibraryOverViewComp