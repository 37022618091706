import React, { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CheckIcon from "@mui/icons-material/Check";
import { danger_sm } from "../../../../assets/img";

const Proofing = () => {
  const [allProofCheck, setAllProofCheck] = useState(false);
  const [selectFiles, setSelectFiles] = useState(false);

  return (
    <div className="">
      <div className="d-flex ac-js ps-2 mt-2">
        <button
          className="cust-btn d-flex ac-js"
          onClick={() => {
            setAllProofCheck(!allProofCheck);
            setSelectFiles(!selectFiles);
          }}
        >
          <div
            className={`check-btn-proof me-2 d-flex ac-jc ${
              allProofCheck && "bg-primar"
            }`}
          >
            <CheckIcon className="f2 fs-13 fs-md-14 fs-lg-16 text-white" />
          </div>
          <p className="f2 fs-13 fs-md-14 fs-lg-16">All Proofs Uploaded</p>
        </button>
        {!selectFiles && (
          <button className="border-0 addbtn bg-primar f3 fs-14 fs-md-15 fs-lg-16 text-white ms-2 rounded-2">
            Select Files
          </button>
        )}
      </div>
      <div className="prev-detail mt-3">
        <div className="img-box">
          <img src={danger_sm} alt="" className="img-show" />
        </div>
        <div className="d-flex ac-js mt-2">
          <button
            className="cust-btn bg-primar text-white px-3 py-2
          f3 fs-15 fs-md-16 fs-lg-17 rounded"
          >
            Download
          </button>
          <button
            className="cust-btn bg-orange text-white px-3 py-2
          f3 fs-15 fs-md-16 fs-lg-17 rounded mx-3"
          >
            Change
          </button>
          <button
            className="cust-btn bg-primary1 text-white px-3 py-2
          f3 fs-15 fs-md-16 fs-lg-17 rounded"
          >
            Delete
          </button>
        </div>
        <p
          className="
          f3 fs-15 fs-md-16 fs-lg-17 mt-2"
        >
          file Name :{" "}
        </p>
        <p className="          f3 fs-15 fs-md-16 fs-lg-17">Date :</p>
        <div className="forms w-100">
          <select
            name=""
            id=""
            className="f2 fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-4 px-2 rounded"
          >
            <option value="No Proof Required">No Proof Required</option>
            <option value="Awaiting Proof">Awaiting Proof</option>
            <option value="Proof Received">Proof Received</option>
            <option value="Pending Approval">Pending Approval</option>
            <option value="Change Request">Change Request</option>
            <option value="Client Approved">Client Approved</option>
            <option value="Proofing Completed">Proofing Completed</option>
          </select>
          <textarea
            name=""
            id=""
            cols="30"
            rows="2"
            placeholder="Commet for client"
            className="f2 fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-4 px-2 rounded"
          ></textarea>
        </div>
        <button
          className="cust-btn bg-primar text-white px-3 py-2
          f3 fs-15 fs-md-16 fs-lg-17 rounded mt-2"
        >
          Upload
        </button>
      </div>
    </div>
  );
};
export default Proofing;
