import React, { useRef, useState } from "react";
import { ChatUserData } from "../../redux/api/DummyJson";
import {
  empty_profile,
  profile,
  profilePic,
  uploadIcon,
} from "../../assets/img";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

const Chat = () => {
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const [images, setImages] = useState([]);
  const [uploadFile, setUploadFile] = useState(false);

  const handleChange = (event) => {
    const selectedFIles = [];
    const targetFiles = event.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="dashRightView p-5 home_section trans ">
      <div className="d-flex ac-jb">
        <div className="d-flex align-items-center gap-1">
          <p
            className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-1 "
            role={"button"}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />
          </p>
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2 ">
            {" "}
            Chat
          </p>
        </div>
        <div className="d-flex gap-2 ac-jc">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 mt-2 ">
            Ticket Status
          </p>
          <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
            <option>Opened</option>
            <option>Closed</option>
            <option>Not Opened</option>
          </select>
        </div>
      </div>
      <div className="chatBackGround p-3 mt-3">
        <div className="d-flex mt-4 ">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-24 fs-xxl-25">
            Dhanush
          </p>
        </div>
        {/* <div>
          <p className='f2 fs-sm-14 fs-md-15 fs-lg-14 fs-xl-14 fs-xxl-14 primary'>Online</p>
        </div> */}
        <div className="d-flex ac-je">
          <div className="userDetails">
            {ChatUserData?.map((item, index) => {
              return (
                <div>
                  <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-20 fs-xxl-20 text-center">
                    {item?.name}
                  </p>
                  <div className="d-flex">
                    <img src={profilePic} className="chatprofile ps-3 mt-2" />
                    <div className="mt-3 ps-2">
                      <div className="d-flex">
                        <p className="f2 fs-sm-14 fs-md-15 fs-lg-14 fs-xl-14 fs-xxl-14 primary ">
                          Customer Id :
                        </p>
                        <p className="f2 fs-sm-14 fs-md-15 fs-lg-14 fs-xl-14 fs-xxl-14">
                          {item?.Vendor}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="f2 fs-sm-14 fs-md-15 fs-lg-14 fs-xl-14 fs-xxl-14 primary">
                          Email id :
                        </p>
                        <p className="f2 fs-sm-14 fs-md-15 fs-lg-14 fs-xl-14 fs-xxl-14">
                          {item?.Email}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="f2 fs-sm-14 fs-md-15 fs-lg-14 fs-xl-14 fs-xxl-14 primary">
                          Mobile No :
                        </p>
                        <p className="f2 fs-sm-14 fs-md-15 fs-lg-14 fs-xl-14 fs-xxl-14">
                          {item?.Mobile}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex gap-1">
          <div className=" d-flex flex-column ae-je">
            <div className="d-flex ac-js gap-3">
              <img src={profilePic} className="profileimgage" />
              <div className="messageContent rounded-3">
                <p className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 text-wrap p-2">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 mt-2">
              <p className="f2 fs-10 fs-lg-12 fs-xl-12 fs-xxl-12 ">
                23/02/2024
              </p>
              <p className="f2 fs-10 fs-lg-12 fs-xl-12 fs-xxl-12 ">1.40 PM</p>
            </div>
          </div>
        </div>

        <div className="ac-je d-flex">
          <div className="messageContent rounded-3">
            <p className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 text-wrap p-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
            </p>
          </div>
          <img src={empty_profile} alt="" className="profileimgage " />
        </div>
        <div className="d-flex ac-je">
          <div className="d-flex gap-2">
            <p className="f2 fs-10 fs-lg-12 fs-xl-12 fs-xxl-12 text-wrap ">
              23/02/2024
            </p>
            <p className="f2 fs-10 fs-lg-12 fs-xl-12 fs-xxl-12 text-wrap">
              1.40 PM
            </p>
          </div>
        </div>

        <div className="mt-5 ">
          {uploadFile && (
            <div className="d-flex ac-jb">
              <input
                type="file"
                multiple
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }}
              />
              <button
                onClick={handleClick}
                className="cust-btn addbtn text-nowrap ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-0 rounded"
              >
                <img
                  src={uploadIcon}
                  alt=""
                  className="me-2"
                  style={{ height: "30px", width: "30px" }}
                />
                {images?.length > 0 ? "File Uploaded" : "upload your Files"}
              </button>
            </div>
          )}
        </div>
        <div className="typeContent">
          <div className="d-flex ac-jb position-relative">
            <div className=" d-flex ac-js w-100 pe-4">
              <button
                className="pulsIcon border-0"
                onClick={() => {
                  setUploadFile(true);
                  setImages([]);
                }}
              >
                +
              </button>
              <input
                type="text"
                className="border-0 ps-2 w-100"
                placeholder="Type here..."
              />
            </div>
            <button
              className="cust-btn addbtn  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 rounded"
              onClick={() => {
                setUploadFile(false);
                setImages([]);
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
