import React from "react";
import { head, logos } from "../assets/img";

const DC = () => {
  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      <div
        className="d-flex ac-jb mt-3  banner bg-lt-blue1"
        style={{
          padding: "20px",
          flexWrap: "wrap",
        }}
      >
        <div className="">
          <img
            alt="img"
            src={logos}
            className=" ps-3"
            style={{ width: "150px", objectFit: "cover" }}
          />
        </div>
        <div>
          <p className="f4 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
            DELIVERY NOTE
          </p>
        </div>
        <div className="ps-2">
          <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
            MerchHQ Pvt.Ltd.
          </h2>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
            INVOICE for 5SK
          </p>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Shankar cement
          </p>
        </div>
      </div>
      <div className=" " style={{ width: "100%", padding: "0px 10px" }}>
        <div
          className="d-flex flex-wrap ac-jc my-4 p-2"
          style={{
            border: "1px",
            borderStyle: "solid",
            borderColor: "#0082ca",
            borderRadius: "20px",
          }}
        >
          <div
            className="col-md-7 left-line"
            style={{
              borderLeft: "2px",
              borderColor:"#cacaca",
              borderStyle: "solid",
              borderTop: 0,
              borderBottom: 0,
              borderRight: 0,
            }}
          >
            <div
              className="d-flex flex-wrap align-items-center mt-3 py-2 banner "
              style={{}}
            >
              <div className="col-md-4">
                <img
                  alt="img"
                  src={logos}
                  className=" p-2 w-90"
                  style={{
                    backgroundColor: "#ecfaff",
                    borderRadius: "20px",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="col-md-8 " style={{}}>
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                  MerchHQ Pvt.Ltd. 39 Krishna Reddy Colony, Domlur Layout
                  Banglore- 560071 Kartnataka, India. Name:Karnataka, Code:29CN:
                  U3336576890TC987
                </p>
              </div>
            </div>
            <hr />
            <div className="my-3 ms-3" style={{ lineHeight: "25px" }}>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Consignee (Shop no)
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                NINOS IT SOLUTION PVT ,LTD. 115/3 ML Chambers 6th main, 2nd
                stage Opposite Poonye metros station Banglore - 560058.
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Name : Kiran
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Phone : +91 8898768076
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                GST Num : 2979YSI$77769
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                State Name : Karnataka Code :29
              </p>
            </div>
            <hr />
            <div className="mb-3 ms-3">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 ">
                Buyer (Bill To)
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                NINOS IT SOLUTION PVT ,LTD. 115/3 ML Chambers 6th main, 2nd
                stage Opposite Poonye metros station Banglore - 560058.
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Name : Kiran
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Phone : +91 8898768076
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                GST Num : 2979YSI$77769
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                State Name : Karnataka Code :29
              </p>
            </div>
          </div>
          <div className="col-md-5 p-1 overflow-scroll">
            <table
              className="w-100 table_inner_border"
              style={{ lineHeight: "30px",border:"1" ,frame:"void", rules:"all" }}
            >
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Delivery Note No : e-way Bill No. DC/2152/23-24
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Date 5-jan-24
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Reference No & Date. From 23-2018 to 30-Dec-2024
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Other Reference Mr.Linson
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Buyers Order No Pending 23-2024
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Date 5-jan-24
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Dispatch DOc no.
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Destination Bangalore , Karnataka
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Dispatch through by Cellular
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Mode / Term of Paymentb Net 45 Days
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    ASM Name LDS
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Customer Name
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Project Name MAK/23/24/25 Anah Nash Tech Lab : New Joinee
                    Kits 27/3/2024
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Partner Name HG
                  </p>
                </td>
              </tr>
            </table>
            <textarea
              className="message rounded w-100 p-2 mt-4"
              placeholder="Terms Of Delivery"
            ></textarea>
          </div>
        </div>
        <div className=""
          style={{
            border: "1px",
            borderStyle: "solid",
            borderColor: "#0082ca",
            borderRadius: "20px",
            padding: "10px",
          }}
        >
          <div className="overflow-scroll">
            <table className="w-100 ">
              <thead>
                <tr className="" style={{border: "1px",
            borderStyle: "solid",
            borderColor: "#000",
            border: "2px",
            borderTop:0,
            borderLeft:0,
            borderRight:0,
            padding: "10px",}}>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                      S.No
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                      Product-Description Goods
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                      HSN/SAC
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                      GST Rate
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                      Quantity
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                      Rate
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                      Per
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                      Amount
                    </p>
                  </th>
                </tr>
              
              </thead>
             
              <tbody>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    2
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    3
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    4
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    5
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2"
                  >
                    TAXES
                  </td>
                  <td
                    colSpan={3}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end"
                  >
                    SUBTOTAL
                  </td>
                  <td
                    colSpan={3}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 bg-lt-blue3 p-2 rounded mx-2"
                  >
                    1,70,00,000
                  </td>
                </tr>
                <tr className="my-3">
                  <td
                    colSpan={2}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end"
                  >
                    Output CGST 6%
                  </td>
                  <td
                    colSpan={4}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-center"
                  >
                    0
                  </td>
                  <td
                    colSpan={1}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-center"
                  >
                    %
                  </td>
                  <td
                    colSpan={1}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18  mx-2"
                  >
                    10,244.00
                  </td>
                </tr>
                <tr className="my-3">
                  <td
                    colSpan={2}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end"
                  >
                    Output CGST 6%
                  </td>
                  <td
                    colSpan={4}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-center"
                  >
                    0
                  </td>
                  <td
                    colSpan={1}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-center"
                  >
                    %{" "}
                  </td>
                  <td
                    colSpan={1}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18  mx-2"
                  >
                    10,244.00
                  </td>
                </tr>
                <tr className="bg-lt-blue3  rounded my-3">
                  <td
                    colSpan={2}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end p-3"
                  >
                    Total
                  </td>
                  <td
                    colSpan={3}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-center"
                  >
                    142 No
                  </td>
                  <td
                    colSpan={3}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18  mx-2"
                  >
                    1,90,848.00$
                  </td>
                </tr>
              </tbody>
            </table>

            <hr />
            <div className="d-flex ac-jb mx-2 my-2">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Amount Changable (in words)
              </p>

              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                E & O.E
              </p>
            </div>

            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-4">
              One Lakh Ninty Thousand Eight Hundred And Fourty Eight INR Only
            </p>
            <hr />
            <div className="d-flex ">
              <div className=" d-flex ae-je col-md-6">
                <p className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 pe-4">
                  Total
                </p>
              </div>
              <div className="col-md-6 ps-1">
                <table
                  className="w-100"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: "black",
                  }}
                >
                  <tr>
                    <td
                      rowSpan={2}
                      className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18"
                    >
                      Taxable Value
                    </td>
                    <td
                      colSpan={2}
                      className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 text-center"
                    >
                      Central Tax
                    </td>
                    <td
                      colSpan={2}
                      className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 text-center"
                    >
                      State Tax
                    </td>
                    <td
                      rowSpan={2}
                      className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18"
                    >
                      Total Amount
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                      Rate
                    </td>
                    <td className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                      Amount
                    </td>
                    <td className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                      Rate
                    </td>
                    <td className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                      Amount
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      1,90,848.00$
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      6%
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      10,224,00
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      6%
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      10,224,00
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      20 ,848.00$
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      1,90,848.00$
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      6%
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      10,224,00
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      6%
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      10,224,00
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      20 ,848.00$
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Tax Amount (in words): Twenty Thousand Eight Hundred And Fourty
              Eight INR Only
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-5">
              Company's PAN : AABCH99868
            </p>
          </div>
          <hr />
          <div className=" d-flex ac-jb mx-2">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Read in Good Condition
            </p>
            <div>
              <p className="f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                MerchHQ PVT.LTD.
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-4">
                Authorised Signatory
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DC;
