import React, { useState } from "react";
import { searchIcon } from "../../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { event_category } from "../../redux/api/DummyJson";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import Inprocess from "../../components/POallRequest/Inprocess";
import Accepted from "../../components/POallRequest/Accepted";
import Rejected from "../../components/POallRequest/Rejected";
import PoNotesViewPopup from "../../components/Popup/PoNotesViewPopup";

const POoverAllRequestScreen = () => {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("Inprocess");

  const navigate = useNavigate();
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const onShowPopupHander = () => {
    setShow(!show);
  };

  return (
    <div className="dashRightView p-0 trans">
      {show && <PoNotesViewPopup onShowPopupHander={onShowPopupHander} />}
      
      
      <div className="d-flex mb-4 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-4 rk">
          <button
            onClick={() => onTabClick("Inprocess")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Inprocess"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Inprocess" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Inprocess
            </p>
          </button>
          <button
            onClick={() => onTabClick("Accepted")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Accepted"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Accepted" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Accepted
            </p>
          </button>
          <button
            onClick={() => onTabClick("Rejected")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Rejected"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "Rejected" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Rejected
            </p>
          </button>
        </div>
        <div className="d-flex ac-js">
          <h5 className="preset_count bold pe-3 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Total Count: 3
          </h5>
          <div className="d-flex mt-md-0 mt-3 border-search">
            <img alt="img" src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div className="d-flex gap-3">
        <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
          <option hidden>Requested PO ID</option>
          <option>Mobile Phone</option>
          <option>Water Bottle</option>
        </select>
        <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
          <option hidden>vendor ID</option>
          <option>Active</option>
          <option>Deactive</option>
        </select>
        <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
          <option hidden>Vendor Name</option>
          <option>Active</option>
          <option>Deactive</option>
        </select>
      </div>
      {activeTab == "Inprocess" && (
        <Inprocess onShowPopupHander={onShowPopupHander} />
      )}
      {activeTab == "Accepted" && (
        <Accepted onShowPopupHander={onShowPopupHander} />
      )}
      {activeTab == "Rejected" && (
        <Rejected onShowPopupHander={onShowPopupHander} />
      )}
      <div className="d-flex ac-jb w-100 mt-3">
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Previous
        </button>
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default POoverAllRequestScreen;
