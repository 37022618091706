import React from "react";
import { head, logos } from "../assets/img";

const DCb = () => {
  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ padding: "20px", flexWrap: "wrap" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-4"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="ps-3 font-blue2">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                DELIVERY NOTE
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                DC for 5SK Supplier
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Sankar Cement
              </p>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div
          className="d-flex flex-wrap ac-jc my-4 p-2"
          style={{
            border: "1px",
            borderStyle: "solid",
            borderColor: "#0082ca",
            borderRadius: "20px",
          }}
        >
          <div
            className="col-md-6 ryt-line"
            // style={{
            //   borderRight: "2px",
            //   borderStyle: "solid",
            //   borderColor: "#cacaca",
            //   borderLeft: "0",
            //   borderTop: "0",
            //   borderBottom: "0",
            //   padding: "2px",
            // }}
          >
            <div
              className="d-flex flex-wrap align-items-center mt-3 py-2 banner "
              style={{}}
            >
              <div className="col-md-4">
                <img
                  alt="img"
                  src={logos}
                  className=" p-2 w-90 bg-lt-blue2"
                  style={{
                    borderRadius: "20px",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="col-md-8 " style={{}}>
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 w-80">
                  MerchHQ Pvt.Ltd. 39 Krishna Reddy Colony, Domlur Layout
                  Banglore- 560071 Kartnataka, India. Name:Karnataka, Code:29CN:
                  U3336576890TC987
                </p>
              </div>
            </div>
            <hr />
            <div className="my-3 ms-3" style={{ lineHeight: "25px" }}>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Consignee (Shop no)
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                NINOS IT SOLUTION PVT ,LTD. 115/3 ML Chambers 6th main, 2nd
                stage Opposite Poonye metros station Banglore - 560058.
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Name : Kiran
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Phone : +91 8898768076
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                GST Num : 2979YSI$77769
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                State Name : Karnataka Code :29
              </p>
            </div>
            <hr />
            <div className="mb-3 ms-3">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 ">
                Buyer (Bill To)
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                NINOS IT SOLUTION PVT ,LTD. 115/3 ML Chambers 6th main, 2nd
                stage Opposite Poonye metros station Banglore - 560058.
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Name : Kiran
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                Phone : +91 8898768076
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                GST Num : 2979YSI$77769
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                State Name : Karnataka Code :29
              </p>
            </div>
          </div>
          <div
            className="col-md-6 p-1 overflow-scroll left-line"
            style={{
              borderLeft: "2px",
              borderColor: "#cacaca",
              borderStyle: "solid",
              borderTop: 0,
              borderBottom: 0,
              borderRight: 0,
              padding: "2px",
            }}
          >
            <table className="w-100  tabs" style={{ lineHeight: "30px" }}>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Delivery Note No : e-way Bill No.
                    <br />
                    DC/2152/23-24
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Date
                    <br />
                    5-jan-24
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Reference No & Date.
                    <br />
                    From 23-2018 to 30-Dec-2024
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Other Reference <br /> Mr.Linson
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Buyers Order No <br />
                    Pending 23-2024
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Date
                    <br />
                    5-jan-24
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Dispatch DOc no.
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Destination <br />
                    Bangalore , Karnataka
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Dispatch through <br /> by Cellular
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Mode / Term of Payment <br /> Net 45 Days
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    ASM Name <br />
                    LDS
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Customer Name
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Project Name <br /> MAK/23/24/25 Anah Nash Tech Lab : New
                    Joinee Kits 27/3/2024
                  </p>
                </td>
                <td>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2">
                    Partner Name <br /> HG
                  </p>
                </td>
              </tr>
            </table>
            <textarea
              className="message rounded w-100 p-2 mt-4"
              placeholder="Terms Of Delivery"
            ></textarea>
          </div>
        </div>
        <div
          style={{
            border: "1px",
            borderStyle: "solid",
            borderColor: "#0082ca",
            borderRadius: "20px",
            padding: "10px",
          }}
        >
          <div className="overflow-scroll">
            <table className="w-100 ">
              <thead>
                <tr
                  className="bg-primar rounded"
                  style={{
                    border: "1px",
                    // borderStyle: "solid",
                    // borderColor: "#000",
                    // border: "2px",
                    // borderTop:0,
                    // borderLeft:0,
                    // borderRight:0,
                    padding: "10px",
                  }}
                >
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 white">
                      S.No
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 white">
                      Product-Description Goods
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 white">
                      HSN/SAC
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 white">
                      GST Rate
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 white">
                      Quantity
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 white">
                      Rate
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 white">
                      Per
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 white">
                      Amount
                    </p>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    2
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    3
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    4
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    5
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    NASH-0001 French Connection All weather Series Jacket-Navy
                    Blue-XS
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    6001268
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    12%
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    20 No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    1200.00
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    No
                  </td>
                  <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 p-2 ">
                    24,000.00
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2"
                  >
                    TAXES
                  </td>
                  <td
                    colSpan={4}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end pe-3"
                  >
                    SUBTOTAL
                  </td>
                  <td
                    colSpan={2}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 bg-lt-blue3 p-2 rounded mx-2 text-center"
                  >
                    1,70,00,000
                  </td>
                </tr>
                <tr className="my-3">
                  <td
                    colSpan={2}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end"
                  >
                    Output CGST 6%
                  </td>
                  <td
                    colSpan={4}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end pe-4"
                  >
                    6
                  </td>
                  <td
                    colSpan={1}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-center"
                  >
                    %
                  </td>
                  <td
                    colSpan={1}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18  mx-2"
                  >
                    10,244.00
                  </td>
                </tr>
                <tr className="my-3 ">
                  <td
                    colSpan={2}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end"
                  >
                    Output CGST 6%
                  </td>
                  <td
                    colSpan={4}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end ms-4 pe-4"
                  >
                    6
                  </td>
                  <td
                    colSpan={1}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-center"
                  >
                    %{" "}
                  </td>
                  <td
                    colSpan={1}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18  mx-2"
                  >
                    10,244.00
                  </td>
                </tr>
                <tr className="bg-lt-blue3  rounded my-3">
                  <td
                    colSpan={2}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end p-3"
                  >
                    Total
                  </td>
                  <td
                    colSpan={3}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mx-2 text-end pe-4"
                  >
                    142 No
                  </td>
                  <td
                    colSpan={3}
                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18  mx-2 text-end pe-4"
                  >
                    1,90,848.00$
                  </td>
                </tr>
              </tbody>
            </table>

           
            <div className="d-flex ac-jb mx-2 my-2">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Amount Changable (in words)
              </p>

              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                E & O.E
              </p>
            </div>

            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-4">
              One Lakh Ninty Thousand Eight Hundred And Fourty Eight INR Only
            </p>
            <hr />
            <div className="d-flex  flex-wrap">
              <div className=" d-flex ae-je col-md-6">
                <p className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 pe-4">
                  Total
                </p>
              </div>
              <div className="col-md-6 ">
                <table
                  className="w-100 tabs "
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: "black",
                  }}
                >
                  <tr>
                    <td
                      rowSpan={2}
                      className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18"
                    >
                      Taxable Value
                    </td>
                    <td
                      colSpan={2}
                      className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 text-center"
                    >
                      Central Tax
                    </td>
                    <td
                      colSpan={2}
                      className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 text-center"
                    >
                      State Tax
                    </td>
                    <td
                      rowSpan={2}
                      className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18"
                    >
                      Total Amount
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                      Rate
                    </td>
                    <td className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                      Amount
                    </td>
                    <td className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                      Rate
                    </td>
                    <td className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                      Amount
                    </td>
                  </tr>
                  <tr className="text-center" style={{ borderBottom: "0" }}>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      1,90,848.00$
                    </td>
                    
                      <td
                        rowSpan={2}
                        className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
                      >
                        6%
                      </td>
                    
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      10,224,00
                    </td>
                   
                      <td
                        rowSpan={2}
                        className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
                      >
                        6%
                      </td>
                  
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      10,224,00
                    </td>
                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      20 ,848.00$
                    </td>
                  </tr>
                  <tr className="text-center" style={{ borderTop: "0" }}>
                    <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      1,90,848.00$
                    </td>
                    {/* <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      
                    </td> */}
                    <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      10,224,00
                    </td>
                    {/* <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      
                    </td> */}
                    <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      10,224,00
                    </td>
                    <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      20 ,848.00$
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <hr />
          <div className="mt-3">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Tax Amount (in words):<span className="f3"> Twenty Thousand Eight Hundred And Fourty
              Eight INR Only</span>
            </p>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-5">
              Company's PAN :<span className="f3"> AABCH99868</span>
            </p>
          </div>
          <hr />
          <div className=" d-flex align-start justify-content-between mx-2">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ">
              Read in Good Condition
            </p>
            <div>
              <p className="f3 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                MerchHQ PVT.LTD.
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-4">
                Authorised Signatory
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DCb;
