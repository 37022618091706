import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { productTableData } from "../redux/api/DummyJson";
import { editIcon, product } from "../assets/img";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const OrderedViewDetails = () => {
  const location = useLocation();
  const [data, setData] = useState({});
  const [action, setAction] = useState(true);
  const navigate = useNavigate();
  const dummy_data_ecom = data?.productName;

  const getData = () => {
    let local = location.state?.data;
    let temp = {};
    temp.catType = local?.catType;
    temp.price = local?.price;
    temp.productName = local?.productName;
    temp.qty = local?.qty;
    temp.id = location?.state?.ind;

    setData(temp);
  };
  useEffect(() => {
    getData();
  }, [location]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setData((val) => ({ ...val, [name]: value }));
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}>
        <KeyboardBackspaceIcon /> Ordered Product Details
      </p>
      <div className="w-100 searchField mt-5 bg-white">
        <div className="container mx-md-4">
          <div className="d-flex justify-content-end me-4 mt-1">
            {dummy_data_ecom != undefined && (
              <button
                className="cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                onClick={() => setAction(!action)}>
                Edit
              </button>
            )}
          </div>
          <div className="w-100 ac-jc d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
            <div className="w-100 d-flex flex-column">
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Order ID:
                </p>
                {/* <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                MNS0001
              </p> */}
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={dummy_data_ecom != undefined ? data?.id : "68501"}
                    name="id"
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Category:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={
                      dummy_data_ecom != undefined
                        ? data?.catType
                        : "Category Title"
                    }
                    name="catType"
                    onChange={handleChange}
                    disabled={action ? true : false}
                  />
                </div>
              </div>
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Sub Category:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={
                      dummy_data_ecom != undefined
                        ? data?.catType
                        : "Sub Category Title"
                    }
                    name="catType"
                    onChange={handleChange}
                    disabled={action ? true : false}
                  />
                </div>
              </div>
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Sub Category Code:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={
                      dummy_data_ecom != undefined
                        ? data?.catType
                        : "Sub Category Code"
                    }
                    name="catType"
                    onChange={handleChange}
                    disabled={action ? true : false}
                  />
                </div>
              </div>
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Product Name:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={
                      dummy_data_ecom != undefined
                        ? data?.productName
                        : "Product Name"
                    }
                    name="productName"
                    onChange={handleChange}
                    disabled={action ? true : false}
                  />
                </div>
              </div>

              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Quantity:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={dummy_data_ecom != undefined ? data?.qty : 2}
                    name="qty"
                    onChange={handleChange}
                    disabled={action ? true : false}
                  />
                </div>
              </div>
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Price:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={dummy_data_ecom != undefined ? data?.price : "₹500"}
                    name="price"
                    onChange={handleChange}
                    disabled={action ? true : false}
                  />
                </div>
              </div>

              {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Order Status:
              </p>
              <select
                placeholder=""
                className="black w-15 editBtnSelect f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 rounded-3"
                name=""
                id=""
              >
                <option>Paid</option>
                <option>Operation</option>
                <option>Shipping Ready</option>
                <option>Shipment</option>
                <option>Deliver</option>
              </select>
            </div> */}
            </div>
            <div className="w-md-50 w-100">
              <img src={product} className="w-100" />
            </div>
          </div>

          <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
              Back
            </button>
            {dummy_data_ecom != undefined && (
              <button
                className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                onClick={() => {
                  navigate(-1);
                }}>
                Submit Details
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderedViewDetails;
