import React from "react";
import FormComp from "./FormComp";
import ImageUplodeComp from "./ImageUplodeComp";

const Product1Comp = ({ showComp, setSelprod, ind }) => {
  return (
    <div
      className={`${
        showComp ? "d-flex" : "d-none"
      }  as-jb w-100 mt-4 res-flex gap-5 `}>
      <FormComp ind={ind} setSelprod={setSelprod} />
      <ImageUplodeComp />
    </div>
  );
};

export default Product1Comp;
