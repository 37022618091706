import { Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownTap from "../components/ProductComp/ProductDropdownTap";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import ProductListComp from "../components/ProductComp/ProductListComp";
import ProductLibraryComp from "../components/ProductLibrary/ProductLibraryComp";
import ProductListLibraryComp from "../components/ProductLibrary/ProductListLibraryComp";
import { searchIcon } from "../assets/img";
import { useDispatch } from "react-redux";
import { saveAlertMessage } from "../redux/slice/alertMessage";

const ProductLibrary = () => {
  const navigate = useNavigate();
  const Path = useLocation();
  // console.log(Path?.state);
  const location = Path?.state?.type;
  // console.log(location);
  const [product, setProduct] = useState(true);
  const [decorative, setDecorative] = useState(false);
  const [both, setBoth] = useState(false);

  const [productList, setParoductList] = useState("");
  useEffect(() => {
    product && setParoductList("product");
    decorative && setParoductList("decorative");
    both && setParoductList("both");
  });
  const locationtwo = useLocation();
  // console.log("locationtwo", locationtwo);

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5">
        Product Library
      </p>
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-3 rk">
          {/* <button
            onClick={() => {
              setProduct(false);
              setDecorative(false);
              setBoth(true);
              setParoductList("both");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              both
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                both ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12  fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Both
            </p>
          </button> */}
          <button
            onClick={() => {
              setProduct(true);
              setDecorative(false);
              setBoth(false);
              setParoductList("product");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              product
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                product ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Library List
            </p>
          </button>
          {/* <button
            onClick={() => {
              setProduct(false);
              setDecorative(true);
              setBoth(false);
              setParoductList("decorative");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              decorative
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                decorative ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Decorative
            </p>
          </button> */}
        </div>
        <div className="d-flex gap-2">
          {/* <button
            onClick={() => {
              navigate("/add-collaborate");
              // console.log("ll");
            }}
            className="cust-btn orangebtn mt-sm-3 ms-1 mt-2 bg-orange text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Collaborate
          </button> */}
          <div className="d-flex mt-sm-3 border-search mx-1">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
          <button
            onClick={() => {
              product &&
                navigate("/products/add-new-product", {
                  state: { type: "Charles", path: "library" },
                }) &&
                setProduct(true) &&
                setDecorative(false) &&
                setBoth(false);
              decorative &&
                navigate("/products/add-new-product", {
                  state: { type: "John Rolph", path: "library" },
                }) &&
                setProduct(false) &&
                setDecorative(true) &&
                setBoth(false);
              both &&
                navigate("/products/add-new-product", {
                  state: { type: "Jake Adams", path: "library" },
                }) &&
                setProduct(false) &&
                setDecorative(false) &&
                setBoth(true);
            }}
            // onClick={() => navigate("/products/add-new-product", {
            //   state: { product: 'Charles', Charles: 'product', servisec: "John Rolph", both: 'Jake Adams' },
            // })}
            className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            {product || both ? " + Add Library" : " + Add decorative"}
          </button>
        </div>
      </div>
      <ProductLibraryComp method={"product-library"} />
      <ProductListLibraryComp
        productList={productList}
        setParoductList={setParoductList}
      />
    </div>
  );
};

export default ProductLibrary;
